import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')
const uuid = require('uuid').v4

let latest
export async function updateProjectPromise(project, debounceTime){
  let requestId = uuid()
  latest = requestId

  setTimeout(
    () => {
      if(latest === requestId){
        updateProjectPromiseRaw(project)
      }
    }, debounceTime ? debounceTime : 0
  )
}

async function updateProjectPromiseRaw(project) {
  let response, result
  try {
    response = await updateProjectRequest(project)
  }
  catch (err) {
    logUnexpected(err, { project }, "updateProjectRequest")
    throw err
  }
  try {
    result = parseUpdateProjectResponse(response)
  }
  catch (err) {
    if (false /* add retries here; check error-type & retryCounter */ ) {
      // retry stub
      // RECURSION
      // return await updateProject(project, retryCounter)
    }
    else {
      // log & rethrow
      logUnexpected(err, { response }, "parseUpdateProjectResponse", { project })
      throw err
    }
  }
  return result
}

function updateProjectRequest(project) {
  return axios({
      method: 'post',
      validateStatus: () => true,
      url: config.UPDATE_PROJECT_ONLY_ENDPOINT,
      data: {
        token: config.MICROSERVICE_TOKEN,
        project,
      }
    })
    .then(val => ({ data: val.data, status: val.status }))
}

function parseUpdateProjectResponse(response) {
  if (response.status === 200) {
    try {
      return response.data
    }
    catch (err) {
      throw Error(`Failed to update project: unexpected reason ${err.message}`)
    }
  }
  else if (response.status === 502) { // If there is an issue with the proxy server
    throw Error(`502 was returned to updateProjectHandler from upstream the proxy server.`)
  }
  else {
    throw Error(`Unexpected Status Code: ${JSON.stringify(response.status)}`)
  }
}
