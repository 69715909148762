import React, { useState } from "react"
import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { ExpandLess, MoreVert } from "@material-ui/icons"
import USER_TYPES from '../../utils/USER_TYPES.js'

export default function PartCardActions({ collapse, partGroupNumber, replacePartFile, user }){
  return(
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      { user.type === USER_TYPES.DME ?
        <PartCardActionsMenu
          partGroupNumber={partGroupNumber}
          replacePartFile={replacePartFile}
        />
        : null }
    <IconButton tabIndex="-1" color="primary" onClick={collapse}>
      <ExpandLess/>
    </IconButton>
    </div>
  )
}

function PartCardActionsMenu({ partGroupNumber, replacePartFile }){
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const replacePartFileHandler = () => {
    handleClose()

    // get the file that was selected from the file system
    document.getElementById('replaceFileButton').click()
    document.getElementById('replaceFileButton').onchange = async () => {
      const files = document.getElementById('replaceFileButton').files
      if(files.length > 0){
        const file = document.getElementById('replaceFileButton').files[0]
        replacePartFile(file, partGroupNumber)
      }
    }
  }

  return(
    <>
      <IconButton tabIndex="-1" color="primary" onClick={handleClick}>
        <MoreVert/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={replacePartFileHandler}>Replace Part File</MenuItem>
      </Menu>
      <input id="replaceFileButton" type="file" hidden/>
    </>
  )
}
