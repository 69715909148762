import React, { Fragment } from 'react'
//DXI: NativeSelect looks better in dropdown menu
import { Button, Checkbox, FormControlLabel, NativeSelect as Select, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { getActivityByActivityNumber } from '../getActivityByActivityNumber.js'

export const GeneratePaperworkOptionsForm = ({
    activities,
    activityNumber,
    classes,
    currentPDF,
    disabled,
    generatePaperwork,
    includeBoxesPaperwork,
    isPackslipApproved,
    isPackslipChecked,
    isPartReportApproved,
    isPartReportChecked,
    isPoApproved,
    isPoChecked,
    isPurchasingReportApproved,
    isPurchasingReportChecked,
    onChange,
    onChangeActivityNumber,
    setIncludeBoxesPaperwork,
    setIsPackslipChecked,
    setIsPartReportChecked,
    setIsPoChecked,
    setIsPurchasingReportChecked,
}) => {
    return (
        <div className={classes.settingsTable}>
      <Table>
        <TableBody>
          <ActivityNumberRow
            activities={activities}
            activityNumber={activityNumber}
            classes={classes}
            disabled={disabled}
            onChangeActivityNumber={onChangeActivityNumber}
          />
          <PaperworkTypeRow
            activities={activities}
            activityNumber={activityNumber}
            classes={classes}
            currentPDF={currentPDF}
            disabled={disabled}
            includeBoxesPaperwork={includeBoxesPaperwork}
            isPackslipApproved={isPackslipApproved}
            isPackslipChecked={isPackslipChecked}
            isPartReportApproved={isPartReportApproved}
            isPartReportChecked={isPartReportChecked}
            isPoApproved={isPoApproved}
            isPoChecked={isPoChecked}
            isPurchasingReportApproved={isPurchasingReportApproved}
            isPurchasingReportChecked={isPurchasingReportChecked}
            onChange={onChange}
            setIncludeBoxesPaperwork={setIncludeBoxesPaperwork}
            setIsPackslipChecked={setIsPackslipChecked}
            setIsPartReportChecked={setIsPartReportChecked}
            setIsPoChecked={setIsPoChecked}
            setIsPurchasingReportChecked={setIsPurchasingReportChecked}
          />
        </TableBody>
      </Table>
    </div>
    )
}

const ActivityNumberRow = ({ activities, activityNumber, classes, disabled, onChangeActivityNumber }) => {
    return (
        <TableRow>
      <TableCell className={classes.labelCell}>
        Activity Number
      </TableCell>
      <TableCell className={classes.menuCell}>
        <Select
          className={classes.selectMenu}
          disabled={disabled}
          onChange={e => onChangeActivityNumber(e.target.value)}
          value={activityNumber}
        >
          {activities.map((activity,index)=>{
            return(
              <option key={index+activity.activityNumber} value={activity.activityNumber}>{activity.activityNumber}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
    )
}

const PaperworkTypeRow = ({
  activities,
  activityNumber,
  classes,
  currentPDF,
  disabled,
  includeBoxesPaperwork,
  isPackslipApproved,
  isPackslipChecked,
  isPartReportApproved,
  isPartReportChecked,
  isPoApproved,
  isPoChecked,
  isPurchasingReportApproved,
  isPurchasingReportChecked,
  onChange,
  setIncludeBoxesPaperwork,
  setIsPackslipChecked,
  setIsPartReportChecked,
  setIsPoChecked,
  setIsPurchasingReportChecked,
}) => {
    return (
        <TableRow>
      <TableCell className={classes.labelCell}>
        Paperwork Types
      </TableCell>
      <TableCell className={classes.menuCell}>
          <PaperworkTypeOptions
            activities={activities}
            activityNumber={activityNumber}
            currentPDF={currentPDF}
            disabled={disabled}
            includeBoxesPaperwork={includeBoxesPaperwork}
            isPackslipApproved={isPackslipApproved}
            isPackslipChecked={isPackslipChecked}
            isPartReportApproved={isPartReportApproved}
            isPartReportChecked={isPartReportChecked}
            isPoApproved={isPoApproved}
            isPoChecked={isPoChecked}
            isPurchasingReportApproved={isPurchasingReportApproved}
            isPurchasingReportChecked={isPurchasingReportChecked}
            setIncludeBoxesPaperwork={setIncludeBoxesPaperwork}
            setIsPackslipChecked={setIsPackslipChecked}
            setIsPartReportChecked={setIsPartReportChecked}
            setIsPoChecked={setIsPoChecked}
            setIsPurchasingReportChecked={setIsPurchasingReportChecked}
          />
      </TableCell>
    </TableRow>
    )
}

const PaperworkTypeOptions = ({
  activities,
  activityNumber,
  currentPDF,
  disabled,
  includeBoxesPaperwork,
  isPackslipApproved,
  isPackslipChecked,
  isPartReportApproved,
  isPartReportChecked,
  isPoApproved,
  isPoChecked,
  isPurchasingReportApproved,
  isPurchasingReportChecked,
  setIncludeBoxesPaperwork,
  setIsPackslipChecked,
  setIsPartReportChecked,
  setIsPoChecked,
  setIsPurchasingReportChecked,
}) => {
    const activity = getActivityByActivityNumber(activities, activityNumber)
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          { activity.makeOrder ?
            <>
              <PaperworkTypeCheckbox
                isApproved={isPurchasingReportApproved}
                disabled={disabled}
                label="Purchasing Report"
                setValue={setIsPurchasingReportChecked}
                value={isPurchasingReportChecked}
              />
              <PaperworkTypeCheckbox
                isApproved={isPoApproved}
                disabled={disabled}
                label="PO"
                setValue={setIsPoChecked}
                value={isPoChecked}
              />
              <PaperworkTypeCheckbox
                isApproved={isPartReportApproved}
                disabled={disabled}
                label="Part Report"
                setValue={setIsPartReportChecked}
                value={isPartReportChecked}
              />
            </>
            : null
          }
          { activity.shipment ?
            <PaperworkTypeCheckbox
              isApproved={isPackslipApproved}
              disabled={disabled}
              label="Packslip"
              setValue={setIsPackslipChecked}
              value={isPackslipChecked}
            />
            : null
          }
          { activity.shipment && activity.shipment.boxes && activity.shipment.boxes.length ?
            <PaperworkTypeCheckbox
              isApproved={false}
              disabled={!isPackslipChecked || disabled}
              label="Boxes on Packslip"
              setValue={setIncludeBoxesPaperwork}
              value={includeBoxesPaperwork}
            />
            : null
          }
        </div>
    )
}

const PaperworkTypeCheckbox = ({ isApproved, disabled, label, setValue, value }) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          disabled={Boolean(disabled)}
          onChange={e => setValue(e.target.checked)}
        />
      }
      label={label}
    />
      {isApproved ? <CheckCircleOutline/> : null}
    </div>
  )
}

