import React from 'react'
import {withStyles} from '@material-ui/core'

/*
  ActionLink is a component that easily binds a provided function (props.action) to a link
  The text of the link is controlled by props.label
*/
const ActionLink = props => {
  const handleClick = event => {
    event.preventDefault()
    if(props.action){
      props.action()
    }
  }

  /*
   The href is using a filler address (autotiv.com) that will not be followed (thanks to event.preventDefault() above)
   The linter (https://github.com/evcohen/eslint-plugin-jsx-a11y) does not allow invalid
   hrefs (ex: href="#") so we use an arbitrary address
  */
  if(props.theme === 'secondary'){
    return(
      <a className={props.classes.secondaryLink} href="autotiv.com" onClick={handleClick}>
        {props.label}
      </a>
    )
  }else{
    return(
      <a className={props.classes.link} href="autotiv.com" onClick={handleClick}>
        {props.label}
      </a>
    )
  }
}

const STYLES = theme => ({
  link:{
    color:'white',
    '&:visited':{
      color:'white',
    },
    '&:hover':{
      color:'#dedede',
    },
  },
  secondaryLink:{
    color:'blue',
    '&:visited':{
      color:'purple',
    },
    '&:hover':{
      color:'indigo',
    },
  },
})

export default withStyles(STYLES)(ActionLink)
