import React from 'react'
import {Typography,Modal,withStyles} from '@material-ui/core'
import {SelectOptionFinishing} from '../Common'

const PostProcessesModal = ({classes, open, onChange, onClose, partName, process, value}) => {
  return(
    <Modal
      open={open}
      onClose={onClose}
      style={{alignItems:'center',display:'flex',justifyContent:'center',maxHeight:'100vh',overflowY:'auto'}}
    >
      <div className={classes.paper}>
        <Typography align="center" style={{marginBottom:'16px'}} variant="h4">
          {partName ? `${partName} Post Processes` : `Post Processes`}
        </Typography>
        <div align="center">
          <div className={classes.dropdownContainer}>
            <SelectOptionFinishing
              name="finishId"
              onChange={onChange}
              process={process}
              value={value}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const STYLES = theme => ({
  dropdownContainer:{
    width: '300px'
  },
  paper:{
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    padding: theme.spacing(4),
    position: 'absolute',
    top: '20%',
    width: '30vw',
    minWidth: '40em'
  }
})

export default withStyles(STYLES)(PostProcessesModal)
