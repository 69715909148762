import config from "../config"
import axios from "axios"
import { SHIP_PROVIDER_OPTIONS } from '../Components/SELECT_FIELDS.js'
import USER_TYPES from '../utils/USER_TYPES.js'
const { logUnexpected } = require('./logUnexpected.js')

export async function loadProject(projectToLoad, user){
  const customerEmail = user.email ? user.email : "" // @rectify: authorization should be in backend
  const isDmePermissionEnabled = user.type === USER_TYPES.DME // @rectify: authorization should be in backend

  let response
  try{
    response = await loadProjectRequest(projectToLoad,customerEmail,isDmePermissionEnabled)
  }
  catch(err){
    logUnexpected(err, { projectToLoad, customerEmail, isDmePermissionEnabled }, 'loadProjectRequest', { user })
    throw err
  }

  let parsedResponse
  try{
    parsedResponse = parseLoadProjectResponse(response)
  }
  catch(err){
    if(err.message.includes('User does not have permissions to this project')){
      throw err
    }
    logUnexpected(err, { response }, 'parseLoadProjectResponse', { projectToLoad, user, customerEmail, isDmePermissionEnabled })
    throw err
  }
  return parsedResponse
}

async function loadProjectRequest(projectToLoad,customerEmail,isDmePermissionEnabled){
  const requestType = isDmePermissionEnabled ? "dme-user" : "customer-app"

  return axios({
    method: 'POST',
    url: config.LOAD_PROJECT_ENDPOINT,
    timeout: 10000,
    validateStatus: () => true,
    data: {
      customerEmail: customerEmail,
      projectNumber: projectToLoad,
      token: config.MICROSERVICE_TOKEN,
      type: requestType
    },
  })
  .then( rawResponse => ({data: rawResponse.data, status: rawResponse.status}))
}

function parseLoadProjectResponse(response){
  if(response.status !== 200) throw new Error(`Unhandled status code : ${response.status}`)
  if(response.data.error) throw new Error (`Internal error: ${response.data.error}`)
  if(response.data.statusMessage === "wrongCustomer") throw new Error('User does not have permissions to this project.')
  if(response.data.statusCode === '400') {
    if(response.data.statusMessage === 'error' && response.data.body && response.data.body.statusMessage && response.data.body.statusMessage.includes('Error when getting a Project object')){
      throw new Error('User does not have permissions to this project.')
    }
    if(response.data.statusMessage && response.data.statusMessage.includes("Project can't be found")){
      throw new Error('User does not have permissions to this project.')
    }
  }
  if(response.data.statusCode !== '200') throw new Error (`Unhandled statusCode : ${response.data.statusCode}`)

  try{
    if("project" in response.data.body){
      return rectify(response.data.body.project)
    }
    else{
      throw new Error(`Expected to find project in response.data.body but did not.`)
    }
  }
  catch(err){
    err.message = `Unexpected data shape in "response": ${err.message}`
    err.response = response
    throw err
  }
}

function rectify(project){
  let P = project

  /** DEFAULTS **/

  // Added so when users have a manual rfq there is a default value
  //   here and one less thing for them to have to fill in
  // The value itself is arbitrary in this case (business logic driven)
  P.leadTimeRequest = P.leadTimeRequest ? P.leadTimeRequest : 5

  // The field is a select option field, so it must have a value
  // We default to FedEx because it's easier for the Shipping team to use FedEx
  P.shipProvider = P.shipProvider ? P.shipProvider : SHIP_PROVIDER_OPTIONS.FEDEX

  // These field are used for custom shipping, and we want their defaults to be of the right type (number, string, bool)
  P.customShippingDays = P.customShippingDays ? P.customShippingDays : 0
  P.customShippingMethod = P.customShippingMethod ? P.customShippingMethod : ''
  P.isCustomShippingMethodUsed = P.isCustomShippingMethodUsed ? P.isCustomShippingMethodUsed : false

  // the recalculate service inconsistently appends 'calculations' the project
  // this makes sure they exist from the beginning
  P.calculations = P.calculations ? P.calculations : {}
  P.calculations.leadTimePrices = P.calculations.leadTimePrices ? P.calculations.leadTimePrices : []

  P.partGroups.map(
    ptg => ptg.productionToolPartConfigurations.map(
      ptpc => {
        // add attachments until we integrate with ticket ATV-0726 which will also hook up to a backend that has this data model
        ptpc.attachments = ptpc.attachments ? ptpc.attachments : []

        // empty text fields should be empty strings (come in as null sometimes)
        ptpc.color = ptpc.color ? ptpc.color : ''
        ptpc.notes = ptpc.notes ? ptpc.notes : ''
      }
    )
  )

  P.partGroups.forEach(ptg => {
    ptg.part.dmeMarkedAtRiskPartBool = typeof ptg.part.dmeMarkedAtRiskPartBool === "boolean" ? ptg.part.dmeMarkedAtRiskPartBool : false
    ptg.overmoldedInserts = ptg.overmoldedInserts ? ptg.overmoldedInserts : []
    ptg.postProcesses = ptg.postProcesses ? ptg.postProcesses : []
    ptg.tolerances = ptg.tolerances ? ptg.tolerances : []
  })

  P.partGroups = rectifyManyToolFields(P.partGroups)

  return P
}

function rectifyManyToolFields(partGroups){
  return partGroups.map(partGroup => {
    partGroup.part = rectifyToolFields(partGroup.part)
    return partGroup
  })
}

function rectifyToolFields(part){
  // rectification: ensure empty data is represented as an empty array
  part.cncRoughingTools = part.cncRoughingTools ? part.cncRoughingTools : []
  part.cncFinishingTools = part.cncFinishingTools ? part.cncFinishingTools : []

  part.imRoughingTools = part.imRoughingTools ? part.imRoughingTools : []
  part.imFinishingTools = part.imFinishingTools ? part.imFinishingTools : []

  return part
}
