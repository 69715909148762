import config from "../../config"
const axios = require('axios')
const logUnexpected = require('../../utils/logUnexpected.js')

export async function createProjectAsCustomer(customerAppQuoteType, userEmail, customerFirstName, customerLastName){
  return createProject({
    customerEmail: userEmail,
    customerFirstName,
    customerLastName,
    customerAppQuoteType,
  })
}

export async function createProjectAsDme(newProjectFields){
  return createProject({
   customerAppQuoteType: '3dPrinting',
    ...newProjectFields
  })
}

async function createProject(requestFields){
  let response
  try{
    response = await createProjectRequest(requestFields)
  }
  catch(err){
    logUnexpected(err, { requestFields }, "createProjectRequest")
    throw err
  }

  let parsedResponse
  try{
    parsedResponse = createProjectParser(response)
  }
  catch(err){
    logUnexpected(err, { response }, "createProjectParser", { requestFields })
    throw err
  }

  return parsedResponse
}

async function createProjectRequest(requestFields){
  let requestBody = {
    token: config.MICROSERVICE_TOKEN,
    "contactedThrough": "Customer Web App",
    "type": "not-customer-app",
    "projectManager": "5a8468431711945b357128de",
    "isDmeReviewed": true,
    ...requestFields
  }
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.CREATE_PROJECT_ENDPOINT,
    data: requestBody
  })
  return {data: response.data, status: response.status}
}

function createProjectParser(response){
  if(response.status === 200){
    if(response.data.body.project.projectNumber){
      let projectID = response.data.body.project.projectNumber
      return projectID
    }
    // if project number is not found (unexpected data shape)
    let unexpectedStatusError = new Error(`Project number is not found (unexpected data shape)`)
    unexpectedStatusError.responseBody = response.data.body
    throw unexpectedStatusError
  }

  // throw if not 200
  let unexpectedStatusError = new Error(`Unexpected status code: ${response.status}`)
  unexpectedStatusError.responseBody = response.data.body
  throw unexpectedStatusError
}
