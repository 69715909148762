const { logUnexpected } = require('../../../../utils/logUnexpected.js')
const axios = require('axios')
const HTTP_ERROR_CODES = require('../../../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../../../utils/throwErrorParsedFromKnownStatusCode.js')

export async function getTemporaryS3UploadUrl(fileName) {
  let response
  try {
    response = await getTemporaryS3UploadUrlRequest(fileName)
  } catch (err) {
    logUnexpected(err, { fileName }, 'getTemporaryS3UploadUrlRequest')
    throw err
  }

  let result
  try {
    result = parseGetTemporaryS3UploadUrlResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseGetTemporaryS3UploadUrlResponse', { fileName })
    throw err
  }

  return result
}

async function getTemporaryS3UploadUrlRequest(fileName) {
  const response = await axios({
    method: 'post',
    validateStatus: () => true,
    url: '/files/presign/upload',
    data: { fileName: fileName, },
  })

  return { data: response.data, status: response.status }
}

function parseGetTemporaryS3UploadUrlResponse(response) {
  if (response.status === 200) {
    if (response.data.success) {
      if (response.data.success.url && response.data.success.fields) {
        return { url: response.data.success.url, fields: response.data.success.fields }
      }
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
