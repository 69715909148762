import React, { useContext } from 'react';
import {CardElement,injectStripe} from 'react-stripe-elements';
import {Button} from '@material-ui/core'
import {SnackBarContext} from '../Common/SnackBarStore'
var validator = require("email-validator");

const CheckoutForm = ({billingEmail,billingName,saveToken,stripe}) => {
  const openSnackBarWithMessage = useContext(SnackBarContext)

  /*
    handleCardSubmit is called when validating card details
    createToken() converts information collected by Elements into a single-use token used in processStripePayment() API call
    If the token is created successfully it is passed to the parent component to be saved (saveToken(token))
  */
  const handleCardSubmit = (ev) => {
    ev.preventDefault()   // We don't want to let default form submission happen here, which would refresh the page.
    if(!billingName){
      openSnackBarWithMessage("Must provide Billing Name")
      return
    }
    if(!billingEmail){
      openSnackBarWithMessage("Must provide Billing Email")
      return
    }
    if(!validator.validate(billingEmail)){
      openSnackBarWithMessage("Please enter a valid Billing Email")
      return
    }
    stripe.createToken({type: 'card', name: billingName})  // documentation : https://stripe.com/docs/stripe-js/reference#stripe-create-token
    .then((result)=>{
      if(result.token){
        if(result.token.id){
          saveToken(result.token.id) // Pass token to parent to be saved
        }
        else{
          openSnackBarWithMessage("There was an error processing your card. Please reach out to us directly")
        }
      }
      else if(result.error){
        openSnackBarWithMessage(result.error.message)
      }
      else{
        openSnackBarWithMessage("There was an error processing your card. Please reach out to us directly")
      }
    })
  }

  return (
    <form onSubmit={handleCardSubmit}>
      <label>
        Card details
        <CardElement style={{fontSize: '18px'}} />
      </label>
      <div align="right">
        <Button color="secondary" style={{marginTop:'16px'}} variant="contained" type="submit">Next</Button>
      </div>
    </form>
  )
}

export default injectStripe(CheckoutForm);
