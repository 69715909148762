import DmeApprovalCheckbox from './DmeApprovalCheckbox.js'
import {NotesAtom} from "./partCardAtoms"
import USER_TYPES from '../../utils/USER_TYPES.js'

export default function DmeApprovedNotesAtom({
  disabled,
  isDmeReviewed,
  onChangeCheckbox,
  onChangeNotes,
  notes,
  user,
  ...otherNotesProps
}){
  return(
    <div style={{display: 'flex', alignItems: 'center', width: '100%' }}>
      <NotesAtom
        {...otherNotesProps}
        disabled={disabled}
        error={user.type === USER_TYPES.DME && !isDmeReviewed}
        onChange={onChangeNotes}
        value={notes}
      />
      {user.type === USER_TYPES.DME ?
       <DmeApprovalCheckbox
         isDmeReviewed={isDmeReviewed}
         onChangeCheckbox={onChangeCheckbox}
       />
       : null}
    </div>
  )
}

