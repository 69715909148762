import React from "react"
import { Button, InputAdornment, Tooltip, Typography } from "@material-ui/core"
import { RemoveCircle } from "@material-ui/icons"
import { NumberField } from "./NumberField"

// Specific tool options maintain minimal code duplication
// but also maintain consistency across multiple uses of Roughing/FinishingTools
export function RoughingTools(props){
  return(
    <Tools
      {...props}
      percentageLabel="Volume"
      percentageValue="volumePercentage"
      title="Roughing Tool"
    />
  )
}

export function FinishingTools(props){
  return(
    <Tools
      {...props}
      percentageLabel="Surface Area"
      percentageValue="surfaceAreaPercentage"
      title="Finishing Tool"
    />
  )
}

export default function Tools(props){
  const {
    addTool, deleteToolByIndex, onChange, tools, // passed in from parents
    percentageLabel, percentageValue, title, // static config in this file
  } = props

  const subtractFromTotal = (total, tool) => total-tool[percentageValue]
  const noToolPercentageRaw = tools.reduce(subtractFromTotal, 100)
  const noToolPercentage = roundToXDecimals(noToolPercentageRaw, 10)

  return (
    <div>
      <Typography variant="subtitle1"> {title}s </Typography>
      <div className="tools">
        <NoToolPercentage
          percentageLabel={percentageLabel}
          noToolPercentage={noToolPercentage}
        />
        {tools.map(
          (tool, index) =>
            <ToolFields
              deleteToolHandler={() => deleteToolByIndex(index)}
              key={index}
              number={+index+1}
              onChange={e => onChange(e, index)}
              percentageLabel={percentageLabel}
              percentageValue={percentageValue}
              title={title}
              tool={tool}
            />
          )
        }
        <Button onClick={() => addTool(noToolPercentage)} variant='contained'>
          + Add a Tool
        </Button>
      </div>
    </div>
  )
}

function NoToolPercentage(props){
  const { percentageLabel, noToolPercentage } = props

  return (
    <NumberField
      disabled={true}
      InputProps={{
        endAdornment: <InputAdornment children="%" position="end"/>
      }}
      label={`No Tool ${percentageLabel}`}
      styles={{width:"10em"}}
      value={noToolPercentage}
    />
  )
}

function ToolFields(props){
  const { deleteToolHandler, onChange, number, percentageLabel, percentageValue, title, tool } = props

  return (
    <div className="tools-tool-fields">
      <NumberField
        label={`Tool ${number} Diameter`}
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="diameter"
        onChange={onChange}
        styles={{width:"5em"}}
        value={tool.diameter}
      />
      <NumberField
        label={`Tool ${number} ${percentageLabel} %`}
        InputProps={{
          endAdornment: <InputAdornment children="%" position="end"/>
        }}
        name={percentageValue}
        onChange={onChange}
        styles={{width:"9em"}}
        value={tool[percentageValue]}
      />
        <Tooltip title={`Delete ${title} ${number}`}>
          <Button className="tools-delete-button">
            <RemoveCircle
              onClick={deleteToolHandler}
            />
          </Button>
        </Tooltip>
    </div>
  )
}

function roundToXDecimals(val, decimalPlaces){
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.round(val * multiplier) / multiplier
}

