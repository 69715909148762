const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function getProjectSummaryPdf(project){
  let response
  try{
    response = await getProjectSummaryPdfRequest(project)
  } catch (err){
    logUnexpected(err, { project }, 'getProjectSummaryPdfRequest')
    throw err
  }

  let result
  try{
    result = parseGetProjectSummaryPdfResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseGetProjectSummaryPdfResponse', { project })
    throw err
  }

  return result
}

function getProjectSummaryPdfRequest(project){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/documents/create/summary',
    data: {
      project
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseGetProjectSummaryPdfResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.base64Pdf){
      return response.data.base64Pdf
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
