import React,{Fragment,useState} from 'react'
import {CheckoutForm} from '.'
import {Elements,StripeProvider} from 'react-stripe-elements'
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import createFile from '../../utils/createFile.js'
import deleteFile from '../../utils/deleteFile.js'
import downloadFromS3AndSaveFile from '../../utils/downloadFromS3AndSaveFile.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
import { sleep } from '../../utils/util.js'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { uploadFileToS3 } from '../QuoteTool/cloudObjectStorage/uploadFileToS3'

export const PaymentInformationExpansionDetails = (props) => {
  return(
    <div style={{width:'100%'}}>
      <PaymentMethodRadioGroup
        paymentMethod={props.paymentMethod}
        setPaymentMethod={props.setPaymentMethod}
      />

      <CreditTermsMaybe
        project={props.project}
        user={props.user}
      />

      <BillingInfo
        billingFieldsToHighlight={props.billingFieldsToHighlight}
        nextStepHandler={props.nextStepHandler}
        paymentMethod={props.paymentMethod}
        paymentPO={props.paymentPO}
        project={props.project}
        saveTokenHandler={props.saveTokenHandler}
        setPaymentPO={props.setPaymentPO}
        setProjectFieldAndSave={props.setProjectFieldAndSave}
        stripe={props.stripe}
        user={props.user}
      />
    </div>
  )
}

const PaymentMethodRadioGroup = ({
  paymentMethod,
  setPaymentMethod,
}) => {
  return(
    <>
      <span style={{fontWeight:'bold'}}>Payment Method</span><br/>
      <FormControl component="fieldset" style={{marginBottom:'10px'}}>
        <RadioGroup
          value={paymentMethod}
          onChange={ev=>setPaymentMethod(ev.target.value)}
        >
          <FormControlLabel value="po" control={<Radio />} label="Purchase Order" />
          <FormControlLabel value="credit" control={<Radio />} label="Credit Card" />
        </RadioGroup>
      </FormControl>
    </>
  )
}

const CreditTermsMaybe = ({
  project,
  user,
}) => {
  return(
    user.type === USER_TYPES.DME ?
      <div style={{background: 'lightgray', padding: '0.5em'}}>
        <Typography>
          Company Credit Terms: {project.customer.company ? project.customer.company.creditTerms : 'No Company'}<br/>
        </Typography>
        <Typography>
          Company Credit Limit: {project.customer.company ? project.customer.company.creditLimit : 'No Company'}
        </Typography>
      </div>
    : null
  )
}

const BillingInfo = ({
  billingFieldsToHighlight,
  nextStepHandler,
  paymentMethod,
  paymentPO,
  project,
  saveTokenHandler,
  setPaymentPO,
  setProjectFieldAndSave,
  stripe,
  user,
}) => {
  return(
    <Fragment><br/>
          <TextField
            error={billingFieldsToHighlight.name}
            id="billingName"
            label="* Billing Name"
            onChange={ev=>setProjectFieldAndSave("billingName",ev.target.value)}
            style={{marginBottom: '15px', minWidth:'220px', width:'50%'}}
            value={project.billingName}
            variant="outlined"
          /><br/>
          <TextField
            error={billingFieldsToHighlight.email}
            id="billingEmail"
            label="* Billing Email"
            onChange={ev=>setProjectFieldAndSave("billingEmail",ev.target.value)}
            style={{marginBottom: '15px', minWidth:'220px', width:'50%'}}
            value={project.billingEmail}
            variant="outlined"
          /><br/>
          <PaymentMethod
            billingFieldsToHighlight={billingFieldsToHighlight}
            nextStepHandler={nextStepHandler}
            paymentMethod={paymentMethod}
            paymentPO={paymentPO}
            project={project}
            saveTokenHandler={saveTokenHandler}
            setPaymentPO={setPaymentPO}
            setProjectFieldAndSave={setProjectFieldAndSave}
            stripe={stripe}
            user={user}
          />
        </Fragment>
  )
}

const PaymentMethod = ({
  billingFieldsToHighlight,
  nextStepHandler,
  paymentMethod,
  paymentPO,
  project,
  saveTokenHandler,
  setPaymentPO,
  setProjectFieldAndSave,
  stripe,
  user,
}) => {
  return (
    user.type === USER_TYPES.DME ?
      <DmePaymentMethod
        billingFieldsToHighlight={billingFieldsToHighlight}
        nextStepHandler={nextStepHandler}
        paymentPO={paymentPO}
        project={project}
        setPaymentPO={setPaymentPO}
        setProjectFieldAndSave={setProjectFieldAndSave}
        user={user}
      />
    :
    <CustomerPaymentMethod
      billingFieldsToHighlight={billingFieldsToHighlight}
      nextStepHandler={nextStepHandler}
      paymentMethod={paymentMethod}
      paymentPO={paymentPO}
      project={project}
      saveTokenHandler={saveTokenHandler}
      setPaymentPO={setPaymentPO}
      setProjectFieldAndSave={setProjectFieldAndSave}
      stripe={stripe}
      user={user}
    />
  )
}

const DmePaymentMethod = ({
  billingFieldsToHighlight,
  nextStepHandler,
  paymentPO,
  project,
  setPaymentPO,
  setProjectFieldAndSave,
  user,
}) => {
  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}>
        <TextField
          error={billingFieldsToHighlight.paymentReferenceNumber}
          id="paymentReferenceNumber"
          label="* Payment Reference Number"
          onChange={ev=>setProjectFieldAndSave("paymentReferenceNumber",ev.target.value)}
          style={{marginBottom: '15px', minWidth:'220px', width:'50%'}}
          value={project.paymentReferenceNumber}
          variant="outlined"
        />
        <PoSection
          billingFieldsToHighlight={billingFieldsToHighlight}
          paymentPO={paymentPO}
          poFile={project.poFile}
          projectNumber={project.projectNumber}
          setPaymentPO={setPaymentPO}
          setProjectFieldAndSave={setProjectFieldAndSave}
          user={user}
        />
      </div>
      <div align="right">
        <Button color="secondary" onClick={()=>{setTimeout(()=>{nextStepHandler()}, 175)}} variant="contained">
          Next
        </Button>
      </div>
    </>
  )
}

const CustomerPaymentMethod = ({
  billingFieldsToHighlight,
  nextStepHandler,
  paymentMethod,
  paymentPO,
  project,
  saveTokenHandler,
  setPaymentPO,
  setProjectFieldAndSave,
  stripe,
  user,
}) => {
  return ((paymentMethod === 'po' ?
           <PaymentMethodPoNumber
             billingFieldsToHighlight={billingFieldsToHighlight}
             nextStepHandler={nextStepHandler}
             paymentPO={paymentPO}
             project={project}
             setPaymentPO={setPaymentPO}
             setProjectFieldAndSave={setProjectFieldAndSave}
             user={user}
           />
           :
           <div style={{marginBottom:'16px', border:'2px', borderColor:'#E0E0E0', borderStyle:'solid', borderRadius:'5px', padding:'16px'}}>
             <StripeProvider stripe={stripe}>
               <Elements>
                 <CheckoutForm
                   billingName={project.billingName}
                   billingEmail={project.billingEmail}
                   saveToken={saveTokenHandler}
                 />
               </Elements>
             </StripeProvider>
           </div>
          )
         )
}

const PaymentMethodPoNumber = ({
  billingFieldsToHighlight,
  nextStepHandler,
  paymentPO,
  project,
  setPaymentPO,
  setProjectFieldAndSave,
  user,
}) => {
  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}>
        <TextField
          error={billingFieldsToHighlight.po}
          id="paymentPO"
          label="* PO #"
          onChange={ev=>setPaymentPO(ev.target.value)}
          style={{minWidth:'220px', width:'50%'}}
          value={paymentPO}
          variant="outlined"
        />
        <PoSection
          billingFieldsToHighlight={billingFieldsToHighlight}
          paymentPO={paymentPO}
          poFile={project.poFile}
          projectNumber={project.projectNumber}
          setPaymentPO={setPaymentPO}
          setProjectFieldAndSave={setProjectFieldAndSave}
          user={user}
        />
      </div>
      <div align="right">
        <Button color="secondary" onClick={()=>{setTimeout(()=>{nextStepHandler()}, 175)}} style={{marginTop: '15px',}} variant="contained">
          Next
        </Button>
      </div>
    </>
  )
}

const PoSection = ({
  billingFieldsToHighlight,
  paymentPO,
  poFile,
  projectNumber,
  setPaymentPO,
  setProjectFieldAndSave,
  user,
}) => {
  const downloadPoPdf = () => downloadFromS3AndSaveFile(poFile.s3Id, poFile.fileName)
  const removePoPdf = async () => {
    await deleteFile(poFile.fileId)
    setProjectFieldAndSave('poFile', null)
  }
  const uploadPoPdf = async file => {
    const s3Id = await uploadFileToS3(file)
    const poFileTemplate = {
      fileName: file.name,
      fileType: 'po',
      isActive: true,
      s3Id,
      projectId: projectNumber,
      userUploaded: user.email,
    }
    const poFile = await createFile(poFileTemplate)
    setProjectFieldAndSave('poFile', poFile)
  }

  return(
    <>
      <DeleteIconWithTooltip
        downloadPoPdf={downloadPoPdf}
        poFile={poFile}
        removePoPdf={removePoPdf}
        uploadPoPdf={uploadPoPdf}
      />
    </>
  )
}

const DeleteIconWithTooltip = ({ downloadPoPdf, poFile, removePoPdf, uploadPoPdf }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  let inputRef

  const upload = async (file) => {
    setIsUploading(true)
    await uploadPoPdf(file)
    setIsUploading(false)
  }
  const download = async () => {
    setIsDownloading(true)
    await downloadPoPdf()
    setIsDownloading(false)
  }
  const remove = async () => {
    setIsDeleting(true)
    await removePoPdf()
    setIsDeleting(false)
  }

  return(
    poFile ?
      <Chip
        deleteIcon={isDeleting ? <CircularProgress size={20}/> : <CancelIcon/>}
        disabled={isDeleting || isDownloading}
        icon={isDownloading ? <CircularProgress size={20}/> : <InsertDriveFileIcon/>}
        label={<Typography style={{ paddingLeft: "0.25em", paddingRight: "0.5em" }}>{poFile.fileName}</Typography>}
        onClick={() => download()}
        onDelete={() => remove()}
        style={{height: '48'}}
      />
    :
    <>
      <input
        onChange={e => upload(e.target.files[0])}
        type="file"
        hidden={true}
        ref={refParam => inputRef = refParam}
      />
      { isUploading ?
        <div>
          <Typography> Uploading PO... </Typography>
          <LinearProgress color="primary"/>
        </div>
        :
        <Button
          onClick={() => inputRef.click()}
          variant='contained'
        >
          Upload PO
        </Button>
      }
    </>
  )
}
