export const PROJECT_MANAGERS = [
  { id: '5bc60da29c479c781c108d00', name: 'Andrew Johnson', email:'andrew.johnson@autotiv.com' },
  { id: '60c750b88602ea001e1db89d', name: 'Alex Behr', email:'alex@autotiv.com' },
  { id: '62b05bd7e887a5001e54cdb0', name: 'Alex Watson', email:'alex.watson@autotiv.com' },
  { id: '5ed16143f0a7520015ab85cc', name: 'Brad', email:'brad@autotiv.com' },
  { id: '5a6b69ab23e1715827b0bf5b', name: 'Chris', email:'chris@autotiv.com' },
  { id: '5fc661f70c7be1001bd9da7a', name: 'Connor', email:'connor@autotiv.com' },
  { id: '5acadb2b8410d71fd0348234', name: 'Coltin', email:'coltin@autotiv.com' },
  { id: '5c7419a5de0e3f085c2704b4', name: 'Dylan', email:'dylan@autotiv.com' },
  { id: '5a6b6ae6f5e57659653241dd', name: 'Evan', email:'evan@autotiv.com' },
  { id: '6239ceeff98fc2001f325c81', name: 'Justin', email:'justin@autotiv.com' },
  { id: '621cda6b9b897d001e44cef4', name: 'Keegan', email:'keegan@autotiv.com' },
  { id: '5f0745773eeffc001abe2d3b', name: 'Kevin', email:'kevin@autotiv.com' },
  { id: '64b9763d0afdc30027167c28', name: 'Nick', email:'nick@autotiv.com' },
  { id: '5a8468431711945b357128de', name: 'Unassigned', email:'contact@autotiv.com' },
  { id: '621cda35b77b6d001fa41240', name: 'Rachel', email:'rachel@autotiv.com' },
  { id: '5e67b77e8de5a600155f0c57', name: 'Tim', email: 'tim@autotiv.com' },
  { id: '60a57aabc3ada6001ea4d63d', name: 'Taylor', email:'taylor@autotiv.com' },
  { id: '64261030b68a6000273364c3', name: 'Weston', email:'weston@autotiv.com' },
  { id: '5e72549962d439001603b3f3', name: 'Will', email:'will@autotiv.com' },
  { id: '6023ffccb8b839001bb96fbe', name: 'Bridgett', email:'bridgett@autotiv.com' },
  { id: '5e72549962d439001603b3f3', name: 'Mark', email:'mark@autotiv.com' },
  { id: '61eded46c92cfa893218c032', name: 'Marcus', email:'marcus@autotiv.com' }
]
