import {
  Checkbox,
  FormControl,
} from "@material-ui/core"
import HtmlTooltip from './HtmlTooltip.js'

const AtRiskPromptAtom = ({ manufacturabilityIssues, onChange, part, isCondensed }) => {
  return(
    <div>
      <HtmlTooltip delay={0} placement="bottom" /* preferences bottom */ title={ <RiskAcceptanceWarning manufacturabilityIssues={manufacturabilityIssues}/> }>
        <div> { /* needs div for hitbox; cant be a Fragment */ }
        <FormControl variant="outlined">
          {isCondensed ?
           // subtle differences between the two (styles to start) is why this isn't duplicate code
           <div style={{display: 'flex', alignItems: 'center', gap: '0.5em'}}>
             <span style={{ color: "red", textAlign: "center" }}>Accept Risk:</span>
             <Checkbox
               style={{color:'red', padding: '0'}}
               onChange={(e)=>{onChange(e,part.partGroupNumber)}}
               onClick={e=>{e.stopPropagation()}}
               name='riskAccepted'
               checked={part.part.riskAccepted}
             />
           </div>
           :
           <>
             <Checkbox
               style={{color:'red'}}
               onChange={(e)=>{onChange(e,part.partGroupNumber)}}
               onClick={e=>{e.stopPropagation()}}
               name='riskAccepted'
               checked={part.part.riskAccepted}
             />
             <span style={{ color: "red", textAlign: "center" }}>Accept Risk</span>
           </>
          }

        </FormControl>
        </div>
      </HtmlTooltip>
    </div>
  )

  function RiskAcceptanceWarning({manufacturabilityIssues}) {
    return (
      <>
        <span style={{ color: "red", fontWeight: "bold" }}>
          Manufacturability Issues:
          <br/>

          <ul style={{paddingLeft: '1.5em', marginTop: '0em'}}>
            { manufacturabilityIssues.map(issue => {
              return (
                <li key={issue.text}>{issue.text}
                  { issue.subIssues ?
                    <ul>
                      {issue.subIssues.map(subIssue => <li>{subIssue}</li>)}
                    </ul>
                    : null
                  }
                </li>
              )
            })}
          </ul>
        </span>
        Please fix the above issues or accept the risk that the Manufacturability Issues on this part will not be covered by Autotiv's quality guarantee
      </>
    )
  }
}

export default AtRiskPromptAtom
