import { TO_FROM_TYPES } from '../supplierTypeArrays.js'

export function calculateTotal(partGroupNumber, boxes){
  return boxes.reduce(boxesToTotal, 0)

  function boxesToTotal(total, box){
    const partInBox = box.partsInBox.find(pt => pt.partGroupNumber === partGroupNumber)
    const amountToAdd = partInBox && partInBox.quantity ? partInBox.quantity : 0

    total = +total + +amountToAdd
    return total
  }
}

export function calculatePtpcTotal(ptpcId, boxes){
  return boxes.reduce(boxesToTotal, 0)

  function boxesToTotal(total, box){
    const partInBox = box.partsInBox.find(pt => pt.ptpcId === ptpcId)
    const amountToAdd = partInBox && partInBox.quantity ? partInBox.quantity : 0

    total = +total + +amountToAdd
    return total
  }
}

export function calculateLeftToBox(boxes, partGroupNumber, project, shipmentId){
  const totalToShip = project.partGroups.find(pg => pg.partGroupNumber === partGroupNumber).quantity
  const totalAlreadyShipped = calculateTotalAlreadyShipped(project.shipments, shipmentId, partGroupNumber)
  const totalInBoxes = calculateTotalInBoxes(boxes, partGroupNumber)

  return totalToShip - totalAlreadyShipped - totalInBoxes

}

export function calculatePtpcLeftToBox(boxes, ptpcId, project, shipmentId){
  const totalToShip = project.partGroups
        .flatMap(ptg => ptg.productionToolPartConfigurations)
        .find(ptpc => {
          return ptpc.productionToolPartConfigurationId === ptpcId
        })
        .quantity

  const totalAlreadyShipped = calculateTotalPtpcsAlreadyShipped(project.shipments, shipmentId, ptpcId)
  const totalInBoxes = calculateTotalPtpcsInBoxes(boxes, ptpcId)

  return totalToShip - totalAlreadyShipped - totalInBoxes
}

function calculateTotalAlreadyShipped(shipments, shipmentId, partGroupNumber){
  const shipmentsBesidesThisOneGoingToCustomer = shipments
        .filter(shipment => shipment.id !== shipmentId)
        .filter(shipment => shipment.shipmentTo === TO_FROM_TYPES.customer)

  return shipmentsBesidesThisOneGoingToCustomer.reduce(shipmentsToTotalShipped, 0)

  function shipmentsToTotalShipped(total, shipment){
    const partsInThisShipment = shipment.partBatches
          .find(partBatch => partBatch.partGroup === partGroupNumber)
          .quantity

    return +total + +partsInThisShipment
  }
}

function calculateTotalPtpcsAlreadyShipped(shipments, shipmentId, ptpcId){
  const shipmentsBesidesThisOneGoingToCustomer = shipments
        .filter(shipment => shipment.id !== shipmentId)
        .filter(shipment => shipment.shipmentTo === TO_FROM_TYPES.customer)

  return shipmentsBesidesThisOneGoingToCustomer.reduce(shipmentsToTotalShipped, 0)

  function shipmentsToTotalShipped(total, shipment){
    const partsInThisShipment = shipment.ptpcPartBatches
          .find(ptpcPartBatch => ptpcPartBatch.productionToolPartConfigurationId === ptpcId)
          .quantity

    return +total + +partsInThisShipment
  }
}

function calculateTotalInBoxes(boxes, partGroupNumber){
  return boxes.reduce(boxesToTotalBoxed, 0)

  function boxesToTotalBoxed(total, box){
    const totalBoxed = box.partsInBox
          .find(partInBox => partInBox.partGroupNumber === partGroupNumber)
          .quantity

    return +total + +totalBoxed
  }
}

function calculateTotalPtpcsInBoxes(boxes, ptpcId){
  return boxes.reduce(boxesToTotalBoxed, 0)

  function boxesToTotalBoxed(total, box){
    const boxMaybe = box.partsInBox
          .find(partInBox => partInBox.ptpcId === ptpcId)
    const totalBoxed = boxMaybe ?  boxMaybe.quantity : 0

    return +total + +totalBoxed
  }
}
