/*
  TranslateShipMethodToShipCost takes a ship method and matches it with an associated delivery cost
*/
export function TranslateShipMethodToShipCost(shipMethod, shippingOptions, isCustomShippingMethodUsed) {
    if (shippingOptions) {
      let shipOptionSelected = shippingOptions.find(option => {
        const method = isCustomShippingMethodUsed ? 'custom' : shipMethod
        return (option.shipMethod === shipMethod)
      })
      return shipOptionSelected ? shipOptionSelected.cost : 0
    }
    else {
      return 0
    }
}
