import {Checkbox} from '@material-ui/core'

export default function DmeApprovalCheckbox({
  isDmeReviewed,
  onChangeCheckbox,
}){
  return(
    <>
      {isDmeReviewed ?
       <Checkbox
         checked={Boolean(isDmeReviewed)}
         color="primary"
         name="isDmeReviewed"
         onChange={onChangeCheckbox}
       />
       :
       <Checkbox
         checked={Boolean(isDmeReviewed)}
         name="isDmeReviewed"
         onChange={onChangeCheckbox}
         style={{color: 'red'}}
       />
      }
      <div style={{width: '10em', color: isDmeReviewed ? '' : 'red'}}>
        DME Approved
      </div>
    </>
  )
}
