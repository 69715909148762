import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function createPart(part) {
  let result
  let response
  try {
    response = await createPartRequest(part)
  } catch (err) {
    logUnexpected(err, { part }, "createPartRequest")
    throw err
  }
  try {
    result = parseCreatePartResponse(response)
  } catch (err) {
    if (false /* add retries here; check error-type & retryCounter */) {
      // retry stub
      // RECURSION
      // return await createPart(part, retryCounter)
    } else {
      // log & rethrow
      logUnexpected(err, { response }, "parseCreatePartResponse", { part })
      throw err
    }
  }
  return result
}

function createPartRequest(part) {
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: config.CREATE_PART_ENDPOINT,
        data: {
          "token": config.MICROSERVICE_TOKEN,
          "part": part
        }
    })
      .then(val => ({data: val.data, status: val.status}))
}

function parseCreatePartResponse(response) {
  if (response.status === 200) {
    let part
    try {
      part = response.data.part
      if ( response.data.part.partNumber === undefined) {
        throw Error('Part has no part number')
      } else {
        return part
      }
    } catch (err) {
      throw Error(`Failed to get part: unexpected reason ${err.message}`)
    }
  } else {
    throw Error(`Unexpected Status Code: ${JSON.stringify(response.status)}`)
  }
}

