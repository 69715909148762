import React,{useContext} from "react"
import { CircularProgress, TextField, Tooltip, Typography } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import { NumberField } from "../../NumberField.js"
import { SelectOption } from "../../SelectOption"
import {SnackBarContext} from '../../../Common/SnackBarStore.js'
import { DeleteButtonAtom } from "../DeleteButtonAtom"
import USER_TYPES from '../../../../utils/USER_TYPES.js'

export const ToleranceRow = ({ deleteTolerance, isCalculatingPrices, tolerance, onChange, toleranceClassification, drawingUnits, user }) => {
  const openSnackBarWithMessage = useContext(SnackBarContext)
  const classificationType = CLASSIFICATION_TYPES.find(ct => ct.value === toleranceClassification.classification)

  const copyTolerancesToClipboard = () => {
    if(classificationType.value === "noBid" && user.type === USER_TYPES.DME){
      const textToCopy = `Revised tolerance:
${tolerance.nominal} +${Number(toleranceClassification.revisedUpperBound).toFixed(4)}/-${Number(toleranceClassification.revisedLowerBound).toFixed(4)}`

      navigator.clipboard.writeText(textToCopy)
      openSnackBarWithMessage(`Text copied to clipboard: ${textToCopy}`)
    }
  }

  const onChangeQuantity = e => {
    const integer = e.target.value.replace(/[^0-9]/g, "") // ensure this number is an integer
    e.target.value = integer
    onChange(e)
  }

  return(
    <div className="tolerance-row-div">
      <TextField
        label="Feature #"
        name="featureNumber"
        onChange={onChange}
        value={tolerance.featureNumber}
      />

      <NumberField
        label="Quantity"
        name="quantity"
        onChange={onChangeQuantity}
        value={tolerance.quantity}
      />

      <SelectOption
        label="Tolerance Type"
        name="type"
        onChange={onChange}
        value={tolerance.type}
        styles={{minWidth:"9em"}}
      >
        {Object.entries(TOLERANCE_TYPES).map(([key,type]) => <option value={type.value} key={key}>{type.label}</option> )}
      </SelectOption>

      <NumberField
        label={`Nominal (${drawingUnits})`}
        name="nominal"
        onChange={onChange}
        value={tolerance.nominal}
      />

      <NumberField
        label={`+ Tolerance (${drawingUnits})`}
        name="upperBound"
        onChange={onChange}
        value={tolerance.upperBound}
      />

      <NumberField
        label={`- Tolerance (${drawingUnits})`}
        name="lowerBound"
        onChange={onChange}
        value={tolerance.lowerBound}
      />

      <div style={{minWidth:"10em"}}>
        <Typography>
          Classification
        </Typography>
        {isCalculatingPrices ? <CircularProgress size="1em" /> :
         !classificationType ? '--' :
         <ClassificationIcon
           classificationType={classificationType}
           copyTolerancesToClipboard={copyTolerancesToClipboard}
           toleranceClassification={toleranceClassification}
         />
        }
      </div>

      <DeleteButtonAtom deleteHandler={deleteTolerance} tooltipTitle={"Delete this tolerance"}/>
    </div>
  )
}

function ClassificationIcon({ classificationType, copyTolerancesToClipboard, toleranceClassification }){
  return(
    <Tooltip
      disableHoverListener={classificationType.value !== "noBid"}
      onClick={copyTolerancesToClipboard}
      title={<Typography>{getHelpMessage(toleranceClassification, classificationType.label)}</Typography>}
    >
      <span style={{color:classificationType.color, display: 'flex', alignItems:'center', gap:'0.5em'}}>
        {classificationType.label}
        {classificationType.value === "noBid" ? <Info/> : null}
      </span>
    </Tooltip>
  )
}

function getHelpMessage(toleranceClassification, label){
  return (toleranceClassification.revisedLowerBound>0 || toleranceClassification.revisedUpperBound>0) ?
    (<div>
       Revised Lower Bound: {toleranceClassification.revisedLowerBound.toFixed(4)}<br/>
       Revised Upper Bound: {toleranceClassification.revisedUpperBound.toFixed(4)}
     </div>)
  :
    <div>
      {label}
    </div>
}

const TOLERANCE_TYPES = {
  DIMENSIONAL: {label:"Dimensional",value:"dimensional"},
  // ANGULAR: {label:"Angular(°)",value:"angular"},
  // FLATNESS: {label:"Flatness",value:"flatness"}
  GEOMETRIC: {label:"Geometric",value:"geometric"},
}

export const CLASSIFICATION_TYPES = [
  {label:"Standard Precision", value:"standard", color:"green"},
  {label:"Medium Precision", value:"medium", color:"blue"},
  {label:"High Precision", value:"tight", color:"orange"},
  {label:"No Bid", value:"noBid", color:"red"},
  {label:"Pending", value:"pending", color:"black"},
]
