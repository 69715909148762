import React, { Component } from 'react'
import {LoadingPage,NotFound} from '../Common'
import {
  loadProject,
  partAnalysisStatus,
  updatePartGroupsPromise,
  updateProjectPromise,
} from '../../utils'
import { updateProjectWithAtRiskBool } from '../../utils/updateProjectWithAtRiskBool.js'
import updateProjectWithEasyAccessDataForKnackViews from './updateProjectWithEasyAccessDataForKnackViews.js'
import getToleranceClassifications from './getToleranceClassifications.js'
import { Button, Typography } from '@material-ui/core'
const {sendErrorEmail} = require('../../utils/util.js')
const {getPricingData} = require('../../utils/getPricingData.js')

export default function withProject(ProjectPage){
  return class ProjectInjectedPage extends Component {
    constructor(props){
      super(props)
      this.state = {
        checkingUserAccess: true,     // Default as still need to check access
        isCalculatingPrices: false,
        pauseCalculations: false,     // Allow DMEs to turn of calculations until all changes are done
        pricingData: {},
        project: {},
        reviewReasons: {              // Reasons for manual review. Used to track any issues that may arise from part or project requirements
          projectReasons:[],          // Tracks project reasons for manual review
          partsReasons:[]             // Tracks individual part reasons for review
        },
        toleranceClassifications: [],
        userCanAccessProject: false,  // User defaults to no project access
      }
    }

    loadProjectHandler = async (projectToLoad) => {
      // @frozen This should be abstracted to an withProject-HOC before making other code changes
      let loadedProject
      try{
        loadedProject = await loadProject(projectToLoad, this.props.user)
      } catch(e){
        if(e.message === 'User does not have permissions to this project.'){
          return this.setState({
            checkingUserAccess: false,
            userCanAccessProject: false,
          })
        }
        throw e
      }

      if(!this.props.canNavigateToPage(loadedProject)){
        return this.props.navigateToLandingPage(this.props.location.pathname, loadedProject)
      }

      this.setState({toleranceClassifications: []})
      getToleranceClassifications(loadedProject)
        .then(toleranceClassifications => this.setState({toleranceClassifications}))
        .then(() => 
      getPricingData(loadedProject)
        )
        .catch(err => {
          console.log({err})
          sendErrorEmail(err) // If there is some problem with the service notify dev
          this.props.openSnackBarHandler('There was a problem updating your project. Please refresh and try again in a few minutes or email us at contact@autotiv.com to get your quote!')
          throw new Error("There was a problem updating your project")
        })
        .then(({pricingData, reviewReasons}) => {
          this.handleNoDomesticSuppliersError(reviewReasons)
          this.setState({pricingData})
          this.setState({
            project: loadedProject,
            reviewReasons,
            userCanAccessProject: true
          })
        })
        .catch(err => {
          this.setState({
            userCanAccessProject: false
          })
        })
        .finally(() => this.setState({ checkingUserAccess: false }))
    }

    setProject = (projectToSet, config={}) => {
      const project = dataTransform(projectToSet, this.props.postProcessData.POST_PROCESS_OPTIONS)
      this.setState({ project }, () => {

        if (typeof config.callback === 'function'){
          config.callback(this.state.project)
        }

        const partGroupsToSave = config.savePartGroup ? [config.partGroupNumber] : []

        let prom
        if(config.dontRecalc){
          prom = Promise.resolve({ latestProject: this.state.project, latestPartGroups: this.state.project.partGroups, partGroupsToSave })
        } else {
          const isPartReadyForCalculation = ptg => {
            const {IS_MODEL, CAN_BE_ANALYZED, PARAM_EXTRACT_FINISHED} = partAnalysisStatus(ptg, this.props.postProcessData.POST_PROCESS_OPTIONS)
            return !CAN_BE_ANALYZED || (CAN_BE_ANALYZED && PARAM_EXTRACT_FINISHED)
          }
          const isProjectReadyForCalculation = project.partGroups.every(isPartReadyForCalculation)
          if(isProjectReadyForCalculation){
            if(config.savePartGroup){
              prom = this.recalculateAndReviewProjectPromise(this.state.project, config.partGroupNumber)
            } else {
              prom = this.recalculateAndReviewProjectPromise(this.state.project)
            }
          } else {
            prom = Promise.resolve({ latestProject: this.state.project, latestPartGroups: this.state.project.partGroups, partGroupsToSave })
          }
        }

        prom
          .then(({latestProject,latestPartGroups,partGroupsToSave}) => {

            if(config.saveProject){
              updateProjectPromise(latestProject, config.saveProjectDebounce)
            }

            if(config.savePartGroups){
              updatePartGroupsPromise(latestPartGroups)
            }

            if(config.savePartGroup){
              console.log({partGroupsToSave})
              partGroupsToSave.forEach(partGroupNumber => {
                const partGroupToSave = project.partGroups
                      .find(ptg => ptg.partGroupNumber === partGroupNumber)
                updatePartGroupsPromise(partGroupToSave)
              })
            }
          })
          .catch((err) => {
	    // If we throw an error while calculating the price, let's still
	    // save the original project
            if(config.saveProject){
              updateProjectPromise(project, config.saveProjectDebounce)
            }

            if(config.savePartGroups){
              updatePartGroupsPromise(project.partGroups)
            }

            if(config.savePartGroup){
              console.log({partGroupsToSave})
              partGroupsToSave.forEach(partGroupNumber => {
                const partGroupToSave = project.partGroups
                      .find(ptg => ptg.partGroupNumber === partGroupNumber)
                updatePartGroupsPromise(partGroupToSave)
              })
            }
	    throw err
          })
      })
    }

    recalculateAndReviewProjectPromise = (projectToSend, partGroupNumber) => {
      return new Promise((resolve, reject) => {
        this.setState({ isCalculatingPrices: true })

        this.setState({toleranceClassifications: []})
        captureSaveRequest(partGroupNumber)
        getToleranceClassifications(projectToSend, this.state.pauseCalculations)
          .then(toleranceClassifications => this.setState({toleranceClassifications}))
          .then(() => getPricingData(projectToSend, this.state.pauseCalculations))
          .then(({ isLatestUpdate, pricingData, reviewReasons }) => {
            if (!isLatestUpdate) {
              return resolve({latestProject:this.state.project, latestPartGroups:this.state.project.partGroups, partGroupsToSave:[partGroupNumber]}) // Return current project in state since it is a more recent state than this update request
            }

            this.handleNoDomesticSuppliersError(reviewReasons)
            this.setState({reviewReasons})

            this.setState({
              pricingData
            }, () => {
              const partGroupsToSave = getPartGroupsToSave()
              clearRequests()
              return resolve({latestProject:this.state.project,latestPartGroups:this.state.project.partGroups, partGroupsToSave})
            })

            this.setState({ isCalculatingPrices: false })
          })
          .catch(err => {
            this.setState({ isCalculatingPrices: false })
            sendErrorEmail(err) // If there is some problem with the service notify dev
            this.props.openSnackBarHandler(err.message)
            reject("There was a problem calculating a price for your project")
          })
      })
    }

    handleNoDomesticSuppliersError = (reviewReasons) => {
      const isNoDomesticSupplier = reviewReasons.projectReasons
            .some(text => text.includes('no valid domestic suppliers'))

      if(isNoDomesticSupplier && this.props.isQuoteConfigurationPage){
        this.props.openSnackBarHandler(
          <div style={{display:"flex", gap:"1em", alignItems:"center"}}>
            <Typography>
              Allow International Sourcing
            </Typography>
            <Button
              onClick={() => {
                const projectCopy = {...this.state.project}
                projectCopy.isDomesticSourcing = false
                this.setProject(projectCopy)

                this.props.closeSnackbar()
              }}
              size="small"
              variant="contained"
            >
              Remove Domestic Only
            </Button>
          </div>
        )
      }
    }

    setPauseCalculations = pauseCalculations => {
      this.setProject(this.state.project)
      this.setState({pauseCalculations})
    }

    render(){
      const { projectNumber, ...strippedProps } = this.props

      if (this.state.checkingUserAccess) {
        return (
          <LoadingPage
            loadProjectPromise={this.loadProjectHandler}
            pageType={'project'}
            projectNumber={projectNumber}
          />)
      }
      else {
        if(!this.state.userCanAccessProject){
          return (<NotFound/>)
        }
        else {
          return (
            <ProjectPage
              {...strippedProps}
              isCalculatingPrices={this.state.isCalculatingPrices}
              pauseCalculations={this.state.pauseCalculations}
              pricingData={this.state.pricingData}
              project={this.state.project}
              reviewReasons={this.state.reviewReasons}
              setPauseCalculations={this.setPauseCalculations}
              setProject={this.setProject}
              toleranceClassifications={this.state.toleranceClassifications}
            />
          )
        }
      }
    }
  }
}

let saveRequests = []
function captureSaveRequest(partGroupNumber){
  if(partGroupNumber){
    if(!saveRequests.includes(partGroupNumber)){
      saveRequests.push(partGroupNumber)
    }
  }
}

function clearRequests(){
  saveRequests = []
}

function getPartGroupsToSave(){
  return JSON.parse(JSON.stringify(saveRequests))
}

function dataTransform(project, POST_PROCESS_OPTIONS){
  /*
    updateProjectWithAtRiskBool updates the part.atRiskBool fields so
    that the backend will mark as manual rfq accordingly.
  */
  let updatedProject = updateProjectWithAtRiskBool(project, POST_PROCESS_OPTIONS)

  updatedProject = updateProjectWithEasyAccessDataForKnackViews(updatedProject)

  return updatedProject
}
