export default function splitPartsList(stringToEdit) {
  /*
    It takes a string from the backend that has embedded html line breaks (<br
    />) and splits the string into an array, dividing the elements where the
    line breaks were.  
    It also trims the array down to 10 elements for a cleaner display of the
    parts list on the page.  
    It then returns the sanitized array.
  */
  let partArray = stringToEdit.split('<br />')
  let secondPartArray = stringToEdit.split('\n')
  if(partArray.length < secondPartArray.length){
    partArray = secondPartArray
  }

  // get the number of parts in array
  let arrayCount = partArray.length
  // if the project has more than 10 parts
  if (arrayCount > 10) {
    // slice off the end of the array leaving only the first 10 to display
    partArray = partArray.slice(0, 10)
    // add a message stating how many more parts there are in the project
    partArray.push(`... ${arrayCount - 10} more parts`)
  }
  return partArray
}

