import React, { useState } from "react"
import { CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@material-ui/core"
import { NumberField } from "./NumberField"
import { RoughingTools, FinishingTools } from './Tools.js'
import { OvermoldingSection } from "./OvermoldingSection.js"
import { InjectionMoldingAnalysisFeedbackAtom } from './InjectionMoldingAnalysisFeedbackAtom.js'
import DmeApprovalCheckbox from './DmeApprovalCheckbox.js'

const DmeInjectionMolding = (props) => {
  return(
    <div className="dme-injection-molding">
      <div style={{display: 'flex', gap: '1em', alignItems:'center'}}>
        <Typography variant="h6" className="dme-section-header">
          Injection Molding Fields
        </Typography>
        <InjectionMoldingAnalysisFeedbackAtom
          partGroup={props.partGroup}
          postProcessData={props.postProcessData}
        />
      </div>
      <InjectionMoldingFields
        onChange={props.onChange}
        partGroup={props.partGroup}
      />
      <MoldDataMaybe
        isCalculatingPrices={props.isCalculatingPrices}
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        onChange={props.onChange}
        partGroup={props.partGroup}
        partGroupPrices={props.partGroupPrices}
        TOOL_MATERIALS={props.TOOL_MATERIALS}
      />
      <InjectionMoldingRoughingTools
        addTool={props.addTool}
        deleteTool={props.deleteTool}
        onChangeToolField={props.onChangeToolField}
        partGroup={props.partGroup}
      />
      <InjectionMoldingFinishingTools
        addTool={props.addTool}
        deleteTool={props.deleteTool}
        onChangeToolField={props.onChangeToolField}
        partGroup={props.partGroup}
      />
      <OvermoldingSection
        overmoldingCrud={props.crud.partGroups(props.partGroup.partGroupNumber).overmoldedInserts}
        inserts={props.partGroup.overmoldedInserts}
      />
    </div>
  )
}

function InjectionMoldingFields(props){
  const { onChange, partGroup } = props
  const onChangePartField = e => onChange(e, partGroup.partGroupNumber)

  return (
    <div className="dme-injection-molding-form">
      <NumberField
        label="# of Slides"
        name="numberOfSlides"
        onChange={onChangePartField}
        styles={{width:"8em"}}
        value={partGroup.part.numberOfSlides}
      />
      <NumberField
        label="# of Collapsing Cores"
        name="numberOfCollapsingCores"
        onChange={onChangePartField}
        styles={{width:"12em"}}
        value={partGroup.part.numberOfCollapsingCores}
      />
      <NumberField
        label="Average Wall Thickness"
        name="averageWallThickness"
        onChange={onChangePartField}
        styles={{width:"12em"}}
        value={partGroup.part.averageWallThickness}
      />
      <NumberField
        label="Standard Deviation in Wall Thickness"
        name="standardDeviationWallThickness"
        onChange={onChangePartField}
        styles={{width:"18em"}}
        value={partGroup.part.standardDeviationWallThickness}
      />
      <div style={{display: 'flex', alignItems: 'center'}}>
        <DmeApprovalCheckbox
          isDmeReviewed={partGroup.areImParamsDmeApproved}
          onChangeCheckbox={e => onChangePartField({ target: { name: "areImParamsDmeApproved", value: e.target.checked } })}
        />
      </div>
    </div>
  )
}

function MoldDataMaybe(props){
  const { isCalculatingPrices, MATERIAL_TRANSLATIONS, onChange, partGroup, partGroupPrices, TOOL_MATERIALS } = props
  const onChangePartField = e => onChange(e, partGroup.partGroupNumber)

  // New molds calculate this data, so it is not editable.
  // Existing molds use this data, so it is editable,
  //   the defaults are saved from the original calculation on project booking
  const isNewMold = partGroup.isOriginalPartGroup && !partGroup.isLegacyTool

  if(!partGroupPrices){
    // if there is a manual rfq then can't show the calculated data
    return null
  }
  const partGroupPrice = partGroupPrices
        .find(ptgp => ptgp.partGroupNumber === partGroup.partGroupNumber)

  const cavityCount = isNewMold ? (partGroupPrice ? partGroupPrice.cavityCount : null) : partGroup.cavityCount
  const materialId = isNewMold ? (partGroupPrice ? partGroupPrice.material : null) : partGroup.materialId

  if(partGroup.processId !== "injMoldingPart"){
    return null // only show on IM
  }
  return (
    <div>
      <Typography variant="subtitle1"><b> Mold Reorder Overrides </b></Typography>
       <div className="dme-injection-molding-form">
         <LegacyMoldData
           onChange={onChange}
           partGroup={partGroup}
         />
      {isCalculatingPrices && isNewMold ? <CircularProgress/> :
       <>
         <NumberField
           disabled={isNewMold}
           label="Cavity Count"
           name="cavityCount"
           onChange={onChangePartField}
           styles={{width:"8em"}}
           value={cavityCount > 0 ? cavityCount : ''}
         />
         <MoldMaterial
           disabled={isNewMold}
           MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
           onChange={onChangePartField}
           TOOL_MATERIALS={TOOL_MATERIALS}
           value={materialId}
         />
       </>
      }
       </div>
    </div>
  )
}

function LegacyMoldData(props){
  const { onChange, partGroup } = props
  const onChangePartField = e => onChange(e, partGroup.partGroupNumber)

  return (
    partGroup.isOriginalPartGroup ?
      <div>
        <div className="dme-injection-molding-form" style={{ height: '3em'}}>
          <FormControlLabel
            checked={Boolean(partGroup.isLegacyTool)}
            color="secondary"
            control={<Switch/>}
            disabled={!partGroup.isOriginalPartGroup}
            label="Is legacy tool"
            labelPlacement="start"
            onChange={e => {
              const target = {
                name: "isLegacyTool",
                value: e.target.checked,
              }
              onChangePartField({target})
            }}
            style={{alignItem:'center',marginLeft:'0em'}}
          />

          { partGroup.isLegacyTool ?
            <NumberField
              disabled={!partGroup.isOriginalPartGroup}
              label="Legacy Parts Made"
              name="legacyPartsMade"
              onChange={onChangePartField}
              styles={{width:"12em"}}
              value={partGroup.legacyPartsMade}
            />
            : null }
        </div>
      </div>
    : null
  )
}

function MoldMaterial(props){
  return(
    <FormControl style={{minWidth:"8em"}}>
      <InputLabel id="id-moldMaterialId-label">Mold Material</InputLabel>
      <Select
        disabled={props.disabled}
        labelId="id-moldMaterialId-label"
        name="materialId"
        native
        onChange={props.onChange}
        style={{width:"100%"}}
        value={props.value}
      >
        <option value={''}>{''}</option>
        {props.TOOL_MATERIALS
         .map(toolMatl => {
           return (
             <option value={toolMatl.material}>{toolMatl.display}</option>
           )
         })
        }
      </Select>
    </FormControl>
  )
}

function InjectionMoldingRoughingTools(props){
  const { addTool, deleteTool, onChangeToolField, partGroup } = props

  return (
    <RoughingTools
      addTool={(defaultPercentage) => addTool(defaultPercentage, partGroup.partGroupNumber, 'imRoughingTools')}
      deleteToolByIndex={toolIndex => deleteTool(partGroup.partGroupNumber, 'imRoughingTools', toolIndex)}
      onChange={(event, toolIndex) => onChangeToolField(event, partGroup.partGroupNumber, toolIndex, 'imRoughingTools')}
      tools={partGroup.part.imRoughingTools}
      toolType='imRoughingTools'
    />
  )
}

function InjectionMoldingFinishingTools(props){
  const { addTool, deleteTool, onChangeToolField, partGroup } = props

  return (
    <FinishingTools
      addTool={(defaultPercentage) => addTool(defaultPercentage, partGroup.partGroupNumber, 'imFinishingTools')}
      deleteToolByIndex={toolIndex => deleteTool(partGroup.partGroupNumber, 'imFinishingTools', toolIndex)}
      onChange={(event, toolIndex) => onChangeToolField(event, partGroup.partGroupNumber, toolIndex, 'imFinishingTools')}
      tools={partGroup.part.imFinishingTools}
      toolType='imFinishingTools'
    />
  )
}

export default DmeInjectionMolding
