import {
  LinearProgress,
  withStyles
} from "@material-ui/core"
import { STATUS_BLUE } from "./STATUS_BLUE.js"

export const UploadingLinearProgress  = withStyles( theme => ({
  root: {
    height: "10px",
    borderRadius: 5,
  },
  colorPrimary: {
    // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: STATUS_BLUE,
  },
}))(LinearProgress)
