const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function calculateProjectQuoteData(project){
  let response
  try{
    response = await calculateProjectQuoteDataRequest(project)
  } catch (err){
    logUnexpected(err, { project }, 'calculateProjectQuoteDataRequest')
    throw err
  }

  let result
  try{
    result = parseCalculateProjectQuoteDataResponse(response)
  } catch (err){
    // Expected errors

    // We know not all payloads will generate prices
    if(err.message.includes('does not fit in any production group')){
      throw err
    }
    if(err.message.includes('does not have valid') && err.message.includes('inputs')){
      throw err
    }
    if(err.message.includes('notes or attachments have changed since the last DME review')){
      throw err
    }
    if(err.message.includes('requires DME inputs to generate a quote')){
      throw err
    }
    if(err.message.includes('needs DME to review injection molding inputs')){
      throw err
    }
    if(err.message.includes('does not have any dimensions')){
      throw err
    }
    if(err.message.includes('Invalid tolerance inputs for tolerance')){
      throw err
    }
    if(err.message.includes('is missing or has invalid markup inputs')){
      throw err
    }
    if(err.message.includes('Tool is not fully defined')){
      throw err
    }
    if(err.message.includes('unable to generate lead time and price pairs')){
      throw err
    }
    if(err.message.includes('no valid domestic suppliers')){
      throw err
    }
    if(err.message.includes('Installed hardware cost is NaN')){
      throw err
    }

    logUnexpected(err, { project }, 'parseCalculateProjectQuoteDataResponse', response)
    throw err
  }

  return result
}

function calculateProjectQuoteDataRequest(project){
  return axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/project/quote',
    data: {
      project
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseCalculateProjectQuoteDataResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }
    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
