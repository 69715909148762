export function shipMethodNumberOfDays(method) {
    switch (method) {
        case '1 Day' : return 1
        case '2 Day' : return 2
        case '3 Day' : return 3
        case '5 Day' : return 5
        case '1 Day Saturday' : return 1
        case 'Next Day Air Early' : return 1
        case 'Next Day Saver' : return 1
        case 'Ground International NA' : return 5
        case 'International NA' : return 5
        case 'International EUR' : return 5
        case 'International ASIA' : return 5
        case 'Pickup' : return 0
        case "Small Package DHL on Autotiv's Account" : return 5
        case "Small Package Fedex on Autotiv's Account" : return 5
        case "Freight Air - Contact Autotiv for Instructions" : return null    // TODO get actual value
        case "Freight Ocean - Contact Autotiv for Instructions" : return null  // TODO get actual value
        default : return 0
    }
}
