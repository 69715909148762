import React from 'react'

/*
  Centers children vertically and horizontally
*/
const CenteredWrapper = ({ children }) => {
  return(
    <div style={{ display:'table', position:'absolute', top:0, left:0, height:'100%', width:'100%' }}>
      <div style={{ display:'table-cell', verticalAlign:'middle' }}>
        <div style={{ padding: '2em', textAlign: 'center' }}>
          { children }
        </div>
      </div>
    </div>
  )
}

export default CenteredWrapper
