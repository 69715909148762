import React, { Fragment } from "react"
import { InputAdornment, Typography } from "@material-ui/core"
import { NumberField } from "./NumberField"
import { CNC_TYPES } from './CNC_TYPES.js'

export default function CncStock(props){
  const { onChangeHandler, part } = props

  return (
    <div>
      <Typography variant="subtitle1"> Stock </Typography>
      <div className="cnc-stock">
        {part.cncMillOrTurn === CNC_TYPES.mill ?
         <MillStockFields onChangeHandler={onChangeHandler} part={part}/>
         : null
        }
        {part.cncMillOrTurn === CNC_TYPES.turn ?
         <TurnStockFields onChangeHandler={onChangeHandler} part={part}/>
         : null
        }
      </div>
    </div>
  )
}

function MillStockFields(props){
  const { onChangeHandler, part } = props

  return (
    <Fragment>
      <NumberField
        label="Stock X"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockX"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockX}
      />
      <NumberField
        label="Stock Y"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockY"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockY}
      />
      <NumberField
        label="Stock Z"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockZ"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockZ}
      />
    </Fragment>
  )
}

function TurnStockFields(props){
  const { onChangeHandler, part } = props

  return (
    <Fragment>
      <NumberField
        label="Stock OD"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockOuterDiameter"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockOuterDiameter}
      />
      <NumberField
        label="Stock ID"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockInnerDiameter"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockInnerDiameter}
      />
      <NumberField
        label="Stock Z"
        InputProps={{
          endAdornment: <InputAdornment children="mm" position="end"/>
        }}
        name="cncStockZ"
        onChange={onChangeHandler}
        styles={{width:"9em"}}
        value={part.cncStockZ}
      />
    </Fragment>
  )
}
