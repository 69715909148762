import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { SnackBarContext } from './SnackBarStore.js'

export default function ProjectFolderLinkButton({label, projectFolderLink, ...props}){
  const openSnackBarWithMessage = useContext(SnackBarContext)

  const onClick = () => {
    if(!projectFolderLink){
      openSnackBarWithMessage('Project is missing Google Drive folder id')
    }
  }

  return (
    <Button
      color="primary"
      onClick={onClick}
      href={projectFolderLink}
      rel="noreferrer"
      size="small"
      style={{textAlign: 'center'}}
      target="_blank"
      variant="contained"
      {...props}
    >
      {label ? label : 'Gdrive Folder'}
    </Button>
  )
}
