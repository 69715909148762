import React from 'react'
import {Button,Typography} from '@material-ui/core'
import TopAppBar from '../Common/TopAppBar'
import {Link} from '@reach/router'
import {Settings as GearIcon} from '@material-ui/icons'

const AccountSettings = props => {
  return(
    <>
      <TopAppBar user={props.user}/>
      <div class="account-settings-container">
        <GearIcon
          color="secondary"
          style={{fontSize:'5em'}} /* DXI: fontSize scales size of icon */
        />
        <Typography color="textPrimary" variant="h3">Account Settings</Typography>

        <div class="account-settings-actions">
          <Link to="changePassword">
            Change Password
          </Link>
          <Link to="../">
            Back to home
          </Link>

          <Button color="secondary" onClick={props.logoutHandler} variant="contained">
            Logout
          </Button>
        </div>
      </div>
    </>
  )
}

export default AccountSettings
