import React,{useEffect,useState} from 'react'
import {Button,Paper,TextField,Typography} from '@material-ui/core'
import {calculateLockedProjectTotal} from '../../shared/calculateLockedProjectTotal.js'
import {
  formatPrice,
  TranslateShipMethodToShipCost,
  translateShipMethodToCustomerTranslation,
  translateShipMethodToUserDeliveryDateString,
} from '../../utils'
import FinishCheckoutButton from './FinishCheckoutButton.js'
import DmeShippingOverrides from './DmeShippingOverrides.js'
import DownloadQuoteButton from '../QuoteTool/DownloadQuoteButton.js'
import projectIsAleadyBooked from '../Common/projectIsAleadyBooked.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
import validatePromoCode from './validatePromoCode.js'

export const OrderSummaryDiv = ({
  areShippingOptionsLoading,
  checkoutStep,
  downloadQuote,
  makeInvoiceThunk,
  navigateToConfirmationPage,
  onChangeShippingPriceOverride,
  openSnackBarWithMessage,
  orderDate,
  placeOrderThunk,
  project,
  quotePrefix,
  selectedPricingData,
  setProjectFieldAndSave,
  setPromoCodeData,
  setQuotePrefix,
  shippingOptions,
  user,
}) => {
  const [ promoCode, setPromoCode ] = useState(project.promoCode || '')
  const [ promoCodeIsFinishedValidating, setPromoCodeIsFinishedValidating ] = useState(project.promoCode || false)
  const [ promoCodeIsValid, setPromoCodeIsValid ] = useState(project.promoCode || false)
  const [ promoCodePercentOff, setPromoCodePercentOff ] = useState(project.promoCodePercentOff || 0)

  useEffect(() => {
    setPromoCodeIsFinishedValidating(false)
    setPromoCodeIsValid(false)
    setPromoCodePercentOff(0)
  }, [promoCode])

  const validatePromoCodeHandler = async () => {
    const result = await validatePromoCode(promoCode, project.customer.email, project.projectNumber)

    setPromoCodeIsFinishedValidating(true)
    if(result.isValid){
      setPromoCodeIsValid(true)
      setPromoCodePercentOff(result.percentOff)

      setPromoCodeData(promoCode, result.percentOff)
    } else {
      setPromoCodeIsValid(false)
      setPromoCodePercentOff(0)

      if(project.promoCode || project.promoCodePercentOff){
        setPromoCodeData('', 0)
      }
    }
  }

  let leadTime, sampleLeadTime, subTotal, totalSale

  if(project.isLeadTimeLocked){
    leadTime = project.lockedProductionLeadTimeDays
    sampleLeadTime = project.lockedSampleLeadTimeDays
    subTotal = calculateLockedProjectTotal(project)
    totalSale = Number(subTotal)
  } else {
    const { pricingData: { leadTimePricePairs }, selectedLeadTime } = selectedPricingData
    const leadTimePricePair = leadTimePricePairs.find(ltpp => ltpp.price === selectedLeadTime)

    totalSale = 0
    if(leadTimePricePair){
      totalSale += +leadTimePricePair.price
    }

    leadTime = leadTimePricePair.productionLeadTime
    sampleLeadTime = leadTimePricePair.sampleLeadTime
    subTotal = leadTimePricePair.price
  }
  const discount = (subTotal * promoCodePercentOff / 100).toFixed(2)
  if(discount > 0){
    totalSale = totalSale - discount
  }

  const shippingPrice = project.overrides.shippingPrice === 'custom' ?
        project.customShippingPrice :
        TranslateShipMethodToShipCost(project.paidShipMethod,shippingOptions,project.isCustomShippingMethodUsed)

  if(shippingPrice){
    totalSale += +shippingPrice
  }

  const shipMethod = translateShipMethodToCustomerTranslation(project.paidShipMethod, areShippingOptionsLoading, shippingOptions, project.isCustomShippingMethodUsed)
  return(
    <div>
      <CustomerOrderSummarySection
        areShippingOptionsLoading={areShippingOptionsLoading}
        checkoutStep={checkoutStep}
        discount={discount}
        downloadQuote={downloadQuote}
        leadTime={leadTime}
        navigateToConfirmationPage={navigateToConfirmationPage}
        orderDate={orderDate}
        placeOrderThunk={placeOrderThunk}
        project={project}
        promoCodePercentOff={promoCodePercentOff}
        promoCodeIsValid={promoCodeIsValid}
        sampleLeadTime={sampleLeadTime}
        shipMethod={shipMethod}
        shippingOptions={shippingOptions}
        shippingPrice={shippingPrice}
        subTotal={subTotal}
        totalSale={totalSale}
        user={user}
      />
      <PromoCodeSection
        openSnackBarWithMessage={openSnackBarWithMessage}
        promoCode={promoCode}
        promoCodeIsFinishedValidating={promoCodeIsFinishedValidating}
        promoCodeIsValid={promoCodeIsValid}
        promoCodePercentOff={promoCodePercentOff}
        setPromoCode={setPromoCode}
        validatePromoCodeHandler={validatePromoCodeHandler}
      />
      { user.type === USER_TYPES.DME ?
        <DmeSection
          areShippingOptionsLoading={areShippingOptionsLoading}
          checkoutStep={checkoutStep}
          downloadQuote={downloadQuote}
          makeInvoiceThunk={makeInvoiceThunk}
          navigateToConfirmationPage={navigateToConfirmationPage}
          onChangeShippingPriceOverride={onChangeShippingPriceOverride}
          placeOrderThunk={placeOrderThunk}
          project={project}
          quotePrefix={quotePrefix}
          setProjectFieldAndSave={setProjectFieldAndSave}
          setQuotePrefix={setQuotePrefix}
        />
        : null }
    </div>
  )
}

function CustomerOrderSummarySection({
  areShippingOptionsLoading,
  checkoutStep,
  discount,
  downloadQuote,
  leadTime,
  navigateToConfirmationPage,
  orderDate,
  placeOrderThunk,
  project,
  promoCodePercentOff,
  promoCodeIsValid,
  sampleLeadTime,
  shipMethod,
  shippingOptions,
  shippingPrice,
  subTotal,
  totalSale,
  user,
}){
  return(
    <div className='order-summary-div'>
      <Typography align="center" gutterBottom={true} variant="h3">
        Order Summary
      </Typography>
      <div style={{backgroundColor:'#E0E0E0',borderRadius:'4px',padding:'8px'}}>Orders submitted after 4:30 p.m. ET will be placed next business day</div>
      <OrderSummaryTable
        areShippingOptionsLoading={areShippingOptionsLoading}
        discount={discount}
        leadTime={leadTime}
        orderDate={orderDate}
        project={project}
        promoCodePercentOff={promoCodePercentOff}
        promoCodeIsValid={promoCodeIsValid}
        sampleLeadTime={sampleLeadTime}
        shipMethod={shipMethod}
        shippingOptions={shippingOptions}
        shippingPrice={shippingPrice}
        subTotal={subTotal}
        totalSale={totalSale}
      />
      <div align="right" style={{display: 'flex', justifyContent: 'space-between', marginTop:'16px'}}>
        <DownloadQuoteButton
          disabled={user.type === USER_TYPES.DME}
          downloadQuote={downloadQuote}
        />
        <FinishCheckoutButton
          disabled={checkoutStep !== 4 || user.type === USER_TYPES.DME}
          label="Place Order"
          navigateToConfirmationPage={navigateToConfirmationPage}
          onClickPromise={placeOrderThunk}
        />
      </div>
    </div>
  )
}

function PromoCodeSection({
  openSnackBarWithMessage,
  promoCode,
  promoCodeIsFinishedValidating,
  promoCodeIsValid,
  promoCodePercentOff,
  setPromoCode,
  validatePromoCodeHandler,
}){
  const [ isValidating, setIsValidating ] = useState(false)
  const onClickHandler = () => {
    setIsValidating(true)
    validatePromoCodeHandler()
      .catch(err => openSnackBarWithMessage(err.message))
      .finally(() => setIsValidating(false))
  }

  return(
    <div className='promo-code-div'>
      <TextField
        label="Promo Code"
        onChange={e => {
          setPromoCode(String(e.target.value).toUpperCase())
        }}
        size="small"
        style={{width:'50%'}}
        variant="outlined"
        value={promoCode}
      />
      <Button
        color="primary"
        disabled={promoCode.length < 4 || isValidating || (promoCodeIsFinishedValidating && promoCodeIsValid)}
        onClick={onClickHandler}
        size="small"
        variant="contained"
      >
        {isValidating ? 'Checking' : 'Apply'}
      </Button>
      {promoCodeIsFinishedValidating ?
       <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
         <Typography variant="caption">
           {promoCodeIsValid ? 'Promo Code Applied' : 'Invalid Code'}
         </Typography>
       </div>
       : null}
    </div>
  )
}

function OrderSummaryTable({
  areShippingOptionsLoading,
  discount,
  leadTime,
  orderDate,
  project,
  promoCodePercentOff,
  promoCodeIsValid,
  sampleLeadTime,
  shipMethod,
  shippingOptions,
  shippingPrice,
  subTotal,
  totalSale,
}){
  return(
    <table style={{width:'100%'}}>
      <tbody>
        <tr>
          <td style={{paddingTop:'8px'}}>Order Date</td>
          <td style={{paddingTop:'8px',textAlign:'right'}}>{orderDate}</td>
        </tr>
        {
          sampleLeadTime ?
            <tr>
              <td style={{paddingTop:'8px'}}>Sample Lead Time</td>
              <td style={{paddingTop:'8px',textAlign:'right'}}>{`${sampleLeadTime} Business Day(s)`}</td>
            </tr>
          : null
        }
        <tr>
          <td style={{paddingTop:'8px'}}>Production Lead Time</td>
          <td style={{paddingTop:'8px',textAlign:'right'}}>{leadTime ? `${leadTime} Business Day(s)` : `-`}</td>
        </tr>
        <tr>
          <td style={{paddingTop:'8px'}}>Shipping Method</td>
          <td style={{paddingTop:'8px',textAlign:'right'}}>{shipMethod ? `${shipMethod}` : `-`}</td>
        </tr>
        <tr>
          <td style={{paddingTop:'8px'}}>Guaranteed Delivery Date</td>
          <td style={{paddingTop:'8px',textAlign:'right'}}>{translateShipMethodToUserDeliveryDateString(project.paidShipMethod, areShippingOptionsLoading, shippingOptions, project.isCustomShippingMethodUsed)}</td>
        </tr>
        <tr>
          <td style={{paddingTop:'16px'}}>Subtotal</td>
          <td style={{paddingTop:'16px',textAlign:'right'}}>{subTotal ? formatPrice(subTotal) : '-'}</td>
        </tr>
        <tr>
          <td style={{paddingTop:'16px'}}>Shipping</td>
          <td style={{paddingTop:'16px',textAlign:'right'}}>
            {shipMethod ? formatPrice(shippingPrice) : '-'}
          </td>
        </tr>
        {promoCodeIsValid ?
         <tr>
           <td style={{paddingTop:'16px',color:'red'}}>{promoCodePercentOff}% Discount</td>
           <td style={{paddingTop:'16px',color:'red',textAlign:'right'}}>- {formatPrice(discount)}</td>
         </tr>
         : null}
        <tr>
          <td style={{paddingTop:'24px'}}>
            <Typography color="textPrimary" variant="h4">
              Total
            </Typography>
          </td>
          <td style={{paddingTop:'24px',textAlign:'right'}}>
            <Typography color="textPrimary" variant="h4">
              {totalSale ? formatPrice(totalSale) : '-'}
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function DmeSection({
  areShippingOptionsLoading,
  checkoutStep,
  downloadQuote,
  makeInvoiceThunk,
  navigateToConfirmationPage,
  onChangeShippingPriceOverride,
  placeOrderThunk,
  project,
  setProjectFieldAndSave,
  setQuotePrefix,
  quotePrefix,
}){
  return(
    <Paper className="dme-shipping-overrides order-summary-div dmeInput">
      <Typography variant="subtitle1">
        Project {project.projectNumber} - {project.customer && project.customer.emailAndName}
      </Typography>
      <div> <hr style={{margin:0}}/> </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <TextField
          onChange={e => setQuotePrefix(e.target.value)}
          label="File Prefix"
          size="small"
          variant="outlined"
          value={quotePrefix}
        />
        <div>
          <DownloadQuoteButton
            downloadQuote={downloadQuote}
            size="medium"
          />
        </div>
      </div>
      <div> <hr style={{margin:0}}/> </div>
      <DmeShippingOverrides
        areShippingOptionsLoading={areShippingOptionsLoading}
        checkoutStep={checkoutStep}
        onChangeShippingPriceOverride={onChangeShippingPriceOverride}
        project={project}
        setProjectFieldAndSave={setProjectFieldAndSave}
      />
      <div> <hr style={{margin:0}}/> </div>
      <div style={{display: 'flex', justifyContent: 'space-between' }}>
        {projectIsAleadyBooked(project) ? <div/> :
         <FinishCheckoutButton
           disabled={checkoutStep !== 4}
           label="Make Invoice"
           navigateToConfirmationPage={navigateToConfirmationPage}
           onClickPromise={makeInvoiceThunk}
         />
        }
        <FinishCheckoutButton
          disabled={checkoutStep !== 4}
          label={projectIsAleadyBooked(project) ? "Update Project" : "Book Project"}
          navigateToConfirmationPage={navigateToConfirmationPage}
          onClickPromise={placeOrderThunk}
        />
      </div>
    </Paper>
  )
}
