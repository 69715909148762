import React,{Fragment,useState} from 'react'
import {Button,CircularProgress,TextField,Typography,withStyles} from '@material-ui/core'
import { Link } from "@reach/router"

const ForgotPasswordPage = ({ classes, forgotPassword, popupMessage }) => {
  const [email, setEmail] = useState()
  const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false)

  const forgotPasswordHandler = async () => {
    if (email) {
      setIsCurrentlyLoading(true)
      await forgotPassword(email)
      setIsCurrentlyLoading(false)
    }
    else{
      popupMessage('Email is required')
    }
  }

  const HEADER_PLACEHOLDER = isCurrentlyLoading ? "AUTHENTICATING" : "Enter Email Address"
  return(
    <Fragment>
      <div align="center">
        <div className={classes.loadingBox}>
          {isCurrentlyLoading && <CircularProgress className={classes.progress} color="secondary" size={200}/>}
        </div>

        <div className={classes.loginDiv}>
          <Typography className={classes.paperHeader} color="textPrimary" variant="h4">
            {HEADER_PLACEHOLDER}
          </Typography>
          <TextField
            autoFocus={true}
            className={classes.textField}
            disabled={isCurrentlyLoading}
            inputProps={{min: "0"}}
            label="Email"
            margin="dense"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){forgotPasswordHandler()}
            }}
            type="text"
            value={email||''}
          /><br/>
          <Button
            className={classes.buttonSubmit}
            color="secondary"
            disabled={isCurrentlyLoading}
            onClick={forgotPasswordHandler}
            style={{minWidth:'100px'}}
            variant="contained"
          >
            SUBMIT
          </Button><br/>
          Remember your password? Login <Link to={`../?${(new URLSearchParams(window.location.search)).toString()}`}>here</Link>.
        </div>
      </div>
    </Fragment>
  )
}

const styles = theme => ({
  buttonSubmit:{
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  loadingBox:{
    height:'300px',
    paddingTop: '50px'
  },
  loginDiv: {
    marginBottom: '10%',
    width: '600px',
  },
  paperHeader:{
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign:'center'
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: '520px'
  }
})

export default withStyles(styles)(ForgotPasswordPage)
