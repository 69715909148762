import React,{useEffect,useState} from "react"
import AttachmentsModal from "./AttachmentsModal"
import PostProcessesModal from './PostProcessesModal'
import CustomerPartConfiguration from './CustomerPartConfiguration.js'
import Tooling from './Tooling'
import ProductionToolPartConfiguration from './ProductionToolPartConfiguration'
import SurfaceFinishesModal from "./SurfaceFinishesModal"
import Add from "@material-ui/icons/Add"
import {Button,Typography} from '@material-ui/core'
import { sleep } from '../../utils/util.js'

export default function ProductionTool(props) {
  const [attachmentsListModalIsOpen, setAttachmentsListModalIsOpen] = useState(false)
  const [postProcessesModalIsOpen, setPostProcessesModalIsOpen] = useState(false)
  const [surfaceFinishesModalIsOpen, setSurfaceFinishesModalIsOpen] = useState(false)

  function addPartConfiguration(){
    props.setIsLoadingNewPtpc(true)
    props.addPartConfigurationToPartGroup(props.partGroup)
      .then(()=>{
        props.setIsLoadingNewPtpc(false)
      })
  }

  /*
    removeAttachmentHandler takes the attachment Id that the attachments Modal
    will emit when deleting an attachment, then appends the part ID and passes
    it up to the remove attachment prop function passed down from the parent component
  */
  const removeAttachmentHandler = fileId => {
    props.removeAttachment(fileId, props.partGroup.partGroupNumber)
  }

  const renderModals = () => (
    <>
      <AttachmentsModal
        attachments={props.partGroup.attachments}
        currentlyLoading={props.currentlyLoading}
        open={attachmentsListModalIsOpen}
        onClose={() => setAttachmentsListModalIsOpen(false)}
        partName={props.partGroup.part.fileName}
        removeAttachment={removeAttachmentHandler}
        uploadParts={(e,failedDropZoneFiles)=>{props.uploadNewAttachment(e, props.partGroup.partGroupNumber,true,failedDropZoneFiles)}}
      />

      <PostProcessesModal
        open={postProcessesModalIsOpen}
        onClose={() => setPostProcessesModalIsOpen(false)}
        partName={props.partGroup.part.fileName}
        process={props.partGroup.processId}
        onChange={(e)=>{props.onChange(e,props.partGroup.partGroupNumber)}}
        value={props.partGroup.finishId}
      />

      <SurfaceFinishesModal
        onClose={() => setSurfaceFinishesModalIsOpen(false)}
        onChange={props.onChange}
        open={surfaceFinishesModalIsOpen}
        partName={props.partGroup.part.fileName}
        value={[]}
      />
    </>
  )

  return(
    <div className="production-tool">
      { renderModals() }
      <Tooling
        crud={props.crud}
        deletePartGroup={props.deletePartGroup}
        isCalculatingPrices={props.isCalculatingPrices}
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        onChange={props.onChange}
        openPartModal={props.openPartModal}
        partGroupPrices={props.partGroupPrices}
        partReasons={props.partReasons}
        postProcessData={props.postProcessData}
        replacePartFile={props.replacePartFile}
        setAttachmentsListModalIsOpen={setAttachmentsListModalIsOpen}
        setPostProcessesModalIsOpen={setPostProcessesModalIsOpen}
        setPartGroupNumberToEdit={props.setPartGroupNumberToEdit}
        status={props.status}
        partGroup={props.partGroup}
        user={props.user}
      />

      <ProductionToolPartConfigurations
        crud={props.crud}
        deleteProductionToolPartConfiguration={props.deleteProductionToolPartConfiguration}
        isCalculatingPrices={props.isCalculatingPrices}
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        partGroup={props.partGroup}
        partReasons={props.partReasons}
        postProcessData={props.postProcessData}
        ptpcPrices={props.ptpcPrices}
        setAttachmentsListModalIsOpen={setAttachmentsListModalIsOpen}
        setPostProcessesModalIsOpen={setPostProcessesModalIsOpen}
        updateProductionToolPartConfiguration={props.updateProductionToolPartConfiguration}
        user={props.user}
        projectNumber={props.projectNumber}
      />

      <AddPartConfiguration
        addPartConfiguration={addPartConfiguration}
        isLoadingNewPtpc={props.isLoadingNewPtpc}
      />
    </div>
  )
}

function getPtpcsFromPtg(partGroup){
  return partGroup.productionToolPartConfigurations
    .filter( productionToolPartConfiguration => productionToolPartConfiguration.processId === partGroup.processId)
}

function ProductionToolPartConfigurations({
  crud,
  deleteProductionToolPartConfiguration,
  isCalculatingPrices,
  MATERIAL_TRANSLATIONS,
  partGroup,
  partReasons,
  postProcessData,
  ptpcPrices,
  setAttachmentsListModalIsOpen,
  setPostProcessesModalIsOpen,
  updateProductionToolPartConfiguration,
  user,
  projectNumber,
}){
  return getPtpcsFromPtg(partGroup).map(ProductionToolPartConfigurationChunk)

  function ProductionToolPartConfigurationChunk(productionToolPartConfiguration, index){
    const setPtpcField = (name, value) => {
      updateProductionToolPartConfiguration(
        name,
        value,
        productionToolPartConfiguration.productionToolPartConfigurationId,
        partGroup.partGroupNumber
      )
    }

    return(
      <ProductionToolPartConfiguration
        ptpcCrud={crud.partGroups(partGroup.partGroupNumber).productionToolPartConfigurations(productionToolPartConfiguration.productionToolPartConfigurationId)}
        ptpcPrices={ptpcPrices}
        deleteProductionToolPartConfiguration={deleteProductionToolPartConfiguration}
        fileName={partGroup.part.fileName}
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        number={+index+1}
        isCalculatingPrices={isCalculatingPrices}
        isTheOnlyPtpc={getPtpcsFromPtg(partGroup).length === 1}
        key={productionToolPartConfiguration.productionToolPartConfigurationId}
        partGroup={partGroup}
        partReasons={partReasons}
        postProcessData={postProcessData}
        productionToolPartConfiguration={productionToolPartConfiguration}
        setAttachmentsListModalIsOpen={setAttachmentsListModalIsOpen}
        setPostProcessesModalIsOpen={setPostProcessesModalIsOpen}
        setPtpcField={setPtpcField}
        user={user}
        projectNumber={projectNumber}
      />
    )
  }
}

const AddPartConfiguration = ({ addPartConfiguration, isLoadingNewPtpc }) => {
  return(
    <Button
      className="production-tool-part-configuration add-ptpc-button"
      onClick={addPartConfiguration}
    >
      {isLoadingNewPtpc ? null : <Add style={{ fontSize: "2em"}}/>}
      <Typography align="center" variant="h5">
        {isLoadingNewPtpc ? "Creating new part configuration..." : "Add another part configuration"}
      </Typography>
    </Button>
  )
}

// SHOWCASE STUFF
const _ = require('lodash')
ProductionTool.exampleProps = _.cloneDeep(CustomerPartConfiguration.exampleProps)
ProductionTool.exampleProps.partGroup.guaranteedLife = 10000
ProductionTool.exampleProps.partGroup.toolPrice = 5025.56
ProductionTool.exampleProps.partGroup.imTooling = { color:"pantone4009c" }
ProductionTool.exampleProps.partGroup.imTooling.quantity = 2000
ProductionTool.exampleProps.partGroup.unitPrice = 5.55
ProductionTool.exampleProps.addPartConfigurationToPartGroup = async () => {console.log("addPartConfigurationToPartGroup"); return sleep(2000)}
ProductionTool.exampleProps.partGroup.productionToolPartConfigurations = [
  {
    productionToolPartConfigurationId: 'tenTdpietnsoaeiomsev',
    partGroupNumber: 194458,
    processId: "injMoldingPart",
    materialId: "injMoldingPartPC385",
    color: "",
    quantity: 12,
    unitPrice: "15.50",
    finishing: [],
    attachments: [],
    partNotes: ""
  }
]
ProductionTool.Showcase = Showcase
function Showcase() {
  return(
    <>
      <ProductionTool { ...ProductionTool.exampleProps } devBackdoors={{}}/>
    </>
  )
}

ProductionTool.defaultProps = {
  devBackdoors: {} /* prevent misindices */
}
