import {determineProjectProcess} from '../../shared/determineProjectProcess.js'

export default function getFileNameFromProject(project, quotePrefix, quoteRevision){
  const today = new Date()
  const quoteDate = `${+today.getMonth() + 1}-${today.getDate()}`

  const process = determineProjectProcess(project)

  const revision = quoteRevision ? ` - Quote r${quoteRevision}` : ""

  let quoteFileName = ""
  if(quotePrefix){
    quoteFileName = `${quotePrefix} ${project.customer.customerName} ${quoteDate} ${process} ${project.projectNumber}${revision}`
  }
  else{
    quoteFileName = `${project.customer.customerName} ${quoteDate} ${process} ${project.projectNumber}${revision}`
  }
  return quoteFileName
}
