import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('../utils/logUnexpected.js')

export default async function createProductionToolPartConfiguration(productionToolPartConfiguration){
  let response
  try{
    response = await createProductionToolPartConfigurationRequest(productionToolPartConfiguration)
  } catch (err){
    logUnexpected(err, { productionToolPartConfiguration }, 'createProductionToolPartConfigurationRequest')
    throw err
  }

  let result
  try{
    result = parseCreateProductionToolPartConfigurationResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseCreateProductionToolPartConfigurationResponse(response)', { productionToolPartConfiguration })
    throw err
  }

  return result
}

function createProductionToolPartConfigurationRequest(productionToolPartConfiguration){
  return axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.CREATE_PTPC_ENDPOINT,
    data: {
      productionToolPartConfiguration,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseCreateProductionToolPartConfigurationResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.productionToolPartConfiguration){
      return rectify(response.data.productionToolPartConfiguration)
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}

function rectify(ptpc){

  // Can remove when ATV-0726 is merged in
  ptpc.attachments = []
  return ptpc
}
