export const TAB_LABELS = {
  solid:{
    label:'Solid',
    tabIndex:0,
    config:{
      flatShading : true,
      needsUpdate : true,
      opacity : 1,
      transparent : false,
      wireframe : false,
    }
  },
  xray: {
    label:'X-Ray',
    tabIndex:1,
    config:{
      flatShading : false,
      needsUpdate : true,
      opacity : '0.5',
      transparent : true,
      wireframe : false,
    }
  },
  wireFrame: {
    label:'Wireframe',
    tabIndex:2,
    config:{
      flatShading : false,
      needsUpdate : true,
      opacity : 0,
      transparent : true,
      wireframe : false,
    }
  }
} //Tabs are controlled by index
