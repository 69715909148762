import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Paper, TextField, Typography } from '@material-ui/core'
import { CONTACTED_THROUGH_COMMUNICATION_PLATFORMS } from './CONTACTED_THROUGH.js'
import { SelectOption } from "../QuoteTool/SelectOption"
import { PROJECT_MANAGERS } from './PROJECT_MANAGERS.js'
import { CustomerSelectAutoComplete } from './CustomerSelectAutocomplete.js'
import {SnackBarContext} from '../Common/SnackBarStore'

export const CreateProjectModal = ({ createProject, open, onClose }) => {
  const [ notes, setNotes ] = useState("")
  const [ platformCustomerCompany, setPlatformCustomerCompany ] = useState("")
  const [ platformCustomerPhoneNumber, setPlatformCustomerPhoneNumber ] = useState("")
  const [ platformCustomerName, setPlatformCustomerName ] = useState("")
  const [ contactedThrough, setContactedThrough ] =
    useState(CONTACTED_THROUGH_COMMUNICATION_PLATFORMS.blank)
  const [ referenceNumber, setReferenceNumber ] = useState("")
  const [ projectManager, setProjectManager ] = useState('')
  const [ customer, setCustomer ] = useState({})

  const openSnackBarWithMessage = useContext(SnackBarContext)

  useEffect(wipeModalState, [ open ])

  function wipeModalState(){
    setNotes("")
    setPlatformCustomerCompany("")
    setPlatformCustomerPhoneNumber("")
    setPlatformCustomerName("")
    setReferenceNumber("")
    setContactedThrough("")
    setProjectManager("")
    setCustomer({})
  }

  const handleCreateProject = () => {
    if(areRequiredFieldsFilled()){
      createProject('3dPrinting', {
        customerEmail: customer.email,
        company: platformCustomerCompany,
        phoneNumber: platformCustomerPhoneNumber,
        customerName: platformCustomerName,
        paymentReferenceNumber: referenceNumber,
        contactedThrough: contactedThrough,
        projectManager: projectManager,
        notes: notes,
      })
    }
    else{
      openSnackBarWithMessage('Required fields not filled')
    }
  }

  const areRequiredFieldsFilled = () => {
    let areFieldsPopulated = customer.email && projectManager && contactedThrough
    return areFieldsPopulated
  }

  const displayPaymentReference =
    contactedThrough !== CONTACTED_THROUGH_COMMUNICATION_PLATFORMS.blank &&
    contactedThrough !== CONTACTED_THROUGH_COMMUNICATION_PLATFORMS.email &&
    contactedThrough !== CONTACTED_THROUGH_COMMUNICATION_PLATFORMS.instantQuoteTool

  return(
    <Modal
      open={open}
      onClose={onClose}
      style={{alignItems:'center',display:'flex',justifyContent:'center'}}
    >
      <Paper style={{ padding:'3em', width:'30em', outline:'none', display:'flex', flexDirection:'column', gap: '1em' }}>
        <Typography variant="h4">
          Add Project
        </Typography>

        <CustomerSelectAutoComplete
          customer={customer}
          setCustomer={setCustomer}
        />

        <ProjectManagerSelect
          value={projectManager}
          setProjectManager={setProjectManager}
        />

        <ContactedThroughSelect
          value={contactedThrough}
          setContactedThrough={setContactedThrough}
        />

        {displayPaymentReference &&
          <TextField
            label="Reference Number"
            fullWidth={true}
            onChange={event => setReferenceNumber(event.target.value)}
            value={referenceNumber}
          />
        }

        <TextField
          label="Platform Customer Name"
          fullWidth={true}
          onChange={event => setPlatformCustomerName(event.target.value)}
          value={platformCustomerName}
        />

        <TextField
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label="Platform Customer Phone Number"
          fullWidth={true}
          onChange={event => setPlatformCustomerPhoneNumber(event.target.value)}
          value={platformCustomerPhoneNumber}
        />

        <TextField
          label="Platform Customer Company"
          fullWidth={true}
          onChange={event => setPlatformCustomerCompany(event.target.value)}
          value={platformCustomerCompany}
        />

        <TextField
          label="Our Notes"
          fullWidth={true}
          multiline
          onChange={event => setNotes(event.target.value)}
          rows={2}
          value={notes}
        />

        <div style={{ display:'flex', justifyContent:'space-between'}}>
          <Button
            color="primary"
            onClick={onClose}
            variant="contained"
          >
            BACK
          </Button>
          <Button
            color="secondary"
            onClick={handleCreateProject}
            variant="contained"
          >
            SUBMIT
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

const ContactedThroughSelect = ({ setContactedThrough, value }) => {
  return(
    <SelectOption
      fullWidth={true}
      label="Contacted Through"
      name="contactedThrough"
      onChange={event => setContactedThrough(event.target.value)}
      required={true}
      value={value}
    >
      {
        Object.values(CONTACTED_THROUGH_COMMUNICATION_PLATFORMS)
          .map( ctcp =>
            <option key={ctcp} value={ctcp}>{ctcp}</option>
          )
      }
    </SelectOption>
  )
}

const ProjectManagerSelect = ({ setProjectManager, value }) => {
  return(
    <SelectOption
      fullWidth={true}
      label="Project Manager"
      name="projectManager"
      onChange={event => setProjectManager(event.target.value)}
      required={true}
      value={value}
    >
      <option value={''}>{''}</option>
      {
        PROJECT_MANAGERS
          .map((pmObj) =>
            <option key={pmObj.id} value={pmObj.id}>{pmObj.name}</option>
          )
      }
    </SelectOption>
  )
}

