import React from 'react'
import PropTypes from 'prop-types'
import {FormControl,Select,withStyles} from '@material-ui/core'
import {GenerateFinishList} from '../../utils'

const SelectOptionFinishing = props => {
  const classes = props.classes
  const FILTERED_FINISH_LIST = GenerateFinishList(props.process,props.blankOptionEnabled) //FILTERED_FINISH_LIST is an array of finishes determined by a given process
  return(
    <form className={classes.root}>
      <FormControl className={classes.formControl} fullWidth={true}>
        <Select
          name={props.name}
          native
          onChange={props.onChange}
          value={props.value}
          >
            {
              //if process is defined display material options for that process
              props.process ? (FILTERED_FINISH_LIST.map(currentFinish =>{return <option key={currentFinish.id} value={currentFinish.id}>{currentFinish.display}</option>})) : null
            }
          </Select>
      </FormControl>
    </form>
  )
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px'
  }
})

SelectOptionFinishing.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  process: PropTypes.string,
  value: PropTypes.string
}

export default withStyles(styles)(SelectOptionFinishing)
