import React from "react"
import {checkFileTypeIncludeUnits} from "../../../utils"
import {
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core"

export const UnitSelectionAtom = ({ onChange, part, rowType, unitValue }) => {
  return(
    <>
    { anyDimsAreGreaterThanZero(part.xDimUnits,part.yDimUnits,part.zDimUnits) ?
      <div>
        <UnitSelectorOrLabelAtom
          onChange={onChange}
          part={part}
          rowType={rowType}
          unitValue={unitValue}
        />
      </div>
        : null
    }
    </>
  )
}

const anyDimsAreGreaterThanZero = ( x, y, z) => {
  return( x>0 || y>0 || z>0 )
}

const UnitSelectorOrLabelAtom = ({onChange,part,rowType,unitValue}) => {
  return (
    checkFileTypeIncludeUnits(part.fileName) ?
      <>{unitValue}</>
      :
      <UnitSelector
        onChange={onChange}
        rowType={rowType}
        unitValue={unitValue}
      />
  )
}

const UnitSelector = ({ rowType, onChange, unitValue }) => {
  let formControlProps = {}
  let selectProps = {}
  if(rowType === "no_units"){
    formControlProps.error = true
    /* For outlining
    formControlProps.variant = "outlined"
    selectProps.input = <OutlinedInput labelWidth={0}/> // label is in headers, not needed here
    */
  }
  return(
    <FormControl {...formControlProps}>
      <InputLabel id='unit-selector'>
        Units
      </InputLabel>
      <Select
        name="units"
        labelId="unit-selector"
        native
        onChange={onChange}
        value={unitValue}
        {...selectProps}
      >
        {rowType === "no_units" ? <option value=""></option> : null}
        <option value="mm">mm</option>
        <option value="inches">inches</option>
      </Select>
    </FormControl>
  )
}
