import React,{useState} from 'react'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {generateSelectComponentOptions} from '../../utils'
import SelectOptionProcess from './SelectOptionProcess.js'
const {generateMaterialList} = require('../../Components/Common/TranslationTableMaterial.js')

export default function SelectMaterialAutocomplete({ autoFocus, blankOptionEnabled, label, MATERIAL_TRANSLATIONS, onChange, process, style, user, value }) {
  const materialListForGivenProcess = generateMaterialList(process,MATERIAL_TRANSLATIONS,user,blankOptionEnabled)
  let currentMaterialValue = materialListForGivenProcess.find(currentMaterial=>{
    return currentMaterial.id === value})
  if(!currentMaterialValue){
    currentMaterialValue = {id:'',display:''}
  }
  const material = {value:currentMaterialValue.id,label:currentMaterialValue.display}

  return(
    <Autocomplete
      disableClearable={true}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      options={generateSelectComponentOptions(process, materialListForGivenProcess)}
      getOptionDisabled={option => option.value === "DIVIDER"}
      getOptionLabel={option => option.label}
      getOptionSelected={(optionToCompare,selectedOption)=>optionToCompare.value === selectedOption.value}
      style={style}
      renderInput={(params) => <TextField {...params} autoFocus={autoFocus} label={label ? label : "Material"} variant="standard" />}
      renderOption={getFormattedOption}
      value={material}
    />
  )
}

function getFormattedOption(option) {
  const isDivider = option.value === "DIVIDER"
  const isManualRfqOption = !option.whiteListed && !isDivider && option.value !== ""
  return(
    <option value={option.value} className={isManualRfqOption ? "informative" : ""}>
      { isManualRfqOption ? `${option.label} | Manual RFQ` : option.label }
    </option>
  )
}

SelectMaterialAutocomplete.exampleProps = {
  process: "sls",
  value: "slsWhiteNylon12"
}
SelectMaterialAutocomplete.Showcase = Showcase

function Showcase() {
  const [ process, setProcess ] = useState(SelectMaterialAutocomplete.exampleProps.process)
  const [ material, setMaterial ] = useState(SelectMaterialAutocomplete.exampleProps.value)
  return(
    <div style={{ margin:"2em" }}>
      <ProcessSelect
        onChange={(process)=>{
          setMaterial("") // when process changes default material
          setProcess(process)
        }}
        process={process}
      />
      <SelectMaterialAutocomplete
        blankOptionEnabled={true}
        process={process}
        onChange={(event)=>{
          if(event === null){setMaterial("")}
          else{setMaterial(event.value)}
        }}
        value={material}
        devBackdoors={{}}
      />
    </div>
  )
}

function ProcessSelect({ onChange, process }) {
  return(
    <div style={{ marginBottom:"2em", width:"20em" }}>
      <SelectOptionProcess
        name="processId"
        onChange={(e)=>{
          let {value} = e.target
          onChange(value)
        }}
        value={process}
      />
    </div>

  )
}

SelectMaterialAutocomplete.defaultProps = {
  autoFocus: false,
  devBackdoors: {} /* prevent misindices */
}
