import config from "../config"
// Paste to tests
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

// takes partNumber, returns partGroupNumber from created partGroup
export async function createPartGroup(partNumber, retryCounter) { 
  (retryCounter === undefined) ? retryCounter = 0 : retryCounter++

  let partGroupId 

  try {
    var response = await createPartGroupRequest(partNumber)
  } catch (err) {
    logUnexpected(err, { partNumber }, 'createPartGroupRequest', { retryCounter })
    throw err
  }

  try {
    let partGroupNumber = parseCreatePartGroupResponse(response)
    partGroupId = partGroupNumber
  } catch (err) {
    if (err.message.includes('Error Status Code: 503') && retryCounter < 2) {
      // RECURSION: retry
      partGroupId = createPartGroup(partNumber, retryCounter)
    } else {
      // Default condition hit means this error is unhandled
      logUnexpected(err, { response }, 'parseCreatePartGroupResponse', { partNumber, retryCounter })
      throw err
    }
  }

  return partGroupId
  
}


function createPartGroupRequest(partNumber) {
  return axios({
    method: 'POST',
    url: config.CREATE_PARTGROUP_ENDPOINT,
    timeout: 10000,
    validateStatus: () => true,
    data: {
      partGroup: { part: partNumber },
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then( rawResponse => ({ data: rawResponse.data, status: rawResponse.status}))
}

function parseCreatePartGroupResponse(responseData) {
  if ( responseData.status === 200) {
    if (('partGroup' in responseData.data) && ('partGroupNumber' in responseData.data.partGroup)) {
      return responseData.data.partGroup.partGroupNumber
    } else {
      throw new Error(`Unexpected data shape: ${JSON.stringify(responseData.data)}`)
    }
  } else if ( responseData.status === 503 ) {
    throw new Error('Error Status Code: 503')
  } else {
    throw new Error(`Unexpected status code: ${responseData.status}`)
  }
}
