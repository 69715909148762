import React from "react"
import PostProcessRow from './PostProcessing/PostProcessRow.js'

export const PostProcessesAtom = ({ disabled, materialId, onClick, processLabel, overrideLabel, postProcessData, postProcesses, postProcessesCrud, user }) => {
  return (
    <PostProcessRow
      disabled={disabled}
      materialId={materialId}
      overrideLabel={overrideLabel}
      postProcesses={postProcesses}
      postProcessesCrud={postProcessesCrud}
      postProcessOptionsFromDb={postProcessData}
      user={user}
    />
  )
}
