import React, { useContext, useEffect, useState } from "react"
import { Button, Checkbox, CircularProgress, FormControlLabel, Modal, Tooltip, Typography } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles'
import { SnackBarContext } from '../Common/SnackBarStore'
import ToolingReadOnly from "./ToolingReadOnly.js"
import getPurchasedProductionTools from './getPurchasedProductionTools.js'
import { updatePartGroupsPromise } from '../../utils'
import USER_TYPES from '../../utils/USER_TYPES.js'

export const PurchasedToolsButtonAndModal = ({ addPurchasedToolToProject, customerEmail, MATERIAL_TRANSLATIONS, user }) => {
  const [ isModalOpen, setisModalOpen ] = useState(false)
  const [ isDataLoading, setIsDataLoading ] = useState(true)
  const [ purchasedTools, setPurchasedTools ] = useState()
  const openSnackBarWithMessage = useContext(SnackBarContext)

  useEffect(() => {
    getPurchasedProductionTools(customerEmail)
      .then(tools => setPurchasedTools(tools))
      .then(() => setIsDataLoading(false))
      .catch(err => openSnackBarWithMessage(err.message))
  }, ['ONMOUNT'])

  const onChangePurchasedTool = (value, partGroupNumber) => {
    const purchasedToolsCopy = JSON.parse(JSON.stringify(purchasedTools))
    const purchasedTool = purchasedToolsCopy
          .find(tool => tool.partGroup.partGroupNumber === partGroupNumber)

    purchasedTool.partGroup.hasBeenMigratedToLegacyTool = value

    updatePartGroupsPromise(purchasedTool.partGroup)
      .then(() => setPurchasedTools(purchasedToolsCopy))
      .catch(err => openSnackBarWithMessage(err.message))
  }

  if(isDataLoading){
    return(
      <CircularProgress/>
    )
  }

  const userAccessiblePurchasedTools = purchasedTools
        .filter(purchasedTool => {
          // only show migrated tools to DMEs
          const userIsDme = user.type === USER_TYPES.DME
          return userIsDme || !purchasedTool.partGroup.hasBeenMigratedToLegacyTool
        })

  return(
    <>
      <Button color="secondary" variant="contained" onClick={()=>{setisModalOpen(true)}}>
        Add Parts from a Previously Purchased Production Tool
      </Button>

      <Modal
        open={isModalOpen}
        onClose={()=>{setisModalOpen(false)}}
        style={{alignItems:'center',display:'flex',justifyContent:'center',maxHeight:'100vh',overflowY:'auto'}}
      >
        <div className="production-tool-modal">
          <Typography variant="h5" style={{marginBottom:"1em", marginLeft:"0.5em"}}>
            Add parts from a previously purchased Production Tool
          </Typography>
          {userAccessiblePurchasedTools.length ? null :
           <Typography variant="h6" style={{marginBottom:"1em", marginLeft:"1em"}}>
             You don't have any purchased production tools yet
           </Typography>
          }
          {userAccessiblePurchasedTools.map(purchasedTool => (
            <ToolingReadOnlyDiv
              addPurchasedToolToProject={addPurchasedToolToProject}
              key={purchasedTool.partGroup.partGroupNumber}
              MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
              onChangePurchasedTool={onChangePurchasedTool}
              purchasedTool={purchasedTool}
              user={user}
            />
          ))}
        </div>
      </Modal>
    </>
  )
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function ToolingReadOnlyDiv({ addPurchasedToolToProject, MATERIAL_TRANSLATIONS, onChangePurchasedTool, purchasedTool, user }){
  const toolHasPartsInCurrentFormat = purchasedTool.projects.every(p => p.projectTotal > 0)
  const toolCanBeBought = toolHasPartsInCurrentFormat // using this as a proxy
  const AddProjectButton = () => (
    <Button onClick={() => addPurchasedToolToProject(purchasedTool)} variant="contained" color="secondary" size="small" disabled={!toolHasPartsInCurrentFormat}>
      { toolHasPartsInCurrentFormat ? 'Add to Project' : 'Cant Add to Project' }
    </Button>
  )
  const CantAddProjectButton = () => (
    <HtmlTooltip title="Tool is too old to add automatically, please email us at contact@autotiv.com to order parts from this tool!" placement="left">
      <div style={{display: 'flex'}}>
        <AddProjectButton/>
      </div>
    </HtmlTooltip>
  )
  return (
    <div className="tooling-read-only-table">
      <ToolingReadOnly
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        purchasedTool={purchasedTool}
      />
      <div style={{width: '10%', display: 'flex'}}>
      { toolHasPartsInCurrentFormat ? <AddProjectButton/> : <CantAddProjectButton/>}
      </div>
      {user.type === USER_TYPES.DME ?
       <div className="tooling-has-been-migrated-div">
         <FormControlLabel
           control={
             <Checkbox
               checked={Boolean(purchasedTool.partGroup.hasBeenMigratedToLegacyTool)}
               onClick={(e) => onChangePurchasedTool(e.target.checked, purchasedTool.partGroup.partGroupNumber)}
             />
           }
           label="Migrated to Legacy Tool"
         />
       </div>
       : null}
    </div>
  )
}
