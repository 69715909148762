export function booleanFromTrueFalseString(trueFalseString) {
    if (trueFalseString === "true") {
        return true
    }
    else if (trueFalseString === "false") {
        return false
    }
    else {
        return Boolean(trueFalseString)
    }
}
