import React from 'react'
import {Typography} from '@material-ui/core'
import {ErrorOutline} from '@material-ui/icons'
import {Link} from "@reach/router"

const NotFound = props => {
  return(
    <div style={{ display:'flex', flexDirection: 'column', alignItems:'center', marginTop:'25vh' }}>
      <ErrorOutline color="primary" fontSize="large"/>
      <Typography color="textPrimary" variant="h4">Sorry, we couldn't find the page you were looking for.</Typography>
      <br/>
      <br/>
      <Link to="/">Back to home</Link>
    </div>
  )
}

export default NotFound
