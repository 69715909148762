import React, { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core"
import { ChevronRight } from "@material-ui/icons"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const AccordionSection = ({ children, className, disabled, label }) => {
  const [isAccordionOpen,setIsAccordionOpen] = useState(false)
  if(disabled){
    return(
      label
    )
  }
  return(
    <Accordion expanded={isAccordionOpen} >
      <AccordionSummary
        className={className}
        expandIcon={<ExpandMoreIcon/>}
        onClick={()=>setIsAccordionOpen(!isAccordionOpen)}
        style={{backgroundColor: className ? '' : '#e7e7e7'}}
      >
        <div style={{display:'flex', gap:'1em'}}>
          {isAccordionOpen ? <ExpandMoreIcon/> : <ChevronRight/>}
          {label}
        </div>
      </AccordionSummary>
      <AccordionDetails className={className} style={{display:"block"}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
