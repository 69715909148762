import { Typography } from "@material-ui/core"
import HtmlTooltip from './HtmlTooltip.js'
import { Info } from "@material-ui/icons"
import {
  fileIsTooLargeToAnalyze,
  MAX_ALLOWED_FILE_SIZE_IN_BYTES,
} from '../../utils'

export default function ManualRfqTextWithTooltip({colorOverride, isCondensed, partGroup}){
  const manualRfqText = getManualRfqText(partGroup)
  return (
    isCondensed ? 
      <CondensedManualRfq colorOverride={colorOverride} style={{textAlign:"right"}} manualRfqText={manualRfqText}/>
    :
    <HtmlTooltip enterDelay={0} placement="bottom" title={
      <div style={{padding:'1em'}}>
        {manualRfqText}
      </div>
    }>
      <Typography align="center" className="informative">
        MANUAL RFQ
        <Info fontSize="small" style={{paddingLeft:"8px"}}/>
      </Typography>
    </HtmlTooltip>
  )
}

function CondensedManualRfq({colorOverride, manualRfqText}){
  return (
    manualRfqText.length ?
      <HtmlTooltip enterDelay={0} placement="bottom" title={
        <div style={{padding:'1em'}}>
          {manualRfqText}
        </div>
      }>
        <div className={manualRfqText.length ? "informative" : ""} style={{justifyContent: 'end', display: 'flex', gap: '0.25em', color: colorOverride ? colorOverride : ''}}>
          <div>
            MANUAL RFQ
          </div>
          {manualRfqText.length ? <Info style={{fontSize:"1em"}}/> : null}
        </div>
      </HtmlTooltip>
    :
    <div>
      MANUAL RFQ
    </div>
  )
}

function getManualRfqText(partGroup){
  const isCncPartMissingData = partGroup.processId === 'cnc' && !partGroup.cncSetupsPerPart
  const isIMPartMissingData = partGroup.processId === 'injMoldingPart' && partGroup.part.numberOfSlides === ''
  const isSheetMetalPartMissingData = partGroup.processId === 'sheetMetal' && !partGroup.sheetMetalCutPathLength
  const isCastingPartMissingData = partGroup.processId === 'casting' // Will need to update once we actually have casting params in the front end

  if(isCncPartMissingData || isIMPartMissingData || isSheetMetalPartMissingData || isCastingPartMissingData){
    return 'DME inputs are required to generate pricing for this process. Please submit for Manual RFQ.'
  }
  if (fileIsTooLargeToAnalyze(partGroup.part.fileSize) && !(partGroup.part.xDim > 0)) {
    const BYTES_IN_A_MB = 1048576
    const fileSizeMb = Math.round(partGroup.part.fileSize / BYTES_IN_A_MB, 0)
    const analysisLimitMb = Math.round(MAX_ALLOWED_FILE_SIZE_IN_BYTES / BYTES_IN_A_MB, 0)
    return `File size of ${fileSizeMb} MB is too big to extract dimensions. Limit is ${analysisLimitMb} MB per file. Please submit for Manual RFQ.`
  } else {
    return 'See Submit Button Hovernote for Manual Rfq Reasons'
  }
}

