import React from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import USER_TYPES from '../../utils/USER_TYPES.js'

export const EditShippingAddressDiv = ({ applyCustomerAddressToProject, fieldsToHighlight, isSavingAddress, project, saveCustomerHandler, setProjectField, updateCustomerAddressHandler, user }) => {

  const setPhoneNumber = (name, value) => {
    const newVal = value.replace(/[^0-9]/g, "")
    setProjectField(name, newVal)
  }

  function maybe(val){
    return val ? val : ''
  }

  function maybeWithComma(val){
    return val ? `, ${val}` : ''
  }

  const shippingAddressFirstHalf = (customer) => {
    const addressComponents = [
      customer.shippingContact,
      customer.shippingCompany,
      customer.shippingEmail,
      customer.shippingPhone
    ]

    return addressComponents
      .filter(Boolean)
      .join(', ')
  }

  const shippingAddressSecondHalf = (customer) => {
    const stateZipComponents = [customer.shippingAddress.state, customer.shippingAddress.zip]
    const stateZip = stateZipComponents
          .filter(Boolean)
          .join(' ')

    const addressComponents = [
      customer.shippingAddress.street,
      customer.shippingAddress.street2,
      customer.shippingAddress.city,
      stateZip,
      customer.shippingAddress.country
    ]

    return addressComponents
      .filter(Boolean)
      .join(', ')
  }

  return(
    <div style={{width:'100%'}}>
      <div style={{display:'flex', alignItems: 'center', gap: '1em'}}>
        <Button color="primary" onClick={() => applyCustomerAddressToProject()} variant="contained">
          Use Saved Address
        </Button>
        <div>
          <Typography>
            {shippingAddressFirstHalf(project.customer)}
          </Typography>
          <Typography>
            {shippingAddressSecondHalf(project.customer)}
          </Typography>
        </div>
      </div>
      <br/><br/>
      <TextField
        error={fieldsToHighlight.name}
        id="shipName"
        label="* Full Name"
        onChange={ev=>setProjectField("shipName",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipName}
        variant="outlined"
      /><br/>
      <TextField
        id="shipCompany"
        label="Company"
        onChange={ev=>setProjectField("shipCompany",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipCompany}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.country}
        id="country"
        label="* Country"
        onChange={ev=>setProjectField("shipAddress.country",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.country}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.street}
        id="street"
        label="* Street Address"
        onChange={ev=>setProjectField("shipAddress.street",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.street}
        variant="outlined"
      /><br/>
      <TextField
        id="street2"
        label="Street Address Line 2"
        onChange={ev=>setProjectField("shipAddress.street2",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.street2}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.city}
        id="city"
        label="* City"
        onChange={ev=>setProjectField("shipAddress.city",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.city}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.state}
        id="state"
        label="* State"
        onChange={ev=>setProjectField("shipAddress.state",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.state}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.zip}
        id="zip"
        label="* Zip code"
        onChange={ev=>setProjectField("shipAddress.zip",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipAddress.zip}
        variant="outlined"
      /><br/>
      <TextField
        error={fieldsToHighlight.phone}
        id="shipPhone"
        label={`${user.type !== USER_TYPES.DME ? '* ' : ''}Phone number`}
        onChange={ev=>setPhoneNumber("shipPhone",ev.target.value)}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        value={project.shipPhone}
        variant="outlined"
      /><br/>
      <TextField
        id="shipEmail"
        label="* Shipping Email"
        onChange={ev=>setProjectField("shipEmail",ev.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter'){saveCustomerHandler()}
        }}
        style={{marginBottom: '10px', minWidth:'220px', width:'100%'}}
        type="email"
        value={project.shipEmail}
        variant="outlined"
      /><br/>
      <div style={{ display: 'flex', gap: '2em', justifyContent: 'end' }}>
        <Button disabled={isSavingAddress} onClick={updateCustomerAddressHandler} variant="contained">
          {isSavingAddress ? 'Saving Address...' : 'Save Address to Account'}
        </Button>
        <Button color="primary" onClick={()=>{setTimeout(()=>{saveCustomerHandler()}, 175)}} variant="contained">
          Next
        </Button>
      </div>
    </div>
  )
}
