import { updatePartGroup } from '../utils'

export function updatePartGroupsPromise(partGroupArrayOrPartGroupObject) {
    let partGroupArray
    if (partGroupArrayOrPartGroupObject.length) {
        partGroupArray = partGroupArrayOrPartGroupObject
    }
    else {
        partGroupArray = [partGroupArrayOrPartGroupObject]
    }
    let partGroupPromises = []
    for (let partGroup of partGroupArray) { /* eslint-disable-line no-unused-vars */
        partGroupPromises.push(updatePartGroup(partGroup))
    }
    return Promise.all(partGroupPromises)
}
