/*
  updateWithQuoteTypeDefaults sets different default process, material, finish based on customerQuoteType
*/
export function updateWithQuoteTypeDefaults(newRowModel, customerAppQuoteType) {
  let newRow = { ...newRowModel }
  switch (customerAppQuoteType) {
    case 'casting':
      newRow.process = 'Casting'
      newRow.processId = 'casting'
      break;
    case 'cnc':
      newRow.process = 'CNC'
      newRow.processId = 'cnc'
      break;
    case 'dmls':
      newRow.process = 'DMLS'
      newRow.processId = 'dmls'
      break;
    case 'injectionMolding':
      newRow.process = 'Inj Mold: Part'
      newRow.processId = 'injMoldingPart'
      break;
    case 'sheetMetal':
      newRow.process = 'Sheet Metal'
      newRow.processId = 'sheetMetal'
      break;
    case '3dPrinting':
    default:
      // Normal default is fine
  }
  return newRow
}
