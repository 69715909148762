export const MATERIAL_BRACKET = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 1.00000 0.00000 0.00000
Ks 1.0 1.0 1.0

newmtl material_2
Ka 0.2 0.2 0.2
Kd 0.00000 1.00000 0.00000
Ks 1.0 1.0 1.0
`
export const MATERIAL_CUBE = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 0.00000 1.00000 0.00000
Ks 1.0 1.0 1.0

`
export const MATERIAL_CUBE_TEN_CM_GREEN = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 0.00000 1.00000 0.00000
Ks 1.0 1.0 1.0

`

export const DEFAULT_MATERIAL = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 0.82000 0.820000 0.82000
Ks 1.0 1.0 1.0

`
export const MATERIAL_CUBE_TEN_CM_RED = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 1.00000 0.00000 0.00000
Ks 1.0 1.0 1.0

`
export const MATERIAL_CUBE_TEN_INCH = `####################################
# netfabb Wavefront export         #
####################################
newmtl material_1
Ka 0.2 0.2 0.2
Kd 0.00000 1.00000 0.00000
Ks 1.0 1.0 1.0

`
export const MODEL_CUBE_TEN_INCH = `####################################
# netfabb Wavefront export         #
####################################
mtllib 10inCube_exportedAsInches_(analysisadded).mtl
v 10.00000 0.00000 10.00000
v 0.00000 0.00000 10.00000
v 10.00000 0.00000 0.00000
v 10.00000 10.00000 10.00000
v 10.00000 10.00000 0.00000
v 0.00000 10.00000 0.00000
v 0.00000 10.00000 10.00000
v 0.00000 0.00000 0.00000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
usemtl material_1
f 1//9 2//9 3//9
f 4//8 5//8 6//8
f 7//7 4//7 6//7
f 1//12 3//12 5//12
f 4//11 1//11 5//11
f 2//10 8//10 3//10
f 4//3 7//3 1//3
f 5//2 3//2 8//2
f 6//1 5//1 8//1
f 7//6 6//6 8//6
f 2//5 7//5 8//5
f 7//4 2//4 1//4
####################################
`
export const MODEL_BRACKET = `####################################
# netfabb Wavefront export         #
####################################
mtllib objExport.mtl
v 63.43926 70.15954 10.49987
v 63.51426 70.10621 10.48665
v 63.48730 70.15954 11.04900
v 63.56347 70.10621 11.04900
v 63.43926 70.15954 11.59813
v 63.29659 70.15954 9.96743
v 63.12959 70.10621 9.42977
v 63.36816 70.10621 9.94138
v 63.29659 70.15954 12.13057
v 63.36816 70.10621 12.15662
v 63.06363 70.15954 12.63015
v 19.05000 6.31491 21.82210
v 7.62000 6.31491 21.82210
v 7.62000 6.30106 21.74113
v 19.05000 6.30106 21.74113
v 63.51426 70.10621 11.61135
v 7.62000 7.31310 19.16153
v 19.05000 7.28910 19.23337
v 7.62000 7.28910 19.23337
v 19.05000 7.01564 18.08738
v 7.62000 7.01564 18.08738
v 7.62000 6.99869 18.03079
v 19.05000 6.99869 18.03079
v 7.62000 7.32616 19.08692
v 19.05000 7.32616 19.08692
v 19.05000 7.31310 19.16153
v 19.05000 6.98844 17.97261
v 7.62000 6.98500 17.91364
v 19.05000 6.98500 17.91364
v 63.06363 70.15954 9.46785
v 62.80581 70.10621 8.96736
v 7.62000 6.98844 17.97261
v 7.62000 6.30043 21.65898
v 62.74746 70.15954 13.08169
v 63.12959 70.10621 12.66823
v 62.80581 70.10621 13.13064
v 62.35769 70.15954 13.47146
v 62.40664 70.10621 13.52981
v 19.05000 8.54219 22.59804
v 7.62000 8.46966 22.64883
v 19.05000 8.46966 22.64883
v 61.40657 70.15954 14.02059
v 61.94423 70.10621 13.85359
v 61.43262 70.10621 14.09216
v 60.87413 70.15954 14.16326
v 7.62000 9.55564 18.08738
v 19.05000 9.55564 18.08738
v 19.05000 9.53869 18.03079
v 61.90615 70.15954 13.78763
v 19.05000 6.31306 21.57781
v 7.62000 6.31306 21.57781
v 7.62000 6.33860 21.49973
v 19.05000 6.33860 21.49973
v 19.05000 8.69302 22.38264
v 7.62000 8.69302 22.38264
v 7.62000 8.65560 22.46289
v 19.05000 6.30043 21.65898
v 19.05000 8.60481 22.53543
v 7.62000 8.60481 22.53543
v 7.62000 8.54219 22.59804
v 19.05000 8.65560 22.46289
v 57.43259 70.19884 9.99625
v 57.35341 70.15954 9.96743
v 57.65935 70.19884 9.50998
v 57.58637 70.15954 9.46785
v 57.96709 70.19884 9.07048
v 57.29373 70.19884 10.51451
v 57.16270 70.15954 11.04900
v 57.21074 70.15954 10.49987
v 90.42400 36.06900 3.17500
v 90.42400 21.39950 3.17500
v 90.42400 36.06900 0.00000
v 93.21800 21.39950 0.00000
v 93.21800 54.80050 3.17500
v 93.21800 54.80050 0.00000
v 93.21800 21.39950 3.17500
v 57.90254 70.15954 9.01631
v 58.34648 70.19884 8.69109
v 58.29231 70.15954 8.62654
v 57.96709 70.19884 13.02752
v 57.90254 70.15954 13.08169
v 57.65935 70.19884 12.58802
v 57.58637 70.15954 12.63015
v 57.43259 70.19884 12.10175
v 58.10684 70.23100 9.18774
v 58.46375 70.23100 8.83084
v 58.87720 70.23100 8.54134
v 58.34648 70.19884 13.40691
v 58.29231 70.15954 13.47146
v 57.29373 70.19884 11.58350
v 57.21074 70.15954 11.59813
v 57.24697 70.19884 11.04900
v 57.35341 70.15954 12.13057
v 96.77399 21.39950 0.00000
v 96.77399 54.80050 3.17500
v 96.77399 54.80050 0.00000
v 19.05000 13.49584 19.01456
v 7.62000 13.50570 18.94438
v 19.05000 13.50570 18.94438
v 7.62000 13.52524 18.87626
v 19.05000 13.52524 18.87626
v 19.05000 13.49584 19.08544
v 7.62000 13.49584 19.08544
v 7.62000 13.49584 19.01456
v 7.62000 7.31852 18.93606
v 19.05000 7.32798 19.01121
v 7.62000 7.32798 19.01121
v 7.62000 7.29799 18.86315
v 19.05000 7.29799 18.86315
v 19.05000 7.31852 18.93606
v 7.62000 13.84300 17.91364
v 19.05000 13.83957 17.97261
v 7.62000 13.83957 17.97261
v 19.05000 13.82931 18.03079
v 7.62000 13.82931 18.03079
v 96.77399 21.39950 3.17500
v 62.74746 70.15954 9.01631
v 19.05000 13.84300 17.91364
v 19.05000 13.52524 19.22375
v 7.62000 13.50570 19.15562
v 19.05000 13.50570 19.15562
v 19.05000 13.81236 18.08738
v 7.62000 13.81236 18.08738
v 7.62000 13.52524 19.22375
v 101.60000 66.67500 17.91364
v 101.60000 66.67500 11.40434
v 113.03000 66.67500 17.91364
v 57.10279 5.96900 9.87621
v 57.69823 5.96900 13.25312
v 57.35540 5.96900 9.33450
v 56.94810 5.96900 10.45356
v 101.60000 66.64436 18.08738
v 113.03000 66.35724 18.87626
v 101.60000 66.35724 18.87626
v 113.03000 66.64436 18.08738
v 113.03000 66.67500 11.40434
v 60.92044 5.96900 7.67209
v 62.52912 5.96900 8.42223
v 61.49779 5.96900 7.82679
v 62.95177 5.96900 8.84488
v 60.32500 5.96900 7.62000
v 56.94810 5.96900 11.64444
v 56.89600 5.96900 11.04900
v 62.03950 5.96900 8.07940
v 113.03000 69.21500 17.91364
v 113.03000 69.21500 8.89000
v 101.60000 69.21500 17.91364
v 101.60000 68.90201 18.86315
v 113.03000 69.18436 18.08738
v 101.60000 69.18436 18.08738
v 113.03000 68.90201 18.86315
v 113.03000 59.81700 17.91364
v 101.60000 59.81700 8.89000
v 113.03000 59.81700 8.89000
v 101.60000 59.81700 17.91364
v 101.60000 69.21500 8.89000
v 19.05000 17.05494 21.57781
v 7.62000 17.06757 21.65898
v 19.05000 17.06757 21.65898
v 113.03000 67.50698 22.38264
v 101.60000 66.35724 19.22375
v 113.03000 66.35724 19.22375
v 101.60000 67.50698 22.38264
v 113.03000 68.91090 19.23337
v 101.60000 69.86140 21.49973
v 113.03000 69.86140 21.49973
v 101.60000 68.91090 19.23337
v 7.62000 17.05494 21.57781
v 63.29461 5.96900 9.33450
v 59.15221 5.96900 14.27121
v 58.61050 5.96900 14.01860
v 59.15221 5.96900 7.82679
v 59.72956 5.96900 14.42591
v 59.72956 5.96900 7.67209
v 62.52912 5.96900 13.67577
v 62.95177 5.96900 13.25312
v 61.49779 5.96900 14.27121
v 58.12088 5.96900 13.67577
v 58.61050 5.96900 8.07940
v 60.92044 5.96900 14.42591
v 89.66200 39.95676 3.17500
v 89.66200 39.95676 0.00000
v 89.79252 40.00946 0.00000
v 60.32500 5.96900 14.47800
v 63.70190 5.96900 10.45356
v 63.54721 5.96900 9.87621
v 57.35540 5.96900 12.76350
v 57.10279 5.96900 12.22179
v 63.70190 5.96900 11.64444
v 63.75400 5.96900 11.04900
v 58.12088 5.96900 8.42223
v 57.69823 5.96900 8.84488
v 63.29461 5.96900 12.76350
v 63.54721 5.96900 12.22179
v 60.88736 70.10621 14.23826
v 60.32500 70.15954 14.21130
v 60.32500 70.10621 14.28746
v 19.05000 9.87216 19.08544
v 7.62000 9.86230 19.15562
v 19.05000 9.86230 19.15562
v 7.62000 9.84276 19.22375
v 19.05000 9.84276 19.22375
v 59.24343 70.15954 14.02059
v 59.76265 70.10621 14.23826
v 59.21738 70.10621 14.09216
v 58.74385 70.15954 13.78763
v 58.70577 70.10621 13.85359
v 59.77588 70.15954 14.16326
v 7.62000 9.52844 17.97261
v 19.05000 9.52844 17.97261
v 19.05000 9.52500 17.91364
v 7.62000 9.52500 17.91364
v 19.05000 9.84276 18.87626
v 7.62000 9.84276 18.87626
v 7.62000 9.86230 18.94438
v 7.62000 9.53869 18.03079
v 19.05000 9.87216 19.01456
v 7.62000 9.87216 19.01456
v 7.62000 9.87216 19.08544
v 19.05000 9.86230 18.94438
v 101.60000 62.38764 18.08738
v 101.60000 62.67476 18.87626
v 113.03000 62.38764 18.08738
v 113.03000 62.67476 19.22375
v 101.60000 61.52502 22.38264
v 113.03000 61.52502 22.38264
v 101.60000 62.67476 19.22375
v 101.60000 62.35700 17.91364
v 113.03000 62.35700 11.40434
v 101.60000 62.35700 11.40434
v 113.03000 62.35700 17.91364
v 113.03000 62.67476 18.87626
v 101.60000 59.17060 21.49973
v 101.60000 60.12110 19.23337
v 113.03000 59.17060 21.49973
v 113.03000 60.12999 18.86315
v 101.60000 59.84764 18.08738
v 113.03000 59.84764 18.08738
v 101.60000 60.12999 18.86315
v 19.05000 17.02940 21.49973
v 7.62000 17.02940 21.49973
v 113.03000 60.12110 19.23337
v 19.05000 14.82581 22.59804
v 7.62000 14.76319 22.53543
v 19.05000 14.76319 22.53543
v 7.62000 14.71240 22.46289
v 19.05000 14.71240 22.46289
v 19.05000 14.89834 22.64883
v 7.62000 14.89834 22.64883
v 7.62000 14.82581 22.59804
v 7.62000 14.67498 22.38264
v 19.05000 14.67498 22.38264
v 113.03000 63.37300 12.70000
v 101.60000 63.37300 10.16000
v 113.03000 63.37300 10.16000
v 101.60000 63.37300 12.70000
v 113.03000 65.65900 10.16000
v 101.60000 65.65900 12.70000
v 113.03000 65.65900 12.70000
v 101.60000 65.65900 10.16000
v 59.33465 70.23100 13.76997
v 58.87720 70.23100 13.55666
v 58.46375 70.23100 13.26716
v 61.81911 70.22290 13.63688
v 61.86402 70.19884 13.71465
v 61.34703 70.22290 13.85701
v 61.37775 70.19884 13.94141
v 60.84390 70.22290 13.99183
v 62.24579 70.22290 13.33811
v 62.68291 70.19884 13.02752
v 62.30352 70.19884 13.40691
v 60.32500 70.22290 14.03722
v 60.32500 70.19884 14.12703
v 59.80610 70.22290 13.99183
v 59.79051 70.19884 14.08027
v 59.30297 70.22290 13.85701
v 60.85949 70.19884 14.08027
v 63.31322 70.22290 11.04900
v 63.40303 70.19884 11.04900
v 63.26783 70.22290 11.56790
v 63.35627 70.19884 11.58350
v 63.13301 70.22290 12.07103
v 63.26783 70.22290 10.53010
v 63.21741 70.19884 9.99625
v 63.35627 70.19884 10.51451
v 62.91288 70.22290 12.54311
v 62.99066 70.19884 12.58802
v 62.61411 70.22290 12.96979
v 63.21741 70.19884 12.10175
v 59.27225 70.19884 13.94141
v 57.51699 70.22290 12.07103
v 57.38218 70.22290 11.56790
v 113.03000 66.65644 11.18800
v 101.60000 66.65644 11.18800
v 101.60000 66.60130 10.97799
v 57.38218 70.22290 10.53010
v 57.51699 70.22290 10.02697
v 57.33678 70.22290 11.04900
v 58.40421 70.22290 13.33811
v 58.78598 70.19884 13.71465
v 58.03589 70.22290 12.96979
v 58.83089 70.22290 13.63688
v 113.03000 66.60130 10.97799
v 101.60000 66.51118 10.78044
v 113.03000 66.51118 10.78044
v 57.73712 70.22290 12.54311
v 113.03000 68.87384 19.08692
v 101.60000 68.87384 19.08692
v 101.60000 68.88690 19.16153
v 113.03000 68.87202 19.01121
v 101.60000 68.87202 19.01121
v 113.03000 68.88690 19.16153
v 101.60000 68.88148 18.93606
v 101.60000 62.69430 18.94438
v 113.03000 62.69430 18.94438
v 113.03000 68.88148 18.93606
v 60.32500 70.23100 8.15340
v 59.80610 70.22290 8.10617
v 60.32500 70.22290 8.06078
v 60.82782 70.23100 8.19739
v 60.84390 70.22290 8.10617
v 59.33465 70.23100 8.32803
v 59.30297 70.22290 8.24099
v 59.82218 70.23100 8.19739
v 101.60000 69.20131 18.03079
v 113.03000 69.20131 18.03079
v 113.03000 69.21156 17.97261
v 101.60000 69.21156 17.97261
v 101.60000 62.70416 19.01456
v 61.81911 70.22290 8.46112
v 61.86402 70.19884 8.38334
v 62.24579 70.22290 8.75989
v 62.30352 70.19884 8.69109
v 62.61411 70.22290 9.12821
v 101.60000 62.36044 17.97261
v 61.34703 70.22290 8.24099
v 61.37775 70.19884 8.15660
v 62.91288 70.22290 9.55489
v 62.99066 70.19884 9.50998
v 63.13301 70.22290 10.02697
v 62.68291 70.19884 9.07048
v 101.60000 62.69430 19.15562
v 113.03000 62.70416 19.08544
v 101.60000 62.70416 19.08544
v 113.03000 62.69430 19.15562
v 113.03000 62.70416 19.01456
v 113.03000 62.36044 17.97261
v 101.60000 62.37070 18.03079
v 113.03000 62.37070 18.03079
v 57.81734 70.23100 9.60120
v 62.54316 70.23100 9.18774
v 63.17661 70.23100 11.55182
v 62.18626 70.23100 8.83084
v 60.32500 70.23100 13.94460
v 59.82218 70.23100 13.90061
v 57.47339 70.23100 11.55182
v 57.42940 70.23100 11.04900
v 57.60403 70.23100 12.03935
v 19.05000 13.84300 11.40434
v 7.62000 13.84300 11.40434
v 7.62000 13.82444 11.18800
v 19.05000 13.82444 11.18800
v 57.81734 70.23100 12.49680
v 57.47339 70.23100 10.54618
v 58.10684 70.23100 12.91026
v 62.83266 70.23100 9.60120
v 61.77280 70.23100 8.54134
v 62.83266 70.23100 12.49680
v 61.31535 70.23100 8.32803
v 63.04597 70.23100 10.05865
v 63.22060 70.23100 11.04900
v 63.17661 70.23100 10.54618
v 7.62000 13.76930 10.97799
v 7.62000 9.54356 11.18800
v 19.05000 9.54356 11.18800
v 19.05000 9.59870 10.97799
v 7.62000 9.59870 10.97799
v 19.05000 9.68882 10.78044
v 61.77280 70.23100 13.55666
v 7.62000 9.52500 11.40434
v 19.05000 9.52500 11.40434
v 63.04597 70.23100 12.03935
v 7.62000 9.68882 10.78044
v 57.60403 70.23100 10.05865
v 19.05000 13.76930 10.97799
v 7.62000 13.67918 10.78044
v 19.05000 13.67918 10.78044
v 62.18626 70.23100 13.26716
v 62.54316 70.23100 12.91026
v 60.82782 70.23100 13.90061
v 61.31535 70.23100 13.76997
v 89.66200 54.80050 3.17500
v 89.66200 54.80050 0.00000
v 90.42400 21.39950 0.00000
v 58.40421 70.22290 8.75989
v 58.83089 70.22290 8.46112
v 58.78598 70.19884 8.38334
v 62.35769 70.15954 8.62654
v 101.60000 62.37556 11.18800
v 113.03000 62.43070 10.97799
v 101.60000 62.43070 10.97799
v 113.03000 62.37556 11.18800
v 57.73712 70.22290 9.55489
v 113.03000 62.52082 10.78044
v 101.60000 62.52082 10.78044
v 58.03589 70.22290 9.12821
v 88.93090 39.40614 4.25661
v 87.84255 41.25212 5.71500
v 87.33538 40.74494 5.71500
v 89.18136 39.65660 4.25661
v 88.43010 41.66352 5.71500
v 88.57805 38.79499 4.25661
v 86.92398 40.15740 5.71500
v 86.62085 39.50734 5.71500
v 88.72774 39.11600 4.25661
v 87.84255 34.94788 5.71500
v 87.19707 35.32444 5.71500
v 87.72929 34.79222 5.71500
v 88.43010 34.53648 5.71500
v 88.34586 34.36050 5.71500
v 89.47150 39.85976 4.25661
v 89.08015 41.96665 5.71500
v 87.33538 35.45506 5.71500
v 45.52074 25.64061 6.35000
v 52.22727 25.40110 6.35000
v 48.34645 25.90410 7.62000
v 56.36093 25.61786 7.62000
v 88.57805 37.40501 4.25661
v 86.62085 36.69266 5.71500
v 88.72774 37.08400 4.25661
v 86.92398 36.04260 5.71500
v 88.93090 36.79386 4.25661
v 58.50767 28.19395 7.62000
v 88.45550 38.10000 4.25661
v 86.43521 38.81453 5.71500
v 86.37270 38.10000 5.71500
v 88.48637 38.45285 4.25661
v 86.43521 37.38547 5.71500
v 88.48637 37.74715 4.25661
v 89.08015 34.23335 5.71500
v 94.05102 36.04260 5.71500
v 93.81263 35.32444 5.71500
v 94.24435 35.94100 5.71500
v 94.35416 36.69266 5.71500
v 94.56245 36.62316 5.71500
v 93.13245 34.94788 5.71500
v 92.66386 34.36050 5.71500
v 93.28041 34.79222 5.71500
v 90.13465 40.10113 4.25661
v 89.77297 42.15229 5.71500
v 89.79252 40.00946 4.25661
v 55.75692 58.97880 7.62000
v 51.95474 58.42165 6.35000
v 57.50936 59.06753 7.62000
v 94.60230 38.10000 5.71500
v 94.75726 37.35019 5.71500
v 94.82285 38.10000 5.71500
v 94.53979 38.81453 5.71500
v 94.75726 38.84981 5.71500
v 94.53979 37.38547 5.71500
v 90.48750 33.98520 5.71500
v 90.50485 33.78200 5.71500
v 91.20203 34.04771 5.71500
v 89.02802 34.04241 5.71500
v 89.77297 34.04771 5.71500
v 89.75504 33.84760 5.71500
v 91.89485 34.23335 5.71500
v 91.98170 34.04241 5.71500
v 92.54490 34.53648 5.71500
v 91.25467 33.84760 5.71500
v 9.03316 21.11670 6.35000
v 9.03451 21.03641 6.62743
v 8.66700 21.05988 6.35000
v 7.28420 20.84531 6.35000
v 0.36417 22.16553 6.35000
v 0.36417 22.06441 6.35000
v 0.31397 22.12105 6.52505
v 92.48863 38.45285 4.25661
v 92.48863 37.74715 4.25661
v 92.51950 38.10000 4.25661
v 92.39696 37.40501 4.25661
v 0.20369 22.02330 6.90964
v 0.20369 22.24547 6.90964
v 32.21990 41.66352 5.71500
v 32.93806 41.40778 5.71500
v 32.32150 41.83950 5.71500
v 31.56985 41.96665 5.71500
v 31.63935 42.15759 5.71500
v 33.72602 40.15740 5.71500
v 33.90200 40.25900 5.71500
v 33.31462 40.74494 5.71500
v 33.47028 40.87556 5.71500
v 32.80744 41.25212 5.71500
v 30.16250 42.21480 5.71500
v 30.91231 42.35240 5.71500
v 30.16250 42.41800 5.71500
v 69.21500 71.75500 11.04900
v 51.43500 71.75500 8.89000
v 51.43500 71.75500 11.04900
v 69.21500 71.75500 8.89000
v 30.87703 42.15229 5.71500
v 90.13465 36.09887 4.25661
v 89.79252 36.19054 4.25661
v 90.84035 36.09887 4.25661
v 91.18249 36.19054 4.25661
v 90.48750 36.06800 4.25661
v 89.18136 36.54340 4.25661
v 58.10522 29.11247 6.35000
v 61.97297 28.67579 6.35000
v 58.07832 29.48199 7.62000
v 89.47150 36.34024 4.25661
v 0.46224 21.78684 7.62000
v 92.24726 37.08400 4.25661
v 93.63962 35.45506 5.71500
v 92.04410 36.79386 4.25661
v 91.50350 36.34024 4.25661
v 91.79364 36.54340 4.25661
v 28.60590 39.40614 4.25661
v 27.51756 41.25212 5.71500
v 27.01038 40.74494 5.71500
v 28.40274 39.11600 4.25661
v 32.07196 37.40501 4.25661
v 34.02915 36.69266 5.71500
v 32.16363 37.74715 4.25661
v 29.14650 39.85976 4.25661
v 28.10510 41.66352 5.71500
v 28.85636 39.65660 4.25661
v 28.16137 38.45285 4.25661
v 26.29585 39.50734 5.71500
v 26.11021 38.81453 5.71500
v 33.72602 36.04260 5.71500
v 28.13050 38.10000 4.25661
v 26.04770 38.10000 5.71500
v 26.59898 40.15740 5.71500
v 28.25304 38.79499 4.25661
v 30.51535 40.10113 4.25661
v 30.16250 40.13200 4.25661
v 31.17850 39.85976 4.25661
v 30.85748 40.00946 4.25661
v 29.46752 40.00946 4.25661
v 29.44797 42.15229 5.71500
v 28.75515 41.96665 5.71500
v 34.21479 37.38547 5.71500
v 32.19450 38.10000 4.25661
v 29.80965 40.10113 4.25661
v 31.92227 37.08400 4.25661
v 31.71910 36.79386 4.25661
v 33.31462 35.45506 5.71500
v 32.80744 34.94788 5.71500
v 30.16250 36.06800 4.25661
v 30.16250 33.98520 5.71500
v 30.51535 36.09887 4.25661
v 29.46752 36.19054 4.25661
v 28.10510 34.53648 5.71500
v 28.75515 34.23335 5.71500
v 29.80965 36.09887 4.25661
v 29.44797 34.04771 5.71500
v 30.85748 36.19054 4.25661
v 31.56985 34.23335 5.71500
v 31.17850 36.34024 4.25661
v 32.21990 34.53648 5.71500
v 31.46865 36.54340 4.25661
v 30.87703 34.04771 5.71500
v 28.25304 37.40501 4.25661
v 26.29585 36.69266 5.71500
v 28.40274 37.08400 4.25661
v 26.59898 36.04260 5.71500
v 28.60590 36.79386 4.25661
v 28.16137 37.74715 4.25661
v 26.11021 37.38547 5.71500
v 28.85636 36.54340 4.25661
v 27.51756 34.94788 5.71500
v 29.14650 36.34024 4.25661
v 27.01038 35.45506 5.71500
v 91.18249 40.00946 4.25661
v 91.89485 41.96665 5.71500
v 90.84035 40.10113 4.25661
v 92.54490 41.66352 5.71500
v 89.75504 42.35240 5.71500
v 91.20203 42.15229 5.71500
v 91.98170 42.15759 5.71500
v 91.25467 42.35240 5.71500
v 90.48750 42.21480 5.71500
v 90.50485 42.41800 5.71500
v 88.34586 41.83950 5.71500
v 87.72929 41.40778 5.71500
v 89.02802 42.15759 5.71500
v 94.05102 40.15740 5.71500
v 94.56245 39.57684 5.71500
v 94.24435 40.25900 5.71500
v 90.48750 40.13200 4.25661
v 94.35416 39.50734 5.71500
v 92.66386 41.83950 5.71500
v 93.13245 41.25212 5.71500
v 93.28041 41.40778 5.71500
v 87.19707 40.87556 5.71500
v 32.07196 38.79499 4.25661
v 34.21479 38.81453 5.71500
v 34.02915 39.50734 5.71500
v 31.92227 39.11600 4.25661
v 86.76536 35.94100 5.71500
v 32.16363 38.45285 4.25661
v 31.46865 39.65660 4.25661
v 34.27730 38.10000 5.71500
v 31.71910 39.40614 4.25661
v 91.79364 39.65660 4.25661
v 91.50350 39.85976 4.25661
v 93.63962 40.74494 5.71500
v 92.04410 39.40614 4.25661
v 86.76536 40.25900 5.71500
v 92.39696 38.79499 4.25661
v 92.24726 39.11600 4.25661
v 34.22009 39.57684 5.71500
v 62.85617 70.04047 8.92510
v 63.18653 70.04047 9.39690
v 71.41288 0.46224 7.62000
v 67.58356 0.00000 6.35000
v 76.28450 0.46224 7.62000
v 79.33838 0.00000 6.35000
v 89.79252 36.19054 0.00000
v 89.66200 36.24324 0.00000
v 89.66200 36.24324 3.17500
v 29.21000 39.89493 3.17500
v 29.21000 39.89493 0.00000
v 29.46752 40.00946 0.00000
v 63.42994 70.04047 9.91889
v 63.57901 70.04047 10.47523
v 63.53359 16.25600 2.08069
v 65.01945 9.52500 2.76119
v 65.01945 16.25600 2.76119
v 78.80132 0.19621 6.88907
v 100.32999 21.39950 0.00000
v 100.32999 54.80050 3.17500
v 100.32999 54.80050 0.00000
v 100.32999 21.39950 3.17500
v 57.28184 70.10621 9.94138
v 57.52041 70.10621 9.42977
v 57.84420 70.10621 8.96736
v 103.88600 21.39950 3.17500
v 103.88600 54.80050 3.17500
v 103.88600 21.39950 0.00000
v 90.13465 36.09887 0.00000
v 90.13465 40.10113 0.00000
v 103.88600 54.80050 0.00000
v 63.53359 9.52500 2.08069
v 63.57901 70.04047 11.62277
v 63.42994 70.04047 12.17911
v 63.18653 70.04047 12.70110
v 63.62921 70.04047 11.04900
v 62.85617 70.04047 13.17290
v 62.44890 70.04047 13.58017
v 0.20369 46.20989 6.90964
v 0.31539 46.33548 6.52011
v 0.20369 46.43158 6.90964
v 60.32500 16.25600 1.52400
v 60.32500 9.52500 1.52400
v 61.95326 9.52500 1.66420
v 58.69674 16.25600 1.66420
v 58.69674 9.52500 1.66420
v 61.95326 16.25600 1.66420
v 55.63055 16.25600 2.76119
v 55.63055 9.52500 2.76119
v 57.11641 9.52500 2.08069
v 57.11641 16.25600 2.08069
v 61.90411 66.67500 1.65581
v 63.43951 59.94400 2.04759
v 63.43951 66.67500 2.04759
v 61.90411 59.94400 1.65581
v 7.62000 16.07891 19.23337
v 19.05000 16.07891 19.23337
v 19.05000 16.05490 19.16153
v 57.28184 70.10621 12.15662
v 64.88873 59.94400 2.68849
v 64.88873 66.67500 2.68849
v 7.62000 16.04184 19.08692
v 19.05000 16.04184 19.08692
v 19.05000 16.04002 19.01121
v 7.62000 16.04002 19.01121
v 19.05000 16.04948 18.93606
v 7.62000 16.05490 19.16153
v 19.05000 17.06694 21.74113
v 7.62000 17.06694 21.74113
v 7.62000 17.05309 21.82210
v 19.05000 17.05309 21.82210
v 58.24335 70.10621 13.52981
v 62.03950 5.96900 14.01860
v 57.84420 70.10621 13.13064
v 57.52041 70.10621 12.66823
v 7.62000 16.04948 18.93606
v 55.76128 66.67500 2.68849
v 55.76128 59.94400 2.68849
v 57.21049 59.94400 2.04759
v 57.13574 70.10621 11.61135
v 57.21049 66.67500 2.04759
v 58.74589 59.94400 1.65581
v 58.74589 66.67500 1.65581
v 57.13574 70.10621 10.48665
v 57.08654 70.10621 11.04900
v 19.05000 16.38300 17.91364
v 7.62000 16.37956 17.97261
v 19.05000 16.37956 17.97261
v 7.62000 16.36931 18.03079
v 19.05000 16.36931 18.03079
v 19.05000 16.07001 18.86315
v 7.62000 16.07001 18.86315
v 7.62000 16.38300 17.91364
v 60.32500 66.67500 1.52400
v 60.32500 59.94400 1.52400
v 7.62000 16.35237 18.08738
v 19.05000 16.35237 18.08738
v 26.85472 40.87556 5.71500
v 26.42300 40.25900 5.71500
v 26.42300 35.94100 5.71500
v 27.38694 41.40778 5.71500
v 27.38694 34.79222 5.71500
v 28.00350 34.36050 5.71500
v 26.85472 35.32444 5.71500
v 69.10555 4.44500 12.43970
v 68.24605 4.44500 15.08498
v 51.43500 4.44500 11.04900
v 51.43500 4.44500 8.89000
v 69.21500 4.44500 8.89000
v 120.43954 51.65455 6.92822
v 120.35809 51.58426 6.66046
v 120.43393 51.49806 6.94367
v 78.86387 0.34165 6.35000
v 69.21500 4.44500 11.04900
v 28.68566 42.15759 5.71500
v 28.00350 41.83950 5.71500
v 29.41269 42.35240 5.71500
v 28.68566 34.04241 5.71500
v 34.22009 36.62316 5.71500
v 34.41490 37.35019 5.71500
v 33.47028 35.32444 5.71500
v 33.90200 35.94100 5.71500
v 34.41490 38.84981 5.71500
v 34.48050 38.10000 5.71500
v 30.16250 33.78200 5.71500
v 30.91231 33.84760 5.71500
v 31.63935 34.04241 5.71500
v 29.41269 33.84760 5.71500
v 32.32150 34.36050 5.71500
v 32.93806 34.79222 5.71500
v 68.77989 71.75500 13.79616
v 68.24605 71.75500 15.08498
v 58.67290 70.04047 13.91053
v 58.20110 70.04047 13.58017
v 57.79383 70.04047 13.17290
v 59.19490 70.04047 14.15394
v 57.46347 70.04047 12.70110
v 57.22006 70.04047 12.17911
v 57.07099 70.04047 11.62277
v 0.46224 46.67662 7.62000
v 61.45510 70.04047 14.15394
v 60.89877 70.04047 14.30301
v 61.97710 70.04047 13.91053
v 60.32500 70.04047 14.35321
v 59.75123 70.04047 14.30301
v 107.44200 21.39950 0.00000
v 107.44200 54.80050 3.17500
v 107.44200 54.80050 0.00000
v 107.44200 21.39950 3.17500
v 110.99800 21.39950 0.00000
v 110.99800 54.80050 3.17500
v 110.99800 54.80050 0.00000
v 29.46752 36.19054 0.00000
v 29.21000 36.30507 0.00000
v 29.21000 36.30507 3.17500
v 73.67940 1.32917 6.35000
v 73.68431 0.95752 7.62000
v 110.99800 21.39950 3.17500
v 57.07099 70.04047 10.47523
v 57.22006 70.04047 9.91889
v 57.02079 70.04047 11.04900
v 29.80965 40.10113 0.00000
v 29.80965 36.09887 0.00000
v 57.46347 70.04047 9.39690
v 51.30800 43.30700 0.00000
v 51.30800 54.80050 0.00000
v 51.30800 43.30700 3.17500
v 54.10200 32.89300 3.17500
v 54.10200 21.39950 0.00000
v 54.10200 21.39950 3.17500
v 50.54600 43.30700 3.17500
v 50.54600 54.80050 3.17500
v 50.54600 43.30700 0.00000
v 51.30800 54.80050 3.17500
v 54.10200 32.89300 0.00000
v 7.62000 62.43070 10.97799
v 19.05000 62.43070 10.97799
v 19.05000 62.52082 10.78044
v 7.62000 62.37556 11.18800
v 54.86400 32.89300 0.00000
v 54.86400 21.39950 3.17500
v 54.86400 21.39950 0.00000
v 7.62000 62.52082 10.78044
v 50.54600 54.80050 0.00000
v 19.05000 66.60130 10.97799
v 7.62000 66.51118 10.78044
v 19.05000 66.51118 10.78044
v 7.62000 66.60130 10.97799
v 50.54600 32.89300 0.00000
v 50.54600 21.39950 0.00000
v 50.54600 32.89300 3.17500
v 51.30800 32.89300 0.00000
v 51.30800 21.39950 3.17500
v 51.30800 21.39950 0.00000
v 19.05000 66.65644 11.18800
v 19.05000 66.67500 11.40434
v 7.62000 66.67500 11.40434
v 7.62000 66.65644 11.18800
v 51.30800 32.89300 3.17500
v 113.03000 13.83957 17.97261
v 101.60000 13.84300 17.91364
v 113.03000 13.84300 17.91364
v 101.60000 13.52524 18.87626
v 113.03000 13.52524 18.87626
v 113.03000 13.50570 18.94438
v 113.03000 13.82931 18.03079
v 101.60000 13.83957 17.97261
v 28.13050 38.10000 3.17500
v 27.27325 21.01850 3.17500
v 27.27325 55.56250 3.17500
v 101.60000 13.50570 18.94438
v 113.03000 13.49584 19.01456
v 101.60000 13.49584 19.01456
v 113.03000 13.49584 19.08544
v 101.60000 13.82931 18.03079
v 32.48025 21.01850 3.17500
v 32.19450 38.10000 3.17500
v 32.48025 55.56250 3.17500
v 7.62000 62.35700 11.40434
v 19.05000 62.35700 11.40434
v 19.05000 62.37556 11.18800
v 54.86400 32.89300 3.17500
v 113.03000 13.81236 18.08738
v 101.60000 13.81236 18.08738
v 54.10200 43.30700 0.00000
v 54.10200 54.80050 3.17500
v 54.10200 54.80050 0.00000
v 54.10200 43.30700 3.17500
v 47.75200 54.80050 3.17500
v 48.13300 55.56250 3.17500
v 47.75200 21.39950 3.17500
v 48.13300 21.01850 3.17500
v 101.60000 9.52500 11.40434
v 113.03000 9.54356 11.18800
v 101.60000 9.54356 11.18800
v 113.03000 9.59870 10.97799
v 101.60000 9.59870 10.97799
v 113.03000 9.68882 10.78044
v 101.60000 9.68882 10.78044
v 40.64000 21.39950 3.17500
v 42.03700 55.56250 3.17500
v 42.03700 21.01850 3.17500
v 46.99000 21.39950 3.17500
v 44.19600 21.39950 3.17500
v 46.99000 54.80050 3.17500
v 113.03000 9.52500 11.40434
v 43.43400 21.39950 3.17500
v 43.43400 54.80050 3.17500
v 14.98600 21.39950 3.17500
v 14.98600 54.80050 3.17500
v 14.98600 21.39950 0.00000
v 36.32200 21.39950 0.00000
v 36.32200 54.80050 3.17500
v 36.32200 54.80050 0.00000
v 36.32200 21.39950 3.17500
v 14.98600 54.80050 0.00000
v 11.43000 21.39950 0.00000
v 11.43000 54.80050 3.17500
v 11.43000 54.80050 0.00000
v 44.19600 54.80050 3.17500
v 11.43000 21.39950 3.17500
v 29.21000 21.39950 0.00000
v 29.21000 21.39950 3.17500
v 7.87400 21.39950 3.17500
v 7.87400 54.80050 3.17500
v 7.87400 21.39950 0.00000
v 7.87400 54.80050 0.00000
v 29.97200 40.12305 3.17500
v 29.97200 54.80050 0.00000
v 29.97200 54.80050 3.17500
v 29.97200 40.12305 0.00000
v 50.54600 21.39950 3.17500
v 46.99000 21.39950 0.00000
v 46.99000 54.80050 0.00000
v 90.42400 40.13101 0.00000
v 90.42400 54.80050 0.00000
v 90.42400 40.13101 3.17500
v 8.63600 54.80050 3.17500
v 8.63600 21.39950 3.17500
v 8.63600 54.80050 0.00000
v 8.63600 21.39950 0.00000
v 39.87800 21.39950 0.00000
v 39.87800 54.80050 3.17500
v 39.87800 54.80050 0.00000
v 39.87800 21.39950 3.17500
v 89.66200 21.39950 0.00000
v 89.66200 21.39950 3.17500
v 43.43400 21.39950 0.00000
v 43.43400 54.80050 0.00000
v 90.42400 54.80050 3.17500
v 101.60000 13.49584 19.08544
v 101.60000 8.60481 22.53543
v 113.03000 8.65560 22.46289
v 101.60000 8.65560 22.46289
v 113.03000 8.69302 22.38264
v 101.60000 8.54219 22.59804
v 113.03000 8.60481 22.53543
v 101.60000 8.69302 22.38264
v 113.03000 9.52844 17.97261
v 101.60000 9.52844 17.97261
v 101.60000 9.53869 18.03079
v 113.03000 9.53869 18.03079
v 113.03000 9.52500 17.91364
v 101.60000 9.52500 17.91364
v 113.03000 8.54219 22.59804
v 61.97600 32.89300 0.00000
v 61.97600 21.39950 3.17500
v 61.97600 21.39950 0.00000
v 61.97600 32.89300 3.17500
v 61.21400 32.89300 3.17500
v 61.21400 21.39950 0.00000
v 61.21400 21.39950 3.17500
v 61.21400 32.89300 0.00000
v 61.21400 43.30700 0.00000
v 61.21400 54.80050 3.17500
v 61.21400 54.80050 0.00000
v 101.60000 8.46966 22.64883
v 113.03000 8.46966 22.64883
v 61.21400 43.30700 3.17500
v 61.97600 43.30700 3.17500
v 61.97600 54.80050 0.00000
v 61.97600 54.80050 3.17500
v 101.60000 14.67498 22.38264
v 113.03000 14.67498 22.38264
v 113.03000 14.71240 22.46289
v 101.60000 14.71240 22.46289
v 101.60000 9.86230 18.94438
v 113.03000 9.86230 18.94438
v 113.03000 9.84276 18.87626
v 101.60000 9.84276 18.87626
v 113.03000 14.76319 22.53543
v 101.60000 14.76319 22.53543
v 113.03000 14.82581 22.59804
v 101.60000 14.82581 22.59804
v 113.03000 14.89834 22.64883
v 101.60000 9.87216 19.01456
v 101.60000 9.84276 19.22375
v 113.03000 9.84276 19.22375
v 113.03000 9.86230 19.15562
v 101.60000 9.86230 19.15562
v 101.60000 9.55564 18.08738
v 113.03000 9.55564 18.08738
v 113.03000 9.87216 19.08544
v 101.60000 9.87216 19.08544
v 113.03000 9.87216 19.01456
v 113.03000 7.31852 18.93606
v 101.60000 7.31852 18.93606
v 101.60000 7.29799 18.86315
v 113.03000 7.29799 18.86315
v 113.03000 7.32798 19.01121
v 101.60000 7.32798 19.01121
v 101.60000 6.98500 17.91364
v 113.03000 6.98500 17.91364
v 113.03000 6.98844 17.97261
v 101.60000 6.98844 17.97261
v 113.03000 6.99869 18.03079
v 101.60000 6.99869 18.03079
v 113.03000 7.01564 18.08738
v 113.03000 7.32616 19.08692
v 101.60000 13.50570 19.15562
v 113.03000 13.50570 19.15562
v 113.03000 13.52524 19.22375
v 101.60000 13.52524 19.22375
v 113.03000 7.28910 19.23337
v 101.60000 7.28910 19.23337
v 101.60000 7.31310 19.16153
v 113.03000 7.31310 19.16153
v 101.60000 7.32616 19.08692
v 57.65800 32.89300 0.00000
v 57.65800 21.39950 0.00000
v 57.65800 32.89300 3.17500
v 58.42000 32.89300 0.00000
v 58.42000 21.39950 3.17500
v 58.42000 21.39950 0.00000
v 54.86400 43.30700 0.00000
v 54.86400 54.80050 0.00000
v 54.86400 43.30700 3.17500
v 57.65800 21.39950 3.17500
v 58.42000 32.89300 3.17500
v 58.42000 43.30700 3.17500
v 58.42000 54.80050 0.00000
v 58.42000 54.80050 3.17500
v 58.42000 43.30700 0.00000
v 57.65800 43.30700 0.00000
v 57.65800 54.80050 3.17500
v 57.65800 54.80050 0.00000
v 57.65800 43.30700 3.17500
v 54.86400 54.80050 3.17500
v 101.60000 6.33860 21.49973
v 113.03000 6.31306 21.57781
v 101.60000 6.31306 21.57781
v 113.03000 6.30043 21.65898
v 101.60000 7.01564 18.08738
v 113.03000 6.33860 21.49973
v 101.60000 6.30043 21.65898
v 101.60000 6.30106 21.74113
v 113.03000 6.30106 21.74113
v 113.03000 6.31491 21.82210
v 101.60000 6.31491 21.82210
v 7.62000 62.38764 18.08738
v 19.05000 62.38764 18.08738
v 19.05000 62.37070 18.03079
v 7.62000 66.67500 17.91364
v 19.05000 66.67156 17.97261
v 7.62000 66.67156 17.97261
v 19.05000 66.67500 17.91364
v 19.05000 66.66131 18.03079
v 7.62000 66.66131 18.03079
v 19.05000 59.82043 17.97261
v 7.62000 59.82043 17.97261
v 7.62000 59.81700 17.91364
v 113.03000 10.54100 12.70000
v 101.60000 10.54100 10.16000
v 113.03000 10.54100 10.16000
v 22.09800 21.39950 3.17500
v 22.09800 54.80050 3.17500
v 22.09800 21.39950 0.00000
v 19.05000 59.81700 17.91364
v 101.60000 10.54100 12.70000
v 19.05000 66.64436 18.08738
v 7.62000 60.14510 19.16153
v 19.05000 60.14510 19.16153
v 19.05000 60.12110 19.23337
v 7.62000 60.12110 19.23337
v 7.62000 60.15816 19.08692
v 19.05000 60.15816 19.08692
v 19.05000 59.84764 18.08738
v 7.62000 59.84764 18.08738
v 7.62000 59.83070 18.03079
v 19.05000 59.83070 18.03079
v 7.62000 66.64436 18.08738
v 101.60000 16.07001 18.86315
v 113.03000 16.35237 18.08738
v 101.60000 16.35237 18.08738
v 113.03000 16.38300 17.91364
v 113.03000 16.38300 8.89000
v 101.60000 16.38300 17.91364
v 101.60000 17.02940 21.49973
v 113.03000 16.07891 19.23337
v 101.60000 16.07891 19.23337
v 113.03000 16.07001 18.86315
v 19.05000 67.54440 22.46289
v 7.62000 67.50698 22.38264
v 19.05000 67.50698 22.38264
v 101.60000 6.98500 8.89000
v 113.03000 6.98500 8.89000
v 7.62000 67.54440 22.46289
v 101.60000 16.38300 8.89000
v 113.03000 17.02940 21.49973
v 101.60000 13.84300 11.40434
v 113.03000 13.84300 11.40434
v 101.60000 12.82700 10.16000
v 101.60000 12.82700 12.70000
v 113.03000 12.82700 10.16000
v 113.03000 12.82700 12.70000
v 22.09800 54.80050 0.00000
v 19.05000 61.37420 22.59804
v 7.62000 61.37420 22.59804
v 7.62000 61.30165 22.64883
v 19.05000 61.30165 22.64883
v 19.05000 66.32784 19.08544
v 7.62000 66.32784 19.01456
v 19.05000 66.32784 19.01456
v 19.05000 61.43681 22.53543
v 29.21000 54.80050 3.17500
v 25.65400 21.39950 3.17500
v 25.65400 54.80050 3.17500
v 25.65400 21.39950 0.00000
v 25.65400 54.80050 0.00000
v 29.21000 54.80050 0.00000
v 86.86800 54.80050 3.17500
v 88.45550 38.10000 3.17500
v 86.86800 21.39950 3.17500
v 7.62000 66.33771 18.94438
v 19.05000 61.48759 22.46289
v 7.62000 61.48759 22.46289
v 7.62000 61.43681 22.53543
v 19.05000 61.52502 22.38264
v 7.62000 61.52502 22.38264
v 92.86874 21.01850 3.17500
v 92.51950 38.10000 3.17500
v 92.86874 55.56250 3.17500
v 19.05000 66.33771 18.94438
v 7.62000 66.35724 18.87626
v 19.05000 66.35724 18.87626
v 7.62000 60.15052 18.93606
v 19.05000 60.15052 18.93606
v 19.05000 60.15998 19.01121
v 19.05000 66.33771 19.15562
v 7.62000 66.33771 19.15562
v 7.62000 66.32784 19.08544
v 7.62000 60.12999 18.86315
v 19.05000 66.35724 19.22375
v 7.62000 60.15998 19.01121
v 7.62000 66.35724 19.22375
v 19.05000 60.12999 18.86315
v 19.05000 59.13306 21.74113
v 7.62000 59.13306 21.74113
v 7.62000 59.13243 21.65898
v 19.05000 59.13243 21.65898
v 19.05000 59.14692 21.82210
v 7.62000 59.14692 21.82210
v 7.62000 59.14506 21.57781
v 19.05000 59.14506 21.57781
v 7.62000 59.17060 21.49973
v 19.05000 59.17060 21.49973
v 7.62000 68.88148 18.93606
v 19.05000 68.88148 18.93606
v 19.05000 68.90201 18.86315
v 7.62000 68.87202 19.01121
v 19.05000 69.21500 17.91364
v 7.62000 69.21500 17.91364
v 7.62000 69.21156 17.97261
v 7.62000 68.90201 18.86315
v 19.05000 68.87202 19.01121
v 72.64400 21.39950 3.17500
v 74.04100 55.56250 3.17500
v 74.04100 21.01850 3.17500
v 7.62000 68.87384 19.08692
v 19.05000 68.87384 19.08692
v 72.64400 54.80050 3.17500
v 19.05000 69.21156 17.97261
v 19.05000 69.88694 21.57781
v 7.62000 69.88694 21.57781
v 7.62000 69.89957 21.65898
v 19.05000 69.86140 21.49973
v 19.05000 69.89957 21.65898
v 7.62000 69.89894 21.74113
v 7.62000 69.86140 21.49973
v 7.62000 69.20131 18.03079
v 19.05000 69.20131 18.03079
v 7.62000 69.18436 18.08738
v 19.05000 69.18436 18.08738
v 19.05000 62.67476 18.87626
v 7.62000 62.69430 18.94438
v 19.05000 62.69430 18.94438
v 7.62000 62.67476 18.87626
v 7.62000 62.70416 19.01456
v 19.05000 62.70416 19.01456
v 7.62000 62.36044 17.97261
v 19.05000 62.35700 17.91364
v 7.62000 62.35700 17.91364
v 7.62000 62.37070 18.03079
v 19.05000 62.36044 17.97261
v 7.62000 62.70416 19.08544
v 7.62000 68.91090 19.23337
v 19.05000 68.88690 19.16153
v 7.62000 68.88690 19.16153
v 19.05000 68.91090 19.23337
v 19.05000 62.69430 19.15562
v 7.62000 62.67476 19.22375
v 19.05000 62.67476 19.22375
v 19.05000 62.70416 19.08544
v 7.62000 62.69430 19.15562
v 29.97200 36.07695 0.00000
v 29.97200 21.39950 3.17500
v 29.97200 21.39950 0.00000
v 29.97200 36.07695 3.17500
v 78.99400 54.80050 3.17500
v 77.59700 21.01850 3.17500
v 77.59700 55.56250 3.17500
v 78.99400 21.39950 3.17500
v 19.05000 67.59520 22.53543
v 7.62000 67.59520 22.53543
v 19.05000 67.73035 22.64883
v 7.62000 67.65781 22.59804
v 19.05000 67.65781 22.59804
v 7.62000 67.73035 22.64883
v 113.03000 13.76930 10.97799
v 101.60000 13.76930 10.97799
v 101.60000 13.67918 10.78044
v 113.03000 13.67918 10.78044
v 113.03000 13.82444 11.18800
v 101.60000 13.82444 11.18800
v 18.54200 21.39950 3.17500
v 18.54200 54.80050 3.17500
v 18.54200 21.39950 0.00000
v 19.05000 69.89894 21.74113
v 7.62000 69.88509 21.82210
v 18.54200 54.80050 0.00000
v 19.05000 69.88509 21.82210
v 32.76600 21.39950 0.00000
v 32.76600 54.80050 3.17500
v 32.76600 54.80050 0.00000
v 32.76600 21.39950 3.17500
v 76.20000 54.80050 3.17500
v 76.20000 21.39950 3.17500
v 75.43800 21.39950 3.17500
v 75.43800 54.80050 3.17500
v 48.51400 43.30700 3.17500
v 48.51400 32.89300 3.17500
v 7.62000 63.37300 10.16000
v 19.05000 63.37300 12.70000
v 7.62000 63.37300 12.70000
v 19.05000 63.37300 10.16000
v 7.62000 65.65900 12.70000
v 19.05000 65.65900 10.16000
v 7.62000 65.65900 10.16000
v 101.60000 59.83070 18.03079
v 113.03000 59.83070 18.03079
v 101.60000 59.82043 17.97261
v 19.05000 65.65900 12.70000
v 72.13600 32.89300 4.44500
v 72.13600 43.30700 0.00000
v 72.13600 32.89300 0.00000
v 113.03000 59.82043 17.97261
v 101.60000 60.14510 19.16153
v 113.03000 60.14510 19.16153
v 101.60000 60.15816 19.08692
v 113.03000 60.15052 18.93606
v 101.60000 60.15052 18.93606
v 113.03000 60.15998 19.01121
v 113.03000 60.15816 19.08692
v 101.60000 60.15998 19.01121
v 19.05000 69.21500 8.89000
v 7.62000 69.21500 8.89000
v 7.62000 59.81700 8.89000
v 19.05000 59.81700 8.89000
v 101.60000 67.65781 22.59804
v 113.03000 67.73035 22.64883
v 101.60000 67.73035 22.64883
v 113.03000 66.67156 17.97261
v 101.60000 66.67156 17.97261
v 72.13600 43.30700 4.44500
v 113.03000 66.33771 18.94438
v 113.03000 66.66131 18.03079
v 101.60000 66.66131 18.03079
v 113.03000 67.65781 22.59804
v 101.60000 69.88509 21.82210
v 113.03000 69.89894 21.74113
v 101.60000 69.89894 21.74113
v 113.03000 69.88509 21.82210
v 113.03000 69.89957 21.65898
v 101.60000 69.89957 21.65898
v 113.03000 69.88694 21.57781
v 101.60000 67.54440 22.46289
v 113.03000 67.59520 22.53543
v 101.60000 67.59520 22.53543
v 113.03000 67.54440 22.46289
v 101.60000 69.88694 21.57781
v 101.60000 66.33771 18.94438
v 113.03000 59.14506 21.57781
v 101.60000 61.48759 22.46289
v 101.60000 59.14506 21.57781
v 113.03000 59.13243 21.65898
v 113.03000 61.48759 22.46289
v 101.60000 61.37420 22.59804
v 113.03000 61.43681 22.53543
v 101.60000 61.43681 22.53543
v 113.03000 61.37420 22.59804
v 101.60000 59.13243 21.65898
v 113.03000 59.13306 21.74113
v 101.60000 59.13306 21.74113
v 113.03000 59.14692 21.82210
v 101.60000 59.14692 21.82210
v 101.60000 61.30165 22.64883
v 101.60000 66.33771 19.15562
v 113.03000 66.33771 19.15562
v 101.60000 66.32784 19.08544
v 113.03000 66.32784 19.08544
v 113.03000 66.32784 19.01456
v 101.60000 66.32784 19.01456
v 113.03000 61.30165 22.64883
v 113.03000 16.04948 18.93606
v 101.60000 16.04948 18.93606
v 101.60000 16.04002 19.01121
v 113.03000 16.04002 19.01121
v 101.60000 16.04184 19.08692
v 71.88200 43.30700 0.00000
v 71.88200 54.80050 3.17500
v 71.88200 54.80050 0.00000
v 71.88200 43.30700 3.17500
v 7.62000 10.54100 10.16000
v 19.05000 10.54100 12.70000
v 7.62000 10.54100 12.70000
v 113.03000 16.04184 19.08692
v 101.60000 16.05490 19.16153
v 113.03000 16.05490 19.16153
v 19.05000 12.82700 12.70000
v 19.05000 12.82700 10.16000
v 7.62000 12.82700 12.70000
v 7.62000 12.82700 10.16000
v 19.05000 10.54100 10.16000
v 101.60000 17.05494 21.57781
v 101.60000 17.05309 21.82210
v 113.03000 17.05309 21.82210
v 113.03000 17.06694 21.74113
v 101.60000 17.06694 21.74113
v 113.03000 17.06757 21.65898
v 71.88200 32.89300 3.17500
v 71.88200 21.39950 0.00000
v 71.88200 21.39950 3.17500
v 69.08800 32.89300 3.17500
v 69.08800 21.39950 3.17500
v 69.08800 32.89300 0.00000
v 69.08800 43.30700 3.17500
v 69.08800 54.80050 0.00000
v 69.08800 54.80050 3.17500
v 69.08800 43.30700 0.00000
v 75.43800 21.39950 0.00000
v 113.03000 17.05494 21.57781
v 72.64400 54.80050 0.00000
v 72.64400 21.39950 0.00000
v 101.60000 17.06757 21.65898
v 75.43800 54.80050 0.00000
v 7.62000 6.98500 8.89000
v 19.05000 6.98500 8.89000
v 101.60000 16.37956 17.97261
v 113.03000 16.37956 17.97261
v 71.88200 32.89300 0.00000
v 101.60000 16.36931 18.03079
v 113.03000 16.36931 18.03079
v 19.05000 16.38300 8.89000
v 7.62000 16.38300 8.89000
v 69.08800 21.39950 0.00000
v 68.32600 32.89300 3.17500
v 68.32600 21.39950 0.00000
v 68.32600 21.39950 3.17500
v 48.51400 31.75000 4.44500
v 48.51400 32.89300 4.44500
v 65.53200 43.30700 0.00000
v 65.53200 54.80050 0.00000
v 65.53200 43.30700 3.17500
v 65.53200 32.89300 3.17500
v 65.53200 21.39950 3.17500
v 65.53200 32.89300 0.00000
v 65.53200 54.80050 3.17500
v 85.17085 31.75000 7.62000
v 72.13600 31.75000 4.44500
v 85.17085 32.76600 7.62000
v 82.55000 21.39950 3.17500
v 82.55000 54.80050 3.17500
v 82.55000 21.39950 0.00000
v 82.55000 54.80050 0.00000
v 64.77000 43.30700 3.17500
v 64.77000 54.80050 3.17500
v 64.77000 43.30700 0.00000
v 86.10600 21.39950 0.00000
v 86.10600 54.80050 3.17500
v 86.10600 54.80050 0.00000
v 101.60000 14.89834 22.64883
v 86.10600 21.39950 3.17500
v 65.53200 21.39950 0.00000
v 64.77000 54.80050 0.00000
v 64.77000 32.89300 0.00000
v 64.77000 21.39950 0.00000
v 64.77000 32.89300 3.17500
v 61.97600 43.30700 0.00000
v 64.77000 21.39950 3.17500
v 68.32600 32.89300 0.00000
v 68.32600 43.30700 0.00000
v 68.32600 54.80050 3.17500
v 68.32600 54.80050 0.00000
v 78.99400 21.39950 0.00000
v 72.13600 44.45000 4.44500
v 48.51400 43.30700 4.44500
v 48.51400 44.45000 4.44500
v 78.99400 54.80050 0.00000
v 68.32600 43.30700 3.17500
v 85.17085 43.43400 7.62000
v 51.43500 71.77573 8.66982
v 69.21500 71.77573 8.66982
v 51.43500 71.81790 8.49527
v 51.43500 72.00037 8.13964
v 69.21500 71.92805 8.25679
v 69.21500 72.00037 8.13964
v 51.43500 72.13122 7.98775
v 69.21500 72.13122 7.98775
v 51.43500 72.21121 7.92014
v 24.60337 11.42218 7.62000
v 31.17037 11.90482 6.35000
v 33.55499 12.21342 6.35000
v 69.21500 71.89506 8.31024
v 69.32527 71.88647 8.30583
v 69.21500 72.21121 7.92014
v 51.43500 72.28433 7.85834
v 69.21500 72.28433 7.85834
v 33.54647 12.57952 7.62000
v 51.43500 72.39191 7.79332
v 26.41600 54.80050 0.00000
v 69.21500 72.39191 7.79332
v 51.43500 72.45591 7.75464
v 22.86000 21.39950 3.17500
v 22.86000 21.39950 0.00000
v 22.86000 54.80050 0.00000
v 22.86000 54.80050 3.17500
v 26.41600 21.39950 0.00000
v 35.96751 12.01474 6.35000
v 51.43500 72.05504 8.07618
v 69.21500 72.05504 8.07618
v 69.32527 71.99259 8.13395
v 26.41600 54.80050 3.17500
v 69.70144 71.97158 7.82661
v 69.70144 72.15205 7.67408
v 69.62317 72.19769 7.73766
v 51.43500 71.92805 8.25679
v 26.41600 21.39950 3.17500
v 69.85000 71.33009 8.02035
v 69.85000 71.48805 7.76447
v 69.84035 71.57701 7.82962
v 69.77431 72.04469 7.62000
v 69.76440 72.07269 7.62000
v 69.76493 71.90778 7.76219
v 51.43500 71.83401 8.45664
v 69.21500 71.83401 8.45664
v 51.43500 71.89506 8.31024
v 69.21500 71.81790 8.49527
v 69.21912 73.01918 7.62000
v 69.32527 72.83555 7.62445
v 69.30463 72.89776 7.62000
v 69.32335 72.86864 7.62000
v 33.41804 11.98510 6.35000
v 19.30400 54.80050 3.17500
v 19.30400 21.39950 0.00000
v 19.30400 54.80050 0.00000
v 69.75013 72.11302 7.62000
v 69.74614 72.12428 7.62000
v 69.84035 71.31915 8.33218
v 69.85000 71.21435 8.29791
v 69.84035 71.42819 8.07069
v 69.81170 71.52332 8.11950
v 69.81170 71.66328 7.89279
v 69.85000 71.14373 8.59022
v 69.76493 71.61256 8.16529
v 69.76493 71.74421 7.95206
v 69.70144 71.81737 8.00563
v 43.73070 11.74082 7.62000
v 12.19200 54.80050 3.17500
v 12.19200 21.39950 3.17500
v 12.19200 54.80050 0.00000
v 12.19200 21.39950 0.00000
v 15.74800 21.39950 0.00000
v 15.74800 54.80050 0.00000
v 69.81170 71.42078 8.36541
v 15.74800 21.39950 3.17500
v 69.85000 71.12000 8.89000
v 69.84035 71.25263 8.60757
v 69.21500 73.02500 7.62000
v 68.40075 73.02500 7.62000
v 69.21500 72.83698 7.63400
v 15.74800 54.80050 3.17500
v 69.81170 71.83717 7.69092
v 69.82964 71.82753 7.62000
v 69.80734 71.91506 7.62000
v 69.69365 72.23508 7.62000
v 51.43500 71.77083 8.69015
v 69.21500 71.77083 8.69015
v 69.62283 72.38458 7.62000
v 69.83663 71.75871 7.62000
v 69.83605 71.76447 7.62000
v 69.32527 72.12443 7.98090
v 69.32527 72.63875 7.67004
v 69.42344 72.71288 7.62000
v 69.34679 72.83217 7.62000
v 69.43218 72.43874 7.72041
v 69.43218 72.63010 7.64272
v 69.53250 72.41779 7.67859
v 69.59035 72.45315 7.62000
v 69.52551 72.55405 7.62000
v 69.61809 72.39458 7.62000
v 69.21500 72.59181 7.69948
v 69.21500 72.45591 7.75464
v 69.32527 72.45159 7.74602
v 69.32527 72.27871 7.85051
v 69.62317 72.02666 7.88221
v 69.53250 72.23472 7.78924
v 69.84975 71.62951 7.62000
v 69.85000 71.60510 7.62000
v 19.30400 21.39950 3.17500
v 69.21500 72.80495 7.64141
v 40.64000 54.80050 3.17500
v 40.64000 54.80050 0.00000
v 97.53600 21.39950 3.17500
v 97.53600 21.39950 0.00000
v 40.64000 21.39950 0.00000
v 101.09200 54.80050 3.17500
v 101.09200 54.80050 0.00000
v 101.09200 21.39950 0.00000
v 97.53600 54.80050 3.17500
v 97.53600 54.80050 0.00000
v 47.75200 54.80050 0.00000
v 47.75200 21.39950 0.00000
v 44.19600 54.80050 0.00000
v 44.19600 21.39950 0.00000
v 83.31200 54.80050 3.17500
v 83.31200 54.80050 0.00000
v 83.31200 21.39950 0.00000
v 83.31200 21.39950 3.17500
v 79.75600 54.80050 3.17500
v 79.75600 54.80050 0.00000
v 79.75600 21.39950 0.00000
v 79.75600 21.39950 3.17500
v 86.86800 21.39950 0.00000
v 86.86800 54.80050 0.00000
v 76.20000 54.80050 0.00000
v 93.98000 54.80050 3.17500
v 93.98000 21.39950 3.17500
v 93.98000 54.80050 0.00000
v 93.98000 21.39950 0.00000
v 76.20000 21.39950 0.00000
v 13.58900 20.63750 3.17500
v 17.14500 20.63750 3.17500
v 6.35000 20.63750 0.00000
v 20.70100 20.63750 3.17500
v 7.11200 20.63750 3.17500
v 10.03300 20.63750 3.17500
v 32.48025 20.63750 3.17500
v 34.92500 20.63750 3.17500
v 38.48100 20.63750 3.17500
v 27.27325 20.63750 3.17500
v 68.40075 3.17500 7.62000
v 51.43500 3.39504 7.64141
v 51.43500 3.36302 7.63400
v 51.43500 3.17500 7.62000
v 69.21500 3.39553 7.63929
v 51.43500 3.55833 7.67923
v 6.35000 21.01850 3.17500
v 6.35000 55.56250 0.00000
v 6.35000 55.56250 3.17500
v 6.35000 20.63750 3.17500
v 98.93300 20.63750 3.17500
v 102.48900 20.63750 3.17500
v 114.30000 20.63750 0.00000
v 106.04500 20.63750 3.17500
v 92.86874 20.63750 3.17500
v 95.37700 20.63750 3.17500
v 114.30000 21.01850 3.17500
v 114.30000 55.56250 0.00000
v 114.30000 20.63750 3.17500
v 114.30000 55.56250 3.17500
v 48.13300 20.63750 3.17500
v 74.04100 20.63750 3.17500
v 42.03700 20.63750 3.17500
v 81.15300 20.63750 3.17500
v 84.70901 20.63750 3.17500
v 77.59700 20.63750 3.17500
v 51.43500 3.60819 7.69948
v 51.43500 4.42918 8.69015
v 69.21500 4.42570 8.66947
v 51.43500 72.83698 7.63400
v 51.43500 73.02500 7.62000
v 69.21500 3.17500 7.62000
v 51.43500 4.38210 8.49527
v 69.21500 4.36841 8.45563
v 51.43500 4.36599 8.45664
v 51.43500 4.42427 8.66982
v 51.43500 72.59181 7.69948
v 51.43500 72.64167 7.67923
v 69.21500 72.64167 7.67923
v 51.43500 72.80495 7.64141
v 69.21500 3.99134 7.91712
v 51.43500 3.91567 7.85834
v 69.21500 3.81000 7.79015
v 51.43500 3.80808 7.79332
v 51.43500 4.06878 7.98775
v 51.43500 3.98879 7.92014
v 69.21500 3.60937 7.69659
v 51.43500 3.74409 7.75464
v 51.43500 4.30495 8.31024
v 51.43500 4.27195 8.25679
v 69.21500 4.27485 8.25500
v 51.43500 4.19963 8.13964
v 69.21500 4.14788 8.07366
v 51.43500 4.14496 8.07618
v 106.04500 55.56250 3.17500
v 33.52800 54.80050 3.17500
v 33.52800 21.39950 0.00000
v 33.52800 54.80050 0.00000
v 33.52800 21.39950 3.17500
v 37.08400 54.80050 0.00000
v 108.20400 21.39950 0.00000
v 108.20400 54.80050 0.00000
v 108.20400 54.80050 3.17500
v 104.64800 54.80050 3.17500
v 104.64800 21.39950 3.17500
v 104.64800 54.80050 0.00000
v 104.64800 21.39950 0.00000
v 37.08400 21.39950 3.17500
v 37.08400 21.39950 0.00000
v 37.08400 54.80050 3.17500
v 101.09200 21.39950 3.17500
v 38.48100 55.56250 3.17500
v 20.70100 55.56250 3.17500
v 34.92500 55.56250 3.17500
v 98.93300 55.56250 3.17500
v 95.37700 55.56250 3.17500
v 102.48900 55.56250 3.17500
v 81.15300 55.56250 3.17500
v 84.70901 55.56250 3.17500
v 111.76000 54.80050 3.17500
v 111.76000 54.80050 0.00000
v 111.76000 21.39950 0.00000
v 108.20400 21.39950 3.17500
v 13.58900 55.56250 3.17500
v 10.03300 55.56250 3.17500
v 7.11200 55.56250 3.17500
v 17.14500 55.56250 3.17500
v 111.76000 21.39950 3.17500
v 19.05000 14.97860 22.68625
v 19.05000 15.06413 22.70917
v 19.05000 15.15234 22.71689
v 19.05000 16.73468 22.17357
v 19.05000 9.81126 10.60112
v 19.05000 8.48828 7.62000
v 19.05000 9.96247 10.44529
v 19.05000 6.96571 8.66947
v 19.05000 15.32609 22.68625
v 19.05000 15.24056 22.70917
v 19.05000 6.63332 22.17357
v 19.05000 8.04191 22.68625
v 19.05000 6.49048 22.09349
v 19.05000 6.55865 22.13932
v 19.05000 16.80935 22.13932
v 19.05000 16.87752 22.09349
v 19.05000 17.02636 21.89977
v 19.05000 16.93741 22.03727
v 19.05000 6.43058 22.03727
v 19.05000 6.38054 21.97213
v 19.05000 16.98746 21.97213
v 19.05000 6.34164 21.89977
v 19.05000 10.13800 10.31749
v 7.62000 6.63332 22.17357
v 7.62000 8.04191 22.68625
v 7.62000 15.32609 22.68625
v 101.60000 17.02636 21.89977
v 101.60000 16.98746 21.97213
v 113.03000 16.98746 21.97213
v 113.03000 17.02636 21.89977
v 19.05000 16.95450 7.62000
v 19.05000 17.65300 7.62000
v 19.05000 17.43247 7.63929
v 19.05000 16.68012 8.07366
v 19.05000 16.55315 8.25500
v 19.05000 12.80160 7.62000
v 19.05000 16.45959 8.45563
v 7.62000 16.73468 22.17357
v 101.60000 15.24056 22.70917
v 113.03000 15.24056 22.70917
v 113.03000 15.32609 22.68625
v 101.60000 15.32609 22.68625
v 101.60000 16.73468 22.17357
v 113.03000 16.73468 22.17357
v 113.03000 16.80935 22.13932
v 101.60000 15.15234 22.71689
v 101.60000 15.06413 22.70917
v 113.03000 15.06413 22.70917
v 113.03000 15.15234 22.71689
v 101.60000 16.87752 22.09349
v 113.03000 16.93741 22.03727
v 101.60000 16.93741 22.03727
v 113.03000 16.87752 22.09349
v 101.60000 16.80935 22.13932
v 19.05000 6.68788 8.07366
v 19.05000 6.53134 7.91712
v 19.05000 6.35000 7.79015
v 19.05000 6.14937 7.69659
v 19.05000 16.40229 8.66947
v 19.05000 13.55674 10.60112
v 19.05000 13.40554 10.44529
v 19.05000 13.03525 10.22145
v 19.05000 13.23000 10.31749
v 19.05000 10.33275 10.22145
v 19.05000 17.01800 7.79015
v 19.05000 16.83666 7.91712
v 19.05000 17.21863 7.69659
v 19.05000 5.71500 7.62000
v 19.05000 5.93553 7.63929
v 19.05000 6.81485 8.25500
v 19.05000 6.90841 8.45563
v 7.62000 12.80160 7.62000
v 7.62000 8.12744 22.70917
v 7.62000 16.95450 7.62000
v 7.62000 17.01800 7.79015
v 7.62000 17.21863 7.69659
v 7.62000 13.23000 10.31749
v 7.62000 13.40554 10.44529
v 7.62000 16.40229 8.66947
v 7.62000 13.55674 10.60112
v 7.62000 16.83666 7.91712
v 7.62000 16.55315 8.25500
v 7.62000 16.68012 8.07366
v 7.62000 13.03525 10.22145
v 7.62000 16.98746 21.97213
v 7.62000 16.93741 22.03727
v 7.62000 17.02636 21.89977
v 7.62000 6.38054 21.97213
v 7.62000 6.49048 22.09349
v 7.62000 6.55865 22.13932
v 7.62000 16.80935 22.13932
v 7.62000 16.87752 22.09349
v 7.62000 8.30387 22.70917
v 7.62000 8.38940 22.68625
v 7.62000 6.34164 21.89977
v 7.62000 8.21566 22.71689
v 7.62000 6.43058 22.03727
v 7.62000 6.96571 8.66947
v 7.62000 9.81126 10.60112
v 7.62000 9.96247 10.44529
v 19.05000 8.38940 22.68625
v 19.05000 8.12744 22.70917
v 19.05000 8.30387 22.70917
v 19.05000 8.21566 22.71689
v 7.62000 5.71500 7.62000
v 7.62000 5.93553 7.63929
v 7.62000 8.48828 7.62000
v 7.62000 6.14937 7.69659
v 7.62000 6.35000 7.79015
v 7.62000 16.45959 8.45563
v 7.62000 6.90841 8.45563
v 7.62000 6.81485 8.25500
v 7.62000 6.53134 7.91712
v 7.62000 6.68788 8.07366
v 7.62000 17.43247 7.63929
v 7.62000 17.65300 7.62000
v 7.62000 10.13800 10.31749
v 7.62000 10.33275 10.22145
v 113.03000 14.97860 22.68625
v 113.03000 6.96571 8.66947
v 113.03000 9.81126 10.60112
v 113.03000 6.49048 22.09349
v 113.03000 6.55865 22.13932
v 113.03000 6.43058 22.03727
v 113.03000 6.38054 21.97213
v 113.03000 8.04191 22.68625
v 113.03000 6.63332 22.17357
v 113.03000 6.34164 21.89977
v 113.03000 9.96247 10.44529
v 101.60000 14.97860 22.68625
v 113.03000 12.80160 7.62000
v 113.03000 16.45959 8.45563
v 113.03000 8.48828 7.62000
v 113.03000 16.40229 8.66947
v 113.03000 17.43247 7.63929
v 113.03000 17.21863 7.69659
v 113.03000 16.95450 7.62000
v 113.03000 16.55315 8.25500
v 113.03000 17.65300 7.62000
v 113.03000 16.68012 8.07366
v 113.03000 6.35000 7.79015
v 113.03000 6.14937 7.69659
v 113.03000 13.55674 10.60112
v 113.03000 13.40554 10.44529
v 113.03000 6.68788 8.07366
v 113.03000 6.53134 7.91712
v 113.03000 10.33275 10.22145
v 113.03000 10.13800 10.31749
v 113.03000 13.23000 10.31749
v 113.03000 13.03525 10.22145
v 113.03000 6.90841 8.45563
v 113.03000 6.81485 8.25500
v 113.03000 16.83666 7.91712
v 113.03000 17.01800 7.79015
v 113.03000 5.71500 7.62000
v 113.03000 5.93553 7.63929
v 101.60000 6.81485 8.25500
v 101.60000 6.68788 8.07366
v 101.60000 6.53134 7.91712
v 101.60000 6.96571 8.66947
v 101.60000 6.90841 8.45563
v 101.60000 6.14937 7.69659
v 101.60000 5.93553 7.63929
v 101.60000 6.35000 7.79015
v 101.60000 16.68012 8.07366
v 101.60000 16.55315 8.25500
v 101.60000 16.45959 8.45563
v 101.60000 17.01800 7.79015
v 101.60000 16.83666 7.91712
v 101.60000 16.40229 8.66947
v 101.60000 8.21566 22.71689
v 113.03000 8.21566 22.71689
v 113.03000 8.30387 22.70917
v 101.60000 8.30387 22.70917
v 113.03000 8.38940 22.68625
v 101.60000 8.12744 22.70917
v 101.60000 8.04191 22.68625
v 113.03000 8.12744 22.70917
v 101.60000 8.38940 22.68625
v 101.60000 6.34164 21.89977
v 101.60000 6.38054 21.97213
v 101.60000 5.71500 7.62000
v 101.60000 6.49048 22.09349
v 101.60000 6.55865 22.13932
v 101.60000 6.63332 22.17357
v 101.60000 6.43058 22.03727
v 101.60000 9.96247 10.44529
v 101.60000 10.13800 10.31749
v 101.60000 10.33275 10.22145
v 101.60000 9.81126 10.60112
v 101.60000 17.43247 7.63929
v 101.60000 17.21863 7.69659
v 101.60000 17.65300 7.62000
v 101.60000 13.55674 10.60112
v 101.60000 13.40554 10.44529
v 101.60000 13.03525 10.22145
v 101.60000 13.23000 10.31749
v 110.15548 52.32297 6.35000
v 114.53511 51.67926 7.62000
v 111.10655 52.26242 6.35000
v 120.18776 51.31940 7.62000
v 110.15825 51.95789 7.62000
v 110.15315 52.32294 6.35063
v 100.77618 51.09724 7.62000
v 110.26032 52.33263 6.35000
v 100.65302 51.09724 7.62000
v 100.67319 51.46141 6.35000
v 99.08823 51.27111 7.62000
v 100.42645 51.48882 6.35000
v 95.69965 51.64761 7.62000
v 90.67794 52.20559 7.62000
v 94.49773 51.78116 7.62000
v 100.75951 51.46141 6.35000
v 24.60337 0.91805 7.62000
v 21.03100 0.00000 6.35000
v 25.63855 0.70886 6.35000
v 25.63871 1.07733 7.62000
v 13.17133 0.00000 6.35000
v 10.17522 0.70497 6.35000
v 6.53289 0.00000 6.35000
v 21.64064 0.46224 7.62000
v 30.21883 0.00000 6.35000
v 29.61313 0.46224 7.62000
v 100.87976 51.47244 6.35000
v 100.90943 51.47326 6.35660
v 12.79677 0.46224 7.62000
v 10.18264 1.07733 7.62000
v 7.00469 0.46224 7.62000
v 116.04020 26.47656 7.62000
v 106.37006 26.95190 6.35000
v 114.53511 26.49386 7.62000
v 103.57697 26.98401 6.35000
v 103.58913 26.61967 7.62000
v 114.53511 52.34342 7.62000
v 95.69965 26.00089 7.62000
v 89.01654 25.84201 6.35000
v 94.49773 25.90662 7.62000
v 85.67247 26.57353 6.35000
v 88.99133 25.47475 7.62000
v 79.83192 27.47837 7.62000
v 99.08823 26.26666 7.62000
v 95.69965 52.02407 7.62000
v 99.08823 51.90160 7.62000
v 100.89932 51.83614 7.62000
v 94.49773 52.06752 7.62000
v 120.18776 51.87448 7.62000
v 110.25865 52.69818 7.62000
v 73.12357 1.78493 7.62000
v 73.31788 1.43109 6.35000
v 73.32487 1.79622 7.62000
v 70.20969 75.60193 7.62000
v 70.20255 75.96825 6.35000
v 68.71557 75.73776 7.62000
v 67.65324 76.20000 6.35000
v 71.97932 76.20000 6.35000
v 101.60108 75.48212 7.62000
v 101.54173 75.84154 6.35000
v 99.08823 75.13492 7.62000
v 96.58752 75.15702 6.35000
v 96.57646 74.78787 7.62000
v 47.26535 75.25757 7.62000
v 45.04652 76.20000 6.35000
v 45.38483 75.73776 7.62000
v 48.58955 75.29529 6.35000
v 48.57032 74.92433 7.62000
v 54.72517 76.20000 6.35000
v 54.08675 75.73776 7.62000
v 71.25106 75.73776 7.62000
v 116.79443 75.73375 6.35000
v 116.81752 75.36230 7.62000
v 118.48009 76.20000 6.35000
v 118.17496 75.73776 7.62000
v 103.40573 76.20000 6.35000
v 114.53511 75.69705 7.62000
v 113.61544 76.20000 6.35000
v 114.25758 75.73776 7.62000
v 94.49773 75.20316 7.62000
v 91.36694 76.20000 6.35000
v 91.82183 75.73776 7.62000
v 102.93044 75.73776 7.62000
v 95.69965 74.96304 7.62000
v 60.54564 0.71489 6.35000
v 82.19114 0.95752 7.62000
v 82.14793 0.59581 6.35000
v 85.61996 0.46224 7.62000
v 86.27277 0.00000 6.35000
v 60.50472 1.07733 7.62000
v 68.40075 1.52010 7.62000
v 56.35164 0.00000 6.35000
v 56.89621 0.46224 7.62000
v 24.32076 75.36230 7.62000
v 24.31360 75.73073 6.35000
v 21.53610 75.73776 7.62000
v 20.83315 76.20000 6.35000
v 107.69946 0.00000 6.35000
v 103.98309 0.94484 6.35000
v 98.55025 0.00000 6.35000
v 24.60337 75.41164 7.62000
v 27.00126 76.20000 6.35000
v 26.47111 75.73776 7.62000
v 99.08823 0.46320 7.62000
v 103.99738 1.31696 7.62000
v 107.35926 0.46224 7.62000
v 99.08276 0.46224 7.62000
v 37.14090 59.90623 7.62000
v 34.98467 60.17050 6.35000
v 26.83686 59.42698 7.62000
v 37.36671 60.49532 6.35000
v 37.38053 60.86475 7.62000
v 37.17737 60.27249 6.35000
v 42.19184 23.98194 6.35000
v 42.36281 24.96220 6.35000
v 29.76436 25.69608 6.35000
v 29.45794 26.39607 6.35000
v 23.35176 25.62403 6.35000
v 48.04402 58.58827 7.62000
v 48.01472 58.22216 6.35000
v 57.02430 58.97880 7.62000
v 47.26535 58.75451 7.62000
v 46.21471 58.97880 7.62000
v 58.46106 28.55674 6.35000
v 48.77068 24.54450 7.62000
v 66.95150 28.48018 7.62000
v 47.26535 26.63130 7.62000
v 47.26535 24.33162 7.62000
v 42.19247 23.61424 7.62000
v 86.47266 63.23159 6.35000
v 85.17889 63.26609 6.35000
v 94.17258 62.66197 7.62000
v 85.18650 62.90159 7.62000
v 85.31363 63.37256 6.35000
v 85.30632 63.74030 7.62000
v 84.27554 63.20422 6.35000
v 76.44004 62.30252 7.62000
v 24.60337 11.30589 7.62000
v 23.36224 11.26156 7.62000
v 33.42665 11.62100 7.62000
v 40.85063 58.97880 7.62000
v 47.69651 57.64270 6.35000
v 45.13120 57.90866 7.62000
v 47.26535 57.37512 7.62000
v 47.68457 57.27031 7.62000
v 51.17418 57.90866 7.62000
v 90.38426 13.67428 6.35000
v 86.28056 13.38931 6.35000
v 86.27213 13.02368 7.62000
v 94.49773 13.59490 7.62000
v 95.69965 13.67837 7.62000
v 95.69965 13.78168 7.62000
v 96.57646 13.73926 7.62000
v 87.70329 14.16860 7.62000
v 87.69448 13.80444 6.35000
v 94.49773 13.83984 7.62000
v 76.39713 14.16860 7.62000
v 82.70005 13.80444 6.35000
v 102.87101 27.25616 6.35000
v 99.08823 27.34547 7.62000
v 89.13092 26.25429 6.35000
v 95.69965 27.09839 7.62000
v 94.49773 27.01075 7.62000
v 102.87356 27.62148 7.62000
v 89.13444 26.61967 7.62000
v 114.53511 26.60744 7.62000
v 24.60337 25.27390 7.62000
v 21.20612 25.23573 7.62000
v 17.00410 25.18852 7.62000
v 29.74140 25.33163 7.62000
v 35.44814 24.54450 7.62000
v 29.45517 26.76279 7.62000
v 42.33559 25.33163 7.62000
v 21.20612 25.71981 7.62000
v 24.60337 26.14934 7.62000
v 9.35259 20.97694 6.35000
v 9.35527 21.07143 6.67827
v 9.36298 21.34250 7.62000
v 0.46224 22.41937 7.62000
v 9.03932 20.74913 7.62000
v 15.29665 21.72010 7.62000
v 7.62000 14.97860 22.68625
v 7.62000 15.24056 22.70917
v 7.62000 15.06413 22.70917
v 7.62000 15.15234 22.71689
v 1.04624 75.97623 6.35000
v 1.27000 76.20000 6.35000
v 1.27000 76.20000 0.00000
v 0.46224 75.39224 7.62000
v 0.73201 75.66201 7.62000
v 0.00000 74.93000 6.35000
v 0.46224 0.80776 7.62000
v 1.27000 0.00000 6.35000
v 0.80776 0.46224 7.62000
v 0.00000 1.27000 0.00000
v 1.27000 0.00000 0.00000
v 0.00000 1.27000 6.35000
v 119.38000 76.20000 6.35000
v 120.65000 74.93000 6.35000
v 119.38000 76.20000 0.00000
v 120.65000 74.93000 0.00000
v 120.18776 0.80776 7.62000
v 119.84225 0.46224 7.62000
v 120.65000 1.27000 6.35000
v 120.18776 75.39224 7.62000
v 0.00000 74.93000 0.00000
v 119.84225 75.73776 7.62000
v 120.18776 61.58364 7.62000
v 120.18776 58.97880 7.62000
v 120.18776 57.90866 7.62000
v 120.18776 43.43400 7.62000
v 120.18776 1.81862 7.62000
v 120.18776 16.95450 7.62000
v 120.18776 12.80160 7.62000
v 120.18776 8.48828 7.62000
v 120.18776 24.54450 7.62000
v 120.18776 32.76600 7.62000
v 120.18776 30.61600 7.62000
v 6.34951 47.19719 6.35000
v 3.25300 46.72968 6.35000
v 6.36092 47.09476 6.35000
v 12.07729 46.36189 6.35000
v 11.50169 46.13834 6.35000
v 0.46224 46.03518 7.62000
v 6.34858 46.72664 7.62000
v 7.08650 74.90762 6.35000
v 7.11631 74.53252 7.62000
v 10.81450 76.20000 6.35000
v 10.59293 75.73776 7.62000
v 12.83747 46.37878 6.35000
v 6.34858 47.56534 7.62000
v 119.38000 0.00000 6.35000
v 120.65000 1.27000 0.00000
v 119.38000 0.00000 0.00000
v 17.49133 46.84646 7.62000
v 11.50060 45.76813 7.62000
v 12.09968 46.72664 7.62000
v 0.46224 16.95450 7.62000
v 0.46224 30.61600 7.62000
v 0.46224 32.76600 7.62000
v 0.46224 43.43400 7.62000
v 0.46224 12.80160 7.62000
v 0.46224 61.58364 7.62000
v 0.46224 58.97880 7.62000
v 0.46224 57.90866 7.62000
v 21.20612 75.73776 7.62000
v 0.46224 24.54450 7.62000
v 0.46224 1.81862 7.62000
v 0.46224 8.48828 7.62000
v 68.80325 9.00027 15.36888
v 68.55164 8.89000 15.79786
v 68.81184 8.89000 15.37326
v 68.02309 9.00027 16.64198
v 67.51716 9.52500 16.27441
v 66.68915 9.51535 17.41315
v 67.60637 9.51535 16.33922
v 67.05337 9.00027 17.77737
v 67.06020 8.89000 17.78419
v 67.59952 8.89000 17.15272
v 68.77772 9.10718 15.35587
v 68.73604 9.20750 15.33464
v 67.96206 9.20750 16.59765
v 67.99991 9.10718 16.62514
v 68.67947 9.29817 15.30581
v 67.84738 9.37644 16.51433
v 66.89980 9.37644 17.62380
v 66.95514 9.29817 17.67914
v 67.77402 9.43993 16.46103
v 67.91070 9.29817 16.56033
v 67.00004 9.20750 17.72404
v 66.83569 9.43993 17.55968
v 66.76475 9.48670 17.48875
v 67.69287 9.48670 16.40207
v 69.37463 9.00027 13.98941
v 69.25682 8.89000 14.29897
v 69.38381 8.89000 13.99239
v 68.24605 9.52500 15.08498
v 68.34429 9.51535 15.13504
v 68.03088 8.89000 16.64765
v 69.34740 9.10718 13.98055
v 69.30290 9.20750 13.96610
v 69.24252 9.29817 13.94648
v 68.60973 9.37644 15.27028
v 68.52895 9.43993 15.22912
v 68.43956 9.48670 15.18357
v 66.42872 8.89000 18.32352
v 65.91798 9.00027 18.74708
v 65.55041 9.52500 18.24116
v 64.41103 9.51535 19.06830
v 65.61522 9.51535 18.33037
v 64.64488 9.00027 19.52724
v 64.64926 8.89000 19.53584
v 65.07386 8.89000 19.27564
v 67.03311 9.10718 17.75711
v 65.87365 9.20750 18.68606
v 65.90115 9.10718 18.72391
v 65.79033 9.37644 18.57138
v 64.54628 9.37644 19.33373
v 64.58181 9.29817 19.40347
v 65.73703 9.43993 18.49802
v 65.83633 9.29817 18.63470
v 64.61064 9.20750 19.46004
v 64.50511 9.43993 19.25294
v 64.45958 9.48670 19.16356
v 65.67807 9.48670 18.41686
v 66.61118 9.52500 17.33518
v 65.92365 8.89000 18.75489
v 69.10555 9.52500 12.43970
v 68.77989 9.52500 13.79616
v 68.88476 9.51535 13.83024
v 69.21500 9.52500 11.04900
v 69.21445 9.51535 12.45695
v 69.32527 9.51535 11.04900
v 64.36098 9.52500 18.97005
v 63.07216 9.52500 19.50389
v 63.10624 9.51535 19.60876
v 69.62317 9.37644 11.04900
v 69.50870 9.37644 12.50355
v 69.58600 9.29817 12.51580
v 69.53250 9.43993 11.04900
v 69.70144 9.29817 11.04900
v 69.64870 9.20750 12.52573
v 69.41914 9.43993 12.48937
v 69.32006 9.48670 12.47368
v 69.43218 9.48670 11.04900
v 69.70144 9.29817 7.62000
v 69.62317 9.37644 7.62000
v 69.76493 9.20750 11.04900
v 69.53250 9.43993 7.62000
v 69.76493 9.20750 7.62000
v 69.84035 9.00027 11.04900
v 69.81170 9.10718 7.62000
v 69.81170 9.10718 11.04900
v 69.84035 9.00027 7.62000
v 69.21500 9.52500 7.62000
v 69.32527 9.51535 7.62000
v 61.71570 9.52500 19.82955
v 61.73295 9.51535 19.93846
v 60.32500 9.52500 19.93900
v 60.32500 9.51535 20.04927
v 69.43218 9.48670 7.62000
v 69.72321 9.00027 12.53753
v 69.69402 8.89000 12.70029
v 69.73273 8.89000 12.53904
v 69.69491 9.10718 12.53305
v 69.16808 9.37644 13.92229
v 69.08185 9.43993 13.89427
v 68.98644 9.48670 13.86327
v 69.85000 8.89000 11.04900
v 58.87045 9.37644 20.23269
v 57.42752 9.29817 19.96652
v 58.85820 9.29817 20.31000
v 57.45171 9.37644 19.89209
v 57.40791 9.20750 20.02690
v 58.84827 9.20750 20.37271
v 58.88463 9.43993 20.14314
v 58.90032 9.48670 20.04406
v 57.51073 9.48670 19.71045
v 57.47973 9.43993 19.80585
v 58.91705 9.51535 19.93846
v 56.00512 9.00027 19.52724
v 56.00074 8.89000 19.53584
v 57.07503 8.89000 19.98082
v 58.83647 9.00027 20.44720
v 58.67371 8.89000 20.41802
v 58.83496 8.89000 20.45673
v 57.54377 9.51535 19.60876
v 56.23896 9.51535 19.06830
v 56.19043 9.48670 19.16356
v 57.57784 9.52500 19.50389
v 57.38459 9.00027 20.09864
v 57.39345 9.10718 20.07139
v 58.84095 9.10718 20.41891
v 60.32500 9.37644 20.34717
v 60.32500 9.29817 20.42544
v 60.32500 9.20750 20.48893
v 60.32500 9.43993 20.25650
v 60.32500 9.48670 20.15618
v 57.38161 8.89000 20.10781
v 60.32500 9.00027 20.56435
v 60.32500 8.89000 20.57400
v 58.93430 9.52500 19.82955
v 60.32500 9.10718 20.53571
v 56.10372 9.37644 19.33373
v 54.81367 9.29817 18.63470
v 56.06818 9.29817 19.40347
v 54.85967 9.37644 18.57138
v 54.77635 9.20750 18.68606
v 56.03936 9.20750 19.46004
v 56.14488 9.43993 19.25294
v 54.97193 9.48670 18.41686
v 54.91297 9.43993 18.49802
v 53.59663 9.00027 17.77737
v 53.58981 8.89000 17.78419
v 54.22128 8.89000 18.32352
v 55.57614 8.89000 19.27564
v 55.03477 9.51535 18.33037
v 53.96085 9.51535 17.41315
v 53.88525 9.48670 17.48875
v 55.09959 9.52500 18.24116
v 54.73202 9.00027 18.74708
v 54.74886 9.10718 18.72391
v 56.01812 9.10718 19.50172
v 54.72635 8.89000 18.75489
v 56.28902 9.52500 18.97005
v 63.57498 8.89000 19.98082
v 63.26540 9.00027 20.09864
v 61.81353 9.00027 20.44720
v 61.81504 8.89000 20.45673
v 61.97629 8.89000 20.41802
v 64.63187 9.10718 19.50172
v 63.24210 9.20750 20.02690
v 63.25655 9.10718 20.07139
v 63.19829 9.37644 19.89209
v 61.77956 9.37644 20.23269
v 61.79180 9.29817 20.31000
v 63.17028 9.43993 19.80585
v 63.22248 9.29817 19.96652
v 61.80173 9.20750 20.37271
v 61.76537 9.43993 20.14314
v 61.74968 9.48670 20.04406
v 63.13927 9.48670 19.71045
v 63.26839 8.89000 20.10781
v 51.54445 9.52500 12.43970
v 51.43500 9.52500 11.04900
v 51.32473 9.51535 11.04900
v 52.40395 9.52500 15.08498
v 51.87011 9.52500 13.79616
v 51.76524 9.51535 13.83024
v 51.43554 9.51535 12.45695
v 61.80905 9.10718 20.41891
v 54.03882 9.52500 17.33518
v 53.13284 9.52500 16.27441
v 53.04363 9.51535 16.33922
v 52.30571 9.51535 15.13504
v 69.41914 4.53008 12.48937
v 69.62317 4.59356 11.04900
v 69.50870 4.59356 12.50355
v 69.53250 4.53008 11.04900
v 69.70144 4.67183 11.04900
v 69.58600 4.67183 12.51580
v 69.32006 4.48330 12.47368
v 69.21445 4.45465 12.45695
v 69.32527 4.45465 11.04900
v 69.43218 4.48330 11.04900
v 69.72321 4.96973 12.53753
v 69.84035 4.96973 11.04900
v 69.85000 5.08000 11.04900
v 69.69491 4.86282 12.53305
v 69.31434 4.45282 8.89000
v 69.73273 5.08000 12.53904
v 69.81170 4.86282 11.04900
v 69.76493 4.76250 11.04900
v 69.64870 4.76250 12.52573
v 69.08185 4.53008 13.89427
v 68.98644 4.48330 13.86327
v 69.16808 4.59356 13.92229
v 68.77989 4.44500 13.79616
v 68.88476 4.45465 13.83024
v 69.69402 5.08000 12.70029
v 69.34740 4.86282 13.98055
v 69.37463 4.96973 13.98941
v 69.38381 5.08000 13.99239
v 69.30290 4.76250 13.96610
v 69.24252 4.67183 13.94648
v 69.84218 4.98066 8.89000
v 69.83961 4.96991 8.89000
v 69.31434 4.15387 8.06863
v 69.81892 4.88377 8.89000
v 69.85000 5.08000 8.89000
v 69.78079 4.79172 8.89000
v 69.81046 4.86334 8.89000
v 69.83365 4.39615 7.62000
v 69.84218 4.55822 7.72934
v 69.81892 4.48400 7.79162
v 69.83865 4.45677 7.62000
v 69.65653 3.88461 7.62000
v 69.66953 3.91449 7.62000
v 69.65794 3.90612 7.63021
v 69.81396 4.31626 7.62000
v 69.85000 4.59490 7.62000
v 69.31434 4.37576 8.45296
v 69.31434 4.28162 8.25109
v 69.85000 4.63431 7.66549
v 69.79626 4.24438 7.62000
v 69.78079 4.21420 7.65152
v 69.77910 4.17473 7.62000
v 69.50329 4.51421 8.89000
v 69.43166 4.48455 8.89000
v 69.53159 4.53156 8.89000
v 69.58824 4.56627 8.89000
v 69.41122 4.47608 8.89000
v 69.32509 4.45540 8.89000
v 69.72872 4.70676 8.89000
v 69.69997 4.67309 8.89000
v 69.76344 4.76341 8.89000
v 69.66402 4.63099 8.89000
v 69.62191 4.59503 8.89000
v 66.83569 4.53008 17.55968
v 67.77402 4.53008 16.46103
v 67.84738 4.59356 16.51433
v 66.76475 4.48330 17.48875
v 66.89980 4.59356 17.62380
v 67.91070 4.67183 16.56033
v 67.69287 4.48330 16.40207
v 66.61118 4.44500 17.33518
v 67.60637 4.45465 16.33922
v 66.68915 4.45465 17.41315
v 68.02309 4.96973 16.64198
v 68.03088 5.08000 16.64765
v 67.59952 5.08000 17.15272
v 67.03311 4.86282 17.75711
v 67.05337 4.96973 17.77737
v 67.99991 4.86282 16.62514
v 67.06020 5.08000 17.78419
v 67.00004 4.76250 17.72404
v 66.95514 4.67183 17.67914
v 67.96206 4.76250 16.59765
v 65.73703 4.53008 18.49802
v 65.67807 4.48330 18.41686
v 65.79033 4.59356 18.57138
v 65.55041 4.44500 18.24116
v 65.61522 4.45465 18.33037
v 66.42872 5.08000 18.32352
v 65.90115 4.86282 18.72391
v 65.91798 4.96973 18.74708
v 65.92365 5.08000 18.75489
v 65.87365 4.76250 18.68606
v 65.83633 4.67183 18.63470
v 68.52895 4.53008 15.22912
v 68.43956 4.48330 15.18357
v 68.60973 4.59356 15.27028
v 68.34429 4.45465 15.13504
v 69.25682 5.08000 14.29897
v 68.77772 4.86282 15.35587
v 68.80325 4.96973 15.36888
v 68.81184 5.08000 15.37326
v 68.73604 4.76250 15.33464
v 68.67947 4.67183 15.30581
v 67.51716 4.44500 16.27441
v 68.55164 5.08000 15.79786
v 69.66402 4.60887 8.63717
v 69.72872 4.61438 8.36611
v 69.72872 4.68349 8.62401
v 69.66402 4.54318 8.39202
v 69.78079 4.69422 8.33705
v 69.78079 4.76715 8.60926
v 69.58824 4.54514 8.64841
v 69.50329 4.49387 8.65745
v 69.50329 4.43345 8.43196
v 69.58824 4.48237 8.41416
v 69.41122 4.45631 8.66407
v 69.84218 4.95323 8.57645
v 69.85000 4.96512 8.23845
v 69.85000 5.05106 8.55920
v 69.41122 4.30177 8.23946
v 69.41122 4.39762 8.44500
v 69.84218 4.87177 8.27243
v 69.81892 4.78072 8.30556
v 69.81892 4.85782 8.59327
v 69.31434 3.99636 7.91113
v 69.50329 4.03583 7.86410
v 69.41122 4.17168 8.05368
v 69.41122 4.01132 7.89332
v 69.50329 4.20089 8.02917
v 69.31434 4.43340 8.66811
v 69.58824 4.37988 8.19436
v 69.66402 4.29035 7.95411
v 69.66402 4.43592 8.16201
v 69.58824 4.24078 7.99571
v 69.72872 4.34839 7.90541
v 69.72872 4.50154 8.12412
v 69.50329 4.33479 8.22039
v 69.84218 4.73875 7.98717
v 69.81892 4.65484 8.03561
v 69.85000 8.89000 7.62000
v 69.85000 4.82478 7.93750
v 69.78079 4.41348 7.85080
v 69.78079 4.57512 8.08164
v 69.62093 3.80279 7.62000
v 69.58824 3.87064 7.68512
v 69.58237 3.74175 7.62000
v 69.50329 3.84461 7.73021
v 69.31434 3.61204 7.68924
v 69.31434 3.81391 7.78338
v 69.41122 3.61999 7.66739
v 69.49693 3.63777 7.63197
v 69.41122 3.82554 7.76323
v 69.31434 3.39689 7.63160
v 69.30463 3.30224 7.62000
v 69.31324 3.31586 7.62000
v 69.36876 3.40372 7.62000
v 69.21912 3.18082 7.62000
v 69.52168 3.64571 7.62000
v 69.71909 4.02838 7.62000
v 69.40131 3.45523 7.62000
v 69.49088 3.59698 7.62000
v 69.66402 4.11089 7.77465
v 69.58824 4.06929 7.82422
v 69.72872 4.15960 7.71661
v 69.77431 4.15532 7.62000
v 69.72321 67.19974 12.53753
v 69.34740 67.09282 13.98055
v 69.69491 67.09282 12.53305
v 69.37463 67.19974 13.98941
v 69.84035 67.19974 11.04900
v 69.81170 67.09282 11.04900
v 68.77772 67.09282 15.35587
v 68.02309 67.19974 16.64198
v 67.99991 67.09282 16.62514
v 68.80325 67.19974 15.36888
v 67.03311 67.09282 17.75711
v 64.63187 67.09282 19.50172
v 64.61064 66.99250 19.46004
v 65.90115 67.09282 18.72391
v 63.24210 66.99250 20.02690
v 65.87365 66.99250 18.68606
v 63.25655 67.09282 20.07139
v 61.80905 67.09282 20.41891
v 60.32500 66.99250 20.48893
v 61.80173 66.99250 20.37271
v 60.32500 67.09282 20.53571
v 63.26839 67.31000 20.10781
v 63.26540 67.19974 20.09864
v 63.57498 67.31000 19.98082
v 61.97629 67.31000 20.41802
v 61.81353 67.19974 20.44720
v 60.32500 67.19974 20.56435
v 60.32500 67.31000 20.57400
v 61.81504 67.31000 20.45673
v 64.64488 67.19974 19.52724
v 65.91798 67.19974 18.74708
v 67.05337 67.19974 17.77737
v 68.73604 66.99250 15.33464
v 67.91070 66.90183 16.56033
v 68.67947 66.90183 15.30581
v 67.96206 66.99250 16.59765
v 69.30290 66.99250 13.96610
v 69.24252 66.90183 13.94648
v 66.95514 66.90183 17.67914
v 65.83633 66.90183 18.63470
v 67.00004 66.99250 17.72404
v 64.58181 66.90183 19.40347
v 61.79180 66.90183 20.31000
v 61.77956 66.82356 20.23269
v 63.22248 66.90183 19.96652
v 60.32500 66.82356 20.34717
v 63.19829 66.82356 19.89209
v 60.32500 66.90183 20.42544
v 69.64870 66.99250 12.52573
v 69.58600 66.90183 12.51580
v 69.76493 66.99250 11.04900
v 69.70144 66.90183 11.04900
v 56.19043 66.71329 19.16356
v 55.03477 66.68465 18.33037
v 56.23896 66.68465 19.06830
v 54.97193 66.71329 18.41686
v 57.51073 66.71329 19.71045
v 57.54377 66.68465 19.60876
v 53.96085 66.68465 17.41315
v 52.95714 66.71329 16.40207
v 53.04363 66.68465 16.33922
v 53.88525 66.71329 17.48875
v 52.30571 66.68465 15.13504
v 51.43554 66.68465 12.45695
v 51.54445 66.67500 12.43970
v 51.76524 66.68465 13.83024
v 51.43500 66.67500 11.04900
v 51.87011 66.67500 13.79616
v 51.32473 66.68465 11.04900
v 58.90032 66.71329 20.04406
v 58.91705 66.68465 19.93846
v 60.32500 66.71329 20.15618
v 60.32500 66.68465 20.04927
v 57.47973 66.76008 19.80585
v 56.14488 66.76008 19.25294
v 58.88463 66.76008 20.14314
v 53.81432 66.76008 17.55968
v 54.91297 66.76008 18.49802
v 51.66356 66.71329 13.86327
v 52.21044 66.71329 15.18357
v 51.32994 66.71329 12.47368
v 60.32500 66.76008 20.25650
v 51.21782 66.71329 11.04900
v 68.55164 67.31000 15.79786
v 68.81184 67.31000 15.37326
v 68.03088 67.31000 16.64765
v 67.59952 67.31000 17.15272
v 69.25682 67.31000 14.29897
v 69.69402 67.31000 12.70029
v 69.38381 67.31000 13.99239
v 65.07386 67.31000 19.27564
v 65.92365 67.31000 18.75489
v 64.64926 67.31000 19.53584
v 66.42872 67.31000 18.32352
v 67.06020 67.31000 17.78419
v 54.03882 66.67500 17.33518
v 55.09959 66.67500 18.24116
v 56.28902 66.67500 18.97005
v 53.13284 66.67500 16.27441
v 52.40395 66.67500 15.08498
v 69.85000 67.31000 11.04900
v 58.93430 66.67500 19.82955
v 60.32500 66.67500 19.93900
v 69.73273 67.31000 12.53904
v 57.57784 66.67500 19.50389
v 69.70144 66.90183 7.62000
v 69.76493 66.99250 7.62000
v 69.62317 66.82356 11.04900
v 69.81170 67.09282 7.62000
v 69.32527 66.68465 11.04900
v 69.10555 66.67500 12.43970
v 69.21500 66.67500 11.04900
v 68.88476 66.68465 13.83024
v 68.77989 66.67500 13.79616
v 69.21445 66.68465 12.45695
v 68.24605 66.67500 15.08498
v 69.57005 66.78353 7.62000
v 69.62317 66.82356 7.62000
v 69.53250 66.76008 11.04900
v 69.43218 66.71329 11.04900
v 69.43858 66.71566 7.60354
v 69.44609 66.71855 7.60540
v 69.85000 67.31000 7.62000
v 69.84035 67.19974 7.62000
v 63.10624 66.68465 19.60876
v 61.71570 66.67500 19.82955
v 63.07216 66.67500 19.50389
v 61.73295 66.68465 19.93846
v 64.41103 66.68465 19.06830
v 64.36098 66.67500 18.97005
v 69.32006 66.71329 12.47368
v 67.60637 66.68465 16.33922
v 67.51716 66.67500 16.27441
v 68.34429 66.68465 15.13504
v 66.61118 66.67500 17.33518
v 66.68915 66.68465 17.41315
v 65.61522 66.68465 18.33037
v 65.55041 66.67500 18.24116
v 35.49650 43.43400 7.62000
v 35.49650 44.45000 7.62000
v 85.17085 44.45000 7.62000
v 73.12357 44.45000 7.62000
v 35.49650 32.76600 7.62000
v 35.49650 31.75000 7.62000
v 47.26535 31.75000 7.62000
v 68.40075 31.75000 7.62000
v 73.12357 31.75000 7.62000
v 47.26535 44.45000 7.62000
v 68.40075 44.45000 7.62000
v 69.39590 66.70207 7.58985
v 69.43209 66.71359 7.60146
v 69.21500 66.67500 7.48467
v 69.24048 66.67558 7.50583
v 69.53201 66.76091 7.61778
v 69.48177 66.73473 7.61173
v 69.50802 66.74664 7.61638
v 69.30402 66.68127 7.54893
v 69.31448 66.68285 7.55477
v 69.31593 66.68307 7.55558
v 69.24390 66.67566 7.50868
v 69.32519 66.68503 7.55987
v 69.37620 66.69580 7.58353
v 66.89980 66.82356 17.62380
v 65.73703 66.76008 18.49802
v 66.83569 66.76008 17.55968
v 65.79033 66.82356 18.57138
v 67.84738 66.82356 16.51433
v 67.77402 66.76008 16.46103
v 64.50511 66.76008 19.25294
v 63.17028 66.76008 19.80585
v 64.54628 66.82356 19.33373
v 61.76537 66.76008 20.14314
v 69.50870 66.82356 12.50355
v 69.41914 66.76008 12.48937
v 69.08185 66.76008 13.89427
v 69.16808 66.82356 13.92229
v 68.60973 66.82356 15.27028
v 68.52895 66.76008 15.22912
v 64.45958 66.71329 19.16356
v 63.13927 66.71329 19.71045
v 65.67807 66.71329 18.41686
v 61.74968 66.71329 20.04406
v 68.43956 66.71329 15.18357
v 68.98644 66.71329 13.86327
v 67.69287 66.71329 16.40207
v 66.76475 66.71329 17.48875
v 52.87598 66.76008 16.46103
v 51.06400 9.29817 12.51580
v 50.94856 9.29817 11.04900
v 50.88507 9.20750 11.04900
v 51.14131 9.37644 12.50355
v 51.00130 9.20750 12.52573
v 50.83829 9.10718 11.04900
v 51.02683 9.37644 11.04900
v 51.32994 9.48670 12.47368
v 51.11750 9.43993 11.04900
v 51.23086 9.43993 12.48937
v 51.21782 9.48670 11.04900
v 51.32473 9.51535 7.62000
v 51.43500 9.52500 7.62000
v 51.21782 9.48670 7.62000
v 50.92680 9.00027 12.53753
v 50.80000 8.89000 11.04900
v 50.91727 8.89000 12.53904
v 50.95509 9.10718 12.53305
v 50.80965 9.00027 11.04900
v 51.48191 9.37644 13.92229
v 51.40748 9.29817 13.94648
v 51.34710 9.20750 13.96610
v 51.56815 9.43993 13.89427
v 51.66356 9.48670 13.86327
v 51.27536 9.00027 13.98941
v 50.95598 8.89000 12.70029
v 51.26619 8.89000 13.99239
v 51.30261 9.10718 13.98055
v 50.83829 67.09282 7.62000
v 50.83829 67.09282 11.04900
v 50.80965 67.19974 11.04900
v 50.88507 66.99250 7.62000
v 50.80965 67.19974 7.62000
v 50.80000 67.31000 11.04900
v 50.88507 66.99250 11.04900
v 51.02683 66.82356 7.62000
v 50.94856 66.90183 11.04900
v 50.94856 66.90183 7.62000
v 51.02683 66.82356 11.04900
v 51.24991 66.70258 7.59220
v 51.21811 66.71409 7.60082
v 51.37802 66.67757 7.52895
v 51.40886 66.67618 7.50498
v 51.18847 66.72481 7.60885
v 51.33508 66.68348 7.55403
v 51.31876 66.68573 7.56356
v 51.32477 66.68490 7.56005
v 50.80000 67.31000 7.62000
v 50.94856 9.29817 7.62000
v 50.88507 9.20750 7.62000
v 50.83829 9.10718 7.62000
v 51.02683 9.37644 7.62000
v 51.11750 9.43993 7.62000
v 51.07996 66.78353 7.62000
v 51.11153 66.76357 7.61906
v 51.11750 66.76008 11.04900
v 51.43500 66.67500 7.48467
v 50.80000 8.89000 7.62000
v 50.80965 9.00027 7.62000
v 53.75020 9.37644 17.62380
v 52.73930 9.29817 16.56033
v 53.69486 9.29817 17.67914
v 52.80262 9.37644 16.51433
v 52.68794 9.20750 16.59765
v 53.64997 9.20750 17.72404
v 53.81432 9.43993 17.55968
v 52.95714 9.48670 16.40207
v 52.87598 9.43993 16.46103
v 51.84676 9.00027 15.36888
v 51.83816 8.89000 15.37326
v 52.09836 8.89000 15.79786
v 53.05048 8.89000 17.15272
v 52.21044 9.48670 15.18357
v 52.62692 9.00027 16.64198
v 52.65009 9.10718 16.62514
v 53.61689 9.10718 17.75711
v 52.61911 8.89000 16.64765
v 52.04027 9.37644 15.27028
v 51.97054 9.29817 15.30581
v 51.91397 9.20750 15.33464
v 52.12106 9.43993 15.22912
v 51.39318 8.89000 14.29897
v 51.87228 9.10718 15.35587
v 51.06400 66.90183 12.51580
v 58.85820 66.90183 20.31000
v 51.14131 66.82356 12.50355
v 51.40748 66.90183 13.94648
v 58.84827 66.99250 20.37271
v 57.40791 66.99250 20.02690
v 56.06818 66.90183 19.40347
v 57.42752 66.90183 19.96652
v 56.03936 66.99250 19.46004
v 53.69486 66.90183 17.67914
v 52.80262 66.82356 16.51433
v 53.75020 66.82356 17.62380
v 52.73930 66.90183 16.56033
v 54.81367 66.90183 18.63470
v 54.85967 66.82356 18.57138
v 52.04027 66.82356 15.27028
v 51.48191 66.82356 13.92229
v 51.97054 66.90183 15.30581
v 51.00130 66.99250 12.52573
v 51.34710 66.99250 13.96610
v 51.91397 66.99250 15.33464
v 58.84095 67.09282 20.41891
v 57.39345 67.09282 20.07139
v 54.77635 66.99250 18.68606
v 53.64997 66.99250 17.72404
v 52.68794 66.99250 16.59765
v 57.45171 66.82356 19.89209
v 58.87045 66.82356 20.23269
v 56.10372 66.82356 19.33373
v 52.12106 66.76008 15.22912
v 51.56815 66.76008 13.89427
v 51.23086 66.76008 12.48937
v 53.05048 67.31000 17.15272
v 52.61911 67.31000 16.64765
v 52.62692 67.19974 16.64198
v 53.59663 67.19974 17.77737
v 52.09836 67.31000 15.79786
v 51.84676 67.19974 15.36888
v 53.58981 67.31000 17.78419
v 54.73202 67.19974 18.74708
v 54.72635 67.31000 18.75489
v 54.22128 67.31000 18.32352
v 55.57614 67.31000 19.27564
v 50.95598 67.31000 12.70029
v 50.92680 67.19974 12.53753
v 51.27536 67.19974 13.98941
v 50.91727 67.31000 12.53904
v 51.26619 67.31000 13.99239
v 51.39318 67.31000 14.29897
v 51.83816 67.31000 15.37326
v 51.16834 66.73417 7.61222
v 51.17579 66.73032 7.61132
v 58.83647 67.19974 20.44720
v 51.11766 66.76039 7.61833
v 51.25427 66.70148 7.59043
v 51.31160 66.68710 7.56708
v 57.07503 67.31000 19.98082
v 56.00074 67.31000 19.53584
v 56.00512 67.19974 19.52724
v 57.38459 67.19974 20.09864
v 57.38161 67.31000 20.10781
v 58.83496 67.31000 20.45673
v 58.67371 67.31000 20.41802
v 51.30261 67.09282 13.98055
v 51.87228 67.09282 15.35587
v 52.65009 67.09282 16.62514
v 50.95509 67.09282 12.53305
v 56.01812 67.09282 19.50172
v 54.74886 67.09282 18.72391
v 53.61689 67.09282 17.75711
v 58.85820 71.52817 20.31000
v 58.87045 71.60645 20.23269
v 57.42752 71.52817 19.96652
v 60.32500 71.60645 20.34717
v 57.45171 71.60645 19.89209
v 56.06818 71.52817 19.40347
v 60.32500 71.52817 20.42544
v 51.00130 71.43750 12.52573
v 51.40748 71.52817 13.94648
v 51.06400 71.52817 12.51580
v 51.34710 71.43750 13.96610
v 50.88507 71.43750 11.04900
v 50.94856 71.52817 11.04900
v 52.73930 71.52817 16.56033
v 53.75020 71.60645 17.62380
v 52.80262 71.60645 16.51433
v 53.69486 71.52817 17.67914
v 51.97054 71.52817 15.30581
v 52.04027 71.60645 15.27028
v 54.85967 71.60645 18.57138
v 56.10372 71.60645 19.33373
v 54.81367 71.52817 18.63470
v 57.40791 71.43750 20.02690
v 56.03936 71.43750 19.46004
v 54.77635 71.43750 18.68606
v 58.84827 71.43750 20.37271
v 50.83829 71.33718 11.04900
v 50.95509 71.33718 12.53305
v 60.32500 71.43750 20.48893
v 51.91397 71.43750 15.33464
v 52.68794 71.43750 16.59765
v 53.64997 71.43750 17.72404
v 51.14131 71.60645 12.50355
v 51.23086 71.66992 12.48937
v 51.02683 71.60645 11.04900
v 51.56815 71.66992 13.89427
v 60.32500 71.66992 20.25650
v 60.32500 71.71671 20.15618
v 58.88463 71.66992 20.14314
v 51.11750 71.66992 11.04900
v 51.48191 71.60645 13.92229
v 52.87598 71.66992 16.46103
v 52.12106 71.66992 15.22912
v 54.91297 71.66992 18.49802
v 56.19043 71.71671 19.16356
v 54.97193 71.71671 18.41686
v 56.14488 71.66992 19.25294
v 53.81432 71.66992 17.55968
v 53.88525 71.71671 17.48875
v 57.51073 71.71671 19.71045
v 58.90032 71.71671 20.04406
v 57.47973 71.66992 19.80585
v 54.73202 71.23026 18.74708
v 55.57614 71.12000 19.27564
v 56.00512 71.23026 19.52724
v 54.72635 71.12000 18.75489
v 56.00074 71.12000 19.53584
v 57.07503 71.12000 19.98082
v 54.22128 71.12000 18.32352
v 53.05048 71.12000 17.15272
v 53.58981 71.12000 17.78419
v 53.59663 71.23026 17.77737
v 52.62692 71.23026 16.64198
v 57.38161 71.12000 20.10781
v 57.38459 71.23026 20.09864
v 58.67371 71.12000 20.41802
v 58.83647 71.23026 20.44720
v 60.32500 71.33718 20.53571
v 58.84095 71.33718 20.41891
v 60.32500 71.23026 20.56435
v 60.32500 71.12000 20.57400
v 58.83496 71.12000 20.45673
v 50.92680 71.23026 12.53753
v 50.80965 71.23026 11.04900
v 50.80000 71.12000 11.04900
v 60.32500 71.74535 20.04927
v 61.71570 71.75500 19.82955
v 60.32500 71.75500 19.93900
v 50.91727 71.12000 12.53904
v 50.95598 71.12000 12.70029
v 61.73295 71.74535 19.93846
v 63.10624 71.74535 19.60876
v 64.36098 71.75500 18.97005
v 63.07216 71.75500 19.50389
v 64.41103 71.74535 19.06830
v 51.84676 71.23026 15.36888
v 52.09836 71.12000 15.79786
v 51.83816 71.12000 15.37326
v 52.61911 71.12000 16.64765
v 51.39318 71.12000 14.29897
v 51.26619 71.12000 13.99239
v 51.27536 71.23026 13.98941
v 56.01812 71.33718 19.50172
v 54.74886 71.33718 18.72391
v 53.61689 71.33718 17.75711
v 57.39345 71.33718 20.07139
v 51.30261 71.33718 13.98055
v 51.87228 71.33718 15.35587
v 52.65009 71.33718 16.62514
v 51.34536 72.89776 7.62000
v 51.33566 72.83582 7.62626
v 51.43089 73.01918 7.62000
v 51.33676 72.88414 7.62000
v 51.30358 72.83163 7.62000
v 51.02494 72.38773 7.62000
v 51.06176 72.40157 7.64623
v 51.02906 72.39720 7.62000
v 50.99347 72.31539 7.62000
v 51.24869 72.74477 7.62000
v 51.23878 72.63229 7.64960
v 51.16600 72.61391 7.62000
v 51.15912 72.60302 7.62000
v 50.83955 71.33666 8.89000
v 50.86921 71.40829 8.89000
v 50.86921 71.42844 8.63558
v 50.83108 71.33752 8.62110
v 50.88655 71.43659 8.89000
v 50.92128 71.51233 8.64895
v 50.83108 71.31623 8.89000
v 50.80782 71.24184 8.60585
v 50.80782 71.21934 8.89000
v 50.81040 71.23009 8.89000
v 50.80000 71.14373 8.59022
v 50.80000 71.21435 8.29791
v 50.80000 71.12000 8.89000
v 50.80000 71.48805 7.76447
v 50.80000 71.33009 8.02035
v 50.80782 71.41846 8.06570
v 50.80782 71.30876 8.32878
v 50.87090 72.02527 7.62000
v 50.86921 71.88721 7.74143
v 50.87569 72.04469 7.62000
v 50.83603 71.88374 7.62000
v 50.92128 71.94700 7.80179
v 50.90331 72.10818 7.62000
v 50.83108 71.82242 7.67603
v 50.80000 71.60510 7.62000
v 50.80782 71.56820 7.82316
v 50.81136 71.74323 7.62000
v 50.81635 71.80386 7.62000
v 50.81316 71.76517 7.62000
v 51.06176 71.70263 8.45758
v 51.14672 71.70248 8.67925
v 51.14672 71.75211 8.47376
v 51.06176 71.65105 8.67106
v 51.23878 71.74014 8.68526
v 51.23878 71.78835 8.48561
v 50.98599 71.64113 8.43747
v 50.92128 71.56911 8.41392
v 50.98599 71.58715 8.66088
v 50.86921 71.48836 8.38751
v 51.33566 71.76310 8.68892
v 51.33566 71.74718 8.89000
v 51.32492 71.74461 8.89000
v 51.33566 72.63931 7.67178
v 51.33566 71.81047 8.49284
v 51.23878 71.72392 8.89000
v 51.21833 71.71546 8.89000
v 51.02809 71.60497 8.89000
v 51.06176 71.63373 8.89000
v 51.11841 71.66845 8.89000
v 50.98599 71.56901 8.89000
v 50.92128 71.49325 8.89000
v 50.95003 71.52692 8.89000
v 50.83108 71.40086 8.35890
v 51.14672 71.68579 8.89000
v 52.21044 71.71671 15.18357
v 52.30571 71.74535 15.13504
v 51.66356 71.71671 13.86327
v 53.04363 71.74535 16.33922
v 51.76524 71.74535 13.83024
v 51.32994 71.71671 12.47368
v 52.95714 71.71671 16.40207
v 55.03477 71.74535 18.33037
v 53.96085 71.74535 17.41315
v 57.54377 71.74535 19.60876
v 58.93430 71.75500 19.82955
v 57.57784 71.75500 19.50389
v 58.91705 71.74535 19.93846
v 56.23896 71.74535 19.06830
v 56.28902 71.75500 18.97005
v 51.43554 71.74535 12.45695
v 51.21782 71.71671 11.04900
v 51.32473 71.74535 11.04900
v 53.13284 71.75500 16.27441
v 54.03882 71.75500 17.33518
v 52.40395 71.75500 15.08498
v 55.09959 71.75500 18.24116
v 51.54445 71.75500 12.43970
v 51.87011 71.75500 13.79616
v 65.55041 71.75500 18.24116
v 69.76493 71.43750 11.04900
v 69.70144 71.52817 8.89000
v 69.70144 71.52817 11.04900
v 69.76493 71.43750 8.89000
v 69.62317 71.60645 8.89000
v 69.62317 71.60645 11.04900
v 69.81170 71.33718 11.04900
v 69.84035 71.23026 11.04900
v 69.84035 71.23026 8.89000
v 69.81170 71.33718 8.89000
v 69.85000 71.12000 11.04900
v 69.32527 71.74535 11.04900
v 69.32527 71.74535 8.89000
v 69.43218 71.71671 11.04900
v 61.81353 71.23026 20.44720
v 69.43218 71.71671 8.89000
v 69.53250 71.66992 8.89000
v 69.53250 71.66992 11.04900
v 69.70144 71.69324 8.20669
v 69.76493 71.51613 8.39659
v 69.81170 71.35821 8.62440
v 69.62317 71.88051 8.05187
v 69.53250 72.07134 7.92731
v 69.43218 72.26200 7.82724
v 69.69402 71.12000 12.70029
v 69.72321 71.23026 12.53753
v 69.37463 71.23026 13.98941
v 69.73273 71.12000 12.53904
v 69.38381 71.12000 13.99239
v 69.25682 71.12000 14.29897
v 68.80325 71.23026 15.36888
v 68.81184 71.12000 15.37326
v 68.55164 71.12000 15.79786
v 68.77772 71.33718 15.35587
v 68.02309 71.23026 16.64198
v 69.34740 71.33718 13.98055
v 67.99991 71.33718 16.62514
v 67.05337 71.23026 17.77737
v 69.69491 71.33718 12.53305
v 63.57498 71.12000 19.98082
v 64.64926 71.12000 19.53584
v 64.64488 71.23026 19.52724
v 63.26540 71.23026 20.09864
v 65.07386 71.12000 19.27564
v 65.91798 71.23026 18.74708
v 63.26839 71.12000 20.10781
v 61.81504 71.12000 20.45673
v 61.97629 71.12000 20.41802
v 67.59952 71.12000 17.15272
v 68.03088 71.12000 16.64765
v 67.06020 71.12000 17.78419
v 65.92365 71.12000 18.75489
v 66.42872 71.12000 18.32352
v 69.53250 71.73704 8.46883
v 69.43218 71.78150 8.48337
v 69.53250 71.68681 8.67676
v 69.43218 71.86098 8.29275
v 69.62317 71.62411 8.66677
v 69.53250 71.81937 8.27140
v 69.43218 71.96948 8.11702
v 69.62317 71.67670 8.44910
v 69.32527 71.76129 8.68863
v 69.43218 71.73300 8.68412
v 69.32527 71.80873 8.49227
v 69.48546 72.61638 7.62000
v 69.62317 71.76288 8.24242
v 69.53250 71.93173 8.08938
v 69.70144 71.60230 8.42477
v 69.43218 72.10426 7.96055
v 69.76493 71.45728 8.64018
v 69.70144 71.54682 8.65445
v 68.52895 71.66992 15.22912
v 68.98644 71.71671 13.86327
v 68.43956 71.71671 15.18357
v 69.08185 71.66992 13.89427
v 67.77402 71.66992 16.46103
v 67.69287 71.71671 16.40207
v 69.32006 71.71671 12.47368
v 69.41914 71.66992 12.48937
v 61.76537 71.66992 20.14314
v 64.50511 71.66992 19.25294
v 64.45958 71.71671 19.16356
v 63.17028 71.66992 19.80585
v 65.67807 71.71671 18.41686
v 63.13927 71.71671 19.71045
v 65.73703 71.66992 18.49802
v 66.83569 71.66992 17.55968
v 66.76475 71.71671 17.48875
v 67.84738 71.60645 16.51433
v 68.60973 71.60645 15.27028
v 66.89980 71.60645 17.62380
v 69.50870 71.60645 12.50355
v 69.16808 71.60645 13.92229
v 63.19829 71.60645 19.89209
v 61.77956 71.60645 20.23269
v 64.54628 71.60645 19.33373
v 65.79033 71.60645 18.57138
v 69.21445 71.74535 12.45695
v 69.10555 71.75500 12.43970
v 68.88476 71.74535 13.83024
v 61.74968 71.71671 20.04406
v 66.68915 71.74535 17.41315
v 66.61118 71.75500 17.33518
v 65.61522 71.74535 18.33037
v 67.51716 71.75500 16.27441
v 67.60637 71.74535 16.33922
v 68.34429 71.74535 15.13504
v 64.63187 71.33718 19.50172
v 65.87365 71.43750 18.68606
v 64.61064 71.43750 19.46004
v 65.90115 71.33718 18.72391
v 63.25655 71.33718 20.07139
v 63.24210 71.43750 20.02690
v 67.00004 71.43750 17.72404
v 67.96206 71.43750 16.59765
v 67.03311 71.33718 17.75711
v 68.73604 71.43750 15.33464
v 69.64870 71.43750 12.52573
v 69.58600 71.52817 12.51580
v 69.30290 71.43750 13.96610
v 69.24252 71.52817 13.94648
v 61.80905 71.33718 20.41891
v 61.80173 71.43750 20.37271
v 66.95514 71.52817 17.67914
v 67.91070 71.52817 16.56033
v 65.83633 71.52817 18.63470
v 68.67947 71.52817 15.30581
v 61.79180 71.52817 20.31000
v 63.22248 71.52817 19.96652
v 64.58181 71.52817 19.40347
v 51.87228 4.86282 15.35587
v 52.65009 4.86282 16.62514
v 52.62692 4.96973 16.64198
v 51.91397 4.76250 15.33464
v 51.84676 4.96973 15.36888
v 52.09836 5.08000 15.79786
v 52.68794 4.76250 16.59765
v 52.04027 4.59356 15.27028
v 52.73930 4.67183 16.56033
v 51.97054 4.67183 15.30581
v 52.80262 4.59356 16.51433
v 52.95714 4.48330 16.40207
v 53.81432 4.53008 17.55968
v 52.87598 4.53008 16.46103
v 53.88525 4.48330 17.48875
v 53.75020 4.59356 17.62380
v 53.04363 4.45465 16.33922
v 53.59663 4.96973 17.77737
v 53.58981 5.08000 17.78419
v 53.05048 5.08000 17.15272
v 51.83816 5.08000 15.37326
v 53.96085 4.45465 17.41315
v 53.13284 4.44500 16.27441
v 51.30261 4.86282 13.98055
v 51.34710 4.76250 13.96610
v 51.27536 4.96973 13.98941
v 51.39318 5.08000 14.29897
v 51.48191 4.59356 13.92229
v 51.40748 4.67183 13.94648
v 52.21044 4.48330 15.18357
v 52.12106 4.53008 15.22912
v 52.30571 4.45465 15.13504
v 52.61911 5.08000 16.64765
v 51.26619 5.08000 13.99239
v 52.40395 4.44500 15.08498
v 53.61689 4.86282 17.75711
v 54.74886 4.86282 18.72391
v 54.73202 4.96973 18.74708
v 53.64997 4.76250 17.72404
v 54.22128 5.08000 18.32352
v 54.77635 4.76250 18.68606
v 54.81367 4.67183 18.63470
v 53.69486 4.67183 17.67914
v 54.85967 4.59356 18.57138
v 54.97193 4.48330 18.41686
v 56.14488 4.53008 19.25294
v 54.91297 4.53008 18.49802
v 56.19043 4.48330 19.16356
v 56.10372 4.59356 19.33373
v 55.03477 4.45465 18.33037
v 56.00512 4.96973 19.52724
v 56.00074 5.08000 19.53584
v 55.57614 5.08000 19.27564
v 56.23896 4.45465 19.06830
v 55.09959 4.44500 18.24116
v 54.72635 5.08000 18.75489
v 54.03882 4.44500 17.33518
v 51.87011 4.44500 13.79616
v 56.28902 4.44500 18.97005
v 58.93430 4.44500 19.82955
v 60.32500 4.44500 19.93900
v 60.32500 4.45465 20.04927
v 51.32492 4.45540 8.89000
v 51.32473 4.45465 11.04900
v 51.23878 4.47608 8.89000
v 57.57784 4.44500 19.50389
v 57.54377 4.45465 19.60876
v 58.91705 4.45465 19.93846
v 51.21782 4.48330 11.04900
v 51.23086 4.53008 12.48937
v 51.11750 4.53008 11.04900
v 51.32994 4.48330 12.47368
v 51.14131 4.59356 12.50355
v 51.02683 4.59356 11.04900
v 51.54445 4.44500 12.43970
v 51.43554 4.45465 12.45695
v 51.76524 4.45465 13.83024
v 50.98599 4.63099 8.89000
v 51.02809 4.59503 8.89000
v 50.94856 4.67183 11.04900
v 51.06176 4.56627 8.89000
v 50.95003 4.67309 8.89000
v 50.88507 4.76250 11.04900
v 50.88655 4.76341 8.89000
v 50.92128 4.70676 8.89000
v 50.86921 4.79172 8.89000
v 51.33566 4.45282 8.89000
v 51.21833 4.48455 8.89000
v 51.11841 4.53156 8.89000
v 51.14672 4.51421 8.89000
v 50.95509 4.86282 12.53305
v 51.00130 4.76250 12.52573
v 50.92680 4.96973 12.53753
v 50.95598 5.08000 12.70029
v 51.06400 4.67183 12.51580
v 51.66356 4.48330 13.86327
v 51.56815 4.53008 13.89427
v 50.91727 5.08000 12.53904
v 50.83829 4.86282 11.04900
v 50.80965 4.96973 11.04900
v 50.80000 5.08000 11.04900
v 61.76537 4.53008 20.14314
v 63.17028 4.53008 19.80585
v 63.19829 4.59356 19.89209
v 61.74968 4.48330 20.04406
v 61.77956 4.59356 20.23269
v 63.22248 4.67183 19.96652
v 63.13927 4.48330 19.71045
v 61.71570 4.44500 19.82955
v 63.10624 4.45465 19.60876
v 61.73295 4.45465 19.93846
v 63.26540 4.96973 20.09864
v 63.26839 5.08000 20.10781
v 61.97629 5.08000 20.41802
v 61.80905 4.86282 20.41891
v 61.81353 4.96973 20.44720
v 63.25655 4.86282 20.07139
v 61.81504 5.08000 20.45673
v 61.80173 4.76250 20.37271
v 61.79180 4.67183 20.31000
v 63.24210 4.76250 20.02690
v 60.32500 4.53008 20.25650
v 60.32500 4.48330 20.15618
v 60.32500 4.59356 20.34717
v 60.32500 4.86282 20.53571
v 60.32500 4.96973 20.56435
v 60.32500 5.08000 20.57400
v 60.32500 4.76250 20.48893
v 60.32500 4.67183 20.42544
v 64.50511 4.53008 19.25294
v 64.45958 4.48330 19.16356
v 64.54628 4.59356 19.33373
v 64.36098 4.44500 18.97005
v 64.41103 4.45465 19.06830
v 65.07386 5.08000 19.27564
v 64.63187 4.86282 19.50172
v 64.64488 4.96973 19.52724
v 64.64926 5.08000 19.53584
v 64.61064 4.76250 19.46004
v 64.58181 4.67183 19.40347
v 63.07216 4.44500 19.50389
v 63.57498 5.08000 19.98082
v 56.01812 4.86282 19.50172
v 57.39345 4.86282 20.07139
v 57.38459 4.96973 20.09864
v 56.03936 4.76250 19.46004
v 57.07503 5.08000 19.98082
v 57.40791 4.76250 20.02690
v 57.42752 4.67183 19.96652
v 56.06818 4.67183 19.40347
v 57.45171 4.59356 19.89209
v 57.51073 4.48330 19.71045
v 58.88463 4.53008 20.14314
v 57.47973 4.53008 19.80585
v 58.90032 4.48330 20.04406
v 58.87045 4.59356 20.23269
v 58.83647 4.96973 20.44720
v 58.83496 5.08000 20.45673
v 58.67371 5.08000 20.41802
v 57.38161 5.08000 20.10781
v 58.84095 4.86282 20.41891
v 58.84827 4.76250 20.37271
v 58.85820 4.67183 20.31000
v 51.14672 71.83347 8.27864
v 51.23878 71.86741 8.29605
v 51.33566 71.88811 8.30667
v 51.06176 71.78716 8.25487
v 50.98599 71.72958 8.22533
v 50.92128 71.66217 8.19074
v 51.33566 4.38953 8.49284
v 51.33566 4.43690 8.68892
v 51.23878 4.45987 8.68526
v 50.80782 4.95817 8.60585
v 50.80782 4.98066 8.89000
v 50.80000 5.08000 8.89000
v 51.23878 71.97529 8.12128
v 51.14672 71.94453 8.09875
v 51.33566 71.99406 8.13503
v 50.98599 71.85031 8.02976
v 51.06176 71.90253 8.06799
v 50.86921 71.58658 8.15196
v 50.83108 71.50467 8.10993
v 51.33566 3.56069 7.67178
v 51.23878 3.56771 7.64960
v 51.24672 3.45575 7.62000
v 51.23878 3.75802 7.72686
v 51.33566 3.36418 7.62626
v 51.43089 3.18082 7.62000
v 51.23878 4.33259 8.29605
v 51.33566 4.31190 8.30667
v 51.23878 4.41164 8.48561
v 51.33566 4.20594 8.13503
v 51.14672 4.44789 8.47376
v 51.14672 4.49752 8.67925
v 51.30322 3.36783 7.62000
v 51.33658 3.31591 7.62000
v 51.16455 3.58362 7.62000
v 51.33566 3.74759 7.74765
v 51.34536 3.30224 7.62000
v 51.33566 3.92022 7.85199
v 51.33566 4.07429 7.98220
v 50.80782 4.63180 7.82316
v 50.81182 4.45659 7.62000
v 50.81395 4.43554 7.62000
v 50.80026 4.57050 7.62000
v 50.92128 4.06832 7.64571
v 50.89988 4.08698 7.62000
v 50.90386 4.07572 7.62000
v 50.92544 4.03018 7.62000
v 51.05965 3.74685 7.62000
v 51.06211 3.74303 7.62000
v 51.06176 3.79843 7.64623
v 50.87569 4.15532 7.62000
v 50.92128 4.25300 7.80179
v 51.23878 72.44198 7.72686
v 51.23878 72.26620 7.83310
v 51.33566 72.27978 7.85199
v 51.14672 72.42490 7.69277
v 51.33566 72.45241 7.74765
v 51.14672 72.24397 7.80212
v 51.06176 72.21361 7.75983
v 50.98599 72.17587 7.70726
v 51.06176 72.04586 7.90160
v 50.98599 72.00033 7.85562
v 51.14672 72.08250 7.93858
v 50.92128 72.13168 7.64571
v 51.33566 72.12572 7.98220
v 51.06763 72.45825 7.62000
v 50.93092 72.17162 7.62000
v 51.23878 72.10934 7.96567
v 50.86921 71.72064 7.93479
v 50.92128 71.78919 7.98499
v 50.83108 71.64637 7.88040
v 50.92128 4.41081 7.98499
v 50.92128 4.53783 8.19074
v 50.86921 4.61343 8.15196
v 50.98599 4.34968 8.02976
v 50.86921 4.71164 8.38751
v 50.83108 4.79914 8.35890
v 50.98599 4.19968 7.85562
v 51.06176 3.98639 7.75983
v 51.14672 3.95603 7.80212
v 51.06176 4.15413 7.90160
v 51.14672 3.77510 7.69277
v 50.80000 4.71194 7.76447
v 50.80000 4.86992 8.02035
v 50.80782 4.78153 8.06570
v 50.80000 4.98565 8.29791
v 50.80000 4.59490 7.62000
v 50.83108 4.86248 8.62110
v 50.81040 4.96991 8.89000
v 50.83451 4.31689 7.62000
v 50.87072 4.17482 7.62000
v 50.83108 4.37758 7.67603
v 50.86921 4.77157 8.63558
v 50.92128 4.68767 8.64895
v 50.86921 4.31279 7.74143
v 50.83108 4.55363 7.88040
v 50.92128 4.63089 8.41392
v 50.98599 4.47042 8.22533
v 51.06176 4.41284 8.25487
v 50.98599 4.55888 8.43747
v 51.06176 4.29747 8.06799
v 51.15536 3.59792 7.62000
v 50.83108 4.88377 8.89000
v 50.83955 4.86334 8.89000
v 50.98599 4.02413 7.70726
v 50.99446 3.88448 7.62000
v 50.83108 4.69533 8.10993
v 50.86921 4.47936 7.93479
v 51.02717 3.81542 7.62000
v 50.80782 4.89124 8.32878
v 50.80000 5.05627 8.59022
v 50.82035 4.37247 7.62000
v 51.06176 4.49737 8.45758
v 50.98599 4.61285 8.66088
v 51.14672 4.36652 8.27864
v 51.23878 4.09066 7.96567
v 51.23878 4.22471 8.12128
v 51.14672 4.25547 8.09875
v 51.06176 4.54894 8.67106
v 51.14672 4.11749 7.93858
v 51.23878 3.93379 7.83310
v 34.41490 38.84981 7.62000
v 34.22009 39.57684 7.62000
v 34.48050 38.10000 7.62000
v 28.00350 41.83950 7.62000
v 27.38694 41.40778 7.62000
v 26.85472 40.87556 7.62000
v 29.41269 42.35240 7.62000
v 28.68566 42.15759 7.62000
v 26.10491 39.57684 5.71500
v 26.10491 39.57684 7.62000
v 25.91010 38.84981 7.62000
v 25.91010 38.84981 5.71500
v 25.84450 38.10000 7.62000
v 26.42300 40.25900 7.62000
v 33.47028 40.87556 7.62000
v 32.93806 41.40778 7.62000
v 32.32150 41.83950 7.62000
v 33.90200 40.25900 7.62000
v 30.91231 42.35240 7.62000
v 30.16250 42.41800 7.62000
v 31.63935 42.15759 7.62000
v 28.85636 36.54340 3.17500
v 28.60590 36.79386 3.17500
v 29.14650 36.34024 3.17500
v 30.16250 40.13200 3.17500
v 30.51535 40.10113 3.17500
v 29.14650 39.85976 3.17500
v 28.40274 37.08400 3.17500
v 28.16137 37.74715 3.17500
v 28.25304 37.40501 3.17500
v 31.46865 36.54340 3.17500
v 31.17850 36.34024 3.17500
v 31.71910 36.79386 3.17500
v 31.92227 37.08400 3.17500
v 30.85748 36.19054 3.17500
v 30.16250 36.06800 3.17500
v 30.51535 36.09887 3.17500
v 28.25304 38.79499 3.17500
v 28.40274 39.11600 3.17500
v 28.60590 39.40614 3.17500
v 28.16137 38.45285 3.17500
v 28.85636 39.65660 3.17500
v 86.44726 39.57684 5.71500
v 86.25245 38.84981 7.62000
v 86.25245 38.84981 5.71500
v 86.18686 38.10000 7.62000
v 86.18686 38.10000 5.71500
v 86.44726 39.57684 7.62000
v 86.76536 40.25900 7.62000
v 86.25245 37.35019 7.62000
v 86.44726 36.62316 5.71500
v 86.76536 35.94100 7.62000
v 87.19707 35.32444 7.62000
v 86.44726 36.62316 7.62000
v 86.25245 37.35019 5.71500
v 87.19707 40.87556 7.62000
v 90.50485 42.41800 7.62000
v 89.75504 42.35240 7.62000
v 91.25467 42.35240 7.62000
v 91.98170 42.15759 7.62000
v 89.02802 42.15759 7.62000
v 87.72929 41.40778 7.62000
v 88.34586 41.83950 7.62000
v 93.81263 35.32444 7.62000
v 94.24435 35.94100 7.62000
v 94.49773 36.48435 7.62000
v 92.66386 34.36050 7.62000
v 93.28041 34.79222 7.62000
v 94.82285 38.10000 7.62000
v 94.75726 38.84981 7.62000
v 94.75726 37.35019 7.62000
v 94.56245 36.62316 7.62000
v 88.34586 34.36050 7.62000
v 89.02802 34.04241 7.62000
v 89.75504 33.84760 7.62000
v 87.72929 34.79222 7.62000
v 91.25467 33.84760 7.62000
v 91.98170 34.04241 7.62000
v 90.50485 33.78200 7.62000
v 30.91231 33.84760 7.62000
v 31.63935 34.04241 7.62000
v 30.16250 33.78200 7.62000
v 29.41269 33.84760 7.62000
v 32.32150 34.36050 7.62000
v 33.47028 35.32444 7.62000
v 33.90200 35.94100 7.62000
v 32.93806 34.79222 7.62000
v 28.68566 34.04241 7.62000
v 25.91010 37.35019 5.71500
v 26.10491 36.62316 7.62000
v 26.10491 36.62316 5.71500
v 26.42300 35.94100 7.62000
v 25.91010 37.35019 7.62000
v 25.84450 38.10000 5.71500
v 26.85472 35.32444 7.62000
v 28.00350 34.36050 7.62000
v 27.38694 34.79222 7.62000
v 93.28041 41.40778 7.62000
v 92.66386 41.83950 7.62000
v 93.81263 40.87556 5.71500
v 93.81263 40.87556 7.62000
v 34.41490 37.35019 7.62000
v 34.22009 36.62316 7.62000
v 32.07196 37.40501 3.17500
v 51.43863 9.52500 7.62000
v 62.52912 9.52500 13.67577
v 62.03950 9.52500 14.01860
v 69.21137 9.52500 7.62000
v 59.15221 9.52500 14.27121
v 58.61050 9.52500 14.01860
v 47.26535 0.46224 7.62000
v 73.12357 75.73776 7.62000
v 68.40075 75.73776 7.62000
v 57.10279 9.52500 12.22179
v 56.94810 9.52500 11.64444
v 56.89600 9.52500 11.04900
v 58.12088 9.52500 13.67577
v 62.95177 9.52500 13.25312
v 63.54721 9.52500 12.22179
v 63.29461 9.52500 12.76350
v 57.69823 9.52500 13.25312
v 56.94810 9.52500 10.45356
v 57.10279 9.52500 9.87621
v 52.15561 9.52500 6.15138
v 57.35540 9.52500 9.33450
v 57.35540 9.52500 12.76350
v 63.70190 9.52500 11.64444
v 63.70190 9.52500 10.45356
v 63.75400 9.52500 11.04900
v 60.32500 9.52500 14.47800
v 59.72956 9.52500 14.42591
v 60.92044 9.52500 14.42591
v 61.49779 9.52500 14.27121
v 24.60337 58.97880 7.62000
v 21.20612 58.97880 7.62000
v 21.20612 57.90866 7.62000
v 24.60337 57.90866 7.62000
v 21.20612 43.43400 7.62000
v 24.60337 61.58364 7.62000
v 114.53511 57.90866 7.62000
v 21.20612 61.58364 7.62000
v 24.60337 43.43400 7.62000
v 24.60337 30.61600 7.62000
v 21.20612 30.61600 7.62000
v 99.08823 58.97880 7.62000
v 99.08823 61.58364 7.62000
v 95.69965 58.97880 7.62000
v 24.60337 32.76600 7.62000
v 21.20612 32.76600 7.62000
v 95.69965 57.90866 7.62000
v 94.49773 57.90866 7.62000
v 94.49773 58.97880 7.62000
v 99.08823 57.90866 7.62000
v 95.69965 32.76600 7.62000
v 99.08823 43.43400 7.62000
v 95.69965 43.43400 7.62000
v 94.56245 39.57684 7.62000
v 73.12357 57.90866 7.62000
v 68.40075 58.97880 7.62000
v 114.53511 58.97880 7.62000
v 68.40075 57.90866 7.62000
v 73.12357 58.97880 7.62000
v 21.20612 0.46224 7.62000
v 101.60000 58.54700 7.62000
v 101.60000 58.97880 7.62000
v 101.60000 61.58364 7.62000
v 114.53511 0.46224 7.62000
v 95.69965 0.46224 7.62000
v 94.49773 0.46224 7.62000
v 113.03000 58.54700 7.62000
v 94.49773 61.58364 7.62000
v 73.12357 61.58364 7.62000
v 69.57005 61.58364 7.62000
v 95.69965 61.58364 7.62000
v 113.03000 58.97880 7.62000
v 114.53511 61.58364 7.62000
v 88.48637 37.74715 3.17500
v 88.48637 38.45285 3.17500
v 88.72774 37.08400 3.17500
v 88.57805 37.40501 3.17500
v 88.72774 39.11600 3.17500
v 88.93090 39.40614 3.17500
v 89.18136 39.65660 3.17500
v 88.57805 38.79499 3.17500
v 90.48750 36.06800 3.17500
v 90.48750 40.13200 3.17500
v 91.18249 36.19054 3.17500
v 90.84035 36.09887 3.17500
v 90.84035 40.10113 3.17500
v 89.18136 36.54340 3.17500
v 88.93090 36.79386 3.17500
v 89.47150 39.85976 3.17500
v 89.47150 36.34024 3.17500
v 31.92227 39.11600 3.17500
v 32.07196 38.79499 3.17500
v 31.71910 39.40614 3.17500
v 31.46865 39.65660 3.17500
v 32.16363 38.45285 3.17500
v 32.16363 37.74715 3.17500
v 31.17850 39.85976 3.17500
v 30.85748 40.00946 3.17500
v 66.36712 9.52500 3.68569
v 62.52912 9.52500 8.42223
v 67.53691 9.52500 4.82695
v 62.95177 9.52500 8.84488
v 68.49439 9.52500 6.15138
v 62.03950 9.52500 8.07940
v 61.49779 9.52500 7.82679
v 63.29461 9.52500 9.33450
v 63.54721 9.52500 9.87621
v 60.92044 9.52500 7.67209
v 54.28288 9.52500 3.68569
v 58.12088 9.52500 8.42223
v 58.61050 9.52500 8.07940
v 57.69823 9.52500 8.84488
v 53.11309 9.52500 4.82695
v 59.15221 9.52500 7.82679
v 60.32500 9.52500 7.62000
v 59.72956 9.52500 7.67209
v 92.39696 37.40501 3.17500
v 92.24726 37.08400 3.17500
v 92.48863 37.74715 3.17500
v 92.04410 36.79386 3.17500
v 91.50350 36.34024 3.17500
v 91.79364 36.54340 3.17500
v 92.48863 38.45285 3.17500
v 91.50350 39.85976 3.17500
v 91.79364 39.65660 3.17500
v 91.18249 40.00946 3.17500
v 92.04410 39.40614 3.17500
v 92.39696 38.79499 3.17500
v 92.24726 39.11600 3.17500
v 57.10279 69.69760 9.87621
v 57.35540 67.20840 9.33450
v 57.35540 69.69760 9.33450
v 57.69823 67.20840 8.84488
v 57.69823 69.69760 8.84488
v 57.10279 67.20840 9.87621
v 56.89600 69.69760 11.04900
v 56.94810 67.20840 10.45356
v 56.94810 69.69760 10.45356
v 58.12088 67.20840 8.42223
v 58.61050 69.69760 8.07940
v 59.15221 67.20840 7.82679
v 59.15221 69.69760 7.82679
v 59.72956 67.20840 7.67209
v 59.72956 69.69760 7.67209
v 58.61050 67.20840 8.07940
v 58.12088 69.69760 8.42223
v 56.89600 67.20840 11.04900
v 58.61050 69.69760 14.01860
v 58.12088 67.20840 13.67577
v 58.12088 69.69760 13.67577
v 57.69823 67.20840 13.25312
v 57.69823 69.69760 13.25312
v 58.61050 67.20840 14.01860
v 59.72956 69.69760 14.42591
v 59.15221 67.20840 14.27121
v 59.15221 69.69760 14.27121
v 57.35540 67.20840 12.76350
v 57.10279 69.69760 12.22179
v 56.94810 67.20840 11.64444
v 56.94810 69.69760 11.64444
v 57.10279 67.20840 12.22179
v 57.35540 69.69760 12.76350
v 64.04844 66.67500 9.69378
v 64.13599 66.68311 10.37702
v 63.96140 66.68311 9.72546
v 63.75654 66.67500 9.06780
v 63.67633 66.68311 9.11411
v 64.22720 66.67500 10.36094
v 64.28740 66.67500 11.04900
v 64.13599 66.68311 11.72098
v 64.19478 66.68311 11.04900
v 63.36038 66.67500 8.50202
v 62.30620 66.67500 7.61746
v 62.81245 66.68311 8.08458
v 62.25989 66.68311 7.69768
v 61.68022 66.67500 7.32556
v 61.64854 66.68311 7.41260
v 62.87199 66.67500 8.01363
v 63.28942 66.68311 8.56156
v 64.22720 66.67500 11.73706
v 62.30620 66.67500 14.48054
v 61.64854 66.68311 14.68540
v 62.25989 66.68311 14.40032
v 62.87199 66.67500 14.08437
v 62.81245 66.68311 14.01342
v 61.68022 66.67500 14.77244
v 60.32500 67.20840 7.62000
v 61.01307 66.67500 14.95120
v 60.99698 66.68311 14.85999
v 63.36038 66.67500 13.59598
v 64.04844 66.67500 12.40422
v 63.67633 66.68311 12.98389
v 63.96140 66.68311 12.37254
v 63.75654 66.67500 13.03020
v 63.28942 66.68311 13.53644
v 67.37160 66.67500 4.64033
v 68.33656 59.94400 5.89724
v 68.33656 66.67500 5.89724
v 69.07977 66.67500 7.29673
v 60.32500 69.69760 7.62000
v 67.37160 59.94400 4.64033
v 66.21162 59.94400 3.56079
v 66.21162 66.67500 3.56079
v 60.92044 67.20840 7.67209
v 61.49779 69.69760 7.82679
v 62.03950 67.20840 8.07940
v 62.03950 69.69760 8.07940
v 62.52912 67.20840 8.42223
v 62.52912 69.69760 8.42223
v 61.49779 67.20840 7.82679
v 60.92044 69.69760 7.67209
v 69.02431 59.93684 7.16999
v 69.02774 59.93619 7.17756
v 69.06006 59.91700 7.25119
v 69.06558 59.91371 7.26378
v 68.98670 59.94400 7.08660
v 51.57022 66.67500 7.29673
v 51.64868 59.94303 7.11872
v 51.66330 59.94400 7.08660
v 52.31345 66.67500 5.89724
v 69.07977 59.90087 7.29673
v 53.27840 66.67500 4.64033
v 54.43838 59.94400 3.56079
v 54.43838 66.67500 3.56079
v 53.27840 59.94400 4.64033
v 52.31345 59.94400 5.89724
v 62.52912 69.69760 13.67577
v 62.52912 67.20840 13.67577
v 62.03950 67.20840 14.01860
v 62.03950 69.69760 14.01860
v 61.49779 67.20840 14.27121
v 62.95177 69.69760 13.25312
v 63.29461 69.69760 12.76350
v 63.29461 67.20840 12.76350
v 62.95177 67.20840 13.25312
v 61.49779 69.69760 14.27121
v 60.32500 69.69760 14.47800
v 60.32500 67.20840 14.47800
v 59.72956 67.20840 14.42591
v 60.92044 69.69760 14.42591
v 60.92044 67.20840 14.42591
v 63.54721 69.69760 12.22179
v 63.29461 69.69760 9.33450
v 63.29461 67.20840 9.33450
v 63.54721 67.20840 9.87621
v 63.54721 69.69760 9.87621
v 63.70190 67.20840 10.45356
v 62.95177 69.69760 8.84488
v 62.95177 67.20840 8.84488
v 63.70190 69.69760 10.45356
v 63.70190 69.69760 11.64444
v 63.70190 67.20840 11.64444
v 63.54721 67.20840 12.22179
v 63.75400 69.69760 11.04900
v 63.75400 67.20840 11.04900
v 58.39011 66.68311 7.69768
v 58.43502 66.70716 7.77545
v 57.83756 66.68311 8.08458
v 57.89528 66.70716 8.15338
v 57.36058 66.68311 8.56156
v 59.03218 66.70716 7.49699
v 59.65302 66.68311 7.23801
v 59.66862 66.70716 7.32646
v 59.00146 66.68311 7.41260
v 57.42938 66.70716 8.61928
v 56.68860 66.68311 9.72546
v 56.77299 66.70716 9.75618
v 56.51402 66.68311 10.37702
v 56.60246 66.70716 10.39262
v 56.45522 66.68311 11.04900
v 57.05145 66.70716 9.15902
v 56.97368 66.68311 9.11411
v 60.32500 66.70716 7.26903
v 63.22062 66.70716 8.61928
v 62.75471 66.70716 8.15338
v 63.59855 66.70716 9.15902
v 63.87701 66.70716 9.75618
v 62.21498 66.70716 7.77545
v 60.99698 66.68311 7.23801
v 60.98138 66.70716 7.32646
v 60.32500 66.68311 7.17922
v 61.61782 66.70716 7.49699
v 60.32500 66.70716 14.82897
v 60.98138 66.70716 14.77154
v 60.96675 66.74646 14.68855
v 61.61782 66.70716 14.60101
v 61.58900 66.74646 14.52182
v 60.32500 66.68311 14.91878
v 59.65302 66.68311 14.85999
v 59.03218 66.70716 14.60101
v 59.66862 66.70716 14.77154
v 62.21498 66.70716 14.32255
v 63.22062 66.70716 13.47872
v 62.70055 66.74646 13.88007
v 63.15607 66.74646 13.42455
v 63.59855 66.70716 12.93898
v 63.52557 66.74646 12.89685
v 62.75471 66.70716 13.94462
v 62.17285 66.74646 14.24957
v 59.00146 66.68311 14.68540
v 56.68860 66.68311 12.37254
v 56.60246 66.70716 11.70538
v 56.77299 66.70716 12.34182
v 56.97368 66.68311 12.98389
v 57.05145 66.70716 12.93898
v 56.51402 66.68311 11.72098
v 56.54503 66.70716 11.04900
v 57.36058 66.68311 13.53644
v 58.39011 66.68311 14.40032
v 57.89528 66.70716 13.94462
v 58.43502 66.70716 14.32255
v 57.83756 66.68311 14.01342
v 57.42938 66.70716 13.47872
v 56.42280 66.67500 10.36094
v 56.36260 66.67500 11.04900
v 56.42280 66.67500 11.73706
v 56.89346 66.67500 9.06780
v 56.60156 66.67500 9.69378
v 56.89346 66.67500 13.03020
v 57.28963 66.67500 13.59598
v 57.77802 66.67500 14.08437
v 56.60156 66.67500 12.40422
v 60.32500 66.67500 7.08660
v 59.63694 66.67500 7.14680
v 58.96978 66.67500 7.32556
v 61.01307 66.67500 7.14680
v 57.77802 66.67500 8.01363
v 57.28963 66.67500 8.50202
v 58.34380 66.67500 7.61746
v 63.87701 66.70716 12.34182
v 64.10497 66.70716 11.04900
v 64.04754 66.70716 10.39262
v 64.04754 66.70716 11.70538
v 58.96978 66.67500 14.77244
v 59.63694 66.67500 14.95120
v 58.34380 66.67500 14.48054
v 60.32500 66.67500 15.01140
v 51.62588 59.93554 7.16976
v 69.12140 66.67500 7.37510
v 69.17543 66.67500 7.44549
v 51.47457 66.67500 7.44549
v 94.24435 40.25900 7.62000
v 94.49773 39.71565 7.62000
v 51.57022 59.90087 7.29673
v 51.59018 59.91599 7.25080
v 51.60842 59.92980 7.20884
v 51.52861 66.67500 7.37510
v 34.92500 21.01850 3.17500
v 20.70100 21.01850 3.17500
v 38.48100 21.01850 3.17500
v 47.26535 61.58364 7.62000
v 13.58900 21.01850 3.17500
v 10.03300 21.01850 3.17500
v 7.11200 21.01850 3.17500
v 17.14500 21.01850 3.17500
v 7.62000 70.48499 7.62000
v 19.05000 70.48499 7.62000
v 7.62000 61.58364 7.62000
v 95.69965 1.81862 7.62000
v 99.08823 1.81862 7.62000
v 99.08823 8.48828 7.62000
v 47.26535 16.95450 7.62000
v 95.69965 12.80160 7.62000
v 21.20612 16.95450 7.62000
v 19.05000 58.97880 7.62000
v 51.43863 16.25600 7.62000
v 68.40075 16.25600 7.62000
v 68.40075 16.95450 7.62000
v 69.21137 16.25600 7.62000
v 69.21137 12.80160 7.62000
v 73.12357 12.80160 7.62000
v 95.37700 21.01850 3.17500
v 98.93300 21.01850 3.17500
v 84.70901 21.01850 3.17500
v 102.48900 21.01850 3.17500
v 106.04500 21.01850 3.17500
v 81.15300 21.01850 3.17500
v 47.26535 1.81862 7.62000
v 21.20612 1.81862 7.62000
v 24.60337 8.48828 7.62000
v 21.20612 8.48828 7.62000
v 21.20612 12.80160 7.62000
v 24.60337 1.81862 7.62000
v 47.26535 8.48828 7.62000
v 68.40075 30.61600 7.62000
v 47.26535 30.61600 7.62000
v 21.20612 24.54450 7.62000
v 24.60337 24.54450 7.62000
v 68.40075 24.54450 7.62000
v 51.43863 12.80160 7.62000
v 73.12357 16.95450 7.62000
v 99.08823 24.54450 7.62000
v 114.53511 24.54450 7.62000
v 94.49773 16.95450 7.62000
v 94.49773 30.61600 7.62000
v 73.12357 30.61600 7.62000
v 47.26535 58.97880 7.62000
v 51.07996 59.41060 7.62000
v 99.08823 30.61600 7.62000
v 114.53511 30.61600 7.62000
v 95.69965 30.61600 7.62000
v 114.53511 16.95450 7.62000
v 101.60000 8.48828 7.62000
v 101.60000 12.80160 7.62000
v 99.08823 12.80160 7.62000
v 101.60000 16.95450 7.62000
v 99.08823 16.95450 7.62000
v 95.69965 24.54450 7.62000
v 94.49773 24.54450 7.62000
v 73.12357 24.54450 7.62000
v 95.69965 16.95450 7.62000
v 114.53511 32.76600 7.62000
v 114.53511 43.43400 7.62000
v 99.08823 32.76600 7.62000
v 94.49773 32.76600 7.62000
v 73.12357 1.81862 7.62000
v 94.49773 1.81862 7.62000
v 94.49773 8.48828 7.62000
v 95.69965 8.48828 7.62000
v 73.12357 8.48828 7.62000
v 69.85000 8.48828 7.62000
v 94.49773 12.80160 7.62000
v 114.53511 8.48828 7.62000
v 114.53511 1.81862 7.62000
v 114.53511 12.80160 7.62000
v 24.60337 16.95450 7.62000
v 47.26535 12.80160 7.62000
v 24.60337 12.80160 7.62000
v 68.40075 1.81862 7.62000
v 19.05000 58.54700 7.62000
v 7.62000 58.54700 7.62000
v 7.62000 58.97880 7.62000
v 19.05000 61.58364 7.62000
v 113.03000 70.48499 7.62000
v 113.03000 61.58364 7.62000
v 101.60000 70.48499 7.62000
v 94.49773 43.43400 7.62000
v 69.57005 59.41060 7.62000
v 68.40075 59.41060 7.62000
v 51.07996 61.58364 7.62000
v 50.80000 8.48828 7.62000
v 113.03000 69.66866 7.91712
v 113.03000 69.51213 8.07366
v 113.03000 69.38515 8.25500
v 113.03000 63.16475 10.22145
v 113.03000 69.85000 7.79015
v 113.03000 59.79770 8.66947
v 113.03000 59.74041 8.45563
v 113.03000 59.64685 8.25500
v 113.03000 59.51988 8.07366
v 113.03000 59.36334 7.91712
v 113.03000 65.86726 10.22145
v 113.03000 58.98137 7.69659
v 113.03000 58.76754 7.63929
v 113.03000 59.18200 7.79015
v 113.03000 70.05064 7.69659
v 113.03000 69.23430 8.66947
v 113.03000 69.29160 8.45563
v 113.03000 66.38874 10.60112
v 113.03000 66.23754 10.44529
v 113.03000 70.26447 7.63929
v 113.03000 69.81947 21.97213
v 113.03000 69.76941 22.03727
v 113.03000 69.70953 22.09349
v 113.03000 62.97001 10.31749
v 113.03000 62.79446 10.44529
v 113.03000 69.85836 21.89977
v 113.03000 67.89613 22.70917
v 113.03000 67.81059 22.68625
v 113.03000 62.64326 10.60112
v 113.03000 67.98434 22.71689
v 113.03000 69.56668 22.17357
v 113.03000 66.06200 10.31749
v 113.03000 68.07256 22.70917
v 113.03000 68.15809 22.68625
v 101.60000 68.15809 22.68625
v 101.60000 59.46532 22.17357
v 101.60000 59.26259 22.03727
v 101.60000 59.32248 22.09349
v 101.60000 69.81947 21.97213
v 101.60000 69.76941 22.03727
v 101.60000 69.70953 22.09349
v 101.60000 61.13587 22.70917
v 101.60000 61.22140 22.68625
v 101.60000 69.85836 21.89977
v 101.60000 69.56668 22.17357
v 101.60000 69.64135 22.13932
v 101.60000 59.17364 21.89977
v 101.60000 68.07256 22.70917
v 101.60000 67.89613 22.70917
v 101.60000 67.98434 22.71689
v 101.60000 67.81059 22.68625
v 101.60000 59.21254 21.97213
v 101.60000 65.86726 10.22145
v 101.60000 66.23754 10.44529
v 101.60000 66.06200 10.31749
v 101.60000 58.76754 7.63929
v 101.60000 59.18200 7.79015
v 101.60000 59.36334 7.91712
v 101.60000 58.98137 7.69659
v 101.60000 62.64326 10.60112
v 101.60000 62.79446 10.44529
v 101.60000 62.97001 10.31749
v 101.60000 66.38874 10.60112
v 101.60000 63.16475 10.22145
v 101.60000 69.66866 7.91712
v 101.60000 69.85000 7.79015
v 101.60000 70.05064 7.69659
v 101.60000 69.38515 8.25500
v 101.60000 69.51213 8.07366
v 113.03000 69.64135 22.13932
v 101.60000 70.26447 7.63929
v 101.60000 59.64685 8.25500
v 101.60000 59.74041 8.45563
v 101.60000 59.79770 8.66947
v 101.60000 59.51988 8.07366
v 101.60000 69.23430 8.66947
v 101.60000 69.29160 8.45563
v 113.03000 59.17364 21.89977
v 113.03000 59.21254 21.97213
v 113.03000 59.32248 22.09349
v 113.03000 59.39066 22.13932
v 113.03000 59.26259 22.03727
v 113.03000 61.04766 22.71689
v 113.03000 60.95944 22.70917
v 113.03000 61.13587 22.70917
v 113.03000 60.87391 22.68625
v 113.03000 61.22140 22.68625
v 113.03000 59.46532 22.17357
v 52.15561 16.25600 6.15138
v 53.11309 16.25600 4.82695
v 67.53691 16.25600 4.82695
v 66.36712 16.25600 3.68569
v 68.49439 16.25600 6.15138
v 54.28288 16.25600 3.68569
v 101.60000 60.87391 22.68625
v 101.60000 60.95944 22.70917
v 101.60000 61.04766 22.71689
v 101.60000 59.39066 22.13932
v 7.62000 69.29160 8.45563
v 19.05000 69.29160 8.45563
v 19.05000 69.38515 8.25500
v 7.62000 69.38515 8.25500
v 19.05000 69.51213 8.07366
v 7.62000 69.23430 8.66947
v 19.05000 69.23430 8.66947
v 7.62000 69.51213 8.07366
v 7.62000 69.85000 7.79015
v 19.05000 69.85000 7.79015
v 19.05000 70.05064 7.69659
v 7.62000 70.05064 7.69659
v 19.05000 70.26447 7.63929
v 7.62000 69.66866 7.91712
v 19.05000 69.66866 7.91712
v 7.62000 59.79770 8.66947
v 7.62000 59.18200 7.79015
v 19.05000 59.18200 7.79015
v 19.05000 59.36334 7.91712
v 7.62000 59.36334 7.91712
v 19.05000 59.51988 8.07366
v 7.62000 58.98137 7.69659
v 7.62000 58.76754 7.63929
v 19.05000 58.76754 7.63929
v 19.05000 58.98137 7.69659
v 7.62000 59.51988 8.07366
v 7.62000 59.74041 8.45563
v 19.05000 59.74041 8.45563
v 19.05000 59.79770 8.66947
v 7.62000 59.64685 8.25500
v 19.05000 59.64685 8.25500
v 19.05000 67.98434 22.71689
v 7.62000 67.89613 22.70917
v 19.05000 67.89613 22.70917
v 7.62000 67.81059 22.68625
v 19.05000 67.81059 22.68625
v 7.62000 67.98434 22.71689
v 19.05000 68.15809 22.68625
v 7.62000 68.07256 22.70917
v 19.05000 68.07256 22.70917
v 19.05000 61.22140 22.68625
v 7.62000 61.13587 22.70917
v 19.05000 61.13587 22.70917
v 7.62000 61.04766 22.71689
v 19.05000 61.04766 22.71689
v 7.62000 61.22140 22.68625
v 7.62000 68.15809 22.68625
v 7.62000 69.85836 21.89977
v 19.05000 69.85836 21.89977
v 7.62000 69.81947 21.97213
v 19.05000 69.81947 21.97213
v 7.62000 70.26447 7.63929
v 7.62000 69.76941 22.03727
v 19.05000 69.70953 22.09349
v 7.62000 69.64135 22.13932
v 19.05000 69.64135 22.13932
v 7.62000 69.56668 22.17357
v 19.05000 69.56668 22.17357
v 7.62000 69.70953 22.09349
v 19.05000 69.76941 22.03727
v 7.62000 59.46532 22.17357
v 7.62000 59.17364 21.89977
v 7.62000 59.26259 22.03727
v 7.62000 59.21254 21.97213
v 7.62000 59.39066 22.13932
v 7.62000 59.32248 22.09349
v 7.62000 66.38874 10.60112
v 19.05000 66.38874 10.60112
v 7.62000 66.23754 10.44529
v 19.05000 66.23754 10.44529
v 7.62000 62.97001 10.31749
v 19.05000 63.16475 10.22145
v 7.62000 63.16475 10.22145
v 7.62000 66.06200 10.31749
v 19.05000 65.86726 10.22145
v 7.62000 65.86726 10.22145
v 19.05000 66.06200 10.31749
v 19.05000 62.97001 10.31749
v 7.62000 62.64326 10.60112
v 19.05000 62.79446 10.44529
v 7.62000 62.79446 10.44529
v 19.05000 62.64326 10.60112
v 19.05000 59.17364 21.89977
v 19.05000 59.46532 22.17357
v 19.05000 59.39066 22.13932
v 19.05000 60.95944 22.70917
v 7.62000 60.87391 22.68625
v 19.05000 60.87391 22.68625
v 7.62000 60.95944 22.70917
v 19.05000 59.32248 22.09349
v 19.05000 59.21254 21.97213
v 19.05000 59.26259 22.03727
v 56.87772 66.94170 11.65685
v 56.91642 67.02597 11.65003
v 57.03564 66.94170 12.24623
v 57.07256 67.02597 12.23279
v 57.29351 66.94170 12.79923
v 56.86383 67.02597 11.04900
v 56.87772 66.94170 10.44115
v 56.91642 67.02597 10.44797
v 56.82454 66.94170 11.04900
v 57.32754 67.02597 12.77958
v 58.07495 66.94170 13.73051
v 58.10020 67.02597 13.70041
v 58.57477 66.94170 14.08049
v 58.59442 67.02597 14.04646
v 59.12777 66.94170 14.33836
v 57.67359 67.02597 13.27380
v 57.64349 66.94170 13.29905
v 57.07256 67.02597 9.86521
v 59.12777 66.94170 7.75964
v 59.14121 67.02597 7.79657
v 58.57477 66.94170 8.01751
v 58.59442 67.02597 8.05154
v 58.07495 66.94170 8.36749
v 59.72397 67.02597 7.64041
v 60.32500 66.94170 7.54854
v 60.32500 67.02597 7.58783
v 59.71715 66.94170 7.60172
v 58.10020 67.02597 8.39759
v 57.29351 66.94170 9.29877
v 57.32754 67.02597 9.31842
v 57.03564 66.94170 9.85177
v 57.67359 67.02597 8.82420
v 57.64349 66.94170 8.79895
v 63.73359 67.02597 11.65003
v 63.55482 67.11578 12.22456
v 63.70988 67.11578 11.64585
v 63.78617 67.02597 11.04900
v 63.76211 67.11578 11.04900
v 63.57743 67.02597 12.23279
v 63.32246 67.02597 12.77958
v 62.95797 67.11578 13.25833
v 63.30162 67.11578 12.76755
v 63.73359 67.02597 10.44797
v 63.32246 67.02597 9.31842
v 63.55482 67.11578 9.87344
v 63.30162 67.11578 9.33045
v 62.97641 67.02597 8.82420
v 62.95797 67.11578 8.83967
v 63.57743 67.02597 9.86521
v 63.70988 67.11578 10.45215
v 62.97641 67.02597 13.27380
v 60.32500 66.94170 14.54946
v 59.72397 67.02597 14.45759
v 60.32500 67.02597 14.51017
v 60.93285 66.94170 14.49628
v 60.92603 67.02597 14.45759
v 59.71715 66.94170 14.49628
v 59.14121 67.02597 14.30143
v 60.92185 67.11578 14.43389
v 61.50879 67.02597 14.30143
v 62.54980 67.02597 13.70041
v 62.04355 67.11578 14.02562
v 62.53432 67.11578 13.68197
v 62.05558 67.02597 14.04646
v 61.50056 67.11578 14.27882
v 58.54810 66.86553 14.12667
v 59.10953 66.86553 14.38847
v 59.70789 66.86553 14.54880
v 57.60264 66.86553 13.33333
v 58.04067 66.86553 13.77136
v 61.52223 66.94170 14.33836
v 62.07523 66.94170 14.08049
v 60.94211 66.86553 14.54880
v 60.32500 66.86553 14.60279
v 56.98553 66.86553 9.83353
v 56.82520 66.86553 10.43189
v 56.77121 66.86553 11.04900
v 57.60264 66.86553 8.76467
v 57.24733 66.86553 9.27210
v 56.98553 66.86553 12.26447
v 57.24733 66.86553 12.82590
v 56.82520 66.86553 11.66611
v 63.00651 66.94170 8.79895
v 62.57506 66.94170 8.36749
v 62.54980 67.02597 8.39759
v 63.35649 66.94170 9.29877
v 63.61436 66.94170 9.85177
v 62.07523 66.94170 8.01751
v 60.93285 66.94170 7.60172
v 61.50879 67.02597 7.79657
v 60.92603 67.02597 7.64041
v 61.52223 66.94170 7.75964
v 62.05558 67.02597 8.05154
v 63.77228 66.94170 10.44115
v 63.00651 66.94170 13.29905
v 63.35649 66.94170 12.79923
v 62.57506 66.94170 13.73051
v 63.61436 66.94170 12.24623
v 63.82547 66.94170 11.04900
v 63.77228 66.94170 11.65685
v 60.32500 67.11578 7.61190
v 59.72815 67.11578 7.66411
v 59.14944 67.11578 7.81918
v 61.50056 67.11578 7.81918
v 60.92185 67.11578 7.66411
v 58.11567 67.11578 8.41603
v 57.69202 67.11578 8.83967
v 57.34838 67.11578 9.33045
v 58.60645 67.11578 8.07238
v 62.53432 67.11578 8.41603
v 62.04355 67.11578 8.07238
v 59.14944 67.11578 14.27882
v 59.72815 67.11578 14.43389
v 58.60645 67.11578 14.02562
v 58.11567 67.11578 13.68197
v 60.32500 67.11578 14.48610
v 51.55391 59.88456 7.32808
v 51.53340 59.86404 7.36750
v 51.52806 59.85870 7.37470
v 57.69202 67.11578 13.25833
v 56.94012 67.11578 10.45215
v 56.88790 67.11578 11.04900
v 57.09518 67.11578 9.87344
v 56.94012 67.11578 11.64585
v 57.34838 67.11578 12.76755
v 57.09518 67.11578 12.22456
v 63.52557 66.74646 9.20115
v 63.72625 66.79979 9.81105
v 63.45961 66.79979 9.23923
v 63.15607 66.74646 8.67345
v 63.09773 66.79979 8.72241
v 63.79782 66.74646 9.78500
v 63.96455 66.74646 10.40725
v 63.94454 66.79979 11.04900
v 63.88955 66.79979 10.42047
v 62.70055 66.74646 8.21793
v 61.58900 66.74646 7.57618
v 62.13477 66.79979 7.91439
v 61.56295 66.79979 7.64775
v 60.96675 66.74646 7.40945
v 60.95353 66.79979 7.48445
v 62.17285 66.74646 7.84843
v 62.65160 66.79979 8.27627
v 64.02070 66.74646 11.04900
v 62.13477 66.79979 14.18361
v 62.65160 66.79979 13.82173
v 63.09773 66.79979 13.37559
v 60.95353 66.79979 14.61355
v 61.56295 66.79979 14.45025
v 63.96455 66.74646 11.69075
v 63.72625 66.79979 12.28695
v 63.88955 66.79979 11.67753
v 63.79782 66.74646 12.31300
v 63.45961 66.79979 12.85877
v 56.62930 66.74646 11.04900
v 56.70546 66.79979 11.04900
v 56.68545 66.74646 11.69075
v 56.76045 66.79979 11.67753
v 56.85218 66.74646 12.31300
v 56.76045 66.79979 10.42047
v 56.85218 66.74646 9.78500
v 56.92375 66.79979 9.81105
v 56.68545 66.74646 10.40725
v 56.92375 66.79979 12.28695
v 57.49393 66.74646 13.42455
v 57.55227 66.79979 13.37559
v 57.94945 66.74646 13.88007
v 57.99841 66.79979 13.82173
v 58.47715 66.74646 14.24957
v 57.19039 66.79979 12.85877
v 57.12443 66.74646 12.89685
v 57.19039 66.79979 9.23923
v 59.68325 66.74646 7.40945
v 59.69647 66.79979 7.48445
v 59.06100 66.74646 7.57618
v 59.08704 66.79979 7.64775
v 58.47715 66.74646 7.84843
v 60.32500 66.79979 7.42946
v 60.32500 66.74646 7.35330
v 58.51523 66.79979 7.91439
v 57.49393 66.74646 8.67345
v 57.55227 66.79979 8.72241
v 57.12443 66.74646 9.20115
v 57.99841 66.79979 8.27627
v 57.94945 66.74646 8.21793
v 59.06100 66.74646 14.52182
v 60.32500 66.74646 14.74470
v 59.68325 66.74646 14.68855
v 61.54047 66.86553 14.38847
v 62.10190 66.86553 14.12667
v 60.32500 66.79979 14.66854
v 62.60934 66.86553 13.77136
v 63.40268 66.86553 12.82590
v 63.66447 66.86553 12.26447
v 63.04736 66.86553 13.33333
v 59.69647 66.79979 14.61355
v 59.08704 66.79979 14.45025
v 58.51523 66.79979 14.18361
v 60.94211 66.86553 7.54920
v 60.32500 66.86553 7.49521
v 59.70789 66.86553 7.54920
v 62.10190 66.86553 7.97133
v 61.54047 66.86553 7.70953
v 58.54810 66.86553 7.97133
v 58.04067 66.86553 8.32664
v 59.10953 66.86553 7.70953
v 63.87879 66.86553 11.04900
v 63.82480 66.86553 10.43189
v 63.66447 66.86553 9.83353
v 63.82480 66.86553 11.66611
v 63.04736 66.86553 8.76467
v 62.60934 66.86553 8.32664
v 63.40268 66.86553 9.27210
v 51.51001 59.84066 7.39903
v 60.91486 69.88004 7.70377
v 60.91903 69.79023 7.68007
v 61.48679 69.88004 7.85702
v 61.49502 69.79023 7.83441
v 62.02341 69.88004 8.10726
v 60.90803 69.96430 7.74247
v 60.32500 69.88004 7.65217
v 59.74197 69.96430 7.74247
v 59.73515 69.88004 7.70377
v 60.32500 69.96430 7.69146
v 62.03545 69.79023 8.08642
v 62.92713 69.88004 8.86556
v 62.94556 69.79023 8.85009
v 63.26675 69.88004 9.35058
v 63.28759 69.79023 9.33855
v 63.51698 69.88004 9.88721
v 62.52391 69.79023 8.42844
v 62.50844 69.88004 8.44688
v 59.16322 69.88004 7.85702
v 57.16995 69.96430 9.90065
v 57.13302 69.88004 9.88721
v 57.41729 69.96430 9.37023
v 57.38326 69.88004 9.35058
v 57.75298 69.96430 8.89082
v 56.97977 69.88004 10.45915
v 56.96746 69.96430 11.04900
v 56.92817 69.88004 11.04900
v 57.01847 69.96430 10.46597
v 57.72288 69.88004 8.86556
v 58.64623 69.96430 8.14129
v 58.62658 69.88004 8.10726
v 59.17665 69.96430 7.89395
v 58.14156 69.88004 8.44688
v 58.16682 69.96430 8.47698
v 60.91486 69.88004 14.39423
v 61.49502 69.79023 14.26359
v 60.91903 69.79023 14.41792
v 60.32500 69.88004 14.44583
v 60.32500 69.79023 14.46990
v 61.48679 69.88004 14.24098
v 62.02341 69.88004 13.99074
v 62.52391 69.79023 13.66956
v 62.03545 69.79023 14.01158
v 59.73515 69.88004 14.39423
v 58.62658 69.88004 13.99074
v 59.15498 69.79023 14.26359
v 58.61455 69.79023 14.01158
v 58.14156 69.88004 13.65112
v 58.12609 69.79023 13.66956
v 59.16322 69.88004 14.24098
v 59.73097 69.79023 14.41792
v 62.50844 69.88004 13.65112
v 63.72184 69.88004 11.04900
v 63.69392 69.79023 10.45497
v 63.74590 69.79023 11.04900
v 63.67023 69.88004 11.63885
v 63.69392 69.79023 11.64303
v 63.67023 69.88004 10.45915
v 63.53959 69.79023 9.87898
v 63.51698 69.88004 12.21079
v 62.92713 69.88004 13.23244
v 63.28759 69.79023 12.75945
v 62.94556 69.79023 13.24791
v 63.26675 69.88004 12.74742
v 63.53959 69.79023 12.21902
v 63.23271 69.96430 9.37023
v 63.48006 69.96430 9.90065
v 63.63153 69.96430 10.46597
v 62.48318 69.96430 8.47698
v 62.89703 69.96430 8.89082
v 63.63153 69.96430 11.63203
v 63.48006 69.96430 12.19735
v 63.23271 69.96430 12.72777
v 63.68254 69.96430 11.04900
v 59.19490 70.04047 7.94406
v 59.75123 70.04047 7.79499
v 60.32500 70.04047 7.74479
v 58.20110 70.04047 8.51783
v 58.67290 70.04047 8.18747
v 61.47335 69.96430 7.89395
v 62.00377 69.96430 8.14129
v 60.89877 70.04047 7.79499
v 58.16682 69.96430 13.62102
v 57.75298 69.96430 13.20718
v 57.72288 69.88004 13.23244
v 58.64623 69.96430 13.95671
v 59.17665 69.96430 14.20405
v 57.41729 69.96430 12.72777
v 57.01847 69.96430 11.63203
v 57.13302 69.88004 12.21079
v 56.97977 69.88004 11.63885
v 57.16995 69.96430 12.19735
v 57.38326 69.88004 12.74742
v 59.74197 69.96430 14.35553
v 62.48318 69.96430 13.62102
v 62.00377 69.96430 13.95671
v 62.89703 69.96430 13.20718
v 61.47335 69.96430 14.20405
v 60.32500 69.96430 14.40654
v 60.90803 69.96430 14.35553
v 56.90410 69.79023 11.04900
v 56.95607 69.79023 10.45497
v 57.11041 69.79023 9.87898
v 57.11041 69.79023 12.21902
v 56.95607 69.79023 11.64303
v 57.70444 69.79023 8.85009
v 58.12609 69.79023 8.42844
v 58.61455 69.79023 8.08642
v 57.36242 69.79023 9.33855
v 57.70444 69.79023 13.24791
v 57.36242 69.79023 12.75945
v 59.73097 69.79023 7.68007
v 60.32500 69.79023 7.62810
v 59.15498 69.79023 7.83441
v 69.11660 59.86404 7.36750
v 69.09608 59.88456 7.32808
v 51.16814 59.49878 7.61048
v 69.48688 59.49377 7.61102
v 51.19377 59.52441 7.60772
v 51.16312 59.49377 7.61102
v 69.12194 59.85870 7.37470
v 69.17595 59.80469 7.44495
v 69.19433 59.78631 7.46223
v 69.16964 59.81100 7.43901
v 69.13998 59.84066 7.39903
v 51.24364 59.57429 7.59054
v 69.48186 59.49878 7.61048
v 69.45624 59.52441 7.60772
v 69.31562 59.66502 7.55291
v 51.33438 59.66502 7.55291
v 69.32848 59.65216 7.56087
v 51.32152 59.65216 7.56087
v 69.33889 59.64175 7.56731
v 51.39266 59.72330 7.51683
v 51.40942 59.74006 7.50573
v 51.40717 59.73782 7.50784
v 69.25734 59.72330 7.51683
v 51.31111 59.64175 7.56731
v 69.40635 59.57429 7.59054
v 51.25319 59.58384 7.58726
v 69.39681 59.58384 7.58726
v 60.85949 70.19884 8.01773
v 59.27225 70.19884 8.15660
v 69.24283 59.73782 7.50784
v 69.24059 59.74006 7.50573
v 51.45567 59.78631 7.46223
v 51.47405 59.80469 7.44495
v 51.48037 59.81100 7.43901
v 61.97710 70.04047 8.18747
v 62.44890 70.04047 8.51783
v 61.45510 70.04047 7.94406
v 60.88736 70.10621 7.85974
v 57.79383 70.04047 8.92510
v 60.32500 70.15954 7.88670
v 60.87413 70.15954 7.93474
v 61.40657 70.15954 8.07741
v 61.43262 70.10621 8.00584
v 60.32500 70.19884 7.97097
v 59.79051 70.19884 8.01773
v 59.24343 70.15954 8.07741
v 59.77588 70.15954 7.93474
v 61.90615 70.15954 8.31037
v 62.40664 70.10621 8.56819
v 58.24335 70.10621 8.56819
v 61.94423 70.10621 8.24441
v 58.74385 70.15954 8.31037
v 58.70577 70.10621 8.24441
v 59.76265 70.10621 7.85974
v 60.32500 70.10621 7.81054
v 59.21738 70.10621 8.00584
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 1.000
vn 0.374 -0.927 0.000
vn 0.103 0.995 0.000
vn 0.423 0.906 -0.007
vn 0.016 1.000 -0.023
vn -0.087 -0.996 0.000
vn -0.259 -0.966 0.000
vn -0.259 -0.966 0.000
vn -0.423 -0.906 0.000
vn -0.423 -0.906 0.000
vn -0.574 -0.819 0.000
vn -0.574 -0.819 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.819 -0.574 0.000
vn -0.819 -0.574 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.966 -0.259 0.000
vn -0.966 -0.259 0.000
vn -0.996 -0.087 0.000
vn -0.996 -0.087 0.000
vn -0.996 0.087 0.000
vn -0.996 0.087 0.000
vn -0.966 0.259 0.000
vn -0.966 0.259 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.819 0.574 0.000
vn -0.819 0.574 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.574 0.819 0.000
vn -0.574 0.819 0.000
vn -0.423 0.906 0.000
vn -0.423 0.906 0.000
vn -0.259 0.966 0.000
vn -0.259 0.966 0.000
vn -0.087 0.996 0.000
vn -0.087 0.996 0.000
vn 0.087 0.996 0.000
vn 0.087 -0.996 -0.004
vn 0.016 -1.000 0.000
vn -0.087 -0.996 0.000
vn 0.454 -0.891 -0.011
vn 0.454 0.891 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.996 -0.087 0.000
vn 0.996 -0.087 0.000
vn 0.966 -0.259 0.000
vn 0.966 -0.259 0.000
vn 0.906 -0.423 0.000
vn 0.906 -0.423 0.000
vn 0.819 -0.574 0.000
vn 0.819 -0.574 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.574 -0.819 0.000
vn 0.574 -0.819 0.000
vn 0.423 -0.906 0.000
vn 0.374 -0.927 0.000
vn 0.259 -0.966 0.000
vn 0.259 -0.966 0.000
vn 0.103 -0.995 0.000
vn 0.103 -0.995 0.000
vn 0.103 0.995 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.374 0.927 0.000
vn 0.374 0.927 0.000
vn 0.406 -0.914 0.000
vn 0.134 0.991 0.000
vn 0.423 0.906 -0.005
vn 0.047 0.999 -0.013
vn -0.087 -0.996 0.000
vn -0.259 -0.966 0.000
vn -0.259 -0.966 0.000
vn -0.423 -0.906 0.000
vn -0.423 -0.906 0.000
vn -0.574 -0.819 0.000
vn -0.574 -0.819 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.819 -0.574 0.000
vn -0.819 -0.574 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.966 -0.259 0.000
vn -0.966 -0.259 0.000
vn -0.996 -0.087 0.000
vn -0.996 -0.087 0.000
vn -0.996 0.087 0.000
vn -0.996 0.087 0.000
vn -0.966 0.259 0.000
vn -0.966 0.259 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.819 0.574 0.000
vn -0.819 0.574 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.574 0.819 0.000
vn -0.574 0.819 0.000
vn -0.423 0.906 0.000
vn -0.423 0.906 0.000
vn -0.259 0.966 0.000
vn -0.259 0.966 0.000
vn -0.087 0.996 0.000
vn -0.087 0.996 0.000
vn 0.087 0.996 0.000
vn 0.087 -0.996 -0.007
vn 0.047 -0.999 0.000
vn -0.087 -0.996 0.000
vn 0.484 -0.875 -0.023
vn 0.484 0.875 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.996 -0.087 0.000
vn 0.996 -0.087 0.000
vn 0.966 -0.259 0.000
vn 0.966 -0.259 0.000
vn 0.906 -0.423 0.000
vn 0.906 -0.423 0.000
vn 0.819 -0.574 0.000
vn 0.819 -0.574 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.574 -0.819 0.000
vn 0.574 -0.819 0.000
vn 0.423 -0.906 0.000
vn 0.406 -0.914 0.000
vn 0.259 -0.966 0.000
vn 0.259 -0.966 0.000
vn 0.134 -0.991 0.000
vn 0.134 -0.991 0.000
vn 0.134 0.991 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.406 0.914 0.000
vn 0.406 0.914 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -0.996 0.087 0.000
vn -0.996 -0.087 0.000
vn -0.996 -0.087 0.000
vn -0.966 -0.259 0.000
vn -0.966 -0.259 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.819 -0.574 0.000
vn -0.819 -0.574 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.574 -0.819 0.000
vn -0.574 -0.819 0.000
vn -0.423 -0.906 0.000
vn -0.423 -0.906 0.000
vn -0.259 -0.966 0.000
vn -0.259 -0.966 0.000
vn -0.087 -0.996 0.000
vn -0.087 -0.996 0.000
vn 0.087 -0.996 0.000
vn 0.087 -0.996 0.000
vn 0.259 -0.966 0.000
vn 0.259 -0.966 0.000
vn 0.423 -0.906 0.000
vn 0.423 -0.906 0.000
vn 0.574 -0.819 0.000
vn 0.574 -0.819 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.819 -0.574 0.000
vn 0.819 -0.574 0.000
vn 0.906 -0.423 0.000
vn 0.906 -0.423 0.000
vn 0.966 -0.259 0.000
vn 0.966 -0.259 0.000
vn 0.996 -0.087 0.000
vn 0.996 -0.087 0.000
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.423 0.906 0.000
vn 0.423 0.906 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.087 0.996 0.000
vn 0.087 0.996 0.000
vn -0.087 0.996 0.000
vn -0.087 0.996 0.000
vn -0.259 0.966 0.000
vn -0.259 0.966 0.000
vn -0.423 0.906 0.000
vn -0.423 0.906 0.000
vn -0.574 0.819 0.000
vn -0.574 0.819 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.819 0.574 0.000
vn -0.819 0.574 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.966 0.259 0.000
vn -0.966 0.259 0.000
vn -0.996 0.087 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.819 -0.574 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.574 -0.819 0.000
vn -0.574 -0.819 0.000
vn -0.423 -0.906 0.000
vn -0.423 -0.906 0.000
vn -0.259 -0.966 0.000
vn -0.259 -0.966 0.000
vn -0.087 -0.996 0.000
vn -0.087 -0.996 0.000
vn 0.087 -0.996 0.000
vn 0.087 -0.996 0.000
vn 0.259 -0.966 0.000
vn 0.259 -0.966 0.000
vn 0.423 -0.906 0.000
vn 0.423 -0.906 0.000
vn 0.574 -0.819 0.000
vn 0.574 -0.819 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.819 -0.574 0.000
vn 0.819 -0.574 0.000
vn 0.906 -0.423 0.000
vn 0.906 -0.423 0.000
vn 0.966 -0.259 0.000
vn 0.966 -0.259 0.000
vn 0.996 -0.087 0.000
vn 0.996 -0.087 0.000
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.423 0.906 0.000
vn 0.423 0.906 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.087 0.996 0.000
vn 0.087 0.996 0.000
vn -0.087 0.996 0.000
vn -0.087 0.996 0.000
vn -0.259 0.966 0.000
vn -0.259 0.966 0.000
vn -0.423 0.906 0.000
vn -0.423 0.906 0.000
vn -0.574 0.819 0.000
vn -0.574 0.819 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.819 0.574 0.000
vn -0.819 0.574 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.966 0.259 0.000
vn -0.966 0.259 0.000
vn -0.996 0.087 0.000
vn -0.996 0.087 0.000
vn -0.996 -0.087 0.000
vn -0.996 -0.087 0.000
vn -0.966 -0.259 0.000
vn -0.966 -0.259 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.819 -0.574 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.555 -0.149 0.818
vn -0.555 -0.149 0.818
vn -0.521 -0.243 0.818
vn -0.521 -0.243 0.818
vn -0.471 -0.330 0.818
vn -0.471 -0.330 0.818
vn -0.407 -0.407 0.818
vn -0.407 -0.407 0.818
vn -0.330 -0.471 0.818
vn -0.330 -0.471 0.818
vn -0.243 -0.521 0.818
vn -0.243 -0.521 0.818
vn -0.149 -0.555 0.818
vn -0.149 -0.555 0.818
vn -0.050 -0.573 0.818
vn -0.050 -0.573 0.818
vn 0.050 -0.573 0.818
vn 0.050 -0.573 0.818
vn 0.149 -0.555 0.818
vn 0.149 -0.555 0.818
vn 0.243 -0.521 0.818
vn 0.243 -0.521 0.818
vn 0.330 -0.471 0.818
vn 0.330 -0.471 0.818
vn 0.407 -0.407 0.818
vn 0.407 -0.407 0.818
vn 0.471 -0.330 0.818
vn 0.471 -0.330 0.818
vn 0.521 -0.243 0.818
vn 0.521 -0.243 0.818
vn 0.555 -0.149 0.818
vn 0.555 -0.149 0.818
vn 0.573 -0.050 0.818
vn 0.573 -0.050 0.818
vn 0.573 0.050 0.818
vn 0.573 0.050 0.818
vn 0.555 0.149 0.818
vn 0.555 0.149 0.818
vn 0.521 0.243 0.818
vn 0.521 0.243 0.818
vn 0.471 0.330 0.818
vn 0.471 0.330 0.818
vn 0.407 0.407 0.818
vn 0.407 0.407 0.818
vn 0.330 0.471 0.818
vn 0.330 0.471 0.818
vn 0.243 0.521 0.818
vn 0.243 0.521 0.818
vn 0.149 0.555 0.818
vn 0.149 0.555 0.818
vn 0.050 0.573 0.818
vn 0.050 0.573 0.818
vn -0.050 0.573 0.818
vn -0.050 0.573 0.818
vn -0.149 0.555 0.818
vn -0.149 0.555 0.818
vn -0.243 0.521 0.818
vn -0.243 0.521 0.818
vn -0.330 0.471 0.818
vn -0.330 0.471 0.818
vn -0.407 0.407 0.818
vn -0.407 0.407 0.818
vn -0.471 0.330 0.818
vn -0.471 0.330 0.818
vn -0.521 0.243 0.818
vn -0.521 0.243 0.818
vn -0.555 0.149 0.818
vn -0.555 0.149 0.818
vn -0.573 0.050 0.818
vn -0.573 0.050 0.818
vn -0.573 -0.050 0.818
vn -0.573 -0.050 0.818
vn -0.555 -0.149 0.818
vn -0.555 -0.149 0.818
vn -0.521 -0.243 0.818
vn -0.521 -0.243 0.818
vn -0.471 -0.330 0.818
vn -0.471 -0.330 0.818
vn -0.407 -0.407 0.818
vn -0.407 -0.407 0.818
vn -0.330 -0.471 0.818
vn -0.330 -0.471 0.818
vn -0.243 -0.521 0.818
vn -0.243 -0.521 0.818
vn -0.149 -0.555 0.818
vn -0.149 -0.555 0.818
vn -0.050 -0.573 0.818
vn -0.050 -0.573 0.818
vn 0.050 -0.573 0.818
vn 0.050 -0.573 0.818
vn 0.149 -0.555 0.818
vn 0.149 -0.555 0.818
vn 0.243 -0.521 0.818
vn 0.243 -0.521 0.818
vn 0.330 -0.471 0.818
vn 0.330 -0.471 0.818
vn 0.407 -0.407 0.818
vn 0.407 -0.407 0.818
vn 0.471 -0.330 0.818
vn 0.471 -0.330 0.818
vn 0.521 -0.243 0.818
vn 0.521 -0.243 0.818
vn 0.555 -0.149 0.818
vn 0.555 -0.149 0.818
vn 0.573 -0.050 0.818
vn 0.573 -0.050 0.818
vn 0.573 0.050 0.818
vn 0.573 0.050 0.818
vn 0.555 0.149 0.818
vn 0.555 0.149 0.818
vn 0.521 0.243 0.818
vn 0.521 0.243 0.818
vn 0.471 0.330 0.818
vn 0.471 0.330 0.818
vn 0.407 0.407 0.818
vn 0.407 0.407 0.818
vn 0.330 0.471 0.818
vn 0.330 0.471 0.818
vn 0.243 0.521 0.818
vn 0.243 0.521 0.818
vn 0.149 0.555 0.818
vn 0.149 0.555 0.818
vn 0.050 0.573 0.818
vn 0.050 0.573 0.818
vn -0.050 0.573 0.818
vn -0.050 0.573 0.818
vn -0.149 0.555 0.818
vn -0.149 0.555 0.818
vn -0.243 0.521 0.818
vn -0.243 0.521 0.818
vn -0.330 0.471 0.818
vn -0.330 0.471 0.818
vn -0.407 0.407 0.818
vn -0.407 0.407 0.818
vn -0.471 0.330 0.818
vn -0.471 0.330 0.818
vn -0.521 0.243 0.818
vn -0.521 0.243 0.818
vn -0.555 0.149 0.818
vn -0.555 0.149 0.818
vn -0.573 0.050 0.818
vn -0.573 0.050 0.818
vn -0.573 -0.050 0.818
vn -0.573 -0.050 0.818
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -0.997 0.000 0.078
vn -0.997 0.000 0.078
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.853 0.000 0.522
vn -0.853 0.000 0.522
vn -0.853 0.000 0.523
vn -0.853 0.000 0.523
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.522 0.000 0.853
vn -0.522 0.000 0.853
vn -0.523 0.000 0.853
vn -0.523 0.000 0.853
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.078 0.000 0.997
vn -0.078 0.000 0.997
vn 0.997 0.000 0.078
vn 0.997 0.000 0.078
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.853 0.000 0.523
vn 0.853 0.000 0.523
vn 0.853 0.000 0.522
vn 0.853 0.000 0.522
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.522 0.000 0.853
vn 0.522 0.000 0.853
vn 0.523 0.000 0.853
vn 0.523 0.000 0.853
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.078 0.000 0.997
vn 0.078 0.000 0.997
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.917 0.000 0.399
vn 0.917 0.000 0.399
vn 0.913 0.000 0.408
vn 0.913 0.000 0.408
vn 0.910 0.000 0.414
vn 0.883 -0.002 0.469
vn -0.877 -0.003 0.480
vn -0.912 0.000 0.411
vn -0.911 0.000 0.412
vn -0.916 0.000 0.402
vn -0.916 0.000 0.402
vn -0.918 0.000 0.396
vn 0.877 0.000 0.480
vn 0.793 0.000 0.609
vn 0.793 0.000 0.609
vn 0.681 0.000 0.732
vn 0.681 0.000 0.732
vn 0.550 0.000 0.835
vn 0.550 0.000 0.835
vn 0.404 0.000 0.915
vn 0.404 0.000 0.915
vn 0.247 0.000 0.969
vn 0.247 0.000 0.969
vn 0.083 0.000 0.997
vn 0.083 0.000 0.997
vn -0.083 0.000 0.997
vn -0.083 0.000 0.997
vn -0.247 0.000 0.969
vn -0.247 0.000 0.969
vn -0.404 0.000 0.915
vn -0.404 0.000 0.915
vn -0.550 0.000 0.835
vn -0.550 0.000 0.835
vn -0.681 0.000 0.732
vn -0.681 0.000 0.732
vn -0.793 0.000 0.609
vn -0.793 0.000 0.609
vn -0.883 0.000 0.469
vn 0.087 0.000 0.996
vn -0.087 0.000 0.996
vn -0.087 0.000 0.996
vn -0.259 0.000 0.966
vn -0.259 0.000 0.966
vn -0.423 0.000 0.906
vn -0.423 0.000 0.906
vn -0.574 0.000 0.819
vn -0.574 0.000 0.819
vn -0.707 0.000 0.707
vn -0.707 0.000 0.707
vn -0.819 0.000 0.574
vn -0.819 0.000 0.574
vn -0.906 0.000 0.423
vn -0.906 0.000 0.423
vn -0.966 0.000 0.259
vn -0.966 0.000 0.259
vn -0.996 0.000 0.087
vn -0.996 0.000 0.087
vn -0.996 0.000 -0.087
vn -0.996 0.000 -0.087
vn -0.966 0.000 -0.259
vn -0.966 0.000 -0.259
vn -0.906 0.000 -0.423
vn -0.906 0.000 -0.423
vn -0.819 0.000 -0.574
vn -0.819 0.000 -0.574
vn -0.707 0.000 -0.707
vn -0.707 0.000 -0.707
vn -0.574 0.000 -0.819
vn -0.574 0.000 -0.819
vn -0.423 0.000 -0.906
vn -0.423 0.000 -0.906
vn -0.259 0.000 -0.966
vn -0.259 0.000 -0.966
vn -0.087 0.000 -0.996
vn -0.087 0.000 -0.996
vn 0.087 0.000 -0.996
vn 0.087 0.000 -0.996
vn 0.259 0.000 -0.966
vn 0.259 0.000 -0.966
vn 0.423 0.000 -0.906
vn 0.423 0.000 -0.906
vn 0.574 0.000 -0.819
vn 0.574 0.000 -0.819
vn 0.707 0.000 -0.707
vn 0.707 0.000 -0.707
vn 0.819 0.000 -0.574
vn 0.819 0.000 -0.574
vn 0.906 0.000 -0.423
vn 0.906 0.000 -0.423
vn 0.966 0.000 -0.259
vn 0.966 0.000 -0.259
vn 0.996 0.000 -0.087
vn 0.996 0.000 -0.087
vn 0.996 0.000 0.087
vn 0.996 0.000 0.087
vn 0.966 0.000 0.259
vn 0.966 0.000 0.259
vn 0.906 0.000 0.423
vn 0.906 0.000 0.423
vn 0.819 0.000 0.574
vn 0.819 0.000 0.574
vn 0.707 0.000 0.707
vn 0.707 0.000 0.707
vn 0.574 0.000 0.819
vn 0.574 0.000 0.819
vn 0.423 0.000 0.906
vn 0.423 0.000 0.906
vn 0.259 0.000 0.966
vn 0.259 0.000 0.966
vn 0.087 0.000 0.996
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -0.023 -0.996 -0.084
vn -0.023 -0.996 -0.084
vn -0.037 -0.996 -0.079
vn -0.037 -0.996 -0.079
vn -0.050 -0.996 -0.072
vn -0.050 -0.996 -0.072
vn -0.062 -0.996 -0.062
vn -0.062 -0.996 -0.062
vn -0.072 -0.996 -0.050
vn -0.072 -0.996 -0.050
vn -0.079 -0.996 -0.037
vn -0.079 -0.996 -0.037
vn -0.084 -0.996 -0.023
vn -0.084 -0.996 -0.023
vn -0.087 -0.996 -0.008
vn -0.087 -0.996 -0.008
vn -0.087 -0.996 0.008
vn -0.087 -0.996 0.008
vn -0.084 -0.996 0.023
vn -0.084 -0.996 0.023
vn -0.079 -0.996 0.037
vn -0.079 -0.996 0.037
vn -0.072 -0.996 0.050
vn -0.072 -0.996 0.050
vn -0.062 -0.996 0.062
vn -0.062 -0.996 0.062
vn -0.050 -0.996 0.072
vn -0.050 -0.996 0.072
vn -0.037 -0.996 0.079
vn -0.037 -0.996 0.079
vn -0.023 -0.996 0.084
vn -0.023 -0.996 0.084
vn -0.008 -0.996 0.087
vn -0.008 -0.996 0.087
vn 0.008 -0.996 0.087
vn 0.008 -0.996 0.087
vn 0.023 -0.996 0.084
vn 0.023 -0.996 0.084
vn 0.037 -0.996 0.079
vn 0.037 -0.996 0.079
vn 0.050 -0.996 0.072
vn 0.050 -0.996 0.072
vn 0.062 -0.996 0.062
vn 0.062 -0.996 0.062
vn 0.072 -0.996 0.050
vn 0.072 -0.996 0.050
vn 0.079 -0.996 0.037
vn 0.079 -0.996 0.037
vn 0.084 -0.996 0.023
vn 0.084 -0.996 0.023
vn 0.087 -0.996 0.008
vn 0.087 -0.996 0.008
vn 0.087 -0.996 -0.008
vn 0.087 -0.996 -0.008
vn 0.084 -0.996 -0.023
vn 0.084 -0.996 -0.023
vn 0.079 -0.996 -0.037
vn 0.079 -0.996 -0.037
vn 0.072 -0.996 -0.050
vn 0.072 -0.996 -0.050
vn 0.062 -0.996 -0.062
vn 0.062 -0.996 -0.062
vn 0.050 -0.996 -0.072
vn 0.050 -0.996 -0.072
vn 0.037 -0.996 -0.079
vn 0.037 -0.996 -0.079
vn 0.023 -0.996 -0.084
vn 0.023 -0.996 -0.084
vn 0.008 -0.996 -0.087
vn 0.008 -0.996 -0.087
vn -0.008 -0.996 -0.087
vn -0.008 -0.996 -0.087
vn -0.067 -0.966 -0.251
vn -0.067 -0.966 -0.251
vn -0.110 -0.966 -0.235
vn -0.110 -0.966 -0.235
vn -0.149 -0.966 -0.213
vn -0.149 -0.966 -0.213
vn -0.184 -0.966 -0.184
vn -0.184 -0.966 -0.184
vn -0.213 -0.966 -0.149
vn -0.213 -0.966 -0.149
vn -0.235 -0.966 -0.110
vn -0.235 -0.966 -0.110
vn -0.251 -0.966 -0.067
vn -0.251 -0.966 -0.067
vn -0.259 -0.966 -0.023
vn -0.259 -0.966 -0.023
vn -0.259 -0.966 0.023
vn -0.259 -0.966 0.023
vn -0.251 -0.966 0.067
vn -0.251 -0.966 0.067
vn -0.235 -0.966 0.110
vn -0.235 -0.966 0.110
vn -0.213 -0.966 0.149
vn -0.213 -0.966 0.149
vn -0.184 -0.966 0.184
vn -0.184 -0.966 0.184
vn -0.149 -0.966 0.213
vn -0.149 -0.966 0.213
vn -0.110 -0.966 0.235
vn -0.110 -0.966 0.235
vn -0.067 -0.966 0.251
vn -0.067 -0.966 0.251
vn -0.023 -0.966 0.259
vn -0.023 -0.966 0.259
vn 0.023 -0.966 0.259
vn 0.023 -0.966 0.259
vn 0.067 -0.966 0.251
vn 0.067 -0.966 0.251
vn 0.110 -0.966 0.235
vn 0.110 -0.966 0.235
vn 0.149 -0.966 0.213
vn 0.149 -0.966 0.213
vn 0.184 -0.966 0.184
vn 0.184 -0.966 0.184
vn 0.213 -0.966 0.149
vn 0.213 -0.966 0.149
vn 0.235 -0.966 0.110
vn 0.235 -0.966 0.110
vn 0.251 -0.966 0.067
vn 0.251 -0.966 0.067
vn 0.259 -0.966 0.023
vn 0.259 -0.966 0.023
vn 0.259 -0.966 -0.023
vn 0.259 -0.966 -0.023
vn 0.251 -0.966 -0.067
vn 0.251 -0.966 -0.067
vn 0.235 -0.966 -0.110
vn 0.235 -0.966 -0.110
vn 0.213 -0.966 -0.149
vn 0.213 -0.966 -0.149
vn 0.184 -0.966 -0.184
vn 0.184 -0.966 -0.184
vn 0.149 -0.966 -0.213
vn 0.149 -0.966 -0.213
vn 0.110 -0.966 -0.235
vn 0.110 -0.966 -0.235
vn 0.067 -0.966 -0.251
vn 0.067 -0.966 -0.251
vn 0.023 -0.966 -0.259
vn 0.023 -0.966 -0.259
vn -0.023 -0.966 -0.259
vn -0.023 -0.966 -0.259
vn -0.110 -0.906 -0.410
vn -0.110 -0.906 -0.410
vn -0.179 -0.906 -0.384
vn -0.179 -0.906 -0.384
vn -0.243 -0.906 -0.347
vn -0.243 -0.906 -0.347
vn -0.300 -0.906 -0.300
vn -0.300 -0.906 -0.300
vn -0.347 -0.906 -0.243
vn -0.347 -0.906 -0.243
vn -0.384 -0.906 -0.179
vn -0.384 -0.906 -0.179
vn -0.410 -0.906 -0.110
vn -0.410 -0.906 -0.110
vn -0.422 -0.906 -0.037
vn -0.422 -0.906 -0.037
vn -0.422 -0.906 0.037
vn -0.422 -0.906 0.037
vn -0.410 -0.906 0.110
vn -0.410 -0.906 0.110
vn -0.384 -0.906 0.179
vn -0.384 -0.906 0.179
vn -0.347 -0.906 0.243
vn -0.347 -0.906 0.243
vn -0.300 -0.906 0.300
vn -0.300 -0.906 0.300
vn -0.243 -0.906 0.347
vn -0.243 -0.906 0.347
vn -0.179 -0.906 0.384
vn -0.179 -0.906 0.384
vn -0.110 -0.906 0.410
vn -0.110 -0.906 0.410
vn -0.037 -0.906 0.422
vn -0.037 -0.906 0.422
vn 0.037 -0.906 0.422
vn 0.037 -0.906 0.422
vn 0.110 -0.906 0.410
vn 0.110 -0.906 0.410
vn 0.179 -0.906 0.384
vn 0.179 -0.906 0.384
vn 0.243 -0.906 0.347
vn 0.243 -0.906 0.347
vn 0.300 -0.906 0.300
vn 0.300 -0.906 0.300
vn 0.347 -0.906 0.243
vn 0.347 -0.906 0.243
vn 0.384 -0.906 0.179
vn 0.384 -0.906 0.179
vn 0.410 -0.906 0.110
vn 0.410 -0.906 0.110
vn 0.422 -0.906 0.037
vn 0.422 -0.906 0.037
vn 0.422 -0.906 -0.037
vn 0.422 -0.906 -0.037
vn 0.410 -0.906 -0.110
vn 0.410 -0.906 -0.110
vn 0.384 -0.906 -0.179
vn 0.384 -0.906 -0.179
vn 0.347 -0.906 -0.243
vn 0.347 -0.906 -0.243
vn 0.300 -0.906 -0.300
vn 0.300 -0.906 -0.300
vn 0.243 -0.906 -0.347
vn 0.243 -0.906 -0.347
vn 0.179 -0.906 -0.384
vn 0.179 -0.906 -0.384
vn 0.110 -0.906 -0.410
vn 0.110 -0.906 -0.410
vn 0.037 -0.906 -0.422
vn 0.037 -0.906 -0.422
vn -0.037 -0.906 -0.422
vn -0.037 -0.906 -0.422
vn -0.149 -0.818 -0.555
vn -0.149 -0.818 -0.555
vn -0.243 -0.818 -0.521
vn -0.243 -0.818 -0.521
vn -0.330 -0.818 -0.471
vn -0.330 -0.818 -0.471
vn -0.407 -0.818 -0.407
vn -0.407 -0.818 -0.407
vn -0.471 -0.818 -0.330
vn -0.471 -0.818 -0.330
vn -0.521 -0.818 -0.243
vn -0.521 -0.818 -0.243
vn -0.555 -0.818 -0.149
vn -0.555 -0.818 -0.149
vn -0.573 -0.818 -0.050
vn -0.573 -0.818 -0.050
vn -0.573 -0.818 0.050
vn -0.573 -0.818 0.050
vn -0.555 -0.818 0.149
vn -0.555 -0.818 0.149
vn -0.521 -0.818 0.243
vn -0.521 -0.818 0.243
vn -0.471 -0.818 0.330
vn -0.471 -0.818 0.330
vn -0.407 -0.818 0.407
vn -0.407 -0.818 0.407
vn -0.330 -0.818 0.471
vn -0.330 -0.818 0.471
vn -0.243 -0.818 0.521
vn -0.243 -0.818 0.521
vn -0.149 -0.818 0.555
vn -0.149 -0.818 0.555
vn -0.050 -0.818 0.573
vn -0.050 -0.818 0.573
vn 0.050 -0.818 0.573
vn 0.050 -0.818 0.573
vn 0.149 -0.818 0.555
vn 0.149 -0.818 0.555
vn 0.243 -0.818 0.521
vn 0.243 -0.818 0.521
vn 0.330 -0.818 0.471
vn 0.330 -0.818 0.471
vn 0.407 -0.818 0.407
vn 0.407 -0.818 0.407
vn 0.471 -0.818 0.330
vn 0.471 -0.818 0.330
vn 0.521 -0.818 0.243
vn 0.521 -0.818 0.243
vn 0.555 -0.818 0.149
vn 0.555 -0.818 0.149
vn 0.573 -0.818 0.050
vn 0.573 -0.818 0.050
vn 0.573 -0.818 -0.050
vn 0.573 -0.818 -0.050
vn 0.555 -0.818 -0.149
vn 0.555 -0.818 -0.149
vn 0.521 -0.818 -0.243
vn 0.521 -0.818 -0.243
vn 0.471 -0.818 -0.330
vn 0.471 -0.818 -0.330
vn 0.407 -0.818 -0.407
vn 0.407 -0.818 -0.407
vn 0.330 -0.818 -0.471
vn 0.330 -0.818 -0.471
vn 0.243 -0.818 -0.521
vn 0.243 -0.818 -0.521
vn 0.149 -0.818 -0.555
vn 0.149 -0.818 -0.555
vn 0.050 -0.818 -0.573
vn 0.050 -0.818 -0.573
vn -0.050 -0.818 -0.573
vn -0.050 -0.818 -0.573
vn -0.183 -0.706 -0.684
vn -0.183 -0.706 -0.684
vn -0.299 -0.706 -0.642
vn -0.299 -0.706 -0.642
vn -0.406 -0.706 -0.580
vn -0.406 -0.706 -0.580
vn -0.501 -0.706 -0.501
vn -0.501 -0.706 -0.501
vn -0.580 -0.706 -0.406
vn -0.580 -0.706 -0.406
vn -0.642 -0.706 -0.299
vn -0.642 -0.706 -0.299
vn -0.684 -0.706 -0.183
vn -0.684 -0.706 -0.183
vn -0.706 -0.706 -0.062
vn -0.706 -0.706 -0.062
vn -0.706 -0.706 0.062
vn -0.706 -0.706 0.062
vn -0.684 -0.706 0.183
vn -0.684 -0.706 0.183
vn -0.642 -0.706 0.299
vn -0.642 -0.706 0.299
vn -0.580 -0.706 0.406
vn -0.580 -0.706 0.406
vn -0.501 -0.706 0.501
vn -0.501 -0.706 0.501
vn -0.406 -0.706 0.580
vn -0.406 -0.706 0.580
vn -0.299 -0.706 0.642
vn -0.299 -0.706 0.642
vn -0.183 -0.706 0.684
vn -0.183 -0.706 0.684
vn -0.062 -0.706 0.706
vn -0.062 -0.706 0.706
vn 0.062 -0.706 0.706
vn 0.062 -0.706 0.706
vn 0.183 -0.706 0.684
vn 0.183 -0.706 0.684
vn 0.299 -0.706 0.642
vn 0.299 -0.706 0.642
vn 0.406 -0.706 0.580
vn 0.406 -0.706 0.580
vn 0.501 -0.706 0.501
vn 0.501 -0.706 0.501
vn 0.580 -0.706 0.406
vn 0.580 -0.706 0.406
vn 0.642 -0.706 0.299
vn 0.642 -0.706 0.299
vn 0.684 -0.706 0.183
vn 0.684 -0.706 0.183
vn 0.706 -0.706 0.062
vn 0.706 -0.706 0.062
vn 0.706 -0.706 -0.062
vn 0.706 -0.706 -0.062
vn 0.684 -0.706 -0.183
vn 0.684 -0.706 -0.183
vn 0.642 -0.706 -0.299
vn 0.642 -0.706 -0.299
vn 0.580 -0.706 -0.406
vn 0.580 -0.706 -0.406
vn 0.501 -0.706 -0.501
vn 0.501 -0.706 -0.501
vn 0.406 -0.706 -0.580
vn 0.406 -0.706 -0.580
vn 0.299 -0.706 -0.642
vn 0.299 -0.706 -0.642
vn 0.183 -0.706 -0.684
vn 0.183 -0.706 -0.684
vn 0.062 -0.706 -0.706
vn 0.062 -0.706 -0.706
vn -0.062 -0.706 -0.706
vn -0.062 -0.706 -0.706
vn -0.212 -0.572 -0.792
vn -0.212 -0.572 -0.792
vn -0.347 -0.572 -0.743
vn -0.347 -0.572 -0.743
vn -0.470 -0.572 -0.672
vn -0.470 -0.572 -0.672
vn -0.580 -0.572 -0.580
vn -0.580 -0.572 -0.580
vn -0.672 -0.572 -0.470
vn -0.672 -0.572 -0.470
vn -0.743 -0.572 -0.347
vn -0.743 -0.572 -0.347
vn -0.792 -0.572 -0.212
vn -0.792 -0.572 -0.212
vn -0.817 -0.572 -0.071
vn -0.817 -0.572 -0.071
vn -0.817 -0.572 0.071
vn -0.817 -0.572 0.071
vn -0.792 -0.572 0.212
vn -0.792 -0.572 0.212
vn -0.743 -0.572 0.347
vn -0.743 -0.572 0.347
vn -0.672 -0.572 0.470
vn -0.672 -0.572 0.470
vn -0.580 -0.572 0.580
vn -0.580 -0.572 0.580
vn -0.470 -0.572 0.672
vn -0.470 -0.572 0.672
vn -0.347 -0.572 0.743
vn -0.347 -0.572 0.743
vn -0.212 -0.572 0.792
vn -0.212 -0.572 0.792
vn -0.071 -0.572 0.817
vn -0.071 -0.572 0.817
vn 0.071 -0.572 0.817
vn 0.071 -0.572 0.817
vn 0.212 -0.572 0.792
vn 0.212 -0.572 0.792
vn 0.347 -0.572 0.743
vn 0.347 -0.572 0.743
vn 0.470 -0.572 0.672
vn 0.470 -0.572 0.672
vn 0.580 -0.572 0.580
vn 0.580 -0.572 0.580
vn 0.672 -0.572 0.470
vn 0.672 -0.572 0.470
vn 0.743 -0.572 0.347
vn 0.743 -0.572 0.347
vn 0.792 -0.572 0.212
vn 0.792 -0.572 0.212
vn 0.817 -0.572 0.071
vn 0.817 -0.572 0.071
vn 0.817 -0.572 -0.071
vn 0.817 -0.572 -0.071
vn 0.792 -0.572 -0.212
vn 0.792 -0.572 -0.212
vn 0.743 -0.572 -0.347
vn 0.743 -0.572 -0.347
vn 0.672 -0.572 -0.470
vn 0.672 -0.572 -0.470
vn 0.580 -0.572 -0.580
vn 0.580 -0.572 -0.580
vn 0.470 -0.572 -0.672
vn 0.470 -0.572 -0.672
vn 0.347 -0.572 -0.743
vn 0.347 -0.572 -0.743
vn 0.212 -0.572 -0.792
vn 0.212 -0.572 -0.792
vn 0.071 -0.572 -0.817
vn 0.071 -0.572 -0.817
vn -0.071 -0.572 -0.817
vn -0.071 -0.572 -0.817
vn -0.235 -0.421 -0.876
vn -0.235 -0.421 -0.876
vn -0.383 -0.421 -0.822
vn -0.383 -0.421 -0.822
vn -0.520 -0.421 -0.743
vn -0.520 -0.421 -0.743
vn -0.641 -0.421 -0.641
vn -0.641 -0.421 -0.641
vn -0.743 -0.421 -0.520
vn -0.743 -0.421 -0.520
vn -0.822 -0.421 -0.383
vn -0.822 -0.421 -0.383
vn -0.876 -0.421 -0.235
vn -0.876 -0.421 -0.235
vn -0.903 -0.421 -0.079
vn -0.903 -0.421 -0.079
vn -0.903 -0.421 0.079
vn -0.903 -0.421 0.079
vn -0.876 -0.421 0.235
vn -0.876 -0.421 0.235
vn -0.822 -0.421 0.383
vn -0.822 -0.421 0.383
vn -0.743 -0.421 0.520
vn -0.743 -0.421 0.520
vn -0.641 -0.421 0.641
vn -0.641 -0.421 0.641
vn -0.520 -0.421 0.743
vn -0.520 -0.421 0.743
vn -0.383 -0.421 0.822
vn -0.383 -0.421 0.822
vn -0.235 -0.421 0.876
vn -0.235 -0.421 0.876
vn -0.079 -0.421 0.903
vn -0.079 -0.421 0.903
vn 0.079 -0.421 0.903
vn 0.079 -0.421 0.903
vn 0.235 -0.421 0.876
vn 0.235 -0.421 0.876
vn 0.383 -0.421 0.822
vn 0.383 -0.421 0.822
vn 0.520 -0.421 0.743
vn 0.520 -0.421 0.743
vn 0.641 -0.421 0.641
vn 0.641 -0.421 0.641
vn 0.743 -0.421 0.520
vn 0.743 -0.421 0.520
vn 0.822 -0.421 0.383
vn 0.822 -0.421 0.383
vn 0.876 -0.421 0.235
vn 0.876 -0.421 0.235
vn 0.903 -0.421 0.079
vn 0.903 -0.421 0.079
vn 0.903 -0.421 -0.079
vn 0.903 -0.421 -0.079
vn 0.876 -0.421 -0.235
vn 0.876 -0.421 -0.235
vn 0.822 -0.421 -0.383
vn 0.822 -0.421 -0.383
vn 0.743 -0.421 -0.520
vn 0.743 -0.421 -0.520
vn 0.641 -0.421 -0.641
vn 0.641 -0.421 -0.641
vn 0.520 -0.421 -0.743
vn 0.520 -0.421 -0.743
vn 0.383 -0.421 -0.822
vn 0.383 -0.421 -0.822
vn 0.235 -0.421 -0.876
vn 0.235 -0.421 -0.876
vn 0.079 -0.421 -0.903
vn 0.079 -0.421 -0.903
vn -0.079 -0.421 -0.903
vn -0.079 -0.421 -0.903
vn -0.250 -0.258 -0.933
vn -0.250 -0.258 -0.933
vn -0.408 -0.258 -0.876
vn -0.408 -0.258 -0.876
vn -0.554 -0.258 -0.791
vn -0.554 -0.258 -0.791
vn -0.683 -0.258 -0.683
vn -0.683 -0.258 -0.683
vn -0.791 -0.258 -0.554
vn -0.791 -0.258 -0.554
vn -0.876 -0.258 -0.408
vn -0.876 -0.258 -0.408
vn -0.933 -0.258 -0.250
vn -0.933 -0.258 -0.250
vn -0.962 -0.258 -0.084
vn -0.962 -0.258 -0.084
vn -0.962 -0.258 0.084
vn -0.962 -0.258 0.084
vn -0.933 -0.258 0.250
vn -0.933 -0.258 0.250
vn -0.876 -0.258 0.408
vn -0.876 -0.258 0.408
vn -0.791 -0.258 0.554
vn -0.791 -0.258 0.554
vn -0.683 -0.258 0.683
vn -0.683 -0.258 0.683
vn -0.554 -0.258 0.791
vn -0.554 -0.258 0.791
vn -0.408 -0.258 0.876
vn -0.408 -0.258 0.876
vn -0.250 -0.258 0.933
vn -0.250 -0.258 0.933
vn -0.084 -0.258 0.962
vn -0.084 -0.258 0.962
vn 0.084 -0.258 0.962
vn 0.084 -0.258 0.962
vn 0.250 -0.258 0.933
vn 0.250 -0.258 0.933
vn 0.408 -0.258 0.876
vn 0.408 -0.258 0.876
vn 0.554 -0.258 0.791
vn 0.554 -0.258 0.791
vn 0.683 -0.258 0.683
vn 0.683 -0.258 0.683
vn 0.791 -0.258 0.554
vn 0.791 -0.258 0.554
vn 0.876 -0.258 0.408
vn 0.876 -0.258 0.408
vn 0.933 -0.258 0.250
vn 0.933 -0.258 0.250
vn 0.962 -0.258 0.084
vn 0.962 -0.258 0.084
vn 0.962 -0.258 -0.084
vn 0.962 -0.258 -0.084
vn 0.933 -0.258 -0.250
vn 0.933 -0.258 -0.250
vn 0.876 -0.258 -0.408
vn 0.876 -0.258 -0.408
vn 0.791 -0.258 -0.554
vn 0.791 -0.258 -0.554
vn 0.683 -0.258 -0.683
vn 0.683 -0.258 -0.683
vn 0.554 -0.258 -0.791
vn 0.554 -0.258 -0.791
vn 0.408 -0.258 -0.876
vn 0.408 -0.258 -0.876
vn 0.250 -0.258 -0.933
vn 0.250 -0.258 -0.933
vn 0.084 -0.258 -0.962
vn 0.084 -0.258 -0.962
vn -0.084 -0.258 -0.962
vn -0.084 -0.258 -0.962
vn -0.258 -0.087 -0.962
vn -0.258 -0.087 -0.962
vn -0.421 -0.087 -0.903
vn -0.421 -0.087 -0.903
vn -0.571 -0.087 -0.816
vn -0.571 -0.087 -0.816
vn -0.704 -0.087 -0.704
vn -0.704 -0.087 -0.704
vn -0.816 -0.087 -0.571
vn -0.816 -0.087 -0.571
vn -0.903 -0.087 -0.421
vn -0.903 -0.087 -0.421
vn -0.962 -0.087 -0.258
vn -0.962 -0.087 -0.258
vn -0.992 -0.087 -0.087
vn -0.992 -0.087 -0.087
vn -0.992 -0.087 0.087
vn -0.992 -0.087 0.087
vn -0.962 -0.087 0.258
vn -0.962 -0.087 0.258
vn -0.903 -0.087 0.421
vn -0.903 -0.087 0.421
vn -0.816 -0.087 0.571
vn -0.816 -0.087 0.571
vn -0.704 -0.087 0.704
vn -0.704 -0.087 0.704
vn -0.571 -0.087 0.816
vn -0.571 -0.087 0.816
vn -0.421 -0.087 0.903
vn -0.421 -0.087 0.903
vn -0.258 -0.087 0.962
vn -0.258 -0.087 0.962
vn -0.087 -0.087 0.992
vn -0.087 -0.087 0.992
vn 0.087 -0.087 0.992
vn 0.087 -0.087 0.992
vn 0.258 -0.087 0.962
vn 0.258 -0.087 0.962
vn 0.421 -0.087 0.903
vn 0.421 -0.087 0.903
vn 0.571 -0.087 0.816
vn 0.571 -0.087 0.816
vn 0.704 -0.087 0.704
vn 0.704 -0.087 0.704
vn 0.816 -0.087 0.571
vn 0.816 -0.087 0.571
vn 0.903 -0.087 0.421
vn 0.903 -0.087 0.421
vn 0.962 -0.087 0.258
vn 0.962 -0.087 0.258
vn 0.992 -0.087 0.087
vn 0.992 -0.087 0.087
vn 0.992 -0.087 -0.087
vn 0.992 -0.087 -0.087
vn 0.962 -0.087 -0.258
vn 0.962 -0.087 -0.258
vn 0.903 -0.087 -0.421
vn 0.903 -0.087 -0.421
vn 0.816 -0.087 -0.571
vn 0.816 -0.087 -0.571
vn 0.704 -0.087 -0.704
vn 0.704 -0.087 -0.704
vn 0.571 -0.087 -0.816
vn 0.571 -0.087 -0.816
vn 0.421 -0.087 -0.903
vn 0.421 -0.087 -0.903
vn 0.258 -0.087 -0.962
vn 0.258 -0.087 -0.962
vn 0.087 -0.087 -0.992
vn 0.087 -0.087 -0.992
vn -0.087 -0.087 -0.992
vn -0.087 -0.087 -0.992
vn 0.887 0.000 0.462
vn 0.887 0.000 0.462
vn 0.802 0.002 0.597
vn 0.883 0.000 0.469
vn 0.803 0.000 0.596
vn 0.614 0.000 0.790
vn 0.685 0.000 0.728
vn 0.614 0.000 0.790
vn 0.685 0.000 0.728
vn 0.704 0.000 0.711
vn 0.685 0.000 0.728
vn 0.793 0.000 0.609
vn 0.684 0.002 0.730
vn 0.803 0.000 0.596
vn 0.504 0.000 0.864
vn 0.526 0.000 0.850
vn 0.527 0.000 0.850
vn 0.504 0.000 0.864
vn 0.527 0.000 0.850
vn 0.527 0.000 0.850
vn 0.504 0.000 0.863
vn 0.441 0.000 0.898
vn 0.326 0.000 0.946
vn 0.377 0.000 0.926
vn 0.326 0.000 0.945
vn 0.326 0.000 0.945
vn 0.261 -0.001 0.965
vn 0.106 0.002 0.994
vn 0.377 -0.002 0.926
vn 0.261 -0.001 0.965
vn 0.107 0.000 0.994
vn 0.191 0.000 0.982
vn 0.119 0.000 0.993
vn 0.108 0.000 0.994
vn 0.120 0.000 0.993
vn 0.098 0.000 0.995
vn 0.119 -0.001 0.993
vn 0.030 0.000 1.000
vn 0.000 0.107 0.994
vn 0.000 0.887 0.462
vn 0.000 0.932 0.363
vn 0.000 0.968 0.252
vn 0.000 0.968 0.252
vn 0.000 0.950 0.313
vn 0.000 0.996 0.085
vn 0.000 0.989 0.145
vn 0.000 0.989 0.145
vn 0.000 0.996 0.086
vn 0.000 1.000 0.030
vn 0.000 0.950 0.313
vn 0.000 0.887 0.462
vn 0.000 0.887 0.462
vn 0.000 0.887 0.462
vn 0.000 0.803 0.596
vn 0.000 0.803 0.596
vn 0.000 0.803 0.596
vn 0.000 0.803 0.596
vn 0.000 0.803 0.596
vn 0.000 0.803 0.596
vn 0.000 0.685 0.729
vn 0.000 0.685 0.729
vn 0.000 0.685 0.728
vn 0.000 0.526 0.850
vn 0.000 0.685 0.728
vn 0.000 0.685 0.728
vn 0.000 0.685 0.728
vn 0.000 0.685 0.728
vn 0.000 0.685 0.728
vn 0.000 0.526 0.850
vn 0.000 0.526 0.850
vn 0.000 0.526 0.850
vn 0.000 0.526 0.850
vn 0.000 0.526 0.850
vn 0.000 0.527 0.850
vn 0.001 0.526 0.851
vn 0.000 0.325 0.946
vn 0.000 0.325 0.946
vn 0.000 0.326 0.946
vn 0.000 0.326 0.946
vn 0.000 0.326 0.946
vn 0.000 0.325 0.946
vn -0.001 0.107 0.994
vn -0.001 0.107 0.994
vn 0.000 0.107 0.994
vn 0.000 0.107 0.994
vn 0.000 0.107 0.994
vn 0.000 0.107 0.994
vn -0.887 0.000 0.462
vn -0.883 0.000 0.469
vn -0.887 0.000 0.462
vn -0.803 0.000 0.596
vn -0.803 0.000 0.596
vn -0.793 0.000 0.609
vn -0.803 0.000 0.596
vn -0.685 0.000 0.728
vn -0.704 0.000 0.711
vn -0.685 0.000 0.728
vn -0.639 -0.001 0.769
vn -0.685 0.000 0.728
vn -0.639 0.000 0.769
vn -0.685 0.000 0.728
vn -0.556 0.000 0.831
vn -0.487 -0.001 0.873
vn -0.527 0.000 0.850
vn -0.527 0.000 0.850
vn -0.487 0.000 0.873
vn -0.326 0.000 0.945
vn -0.525 0.002 0.851
vn -0.305 -0.001 0.952
vn -0.526 0.001 0.851
vn -0.421 0.000 0.907
vn -0.421 0.000 0.907
vn -0.107 0.000 0.994
vn -0.326 0.000 0.945
vn -0.239 -0.001 0.971
vn -0.326 0.000 0.945
vn -0.305 0.000 0.952
vn -0.305 0.000 0.952
vn -0.058 0.000 0.998
vn -0.058 0.000 0.998
vn -0.107 0.000 0.994
vn -0.096 0.000 0.995
vn -0.108 0.000 0.994
vn -0.175 0.000 0.985
vn -0.175 0.000 0.985
vn -0.023 -0.996 0.084
vn -0.023 -0.996 0.084
vn -0.037 -0.996 0.079
vn -0.037 -0.996 0.079
vn -0.050 -0.996 0.072
vn -0.050 -0.996 0.072
vn -0.062 -0.996 0.062
vn -0.062 -0.996 0.062
vn -0.072 -0.996 0.050
vn -0.072 -0.996 0.050
vn -0.079 -0.996 0.037
vn -0.079 -0.996 0.037
vn -0.084 -0.996 0.023
vn -0.084 -0.996 0.023
vn -0.087 -0.996 0.008
vn -0.087 -0.996 0.008
vn -0.087 -0.996 -0.008
vn -0.087 -0.996 -0.008
vn -0.084 -0.996 -0.023
vn -0.084 -0.996 -0.023
vn -0.079 -0.996 -0.037
vn -0.079 -0.996 -0.037
vn -0.072 -0.996 -0.050
vn -0.072 -0.996 -0.050
vn -0.062 -0.996 -0.062
vn -0.062 -0.996 -0.062
vn -0.050 -0.996 -0.072
vn -0.050 -0.996 -0.072
vn -0.037 -0.996 -0.079
vn -0.037 -0.996 -0.079
vn -0.023 -0.996 -0.084
vn -0.023 -0.996 -0.084
vn -0.008 -0.996 -0.087
vn -0.008 -0.996 -0.087
vn 0.008 -0.996 -0.087
vn 0.008 -0.996 -0.087
vn 0.023 -0.996 -0.084
vn 0.023 -0.996 -0.084
vn 0.037 -0.996 -0.079
vn 0.037 -0.996 -0.079
vn 0.050 -0.996 -0.072
vn 0.050 -0.996 -0.072
vn 0.062 -0.996 -0.062
vn 0.062 -0.996 -0.062
vn 0.072 -0.996 -0.050
vn 0.072 -0.996 -0.050
vn 0.079 -0.996 -0.037
vn 0.079 -0.996 -0.037
vn 0.084 -0.996 -0.023
vn 0.084 -0.996 -0.023
vn 0.087 -0.996 -0.008
vn 0.087 -0.996 -0.008
vn 0.087 -0.996 0.008
vn 0.087 -0.996 0.008
vn 0.084 -0.996 0.023
vn 0.084 -0.996 0.023
vn 0.079 -0.996 0.037
vn 0.079 -0.996 0.037
vn 0.072 -0.996 0.050
vn 0.072 -0.996 0.050
vn 0.062 -0.996 0.062
vn 0.062 -0.996 0.062
vn 0.050 -0.996 0.072
vn 0.050 -0.996 0.072
vn 0.037 -0.996 0.079
vn 0.037 -0.996 0.079
vn 0.023 -0.996 0.084
vn 0.023 -0.996 0.084
vn 0.008 -0.996 0.087
vn 0.008 -0.996 0.087
vn -0.008 -0.996 0.087
vn -0.008 -0.996 0.087
vn -0.067 -0.966 0.251
vn -0.067 -0.966 0.251
vn -0.110 -0.966 0.235
vn -0.110 -0.966 0.235
vn -0.149 -0.966 0.213
vn -0.149 -0.966 0.213
vn -0.184 -0.966 0.184
vn -0.184 -0.966 0.184
vn -0.213 -0.966 0.149
vn -0.213 -0.966 0.149
vn -0.235 -0.966 0.110
vn -0.235 -0.966 0.110
vn -0.251 -0.966 0.067
vn -0.251 -0.966 0.067
vn -0.259 -0.966 0.023
vn -0.259 -0.966 0.023
vn -0.259 -0.966 -0.023
vn -0.259 -0.966 -0.023
vn -0.251 -0.966 -0.067
vn -0.251 -0.966 -0.067
vn -0.235 -0.966 -0.110
vn -0.235 -0.966 -0.110
vn -0.213 -0.966 -0.149
vn -0.213 -0.966 -0.149
vn -0.184 -0.966 -0.184
vn -0.184 -0.966 -0.184
vn -0.149 -0.966 -0.213
vn -0.149 -0.966 -0.213
vn -0.110 -0.966 -0.235
vn -0.110 -0.966 -0.235
vn -0.067 -0.966 -0.251
vn -0.067 -0.966 -0.251
vn -0.023 -0.966 -0.259
vn -0.023 -0.966 -0.259
vn 0.023 -0.966 -0.259
vn 0.023 -0.966 -0.259
vn 0.067 -0.966 -0.251
vn 0.067 -0.966 -0.251
vn 0.110 -0.966 -0.235
vn 0.110 -0.966 -0.235
vn 0.149 -0.966 -0.213
vn 0.149 -0.966 -0.213
vn 0.184 -0.966 -0.184
vn 0.184 -0.966 -0.184
vn 0.213 -0.966 -0.149
vn 0.213 -0.966 -0.149
vn 0.235 -0.966 -0.110
vn 0.235 -0.966 -0.110
vn 0.251 -0.966 -0.067
vn 0.251 -0.966 -0.067
vn 0.259 -0.966 -0.023
vn 0.259 -0.966 -0.023
vn 0.259 -0.966 0.023
vn 0.259 -0.966 0.023
vn 0.251 -0.966 0.067
vn 0.251 -0.966 0.067
vn 0.235 -0.966 0.110
vn 0.235 -0.966 0.110
vn 0.213 -0.966 0.149
vn 0.213 -0.966 0.149
vn 0.184 -0.966 0.184
vn 0.184 -0.966 0.184
vn 0.149 -0.966 0.213
vn 0.149 -0.966 0.213
vn 0.110 -0.966 0.235
vn 0.110 -0.966 0.235
vn 0.067 -0.966 0.251
vn 0.067 -0.966 0.251
vn 0.023 -0.966 0.259
vn 0.023 -0.966 0.259
vn -0.023 -0.966 0.259
vn -0.023 -0.966 0.259
vn -0.110 -0.906 0.410
vn -0.110 -0.906 0.410
vn -0.179 -0.906 0.384
vn -0.179 -0.906 0.384
vn -0.243 -0.906 0.347
vn -0.243 -0.906 0.347
vn -0.300 -0.906 0.300
vn -0.300 -0.906 0.300
vn -0.347 -0.906 0.243
vn -0.347 -0.906 0.243
vn -0.384 -0.906 0.179
vn -0.384 -0.906 0.179
vn -0.410 -0.906 0.110
vn -0.410 -0.906 0.110
vn -0.422 -0.906 0.037
vn -0.422 -0.906 0.037
vn -0.422 -0.906 -0.037
vn -0.422 -0.906 -0.037
vn -0.410 -0.906 -0.110
vn -0.410 -0.906 -0.110
vn -0.384 -0.906 -0.179
vn -0.384 -0.906 -0.179
vn -0.347 -0.906 -0.243
vn -0.347 -0.906 -0.243
vn -0.300 -0.906 -0.300
vn -0.300 -0.906 -0.300
vn -0.243 -0.906 -0.347
vn -0.243 -0.906 -0.347
vn -0.179 -0.906 -0.384
vn -0.179 -0.906 -0.384
vn -0.110 -0.906 -0.410
vn -0.110 -0.906 -0.410
vn -0.037 -0.906 -0.422
vn -0.037 -0.906 -0.422
vn 0.037 -0.906 -0.422
vn 0.037 -0.906 -0.422
vn 0.110 -0.906 -0.410
vn 0.110 -0.906 -0.410
vn 0.179 -0.906 -0.384
vn 0.179 -0.906 -0.384
vn 0.243 -0.906 -0.347
vn 0.243 -0.906 -0.347
vn 0.300 -0.906 -0.300
vn 0.300 -0.906 -0.300
vn 0.347 -0.906 -0.243
vn 0.347 -0.906 -0.243
vn 0.384 -0.906 -0.179
vn 0.384 -0.906 -0.179
vn 0.410 -0.906 -0.110
vn 0.410 -0.906 -0.110
vn 0.422 -0.906 -0.037
vn 0.422 -0.906 -0.037
vn 0.422 -0.906 0.037
vn 0.422 -0.906 0.037
vn 0.410 -0.906 0.110
vn 0.410 -0.906 0.110
vn 0.384 -0.906 0.179
vn 0.384 -0.906 0.179
vn 0.347 -0.906 0.243
vn 0.347 -0.906 0.243
vn 0.300 -0.906 0.300
vn 0.300 -0.906 0.300
vn 0.243 -0.906 0.347
vn 0.243 -0.906 0.347
vn 0.179 -0.906 0.384
vn 0.179 -0.906 0.384
vn 0.110 -0.906 0.410
vn 0.110 -0.906 0.410
vn 0.037 -0.906 0.422
vn 0.037 -0.906 0.422
vn -0.037 -0.906 0.422
vn -0.037 -0.906 0.422
vn -0.149 -0.818 0.555
vn -0.149 -0.818 0.555
vn -0.243 -0.818 0.521
vn -0.243 -0.818 0.521
vn -0.330 -0.818 0.471
vn -0.330 -0.818 0.471
vn -0.407 -0.818 0.407
vn -0.407 -0.818 0.407
vn -0.471 -0.818 0.330
vn -0.471 -0.818 0.330
vn -0.521 -0.818 0.243
vn -0.521 -0.818 0.243
vn -0.555 -0.818 0.149
vn -0.555 -0.818 0.149
vn -0.573 -0.818 0.050
vn -0.573 -0.818 0.050
vn -0.573 -0.818 -0.050
vn -0.573 -0.818 -0.050
vn -0.555 -0.818 -0.149
vn -0.555 -0.818 -0.149
vn -0.521 -0.818 -0.243
vn -0.521 -0.818 -0.243
vn -0.471 -0.818 -0.330
vn -0.471 -0.818 -0.330
vn -0.407 -0.818 -0.407
vn -0.407 -0.818 -0.407
vn -0.330 -0.818 -0.471
vn -0.330 -0.818 -0.471
vn -0.243 -0.818 -0.521
vn -0.243 -0.818 -0.521
vn -0.149 -0.818 -0.555
vn -0.149 -0.818 -0.555
vn -0.050 -0.818 -0.573
vn -0.050 -0.818 -0.573
vn 0.050 -0.818 -0.573
vn 0.050 -0.818 -0.573
vn 0.149 -0.818 -0.555
vn 0.149 -0.818 -0.555
vn 0.243 -0.818 -0.521
vn 0.243 -0.818 -0.521
vn 0.330 -0.818 -0.471
vn 0.330 -0.818 -0.471
vn 0.407 -0.818 -0.407
vn 0.407 -0.818 -0.407
vn 0.471 -0.818 -0.330
vn 0.471 -0.818 -0.330
vn 0.521 -0.818 -0.243
vn 0.521 -0.818 -0.243
vn 0.555 -0.818 -0.149
vn 0.555 -0.818 -0.149
vn 0.573 -0.818 -0.050
vn 0.573 -0.818 -0.050
vn 0.573 -0.818 0.050
vn 0.573 -0.818 0.050
vn 0.555 -0.818 0.149
vn 0.555 -0.818 0.149
vn 0.521 -0.818 0.243
vn 0.521 -0.818 0.243
vn 0.471 -0.818 0.330
vn 0.471 -0.818 0.330
vn 0.407 -0.818 0.407
vn 0.407 -0.818 0.407
vn 0.330 -0.818 0.471
vn 0.330 -0.818 0.471
vn 0.243 -0.818 0.521
vn 0.243 -0.818 0.521
vn 0.149 -0.818 0.555
vn 0.149 -0.818 0.555
vn 0.050 -0.818 0.573
vn 0.050 -0.818 0.573
vn -0.050 -0.818 0.573
vn -0.050 -0.818 0.573
vn -0.183 -0.706 0.684
vn -0.183 -0.706 0.684
vn -0.299 -0.706 0.642
vn -0.299 -0.706 0.642
vn -0.406 -0.706 0.580
vn -0.406 -0.706 0.580
vn -0.501 -0.706 0.501
vn -0.501 -0.706 0.501
vn -0.580 -0.706 0.406
vn -0.580 -0.706 0.406
vn -0.642 -0.706 0.299
vn -0.642 -0.706 0.299
vn -0.684 -0.706 0.183
vn -0.684 -0.706 0.183
vn -0.706 -0.706 0.062
vn -0.706 -0.706 0.062
vn -0.706 -0.706 -0.062
vn -0.706 -0.706 -0.062
vn -0.684 -0.706 -0.183
vn -0.684 -0.706 -0.183
vn -0.642 -0.706 -0.299
vn -0.642 -0.706 -0.299
vn -0.580 -0.706 -0.406
vn -0.580 -0.706 -0.406
vn -0.501 -0.706 -0.501
vn -0.501 -0.706 -0.501
vn -0.406 -0.706 -0.580
vn -0.406 -0.706 -0.580
vn -0.299 -0.706 -0.642
vn -0.299 -0.706 -0.642
vn -0.183 -0.706 -0.684
vn -0.183 -0.706 -0.684
vn -0.062 -0.706 -0.706
vn -0.062 -0.706 -0.706
vn 0.062 -0.706 -0.706
vn 0.062 -0.706 -0.706
vn 0.183 -0.706 -0.684
vn 0.183 -0.706 -0.684
vn 0.299 -0.706 -0.642
vn 0.299 -0.706 -0.642
vn 0.406 -0.706 -0.580
vn 0.406 -0.706 -0.580
vn 0.501 -0.706 -0.501
vn 0.501 -0.706 -0.501
vn 0.580 -0.706 -0.406
vn 0.580 -0.706 -0.406
vn 0.642 -0.706 -0.299
vn 0.642 -0.706 -0.299
vn 0.684 -0.706 -0.183
vn 0.684 -0.706 -0.183
vn 0.706 -0.706 -0.062
vn 0.706 -0.706 -0.062
vn 0.706 -0.706 0.062
vn 0.706 -0.706 0.062
vn 0.684 -0.706 0.183
vn 0.684 -0.706 0.183
vn 0.642 -0.706 0.299
vn 0.642 -0.706 0.299
vn 0.580 -0.706 0.406
vn 0.580 -0.706 0.406
vn 0.501 -0.706 0.501
vn 0.501 -0.706 0.501
vn 0.406 -0.706 0.580
vn 0.406 -0.706 0.580
vn 0.299 -0.706 0.642
vn 0.299 -0.706 0.642
vn 0.183 -0.706 0.684
vn 0.183 -0.706 0.684
vn 0.062 -0.706 0.706
vn 0.062 -0.706 0.706
vn -0.062 -0.706 0.706
vn -0.062 -0.706 0.706
vn -0.212 -0.572 0.792
vn -0.212 -0.572 0.792
vn -0.347 -0.572 0.743
vn -0.347 -0.572 0.743
vn -0.470 -0.572 0.672
vn -0.470 -0.572 0.672
vn -0.580 -0.572 0.580
vn -0.580 -0.572 0.580
vn -0.672 -0.572 0.470
vn -0.672 -0.572 0.470
vn -0.743 -0.572 0.347
vn -0.743 -0.572 0.347
vn -0.792 -0.572 0.212
vn -0.792 -0.572 0.212
vn -0.817 -0.572 0.071
vn -0.817 -0.572 0.071
vn -0.817 -0.572 -0.071
vn -0.817 -0.572 -0.071
vn -0.792 -0.572 -0.212
vn -0.792 -0.572 -0.212
vn -0.743 -0.572 -0.347
vn -0.743 -0.572 -0.347
vn -0.672 -0.572 -0.470
vn -0.672 -0.572 -0.470
vn -0.580 -0.572 -0.580
vn -0.580 -0.572 -0.580
vn -0.470 -0.572 -0.672
vn -0.470 -0.572 -0.672
vn -0.347 -0.572 -0.743
vn -0.347 -0.572 -0.743
vn -0.212 -0.572 -0.792
vn -0.212 -0.572 -0.792
vn -0.071 -0.572 -0.817
vn -0.071 -0.572 -0.817
vn 0.071 -0.572 -0.817
vn 0.071 -0.572 -0.817
vn 0.212 -0.572 -0.792
vn 0.212 -0.572 -0.792
vn 0.347 -0.572 -0.743
vn 0.347 -0.572 -0.743
vn 0.470 -0.572 -0.672
vn 0.470 -0.572 -0.672
vn 0.580 -0.572 -0.580
vn 0.580 -0.572 -0.580
vn 0.672 -0.572 -0.470
vn 0.672 -0.572 -0.470
vn 0.743 -0.572 -0.347
vn 0.743 -0.572 -0.347
vn 0.792 -0.572 -0.212
vn 0.792 -0.572 -0.212
vn 0.817 -0.572 -0.071
vn 0.817 -0.572 -0.071
vn 0.817 -0.572 0.071
vn 0.817 -0.572 0.071
vn 0.792 -0.572 0.212
vn 0.792 -0.572 0.212
vn 0.743 -0.572 0.347
vn 0.743 -0.572 0.347
vn 0.672 -0.572 0.470
vn 0.672 -0.572 0.470
vn 0.580 -0.572 0.580
vn 0.580 -0.572 0.580
vn 0.470 -0.572 0.672
vn 0.470 -0.572 0.672
vn 0.347 -0.572 0.743
vn 0.347 -0.572 0.743
vn 0.212 -0.572 0.792
vn 0.212 -0.572 0.792
vn 0.071 -0.572 0.817
vn 0.071 -0.572 0.817
vn -0.071 -0.572 0.817
vn -0.071 -0.572 0.817
vn -0.235 -0.421 0.876
vn -0.235 -0.421 0.876
vn -0.383 -0.421 0.822
vn -0.383 -0.421 0.822
vn -0.520 -0.421 0.743
vn -0.520 -0.421 0.743
vn -0.641 -0.421 0.641
vn -0.641 -0.421 0.641
vn -0.743 -0.421 0.520
vn -0.743 -0.421 0.520
vn -0.822 -0.421 0.383
vn -0.822 -0.421 0.383
vn -0.876 -0.421 0.235
vn -0.876 -0.421 0.235
vn -0.903 -0.421 0.079
vn -0.903 -0.421 0.079
vn -0.903 -0.421 -0.079
vn -0.903 -0.421 -0.079
vn -0.876 -0.421 -0.235
vn -0.876 -0.421 -0.235
vn -0.822 -0.421 -0.383
vn -0.822 -0.421 -0.383
vn -0.743 -0.421 -0.520
vn -0.743 -0.421 -0.520
vn -0.641 -0.421 -0.641
vn -0.641 -0.421 -0.641
vn -0.520 -0.421 -0.743
vn -0.520 -0.421 -0.743
vn -0.383 -0.421 -0.822
vn -0.383 -0.421 -0.822
vn -0.235 -0.421 -0.876
vn -0.235 -0.421 -0.876
vn -0.079 -0.421 -0.903
vn -0.079 -0.421 -0.903
vn 0.079 -0.421 -0.903
vn 0.079 -0.421 -0.903
vn 0.235 -0.421 -0.876
vn 0.235 -0.421 -0.876
vn 0.383 -0.421 -0.822
vn 0.383 -0.421 -0.822
vn 0.520 -0.421 -0.743
vn 0.520 -0.421 -0.743
vn 0.641 -0.421 -0.641
vn 0.641 -0.421 -0.641
vn 0.743 -0.421 -0.520
vn 0.743 -0.421 -0.520
vn 0.822 -0.421 -0.383
vn 0.822 -0.421 -0.383
vn 0.876 -0.421 -0.235
vn 0.876 -0.421 -0.235
vn 0.903 -0.421 -0.079
vn 0.903 -0.421 -0.079
vn 0.903 -0.421 0.079
vn 0.903 -0.421 0.079
vn 0.876 -0.421 0.235
vn 0.876 -0.421 0.235
vn 0.822 -0.421 0.383
vn 0.822 -0.421 0.383
vn 0.743 -0.421 0.520
vn 0.743 -0.421 0.520
vn 0.641 -0.421 0.641
vn 0.641 -0.421 0.641
vn 0.520 -0.421 0.743
vn 0.520 -0.421 0.743
vn 0.383 -0.421 0.822
vn 0.383 -0.421 0.822
vn 0.235 -0.421 0.876
vn 0.235 -0.421 0.876
vn 0.079 -0.421 0.903
vn 0.079 -0.421 0.903
vn -0.079 -0.421 0.903
vn -0.079 -0.421 0.903
vn -0.250 -0.258 0.933
vn -0.250 -0.258 0.933
vn -0.408 -0.258 0.876
vn -0.408 -0.258 0.876
vn -0.554 -0.258 0.791
vn -0.554 -0.258 0.791
vn -0.683 -0.258 0.683
vn -0.683 -0.258 0.683
vn -0.791 -0.258 0.554
vn -0.791 -0.258 0.554
vn -0.876 -0.258 0.408
vn -0.876 -0.258 0.408
vn -0.933 -0.258 0.250
vn -0.933 -0.258 0.250
vn -0.962 -0.258 0.084
vn -0.963 -0.258 0.084
vn -0.963 -0.258 -0.084
vn -0.962 -0.258 -0.084
vn -0.933 -0.258 -0.250
vn -0.933 -0.258 -0.250
vn -0.876 -0.258 -0.408
vn -0.876 -0.258 -0.408
vn -0.791 -0.258 -0.554
vn -0.791 -0.258 -0.554
vn -0.683 -0.258 -0.683
vn -0.683 -0.258 -0.683
vn -0.554 -0.258 -0.791
vn -0.554 -0.258 -0.791
vn -0.408 -0.258 -0.876
vn -0.408 -0.258 -0.876
vn -0.250 -0.258 -0.933
vn -0.250 -0.258 -0.933
vn -0.084 -0.258 -0.962
vn -0.084 -0.258 -0.962
vn 0.084 -0.258 -0.962
vn 0.084 -0.258 -0.962
vn 0.250 -0.258 -0.933
vn 0.250 -0.258 -0.933
vn 0.408 -0.258 -0.876
vn 0.408 -0.258 -0.876
vn 0.554 -0.258 -0.791
vn 0.554 -0.258 -0.791
vn 0.683 -0.258 -0.683
vn 0.683 -0.258 -0.683
vn 0.791 -0.258 -0.554
vn 0.791 -0.258 -0.554
vn 0.876 -0.258 -0.408
vn 0.876 -0.258 -0.408
vn 0.933 -0.258 -0.250
vn 0.933 -0.258 -0.250
vn 0.962 -0.258 -0.084
vn 0.962 -0.258 -0.084
vn 0.962 -0.258 0.084
vn 0.962 -0.258 0.084
vn 0.933 -0.258 0.250
vn 0.933 -0.258 0.250
vn 0.876 -0.258 0.408
vn 0.876 -0.258 0.408
vn 0.791 -0.258 0.554
vn 0.791 -0.258 0.554
vn 0.683 -0.258 0.683
vn 0.683 -0.258 0.683
vn 0.554 -0.258 0.791
vn 0.554 -0.258 0.791
vn 0.408 -0.258 0.876
vn 0.408 -0.258 0.876
vn 0.250 -0.258 0.933
vn 0.250 -0.258 0.933
vn 0.084 -0.258 0.962
vn 0.084 -0.258 0.962
vn -0.084 -0.258 0.962
vn -0.084 -0.258 0.962
vn -0.258 -0.087 0.962
vn -0.258 -0.087 0.962
vn -0.421 -0.087 0.903
vn -0.421 -0.087 0.903
vn -0.571 -0.087 0.816
vn -0.571 -0.087 0.816
vn -0.704 -0.087 0.704
vn -0.704 -0.087 0.704
vn -0.816 -0.087 0.571
vn -0.816 -0.087 0.571
vn -0.903 -0.087 0.421
vn -0.903 -0.087 0.421
vn -0.962 -0.087 0.258
vn -0.962 -0.087 0.258
vn -0.992 -0.087 0.087
vn -0.992 -0.087 0.087
vn -0.992 -0.087 -0.087
vn -0.992 -0.087 -0.087
vn -0.962 -0.087 -0.258
vn -0.962 -0.087 -0.258
vn -0.903 -0.087 -0.421
vn -0.903 -0.087 -0.421
vn -0.816 -0.087 -0.571
vn -0.816 -0.087 -0.571
vn -0.704 -0.087 -0.704
vn -0.704 -0.087 -0.704
vn -0.571 -0.087 -0.816
vn -0.571 -0.087 -0.816
vn -0.421 -0.087 -0.903
vn -0.421 -0.087 -0.903
vn -0.258 -0.087 -0.962
vn -0.258 -0.087 -0.962
vn -0.087 -0.087 -0.992
vn -0.087 -0.087 -0.992
vn 0.087 -0.087 -0.992
vn 0.087 -0.087 -0.992
vn 0.258 -0.087 -0.962
vn 0.258 -0.087 -0.962
vn 0.421 -0.087 -0.903
vn 0.421 -0.087 -0.903
vn 0.571 -0.087 -0.816
vn 0.571 -0.087 -0.816
vn 0.704 -0.087 -0.704
vn 0.704 -0.087 -0.704
vn 0.816 -0.087 -0.571
vn 0.816 -0.087 -0.571
vn 0.903 -0.087 -0.421
vn 0.903 -0.087 -0.421
vn 0.962 -0.087 -0.258
vn 0.962 -0.087 -0.258
vn 0.992 -0.087 -0.087
vn 0.992 -0.087 -0.087
vn 0.992 -0.087 0.087
vn 0.992 -0.087 0.087
vn 0.962 -0.087 0.258
vn 0.962 -0.087 0.258
vn 0.903 -0.087 0.421
vn 0.903 -0.087 0.421
vn 0.816 -0.087 0.571
vn 0.816 -0.087 0.571
vn 0.704 -0.087 0.704
vn 0.704 -0.087 0.704
vn 0.571 -0.087 0.816
vn 0.571 -0.087 0.816
vn 0.421 -0.087 0.903
vn 0.421 -0.087 0.903
vn 0.258 -0.087 0.962
vn 0.258 -0.087 0.962
vn 0.087 -0.087 0.992
vn 0.087 -0.087 0.992
vn -0.087 -0.087 0.992
vn -0.087 -0.087 0.992
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -0.997 0.000 0.078
vn -0.997 0.000 0.078
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.972 0.000 0.233
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.924 0.000 0.383
vn -0.853 0.000 0.522
vn -0.853 0.000 0.522
vn -0.853 0.000 0.523
vn -0.853 0.000 0.523
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.760 0.000 0.649
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.649 0.000 0.760
vn -0.522 0.000 0.853
vn -0.522 0.000 0.853
vn -0.523 0.000 0.853
vn -0.523 0.000 0.853
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.383 0.000 0.924
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.233 0.000 0.972
vn -0.078 0.000 0.997
vn -0.078 0.000 0.997
vn 0.997 0.000 0.078
vn 0.997 0.000 0.078
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.972 0.000 0.233
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.924 0.000 0.383
vn 0.853 0.000 0.523
vn 0.853 0.000 0.523
vn 0.853 0.000 0.522
vn 0.853 0.000 0.522
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.760 0.000 0.649
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.649 0.000 0.760
vn 0.522 0.000 0.853
vn 0.522 0.000 0.853
vn 0.523 0.000 0.853
vn 0.523 0.000 0.853
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.383 0.000 0.924
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.233 0.000 0.972
vn 0.078 0.000 0.997
vn 0.078 0.000 0.997
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.899 0.000 0.439
vn 0.899 0.000 0.439
vn -0.899 0.000 0.439
vn 0.899 0.000 0.439
vn 0.810 0.000 0.586
vn 0.810 0.000 0.586
vn 0.698 0.000 0.716
vn 0.698 0.000 0.716
vn 0.566 0.000 0.825
vn 0.566 0.000 0.825
vn 0.416 0.000 0.909
vn 0.416 0.000 0.909
vn 0.255 0.000 0.967
vn 0.255 0.000 0.967
vn 0.086 0.000 0.996
vn 0.086 0.000 0.996
vn -0.086 0.000 0.996
vn -0.086 0.000 0.996
vn -0.255 0.000 0.967
vn -0.255 0.000 0.967
vn -0.416 0.000 0.909
vn -0.416 0.000 0.909
vn -0.566 0.000 0.825
vn -0.566 0.000 0.825
vn -0.698 0.000 0.716
vn -0.698 0.000 0.716
vn -0.810 0.000 0.586
vn -0.810 0.000 0.586
vn -0.899 0.000 0.439
vn -0.899 0.000 0.439
vn 0.087 0.000 -0.996
vn -0.087 0.000 -0.996
vn -0.087 0.000 -0.996
vn -0.259 0.000 -0.966
vn -0.259 0.000 -0.966
vn -0.423 0.000 -0.906
vn -0.423 0.000 -0.906
vn -0.574 0.000 -0.819
vn -0.574 0.000 -0.819
vn -0.707 0.000 -0.707
vn -0.707 0.000 -0.707
vn -0.819 0.000 -0.574
vn -0.819 0.000 -0.574
vn -0.906 0.000 -0.423
vn -0.906 0.000 -0.423
vn -0.966 0.000 -0.259
vn -0.966 0.000 -0.259
vn -0.996 0.000 -0.087
vn -0.996 0.000 -0.087
vn -0.996 0.000 0.087
vn -0.996 0.000 0.087
vn -0.966 0.000 0.259
vn -0.966 0.000 0.259
vn -0.906 0.000 0.423
vn -0.906 0.000 0.423
vn -0.819 0.000 0.574
vn -0.819 0.000 0.574
vn -0.707 0.000 0.707
vn -0.707 0.000 0.707
vn -0.574 0.000 0.819
vn -0.574 0.000 0.819
vn -0.423 0.000 0.906
vn -0.423 0.000 0.906
vn -0.259 0.000 0.966
vn -0.259 0.000 0.966
vn -0.087 0.000 0.996
vn -0.087 0.000 0.996
vn 0.087 0.000 0.996
vn 0.087 0.000 0.996
vn 0.259 0.000 0.966
vn 0.259 0.000 0.966
vn 0.423 0.000 0.906
vn 0.423 0.000 0.906
vn 0.574 0.000 0.819
vn 0.574 0.000 0.819
vn 0.707 0.000 0.707
vn 0.707 0.000 0.707
vn 0.819 0.000 0.574
vn 0.819 0.000 0.574
vn 0.906 0.000 0.423
vn 0.906 0.000 0.423
vn 0.966 0.000 0.259
vn 0.966 0.000 0.259
vn 0.996 0.000 0.087
vn 0.996 0.000 0.087
vn 0.996 0.000 -0.087
vn 0.996 0.000 -0.087
vn 0.966 0.000 -0.259
vn 0.966 0.000 -0.259
vn 0.906 0.000 -0.423
vn 0.906 0.000 -0.423
vn 0.819 0.000 -0.574
vn 0.819 0.000 -0.574
vn 0.707 0.000 -0.707
vn 0.707 0.000 -0.707
vn 0.574 0.000 -0.819
vn 0.574 0.000 -0.819
vn 0.423 0.000 -0.906
vn 0.423 0.000 -0.906
vn 0.259 0.000 -0.966
vn 0.259 0.000 -0.966
vn 0.087 0.000 -0.996
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 0.342 0.940
vn 0.000 0.342 0.940
vn 0.000 0.922 -0.387
vn 0.000 0.922 -0.387
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.922 -0.387
vn 0.000 -0.922 -0.387
vn 0.000 -0.342 0.940
vn 0.000 -0.342 0.940
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 -0.342
vn 0.000 0.940 -0.342
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 0.996 0.085
vn 0.000 0.996 0.085
vn 0.000 0.967 0.254
vn 0.000 0.967 0.254
vn 0.000 0.910 0.415
vn 0.000 0.910 0.415
vn 0.000 0.826 0.564
vn 0.000 0.826 0.564
vn 0.000 0.718 0.696
vn 0.000 0.718 0.696
vn 0.000 0.589 0.808
vn 0.000 0.589 0.808
vn 0.000 0.442 0.897
vn 0.000 0.442 0.897
vn 0.000 0.283 0.959
vn 0.000 0.283 0.959
vn 0.000 -0.996 0.085
vn 0.000 -0.996 0.085
vn 0.000 -0.967 0.254
vn 0.000 -0.967 0.254
vn 0.000 -0.910 0.415
vn 0.000 -0.910 0.415
vn 0.000 -0.826 0.564
vn 0.000 -0.826 0.564
vn 0.000 -0.718 0.696
vn 0.000 -0.718 0.696
vn 0.000 -0.589 0.808
vn 0.000 -0.589 0.808
vn 0.000 -0.442 0.897
vn 0.000 -0.442 0.897
vn 0.000 -0.283 0.959
vn 0.000 -0.283 0.959
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.966 0.259
vn 0.000 -0.966 0.259
vn 0.000 -0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 0.966 0.259
vn 0.000 0.966 0.259
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.998 -0.058
vn 0.000 0.998 -0.058
vn 0.000 0.985 -0.174
vn 0.000 0.985 -0.174
vn 0.000 0.958 -0.287
vn 0.000 0.958 -0.287
vn 0.000 0.961 0.276
vn 0.000 0.961 0.276
vn 0.000 0.990 0.139
vn 0.000 0.990 0.139
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.990 -0.139
vn 0.000 0.990 -0.139
vn 0.000 0.961 -0.276
vn 0.000 0.961 -0.276
vn 0.000 0.963 0.271
vn 0.000 0.963 0.271
vn 0.000 0.992 0.125
vn 0.000 0.992 0.125
vn 0.000 1.000 -0.024
vn 0.000 1.000 -0.024
vn 0.000 0.985 -0.172
vn 0.000 0.985 -0.172
vn 0.000 0.948 -0.317
vn 0.000 0.948 -0.317
vn 0.000 0.958 0.287
vn 0.000 0.958 0.287
vn 0.000 0.985 0.174
vn 0.000 0.985 0.174
vn 0.000 0.998 0.058
vn 0.000 0.998 0.058
vn 0.000 0.417 0.909
vn 0.000 0.417 0.909
vn 0.000 0.558 0.830
vn 0.000 0.558 0.830
vn 0.000 0.684 0.729
vn 0.000 0.684 0.729
vn 0.000 0.793 0.609
vn 0.000 0.793 0.609
vn 0.000 0.881 0.473
vn 0.000 0.881 0.473
vn 0.000 0.946 0.325
vn 0.000 0.946 0.325
vn 0.000 0.986 0.169
vn 0.000 0.986 0.169
vn 0.000 1.000 0.008
vn 0.000 1.000 0.008
vn 0.000 0.988 -0.154
vn 0.000 0.988 -0.154
vn 0.000 0.950 -0.311
vn 0.000 0.950 -0.311
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 -0.958 -0.287
vn 0.000 -0.958 -0.287
vn 0.000 -0.985 -0.174
vn 0.000 -0.985 -0.174
vn 0.000 -0.998 -0.058
vn 0.000 -0.998 -0.058
vn 0.000 -0.961 -0.276
vn 0.000 -0.961 -0.276
vn 0.000 -0.990 -0.139
vn 0.000 -0.990 -0.139
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.990 0.139
vn 0.000 -0.990 0.139
vn 0.000 -0.961 0.276
vn 0.000 -0.961 0.276
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 -0.950 -0.311
vn 0.000 -0.950 -0.311
vn 0.000 -0.988 -0.154
vn 0.000 -0.988 -0.154
vn 0.000 -1.000 0.008
vn 0.000 -1.000 0.008
vn 0.000 -0.986 0.169
vn 0.000 -0.986 0.169
vn 0.000 -0.946 0.325
vn 0.000 -0.946 0.325
vn 0.000 -0.881 0.473
vn 0.000 -0.881 0.473
vn 0.000 -0.793 0.609
vn 0.000 -0.793 0.609
vn 0.000 -0.684 0.729
vn 0.000 -0.684 0.729
vn 0.000 -0.558 0.830
vn 0.000 -0.558 0.830
vn 0.000 -0.417 0.909
vn 0.000 -0.417 0.909
vn 0.000 -0.948 -0.317
vn 0.000 -0.948 -0.317
vn 0.000 -0.985 -0.172
vn 0.000 -0.985 -0.172
vn 0.000 -1.000 -0.024
vn 0.000 -1.000 -0.024
vn 0.000 -0.992 0.125
vn 0.000 -0.992 0.125
vn 0.000 -0.963 0.271
vn 0.000 -0.963 0.271
vn 0.000 -0.998 0.058
vn 0.000 -0.998 0.058
vn 0.000 -0.985 0.174
vn 0.000 -0.985 0.174
vn 0.000 -0.958 0.287
vn 0.000 -0.958 0.287
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.940 -0.342
vn 0.000 0.940 -0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 -0.342 0.940
vn 0.000 -0.342 0.940
vn 0.000 -0.922 -0.387
vn 0.000 -0.922 -0.387
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.922 -0.387
vn 0.000 0.922 -0.387
vn 0.000 0.342 0.940
vn 0.000 0.342 0.940
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 -0.342
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 0.996 0.085
vn 0.000 0.996 0.085
vn 0.000 0.967 0.254
vn 0.000 0.967 0.254
vn 0.000 0.910 0.415
vn 0.000 0.910 0.415
vn 0.000 0.826 0.564
vn 0.000 0.826 0.564
vn 0.000 0.718 0.696
vn 0.000 0.718 0.696
vn 0.000 0.589 0.808
vn 0.000 0.589 0.808
vn 0.000 0.442 0.897
vn 0.000 0.442 0.897
vn 0.000 0.283 0.959
vn 0.000 0.283 0.959
vn 0.000 -0.996 0.085
vn 0.000 -0.996 0.085
vn 0.000 -0.967 0.254
vn 0.000 -0.967 0.254
vn 0.000 -0.910 0.415
vn 0.000 -0.910 0.415
vn 0.000 -0.826 0.564
vn 0.000 -0.826 0.564
vn 0.000 -0.718 0.696
vn 0.000 -0.718 0.696
vn 0.000 -0.589 0.808
vn 0.000 -0.589 0.808
vn 0.000 -0.442 0.897
vn 0.000 -0.442 0.897
vn 0.000 -0.283 0.959
vn 0.000 -0.283 0.959
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.966 0.259
vn 0.000 -0.966 0.259
vn 0.000 -0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 0.966 0.259
vn 0.000 0.966 0.259
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.958 -0.287
vn 0.000 0.958 -0.287
vn 0.000 0.985 -0.174
vn 0.000 0.985 -0.174
vn 0.000 0.998 -0.058
vn 0.000 0.998 -0.058
vn 0.000 0.961 -0.276
vn 0.000 0.961 -0.276
vn 0.000 0.990 -0.139
vn 0.000 0.990 -0.139
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.990 0.139
vn 0.000 0.990 0.139
vn 0.000 0.961 0.276
vn 0.000 0.961 0.276
vn 0.000 0.948 -0.317
vn 0.000 0.948 -0.317
vn 0.000 0.985 -0.172
vn 0.000 0.985 -0.172
vn 0.000 1.000 -0.024
vn 0.000 1.000 -0.024
vn 0.000 0.992 0.125
vn 0.000 0.992 0.125
vn 0.000 0.963 0.271
vn 0.000 0.963 0.271
vn 0.000 0.998 0.058
vn 0.000 0.998 0.058
vn 0.000 0.985 0.174
vn 0.000 0.985 0.174
vn 0.000 0.958 0.287
vn 0.000 0.958 0.287
vn 0.000 0.950 -0.311
vn 0.000 0.950 -0.311
vn 0.000 0.988 -0.154
vn 0.000 0.988 -0.154
vn 0.000 1.000 0.008
vn 0.000 1.000 0.008
vn 0.000 0.986 0.169
vn 0.000 0.986 0.169
vn 0.000 0.946 0.325
vn 0.000 0.946 0.325
vn 0.000 0.881 0.473
vn 0.000 0.881 0.473
vn 0.000 0.793 0.609
vn 0.000 0.793 0.609
vn 0.000 0.684 0.729
vn 0.000 0.684 0.729
vn 0.000 0.558 0.830
vn 0.000 0.558 0.830
vn 0.000 0.417 0.909
vn 0.000 0.417 0.909
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.998 -0.058
vn 0.000 -0.998 -0.058
vn 0.000 -0.985 -0.174
vn 0.000 -0.985 -0.174
vn 0.000 -0.958 -0.287
vn 0.000 -0.958 -0.287
vn 0.000 -0.961 0.276
vn 0.000 -0.961 0.276
vn 0.000 -0.990 0.139
vn 0.000 -0.990 0.139
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.990 -0.139
vn 0.000 -0.990 -0.139
vn 0.000 -0.961 -0.276
vn 0.000 -0.961 -0.276
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.417 0.909
vn 0.000 -0.417 0.909
vn 0.000 -0.558 0.830
vn 0.000 -0.558 0.830
vn 0.000 -0.684 0.729
vn 0.000 -0.684 0.729
vn 0.000 -0.793 0.609
vn 0.000 -0.793 0.609
vn 0.000 -0.881 0.473
vn 0.000 -0.881 0.473
vn 0.000 -0.946 0.325
vn 0.000 -0.946 0.325
vn 0.000 -0.986 0.169
vn 0.000 -0.986 0.169
vn 0.000 -1.000 0.008
vn 0.000 -1.000 0.008
vn 0.000 -0.988 -0.154
vn 0.000 -0.988 -0.154
vn 0.000 -0.950 -0.311
vn 0.000 -0.950 -0.311
vn 0.000 -0.963 0.271
vn 0.000 -0.963 0.271
vn 0.000 -0.992 0.125
vn 0.000 -0.992 0.125
vn 0.000 -1.000 -0.024
vn 0.000 -1.000 -0.024
vn 0.000 -0.985 -0.172
vn 0.000 -0.985 -0.172
vn 0.000 -0.948 -0.317
vn 0.000 -0.948 -0.317
vn 0.000 -0.958 0.287
vn 0.000 -0.958 0.287
vn 0.000 -0.985 0.174
vn 0.000 -0.985 0.174
vn 0.000 -0.998 0.058
vn 0.000 -0.998 0.058
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 0.342 0.940
vn 0.000 0.342 0.940
vn 0.000 0.922 -0.387
vn 0.000 0.922 -0.387
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.922 -0.387
vn 0.000 -0.922 -0.387
vn 0.000 -0.342 0.940
vn 0.000 -0.342 0.940
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 -0.342
vn 0.000 0.940 -0.342
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -0.996 0.085
vn 0.000 -0.996 0.085
vn 0.000 -0.967 0.254
vn 0.000 -0.967 0.254
vn 0.000 -0.910 0.415
vn 0.000 -0.910 0.415
vn 0.000 -0.826 0.564
vn 0.000 -0.826 0.564
vn 0.000 -0.718 0.696
vn 0.000 -0.718 0.696
vn 0.000 -0.589 0.808
vn 0.000 -0.589 0.808
vn 0.000 -0.442 0.897
vn 0.000 -0.442 0.897
vn 0.000 -0.283 0.959
vn 0.000 -0.283 0.959
vn 0.000 0.996 0.085
vn 0.000 0.996 0.085
vn 0.000 0.967 0.254
vn 0.000 0.967 0.254
vn 0.000 0.910 0.415
vn 0.000 0.910 0.415
vn 0.000 0.826 0.564
vn 0.000 0.826 0.564
vn 0.000 0.718 0.696
vn 0.000 0.718 0.696
vn 0.000 0.589 0.808
vn 0.000 0.589 0.808
vn 0.000 0.442 0.897
vn 0.000 0.442 0.897
vn 0.000 0.283 0.959
vn 0.000 0.283 0.959
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.966 0.259
vn 0.000 0.966 0.259
vn 0.000 0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 -0.966 0.259
vn 0.000 -0.966 0.259
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.958 -0.287
vn 0.000 -0.958 -0.287
vn 0.000 -0.985 -0.174
vn 0.000 -0.985 -0.174
vn 0.000 -0.998 -0.058
vn 0.000 -0.998 -0.058
vn 0.000 -0.961 -0.276
vn 0.000 -0.961 -0.276
vn 0.000 -0.990 -0.139
vn 0.000 -0.990 -0.139
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.990 0.139
vn 0.000 -0.990 0.139
vn 0.000 -0.961 0.276
vn 0.000 -0.961 0.276
vn 0.000 -0.948 -0.317
vn 0.000 -0.948 -0.317
vn 0.000 -0.985 -0.172
vn 0.000 -0.985 -0.172
vn 0.000 -1.000 -0.024
vn 0.000 -1.000 -0.024
vn 0.000 -0.992 0.125
vn 0.000 -0.992 0.125
vn 0.000 -0.963 0.271
vn 0.000 -0.963 0.271
vn 0.000 -0.998 0.058
vn 0.000 -0.998 0.058
vn 0.000 -0.985 0.174
vn 0.000 -0.985 0.174
vn 0.000 -0.958 0.287
vn 0.000 -0.958 0.287
vn 0.000 -0.950 -0.311
vn 0.000 -0.950 -0.311
vn 0.000 -0.988 -0.154
vn 0.000 -0.988 -0.154
vn 0.000 -1.000 0.008
vn 0.000 -1.000 0.008
vn 0.000 -0.986 0.169
vn 0.000 -0.986 0.169
vn 0.000 -0.946 0.325
vn 0.000 -0.946 0.325
vn 0.000 -0.881 0.473
vn 0.000 -0.881 0.473
vn 0.000 -0.793 0.609
vn 0.000 -0.793 0.609
vn 0.000 -0.684 0.729
vn 0.000 -0.684 0.729
vn 0.000 -0.558 0.830
vn 0.000 -0.558 0.830
vn 0.000 -0.417 0.909
vn 0.000 -0.417 0.909
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.998 -0.058
vn 0.000 0.998 -0.058
vn 0.000 0.985 -0.174
vn 0.000 0.985 -0.174
vn 0.000 0.958 -0.287
vn 0.000 0.958 -0.287
vn 0.000 0.961 0.276
vn 0.000 0.961 0.276
vn 0.000 0.990 0.139
vn 0.000 0.990 0.139
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.990 -0.139
vn 0.000 0.990 -0.139
vn 0.000 0.961 -0.276
vn 0.000 0.961 -0.276
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.417 0.909
vn 0.000 0.417 0.909
vn 0.000 0.558 0.830
vn 0.000 0.558 0.830
vn 0.000 0.684 0.729
vn 0.000 0.684 0.729
vn 0.000 0.793 0.609
vn 0.000 0.793 0.609
vn 0.000 0.881 0.474
vn 0.000 0.881 0.474
vn 0.000 0.946 0.325
vn 0.000 0.946 0.325
vn 0.000 0.986 0.169
vn 0.000 0.986 0.169
vn 0.000 1.000 0.008
vn 0.000 1.000 0.008
vn 0.000 0.988 -0.154
vn 0.000 0.988 -0.154
vn 0.000 0.950 -0.311
vn 0.000 0.950 -0.311
vn 0.000 0.963 0.271
vn 0.000 0.963 0.271
vn 0.000 0.992 0.125
vn 0.000 0.992 0.125
vn 0.000 1.000 -0.024
vn 0.000 1.000 -0.024
vn 0.000 0.985 -0.172
vn 0.000 0.985 -0.172
vn 0.000 0.948 -0.317
vn 0.000 0.948 -0.317
vn 0.000 0.958 0.287
vn 0.000 0.958 0.287
vn 0.000 0.985 0.174
vn 0.000 0.985 0.174
vn 0.000 0.998 0.058
vn 0.000 0.998 0.058
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.940 -0.342
vn 0.000 0.940 -0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 -0.342 0.940
vn 0.000 -0.342 0.940
vn 0.000 -0.922 -0.387
vn 0.000 -0.922 -0.387
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.922 -0.387
vn 0.000 0.922 -0.387
vn 0.000 0.342 0.940
vn 0.000 0.342 0.940
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 -0.342
vn 0.000 -0.940 -0.342
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -0.996 0.085
vn 0.000 -0.996 0.085
vn 0.000 -0.967 0.254
vn 0.000 -0.967 0.254
vn 0.000 -0.910 0.415
vn 0.000 -0.910 0.415
vn 0.000 -0.826 0.564
vn 0.000 -0.826 0.564
vn 0.000 -0.718 0.696
vn 0.000 -0.718 0.696
vn 0.000 -0.589 0.808
vn 0.000 -0.589 0.808
vn 0.000 -0.442 0.897
vn 0.000 -0.442 0.897
vn 0.000 -0.283 0.959
vn 0.000 -0.283 0.959
vn 0.000 0.996 0.085
vn 0.000 0.996 0.085
vn 0.000 0.967 0.254
vn 0.000 0.967 0.254
vn 0.000 0.910 0.415
vn 0.000 0.910 0.415
vn 0.000 0.826 0.564
vn 0.000 0.826 0.564
vn 0.000 0.718 0.696
vn 0.000 0.718 0.696
vn 0.000 0.589 0.808
vn 0.000 0.589 0.808
vn 0.000 0.442 0.897
vn 0.000 0.442 0.897
vn 0.000 0.283 0.959
vn 0.000 0.283 0.959
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.966 0.259
vn 0.000 0.966 0.259
vn 0.000 0.996 0.087
vn 0.000 0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 -0.996 0.087
vn 0.000 -0.966 0.259
vn 0.000 -0.966 0.259
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.998 -0.058
vn 0.000 -0.998 -0.058
vn 0.000 -0.985 -0.174
vn 0.000 -0.985 -0.174
vn 0.000 -0.958 -0.287
vn 0.000 -0.958 -0.287
vn 0.000 -0.961 0.276
vn 0.000 -0.961 0.276
vn 0.000 -0.990 0.139
vn 0.000 -0.990 0.139
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -0.990 -0.139
vn 0.000 -0.990 -0.139
vn 0.000 -0.961 -0.276
vn 0.000 -0.961 -0.276
vn 0.000 -0.963 0.271
vn 0.000 -0.963 0.271
vn 0.000 -0.992 0.125
vn 0.000 -0.992 0.125
vn 0.000 -1.000 -0.024
vn 0.000 -1.000 -0.024
vn 0.000 -0.985 -0.172
vn 0.000 -0.985 -0.172
vn 0.000 -0.948 -0.317
vn 0.000 -0.948 -0.317
vn 0.000 -0.958 0.287
vn 0.000 -0.958 0.287
vn 0.000 -0.985 0.174
vn 0.000 -0.985 0.174
vn 0.000 -0.998 0.058
vn 0.000 -0.998 0.058
vn 0.000 -0.417 0.909
vn 0.000 -0.417 0.909
vn 0.000 -0.558 0.830
vn 0.000 -0.558 0.830
vn 0.000 -0.684 0.729
vn 0.000 -0.684 0.729
vn 0.000 -0.793 0.609
vn 0.000 -0.793 0.609
vn 0.000 -0.881 0.473
vn 0.000 -0.881 0.473
vn 0.000 -0.946 0.325
vn 0.000 -0.946 0.325
vn 0.000 -0.986 0.169
vn 0.000 -0.986 0.169
vn 0.000 -1.000 0.008
vn 0.000 -1.000 0.008
vn 0.000 -0.988 -0.154
vn 0.000 -0.988 -0.154
vn 0.000 -0.950 -0.311
vn 0.000 -0.950 -0.311
vn 0.000 0.906 0.423
vn 0.000 0.906 0.423
vn 0.000 0.819 0.574
vn 0.000 0.819 0.574
vn 0.000 0.707 0.707
vn 0.000 0.707 0.707
vn 0.000 0.574 0.819
vn 0.000 0.574 0.819
vn 0.000 0.423 0.906
vn 0.000 0.423 0.906
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 0.958 -0.287
vn 0.000 0.958 -0.287
vn 0.000 0.985 -0.174
vn 0.000 0.985 -0.174
vn 0.000 0.998 -0.058
vn 0.000 0.998 -0.058
vn 0.000 0.961 -0.276
vn 0.000 0.961 -0.276
vn 0.000 0.990 -0.139
vn 0.000 0.990 -0.139
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.990 0.139
vn 0.000 0.990 0.139
vn 0.000 0.961 0.276
vn 0.000 0.961 0.276
vn 0.000 0.259 0.966
vn 0.000 0.259 0.966
vn 0.000 0.087 0.996
vn 0.000 0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.087 0.996
vn 0.000 -0.259 0.966
vn 0.000 -0.259 0.966
vn 0.000 -0.423 0.906
vn 0.000 -0.423 0.906
vn 0.000 -0.574 0.819
vn 0.000 -0.574 0.819
vn 0.000 -0.707 0.707
vn 0.000 -0.707 0.707
vn 0.000 -0.819 0.574
vn 0.000 -0.819 0.574
vn 0.000 -0.906 0.423
vn 0.000 -0.906 0.423
vn 0.000 0.950 -0.311
vn 0.000 0.950 -0.311
vn 0.000 0.988 -0.154
vn 0.000 0.988 -0.154
vn 0.000 1.000 0.008
vn 0.000 1.000 0.008
vn 0.000 0.986 0.169
vn 0.000 0.986 0.169
vn 0.000 0.946 0.325
vn 0.000 0.946 0.325
vn 0.000 0.881 0.474
vn 0.000 0.881 0.474
vn 0.000 0.793 0.609
vn 0.000 0.793 0.609
vn 0.000 0.684 0.729
vn 0.000 0.684 0.729
vn 0.000 0.558 0.830
vn 0.000 0.558 0.830
vn 0.000 0.417 0.909
vn 0.000 0.417 0.909
vn 0.000 0.948 -0.317
vn 0.000 0.948 -0.317
vn 0.000 0.985 -0.172
vn 0.000 0.985 -0.172
vn 0.000 1.000 -0.024
vn 0.000 1.000 -0.024
vn 0.000 0.992 0.125
vn 0.000 0.992 0.125
vn 0.000 0.963 0.271
vn 0.000 0.963 0.271
vn 0.000 0.998 0.058
vn 0.000 0.998 0.058
vn 0.000 0.985 0.174
vn 0.000 0.985 0.174
vn 0.000 0.958 0.287
vn 0.000 0.958 0.287
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn 0.000 0.940 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn -0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.940 0.000 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn 0.000 -0.940 0.342
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.707 -0.707 0.000
vn 0.961 0.000 0.276
vn 0.144 -0.950 0.276
vn 0.144 -0.950 0.276
vn 0.144 -0.950 0.276
vn 0.143 -0.950 0.276
vn -0.139 -0.951 0.276
vn -0.139 -0.951 0.276
vn 0.021 -0.961 0.276
vn 0.021 -0.961 0.276
vn -0.170 0.946 0.276
vn -0.170 0.946 0.276
vn 0.176 0.945 0.276
vn 0.176 0.945 0.276
vn -0.112 0.955 0.276
vn -0.112 0.955 0.276
vn -0.112 0.955 0.276
vn -0.112 0.955 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.167 0.947 0.276
vn 0.167 0.947 0.276
vn -0.315 0.908 0.276
vn -0.315 0.908 0.276
vn 0.000 0.000 1.000
vn 0.139 0.951 0.276
vn 0.139 0.951 0.276
vn 0.140 0.951 0.276
vn -0.256 0.926 0.276
vn -0.256 0.926 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.188 0.943 0.276
vn 0.188 0.943 0.276
vn 0.188 0.943 0.276
vn 0.188 0.943 0.276
vn -0.182 0.944 0.276
vn -0.182 0.944 0.276
vn -0.132 0.952 0.276
vn -0.132 0.952 0.276
vn -0.132 0.952 0.276
vn 0.000 0.000 1.000
vn 0.087 0.957 0.276
vn 0.087 0.957 0.276
vn -0.124 0.953 0.276
vn -0.124 0.953 0.276
vn 0.000 0.000 1.000
vn 0.238 0.931 0.276
vn 0.238 0.931 0.276
vn 0.238 0.931 0.276
vn -0.140 0.951 0.276
vn -0.140 0.951 0.276
vn 0.000 0.000 1.000
vn -0.165 0.947 0.276
vn -0.165 0.947 0.276
vn -0.165 0.947 0.276
vn 0.128 0.953 0.276
vn 0.128 0.953 0.276
vn 0.000 0.000 1.000
vn -0.237 -0.932 0.276
vn -0.237 -0.932 0.276
vn 0.165 -0.947 0.275
vn 0.165 -0.947 0.276
vn 0.165 -0.947 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.205 0.939 0.276
vn -0.205 0.939 0.276
vn 0.180 0.944 0.276
vn 0.180 0.944 0.276
vn 0.180 0.944 0.276
vn 0.562 0.780 0.276
vn -0.137 -0.951 0.276
vn -0.137 -0.951 0.276
vn 0.162 -0.948 0.276
vn 0.162 -0.948 0.276
vn -0.091 -0.957 0.276
vn -0.091 -0.957 0.276
vn 0.054 -0.960 0.276
vn 0.054 -0.960 0.276
vn 0.054 -0.960 0.276
vn 0.054 -0.960 0.276
vn 0.000 0.000 1.000
vn -0.147 -0.950 0.276
vn -0.147 -0.950 0.276
vn 0.146 -0.950 0.276
vn 0.146 -0.950 0.276
vn 0.146 -0.950 0.276
vn 0.000 0.000 1.000
vn 0.183 -0.944 0.276
vn 0.183 -0.944 0.276
vn -0.220 -0.936 0.276
vn -0.220 -0.936 0.276
vn -0.088 0.957 0.276
vn -0.088 0.957 0.276
vn -0.088 0.957 0.276
vn -0.088 0.957 0.276
vn 0.000 0.000 1.000
vn -0.959 0.062 0.276
vn 0.062 0.959 0.276
vn 0.061 0.959 0.276
vn 0.061 0.959 0.276
vn 0.061 0.959 0.276
vn 0.061 0.959 0.276
vn 0.061 0.959 0.276
vn 0.000 0.961 0.276
vn 0.000 0.961 0.276
vn 0.106 0.955 0.276
vn 0.106 0.955 0.276
vn 0.106 0.955 0.276
vn 0.106 0.955 0.276
vn 0.106 0.955 0.276
vn -0.035 -0.961 0.276
vn -0.035 -0.961 0.276
vn -0.035 -0.961 0.276
vn -0.035 -0.961 0.276
vn -0.035 -0.961 0.276
vn -0.035 -0.961 0.276
vn 0.000 0.000 0.000
vn 0.088 -0.957 0.276
vn 0.088 -0.957 0.276
vn 0.088 -0.957 0.276
vn -0.079 -0.958 0.276
vn -0.079 -0.958 0.276
vn -0.079 -0.958 0.276
vn -0.079 -0.958 0.276
vn -0.079 -0.958 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.011 0.961 0.276
vn 0.011 0.961 0.276
vn 0.011 0.961 0.276
vn -0.075 0.958 0.276
vn -0.075 0.958 0.276
vn -0.075 0.958 0.276
vn -0.075 0.958 0.276
vn -0.075 0.958 0.276
vn 0.205 0.939 0.276
vn 0.205 0.939 0.276
vn -0.088 -0.957 0.276
vn -0.088 -0.957 0.276
vn 0.070 -0.959 0.276
vn 0.070 -0.959 0.276
vn 0.070 -0.959 0.276
vn 0.070 -0.959 0.276
vn 0.070 -0.959 0.276
vn -0.083 -0.958 0.276
vn -0.083 -0.958 0.276
vn -0.083 -0.958 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.046 -0.960 0.276
vn -0.046 -0.960 0.276
vn -0.046 -0.960 0.276
vn -0.046 -0.960 0.276
vn -0.067 0.959 0.276
vn -0.067 0.959 0.276
vn -0.067 0.959 0.276
vn -0.067 0.959 0.276
vn 0.111 0.955 0.276
vn 0.111 0.955 0.276
vn 0.000 -0.961 0.276
vn 0.000 -0.961 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn -0.061 0.959 -0.276
vn -0.061 0.959 -0.276
vn 0.147 -0.950 -0.276
vn 0.147 -0.950 -0.276
vn -0.961 0.000 -0.276
vn 0.961 0.000 0.276
vn -0.115 -0.954 0.276
vn -0.115 -0.954 0.276
vn -0.115 -0.954 0.276
vn -0.115 -0.954 0.276
vn -0.115 -0.954 0.276
vn -0.115 -0.954 0.276
vn 0.061 -0.959 0.276
vn -0.147 0.950 0.276
vn -0.147 0.950 0.276
vn 0.115 0.954 0.276
vn 0.115 0.954 0.276
vn 0.115 0.954 0.276
vn 0.115 0.954 0.276
vn 0.115 0.954 0.276
vn 0.000 0.000 1.000
vn 0.131 0.952 0.276
vn 0.131 0.952 0.276
vn 0.131 0.952 0.276
vn -0.011 0.961 0.276
vn -0.011 0.961 0.276
vn -0.011 0.961 0.276
vn -0.011 0.961 0.276
vn 0.121 -0.954 0.276
vn 0.121 -0.954 0.276
vn 0.121 -0.954 0.276
vn 0.121 -0.954 0.276
vn -0.106 -0.955 0.276
vn -0.106 -0.955 0.276
vn 0.245 -0.930 0.276
vn 0.245 -0.930 0.276
vn 0.245 -0.930 0.276
vn -0.108 -0.955 0.276
vn -0.108 -0.955 0.276
vn -0.033 0.961 0.276
vn -0.033 0.961 0.276
vn -0.211 0.938 0.276
vn -0.211 0.938 0.276
vn -0.034 -0.961 0.276
vn -0.034 -0.961 0.276
vn -0.135 0.952 0.276
vn -0.135 0.952 0.276
vn -0.135 0.952 0.276
vn -0.135 0.952 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.045 0.960 0.276
vn -0.045 0.960 0.276
vn 0.130 -0.952 0.276
vn 0.130 -0.952 0.276
vn -0.201 -0.940 0.276
vn -0.201 -0.940 0.276
vn -0.201 -0.940 0.276
vn -0.201 -0.940 0.276
vn 0.049 -0.960 0.276
vn 0.049 -0.960 0.276
vn 0.049 -0.960 0.276
vn -0.173 0.946 0.276
vn -0.173 0.946 0.276
vn -0.173 0.946 0.276
vn -0.173 0.946 0.276
vn 0.233 0.933 0.276
vn 0.233 0.933 0.276
vn 0.233 0.933 0.276
vn 0.233 0.933 0.276
vn 0.233 0.933 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.066 0.959 0.276
vn -0.066 0.959 0.276
vn 0.154 -0.949 0.276
vn 0.154 -0.949 0.276
vn -0.116 -0.954 0.276
vn -0.116 -0.954 0.276
vn 0.026 0.961 0.276
vn 0.026 0.961 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.011 0.961 0.276
vn -0.011 0.961 0.276
vn -0.034 0.961 0.276
vn -0.034 0.961 0.276
vn -0.034 0.961 0.276
vn 0.123 -0.953 0.276
vn 0.123 -0.953 0.276
vn 0.123 -0.953 0.276
vn -0.079 -0.958 0.276
vn -0.079 -0.958 0.276
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 0.074 0.997
vn 0.035 0.225 0.974
vn 0.000 0.997 0.079
vn 0.000 0.997 0.079
vn 0.000 0.972 0.235
vn 0.000 0.972 0.235
vn 0.000 0.972 0.235
vn 0.000 0.972 0.235
vn 0.000 0.923 0.385
vn 0.000 0.923 0.385
vn 0.000 0.923 0.385
vn 0.000 0.923 0.385
vn 0.000 0.851 0.525
vn 0.000 0.851 0.525
vn 0.000 0.851 0.525
vn 0.000 0.851 0.525
vn 0.000 0.758 0.653
vn 0.000 0.758 0.653
vn 0.000 0.758 0.653
vn 0.000 0.758 0.653
vn 0.000 0.646 0.764
vn 0.000 0.646 0.764
vn 0.000 0.646 0.764
vn 0.000 0.646 0.764
vn 0.000 0.517 0.856
vn 0.000 0.517 0.856
vn 0.000 0.517 0.856
vn 0.000 0.517 0.856
vn 0.000 0.376 0.927
vn 0.000 0.376 0.927
vn 0.000 0.376 0.927
vn 0.000 0.376 0.927
vn 0.000 0.226 0.974
vn 0.000 0.226 0.974
vn 0.000 0.226 0.974
vn 0.000 0.096 0.995
vn 0.000 0.074 0.997
vn 0.000 -0.087 0.996
vn 0.000 -0.997 0.079
vn 0.000 -0.996 0.087
vn 0.000 -0.972 0.235
vn 0.000 -0.972 0.235
vn 0.000 -0.966 0.259
vn 0.000 -0.923 0.385
vn 0.000 -0.923 0.385
vn 0.000 -0.906 0.423
vn 0.000 -0.851 0.525
vn 0.000 -0.851 0.525
vn 0.000 -0.819 0.574
vn 0.000 -0.758 0.653
vn 0.000 -0.758 0.653
vn 0.000 -0.707 0.707
vn 0.000 -0.645 0.764
vn 0.000 -0.646 0.764
vn 0.000 -0.574 0.819
vn 0.000 -0.517 0.856
vn 0.000 -0.423 0.906
vn -0.001 -0.517 0.856
vn 0.000 -0.376 0.927
vn 0.000 -0.259 0.966
vn -0.001 -0.376 0.927
vn 0.000 -0.226 0.974
vn 0.000 -0.088 0.996
vn -0.002 -0.226 0.974
vn 0.000 -0.074 0.997
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.087 0.375 0.923
vn 0.087 0.515 0.853
vn 0.087 0.643 0.761
vn 0.087 0.755 0.650
vn 0.087 0.848 0.523
vn 0.706 0.457 0.541
vn 0.996 0.074 0.046
vn 0.880 0.312 0.358
vn 0.187 0.132 0.973
vn 0.222 0.143 0.965
vn 0.258 0.363 0.895
vn 0.259 0.281 0.924
vn 0.310 0.215 0.926
vn 0.222 0.143 0.965
vn 0.222 0.143 0.965
vn 0.593 0.281 0.755
vn 0.593 0.281 0.755
vn 0.550 0.353 0.757
vn 0.597 0.428 0.678
vn 0.706 0.378 0.598
vn 0.800 0.379 0.466
vn 0.799 0.379 0.466
vn 0.948 0.242 0.207
vn 0.906 0.274 0.323
vn 0.906 0.321 0.276
vn 0.993 0.100 0.069
vn 0.992 0.101 0.070
vn 0.996 0.064 0.061
vn 1.000 0.010 0.008
vn 0.996 0.081 0.034
vn 0.996 0.074 0.046
vn 0.966 0.221 0.136
vn 0.966 0.203 0.161
vn 0.972 0.177 0.152
vn 0.968 0.098 0.232
vn 0.948 0.242 0.207
vn 0.996 0.085 0.021
vn 0.996 0.081 0.034
vn 0.966 0.240 0.100
vn 0.966 0.221 0.136
vn 0.906 0.360 0.223
vn 0.906 0.321 0.277
vn 0.818 0.436 0.375
vn 0.819 0.377 0.434
vn 0.827 0.363 0.430
vn 0.806 0.285 0.520
vn 0.806 0.285 0.519
vn 0.996 0.087 0.007
vn 0.996 0.085 0.021
vn 0.966 0.252 0.061
vn 0.966 0.240 0.100
vn 0.906 0.391 0.163
vn 0.906 0.360 0.223
vn 0.818 0.489 0.302
vn 0.818 0.435 0.375
vn 0.706 0.537 0.462
vn 0.706 0.457 0.541
vn 0.572 0.529 0.626
vn 0.572 0.424 0.702
vn 0.422 0.469 0.776
vn 0.422 0.376 0.825
vn 0.470 0.332 0.818
vn 0.437 0.281 0.855
vn 0.437 0.281 0.855
vn 0.422 0.904 0.072
vn 0.258 0.963 0.076
vn 0.258 0.963 0.076
vn 0.258 0.939 0.227
vn 0.087 0.993 0.079
vn 0.087 0.993 0.079
vn 0.087 0.968 0.234
vn 0.087 0.968 0.234
vn 0.087 0.968 0.234
vn 0.087 0.919 0.384
vn 0.087 0.919 0.383
vn 0.087 0.919 0.383
vn 0.258 0.892 0.372
vn 0.258 0.939 0.227
vn 0.421 0.881 0.213
vn 0.421 0.904 0.072
vn 0.572 0.817 0.065
vn 0.087 0.848 0.523
vn 0.087 0.848 0.523
vn 0.258 0.822 0.508
vn 0.258 0.892 0.372
vn 0.422 0.837 0.349
vn 0.422 0.881 0.213
vn 0.572 0.797 0.193
vn 0.572 0.817 0.065
vn 0.706 0.706 0.056
vn 0.087 0.755 0.650
vn 0.087 0.755 0.650
vn 0.258 0.732 0.631
vn 0.258 0.822 0.508
vn 0.422 0.772 0.476
vn 0.422 0.837 0.349
vn 0.572 0.757 0.316
vn 0.572 0.797 0.193
vn 0.706 0.688 0.166
vn 0.706 0.706 0.056
vn 0.818 0.573 0.045
vn 0.087 0.643 0.761
vn 0.087 0.643 0.761
vn 0.258 0.624 0.738
vn 0.258 0.732 0.631
vn 0.422 0.687 0.592
vn 0.422 0.772 0.476
vn 0.572 0.698 0.431
vn 0.572 0.757 0.316
vn 0.706 0.654 0.273
vn 0.706 0.688 0.166
vn 0.818 0.559 0.135
vn 0.818 0.573 0.045
vn 0.906 0.422 0.033
vn 0.087 0.515 0.853
vn 0.087 0.515 0.853
vn 0.258 0.500 0.827
vn 0.258 0.624 0.738
vn 0.422 0.585 0.693
vn 0.422 0.687 0.592
vn 0.572 0.621 0.535
vn 0.572 0.698 0.431
vn 0.706 0.603 0.372
vn 0.706 0.654 0.273
vn 0.818 0.531 0.221
vn 0.818 0.559 0.135
vn 0.906 0.412 0.099
vn 0.906 0.422 0.033
vn 0.966 0.259 0.020
vn 0.087 0.375 0.923
vn 0.087 0.375 0.923
vn 0.258 0.363 0.895
vn 0.258 0.500 0.827
vn 0.422 0.469 0.776
vn 0.422 0.585 0.693
vn 0.572 0.529 0.626
vn 0.572 0.621 0.535
vn 0.706 0.537 0.462
vn 0.706 0.603 0.372
vn 0.818 0.489 0.302
vn 0.818 0.531 0.221
vn 0.906 0.391 0.163
vn 0.906 0.412 0.099
vn 0.966 0.252 0.061
vn 0.966 0.259 0.021
vn 0.996 0.087 0.007
vn 0.087 0.225 0.971
vn 0.087 0.225 0.971
vn 0.087 0.225 0.971
vn 0.087 0.074 0.993
vn 0.104 0.074 0.992
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.423 0.906 0.000
vn 0.423 0.906 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.087 0.996 0.000
vn 0.087 0.996 0.000
vn 0.078 0.087 0.993
vn 0.078 0.087 0.993
vn 0.233 0.087 0.969
vn 0.233 0.087 0.969
vn 0.233 0.087 0.969
vn 0.381 0.087 0.920
vn 0.381 0.087 0.920
vn 0.381 0.087 0.920
vn 0.521 0.087 0.849
vn 0.521 0.087 0.849
vn 0.521 0.087 0.849
vn 0.647 0.087 0.758
vn 0.647 0.087 0.758
vn 0.647 0.087 0.758
vn 0.758 0.087 0.647
vn 0.758 0.087 0.647
vn 0.758 0.087 0.647
vn 0.849 0.087 0.521
vn 0.849 0.087 0.521
vn 0.849 0.087 0.521
vn 0.920 0.087 0.381
vn 0.920 0.087 0.381
vn 0.993 0.087 0.078
vn 0.993 0.087 0.078
vn 0.969 0.087 0.233
vn 0.969 0.087 0.233
vn 0.969 0.087 0.233
vn 0.920 0.087 0.381
vn 0.963 0.258 0.076
vn 0.963 0.258 0.076
vn 0.939 0.258 0.226
vn 0.939 0.258 0.226
vn 0.893 0.258 0.370
vn 0.893 0.258 0.370
vn 0.824 0.258 0.505
vn 0.824 0.258 0.505
vn 0.735 0.258 0.627
vn 0.735 0.258 0.627
vn 0.627 0.258 0.735
vn 0.627 0.258 0.735
vn 0.505 0.258 0.824
vn 0.505 0.258 0.824
vn 0.370 0.258 0.893
vn 0.370 0.258 0.893
vn 0.226 0.258 0.939
vn 0.226 0.258 0.939
vn 0.076 0.258 0.963
vn 0.076 0.258 0.963
vn 0.904 0.421 0.071
vn 0.904 0.422 0.071
vn 0.882 0.422 0.212
vn 0.882 0.422 0.212
vn 0.838 0.422 0.347
vn 0.838 0.422 0.347
vn 0.773 0.422 0.474
vn 0.773 0.422 0.474
vn 0.690 0.422 0.589
vn 0.690 0.422 0.589
vn 0.589 0.422 0.690
vn 0.589 0.422 0.690
vn 0.474 0.422 0.773
vn 0.474 0.422 0.773
vn 0.347 0.422 0.838
vn 0.347 0.422 0.838
vn 0.212 0.422 0.882
vn 0.212 0.422 0.882
vn 0.071 0.422 0.904
vn 0.071 0.422 0.904
vn 0.817 0.572 0.064
vn 0.817 0.572 0.064
vn 0.797 0.572 0.191
vn 0.797 0.572 0.191
vn 0.758 0.572 0.314
vn 0.758 0.572 0.314
vn 0.699 0.572 0.428
vn 0.699 0.572 0.428
vn 0.624 0.572 0.533
vn 0.624 0.572 0.533
vn 0.533 0.572 0.624
vn 0.533 0.572 0.624
vn 0.428 0.572 0.699
vn 0.428 0.572 0.699
vn 0.314 0.572 0.758
vn 0.314 0.572 0.758
vn 0.191 0.572 0.797
vn 0.191 0.572 0.797
vn 0.064 0.572 0.817
vn 0.064 0.572 0.817
vn 0.706 0.706 0.056
vn 0.706 0.706 0.056
vn 0.689 0.706 0.165
vn 0.689 0.706 0.165
vn 0.654 0.706 0.271
vn 0.654 0.706 0.271
vn 0.604 0.706 0.370
vn 0.604 0.706 0.370
vn 0.539 0.706 0.460
vn 0.539 0.706 0.460
vn 0.460 0.706 0.539
vn 0.460 0.706 0.539
vn 0.370 0.706 0.604
vn 0.370 0.706 0.604
vn 0.271 0.706 0.654
vn 0.271 0.706 0.654
vn 0.165 0.706 0.689
vn 0.165 0.706 0.689
vn 0.056 0.706 0.706
vn 0.056 0.706 0.706
vn 0.573 0.818 0.045
vn 0.573 0.818 0.045
vn 0.559 0.818 0.134
vn 0.559 0.818 0.134
vn 0.531 0.818 0.220
vn 0.531 0.818 0.220
vn 0.490 0.818 0.300
vn 0.490 0.818 0.300
vn 0.437 0.818 0.373
vn 0.437 0.818 0.373
vn 0.373 0.818 0.437
vn 0.373 0.818 0.437
vn 0.300 0.818 0.490
vn 0.300 0.818 0.490
vn 0.220 0.818 0.531
vn 0.220 0.818 0.531
vn 0.134 0.818 0.559
vn 0.134 0.818 0.559
vn 0.045 0.818 0.573
vn 0.045 0.818 0.573
vn 0.422 0.906 0.033
vn 0.422 0.906 0.033
vn 0.412 0.906 0.099
vn 0.412 0.906 0.099
vn 0.391 0.906 0.162
vn 0.391 0.906 0.162
vn 0.361 0.906 0.221
vn 0.361 0.906 0.221
vn 0.322 0.906 0.275
vn 0.322 0.906 0.275
vn 0.275 0.906 0.322
vn 0.275 0.906 0.322
vn 0.221 0.906 0.361
vn 0.221 0.906 0.361
vn 0.162 0.906 0.391
vn 0.162 0.906 0.391
vn 0.099 0.906 0.412
vn 0.099 0.906 0.412
vn 0.033 0.906 0.422
vn 0.033 0.906 0.422
vn 0.259 0.966 0.020
vn 0.259 0.966 0.020
vn 0.252 0.966 0.061
vn 0.252 0.966 0.061
vn 0.240 0.966 0.099
vn 0.240 0.966 0.099
vn 0.221 0.966 0.136
vn 0.221 0.966 0.136
vn 0.197 0.966 0.169
vn 0.197 0.966 0.169
vn 0.169 0.966 0.197
vn 0.169 0.966 0.197
vn 0.136 0.966 0.221
vn 0.136 0.966 0.221
vn 0.099 0.966 0.240
vn 0.099 0.966 0.240
vn 0.061 0.966 0.252
vn 0.061 0.966 0.252
vn 0.020 0.966 0.259
vn 0.020 0.966 0.259
vn 0.087 0.996 0.007
vn 0.087 0.996 0.007
vn 0.085 0.996 0.020
vn 0.085 0.996 0.020
vn 0.081 0.996 0.033
vn 0.081 0.996 0.033
vn 0.075 0.996 0.046
vn 0.075 0.996 0.046
vn 0.066 0.996 0.057
vn 0.066 0.996 0.057
vn 0.057 0.996 0.066
vn 0.057 0.996 0.066
vn 0.046 0.996 0.075
vn 0.046 0.996 0.075
vn 0.033 0.996 0.081
vn 0.033 0.996 0.081
vn 0.020 0.996 0.085
vn 0.020 0.996 0.085
vn 0.007 0.996 0.087
vn 0.007 0.996 0.087
vn -0.993 0.087 0.078
vn -0.993 0.087 0.078
vn -0.969 0.087 0.233
vn -0.969 0.087 0.233
vn -0.969 0.087 0.233
vn -0.920 0.087 0.381
vn -0.920 0.087 0.381
vn -0.920 0.087 0.381
vn -0.849 0.087 0.521
vn -0.849 0.087 0.521
vn -0.849 0.087 0.521
vn -0.758 0.087 0.647
vn -0.758 0.087 0.647
vn -0.758 0.087 0.647
vn -0.647 0.087 0.758
vn -0.647 0.087 0.758
vn -0.647 0.087 0.758
vn -0.521 0.087 0.849
vn -0.521 0.087 0.849
vn -0.521 0.087 0.849
vn -0.381 0.087 0.920
vn -0.381 0.087 0.920
vn -0.078 0.087 0.993
vn -0.078 0.087 0.993
vn -0.233 0.087 0.969
vn -0.233 0.087 0.969
vn -0.233 0.087 0.969
vn -0.381 0.087 0.920
vn -0.076 0.258 0.963
vn -0.076 0.258 0.963
vn -0.226 0.258 0.939
vn -0.226 0.258 0.939
vn -0.370 0.258 0.893
vn -0.370 0.258 0.893
vn -0.505 0.258 0.824
vn -0.505 0.258 0.824
vn -0.627 0.258 0.735
vn -0.627 0.258 0.735
vn -0.735 0.258 0.627
vn -0.735 0.258 0.627
vn -0.824 0.258 0.505
vn -0.824 0.258 0.505
vn -0.893 0.258 0.370
vn -0.893 0.258 0.370
vn -0.939 0.258 0.226
vn -0.939 0.258 0.226
vn -0.963 0.258 0.076
vn -0.963 0.258 0.076
vn -0.071 0.422 0.904
vn -0.071 0.422 0.904
vn -0.212 0.422 0.882
vn -0.212 0.422 0.882
vn -0.347 0.422 0.838
vn -0.347 0.422 0.838
vn -0.474 0.422 0.773
vn -0.474 0.422 0.773
vn -0.589 0.422 0.690
vn -0.589 0.422 0.690
vn -0.690 0.422 0.589
vn -0.690 0.422 0.589
vn -0.773 0.422 0.474
vn -0.773 0.422 0.474
vn -0.838 0.422 0.347
vn -0.838 0.422 0.347
vn -0.882 0.422 0.212
vn -0.882 0.422 0.212
vn -0.904 0.422 0.071
vn -0.904 0.422 0.071
vn -0.064 0.572 0.817
vn -0.064 0.572 0.817
vn -0.191 0.572 0.797
vn -0.191 0.572 0.797
vn -0.314 0.572 0.758
vn -0.314 0.572 0.758
vn -0.428 0.572 0.699
vn -0.428 0.572 0.699
vn -0.533 0.572 0.624
vn -0.533 0.572 0.624
vn -0.624 0.572 0.533
vn -0.624 0.572 0.533
vn -0.699 0.572 0.428
vn -0.699 0.572 0.428
vn -0.758 0.572 0.314
vn -0.758 0.572 0.314
vn -0.797 0.572 0.191
vn -0.797 0.572 0.191
vn -0.817 0.572 0.064
vn -0.817 0.572 0.064
vn -0.056 0.706 0.706
vn -0.056 0.706 0.706
vn -0.165 0.706 0.689
vn -0.165 0.706 0.689
vn -0.271 0.706 0.654
vn -0.271 0.706 0.654
vn -0.370 0.706 0.604
vn -0.370 0.706 0.604
vn -0.460 0.706 0.539
vn -0.460 0.706 0.539
vn -0.539 0.706 0.460
vn -0.539 0.706 0.460
vn -0.604 0.706 0.370
vn -0.604 0.706 0.370
vn -0.654 0.706 0.271
vn -0.654 0.706 0.271
vn -0.689 0.706 0.165
vn -0.689 0.706 0.165
vn -0.706 0.706 0.056
vn -0.706 0.706 0.056
vn -0.045 0.818 0.573
vn -0.045 0.818 0.573
vn -0.134 0.818 0.559
vn -0.134 0.818 0.559
vn -0.220 0.818 0.531
vn -0.220 0.818 0.531
vn -0.300 0.818 0.490
vn -0.300 0.818 0.490
vn -0.373 0.818 0.437
vn -0.373 0.818 0.437
vn -0.437 0.818 0.373
vn -0.437 0.818 0.373
vn -0.490 0.818 0.300
vn -0.490 0.818 0.300
vn -0.531 0.818 0.220
vn -0.531 0.818 0.220
vn -0.559 0.818 0.134
vn -0.559 0.818 0.134
vn -0.573 0.818 0.045
vn -0.573 0.818 0.045
vn -0.033 0.906 0.422
vn -0.033 0.906 0.422
vn -0.099 0.906 0.412
vn -0.099 0.906 0.412
vn -0.162 0.906 0.391
vn -0.162 0.906 0.391
vn -0.221 0.906 0.361
vn -0.221 0.906 0.361
vn -0.275 0.906 0.322
vn -0.275 0.906 0.322
vn -0.322 0.906 0.275
vn -0.322 0.906 0.275
vn -0.361 0.906 0.221
vn -0.361 0.906 0.221
vn -0.391 0.906 0.162
vn -0.391 0.906 0.162
vn -0.412 0.906 0.099
vn -0.412 0.906 0.099
vn -0.422 0.906 0.033
vn -0.422 0.906 0.033
vn -0.020 0.966 0.259
vn -0.020 0.966 0.259
vn -0.061 0.966 0.252
vn -0.061 0.966 0.252
vn -0.099 0.966 0.240
vn -0.099 0.966 0.240
vn -0.136 0.966 0.221
vn -0.136 0.966 0.221
vn -0.169 0.966 0.197
vn -0.169 0.966 0.197
vn -0.197 0.966 0.169
vn -0.197 0.966 0.169
vn -0.221 0.966 0.136
vn -0.221 0.966 0.136
vn -0.240 0.966 0.099
vn -0.240 0.966 0.099
vn -0.252 0.966 0.061
vn -0.252 0.966 0.061
vn -0.259 0.966 0.020
vn -0.259 0.966 0.020
vn -0.007 0.996 0.087
vn -0.007 0.996 0.087
vn -0.020 0.996 0.085
vn -0.020 0.996 0.085
vn -0.033 0.996 0.081
vn -0.033 0.996 0.081
vn -0.046 0.996 0.075
vn -0.046 0.996 0.075
vn -0.057 0.996 0.066
vn -0.057 0.996 0.066
vn -0.066 0.996 0.057
vn -0.066 0.996 0.057
vn -0.075 0.996 0.046
vn -0.075 0.996 0.046
vn -0.081 0.996 0.033
vn -0.081 0.996 0.033
vn -0.085 0.996 0.020
vn -0.085 0.996 0.020
vn -0.087 0.996 0.007
vn -0.087 0.996 0.007
vn -0.078 0.997 0.000
vn -0.087 0.996 0.000
vn -0.233 0.972 0.000
vn -0.233 0.972 0.000
vn -0.259 0.966 0.001
vn -0.382 0.924 -0.001
vn -0.383 0.924 -0.001
vn -0.423 0.906 0.001
vn -0.522 0.853 -0.001
vn -0.523 0.853 -0.001
vn -0.574 0.819 0.001
vn -0.650 0.760 -0.001
vn -0.649 0.760 -0.001
vn -0.707 0.707 0.001
vn -0.760 0.650 -0.001
vn -0.760 0.649 -0.001
vn -0.819 0.574 0.001
vn -0.853 0.523 -0.001
vn -0.853 0.522 -0.001
vn -0.906 0.423 0.001
vn -0.924 0.383 -0.001
vn -0.924 0.383 -0.001
vn -0.966 0.259 0.001
vn -0.997 0.078 0.000
vn -0.996 0.087 0.000
vn -0.972 0.234 0.000
vn -0.972 0.233 0.000
vn -0.997 0.078 0.006
vn -0.997 0.077 0.018
vn -0.997 0.073 0.030
vn -0.997 0.067 0.041
vn -0.997 0.061 0.049
vn -0.995 0.082 0.053
vn -0.970 0.080 0.229
vn -0.956 0.236 0.173
vn -0.923 0.297 0.244
vn -0.940 0.232 0.250
vn -0.940 0.232 0.250
vn -0.852 0.341 0.397
vn -0.838 0.365 0.406
vn -0.624 0.272 0.733
vn -0.624 0.272 0.733
vn -0.420 0.265 0.868
vn -0.206 0.130 0.970
vn -0.104 0.074 0.992
vn -0.105 0.074 0.992
vn -0.078 0.112 0.991
vn -0.206 0.130 0.970
vn -0.206 0.130 0.970
vn -0.997 0.078 0.006
vn -0.972 0.234 0.018
vn -0.972 0.233 0.018
vn -0.972 0.233 0.018
vn -0.923 0.383 0.030
vn -0.923 0.383 0.030
vn -0.923 0.382 0.030
vn -0.852 0.522 0.041
vn -0.852 0.522 0.041
vn -0.852 0.522 0.041
vn -0.759 0.649 0.051
vn -0.759 0.649 0.051
vn -0.759 0.649 0.051
vn -0.648 0.759 0.060
vn -0.648 0.759 0.060
vn -0.648 0.759 0.060
vn -0.521 0.851 0.067
vn -0.521 0.851 0.067
vn -0.521 0.851 0.067
vn -0.382 0.921 0.073
vn -0.382 0.921 0.073
vn -0.997 0.077 0.018
vn -0.972 0.228 0.055
vn -0.972 0.228 0.055
vn -0.923 0.373 0.090
vn -0.923 0.373 0.090
vn -0.852 0.509 0.123
vn -0.852 0.509 0.123
vn -0.759 0.632 0.153
vn -0.759 0.632 0.153
vn -0.648 0.740 0.179
vn -0.648 0.740 0.179
vn -0.521 0.829 0.200
vn -0.521 0.830 0.200
vn -0.382 0.898 0.217
vn -0.382 0.898 0.217
vn -0.233 0.945 0.228
vn -0.233 0.945 0.228
vn -0.381 0.922 0.073
vn -0.233 0.969 0.077
vn -0.233 0.970 0.077
vn -0.233 0.970 0.077
vn -0.078 0.994 0.079
vn -0.078 0.225 0.971
vn -0.420 0.265 0.868
vn -0.233 0.267 0.935
vn -0.293 0.216 0.931
vn -0.078 0.225 0.971
vn -0.078 0.225 0.971
vn -0.078 0.375 0.924
vn -0.608 0.384 0.694
vn -0.522 0.402 0.752
vn -0.551 0.348 0.758
vn -0.382 0.372 0.846
vn -0.435 0.339 0.834
vn -0.233 0.366 0.901
vn -0.233 0.366 0.901
vn -0.078 0.375 0.924
vn -0.078 0.375 0.924
vn -0.078 0.516 0.853
vn -0.884 0.385 0.266
vn -0.755 0.474 0.453
vn -0.800 0.348 0.488
vn -0.649 0.428 0.629
vn -0.700 0.369 0.611
vn -0.521 0.441 0.730
vn -0.521 0.441 0.730
vn -0.382 0.478 0.791
vn -0.382 0.478 0.791
vn -0.233 0.503 0.832
vn -0.233 0.503 0.832
vn -0.078 0.516 0.853
vn -0.078 0.516 0.853
vn -0.078 0.644 0.761
vn -0.889 0.296 0.350
vn -0.759 0.420 0.497
vn -0.759 0.420 0.497
vn -0.648 0.492 0.582
vn -0.648 0.491 0.582
vn -0.521 0.551 0.652
vn -0.521 0.551 0.652
vn -0.382 0.597 0.706
vn -0.382 0.597 0.706
vn -0.233 0.628 0.743
vn -0.233 0.628 0.743
vn -0.078 0.644 0.761
vn -0.078 0.644 0.761
vn -0.078 0.755 0.651
vn -0.995 0.082 0.053
vn -0.972 0.180 0.149
vn -0.976 0.166 0.143
vn -0.923 0.291 0.250
vn -0.923 0.291 0.250
vn -0.852 0.397 0.342
vn -0.852 0.397 0.342
vn -0.759 0.493 0.425
vn -0.759 0.493 0.425
vn -0.648 0.577 0.497
vn -0.648 0.577 0.497
vn -0.521 0.647 0.557
vn -0.521 0.647 0.557
vn -0.382 0.700 0.603
vn -0.382 0.700 0.603
vn -0.233 0.737 0.635
vn -0.233 0.737 0.635
vn -0.078 0.755 0.651
vn -0.078 0.755 0.651
vn -0.078 0.848 0.524
vn -0.997 0.067 0.041
vn -0.972 0.199 0.123
vn -0.972 0.199 0.123
vn -0.923 0.327 0.202
vn -0.923 0.327 0.202
vn -0.852 0.446 0.275
vn -0.852 0.446 0.275
vn -0.759 0.554 0.342
vn -0.759 0.554 0.342
vn -0.648 0.648 0.400
vn -0.648 0.648 0.400
vn -0.521 0.726 0.448
vn -0.521 0.726 0.448
vn -0.382 0.786 0.486
vn -0.382 0.787 0.486
vn -0.233 0.828 0.511
vn -0.233 0.828 0.511
vn -0.078 0.848 0.524
vn -0.078 0.848 0.524
vn -0.997 0.073 0.030
vn -0.972 0.216 0.090
vn -0.972 0.216 0.090
vn -0.923 0.354 0.148
vn -0.923 0.354 0.148
vn -0.852 0.483 0.202
vn -0.852 0.483 0.202
vn -0.759 0.600 0.250
vn -0.759 0.601 0.250
vn -0.648 0.703 0.293
vn -0.648 0.703 0.293
vn -0.521 0.788 0.328
vn -0.521 0.788 0.328
vn -0.382 0.853 0.356
vn -0.382 0.853 0.356
vn -0.233 0.898 0.374
vn -0.233 0.898 0.374
vn -0.078 0.920 0.384
vn -0.078 0.920 0.384
vn -0.078 0.994 0.079
vn -0.078 0.969 0.234
vn -0.078 0.969 0.234
vn -0.078 0.969 0.234
vn -0.078 0.920 0.384
vn -0.997 -0.078 0.006
vn -0.233 -0.945 0.228
vn -0.078 -0.994 0.079
vn -0.078 -0.848 0.524
vn -0.078 -0.755 0.651
vn -0.078 -0.644 0.761
vn -0.078 -0.516 0.853
vn -0.078 -0.375 0.924
vn -0.405 -0.260 0.876
vn -0.180 -0.127 0.975
vn -0.202 -0.130 0.971
vn -0.557 -0.358 0.749
vn -0.812 -0.385 0.439
vn -0.857 -0.333 0.394
vn -0.829 -0.293 0.477
vn -0.829 -0.293 0.477
vn -0.993 -0.101 0.067
vn -0.993 -0.101 0.067
vn -0.078 -0.225 0.971
vn -0.078 -0.225 0.971
vn -0.078 -0.225 0.971
vn -0.078 -0.075 0.994
vn -0.104 -0.074 0.992
vn -0.078 -0.969 0.234
vn -0.078 -0.969 0.234
vn -0.078 -0.969 0.234
vn -0.078 -0.920 0.384
vn -0.233 -0.366 0.901
vn -0.233 -0.264 0.936
vn -0.288 -0.216 0.933
vn -0.202 -0.130 0.971
vn -0.202 -0.130 0.971
vn -0.078 -0.848 0.524
vn -0.078 -0.848 0.524
vn -0.233 -0.828 0.511
vn -0.233 -0.898 0.374
vn -0.382 -0.853 0.356
vn -0.382 -0.898 0.217
vn -0.521 -0.830 0.200
vn -0.078 -0.994 0.079
vn -0.233 -0.969 0.077
vn -0.233 -0.970 0.077
vn -0.233 -0.970 0.077
vn -0.382 -0.921 0.073
vn -0.078 -0.920 0.384
vn -0.078 -0.920 0.384
vn -0.233 -0.898 0.374
vn -0.233 -0.945 0.228
vn -0.382 -0.898 0.217
vn -0.382 -0.921 0.073
vn -0.557 -0.358 0.749
vn -0.522 -0.346 0.780
vn -0.521 -0.441 0.730
vn -0.078 -0.644 0.761
vn -0.078 -0.644 0.761
vn -0.233 -0.628 0.743
vn -0.233 -0.737 0.635
vn -0.382 -0.700 0.603
vn -0.382 -0.787 0.486
vn -0.521 -0.726 0.448
vn -0.521 -0.788 0.328
vn -0.648 -0.703 0.293
vn -0.648 -0.740 0.179
vn -0.759 -0.632 0.153
vn -0.382 -0.921 0.073
vn -0.521 -0.851 0.067
vn -0.521 -0.851 0.067
vn -0.521 -0.851 0.067
vn -0.648 -0.759 0.060
vn -0.078 -0.755 0.651
vn -0.078 -0.755 0.651
vn -0.233 -0.737 0.635
vn -0.233 -0.828 0.511
vn -0.382 -0.787 0.486
vn -0.382 -0.853 0.356
vn -0.521 -0.788 0.328
vn -0.521 -0.830 0.200
vn -0.648 -0.740 0.179
vn -0.648 -0.759 0.060
vn -0.812 -0.385 0.439
vn -0.760 -0.377 0.529
vn -0.759 -0.420 0.497
vn -0.078 -0.375 0.924
vn -0.078 -0.375 0.924
vn -0.233 -0.366 0.901
vn -0.233 -0.503 0.832
vn -0.382 -0.478 0.791
vn -0.382 -0.597 0.706
vn -0.521 -0.551 0.652
vn -0.521 -0.647 0.557
vn -0.648 -0.577 0.497
vn -0.648 -0.648 0.400
vn -0.759 -0.554 0.342
vn -0.759 -0.601 0.250
vn -0.852 -0.483 0.202
vn -0.852 -0.509 0.123
vn -0.923 -0.373 0.090
vn -0.648 -0.759 0.060
vn -0.759 -0.649 0.051
vn -0.759 -0.649 0.051
vn -0.759 -0.649 0.051
vn -0.852 -0.522 0.041
vn -0.078 -0.516 0.853
vn -0.078 -0.516 0.853
vn -0.233 -0.503 0.832
vn -0.233 -0.628 0.743
vn -0.382 -0.597 0.706
vn -0.382 -0.700 0.603
vn -0.521 -0.647 0.557
vn -0.521 -0.726 0.448
vn -0.648 -0.648 0.400
vn -0.648 -0.703 0.293
vn -0.759 -0.600 0.250
vn -0.759 -0.632 0.153
vn -0.852 -0.509 0.123
vn -0.852 -0.522 0.041
vn -0.949 -0.242 0.204
vn -0.924 -0.259 0.282
vn -0.923 -0.291 0.250
vn -0.852 -0.522 0.041
vn -0.923 -0.383 0.030
vn -0.923 -0.383 0.030
vn -0.923 -0.383 0.030
vn -0.972 -0.233 0.018
vn -0.405 -0.261 0.876
vn -0.422 -0.341 0.840
vn -0.382 -0.367 0.848
vn -0.382 -0.478 0.791
vn -0.521 -0.441 0.730
vn -0.521 -0.551 0.652
vn -0.648 -0.491 0.582
vn -0.648 -0.577 0.497
vn -0.759 -0.493 0.425
vn -0.759 -0.554 0.342
vn -0.852 -0.446 0.275
vn -0.852 -0.483 0.202
vn -0.923 -0.354 0.148
vn -0.923 -0.373 0.090
vn -0.972 -0.228 0.055
vn -0.972 -0.233 0.018
vn -0.972 -0.234 0.018
vn -1.000 -0.011 0.009
vn -0.997 -0.057 0.054
vn -0.997 -0.067 0.041
vn -0.997 -0.073 0.030
vn -0.997 -0.067 0.041
vn -0.972 -0.199 0.123
vn -0.972 -0.182 0.146
vn -0.978 -0.158 0.136
vn -0.976 -0.099 0.196
vn -0.949 -0.242 0.204
vn -0.997 -0.077 0.018
vn -0.997 -0.073 0.030
vn -0.972 -0.216 0.090
vn -0.972 -0.199 0.123
vn -0.923 -0.327 0.202
vn -0.923 -0.291 0.250
vn -0.852 -0.397 0.342
vn -0.852 -0.351 0.388
vn -0.907 -0.231 0.352
vn -0.997 -0.078 0.006
vn -0.997 -0.077 0.018
vn -0.972 -0.228 0.055
vn -0.972 -0.216 0.090
vn -0.923 -0.354 0.148
vn -0.923 -0.327 0.202
vn -0.852 -0.446 0.275
vn -0.852 -0.397 0.342
vn -0.759 -0.493 0.425
vn -0.759 -0.420 0.497
vn -0.648 -0.491 0.582
vn -0.649 -0.431 0.627
vn -0.705 -0.367 0.607
vn -0.667 -0.316 0.675
vn -0.667 -0.316 0.675
vn -0.997 -0.078 0.000
vn -0.996 -0.087 0.000
vn -0.972 -0.234 0.000
vn -0.972 -0.233 0.000
vn -0.966 -0.259 0.001
vn -0.924 -0.383 -0.001
vn -0.924 -0.383 -0.001
vn -0.906 -0.423 0.001
vn -0.853 -0.522 -0.001
vn -0.853 -0.523 -0.001
vn -0.819 -0.574 0.001
vn -0.760 -0.649 -0.001
vn -0.760 -0.649 -0.001
vn -0.707 -0.707 0.001
vn -0.649 -0.760 -0.001
vn -0.649 -0.760 -0.001
vn -0.574 -0.819 0.001
vn -0.522 -0.853 -0.001
vn -0.523 -0.853 -0.001
vn -0.423 -0.906 0.001
vn -0.383 -0.924 -0.001
vn -0.383 -0.924 -0.001
vn -0.259 -0.966 0.001
vn -0.078 -0.997 0.000
vn -0.087 -0.996 0.000
vn -0.234 -0.972 0.000
vn -0.233 -0.972 0.000
vn -0.007 -0.996 0.087
vn -0.020 -0.996 0.085
vn -0.033 -0.996 0.081
vn -0.046 -0.996 0.075
vn -0.057 -0.996 0.066
vn -0.066 -0.996 0.057
vn -0.075 -0.996 0.046
vn -0.081 -0.996 0.033
vn -0.085 -0.996 0.020
vn -0.087 -0.996 0.007
vn -0.087 -0.996 0.007
vn -0.259 -0.966 0.020
vn -0.259 -0.966 0.020
vn -0.422 -0.906 0.033
vn -0.422 -0.906 0.033
vn -0.573 -0.818 0.045
vn -0.573 -0.818 0.045
vn -0.706 -0.706 0.056
vn -0.706 -0.706 0.056
vn -0.817 -0.572 0.064
vn -0.817 -0.572 0.064
vn -0.904 -0.422 0.071
vn -0.904 -0.422 0.071
vn -0.963 -0.258 0.076
vn -0.963 -0.258 0.076
vn -0.993 -0.087 0.078
vn -0.993 -0.087 0.078
vn -0.969 -0.087 0.233
vn -0.085 -0.996 0.020
vn -0.252 -0.966 0.061
vn -0.252 -0.966 0.061
vn -0.412 -0.906 0.099
vn -0.412 -0.906 0.099
vn -0.559 -0.818 0.134
vn -0.559 -0.818 0.134
vn -0.689 -0.706 0.165
vn -0.689 -0.706 0.165
vn -0.797 -0.572 0.191
vn -0.797 -0.572 0.191
vn -0.882 -0.422 0.212
vn -0.882 -0.422 0.212
vn -0.939 -0.258 0.226
vn -0.939 -0.258 0.226
vn -0.969 -0.087 0.233
vn -0.969 -0.087 0.233
vn -0.920 -0.087 0.381
vn -0.081 -0.996 0.033
vn -0.240 -0.966 0.099
vn -0.240 -0.966 0.099
vn -0.391 -0.906 0.162
vn -0.391 -0.906 0.162
vn -0.531 -0.818 0.220
vn -0.531 -0.818 0.220
vn -0.654 -0.706 0.271
vn -0.654 -0.706 0.271
vn -0.758 -0.572 0.314
vn -0.758 -0.572 0.314
vn -0.838 -0.422 0.347
vn -0.838 -0.422 0.347
vn -0.893 -0.258 0.370
vn -0.893 -0.258 0.370
vn -0.920 -0.087 0.381
vn -0.920 -0.087 0.381
vn -0.849 -0.087 0.521
vn -0.075 -0.996 0.046
vn -0.221 -0.966 0.136
vn -0.221 -0.966 0.136
vn -0.361 -0.906 0.221
vn -0.361 -0.906 0.221
vn -0.490 -0.818 0.300
vn -0.490 -0.818 0.300
vn -0.604 -0.706 0.370
vn -0.604 -0.706 0.370
vn -0.699 -0.572 0.428
vn -0.699 -0.572 0.428
vn -0.773 -0.422 0.474
vn -0.773 -0.422 0.474
vn -0.824 -0.258 0.505
vn -0.824 -0.258 0.505
vn -0.849 -0.087 0.521
vn -0.849 -0.087 0.521
vn -0.758 -0.087 0.647
vn -0.066 -0.996 0.057
vn -0.197 -0.966 0.169
vn -0.197 -0.966 0.169
vn -0.322 -0.906 0.275
vn -0.322 -0.906 0.275
vn -0.437 -0.818 0.373
vn -0.437 -0.818 0.373
vn -0.539 -0.706 0.460
vn -0.539 -0.706 0.460
vn -0.624 -0.572 0.533
vn -0.624 -0.572 0.533
vn -0.690 -0.422 0.589
vn -0.690 -0.422 0.589
vn -0.735 -0.258 0.627
vn -0.735 -0.258 0.627
vn -0.758 -0.087 0.647
vn -0.758 -0.087 0.647
vn -0.647 -0.087 0.758
vn -0.057 -0.996 0.066
vn -0.169 -0.966 0.197
vn -0.169 -0.966 0.197
vn -0.275 -0.906 0.322
vn -0.275 -0.906 0.322
vn -0.373 -0.818 0.437
vn -0.373 -0.818 0.437
vn -0.460 -0.706 0.539
vn -0.460 -0.706 0.539
vn -0.533 -0.572 0.624
vn -0.533 -0.572 0.624
vn -0.589 -0.422 0.690
vn -0.589 -0.422 0.690
vn -0.627 -0.258 0.735
vn -0.627 -0.258 0.735
vn -0.647 -0.087 0.758
vn -0.647 -0.087 0.758
vn -0.521 -0.087 0.849
vn -0.046 -0.996 0.075
vn -0.136 -0.966 0.221
vn -0.136 -0.966 0.221
vn -0.221 -0.906 0.361
vn -0.221 -0.906 0.361
vn -0.300 -0.818 0.490
vn -0.300 -0.818 0.490
vn -0.370 -0.706 0.604
vn -0.370 -0.706 0.604
vn -0.428 -0.572 0.699
vn -0.428 -0.572 0.699
vn -0.474 -0.422 0.773
vn -0.474 -0.422 0.773
vn -0.505 -0.258 0.824
vn -0.505 -0.258 0.824
vn -0.521 -0.087 0.849
vn -0.521 -0.087 0.849
vn -0.381 -0.087 0.920
vn -0.033 -0.996 0.081
vn -0.099 -0.966 0.240
vn -0.099 -0.966 0.240
vn -0.162 -0.906 0.391
vn -0.162 -0.906 0.391
vn -0.220 -0.818 0.531
vn -0.220 -0.818 0.531
vn -0.271 -0.706 0.654
vn -0.271 -0.706 0.654
vn -0.314 -0.572 0.758
vn -0.314 -0.572 0.758
vn -0.347 -0.422 0.838
vn -0.347 -0.422 0.838
vn -0.370 -0.258 0.893
vn -0.370 -0.258 0.893
vn -0.381 -0.087 0.920
vn -0.381 -0.087 0.920
vn -0.233 -0.087 0.969
vn -0.020 -0.996 0.085
vn -0.061 -0.966 0.252
vn -0.061 -0.966 0.252
vn -0.099 -0.906 0.412
vn -0.099 -0.906 0.412
vn -0.134 -0.818 0.559
vn -0.134 -0.818 0.559
vn -0.165 -0.706 0.689
vn -0.165 -0.706 0.689
vn -0.191 -0.572 0.797
vn -0.191 -0.572 0.797
vn -0.212 -0.422 0.882
vn -0.212 -0.422 0.882
vn -0.226 -0.258 0.939
vn -0.226 -0.258 0.939
vn -0.233 -0.087 0.969
vn -0.233 -0.087 0.969
vn -0.007 -0.996 0.087
vn -0.020 -0.966 0.259
vn -0.020 -0.966 0.259
vn -0.033 -0.906 0.422
vn -0.033 -0.906 0.422
vn -0.045 -0.818 0.573
vn -0.045 -0.818 0.573
vn -0.056 -0.706 0.706
vn -0.056 -0.706 0.706
vn -0.064 -0.572 0.817
vn -0.064 -0.572 0.817
vn -0.071 -0.422 0.904
vn -0.071 -0.422 0.904
vn -0.076 -0.258 0.963
vn -0.076 -0.258 0.963
vn -0.078 -0.087 0.993
vn -0.078 -0.087 0.993
vn 0.087 -0.996 0.007
vn 0.085 -0.996 0.020
vn 0.081 -0.996 0.033
vn 0.075 -0.996 0.046
vn 0.066 -0.996 0.057
vn 0.057 -0.996 0.066
vn 0.046 -0.996 0.075
vn 0.033 -0.996 0.081
vn 0.020 -0.996 0.085
vn 0.007 -0.996 0.087
vn 0.007 -0.996 0.087
vn 0.020 -0.966 0.259
vn 0.020 -0.966 0.259
vn 0.033 -0.906 0.422
vn 0.033 -0.906 0.422
vn 0.045 -0.818 0.573
vn 0.045 -0.818 0.573
vn 0.056 -0.706 0.706
vn 0.056 -0.706 0.706
vn 0.064 -0.572 0.817
vn 0.064 -0.572 0.817
vn 0.071 -0.422 0.904
vn 0.071 -0.422 0.904
vn 0.076 -0.258 0.963
vn 0.076 -0.258 0.963
vn 0.078 -0.087 0.993
vn 0.078 -0.087 0.993
vn 0.233 -0.087 0.969
vn 0.020 -0.996 0.085
vn 0.061 -0.966 0.252
vn 0.061 -0.966 0.252
vn 0.099 -0.906 0.412
vn 0.099 -0.906 0.412
vn 0.134 -0.818 0.559
vn 0.134 -0.818 0.559
vn 0.165 -0.706 0.689
vn 0.165 -0.706 0.689
vn 0.191 -0.572 0.797
vn 0.191 -0.572 0.797
vn 0.212 -0.422 0.882
vn 0.212 -0.422 0.882
vn 0.226 -0.258 0.939
vn 0.226 -0.258 0.939
vn 0.233 -0.087 0.969
vn 0.233 -0.087 0.969
vn 0.381 -0.087 0.920
vn 0.033 -0.996 0.081
vn 0.099 -0.966 0.240
vn 0.099 -0.966 0.240
vn 0.162 -0.906 0.391
vn 0.162 -0.906 0.391
vn 0.220 -0.818 0.531
vn 0.220 -0.818 0.531
vn 0.271 -0.706 0.654
vn 0.271 -0.706 0.654
vn 0.314 -0.572 0.758
vn 0.314 -0.572 0.758
vn 0.347 -0.422 0.838
vn 0.347 -0.422 0.838
vn 0.370 -0.258 0.893
vn 0.370 -0.258 0.893
vn 0.381 -0.087 0.920
vn 0.381 -0.087 0.920
vn 0.521 -0.087 0.849
vn 0.046 -0.996 0.075
vn 0.136 -0.966 0.221
vn 0.136 -0.966 0.221
vn 0.221 -0.906 0.361
vn 0.221 -0.906 0.361
vn 0.300 -0.818 0.490
vn 0.300 -0.818 0.490
vn 0.370 -0.706 0.604
vn 0.370 -0.706 0.604
vn 0.428 -0.572 0.699
vn 0.428 -0.572 0.699
vn 0.474 -0.422 0.773
vn 0.474 -0.422 0.773
vn 0.505 -0.258 0.824
vn 0.505 -0.258 0.824
vn 0.521 -0.087 0.849
vn 0.521 -0.087 0.849
vn 0.647 -0.087 0.758
vn 0.057 -0.996 0.066
vn 0.169 -0.966 0.197
vn 0.169 -0.966 0.197
vn 0.275 -0.906 0.322
vn 0.275 -0.906 0.322
vn 0.373 -0.818 0.437
vn 0.373 -0.818 0.437
vn 0.460 -0.706 0.539
vn 0.460 -0.706 0.539
vn 0.533 -0.572 0.624
vn 0.533 -0.572 0.624
vn 0.589 -0.422 0.690
vn 0.589 -0.422 0.690
vn 0.627 -0.258 0.735
vn 0.627 -0.258 0.735
vn 0.647 -0.087 0.758
vn 0.647 -0.087 0.758
vn 0.758 -0.087 0.647
vn 0.066 -0.996 0.057
vn 0.197 -0.966 0.169
vn 0.197 -0.966 0.169
vn 0.322 -0.906 0.275
vn 0.322 -0.906 0.275
vn 0.437 -0.818 0.373
vn 0.437 -0.818 0.373
vn 0.539 -0.706 0.460
vn 0.539 -0.706 0.460
vn 0.624 -0.572 0.533
vn 0.624 -0.572 0.533
vn 0.690 -0.422 0.589
vn 0.690 -0.422 0.589
vn 0.735 -0.258 0.627
vn 0.735 -0.258 0.627
vn 0.758 -0.087 0.647
vn 0.758 -0.087 0.647
vn 0.849 -0.087 0.521
vn 0.075 -0.996 0.046
vn 0.221 -0.966 0.136
vn 0.221 -0.966 0.136
vn 0.361 -0.906 0.221
vn 0.361 -0.906 0.221
vn 0.490 -0.818 0.300
vn 0.490 -0.818 0.300
vn 0.604 -0.706 0.370
vn 0.604 -0.706 0.370
vn 0.699 -0.572 0.428
vn 0.699 -0.572 0.428
vn 0.773 -0.422 0.474
vn 0.773 -0.422 0.474
vn 0.824 -0.258 0.505
vn 0.824 -0.258 0.505
vn 0.849 -0.087 0.521
vn 0.849 -0.087 0.521
vn 0.920 -0.087 0.381
vn 0.081 -0.996 0.033
vn 0.240 -0.966 0.099
vn 0.240 -0.966 0.099
vn 0.391 -0.906 0.162
vn 0.391 -0.906 0.162
vn 0.531 -0.818 0.220
vn 0.531 -0.818 0.220
vn 0.654 -0.706 0.271
vn 0.654 -0.706 0.271
vn 0.758 -0.572 0.314
vn 0.758 -0.572 0.314
vn 0.838 -0.422 0.347
vn 0.838 -0.422 0.347
vn 0.893 -0.258 0.370
vn 0.893 -0.258 0.370
vn 0.920 -0.087 0.381
vn 0.920 -0.087 0.381
vn 0.969 -0.087 0.233
vn 0.085 -0.996 0.020
vn 0.252 -0.966 0.061
vn 0.252 -0.966 0.061
vn 0.412 -0.906 0.099
vn 0.412 -0.906 0.099
vn 0.559 -0.818 0.134
vn 0.559 -0.818 0.134
vn 0.689 -0.706 0.165
vn 0.689 -0.706 0.165
vn 0.797 -0.572 0.191
vn 0.797 -0.572 0.191
vn 0.882 -0.422 0.212
vn 0.882 -0.422 0.212
vn 0.939 -0.258 0.226
vn 0.939 -0.258 0.226
vn 0.969 -0.087 0.233
vn 0.969 -0.087 0.233
vn 0.087 -0.996 0.007
vn 0.259 -0.966 0.020
vn 0.259 -0.966 0.020
vn 0.422 -0.906 0.033
vn 0.422 -0.906 0.033
vn 0.573 -0.818 0.045
vn 0.573 -0.818 0.045
vn 0.706 -0.706 0.056
vn 0.706 -0.706 0.056
vn 0.817 -0.572 0.064
vn 0.817 -0.572 0.064
vn 0.904 -0.421 0.071
vn 0.904 -0.422 0.071
vn 0.963 -0.258 0.076
vn 0.963 -0.258 0.076
vn 0.993 -0.087 0.078
vn 0.993 -0.087 0.078
vn 0.078 -0.997 0.000
vn 0.087 -0.996 0.000
vn 0.234 -0.972 0.000
vn 0.233 -0.972 0.000
vn 0.259 -0.966 0.001
vn 0.383 -0.924 -0.001
vn 0.383 -0.924 -0.001
vn 0.423 -0.906 0.001
vn 0.523 -0.853 -0.001
vn 0.523 -0.853 -0.001
vn 0.574 -0.819 0.001
vn 0.649 -0.760 -0.001
vn 0.649 -0.760 -0.001
vn 0.707 -0.707 0.001
vn 0.760 -0.649 -0.001
vn 0.760 -0.649 -0.001
vn 0.819 -0.574 0.001
vn 0.853 -0.523 -0.001
vn 0.853 -0.523 -0.001
vn 0.906 -0.423 0.001
vn 0.924 -0.383 -0.001
vn 0.924 -0.383 -0.001
vn 0.966 -0.259 0.001
vn 0.997 -0.078 0.000
vn 0.996 -0.087 0.000
vn 0.973 -0.233 0.000
vn 0.972 -0.233 0.000
vn 0.078 -0.817 0.572
vn 0.078 -0.904 0.421
vn 0.078 -0.963 0.258
vn 0.943 -0.232 0.240
vn 0.997 -0.059 0.051
vn 0.996 -0.082 0.044
vn 0.996 -0.082 0.044
vn 0.972 -0.164 0.167
vn 0.951 -0.234 0.202
vn 0.746 -0.325 0.581
vn 0.746 -0.325 0.581
vn 0.493 -0.311 0.812
vn 0.223 -0.141 0.965
vn 0.122 -0.087 0.989
vn 0.123 -0.086 0.989
vn 0.078 -0.129 0.989
vn 0.223 -0.141 0.965
vn 0.223 -0.141 0.965
vn 0.078 -0.993 0.087
vn 0.078 -0.993 0.087
vn 0.493 -0.311 0.812
vn 0.417 -0.312 0.853
vn 0.432 -0.273 0.859
vn 0.233 -0.282 0.931
vn 0.289 -0.248 0.925
vn 0.078 -0.258 0.963
vn 0.078 -0.258 0.963
vn 0.233 -0.969 0.085
vn 0.233 -0.969 0.085
vn 0.233 -0.969 0.085
vn 0.381 -0.921 0.081
vn 0.381 -0.921 0.081
vn 0.381 -0.921 0.081
vn 0.746 -0.325 0.581
vn 0.681 -0.309 0.664
vn 0.604 -0.381 0.700
vn 0.522 -0.403 0.751
vn 0.555 -0.371 0.744
vn 0.382 -0.406 0.830
vn 0.420 -0.384 0.823
vn 0.233 -0.411 0.881
vn 0.233 -0.411 0.881
vn 0.078 -0.421 0.904
vn 0.078 -0.421 0.904
vn 0.521 -0.850 0.074
vn 0.521 -0.850 0.074
vn 0.521 -0.850 0.074
vn 0.648 -0.759 0.066
vn 0.648 -0.759 0.066
vn 0.648 -0.759 0.066
vn 0.943 -0.232 0.240
vn 0.852 -0.317 0.416
vn 0.836 -0.364 0.411
vn 0.760 -0.420 0.495
vn 0.801 -0.361 0.478
vn 0.649 -0.451 0.613
vn 0.684 -0.418 0.598
vn 0.521 -0.490 0.699
vn 0.521 -0.490 0.699
vn 0.381 -0.530 0.757
vn 0.381 -0.530 0.757
vn 0.233 -0.558 0.797
vn 0.233 -0.558 0.797
vn 0.078 -0.572 0.817
vn 0.078 -0.572 0.817
vn 0.759 -0.648 0.057
vn 0.759 -0.648 0.057
vn 0.759 -0.648 0.057
vn 0.852 -0.522 0.046
vn 0.852 -0.522 0.046
vn 0.852 -0.522 0.046
vn 0.951 -0.234 0.202
vn 0.924 -0.277 0.265
vn 0.943 -0.236 0.236
vn 0.852 -0.371 0.371
vn 0.852 -0.371 0.371
vn 0.759 -0.460 0.460
vn 0.759 -0.460 0.460
vn 0.648 -0.539 0.539
vn 0.648 -0.539 0.539
vn 0.521 -0.604 0.604
vn 0.521 -0.604 0.604
vn 0.381 -0.654 0.654
vn 0.381 -0.654 0.654
vn 0.233 -0.688 0.688
vn 0.233 -0.688 0.688
vn 0.078 -0.705 0.705
vn 0.078 -0.705 0.705
vn 0.923 -0.382 0.033
vn 0.923 -0.382 0.033
vn 0.923 -0.383 0.033
vn 0.972 -0.233 0.020
vn 0.972 -0.233 0.020
vn 0.972 -0.233 0.020
vn 0.997 -0.078 0.007
vn 0.997 -0.078 0.007
vn 0.078 -0.963 0.258
vn 0.233 -0.939 0.252
vn 0.233 -0.939 0.252
vn 0.381 -0.893 0.239
vn 0.381 -0.893 0.239
vn 0.521 -0.824 0.221
vn 0.521 -0.824 0.221
vn 0.648 -0.736 0.197
vn 0.648 -0.736 0.197
vn 0.759 -0.629 0.168
vn 0.759 -0.629 0.168
vn 0.852 -0.506 0.136
vn 0.852 -0.506 0.136
vn 0.923 -0.371 0.099
vn 0.923 -0.371 0.099
vn 0.972 -0.226 0.061
vn 0.972 -0.226 0.061
vn 0.997 -0.076 0.020
vn 0.997 -0.076 0.020
vn 0.078 -0.904 0.421
vn 0.233 -0.881 0.411
vn 0.233 -0.881 0.411
vn 0.381 -0.838 0.391
vn 0.381 -0.838 0.391
vn 0.521 -0.774 0.361
vn 0.521 -0.774 0.361
vn 0.648 -0.690 0.322
vn 0.648 -0.690 0.322
vn 0.759 -0.590 0.275
vn 0.759 -0.590 0.275
vn 0.852 -0.475 0.221
vn 0.852 -0.475 0.221
vn 0.923 -0.348 0.162
vn 0.923 -0.348 0.162
vn 0.972 -0.212 0.099
vn 0.972 -0.212 0.099
vn 0.997 -0.071 0.033
vn 0.997 -0.071 0.033
vn 0.078 -0.817 0.572
vn 0.233 -0.797 0.558
vn 0.233 -0.797 0.558
vn 0.381 -0.757 0.530
vn 0.381 -0.757 0.530
vn 0.521 -0.699 0.490
vn 0.521 -0.699 0.490
vn 0.648 -0.624 0.437
vn 0.648 -0.624 0.437
vn 0.759 -0.533 0.373
vn 0.759 -0.533 0.373
vn 0.852 -0.429 0.301
vn 0.852 -0.429 0.301
vn 0.923 -0.314 0.220
vn 0.923 -0.314 0.220
vn 0.972 -0.192 0.134
vn 0.972 -0.192 0.134
vn 0.997 -0.065 0.045
vn 0.997 -0.065 0.045
vn 0.996 0.087 0.000
vn 0.996 0.087 0.000
vn 0.966 0.259 0.000
vn 0.966 0.259 0.000
vn 0.906 0.423 0.000
vn 0.906 0.423 0.000
vn 0.819 0.574 0.000
vn 0.819 0.574 0.000
vn 0.707 0.707 0.000
vn 0.707 0.707 0.000
vn 0.574 0.819 0.000
vn 0.574 0.819 0.000
vn 0.423 0.906 0.000
vn 0.423 0.906 0.000
vn 0.259 0.966 0.000
vn 0.259 0.966 0.000
vn 0.087 0.996 0.000
vn 0.087 0.996 0.000
vn 0.007 0.996 0.087
vn 0.020 0.996 0.085
vn 0.033 0.996 0.081
vn 0.046 0.996 0.075
vn 0.057 0.996 0.066
vn 0.066 0.996 0.057
vn 0.075 0.996 0.046
vn 0.081 0.996 0.033
vn 0.085 0.996 0.020
vn 0.087 0.996 0.007
vn 0.087 0.996 0.007
vn 0.259 0.966 0.020
vn 0.259 0.966 0.020
vn 0.422 0.906 0.033
vn 0.422 0.906 0.033
vn 0.573 0.818 0.045
vn 0.573 0.818 0.045
vn 0.706 0.706 0.056
vn 0.706 0.706 0.056
vn 0.817 0.572 0.064
vn 0.817 0.572 0.064
vn 0.904 0.422 0.071
vn 0.904 0.421 0.071
vn 0.963 0.258 0.076
vn 0.963 0.258 0.076
vn 0.993 0.087 0.078
vn 0.993 0.087 0.078
vn 0.969 0.087 0.233
vn 0.085 0.996 0.020
vn 0.252 0.966 0.061
vn 0.252 0.966 0.061
vn 0.412 0.906 0.099
vn 0.412 0.906 0.099
vn 0.559 0.818 0.134
vn 0.559 0.818 0.134
vn 0.689 0.706 0.165
vn 0.689 0.706 0.165
vn 0.797 0.572 0.191
vn 0.797 0.572 0.191
vn 0.882 0.422 0.212
vn 0.882 0.422 0.212
vn 0.939 0.258 0.226
vn 0.939 0.258 0.226
vn 0.969 0.087 0.233
vn 0.969 0.087 0.233
vn 0.920 0.087 0.381
vn 0.081 0.996 0.033
vn 0.240 0.966 0.099
vn 0.240 0.966 0.099
vn 0.391 0.906 0.162
vn 0.391 0.906 0.162
vn 0.531 0.818 0.220
vn 0.531 0.818 0.220
vn 0.654 0.706 0.271
vn 0.654 0.706 0.271
vn 0.758 0.572 0.314
vn 0.758 0.572 0.314
vn 0.838 0.422 0.347
vn 0.838 0.422 0.347
vn 0.893 0.258 0.370
vn 0.893 0.258 0.370
vn 0.920 0.087 0.381
vn 0.920 0.087 0.381
vn 0.849 0.087 0.521
vn 0.075 0.996 0.046
vn 0.221 0.966 0.136
vn 0.221 0.966 0.136
vn 0.361 0.906 0.221
vn 0.361 0.906 0.221
vn 0.490 0.818 0.300
vn 0.490 0.818 0.300
vn 0.604 0.706 0.370
vn 0.604 0.706 0.370
vn 0.699 0.572 0.428
vn 0.699 0.572 0.428
vn 0.773 0.422 0.474
vn 0.773 0.422 0.474
vn 0.824 0.258 0.505
vn 0.824 0.258 0.505
vn 0.849 0.087 0.521
vn 0.849 0.087 0.521
vn 0.758 0.087 0.647
vn 0.066 0.996 0.057
vn 0.197 0.966 0.169
vn 0.197 0.966 0.169
vn 0.322 0.906 0.275
vn 0.322 0.906 0.275
vn 0.437 0.818 0.373
vn 0.437 0.818 0.373
vn 0.539 0.706 0.460
vn 0.539 0.706 0.460
vn 0.624 0.572 0.533
vn 0.624 0.572 0.533
vn 0.690 0.422 0.589
vn 0.690 0.422 0.589
vn 0.735 0.258 0.627
vn 0.735 0.258 0.627
vn 0.758 0.087 0.647
vn 0.758 0.087 0.647
vn 0.647 0.087 0.758
vn 0.057 0.996 0.066
vn 0.169 0.966 0.197
vn 0.169 0.966 0.197
vn 0.275 0.906 0.322
vn 0.275 0.906 0.322
vn 0.373 0.818 0.437
vn 0.373 0.818 0.437
vn 0.460 0.706 0.539
vn 0.460 0.706 0.539
vn 0.533 0.572 0.624
vn 0.533 0.572 0.624
vn 0.589 0.422 0.690
vn 0.589 0.422 0.690
vn 0.627 0.258 0.735
vn 0.627 0.258 0.735
vn 0.647 0.087 0.758
vn 0.647 0.087 0.758
vn 0.521 0.087 0.849
vn 0.046 0.996 0.075
vn 0.136 0.966 0.221
vn 0.136 0.966 0.221
vn 0.221 0.906 0.361
vn 0.221 0.906 0.361
vn 0.300 0.818 0.490
vn 0.300 0.818 0.490
vn 0.370 0.706 0.604
vn 0.370 0.706 0.604
vn 0.428 0.572 0.699
vn 0.428 0.572 0.699
vn 0.474 0.422 0.773
vn 0.474 0.422 0.773
vn 0.505 0.258 0.824
vn 0.505 0.258 0.824
vn 0.521 0.087 0.849
vn 0.521 0.087 0.849
vn 0.381 0.087 0.920
vn 0.033 0.996 0.081
vn 0.099 0.966 0.240
vn 0.099 0.966 0.240
vn 0.162 0.906 0.391
vn 0.162 0.906 0.391
vn 0.220 0.818 0.531
vn 0.220 0.818 0.531
vn 0.271 0.706 0.654
vn 0.271 0.706 0.654
vn 0.314 0.572 0.758
vn 0.314 0.572 0.758
vn 0.347 0.422 0.838
vn 0.347 0.422 0.838
vn 0.370 0.258 0.893
vn 0.370 0.258 0.893
vn 0.381 0.087 0.920
vn 0.381 0.087 0.920
vn 0.233 0.087 0.969
vn 0.020 0.996 0.085
vn 0.061 0.966 0.252
vn 0.061 0.966 0.252
vn 0.099 0.906 0.412
vn 0.099 0.906 0.412
vn 0.134 0.818 0.559
vn 0.134 0.818 0.559
vn 0.165 0.706 0.689
vn 0.165 0.706 0.689
vn 0.191 0.572 0.797
vn 0.191 0.572 0.797
vn 0.212 0.422 0.882
vn 0.212 0.422 0.882
vn 0.226 0.258 0.939
vn 0.226 0.258 0.939
vn 0.233 0.087 0.969
vn 0.233 0.087 0.969
vn 0.007 0.996 0.087
vn 0.020 0.966 0.259
vn 0.020 0.966 0.259
vn 0.033 0.906 0.422
vn 0.033 0.906 0.422
vn 0.045 0.818 0.573
vn 0.045 0.818 0.573
vn 0.056 0.706 0.706
vn 0.056 0.706 0.706
vn 0.064 0.572 0.817
vn 0.064 0.572 0.817
vn 0.071 0.422 0.904
vn 0.071 0.422 0.904
vn 0.076 0.258 0.963
vn 0.076 0.258 0.963
vn 0.078 0.087 0.993
vn 0.078 0.087 0.993
vn -0.087 0.996 0.007
vn -0.085 0.996 0.020
vn -0.081 0.996 0.033
vn -0.075 0.996 0.046
vn -0.066 0.996 0.057
vn -0.057 0.996 0.066
vn -0.046 0.996 0.075
vn -0.033 0.996 0.081
vn -0.020 0.996 0.085
vn -0.007 0.996 0.087
vn -0.007 0.996 0.087
vn -0.020 0.966 0.259
vn -0.020 0.966 0.259
vn -0.033 0.906 0.422
vn -0.033 0.906 0.422
vn -0.045 0.818 0.573
vn -0.045 0.818 0.573
vn -0.056 0.706 0.706
vn -0.056 0.706 0.706
vn -0.064 0.572 0.817
vn -0.064 0.572 0.817
vn -0.071 0.422 0.904
vn -0.071 0.422 0.904
vn -0.076 0.258 0.963
vn -0.076 0.258 0.963
vn -0.078 0.087 0.993
vn -0.078 0.087 0.993
vn -0.233 0.087 0.969
vn -0.020 0.996 0.085
vn -0.061 0.966 0.252
vn -0.061 0.966 0.252
vn -0.099 0.906 0.412
vn -0.099 0.906 0.412
vn -0.134 0.818 0.559
vn -0.134 0.818 0.559
vn -0.165 0.706 0.689
vn -0.165 0.706 0.689
vn -0.191 0.572 0.797
vn -0.191 0.572 0.797
vn -0.212 0.422 0.882
vn -0.212 0.422 0.882
vn -0.226 0.258 0.939
vn -0.226 0.258 0.939
vn -0.233 0.087 0.969
vn -0.233 0.087 0.969
vn -0.381 0.087 0.920
vn -0.033 0.996 0.081
vn -0.099 0.966 0.240
vn -0.099 0.966 0.240
vn -0.162 0.906 0.391
vn -0.162 0.906 0.391
vn -0.220 0.818 0.531
vn -0.220 0.818 0.531
vn -0.271 0.706 0.654
vn -0.271 0.706 0.654
vn -0.314 0.572 0.758
vn -0.314 0.572 0.758
vn -0.347 0.422 0.838
vn -0.347 0.422 0.838
vn -0.370 0.258 0.893
vn -0.370 0.258 0.893
vn -0.381 0.087 0.920
vn -0.381 0.087 0.920
vn -0.521 0.087 0.849
vn -0.046 0.996 0.075
vn -0.136 0.966 0.221
vn -0.136 0.966 0.221
vn -0.221 0.906 0.361
vn -0.221 0.906 0.361
vn -0.300 0.818 0.490
vn -0.300 0.818 0.490
vn -0.370 0.706 0.604
vn -0.370 0.706 0.604
vn -0.428 0.572 0.699
vn -0.428 0.572 0.699
vn -0.474 0.422 0.773
vn -0.474 0.422 0.773
vn -0.505 0.258 0.824
vn -0.505 0.258 0.824
vn -0.521 0.087 0.849
vn -0.521 0.087 0.849
vn -0.647 0.087 0.758
vn -0.057 0.996 0.066
vn -0.169 0.966 0.197
vn -0.169 0.966 0.197
vn -0.275 0.906 0.322
vn -0.275 0.906 0.322
vn -0.373 0.818 0.437
vn -0.373 0.818 0.437
vn -0.460 0.706 0.539
vn -0.460 0.706 0.539
vn -0.533 0.572 0.624
vn -0.533 0.572 0.624
vn -0.589 0.422 0.690
vn -0.589 0.422 0.690
vn -0.627 0.258 0.735
vn -0.627 0.258 0.735
vn -0.647 0.087 0.758
vn -0.647 0.087 0.758
vn -0.758 0.087 0.647
vn -0.066 0.996 0.057
vn -0.197 0.966 0.169
vn -0.197 0.966 0.169
vn -0.322 0.906 0.275
vn -0.322 0.906 0.275
vn -0.437 0.818 0.373
vn -0.437 0.818 0.373
vn -0.539 0.706 0.460
vn -0.539 0.706 0.460
vn -0.624 0.572 0.533
vn -0.624 0.572 0.533
vn -0.690 0.422 0.589
vn -0.690 0.422 0.589
vn -0.735 0.258 0.627
vn -0.735 0.258 0.627
vn -0.758 0.087 0.647
vn -0.758 0.087 0.647
vn -0.849 0.087 0.521
vn -0.075 0.996 0.046
vn -0.221 0.966 0.136
vn -0.221 0.966 0.136
vn -0.361 0.906 0.221
vn -0.361 0.906 0.221
vn -0.490 0.818 0.300
vn -0.490 0.818 0.300
vn -0.604 0.706 0.370
vn -0.604 0.706 0.370
vn -0.699 0.572 0.428
vn -0.699 0.572 0.428
vn -0.773 0.422 0.474
vn -0.773 0.422 0.474
vn -0.824 0.258 0.505
vn -0.824 0.258 0.505
vn -0.849 0.087 0.521
vn -0.849 0.087 0.521
vn -0.920 0.087 0.381
vn -0.081 0.996 0.033
vn -0.240 0.966 0.099
vn -0.240 0.966 0.099
vn -0.391 0.906 0.162
vn -0.391 0.906 0.162
vn -0.531 0.818 0.220
vn -0.531 0.818 0.220
vn -0.654 0.706 0.271
vn -0.654 0.706 0.271
vn -0.758 0.572 0.314
vn -0.758 0.572 0.314
vn -0.838 0.422 0.347
vn -0.838 0.422 0.347
vn -0.893 0.258 0.370
vn -0.893 0.258 0.370
vn -0.920 0.087 0.381
vn -0.920 0.087 0.381
vn -0.969 0.087 0.233
vn -0.085 0.996 0.020
vn -0.252 0.966 0.061
vn -0.252 0.966 0.061
vn -0.412 0.906 0.099
vn -0.412 0.906 0.099
vn -0.559 0.818 0.134
vn -0.559 0.818 0.134
vn -0.689 0.706 0.165
vn -0.689 0.706 0.165
vn -0.797 0.572 0.191
vn -0.797 0.572 0.191
vn -0.882 0.422 0.212
vn -0.882 0.422 0.212
vn -0.939 0.258 0.226
vn -0.939 0.258 0.226
vn -0.969 0.087 0.233
vn -0.969 0.087 0.233
vn -0.087 0.996 0.007
vn -0.259 0.966 0.020
vn -0.259 0.966 0.020
vn -0.422 0.906 0.033
vn -0.422 0.906 0.033
vn -0.573 0.818 0.045
vn -0.573 0.818 0.045
vn -0.706 0.706 0.056
vn -0.706 0.706 0.056
vn -0.817 0.572 0.064
vn -0.817 0.572 0.064
vn -0.904 0.422 0.071
vn -0.904 0.422 0.071
vn -0.963 0.258 0.076
vn -0.963 0.258 0.076
vn -0.993 0.087 0.078
vn -0.993 0.087 0.078
vn -0.087 0.996 0.000
vn -0.087 0.996 0.000
vn -0.259 0.966 0.000
vn -0.259 0.966 0.000
vn -0.423 0.906 0.000
vn -0.423 0.906 0.000
vn -0.574 0.819 0.000
vn -0.574 0.819 0.000
vn -0.707 0.707 0.000
vn -0.707 0.707 0.000
vn -0.819 0.574 0.000
vn -0.819 0.574 0.000
vn -0.906 0.423 0.000
vn -0.906 0.423 0.000
vn -0.966 0.259 0.000
vn -0.966 0.259 0.000
vn -0.996 0.087 0.000
vn -0.996 0.087 0.000
vn -0.045 -0.999 0.000
vn -0.535 -0.845 0.000
vn -0.602 -0.799 0.001
vn -0.574 -0.819 0.000
vn -0.707 -0.707 0.000
vn -0.707 -0.707 0.000
vn -0.819 -0.574 0.000
vn -0.819 -0.574 0.000
vn -0.906 -0.423 0.000
vn -0.906 -0.423 0.000
vn -0.966 -0.259 0.000
vn -0.966 -0.259 0.000
vn -0.996 -0.087 0.000
vn -0.996 -0.087 0.000
vn -0.137 -0.991 0.000
vn -0.136 -0.991 0.000
vn -0.087 -0.996 0.000
vn -0.136 -0.991 0.001
vn -0.045 -0.999 0.000
vn -0.340 -0.941 0.002
vn -0.259 -0.966 0.000
vn -0.340 -0.940 0.000
vn -0.244 -0.970 0.000
vn -0.243 -0.970 0.000
vn -0.188 -0.982 0.000
vn -0.459 -0.888 0.000
vn -0.460 -0.888 0.000
vn -0.423 -0.906 0.001
vn -0.459 -0.888 0.001
vn -0.398 -0.917 0.000
vn -0.078 -0.087 0.993
vn -0.078 -0.087 0.993
vn -0.233 -0.087 0.969
vn -0.233 -0.087 0.969
vn -0.233 -0.087 0.969
vn -0.381 -0.087 0.920
vn -0.381 -0.087 0.920
vn -0.381 -0.087 0.920
vn -0.521 -0.087 0.849
vn -0.521 -0.087 0.849
vn -0.521 -0.087 0.849
vn -0.647 -0.087 0.758
vn -0.647 -0.087 0.758
vn -0.647 -0.087 0.758
vn -0.758 -0.087 0.647
vn -0.758 -0.087 0.647
vn -0.758 -0.087 0.647
vn -0.849 -0.087 0.521
vn -0.849 -0.087 0.521
vn -0.849 -0.087 0.521
vn -0.920 -0.087 0.381
vn -0.920 -0.087 0.381
vn -0.993 -0.087 0.078
vn -0.993 -0.087 0.078
vn -0.969 -0.087 0.233
vn -0.969 -0.087 0.233
vn -0.969 -0.087 0.233
vn -0.920 -0.087 0.381
vn -0.963 -0.258 0.076
vn -0.963 -0.258 0.076
vn -0.939 -0.258 0.226
vn -0.939 -0.258 0.226
vn -0.893 -0.258 0.370
vn -0.893 -0.258 0.370
vn -0.824 -0.258 0.505
vn -0.824 -0.258 0.505
vn -0.735 -0.258 0.627
vn -0.735 -0.258 0.627
vn -0.627 -0.258 0.735
vn -0.627 -0.258 0.735
vn -0.505 -0.258 0.824
vn -0.505 -0.258 0.824
vn -0.370 -0.258 0.893
vn -0.370 -0.258 0.893
vn -0.226 -0.258 0.939
vn -0.226 -0.258 0.939
vn -0.076 -0.258 0.963
vn -0.076 -0.258 0.963
vn -0.904 -0.422 0.071
vn -0.904 -0.422 0.071
vn -0.882 -0.422 0.212
vn -0.882 -0.422 0.212
vn -0.838 -0.422 0.347
vn -0.838 -0.422 0.347
vn -0.773 -0.422 0.474
vn -0.773 -0.422 0.474
vn -0.690 -0.422 0.589
vn -0.690 -0.422 0.589
vn -0.589 -0.422 0.690
vn -0.589 -0.422 0.690
vn -0.474 -0.422 0.773
vn -0.474 -0.422 0.773
vn -0.347 -0.422 0.838
vn -0.347 -0.422 0.838
vn -0.212 -0.422 0.882
vn -0.212 -0.422 0.882
vn -0.071 -0.422 0.904
vn -0.071 -0.422 0.904
vn -0.817 -0.572 0.064
vn -0.817 -0.572 0.064
vn -0.797 -0.572 0.191
vn -0.797 -0.572 0.191
vn -0.758 -0.572 0.314
vn -0.758 -0.572 0.314
vn -0.699 -0.572 0.428
vn -0.699 -0.572 0.428
vn -0.624 -0.572 0.533
vn -0.624 -0.572 0.533
vn -0.533 -0.572 0.624
vn -0.533 -0.572 0.624
vn -0.428 -0.572 0.699
vn -0.428 -0.572 0.699
vn -0.314 -0.572 0.758
vn -0.314 -0.572 0.758
vn -0.191 -0.572 0.797
vn -0.191 -0.572 0.797
vn -0.064 -0.572 0.817
vn -0.064 -0.572 0.817
vn -0.706 -0.706 0.056
vn -0.706 -0.706 0.056
vn -0.689 -0.706 0.165
vn -0.689 -0.706 0.165
vn -0.654 -0.706 0.271
vn -0.654 -0.706 0.271
vn -0.604 -0.706 0.370
vn -0.604 -0.706 0.370
vn -0.539 -0.706 0.460
vn -0.539 -0.706 0.460
vn -0.460 -0.706 0.539
vn -0.460 -0.706 0.539
vn -0.370 -0.706 0.604
vn -0.370 -0.706 0.604
vn -0.271 -0.706 0.654
vn -0.271 -0.706 0.654
vn -0.165 -0.706 0.689
vn -0.165 -0.706 0.689
vn -0.056 -0.706 0.706
vn -0.056 -0.706 0.706
vn -0.573 -0.818 0.045
vn -0.573 -0.818 0.045
vn -0.559 -0.818 0.134
vn -0.559 -0.818 0.134
vn -0.531 -0.818 0.220
vn -0.531 -0.818 0.220
vn -0.490 -0.818 0.300
vn -0.490 -0.818 0.300
vn -0.437 -0.818 0.373
vn -0.437 -0.818 0.373
vn -0.373 -0.818 0.437
vn -0.373 -0.818 0.437
vn -0.300 -0.818 0.490
vn -0.300 -0.818 0.490
vn -0.220 -0.818 0.531
vn -0.220 -0.818 0.531
vn -0.134 -0.818 0.559
vn -0.134 -0.818 0.559
vn -0.045 -0.818 0.573
vn -0.045 -0.818 0.573
vn -0.422 -0.906 0.033
vn -0.422 -0.906 0.033
vn -0.412 -0.906 0.099
vn -0.412 -0.906 0.099
vn -0.391 -0.906 0.162
vn -0.391 -0.906 0.162
vn -0.361 -0.906 0.221
vn -0.361 -0.906 0.221
vn -0.322 -0.906 0.275
vn -0.322 -0.906 0.275
vn -0.275 -0.906 0.322
vn -0.275 -0.906 0.322
vn -0.221 -0.906 0.361
vn -0.221 -0.906 0.361
vn -0.162 -0.906 0.391
vn -0.162 -0.906 0.391
vn -0.099 -0.906 0.412
vn -0.099 -0.906 0.412
vn -0.033 -0.906 0.422
vn -0.033 -0.906 0.422
vn -0.259 -0.966 0.020
vn -0.259 -0.966 0.020
vn -0.252 -0.966 0.061
vn -0.252 -0.966 0.061
vn -0.240 -0.966 0.099
vn -0.240 -0.966 0.099
vn -0.221 -0.966 0.136
vn -0.221 -0.966 0.136
vn -0.197 -0.966 0.169
vn -0.197 -0.966 0.169
vn -0.169 -0.966 0.197
vn -0.169 -0.966 0.197
vn -0.136 -0.966 0.221
vn -0.136 -0.966 0.221
vn -0.099 -0.966 0.240
vn -0.099 -0.966 0.240
vn -0.061 -0.966 0.252
vn -0.061 -0.966 0.252
vn -0.020 -0.966 0.259
vn -0.020 -0.966 0.259
vn -0.087 -0.996 0.007
vn -0.087 -0.996 0.007
vn -0.085 -0.996 0.020
vn -0.085 -0.996 0.020
vn -0.081 -0.996 0.033
vn -0.081 -0.996 0.033
vn -0.075 -0.996 0.046
vn -0.075 -0.996 0.046
vn -0.066 -0.996 0.057
vn -0.066 -0.996 0.057
vn -0.057 -0.996 0.066
vn -0.057 -0.996 0.066
vn -0.046 -0.996 0.075
vn -0.046 -0.996 0.075
vn -0.033 -0.996 0.081
vn -0.033 -0.996 0.081
vn -0.020 -0.996 0.085
vn -0.020 -0.996 0.085
vn -0.007 -0.996 0.087
vn -0.007 -0.996 0.087
vn 0.993 -0.087 0.078
vn 0.993 -0.087 0.078
vn 0.969 -0.087 0.233
vn 0.969 -0.087 0.233
vn 0.969 -0.087 0.233
vn 0.920 -0.087 0.381
vn 0.920 -0.087 0.381
vn 0.920 -0.087 0.381
vn 0.849 -0.087 0.521
vn 0.849 -0.087 0.521
vn 0.849 -0.087 0.521
vn 0.758 -0.087 0.647
vn 0.758 -0.087 0.647
vn 0.758 -0.087 0.647
vn 0.647 -0.087 0.758
vn 0.647 -0.087 0.758
vn 0.647 -0.087 0.758
vn 0.521 -0.087 0.849
vn 0.521 -0.087 0.849
vn 0.521 -0.087 0.849
vn 0.381 -0.087 0.920
vn 0.381 -0.087 0.920
vn 0.078 -0.087 0.993
vn 0.078 -0.087 0.993
vn 0.233 -0.087 0.969
vn 0.233 -0.087 0.969
vn 0.233 -0.087 0.969
vn 0.381 -0.087 0.920
vn 0.076 -0.258 0.963
vn 0.076 -0.258 0.963
vn 0.226 -0.258 0.939
vn 0.226 -0.258 0.939
vn 0.370 -0.258 0.893
vn 0.370 -0.258 0.893
vn 0.505 -0.258 0.824
vn 0.505 -0.258 0.824
vn 0.627 -0.258 0.735
vn 0.627 -0.258 0.735
vn 0.735 -0.258 0.627
vn 0.735 -0.258 0.627
vn 0.824 -0.258 0.505
vn 0.824 -0.258 0.505
vn 0.893 -0.258 0.370
vn 0.893 -0.258 0.370
vn 0.939 -0.258 0.226
vn 0.939 -0.258 0.226
vn 0.963 -0.258 0.076
vn 0.963 -0.258 0.076
vn 0.071 -0.422 0.904
vn 0.071 -0.422 0.904
vn 0.212 -0.422 0.882
vn 0.212 -0.422 0.882
vn 0.347 -0.422 0.838
vn 0.347 -0.422 0.838
vn 0.474 -0.422 0.773
vn 0.474 -0.422 0.773
vn 0.589 -0.422 0.690
vn 0.589 -0.422 0.690
vn 0.690 -0.422 0.589
vn 0.690 -0.422 0.589
vn 0.773 -0.422 0.474
vn 0.773 -0.422 0.474
vn 0.838 -0.422 0.347
vn 0.838 -0.422 0.347
vn 0.882 -0.422 0.212
vn 0.882 -0.422 0.212
vn 0.904 -0.422 0.071
vn 0.904 -0.421 0.071
vn 0.064 -0.572 0.817
vn 0.064 -0.572 0.817
vn 0.191 -0.572 0.797
vn 0.191 -0.572 0.797
vn 0.314 -0.572 0.758
vn 0.314 -0.572 0.758
vn 0.428 -0.572 0.699
vn 0.428 -0.572 0.699
vn 0.533 -0.572 0.624
vn 0.533 -0.572 0.624
vn 0.624 -0.572 0.533
vn 0.624 -0.572 0.533
vn 0.699 -0.572 0.428
vn 0.699 -0.572 0.428
vn 0.758 -0.572 0.314
vn 0.758 -0.572 0.314
vn 0.797 -0.572 0.191
vn 0.797 -0.572 0.191
vn 0.817 -0.572 0.064
vn 0.817 -0.572 0.064
vn 0.056 -0.706 0.706
vn 0.056 -0.706 0.706
vn 0.165 -0.706 0.689
vn 0.165 -0.706 0.689
vn 0.271 -0.706 0.654
vn 0.271 -0.706 0.654
vn 0.370 -0.706 0.604
vn 0.370 -0.706 0.604
vn 0.460 -0.706 0.539
vn 0.460 -0.706 0.539
vn 0.539 -0.706 0.460
vn 0.539 -0.706 0.460
vn 0.604 -0.706 0.370
vn 0.604 -0.706 0.370
vn 0.654 -0.706 0.271
vn 0.654 -0.706 0.271
vn 0.689 -0.706 0.165
vn 0.689 -0.706 0.165
vn 0.706 -0.706 0.056
vn 0.706 -0.706 0.056
vn 0.045 -0.818 0.573
vn 0.045 -0.818 0.573
vn 0.134 -0.818 0.559
vn 0.134 -0.818 0.559
vn 0.220 -0.818 0.531
vn 0.220 -0.818 0.531
vn 0.300 -0.818 0.490
vn 0.300 -0.818 0.490
vn 0.373 -0.818 0.437
vn 0.373 -0.818 0.437
vn 0.437 -0.818 0.373
vn 0.437 -0.818 0.373
vn 0.490 -0.818 0.300
vn 0.490 -0.818 0.300
vn 0.531 -0.818 0.220
vn 0.531 -0.818 0.220
vn 0.559 -0.818 0.134
vn 0.559 -0.818 0.134
vn 0.573 -0.818 0.045
vn 0.573 -0.818 0.045
vn 0.033 -0.906 0.422
vn 0.033 -0.906 0.422
vn 0.099 -0.906 0.412
vn 0.099 -0.906 0.412
vn 0.162 -0.906 0.391
vn 0.162 -0.906 0.391
vn 0.221 -0.906 0.361
vn 0.221 -0.906 0.361
vn 0.275 -0.906 0.322
vn 0.275 -0.906 0.322
vn 0.322 -0.906 0.275
vn 0.322 -0.906 0.275
vn 0.361 -0.906 0.221
vn 0.361 -0.906 0.221
vn 0.391 -0.906 0.162
vn 0.391 -0.906 0.162
vn 0.412 -0.906 0.099
vn 0.412 -0.906 0.099
vn 0.422 -0.906 0.033
vn 0.422 -0.906 0.033
vn 0.020 -0.966 0.259
vn 0.020 -0.966 0.259
vn 0.061 -0.966 0.252
vn 0.061 -0.966 0.252
vn 0.099 -0.966 0.240
vn 0.099 -0.966 0.240
vn 0.136 -0.966 0.221
vn 0.136 -0.966 0.221
vn 0.169 -0.966 0.197
vn 0.169 -0.966 0.197
vn 0.197 -0.966 0.169
vn 0.197 -0.966 0.169
vn 0.221 -0.966 0.136
vn 0.221 -0.966 0.136
vn 0.240 -0.966 0.099
vn 0.240 -0.966 0.099
vn 0.252 -0.966 0.061
vn 0.252 -0.966 0.061
vn 0.259 -0.966 0.020
vn 0.259 -0.966 0.020
vn 0.007 -0.996 0.087
vn 0.007 -0.996 0.087
vn 0.020 -0.996 0.085
vn 0.020 -0.996 0.085
vn 0.033 -0.996 0.081
vn 0.033 -0.996 0.081
vn 0.046 -0.996 0.075
vn 0.046 -0.996 0.075
vn 0.057 -0.996 0.066
vn 0.057 -0.996 0.066
vn 0.066 -0.996 0.057
vn 0.066 -0.996 0.057
vn 0.075 -0.996 0.046
vn 0.075 -0.996 0.046
vn 0.081 -0.996 0.033
vn 0.081 -0.996 0.033
vn 0.085 -0.996 0.020
vn 0.085 -0.996 0.020
vn 0.087 -0.996 0.007
vn 0.087 -0.996 0.007
vn 0.707 -0.707 0.000
vn 0.819 -0.574 0.000
vn 0.819 -0.574 0.000
vn 0.906 -0.423 0.000
vn 0.906 -0.423 0.000
vn 0.966 -0.259 0.000
vn 0.966 -0.259 0.000
vn 0.996 -0.087 0.000
vn 0.996 -0.087 0.000
vn 0.574 -0.819 0.001
vn 0.602 -0.799 0.000
vn 0.707 -0.707 0.000
vn 0.359 -0.933 0.000
vn 0.413 -0.911 0.000
vn 0.413 -0.911 0.000
vn 0.423 -0.906 0.001
vn 0.511 -0.859 0.000
vn 0.511 -0.860 0.000
vn 0.259 -0.966 0.000
vn 0.303 -0.953 0.000
vn 0.304 -0.953 0.000
vn 0.023 -1.000 0.000
vn 0.207 -0.978 0.000
vn 0.207 -0.978 0.000
vn 0.303 -0.953 0.001
vn 0.022 -1.000 0.000
vn 0.093 -0.996 0.001
vn 0.087 -0.996 0.000
vn 0.149 -0.989 0.000
vn 0.146 -0.989 0.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn -0.237 0.000 0.972
vn -0.237 0.000 0.972
vn -0.237 0.000 0.972
vn -0.237 0.000 0.972
vn -0.237 0.000 0.972
vn -0.237 0.000 0.972
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.237 0.000 0.972
vn 0.237 0.000 0.972
vn 0.237 0.000 0.972
vn 0.237 0.000 0.972
vn 0.237 0.000 0.972
vn 0.237 0.000 0.972
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
usemtl material_1
f 1//3185 2//3185 3//3185
f 3//3186 2//3186 4//3186
f 3//3187 4//3187 5//3187
f 6//3182 7//3182 8//3182
f 6//3183 8//3183 1//3183
f 1//3184 8//3184 2//3184
f 9//3191 10//3191 11//3191
f 12//5714 13//5714 14//5714
f 12//5715 14//5715 15//5715
f 5//3188 4//3188 16//3188
f 5//3189 16//3189 9//3189
f 9//3190 16//3190 10//3190
f 17//5695 18//5695 19//5695
f 20//5696 21//5696 22//5696
f 20//5697 22//5697 23//5697
f 24//5692 25//5692 26//5692
f 24//5693 26//5693 17//5693
f 17//5694 26//5694 18//5694
f 27//5701 28//5701 29//5701
f 30//3180 31//3180 7//3180
f 30//3181 7//3181 6//3181
f 23//5698 22//5698 32//5698
f 23//5699 32//5699 27//5699
f 27//5700 32//5700 28//5700
f 15//5716 14//5716 33//5716
f 34//3194 35//3194 36//3194
f 34//3195 36//3195 37//3195
f 37//3196 36//3196 38//3196
f 39//5729 40//5729 41//5729
f 11//3192 10//3192 35//3192
f 11//3193 35//3193 34//3193
f 42//3200 43//3200 44//3200
f 42//3201 44//3201 45//3201
f 46//5740 47//5740 48//5740
f 37//3197 38//3197 49//3197
f 49//3198 38//3198 43//3198
f 49//3199 43//3199 42//3199
f 50//5720 51//5720 52//5720
f 50//5721 52//5721 53//5721
f 54//5722 55//5722 56//5722
f 15//5717 33//5717 57//5717
f 57//5718 33//5718 51//5718
f 57//5719 51//5719 50//5719
f 58//5726 59//5726 60//5726
f 58//5727 60//5727 39//5727
f 39//5728 60//5728 40//5728
f 54//5723 56//5723 61//5723
f 61//5724 56//5724 59//5724
f 61//5725 59//5725 58//5725
f 62//3155 63//3155 64//3155
f 64//3156 63//3156 65//3156
f 64//3157 65//3157 66//3157
f 67//3152 68//3152 69//3152
f 67//3153 69//3153 62//3153
f 62//3154 69//3154 63//3154
f 70//6326 71//6326 72//6326
f 73//6321 74//6321 75//6321
f 76//6320 74//6320 73//6320
f 66//3158 65//3158 77//3158
f 66//3159 77//3159 78//3159
f 78//3160 77//3160 79//3160
f 80//3143 81//3143 82//3143
f 82//3144 81//3144 83//3144
f 82//3145 83//3145 84//3145
f 85//2148 86//2148 87//2148
f 88//3141 89//3141 80//3141
f 80//3142 89//3142 81//3142
f 90//3149 91//3149 92//3149
f 92//3150 91//3150 68//3150
f 92//3151 68//3151 67//3151
f 84//3146 83//3146 93//3146
f 84//3147 93//3147 90//3147
f 90//3148 93//3148 91//3148
f 94//6311 95//6311 96//6311
f 97//5683 98//5683 99//5683
f 99//5684 98//5684 100//5684
f 99//5685 100//5685 101//5685
f 102//5680 103//5680 104//5680
f 102//5681 104//5681 97//5681
f 97//5682 104//5682 98//5682
f 105//5689 106//5689 107//5689
f 107//5690 106//5690 25//5690
f 107//5691 25//5691 24//5691
f 108//5686 109//5686 110//5686
f 108//5687 110//5687 105//5687
f 105//5688 110//5688 106//5688
f 111//5671 112//5671 113//5671
f 113//5672 112//5672 114//5672
f 113//5673 114//5673 115//5673
f 116//6310 95//6310 94//6310
f 117//3179 31//3179 30//3179
f 111//5670 118//5670 112//5670
f 119//5677 120//5677 121//5677
f 121//5678 120//5678 103//5678
f 121//5679 103//5679 102//5679
f 115//5674 114//5674 122//5674
f 115//5675 122//5675 123//5675
f 119//5676 124//5676 120//5676
f 125//3862 126//3862 127//3862
f 128//3861 129//3861 130//3861
f 131//3860 129//3860 128//3860
f 132//3865 133//3865 134//3865
f 135//3864 133//3864 132//3864
f 127//3863 126//3863 136//3863
f 137//3856 138//3856 139//3856
f 137//3855 140//3855 138//3855
f 141//3854 140//3854 137//3854
f 131//3859 142//3859 129//3859
f 143//3858 142//3858 131//3858
f 139//3857 138//3857 144//3857
f 145//3874 146//3874 147//3874
f 148//3873 149//3873 150//3873
f 151//3872 149//3872 148//3872
f 152//3877 153//3877 154//3877
f 155//3876 153//3876 152//3876
f 147//3875 146//3875 156//3875
f 157//5777 158//5777 159//5777
f 160//3867 161//3867 162//3867
f 163//3866 161//3866 160//3866
f 164//3871 165//3871 166//3871
f 167//3870 165//3870 164//3870
f 157//5776 168//5776 158//5776
f 141//3853 169//3853 140//3853
f 170//3837 129//3837 171//3837
f 170//3836 172//3836 129//3836
f 173//3835 172//3835 170//3835
f 174//3840 175//3840 176//3840
f 177//3839 175//3839 174//3839
f 171//3838 129//3838 178//3838
f 129//3831 172//3831 179//3831
f 174//3830 180//3830 177//3830
f 181//1205 182//1205 183//1205
f 173//3834 180//3834 172//3834
f 184//3833 180//3833 173//3833
f 185//3832 186//3832 169//3832
f 142//3849 187//3849 129//3849
f 188//3848 187//3848 142//3848
f 174//3847 189//3847 190//3847
f 174//3852 169//3852 141//3852
f 174//3851 185//3851 169//3851
f 190//3850 185//3850 174//3850
f 129//3843 191//3843 192//3843
f 179//3842 191//3842 129//3842
f 174//3841 176//3841 193//3841
f 174//3846 194//3846 189//3846
f 193//3845 194//3845 174//3845
f 129//3844 192//3844 130//3844
f 45//3202 44//3202 195//3202
f 45//3203 195//3203 196//3203
f 196//3204 195//3204 197//3204
f 198//5753 199//5753 200//5753
f 200//5754 199//5754 201//5754
f 200//5755 201//5755 202//5755
f 203//3208 204//3208 205//3208
f 203//3209 205//3209 206//3209
f 206//3210 205//3210 207//3210
f 196//3205 197//3205 208//3205
f 208//3206 197//3206 204//3206
f 208//3207 204//3207 203//3207
f 209//5744 210//5744 211//5744
f 209//5745 211//5745 212//5745
f 213//5746 214//5746 215//5746
f 46//5741 48//5741 216//5741
f 216//5742 48//5742 210//5742
f 216//5743 210//5743 209//5743
f 217//5750 218//5750 219//5750
f 217//5751 219//5751 198//5751
f 198//5752 219//5752 199//5752
f 213//5747 215//5747 220//5747
f 220//5748 215//5748 218//5748
f 220//5749 218//5749 217//5749
f 206//3211 207//3211 89//3211
f 221//3886 222//3886 223//3886
f 224//3885 225//3885 226//3885
f 227//3884 225//3884 224//3884
f 228//3889 229//3889 230//3889
f 231//3888 229//3888 228//3888
f 223//3887 222//3887 232//3887
f 233//3880 234//3880 235//3880
f 236//3879 237//3879 238//3879
f 239//3878 237//3878 236//3878
f 240//5774 241//5774 168//5774
f 240//5775 168//5775 157//5775
f 235//3881 234//3881 242//3881
f 243//5769 244//5769 245//5769
f 245//5770 244//5770 246//5770
f 245//5771 246//5771 247//5771
f 248//5766 249//5766 250//5766
f 248//5767 250//5767 243//5767
f 243//5768 250//5768 244//5768
f 247//5773 251//5773 252//5773
f 253//3891 254//3891 255//3891
f 256//3890 254//3890 253//3890
f 257//3895 258//3895 259//3895
f 260//3894 258//3894 257//3894
f 247//5772 246//5772 251//5772
f 261//2149 262//2149 263//2149
f 264//3055 265//3055 266//3055
f 266//3056 265//3056 267//3056
f 266//3057 267//3057 268//3057
f 269//3052 270//3052 271//3052
f 269//3053 271//3053 264//3053
f 264//3054 271//3054 265//3054
f 272//3061 273//3061 274//3061
f 274//3062 273//3062 275//3062
f 274//3063 275//3063 276//3063
f 268//3058 267//3058 277//3058
f 268//3059 277//3059 272//3059
f 272//3060 277//3060 273//3060
f 278//3043 279//3043 280//3043
f 280//3044 279//3044 281//3044
f 280//3045 281//3045 282//3045
f 283//3040 284//3040 285//3040
f 283//3041 285//3041 278//3041
f 278//3042 285//3042 279//3042
f 286//3049 287//3049 288//3049
f 288//3050 287//3050 270//3050
f 288//3051 270//3051 269//3051
f 282//3046 281//3046 289//3046
f 282//3047 289//3047 286//3047
f 286//3048 289//3048 287//3048
f 276//3064 275//3064 290//3064
f 291//3074 82//3074 84//3074
f 291//3075 84//3075 292//3075
f 292//3076 84//3076 90//3076
f 293//4158 294//4158 295//4158
f 136//4157 294//4157 293//4157
f 136//4156 126//4156 294//4156
f 296//3080 92//3080 67//3080
f 296//3081 67//3081 297//3081
f 297//3082 67//3082 62//3082
f 292//3077 90//3077 298//3077
f 298//3078 90//3078 92//3078
f 298//3079 92//3079 296//3079
f 299//3068 300//3068 88//3068
f 299//3069 88//3069 301//3069
f 301//3070 88//3070 80//3070
f 276//3065 290//3065 302//3065
f 302//3066 290//3066 300//3066
f 302//3067 300//3067 299//3067
f 303//4161 304//4161 305//4161
f 303//4160 295//4160 304//4160
f 293//4159 295//4159 303//4159
f 301//3071 80//3071 306//3071
f 306//3072 80//3072 82//3072
f 306//3073 82//3073 291//3073
f 307//4230 308//4230 309//4230
f 310//4229 308//4229 307//4229
f 310//4228 311//4228 308//4228
f 312//4233 167//4233 164//4233
f 312//4232 309//4232 167//4232
f 307//4231 309//4231 312//4231
f 151//4224 148//4224 313//4224
f 314//4223 232//4223 222//4223
f 314//4222 315//4222 232//4222
f 316//4227 311//4227 310//4227
f 316//4226 313//4226 311//4226
f 151//4225 313//4225 316//4225
f 317//3024 318//3024 319//3024
f 317//3025 319//3025 320//3025
f 320//3026 319//3026 321//3026
f 322//3021 323//3021 324//3021
f 324//3022 323//3022 318//3022
f 324//3023 318//3023 317//3023
f 325//4236 326//4236 327//4236
f 150//4235 326//4235 325//4235
f 150//4234 149//4234 326//4234
f 328//4239 145//4239 147//4239
f 328//4238 327//4238 145//4238
f 325//4237 327//4237 328//4237
f 329//4221 315//4221 314//4221
f 330//3031 331//3031 332//3031
f 332//3032 331//3032 333//3032
f 332//3033 333//3033 334//3033
f 231//4208 228//4208 335//4208
f 336//3029 337//3029 330//3029
f 330//3030 337//3030 331//3030
f 338//3037 339//3037 340//3037
f 340//3038 339//3038 284//3038
f 340//3039 284//3039 283//3039
f 334//3034 333//3034 341//3034
f 334//3035 341//3035 338//3035
f 338//3036 341//3036 339//3036
f 342//4217 343//4217 344//4217
f 342//4216 345//4216 343//4216
f 227//4215 345//4215 342//4215
f 329//4220 346//4220 315//4220
f 344//4219 346//4219 329//4219
f 344//4218 343//4218 346//4218
f 347//4211 348//4211 349//4211
f 347//4210 335//4210 348//4210
f 231//4209 335//4209 347//4209
f 227//4214 224//4214 345//4214
f 349//4213 221//4213 223//4213
f 349//4212 348//4212 221//4212
f 263//2171 87//2171 261//2171
f 263//2170 85//2170 87//2170
f 350//2169 85//2169 263//2169
f 351//2174 352//2174 353//2174
f 261//2173 354//2173 355//2173
f 261//2172 87//2172 354//2172
f 356//2165 357//2165 358//2165
f 359//5602 360//5602 361//5602
f 359//5603 361//5603 362//5603
f 363//2168 364//2168 365//2168
f 358//2167 364//2167 363//2167
f 358//2166 357//2166 364//2166
f 300//3139 206//3139 88//3139
f 88//3140 206//3140 89//3140
f 366//2181 352//2181 351//2181
f 290//3136 208//3136 203//3136
f 290//3137 203//3137 300//3137
f 300//3138 203//3138 206//3138
f 367//2177 368//2177 369//2177
f 353//2176 368//2176 367//2176
f 353//2175 352//2175 368//2175
f 370//2180 352//2180 366//2180
f 370//2179 371//2179 352//2179
f 372//2178 371//2178 370//2178
f 362//5604 361//5604 373//5604
f 374//5620 375//5620 376//5620
f 374//5621 376//5621 377//5621
f 377//5622 376//5622 378//5622
f 379//2155 324//2155 320//2155
f 380//5618 381//5618 375//5618
f 380//5619 375//5619 374//5619
f 352//2152 382//2152 368//2152
f 379//2151 87//2151 322//2151
f 324//2150 317//2150 320//2150
f 377//5623 378//5623 383//5623
f 322//2154 324//2154 379//2154
f 368//2153 320//2153 369//2153
f 263//2164 384//2164 350//2164
f 263//2163 364//2163 384//2163
f 365//2162 364//2162 263//2162
f 362//5605 373//5605 385//5605
f 385//5606 373//5606 386//5606
f 385//5607 386//5607 387//5607
f 388//2158 368//2158 389//2158
f 388//2157 320//2157 368//2157
f 379//2156 320//2156 388//2156
f 87//2161 390//2161 354//2161
f 87//2160 391//2160 390//2160
f 379//2159 391//2159 87//2159
f 182//6331 392//6331 393//6331
f 181//6330 392//6330 182//6330
f 72//6327 71//6327 394//6327
f 395//3089 78//3089 396//3089
f 396//3090 78//3090 397//3090
f 396//3091 397//3091 323//3091
f 339//3108 117//3108 30//3108
f 339//3109 30//3109 284//3109
f 284//3110 30//3110 6//3110
f 333//3105 398//3105 341//3105
f 341//3106 398//3106 117//3106
f 341//3107 117//3107 339//3107
f 399//4143 400//4143 401//4143
f 399//4142 402//4142 400//4142
f 230//4141 402//4141 399//4141
f 297//3083 62//3083 403//3083
f 401//4145 404//4145 405//4145
f 401//4144 400//4144 404//4144
f 406//3086 64//3086 66//3086
f 406//3087 66//3087 395//3087
f 395//3088 66//3088 78//3088
f 230//4140 229//4140 402//4140
f 403//3084 62//3084 64//3084
f 403//3085 64//3085 406//3085
f 284//3111 6//3111 285//3111
f 265//3127 49//3127 267//3127
f 267//3128 49//3128 42//3128
f 267//3129 42//3129 277//3129
f 271//3124 34//3124 37//3124
f 271//3125 37//3125 265//3125
f 265//3126 37//3126 49//3126
f 273//3133 196//3133 275//3133
f 275//3134 196//3134 208//3134
f 275//3135 208//3135 290//3135
f 277//3130 42//3130 45//3130
f 277//3131 45//3131 273//3131
f 273//3132 45//3132 196//3132
f 279//3115 3//3115 281//3115
f 281//3116 3//3116 5//3116
f 281//3117 5//3117 289//3117
f 285//3112 6//3112 1//3112
f 285//3113 1//3113 279//3113
f 279//3114 1//3114 3//3114
f 287//3121 11//3121 270//3121
f 270//3122 11//3122 34//3122
f 270//3123 34//3123 271//3123
f 289//3118 5//3118 9//3118
f 289//3119 9//3119 287//3119
f 287//3120 9//3120 11//3120
f 172//3829 180//3829 174//3829
f 407//1793 408//1793 409//1793
f 410//1792 408//1792 407//1792
f 410//1791 411//1791 408//1791
f 412//1797 413//1797 414//1797
f 415//1796 413//1796 412//1796
f 407//1794 409//1794 415//1794
f 416//1625 417//1625 418//1625
f 416//1626 418//1626 419//1626
f 419//1627 418//1627 420//1627
f 421//1790 411//1790 410//1790
f 421//1789 422//1789 411//1789
f 423//1624 417//1624 416//1624
f 424//6091 425//6091 426//6091
f 426//6092 425//6092 427//6092
f 428//1806 429//1806 430//1806
f 430//1808 431//1808 432//1808
f 430//1807 429//1807 431//1807
f 433//6090 424//6090 426//6090
f 434//1801 435//1801 436//1801
f 437//1800 435//1800 434//1800
f 437//1799 414//1799 435//1799
f 428//1805 438//1805 429//1805
f 439//1803 436//1803 438//1803
f 434//1802 436//1802 439//1802
f 419//1628 420//1628 440//1628
f 441//1645 442//1645 443//1645
f 441//1646 443//1646 444//1646
f 444//1647 443//1647 445//1647
f 446//1641 447//1641 448//1641
f 449//1786 450//1786 451//1786
f 452//6115 453//6115 454//6115
f 455//1651 456//1651 457//1651
f 455//1652 457//1652 458//1652
f 458//1653 457//1653 459//1653
f 444//1648 445//1648 460//1648
f 460//1649 445//1649 456//1649
f 460//1650 456//1650 455//1650
f 451//1788 422//1788 421//1788
f 451//1787 450//1787 422//1787
f 461//1634 462//1634 463//1634
f 440//1629 420//1629 464//1629
f 440//1630 464//1630 465//1630
f 465//1631 464//1631 466//1631
f 467//1638 468//1638 469//1638
f 469//1639 468//1639 447//1639
f 469//1640 447//1640 446//1640
f 463//1635 462//1635 470//1635
f 463//1636 470//1636 467//1636
f 467//1637 470//1637 468//1637
f 471//6050 472//6050 473//6050
f 473//6051 472//6051 474//6051
f 475//6052 476//6052 477//6052
f 475//6046 471//6046 476//6046
f 476//6047 471//6047 473//6047
f 478//1839 455//1839 458//1839
f 479//1836 460//1836 480//1836
f 479//1835 444//1835 460//1835
f 481//1834 444//1834 479//1834
f 482//6053 477//6053 483//6053
f 480//1838 455//1838 478//1838
f 480//1837 460//1837 455//1837
f 484//1543 485//1543 486//1543
f 484//1544 486//1544 487//1544
f 487//1545 486//1545 488//1545
f 489//1538 490//1538 491//1538
f 491//1539 490//1539 492//1539
f 493//1542 485//1542 484//1542
f 494//1549 495//1549 496//1549
f 497//1849 498//1849 499//1849
f 500//1848 498//1848 497//1848
f 487//1546 488//1546 501//1546
f 501//1547 488//1547 495//1547
f 501//1548 495//1548 494//1548
f 481//1833 441//1833 444//1833
f 502//1817 440//1817 465//1817
f 503//1816 440//1816 502//1816
f 503//1815 419//1815 440//1815
f 504//1822 463//1822 505//1822
f 504//1821 461//1821 463//1821
f 506//1820 461//1820 504//1820
f 507//1811 423//1811 416//1811
f 508//6085 509//6085 510//6085
f 432//1810 423//1810 507//1810
f 511//1814 419//1814 503//1814
f 511//1813 416//1813 419//1813
f 507//1812 416//1812 511//1812
f 477//6063 482//6063 512//6063
f 471//6064 475//6064 472//6064
f 472//6065 475//6065 477//6065
f 513//1832 441//1832 481//1832
f 513//1831 514//1831 441//1831
f 515//1830 514//1830 513//1830
f 516//1825 467//1825 469//1825
f 505//1824 467//1824 516//1824
f 505//1823 463//1823 467//1823
f 515//1829 446//1829 514//1829
f 517//1828 446//1828 515//1828
f 516//1826 469//1826 517//1826
f 518//1721 519//1721 520//1721
f 518//1722 520//1722 521//1722
f 522//1762 523//1762 524//1762
f 525//1718 526//1718 527//1718
f 527//1719 526//1719 519//1719
f 527//1720 519//1720 518//1720
f 528//1727 529//1727 530//1727
f 522//1761 531//1761 523//1761
f 532//1729 530//1729 533//1729
f 521//1724 534//1724 535//1724
f 535//1725 534//1725 529//1725
f 535//1726 529//1726 528//1726
f 536//1709 487//1709 501//1709
f 536//1710 501//1710 537//1710
f 537//1711 501//1711 494//1711
f 538//1706 484//1706 539//1706
f 539//1707 484//1707 487//1707
f 539//1708 487//1708 536//1708
f 540//1715 541//1715 542//1715
f 540//1716 542//1716 525//1716
f 525//1717 542//1717 526//1717
f 524//1764 543//1764 544//1764
f 524//1763 523//1763 543//1763
f 545//1714 541//1714 540//1714
f 546//1760 531//1760 522//1760
f 547//1758 548//1758 546//1758
f 547//1757 549//1757 548//1757
f 550//1748 551//1748 552//1748
f 553//1743 554//1743 555//1743
f 553//1744 555//1744 556//1744
f 556//1745 555//1745 557//1745
f 558//1752 559//1752 560//1752
f 560//1753 559//1753 561//1753
f 560//1754 561//1754 562//1754
f 552//1749 551//1749 563//1749
f 552//1750 563//1750 558//1750
f 558//1751 563//1751 559//1751
f 564//1734 565//1734 566//1734
f 566//1735 565//1735 567//1735
f 566//1736 567//1736 568//1736
f 569//1731 533//1731 570//1731
f 569//1732 570//1732 564//1732
f 564//1733 570//1733 565//1733
f 571//1740 572//1740 573//1740
f 573//1741 572//1741 554//1741
f 573//1742 554//1742 553//1742
f 546//1759 548//1759 531//1759
f 568//1738 574//1738 571//1738
f 571//1739 574//1739 572//1739
f 575//1780 576//1780 577//1780
f 575//1779 578//1779 576//1779
f 450//1672 579//1672 422//1672
f 580//1667 581//1667 582//1667
f 580//1668 582//1668 583//1668
f 583//1669 582//1669 584//1669
f 411//1676 585//1676 408//1676
f 408//1677 585//1677 586//1677
f 408//1678 586//1678 409//1678
f 422//1673 579//1673 587//1673
f 422//1674 587//1674 411//1674
f 411//1675 587//1675 585//1675
f 588//1657 589//1657 590//1657
f 591//1783 580//1783 583//1783
f 577//1782 580//1782 591//1782
f 458//1654 459//1654 592//1654
f 592//1655 459//1655 589//1655
f 592//1656 589//1656 588//1656
f 578//1664 593//1664 576//1664
f 576//1665 593//1665 581//1665
f 576//1666 581//1666 580//1666
f 577//1781 576//1781 580//1781
f 594//1662 595//1662 578//1662
f 578//1663 595//1663 593//1663
f 409//1679 586//1679 596//1679
f 597//1697 598//1697 599//1697
f 597//1698 599//1698 600//1698
f 600//1699 599//1699 489//1699
f 431//1694 601//1694 423//1694
f 423//1695 601//1695 417//1695
f 602//1696 598//1696 597//1696
f 603//1703 491//1703 493//1703
f 603//1704 493//1704 538//1704
f 544//1765 543//1765 604//1765
f 600//1700 489//1700 605//1700
f 605//1701 489//1701 491//1701
f 544//1766 604//1766 602//1766
f 606//1776 594//1776 607//1776
f 606//1775 608//1775 594//1775
f 609//1773 588//1773 608//1773
f 409//1680 596//1680 413//1680
f 413//1681 596//1681 610//1681
f 607//1778 578//1778 575//1778
f 611//1769 458//1769 592//1769
f 478//1768 458//1768 611//1768
f 602//1767 604//1767 598//1767
f 612//1772 588//1772 609//1772
f 612//1771 592//1771 588//1771
f 611//1770 592//1770 612//1770
f 489//1537 613//1537 490//1537
f 7//3252 614//3252 615//3252
f 616//5851 617//5851 618//5851
f 618//5852 617//5852 619//5852
f 503//1283 620//1283 621//1283
f 503//1284 621//1284 622//1284
f 623//1285 624//1285 625//1285
f 8//3255 626//3255 2//3255
f 2//3256 626//3256 627//3256
f 628//3747 629//3747 630//3747
f 618//5853 619//5853 631//5853
f 7//3253 615//3253 8//3253
f 8//3254 615//3254 626//3254
f 632//6301 633//6301 634//6301
f 635//6300 633//6300 632//6300
f 181//1275 183//1275 451//1275
f 65//3228 636//3228 637//3228
f 65//3229 637//3229 77//3229
f 77//3230 637//3230 638//3230
f 639//6290 640//6290 641//6290
f 502//1281 642//1281 620//1281
f 502//1282 620//1282 503//1282
f 451//1276 183//1276 643//1276
f 451//1277 643//1277 449//1277
f 641//6291 640//6291 644//6291
f 628//3746 645//3746 629//3746
f 10//3262 646//3262 647//3262
f 10//3263 647//3263 35//3263
f 35//3264 647//3264 648//3264
f 4//3259 649//3259 16//3259
f 16//3260 649//3260 646//3260
f 16//3261 646//3261 10//3261
f 38//3268 650//3268 651//3268
f 38//3269 651//3269 43//3269
f 652//5871 653//5871 654//5871
f 35//3265 648//3265 36//3265
f 36//3266 648//3266 650//3266
f 36//3267 650//3267 38//3267
f 655//3742 656//3742 657//3742
f 658//3741 656//3741 655//3741
f 658//3740 659//3740 656//3740
f 660//3745 645//3745 628//3745
f 660//3744 657//3744 645//3744
f 655//3743 657//3743 660//3743
f 661//3736 662//3736 663//3736
f 2//3257 627//3257 4//3257
f 4//3258 627//3258 649//3258
f 664//3739 659//3739 658//3739
f 664//3738 663//3738 659//3738
f 661//3737 663//3737 664//3737
f 665//2066 666//2066 667//2066
f 665//2065 668//2065 666//2065
f 669//5794 670//5794 671//5794
f 93//3219 672//3219 91//3219
f 667//2068 673//2068 674//2068
f 667//2067 666//2067 673//2067
f 675//5798 676//5798 677//5798
f 675//5799 677//5799 678//5799
f 678//5800 677//5800 679//5800
f 669//5795 671//5795 680//5795
f 680//5796 671//5796 676//5796
f 680//5797 676//5797 675//5797
f 681//5780 682//5780 683//5780
f 681//5781 683//5781 684//5781
f 89//3212 207//3212 685//3212
f 177//3828 686//3828 175//3828
f 159//5778 158//5778 682//5778
f 159//5779 682//5779 681//5779
f 83//3216 687//3216 688//3216
f 83//3217 688//3217 93//3217
f 93//3218 688//3218 672//3218
f 89//3213 685//3213 81//3213
f 81//3214 685//3214 687//3214
f 81//3215 687//3215 83//3215
f 678//5801 679//5801 689//5801
f 690//2057 691//2057 692//2057
f 91//3220 672//3220 693//3220
f 91//3221 693//3221 68//3221
f 694//2060 695//2060 696//2060
f 694//2059 692//2059 695//2059
f 690//2058 692//2058 694//2058
f 69//3225 697//3225 63//3225
f 63//3226 697//3226 636//3226
f 63//3227 636//3227 65//3227
f 68//3222 693//3222 698//3222
f 68//3223 698//3223 69//3223
f 69//3224 698//3224 697//3224
f 699//5805 700//5805 701//5805
f 701//5806 700//5806 702//5806
f 701//5807 702//5807 703//5807
f 689//5802 679//5802 704//5802
f 689//5803 704//5803 705//5803
f 699//5804 706//5804 700//5804
f 707//2063 708//2063 668//2063
f 696//2062 708//2062 707//2062
f 696//2061 695//2061 708//2061
f 703//5808 702//5808 709//5808
f 703//5809 709//5809 710//5809
f 707//2064 668//2064 665//2064
f 534//1489 711//1489 712//1489
f 534//1490 712//1490 529//1490
f 567//1502 713//1502 574//1502
f 519//1486 714//1486 520//1486
f 520//1487 714//1487 711//1487
f 520//1488 711//1488 534//1488
f 572//1506 715//1506 554//1506
f 554//1507 715//1507 716//1507
f 554//1508 716//1508 555//1508
f 574//1503 713//1503 717//1503
f 574//1504 717//1504 572//1504
f 572//1505 717//1505 715//1505
f 718//3621 719//3621 720//3621
f 720//3619 721//3619 722//3619
f 723//5977 724//5977 725//5977
f 619//5950 726//5950 631//5950
f 727//3623 720//3623 722//3623
f 718//3622 720//3622 727//3622
f 526//1483 728//1483 729//1483
f 526//1484 729//1484 519//1484
f 519//1485 729//1485 714//1485
f 541//1480 730//1480 542//1480
f 542//1481 730//1481 728//1481
f 542//1482 728//1482 526//1482
f 555//1509 716//1509 731//1509
f 523//1528 732//1528 543//1528
f 543//1529 732//1529 733//1529
f 543//1530 733//1530 604//1530
f 531//1525 734//1525 735//1525
f 531//1526 735//1526 523//1526
f 523//1527 735//1527 732//1527
f 598//1534 736//1534 599//1534
f 599//1535 736//1535 613//1535
f 599//1536 613//1536 489//1536
f 604//1531 733//1531 737//1531
f 604//1532 737//1532 598//1532
f 598//1533 737//1533 736//1533
f 563//1515 738//1515 739//1515
f 563//1516 739//1516 559//1516
f 559//1517 739//1517 740//1517
f 555//1510 731//1510 557//1510
f 557//1511 731//1511 741//1511
f 551//1514 738//1514 563//1514
f 549//1521 742//1521 743//1521
f 744//1853 499//1853 745//1853
f 744//1852 497//1852 499//1852
f 559//1518 740//1518 561//1518
f 561//1519 740//1519 742//1519
f 561//1520 742//1520 549//1520
f 685//3284 746//3284 747//3284
f 685//3285 747//3285 687//3285
f 687//3286 747//3286 748//3286
f 205//3281 749//3281 207//3281
f 207//3282 749//3282 746//3282
f 207//3283 746//3283 685//3283
f 672//3290 750//3290 751//3290
f 672//3291 751//3291 693//3291
f 693//3292 751//3292 752//3292
f 687//3287 748//3287 688//3287
f 688//3288 748//3288 750//3288
f 688//3289 750//3289 672//3289
f 653//5875 753//5875 654//5875
f 44//3273 754//3273 195//3273
f 195//3274 754//3274 755//3274
f 43//3270 651//3270 756//3270
f 43//3271 756//3271 44//3271
f 44//3272 756//3272 754//3272
f 204//3278 757//3278 758//3278
f 204//3279 758//3279 205//3279
f 205//3280 758//3280 749//3280
f 195//3275 755//3275 197//3275
f 197//3276 755//3276 757//3276
f 197//3277 757//3277 204//3277
f 693//3293 752//3293 698//3293
f 759//6281 760//6281 761//6281
f 762//6280 760//6280 759//6280
f 763//6271 764//6271 765//6271
f 556//1362 766//1362 553//1362
f 553//1363 766//1363 767//1363
f 553//1364 767//1364 768//1364
f 631//5947 726//5947 618//5947
f 618//5948 726//5948 769//5948
f 618//5949 769//5949 770//5949
f 771//6270 764//6270 763//6270
f 770//5945 769//5945 616//5945
f 616//5946 769//5946 617//5946
f 697//3297 772//3297 636//3297
f 636//3298 772//3298 773//3298
f 636//3299 773//3299 637//3299
f 698//3294 752//3294 774//3294
f 698//3295 774//3295 697//3295
f 697//3296 774//3296 772//3296
f 540//1356 625//1356 775//1356
f 540//1357 775//1357 545//1357
f 556//1361 776//1361 766//1361
f 637//3300 773//3300 777//3300
f 637//3301 777//3301 638//3301
f 623//1355 625//1355 540//1355
f 778//6442 779//6442 780//6442
f 781//90 782//90 783//90
f 784//6446 785//6446 786//6446
f 780//6443 779//6443 787//6443
f 788//89 782//89 781//89
f 789//4630 790//4630 791//4630
f 792//4629 790//4629 789//4629
f 793//88 794//88 795//88
f 789//4631 791//4631 796//4631
f 786//6447 785//6447 797//6447
f 798//4647 799//4647 800//4647
f 798//4646 801//4646 799//4646
f 802//95 803//95 804//95
f 805//94 806//94 807//94
f 808//4645 801//4645 798//4645
f 809//4642 810//4642 811//4642
f 812//93 806//93 805//93
f 808//4644 811//4644 801//4644
f 809//4643 811//4643 808//4643
f 813//5187 814//5187 815//5187
f 816//5188 817//5188 818//5188
f 819//5185 820//5185 813//5185
f 813//5186 820//5186 814//5186
f 821//419 822//419 823//419
f 824//5191 825//5191 826//5191
f 826//5192 825//5192 827//5192
f 816//5189 818//5189 824//5189
f 824//5190 818//5190 825//5190
f 819//5184 828//5184 820//5184
f 829//406 830//406 831//406
f 832//4626 833//4626 834//4626
f 792//4628 834//4628 790//4628
f 832//4627 834//4627 792//4627
f 835//87 794//87 793//87
f 836//5182 837//5182 828//5182
f 836//5183 828//5183 819//5183
f 838//6437 839//6437 840//6437
f 841//6436 839//6436 838//6436
f 842//346 843//346 844//346
f 844//347 843//347 845//347
f 846//5131 847//5131 848//5131
f 848//5132 847//5132 849//5132
f 848//5133 849//5133 850//5133
f 850//5135 851//5135 852//5135
f 853//352 854//352 855//352
f 850//5134 849//5134 851//5134
f 856//350 857//350 858//350
f 846//5130 859//5130 847//5130
f 860//339 855//339 861//339
f 862//6548 863//6548 864//6548
f 865//6487 866//6487 867//6487
f 868//6486 866//6486 865//6486
f 864//6549 863//6549 869//6549
f 870//6559 871//6559 872//6559
f 857//342 873//342 858//342
f 861//340 855//340 854//340
f 874//6558 871//6558 870//6558
f 768//102 875//102 876//102
f 767//101 875//101 768//101
f 877//6568 878//6568 879//6568
f 879//6569 878//6569 880//6569
f 881//100 882//100 883//100
f 884//99 882//99 881//99
f 804//96 803//96 885//96
f 886//6457 858//6457 887//6457
f 856//6456 858//6456 886//6456
f 888//107 889//107 890//107
f 891//6564 892//6564 893//6564
f 893//6565 892//6565 894//6565
f 895//6477 896//6477 897//6477
f 898//6476 896//6476 895//6476
f 622//110 899//110 900//110
f 901//6467 861//6467 902//6467
f 860//6466 861//6466 901//6466
f 621//109 899//109 622//109
f 890//108 889//108 903//108
f 826//5193 827//5193 904//5193
f 905//5249 906//5249 907//5249
f 907//5250 906//5250 908//5250
f 909//5247 910//5247 905//5247
f 905//5248 910//5248 906//5248
f 907//5251 908//5251 911//5251
f 912//5254 913//5254 914//5254
f 912//5255 914//5255 915//5255
f 916//5252 917//5252 913//5252
f 916//5253 913//5253 912//5253
f 909//5246 918//5246 910//5246
f 919//76 920//76 921//76
f 922//75 920//75 919//75
f 923//78 924//78 925//78
f 926//77 924//77 923//77
f 927//6417 928//6417 929//6417
f 930//5244 931//5244 918//5244
f 930//5245 918//5245 909//5245
f 932//6416 928//6416 927//6416
f 933//6413 934//6413 935//6413
f 936//5268 937//5268 938//5268
f 936//5269 938//5269 939//5269
f 940//5266 941//5266 942//5266
f 940//5267 942//5267 943//5267
f 939//5270 938//5270 944//5270
f 945//5273 946//5273 947//5273
f 947//5274 946//5274 948//5274
f 939//5271 944//5271 945//5271
f 945//5272 944//5272 946//5272
f 949//5265 941//5265 940//5265
f 950//5258 951//5258 952//5258
f 950//5259 952//5259 953//5259
f 915//5256 914//5256 954//5256
f 915//5257 954//5257 955//5257
f 953//5260 952//5260 956//5260
f 957//5263 958//5263 949//5263
f 949//5264 958//5264 941//5264
f 953//5261 956//5261 957//5261
f 957//5262 956//5262 958//5262
f 959//5206 960//5206 961//5206
f 959//5207 961//5207 962//5207
f 963//5204 964//5204 960//5204
f 963//5205 960//5205 959//5205
f 965//5208 966//5208 967//5208
f 968//5211 969//5211 970//5211
f 970//5212 969//5212 971//5212
f 965//5209 967//5209 968//5209
f 968//5210 967//5210 969//5210
f 972//5203 964//5203 963//5203
f 973//5196 974//5196 975//5196
f 973//5197 975//5197 976//5197
f 904//5194 827//5194 974//5194
f 904//5195 974//5195 973//5195
f 977//5198 978//5198 979//5198
f 980//5201 981//5201 972//5201
f 972//5202 981//5202 964//5202
f 977//5199 979//5199 980//5199
f 980//5200 979//5200 981//5200
f 982//83 983//83 984//83
f 985//82 986//82 987//82
f 988//6432 989//6432 990//6432
f 984//84 983//84 991//84
f 992//81 986//81 985//81
f 993//6423 994//6423 995//6423
f 996//6422 994//6422 993//6422
f 997//6427 998//6427 999//6427
f 1000//6426 998//6426 997//6426
f 990//6433 989//6433 1001//6433
f 1002//5215 1003//5215 1004//5215
f 1004//5216 1003//5216 1005//5216
f 970//5213 971//5213 1006//5213
f 1002//5214 1007//5214 1003//5214
f 1004//5217 1005//5217 1008//5217
f 1009//5220 1010//5220 1011//5220
f 1009//5221 1011//5221 1012//5221
f 1008//5218 1005//5218 1010//5218
f 1008//5219 1010//5219 1009//5219
f 1013//4694 1014//4694 1015//4694
f 1016//4765 1017//4765 1018//4765
f 1016//4764 1019//4764 1017//4764
f 1018//4767 1020//4767 1021//4767
f 1018//4766 1017//4766 1020//4766
f 1022//4832 1023//4832 1024//4832
f 1025//4835 1026//4835 1027//4835
f 1028//6528 1029//6528 1030//6528
f 1022//4833 1024//4833 1031//4833
f 1032//4834 1026//4834 1025//4834
f 1021//4768 1020//4768 1033//4768
f 1034//4826 1035//4826 1036//4826
f 1034//4827 1036//4827 1037//4827
f 1038//4824 1039//4824 1035//4824
f 1038//4825 1035//4825 1034//4825
f 1040//4828 1041//4828 1042//4828
f 1043//4831 1023//4831 1022//4831
f 1021//4769 1033//4769 1044//4769
f 1040//4829 1042//4829 1043//4829
f 1043//4830 1042//4830 1023//4830
f 1045//4851 1046//4851 1047//4851
f 1048//4852 1049//4852 1050//4852
f 1051//4849 1052//4849 1053//4849
f 1054//4850 1046//4850 1045//4850
f 1055//4763 1056//4763 1057//4763
f 965//4854 1058//4854 966//4854
f 966//4855 1058//4855 1059//4855
f 1055//4762 1060//4762 1056//4762
f 1050//4853 1049//4853 1061//4853
f 1062//4848 1052//4848 1051//4848
f 814//4840 1063//4840 815//4840
f 815//4841 1063//4841 1064//4841
f 1065//4838 1066//4838 1067//4838
f 1067//4839 1066//4839 1068//4839
f 836//4842 817//4842 837//4842
f 976//4845 937//4845 936//4845
f 1030//6529 1029//6529 1069//6529
f 837//4843 817//4843 816//4843
f 975//4844 937//4844 976//4844
f 1070//4786 1071//4786 1072//4786
f 1070//4787 1072//4787 1073//4787
f 1074//4775 1075//4775 1076//4775
f 1077//4785 1071//4785 1070//4785
f 623//6506 1078//6506 624//6506
f 1079//6518 1080//6518 1081//6518
f 1081//6519 1080//6519 1082//6519
f 624//6507 1078//6507 1083//6507
f 1084//215 1085//215 1086//215
f 1076//4776 1075//4776 1087//4776
f 1088//4782 1089//4782 1090//4782
f 1091//4781 1089//4781 1088//4781
f 1077//4784 1090//4784 1071//4784
f 1088//4783 1090//4783 1077//4783
f 1091//4780 1092//4780 1089//4780
f 1093//237 1094//237 1095//237
f 1076//4777 1087//4777 1096//4777
f 1096//4779 1097//4779 1098//4779
f 1096//4778 1087//4778 1097//4778
f 1099//4820 1100//4820 1101//4820
f 1102//4772 1103//4772 1104//4772
f 1105//4819 1100//4819 1099//4819
f 1102//4773 1104//4773 1074//4773
f 1106//4771 1103//4771 1102//4771
f 1107//4822 1101//4822 1039//4822
f 1107//4823 1039//4823 1038//4823
f 1106//4770 1108//4770 1103//4770
f 1099//4821 1101//4821 1107//4821
f 1105//4818 1109//4818 1100//4818
f 1110//4812 1111//4812 1112//4812
f 1110//4813 1112//4813 1113//4813
f 1114//4810 1115//4810 1111//4810
f 1114//4811 1111//4811 1110//4811
f 1113//4814 1112//4814 1116//4814
f 1117//4816 1116//4816 1118//4816
f 1117//4817 1118//4817 1119//4817
f 1113//4815 1116//4815 1117//4815
f 1074//4774 1104//4774 1075//4774
f 961//4856 1006//4856 962//4856
f 1120//4718 1121//4718 1122//4718
f 1123//4717 1121//4717 1120//4717
f 1124//4720 1125//4720 1126//4720
f 1120//4719 1122//4719 1127//4719
f 1123//4716 1128//4716 1121//4716
f 1129//310 1130//310 1131//310
f 1132//4714 1133//4714 1128//4714
f 1132//4715 1128//4715 1123//4715
f 1134//309 1130//309 1129//309
f 1124//4721 1126//4721 1135//4721
f 1136//4728 1137//4728 1138//4728
f 1139//4727 1137//4727 1136//4727
f 1140//4730 1138//4730 1141//4730
f 1136//4729 1138//4729 1140//4729
f 1139//4726 1142//4726 1137//4726
f 1135//4723 1143//4723 1144//4723
f 1135//4722 1126//4722 1143//4722
f 1144//4725 1145//4725 1146//4725
f 1144//4724 1143//4724 1145//4724
f 1147//4701 1148//4701 1149//4701
f 1147//4700 1150//4700 1148//4700
f 1149//4703 1151//4703 1152//4703
f 1149//4702 1148//4702 1151//4702
f 1153//4699 1154//4699 1155//4699
f 1156//4696 1015//4696 1157//4696
f 1013//4695 1015//4695 1156//4695
f 1153//4698 1157//4698 1154//4698
f 1156//4697 1157//4697 1153//4697
f 1152//4704 1151//4704 1158//4704
f 1159//4711 1160//4711 1161//4711
f 1159//4710 1162//4710 1160//4710
f 1161//4713 1133//4713 1132//4713
f 1161//4712 1160//4712 1133//4712
f 1163//4709 1164//4709 1165//4709
f 1166//4706 1158//4706 1167//4706
f 1152//4705 1158//4705 1166//4705
f 1163//4708 1167//4708 1164//4708
f 1166//4707 1167//4707 1163//4707
f 955//4865 943//4865 942//4865
f 1168//6503 1169//6503 1170//6503
f 911//4863 951//4863 950//4863
f 954//4864 943//4864 955//4864
f 1171//6502 1169//6502 1168//6502
f 1172//266 1173//266 1174//266
f 917//4867 859//4867 846//4867
f 916//4866 859//4866 917//4866
f 1175//265 1173//265 1172//265
f 908//4862 951//4862 911//4862
f 1176//4761 1060//4761 1055//4761
f 1176//4760 1177//4760 1060//4760
f 962//4857 1006//4857 971//4857
f 977//4858 1007//4858 978//4858
f 978//4859 1007//4859 1002//4859
f 1178//4757 1179//4757 1180//4757
f 1178//4756 1181//4756 1179//4756
f 1180//4759 1177//4759 1176//4759
f 1180//4758 1179//4758 1177//4758
f 1182//5118 1183//5118 1184//5118
f 1182//5119 1184//5119 1185//5119
f 1186//5116 1187//5116 1183//5116
f 1186//5117 1183//5117 1182//5117
f 1188//6538 1189//6538 1190//6538
f 1191//4732 1141//4732 1192//4732
f 1140//4731 1141//4731 1191//4731
f 1190//6539 1189//6539 1193//6539
f 1191//4733 1192//4733 1194//4733
f 1064//5115 1187//5115 1186//5115
f 1195//6497 1196//6497 1197//6497
f 1198//6496 1196//6496 1195//6496
f 1199//269 1174//269 1200//269
f 1200//270 1174//270 1173//270
f 1201//273 1131//273 1202//273
f 1203//278 1204//278 845//278
f 1064//5114 1063//5114 1187//5114
f 1202//274 1131//274 1130//274
f 845//277 843//277 1203//277
f 379//2982 269//2982 264//2982
f 379//2983 264//2983 391//2983
f 1205//4353 1206//4353 1207//4353
f 1208//4352 1206//4352 1205//4352
f 1209//4349 1210//4349 1211//4349
f 1212//4346 1213//4346 238//4346
f 1214//4345 1213//4345 1212//4345
f 1215//4348 1210//4348 1209//4348
f 1212//4347 238//4347 237//4347
f 1154//4354 833//4354 1155//4354
f 388//2981 269//2981 379//2981
f 1216//2 1217//2 1218//2
f 1118//4362 1037//4362 1119//4362
f 388//2980 288//2980 269//2980
f 1165//4359 1092//4359 1091//4359
f 1013//4356 1150//4356 1014//4356
f 1155//4355 833//4355 832//4355
f 1164//4358 1092//4358 1165//4358
f 1014//4357 1150//4357 1147//4357
f 1214//4344 1219//4344 1213//4344
f 391//2984 264//2984 266//2984
f 391//2985 266//2985 390//2985
f 242//4333 1220//4333 1221//4333
f 242//4332 234//4332 1220//4332
f 390//2986 266//2986 268//2986
f 354//2989 272//2989 355//2989
f 355//2990 272//2990 274//2990
f 390//2987 268//2987 354//2987
f 354//2988 268//2988 272//2988
f 1221//4334 1220//4334 1222//4334
f 1223//4341 239//4341 236//4341
f 1223//4340 1224//4340 239//4340
f 155//4343 1219//4343 1214//4343
f 155//4342 152//4342 1219//4342
f 1225//4339 1224//4339 1223//4339
f 1226//4336 1222//4336 1227//4336
f 1221//4335 1222//4335 1226//4335
f 1225//4338 1227//4338 1224//4338
f 1226//4337 1227//4337 1225//4337
f 1119//4363 1037//4363 1036//4363
f 372//2969 283//2969 371//2969
f 371//2970 283//2970 278//2970
f 370//2967 340//2967 372//2967
f 372//2968 340//2968 283//2968
f 371//2971 278//2971 352//2971
f 382//2974 280//2974 282//2974
f 382//2975 282//2975 368//2975
f 352//2972 278//2972 280//2972
f 352//2973 280//2973 382//2973
f 370//2966 338//2966 340//2966
f 367//2959 330//2959 353//2959
f 353//2960 330//2960 332//2960
f 369//2957 336//2957 367//2957
f 367//2958 336//2958 330//2958
f 353//2961 332//2961 351//2961
f 366//2964 334//2964 338//2964
f 366//2965 338//2965 370//2965
f 351//2962 332//2962 334//2962
f 351//2963 334//2963 366//2963
f 368//2976 282//2976 286//2976
f 1122//4370 1146//4370 1127//4370
f 1125//4369 1228//4369 1229//4369
f 1159//4372 1142//4372 1162//4372
f 1127//4371 1146//4371 1145//4371
f 1124//4368 1228//4368 1125//4368
f 1109//4365 1041//4365 1040//4365
f 1105//4364 1041//4364 1109//4364
f 1031//4367 1230//4367 1231//4367
f 1024//4366 1230//4366 1031//4366
f 1162//4373 1142//4373 1139//4373
f 1016//4380 810//4380 1019//4380
f 1044//4379 1098//4379 1097//4379
f 368//2977 286//2977 389//2977
f 1019//4381 810//4381 809//4381
f 1033//4378 1098//4378 1044//4378
f 389//2978 286//2978 288//2978
f 389//2979 288//2979 388//2979
f 1057//4377 1108//4377 1106//4377
f 1056//4376 1108//4376 1057//4376
f 384//3010 296//3010 297//3010
f 384//3011 297//3011 350//3011
f 364//3008 298//3008 296//3008
f 364//3009 296//3009 384//3009
f 350//3012 297//3012 403//3012
f 85//3015 406//3015 86//3015
f 1232//4267 1233//4267 1234//4267
f 350//3013 403//3013 85//3013
f 85//3014 403//3014 406//3014
f 357//3007 298//3007 364//3007
f 1235//4283 125//4283 127//4283
f 1235//4282 1236//4282 125//4282
f 1237//1 1217//1 1216//1
f 134//4284 133//4284 1238//4284
f 1239//4281 1236//4281 1235//4281
f 135//4278 132//4278 1240//4278
f 357//3006 292//3006 298//3006
f 1239//4280 1240//4280 1236//4280
f 135//4279 1240//4279 1239//4279
f 1232//4266 1241//4266 1233//4266
f 1242//4253 1243//4253 1244//4253
f 1242//4252 1245//4252 1243//4252
f 1244//4255 1246//4255 1247//4255
f 1244//4254 1243//4254 1246//4254
f 86//3016 406//3016 395//3016
f 87//3019 396//3019 322//3019
f 322//3020 396//3020 323//3020
f 86//3017 395//3017 87//3017
f 87//3018 395//3018 396//3018
f 1247//4256 1246//4256 1248//4256
f 1249//4263 1250//4263 1251//4263
f 1249//4262 1252//4262 1250//4262
f 1251//4265 1241//4265 1232//4265
f 1251//4264 1250//4264 1241//4264
f 163//4261 1252//4261 1249//4261
f 1253//4258 1248//4258 166//4258
f 1247//4257 1248//4257 1253//4257
f 163//4260 160//4260 1252//4260
f 1253//4259 166//4259 165//4259
f 134//4285 1238//4285 1254//4285
f 233//4312 235//4312 1255//4312
f 1256//4311 226//4311 225//4311
f 1257//4314 1255//4314 1258//4314
f 233//4313 1255//4313 1257//4313
f 1256//4310 1259//4310 226//4310
f 1260//4307 1261//4307 1262//4307
f 1260//4306 1263//4306 1261//4306
f 1262//4309 1259//4309 1256//4309
f 1262//4308 1261//4308 1259//4308
f 1257//4315 1258//4315 1264//4315
f 261//2993 276//2993 262//2993
f 262//2994 276//2994 302//2994
f 355//2991 274//2991 261//2991
f 261//2992 274//2992 276//2992
f 262//2995 302//2995 263//2995
f 1264//4317 1265//4317 1266//4317
f 1264//4316 1258//4316 1265//4316
f 1266//4319 1267//4319 1268//4319
f 1266//4318 1265//4318 1267//4318
f 1269//4305 1263//4305 1260//4305
f 1270//4292 1271//4292 162//4292
f 1272//4291 1271//4291 1270//4291
f 356//3005 292//3005 357//3005
f 1270//4293 162//4293 161//4293
f 1272//4290 1273//4290 1271//4290
f 1254//4287 1274//4287 1275//4287
f 1254//4286 1238//4286 1274//4286
f 1275//4289 1273//4289 1272//4289
f 1275//4288 1274//4288 1273//4288
f 356//3004 291//3004 292//3004
f 263//2997 299//2997 365//2997
f 365//2998 299//2998 301//2998
f 1269//4304 1276//4304 1263//4304
f 263//2996 302//2996 299//2996
f 365//2999 301//2999 363//2999
f 358//3002 306//3002 291//3002
f 358//3003 291//3003 356//3003
f 363//3000 301//3000 306//3000
f 363//3001 306//3001 358//3001
f 1277//5308 1278//5308 1279//5308
f 1277//5309 1279//5309 1280//5309
f 1280//5310 1279//5310 1281//5310
f 1282//6383 1283//6383 1284//6383
f 1054//5306 1045//5306 1278//5306
f 1054//5307 1278//5307 1277//5307
f 1285//6382 1283//6382 1282//6382
f 1286//5355 1287//5355 1288//5355
f 1289//5313 1290//5313 1291//5313
f 1291//5314 1290//5314 1053//5314
f 1292//5350 1293//5350 1294//5350
f 1294//5351 1293//5351 1295//5351
f 1296//5354 1287//5354 1286//5354
f 1280//5311 1281//5311 1289//5311
f 1289//5312 1281//5312 1290//5312
f 1297//5305 1062//5305 1051//5305
f 1298//5298 1299//5298 1300//5298
f 1298//5299 1300//5299 1301//5299
f 1301//5300 1300//5300 1302//5300
f 1303//62 1304//62 1305//62
f 1306//63 1307//63 1308//63
f 1309//6393 1310//6393 1311//6393
f 1312//6392 1310//6392 1309//6392
f 1201//6372 1202//6372 1313//6372
f 1297//5304 1314//5304 1062//5304
f 1134//6378 1129//6378 1315//6378
f 1315//6379 1129//6379 1316//6379
f 1317//5303 1314//5303 1297//5303
f 1313//6373 1202//6373 1318//6373
f 1301//5301 1302//5301 1317//5301
f 1317//5302 1302//5302 1314//5302
f 118//5349 360//5349 359//5349
f 29//5335 1319//5335 1320//5335
f 28//5334 1319//5334 29//5334
f 46//5324 214//5324 47//5324
f 212//5323 381//5323 380//5323
f 1321//5320 1322//5320 1048//5320
f 1321//5321 1048//5321 1050//5321
f 211//5322 381//5322 212//5322
f 109//5333 21//5333 20//5333
f 1323//61 1304//61 1303//61
f 55//5327 202//5327 201//5327
f 54//5326 202//5326 55//5326
f 47//5325 214//5325 213//5325
f 108//5332 21//5332 109//5332
f 19//5331 53//5331 52//5331
f 18//5330 53//5330 19//5330
f 1324//5319 1322//5319 1321//5319
f 119//5344 252//5344 124//5344
f 241//5341 670//5341 669//5341
f 240//5340 670//5340 241//5340
f 124//5345 252//5345 251//5345
f 111//5348 360//5348 118//5348
f 123//5347 101//5347 100//5347
f 122//5346 101//5346 123//5346
f 705//5339 710//5339 709//5339
f 1047//5316 1046//5316 1325//5316
f 1047//5317 1325//5317 1324//5317
f 1324//5318 1325//5318 1322//5318
f 699//5336 1326//5336 706//5336
f 704//5338 710//5338 705//5338
f 706//5337 1326//5337 1327//5337
f 1291//5315 1053//5315 1052//5315
f 1308//64 1307//64 1328//64
f 1329//66 1330//66 1331//66
f 1332//8934 1216//8934 1333//8934
f 1334//6402 1335//6402 1336//6402
f 1337//69 1338//69 1339//69
f 1336//6403 1335//6403 1340//6403
f 1341//8932 1216//8932 1342//8932
f 1343//8930 1237//8930 1216//8930
f 1342//8933 1216//8933 1332//8933
f 1344//6352 1345//6352 1346//6352
f 1346//6353 1345//6353 1347//6353
f 1348//6406 1349//6406 1350//6406
f 1351//6343 1352//6343 1353//6343
f 947//5275 948//5275 1354//5275
f 1355//6342 1352//6342 1351//6342
f 369//2956 321//2956 336//2956
f 320//2955 321//2955 369//2955
f 1339//70 1338//70 1356//70
f 1350//6407 1349//6407 1357//6407
f 1358//71 1359//71 1360//71
f 1361//6412 934//6412 933//6412
f 1360//72 1359//72 1362//72
f 1363//65 1330//65 1329//65
f 1364//6397 1365//6397 1366//6397
f 1175//6362 1172//6362 1367//6362
f 1368//8926 1369//8926 1237//8926
f 1370//8925 1369//8925 1368//8925
f 1367//6363 1172//6363 1371//6363
f 1372//6396 1365//6396 1364//6396
f 1373//8928 1368//8928 1237//8928
usemtl material_2
f 1374//6157 1375//6157 1376//6157
f 1377//6166 1378//6166 1379//6166
f 1380//6171 1381//6171 1382//6171
f 1383//6145 1384//6145 1385//6145
f 1378//6578 1386//6578 1387//6578
f 1382//6173 1388//6173 1389//6173
f 1389//6174 1388//6174 1390//6174
f 1382//6172 1381//6172 1388//6172
f 1383//6146 1385//6146 1391//6146
f 1389//6175 1390//6175 1392//6175
f 1393//6523 1081//6523 1082//6523
f 1392//6177 1394//6177 1395//6177
f 1392//6176 1390//6176 1394//6176
f 1396//6526 1028//6526 1397//6526
f 1398//6525 1396//6525 1397//6525
f 1399//6524 1396//6524 1398//6524
f 1400//6522 1081//6522 1393//6522
f 1385//6147 1401//6147 1391//6147
f 1377//6167 1379//6167 1402//6167
f 1403//6577 1379//6577 1404//6577
f 1082//6521 1405//6521 1393//6521
f 1397//6527 1028//6527 1030//6527
f 1080//6520 1405//6520 1082//6520
f 1406//6579 1407//6579 1408//6579
f 776//6511 1170//6511 875//6511
f 1402//6169 1403//6169 1380//6169
f 1402//6168 1379//6168 1403//6168
f 1409//6164 1386//6164 1378//6164
f 1083//6509 883//6509 882//6509
f 1168//6510 1170//6510 776//6510
f 776//6512 875//6512 766//6512
f 1405//6514 1410//6514 1393//6514
f 1393//6515 1410//6515 1400//6515
f 1411//6580 1412//6580 1413//6580
f 1410//6516 1079//6516 1400//6516
f 1414//6581 1415//6581 1416//6581
f 766//6513 875//6513 767//6513
f 1417//6161 1418//6161 1419//6161
f 1380//6170 1403//6170 1381//6170
f 1409//6165 1378//6165 1377//6165
f 1376//6158 1375//6158 1420//6158
f 1376//6159 1420//6159 1417//6159
f 1417//6160 1420//6160 1418//6160
f 1421//6582 1422//6582 1423//6582
f 1423//6583 1422//6583 1424//6583
f 1400//6517 1079//6517 1081//6517
f 1078//6508 883//6508 1083//6508
f 1419//6162 1418//6162 1386//6162
f 1419//6163 1386//6163 1409//6163
f 1170//6505 876//6505 875//6505
f 1406//6618 1415//6618 1407//6618
f 1384//6149 1425//6149 1385//6149
f 1189//6540 1426//6540 1193//6540
f 1406//6617 1416//6617 1415//6617
f 880//6571 891//6571 893//6571
f 878//6570 891//6570 880//6570
f 1427//6542 1190//6542 1428//6542
f 894//6567 877//6567 879//6567
f 892//6566 877//6566 894//6566
f 1193//6541 1426//6541 1428//6541
f 1407//6619 1415//6619 1429//6619
f 1407//6620 1429//6620 1430//6620
f 1431//6611 1432//6611 1433//6611
f 1431//6612 1433//6612 1434//6612
f 1434//6613 1433//6613 1435//6613
f 1436//6610 1432//6610 1431//6610
f 1437//6627 1438//6627 1439//6627
f 1437//6626 1434//6626 1438//6626
f 893//6573 879//6573 880//6573
f 894//6572 879//6572 893//6572
f 1438//6616 1416//6616 1406//6616
f 1434//6614 1435//6614 1438//6614
f 1438//6615 1435//6615 1416//6615
f 1391//6148 1401//6148 1440//6148
f 1441//6554 1442//6554 1443//6554
f 1443//6555 1442//6555 1444//6555
f 1442//6556 874//6556 1444//6556
f 1445//6552 864//6552 1446//6552
f 1447//6624 1431//6624 1434//6624
f 1446//6553 864//6553 869//6553
f 871//6560 1441//6560 872//6560
f 872//6561 1441//6561 1443//6561
f 1447//6625 1434//6625 1437//6625
f 1444//6557 874//6557 870//6557
f 1443//6563 870//6563 872//6563
f 1444//6562 870//6562 1443//6562
f 1385//6150 1425//6150 1401//6150
f 1446//6545 1448//6545 1445//6545
f 1449//6621 1436//6621 1450//6621
f 1451//6151 1452//6151 1453//6151
f 1428//6543 1190//6543 1193//6543
f 1454//6544 1448//6544 1446//6544
f 1450//6623 1431//6623 1447//6623
f 863//6550 1454//6550 869//6550
f 869//6551 1454//6551 1446//6551
f 1448//6546 862//6546 1445//6546
f 1445//6547 862//6547 864//6547
f 1450//6622 1436//6622 1431//6622
f 1455//6609 1456//6609 1457//6609
f 1458//6595 1407//6595 1430//6595
f 1457//6596 1414//6596 1455//6596
f 1455//6597 1414//6597 1416//6597
f 1459//6155 1460//6155 1374//6155
f 1459//6154 500//6154 1460//6154
f 1461//6594 1407//6594 1458//6594
f 1413//6599 1462//6599 1463//6599
f 498//6153 500//6153 1459//6153
f 1388//6576 1381//6576 1464//6576
f 1069//6531 1399//6531 1398//6531
f 1397//6532 1030//6532 1398//6532
f 1455//6598 1416//6598 1435//6598
f 1374//6156 1460//6156 1375//6156
f 1465//6586 1466//6586 1422//6586
f 1422//6587 1466//6587 1467//6587
f 1468//6584 1469//6584 1465//6584
f 1465//6585 1469//6585 1466//6585
f 1029//6530 1399//6530 1069//6530
f 1470//6591 1471//6591 1472//6591
f 1470//6592 1408//6592 1461//6592
f 1461//6593 1408//6593 1407//6593
f 1422//6588 1467//6588 1424//6588
f 1461//6589 1473//6589 1470//6589
f 1470//6590 1473//6590 1471//6590
f 1474//6574 1475//6574 1476//6574
f 1455//6608 1463//6608 1456//6608
f 1468//6634 1470//6634 1472//6634
f 1435//6606 1413//6606 1463//6606
f 1435//6607 1463//6607 1455//6607
f 1394//6575 1390//6575 1477//6575
f 1478//6630 1406//6630 1408//6630
f 1439//6629 1406//6629 1478//6629
f 1439//6628 1438//6628 1406//6628
f 1479//6633 1470//6633 1468//6633
f 1479//6632 1408//6632 1470//6632
f 1478//6631 1408//6631 1479//6631
f 1480//6602 1412//6602 1481//6602
f 1426//6534 1482//6534 1428//6534
f 1428//6535 1482//6535 1427//6535
f 1398//6533 1030//6533 1069//6533
f 1462//6600 1413//6600 1480//6600
f 1480//6601 1413//6601 1412//6601
f 1432//6603 1411//6603 1433//6603
f 1433//6604 1411//6604 1413//6604
f 1433//6605 1413//6605 1435//6605
f 1482//6536 1188//6536 1427//6536
f 1427//6537 1188//6537 1190//6537
f 1453//6152 1452//6152 1483//6152
f 1395//6178 1394//6178 1475//6178
f 794//6434 783//6434 795//6434
f 997//6431 994//6431 996//6431
f 839//6438 1001//6438 840//6438
f 795//6435 783//6435 782//6435
f 998//6428 995//6428 999//6428
f 987//6425 991//6425 983//6425
f 999//6430 994//6430 997//6430
f 999//6429 995//6429 994//6429
f 785//6448 787//6448 797//6448
f 807//6445 885//6445 803//6445
f 797//6450 779//6450 786//6450
f 797//6449 787//6449 779//6449
f 840//6440 989//6440 838//6440
f 840//6439 1001//6439 989//6439
f 806//6444 885//6444 807//6444
f 838//6441 989//6441 988//6441
f 1349//6408 1340//6408 1357//6408
f 1356//6405 1362//6405 1359//6405
f 1357//6410 1335//6410 1350//6410
f 1357//6409 1340//6409 1335//6409
f 1366//6400 1310//6400 1364//6400
f 1366//6399 1311//6399 1310//6399
f 1338//6404 1362//6404 1356//6404
f 1364//6401 1310//6401 1312//6401
f 929//6420 934//6420 927//6420
f 929//6419 935//6419 934//6419
f 986//6424 991//6424 987//6424
f 927//6421 934//6421 1361//6421
f 920//6414 925//6414 921//6414
f 1350//6411 1335//6411 1334//6411
f 928//6418 935//6418 929//6418
f 921//6415 925//6415 924//6415
f 786//6451 779//6451 778//6451
f 897//6479 1484//6479 1485//6479
f 896//6478 1484//6478 897//6478
f 1486//6308 116//6308 1487//6308
f 1488//6480 895//6480 1485//6480
f 1485//6473 853//6473 1488//6473
f 1484//6472 853//6472 1485//6472
f 1488//6475 898//6475 895//6475
f 853//6474 898//6474 1488//6474
f 633//6302 1489//6302 634//6302
f 634//6303 1489//6303 1490//6303
f 1491//6299 635//6299 632//6299
f 1485//6481 895//6481 897//6481
f 1492//6306 1486//6306 1493//6306
f 1493//6307 1486//6307 1487//6307
f 1491//6304 632//6304 1490//6304
f 1490//6305 632//6305 634//6305
f 887//6459 842//6459 1494//6459
f 858//6458 842//6458 887//6458
f 1494//6461 886//6461 887//6461
f 1495//6460 886//6460 1494//6460
f 1494//6453 844//6453 1495//6453
f 842//6452 844//6452 1494//6452
f 1495//6455 856//6455 886//6455
f 844//6454 856//6454 1495//6454
f 902//6469 873//6469 1496//6469
f 861//6468 873//6468 902//6468
f 1496//6471 901//6471 902//6471
f 1497//6470 901//6470 1496//6470
f 1496//6463 857//6463 1497//6463
f 873//6462 857//6462 1496//6462
f 1497//6465 860//6465 901//6465
f 857//6464 860//6464 1497//6464
f 1365//6398 1311//6398 1366//6398
f 1347//6355 1498//6355 1499//6355
f 1345//6354 1498//6354 1347//6354
f 1499//6357 1346//6357 1347//6357
f 1500//6356 1346//6356 1499//6356
f 1499//6349 1501//6349 1500//6349
f 1498//6348 1501//6348 1499//6348
f 1500//6351 1344//6351 1346//6351
f 1501//6350 1344//6350 1500//6350
f 1371//6365 1502//6365 1503//6365
f 1172//6364 1502//6364 1371//6364
f 1503//6367 1367//6367 1371//6367
f 1504//6366 1367//6366 1503//6366
f 1503//6359 1505//6359 1504//6359
f 1502//6358 1505//6358 1503//6358
f 1504//6361 1175//6361 1367//6361
f 1505//6360 1175//6360 1504//6360
f 642//6336 899//6336 620//6336
f 620//6337 899//6337 621//6337
f 72//6334 394//6334 642//6334
f 642//6335 394//6335 899//6335
f 1086//6340 1355//6340 1506//6340
f 1506//6341 1355//6341 1351//6341
f 1084//6338 1086//6338 1507//6338
f 1507//6339 1086//6339 1506//6339
f 1353//6345 1084//6345 1507//6345
f 1352//6344 1084//6344 1353//6344
f 1507//6347 1351//6347 1353//6347
f 1506//6346 1351//6346 1507//6346
f 392//6332 903//6332 393//6332
f 393//6333 903//6333 889//6333
f 71//6328 900//6328 394//6328
f 394//6329 900//6329 899//6329
f 1199//6368 1200//6368 1508//6368
f 1509//6316 1510//6316 1511//6316
f 1511//6317 1510//6317 1512//6317
f 1487//6314 94//6314 1493//6314
f 1493//6315 94//6315 96//6315
f 74//6322 1509//6322 75//6322
f 75//6323 1509//6323 1511//6323
f 1510//6318 76//6318 1512//6318
f 1512//6319 76//6319 73//6319
f 1323//6391 1316//6391 1304//6391
f 1218//6390 1316//6390 1323//6390
f 1328//6395 1331//6395 1330//6395
f 1307//6394 1331//6394 1328//6394
f 95//6312 1492//6312 96//6312
f 96//6313 1492//6313 1493//6313
f 1218//6389 1315//6389 1316//6389
f 1487//6309 116//6309 94//6309
f 1513//6376 1313//6376 1508//6376
f 1318//6375 1199//6375 1508//6375
f 1129//6380 1305//6380 1316//6380
f 1508//6377 1313//6377 1318//6377
f 1200//6370 1201//6370 1513//6370
f 1508//6369 1200//6369 1513//6369
f 1202//6374 1199//6374 1318//6374
f 1513//6371 1201//6371 1313//6371
f 1217//6388 1315//6388 1218//6388
f 1217//6387 1284//6387 1315//6387
f 1512//6324 73//6324 1511//6324
f 1511//6325 73//6325 75//6325
f 1283//6384 1134//6384 1284//6384
f 1316//6381 1305//6381 1304//6381
f 1282//6386 1284//6386 1217//6386
f 1284//6385 1134//6385 1315//6385
f 1514//6222 1515//6222 1516//6222
f 1516//6223 1515//6223 1517//6223
f 1516//6220 1518//6220 1519//6220
f 1516//6221 1519//6221 1514//6221
f 1516//6226 1520//6226 1521//6226
f 1516//6227 1521//6227 1522//6227
f 1516//6224 1517//6224 1523//6224
f 1523//6225 1520//6225 1516//6225
f 1524//6214 1525//6214 1526//6214
f 1524//6215 1526//6215 1527//6215
f 1528//6212 1529//6212 1525//6212
f 1528//6213 1525//6213 1524//6213
f 1530//6218 1531//6218 1532//6218
f 1533//6219 1518//6219 1516//6219
f 1533//6216 1516//6216 1530//6216
f 1530//6217 1516//6217 1531//6217
f 1534//6238 1535//6238 1536//6238
f 1536//6239 1535//6239 1537//6239
f 1536//6236 1538//6236 1539//6236
f 1536//6237 1539//6237 1534//6237
f 1540//6242 1541//6242 1536//6242
f 1540//6243 1536//6243 1542//6243
f 1536//6240 1537//6240 1542//6240
f 1543//6241 1541//6241 1540//6241
f 1516//6230 1544//6230 1536//6230
f 1536//6231 1544//6231 1545//6231
f 1522//6228 1546//6228 1516//6228
f 1516//6229 1546//6229 1544//6229
f 1536//6234 1547//6234 1548//6234
f 1548//6235 1538//6235 1536//6235
f 1545//6232 1549//6232 1536//6232
f 1536//6233 1549//6233 1547//6233
f 1550//6211 1529//6211 1528//6211
f 721//6189 1551//6189 722//6189
f 722//6190 1551//6190 1552//6190
f 1553//6187 1452//6187 1554//6187
f 1524//6188 1555//6188 1528//6188
f 1552//6193 1556//6193 1557//6193
f 1556//6194 1558//6194 1557//6194
f 1551//6191 1559//6191 1552//6191
f 1552//6192 1559//6192 1556//6192
f 1560//6181 1474//6181 1561//6181
f 1561//6182 1474//6182 1562//6182
f 1395//6179 1475//6179 1560//6179
f 1560//6180 1475//6180 1474//6180
f 1563//6185 1483//6185 1553//6185
f 1553//6186 1483//6186 1452//6186
f 1561//6183 1562//6183 1563//6183
f 1563//6184 1562//6184 1483//6184
f 1564//6205 1565//6205 1566//6205
f 1566//6206 1565//6206 1567//6206
f 1568//6203 1569//6203 1564//6203
f 1564//6204 1569//6204 1565//6204
f 1570//6209 1571//6209 1550//6209
f 1570//6210 1550//6210 1528//6210
f 1566//6207 1567//6207 1570//6207
f 1567//6208 1571//6208 1570//6208
f 1572//6197 1573//6197 1574//6197
f 1574//6198 1573//6198 1575//6198
f 1557//6195 1558//6195 1572//6195
f 1557//6196 1572//6196 1574//6196
f 1576//6201 1577//6201 1568//6201
f 1576//6202 1568//6202 1564//6202
f 1574//6199 1575//6199 1576//6199
f 1575//6200 1577//6200 1576//6200
f 1541//6244 1543//6244 1578//6244
f 1196//6498 1579//6498 1197//6498
f 1580//6495 1198//6495 1195//6495
f 1580//6500 1195//6500 1581//6500
f 1197//6499 1579//6499 1581//6499
f 1579//6492 1582//6492 1581//6492
f 1583//6491 865//6491 867//6491
f 1582//6494 1198//6494 1580//6494
f 1581//6493 1582//6493 1580//6493
f 1584//6284 759//6284 1585//6284
f 1585//6285 759//6285 761//6285
f 760//6282 1586//6282 761//6282
f 761//6283 1586//6283 1585//6283
f 1169//6504 876//6504 1170//6504
f 1581//6501 1195//6501 1197//6501
f 1587//6286 1588//6286 1589//6286
f 1589//6287 1588//6287 1590//6287
f 644//6293 1587//6293 1589//6293
f 1590//6294 641//6294 1589//6294
f 1583//6483 1591//6483 1592//6483
f 1593//6482 1591//6482 1583//6482
f 1490//6297 1594//6297 1491//6297
f 1594//6298 635//6298 1491//6298
f 1589//6295 641//6295 644//6295
f 1489//6296 1594//6296 1490//6296
f 1588//6288 639//6288 1590//6288
f 1590//6289 639//6289 641//6289
f 1592//6490 865//6490 1583//6490
f 867//6489 1593//6489 1583//6489
f 1591//6484 868//6484 1592//6484
f 640//6292 1587//6292 644//6292
f 866//6488 1593//6488 867//6488
f 1592//6485 868//6485 865//6485
f 1584//6279 762//6279 759//6279
f 1531//6255 843//6255 854//6255
f 1531//6256 854//6256 1595//6256
f 1541//6253 1130//6253 1531//6253
f 1130//6254 843//6254 1531//6254
f 1531//6259 831//6259 823//6259
f 823//6260 1596//6260 1531//6260
f 1595//6257 1597//6257 1531//6257
f 1531//6258 1597//6258 831//6258
f 1541//6247 1598//6247 1599//6247
f 1599//6248 1095//6248 1541//6248
f 1578//6245 1600//6245 1541//6245
f 1541//6246 1600//6246 1598//6246
f 1601//6251 1174//6251 1541//6251
f 1541//6252 1174//6252 1130//6252
f 1541//6249 1095//6249 1602//6249
f 1541//6250 1602//6250 1601//6250
f 765//6273 1603//6273 1604//6273
f 1605//6274 763//6274 1604//6274
f 1605//6269 771//6269 763//6269
f 764//6272 1603//6272 765//6272
f 1585//6277 1606//6277 1584//6277
f 1606//6278 762//6278 1584//6278
f 1604//6275 763//6275 765//6275
f 1586//6276 1606//6276 1585//6276
f 1607//6263 1608//6263 1531//6263
f 1531//6264 1608//6264 1609//6264
f 1531//6261 1596//6261 1610//6261
f 1531//6262 1610//6262 1607//6262
f 1604//6267 1611//6267 1605//6267
f 1611//6268 771//6268 1605//6268
f 1531//6265 1609//6265 1532//6265
f 1603//6266 1611//6266 1604//6266
f 243//5407 1612//5407 248//5407
f 676//5408 671//5408 119//5408
f 119//5409 671//5409 670//5409
f 243//5406 1613//5406 1612//5406
f 247//5403 1614//5403 245//5403
f 245//5404 1614//5404 1613//5404
f 245//5405 1613//5405 243//5405
f 202//5414 26//5414 25//5414
f 157//5415 681//5415 1615//5415
f 1615//5416 681//5416 684//5416
f 18//5413 26//5413 202//5413
f 119//5410 670//5410 1615//5410
f 1615//5411 670//5411 240//5411
f 1615//5412 240//5412 157//5412
f 1320//5393 378//5393 376//5393
f 102//5394 677//5394 121//5394
f 121//5395 677//5395 676//5395
f 1320//5392 1616//5392 378//5392
f 1617//5389 1618//5389 1619//5389
f 1619//5390 1618//5390 1616//5390
f 1619//5391 1616//5391 1320//5391
f 252//5400 1620//5400 1621//5400
f 252//5401 1621//5401 247//5401
f 247//5402 1621//5402 1614//5402
f 119//5399 1620//5399 252//5399
f 121//5396 676//5396 119//5396
f 157//5397 159//5397 681//5397
f 1615//5398 1620//5398 119//5398
f 1622//5435 18//5435 1623//5435
f 1623//5436 18//5436 202//5436
f 213//5437 220//5437 106//5437
f 1622//5434 53//5434 18//5434
f 1624//5431 50//5431 1625//5431
f 1625//5432 50//5432 53//5432
f 1625//5433 53//5433 1622//5433
f 48//5442 110//5442 109//5442
f 48//5443 109//5443 210//5443
f 699//5444 701//5444 118//5444
f 47//5441 110//5441 48//5441
f 106//5438 220//5438 25//5438
f 213//5439 106//5439 47//5439
f 47//5440 106//5440 110//5440
f 1626//5421 1615//5421 1627//5421
f 1627//5422 1615//5422 1628//5422
f 1627//5423 1628//5423 1629//5423
f 359//5420 699//5420 118//5420
f 1615//5417 684//5417 1628//5417
f 362//5418 1326//5418 359//5418
f 359//5419 1326//5419 699//5419
f 1630//5428 15//5428 57//5428
f 1630//5429 57//5429 1624//5429
f 1624//5430 57//5430 50//5430
f 1631//5427 15//5427 1630//5427
f 1629//5424 1628//5424 1632//5424
f 1633//5425 12//5425 1631//5425
f 1631//5426 12//5426 15//5426
f 1617//5388 1634//5388 1618//5388
f 1622//5328 1623//5328 1635//5328
f 1635//5329 1623//5329 1636//5329
f 1620//5342 1615//5342 1637//5342
f 1638//5297 1299//5297 1298//5297
f 1639//5294 1640//5294 1641//5294
f 1639//5295 1641//5295 1638//5295
f 1638//5296 1641//5296 1299//5296
f 1642//5357 1643//5357 1644//5357
f 1645//5358 1646//5358 1647//5358
f 1647//5359 1646//5359 1648//5359
f 1293//5356 1292//5356 1287//5356
f 1637//5343 1615//5343 1649//5343
f 1287//5352 1292//5352 1288//5352
f 1288//5353 1292//5353 1294//5353
f 1650//5284 1651//5284 1652//5284
f 1650//5285 1652//5285 1653//5285
f 1654//5286 1655//5286 1656//5286
f 1657//5283 1651//5283 1650//5283
f 1658//5280 1659//5280 1660//5280
f 1658//5281 1660//5281 1657//5281
f 1657//5282 1660//5282 1651//5282
f 1661//5291 1662//5291 1663//5291
f 1663//5292 1662//5292 1640//5292
f 1663//5293 1640//5293 1639//5293
f 1661//5290 1664//5290 1662//5290
f 1654//5287 1656//5287 1665//5287
f 1665//5288 1656//5288 1664//5288
f 1665//5289 1664//5289 1661//5289
f 1666//5378 1667//5378 1617//5378
f 1617//5379 1667//5379 1668//5379
f 1617//5380 1668//5380 1669//5380
f 1670//5377 1671//5377 1672//5377
f 1326//5374 385//5374 387//5374
f 1326//5375 387//5375 1670//5375
f 1670//5376 387//5376 1671//5376
f 1673//5385 1670//5385 1674//5385
f 1674//5386 1670//5386 1672//5386
f 1675//5387 1634//5387 1617//5387
f 1673//5384 1617//5384 1670//5384
f 1287//5381 1296//5381 1293//5381
f 1293//5382 1296//5382 1617//5382
f 1293//5383 1617//5383 1673//5383
f 1642//5364 1676//5364 1647//5364
f 1647//5365 1676//5365 1677//5365
f 1647//5366 1677//5366 1645//5366
f 1642//5363 1678//5363 1676//5363
f 1647//5360 1648//5360 1617//5360
f 1617//5361 1648//5361 1670//5361
f 1644//5362 1678//5362 1642//5362
f 1679//5371 1617//5371 1680//5371
f 1680//5372 1617//5372 1669//5372
f 362//5373 385//5373 1326//5373
f 1617//5370 1681//5370 1666//5370
f 1617//5367 1296//5367 1675//5367
f 1619//5368 1682//5368 1617//5368
f 1617//5369 1682//5369 1681//5369
f 199//5520 24//5520 201//5520
f 201//5521 24//5521 17//5521
f 201//5522 17//5522 19//5522
f 199//5519 107//5519 24//5519
f 1295//5516 1683//5516 1286//5516
f 1295//5517 1286//5517 1288//5517
f 219//5518 107//5518 199//5518
f 201//5527 1636//5527 55//5527
f 55//5528 1636//5528 1684//5528
f 55//5529 1684//5529 56//5529
f 201//5526 1635//5526 1636//5526
f 19//5523 52//5523 1635//5523
f 1635//5524 52//5524 51//5524
f 19//5525 1635//5525 201//5525
f 1685//5506 1686//5506 1687//5506
f 1688//5507 1689//5507 1690//5507
f 1690//5508 1689//5508 1691//5508
f 1685//5505 1692//5505 1686//5505
f 1693//5502 1694//5502 1683//5502
f 1683//5503 1694//5503 1692//5503
f 1683//5504 1692//5504 1685//5504
f 1688//5513 1690//5513 1695//5513
f 1695//5514 1690//5514 1683//5514
f 1695//5515 1683//5515 1295//5515
f 218//5512 215//5512 214//5512
f 1690//5509 1691//5509 1327//5509
f 1327//5510 1691//5510 386//5510
f 1327//5511 386//5511 373//5511
f 1696//5548 683//5548 682//5548
f 1696//5549 682//5549 1697//5549
f 1697//5550 682//5550 158//5550
f 1698//5547 683//5547 1696//5547
f 13//5544 1699//5544 1635//5544
f 1635//5545 1699//5545 1700//5545
f 1635//5546 1700//5546 1701//5546
f 1702//5555 241//5555 1649//5555
f 1649//5556 241//5556 669//5556
f 1649//5557 669//5557 1637//5557
f 1702//5554 168//5554 241//5554
f 1697//5551 158//5551 1703//5551
f 1703//5552 158//5552 168//5552
f 1703//5553 168//5553 1702//5553
f 60//5534 1704//5534 1705//5534
f 60//5535 1705//5535 40//5535
f 13//5536 1706//5536 1699//5536
f 59//5533 1704//5533 60//5533
f 56//5530 1684//5530 1707//5530
f 56//5531 1707//5531 59//5531
f 59//5532 1707//5532 1704//5532
f 51//5541 33//5541 1635//5541
f 1635//5542 33//5542 14//5542
f 1635//5543 14//5543 13//5543
f 380//5540 28//5540 212//5540
f 1699//5537 1708//5537 1700//5537
f 374//5538 1319//5538 380//5538
f 380//5539 1319//5539 28//5539
f 1709//5501 1710//5501 1711//5501
f 1623//5463 61//5463 58//5463
f 39//5464 41//5464 1712//5464
f 109//5465 20//5465 210//5465
f 1623//5462 54//5462 61//5462
f 198//5459 25//5459 217//5459
f 217//5460 25//5460 220//5460
f 202//5461 54//5461 1623//5461
f 1623//5470 1712//5470 1713//5470
f 1713//5471 1712//5471 1714//5471
f 1713//5472 1714//5472 1715//5472
f 1623//5469 39//5469 1712//5469
f 210//5466 20//5466 23//5466
f 210//5467 23//5467 211//5467
f 58//5468 39//5468 1623//5468
f 114//5449 710//5449 704//5449
f 114//5450 704//5450 122//5450
f 122//5451 704//5451 679//5451
f 112//5448 710//5448 114//5448
f 118//5445 701//5445 703//5445
f 118//5446 703//5446 112//5446
f 112//5447 703//5447 710//5447
f 99//5456 102//5456 97//5456
f 200//5457 202//5457 198//5457
f 198//5458 202//5458 25//5458
f 99//5455 677//5455 102//5455
f 122//5452 679//5452 101//5452
f 101//5453 679//5453 677//5453
f 101//5454 677//5454 99//5454
f 1716//5491 1717//5491 1718//5491
f 1718//5492 1717//5492 1719//5492
f 1718//5493 1719//5493 1720//5493
f 1683//5490 1721//5490 1693//5490
f 1722//5487 1683//5487 1718//5487
f 1722//5488 1718//5488 1723//5488
f 1690//5489 1721//5489 1683//5489
f 1319//5498 377//5498 383//5498
f 1319//5499 383//5499 1709//5499
f 1709//5500 383//5500 1710//5500
f 374//5497 377//5497 1319//5497
f 1720//5494 1724//5494 1718//5494
f 1718//5495 1724//5495 1725//5495
f 1718//5496 1725//5496 1723//5496
f 29//5477 211//5477 27//5477
f 27//5478 211//5478 23//5478
f 1726//5479 1727//5479 1685//5479
f 29//5476 381//5476 211//5476
f 376//5473 375//5473 1320//5473
f 1320//5474 375//5474 381//5474
f 1320//5475 381//5475 29//5475
f 1728//5484 1729//5484 1709//5484
f 1709//5485 1729//5485 1683//5485
f 1709//5486 1683//5486 1722//5486
f 1726//5483 1685//5483 1687//5483
f 1729//5480 1286//5480 1683//5480
f 1711//5481 1728//5481 1709//5481
f 1288//5482 1294//5482 1295//5482
f 951//5045 980//5045 972//5045
f 1186//5046 1049//5046 1064//5046
f 1064//5047 1049//5047 1048//5047
f 977//5044 980//5044 951//5044
f 944//5041 1659//5041 946//5041
f 946//5042 1659//5042 1730//5042
f 946//5043 1730//5043 948//5043
f 813//5052 1325//5052 1046//5052
f 813//5053 1046//5053 819//5053
f 819//5054 1046//5054 1054//5054
f 815//5051 1325//5051 813//5051
f 1064//5048 1048//5048 815//5048
f 1048//5049 1322//5049 815//5049
f 815//5050 1322//5050 1325//5050
f 975//5031 1289//5031 1291//5031
f 975//5032 1291//5032 1052//5032
f 1052//5033 1655//5033 975//5033
f 974//5030 1289//5030 975//5030
f 1731//5027 1732//5027 1059//5027
f 1059//5028 1732//5028 851//5028
f 1059//5029 851//5029 849//5029
f 938//5038 1651//5038 1660//5038
f 938//5039 1660//5039 944//5039
f 944//5040 1660//5040 1659//5040
f 937//5037 1651//5037 938//5037
f 975//5034 1655//5034 1652//5034
f 975//5035 1652//5035 937//5035
f 937//5036 1652//5036 1651//5036
f 1733//5073 1005//5073 1003//5073
f 1733//5074 1003//5074 1734//5074
f 1734//5075 1003//5075 1007//5075
f 1735//5072 1005//5072 1733//5072
f 1736//5069 1011//5069 1010//5069
f 1736//5070 1010//5070 1735//5070
f 1735//5071 1010//5071 1005//5071
f 1737//5080 951//5080 908//5080
f 942//5081 941//5081 963//5081
f 963//5082 941//5082 972//5082
f 1737//5079 977//5079 951//5079
f 1734//5076 1007//5076 1738//5076
f 1738//5077 1007//5077 977//5077
f 1738//5078 977//5078 1737//5078
f 817//5059 1280//5059 818//5059
f 818//5060 1280//5060 1289//5060
f 818//5061 1289//5061 825//5061
f 817//5058 1277//5058 1280//5058
f 819//5055 1054//5055 836//5055
f 836//5056 1054//5056 1277//5056
f 836//5057 1277//5057 817//5057
f 1314//5066 1655//5066 1062//5066
f 1062//5067 1655//5067 1052//5067
f 1739//5068 1011//5068 1736//5068
f 1314//5065 1664//5065 1655//5065
f 825//5062 1289//5062 974//5062
f 825//5063 974//5063 827//5063
f 1662//5064 1664//5064 1314//5064
f 1731//5026 1740//5026 1732//5026
f 1653//4988 1741//4988 1650//4988
f 1650//4989 1741//4989 1658//4989
f 1650//4990 1658//4990 1657//4990
f 1653//4987 947//4987 1741//4987
f 1050//4984 814//4984 1321//4984
f 1321//4985 814//4985 1324//4985
f 945//4986 947//4986 1653//4986
f 1742//4995 1743//4995 1744//4995
f 1744//4996 1743//4996 1745//4996
f 1746//4997 1747//4997 1748//4997
f 1742//4994 1749//4994 1743//4994
f 1067//4991 1068//4991 1025//4991
f 1748//4992 1750//4992 1746//4992
f 1751//4993 1749//4993 1742//4993
f 820//4974 1047//4974 1324//4974
f 820//4975 1324//4975 814//4975
f 976//4976 936//4976 1653//4976
f 1045//4973 1047//4973 820//4973
f 1654//4970 1051//4970 1053//4970
f 1654//4971 1053//4971 1653//4971
f 1653//4972 1053//4972 976//4972
f 1061//4981 1187//4981 1063//4981
f 1061//4982 1063//4982 1050//4982
f 1050//4983 1063//4983 814//4983
f 1183//4980 1187//4980 1061//4980
f 1653//4977 936//4977 939//4977
f 1653//4978 939//4978 945//4978
f 947//4979 1354//4979 1741//4979
f 1664//5016 1656//5016 1655//5016
f 1025//5017 1027//5017 1067//5017
f 1067//5018 1027//5018 1744//5018
f 1744//5015 1752//5015 1753//5015
f 1745//5012 1754//5012 1755//5012
f 1756//5013 1757//5013 1744//5013
f 1744//5014 1757//5014 1752//5014
f 1758//5023 1759//5023 1744//5023
f 1744//5024 1759//5024 1740//5024
f 1744//5025 1740//5025 1731//5025
f 1760//5022 1745//5022 1755//5022
f 1067//5019 1744//5019 1761//5019
f 1761//5020 1744//5020 1745//5020
f 1761//5021 1745//5021 1760//5021
f 1744//5002 1027//5002 1758//5002
f 1731//5003 1762//5003 1744//5003
f 1744//5004 1762//5004 1763//5004
f 1742//5001 1764//5001 1751//5001
f 1748//4998 1747//4998 1765//4998
f 1748//4999 1765//4999 1742//4999
f 1742//5000 1765//5000 1764//5000
f 1049//5009 1182//5009 1185//5009
f 1049//5010 1185//5010 1745//5010
f 1745//5011 1185//5011 1754//5011
f 1186//5008 1182//5008 1049//5008
f 1744//5005 1763//5005 1756//5005
f 1766//5006 1744//5006 1767//5006
f 1767//5007 1744//5007 1753//5007
f 1763//5170 1768//5170 1769//5170
f 1763//5171 1769//5171 1756//5171
f 1756//5172 1769//5172 1770//5172
f 1762//5169 1768//5169 1763//5169
f 1731//5166 1771//5166 1772//5166
f 1731//5167 1772//5167 1762//5167
f 1762//5168 1772//5168 1768//5168
f 1752//5177 1773//5177 1753//5177
f 1753//5178 1773//5178 1774//5178
f 1753//5179 1774//5179 1767//5179
f 1752//5176 1775//5176 1773//5176
f 1756//5173 1770//5173 1757//5173
f 1757//5174 1770//5174 1775//5174
f 1757//5175 1775//5175 1752//5175
f 1751//5156 1776//5156 1777//5156
f 1751//5157 1777//5157 1749//5157
f 1749//5158 1777//5158 1778//5158
f 1764//5155 1776//5155 1751//5155
f 1765//5152 1779//5152 1780//5152
f 1765//5153 1780//5153 1764//5153
f 1764//5154 1780//5154 1776//5154
f 1745//5163 1061//5163 1049//5163
f 1059//5164 1058//5164 1771//5164
f 1059//5165 1771//5165 1731//5165
f 1745//5162 1781//5162 1061//5162
f 1749//5159 1778//5159 1743//5159
f 1743//5160 1778//5160 1781//5160
f 1743//5161 1781//5161 1745//5161
f 1782//5238 1783//5238 1784//5238
f 1782//5239 1784//5239 1785//5239
f 1785//5240 1784//5240 1786//5240
f 1787//5237 1783//5237 1782//5237
f 1788//5234 1737//5234 1789//5234
f 1788//5235 1789//5235 1787//5235
f 1787//5236 1789//5236 1783//5236
f 1354//5277 1730//5277 1741//5277
f 1741//5278 1730//5278 1659//5278
f 1741//5279 1659//5279 1658//5279
f 1354//5276 948//5276 1730//5276
f 1785//5241 1786//5241 1790//5241
f 1790//5242 1786//5242 931//5242
f 1790//5243 931//5243 930//5243
f 1791//5224 1739//5224 1736//5224
f 1791//5225 1736//5225 1792//5225
f 1792//5226 1736//5226 1735//5226
f 1012//5223 1739//5223 1791//5223
f 1767//5180 1774//5180 1793//5180
f 1767//5181 1793//5181 1766//5181
f 1012//5222 1011//5222 1739//5222
f 1794//5231 1734//5231 1795//5231
f 1795//5232 1734//5232 1738//5232
f 1795//5233 1738//5233 1796//5233
f 1794//5230 1733//5230 1734//5230
f 1792//5227 1735//5227 1797//5227
f 1797//5228 1735//5228 1733//5228
f 1797//5229 1733//5229 1794//5229
f 1747//5151 1779//5151 1765//5151
f 912//5101 971//5101 969//5101
f 912//5102 969//5102 916//5102
f 1783//5103 1789//5103 1784//5103
f 962//5100 971//5100 912//5100
f 908//5097 906//5097 1737//5097
f 1737//5098 906//5098 910//5098
f 1737//5099 910//5099 918//5099
f 849//5108 847//5108 1059//5108
f 1059//5109 847//5109 859//5109
f 1059//5110 859//5110 966//5110
f 1786//5107 918//5107 931//5107
f 1784//5104 1789//5104 1737//5104
f 1784//5105 1737//5105 1786//5105
f 1786//5106 1737//5106 918//5106
f 915//5087 962//5087 912//5087
f 1314//5088 1302//5088 1300//5088
f 1641//5089 1640//5089 1662//5089
f 915//5086 959//5086 962//5086
f 942//5083 963//5083 955//5083
f 955//5084 963//5084 959//5084
f 955//5085 959//5085 915//5085
f 1662//5094 1314//5094 1641//5094
f 1641//5095 1314//5095 1300//5095
f 1641//5096 1300//5096 1299//5096
f 958//5093 972//5093 941//5093
f 952//5090 951//5090 956//5090
f 956//5091 951//5091 972//5091
f 956//5092 972//5092 958//5092
f 1798//5141 1759//5141 1799//5141
f 1799//5142 1759//5142 1758//5142
f 1799//5143 1758//5143 1800//5143
f 1798//5140 1740//5140 1759//5140
f 852//5137 1732//5137 1801//5137
f 1801//5138 1732//5138 1740//5138
f 1801//5139 1740//5139 1798//5139
f 1746//5148 1802//5148 1803//5148
f 1746//5149 1803//5149 1747//5149
f 1747//5150 1803//5150 1779//5150
f 1750//5147 1802//5147 1746//5147
f 1800//5144 1758//5144 1027//5144
f 1800//5145 1027//5145 1026//5145
f 1750//5146 1804//5146 1802//5146
f 1185//5121 1805//5121 1754//5121
f 1754//5122 1805//5122 1806//5122
f 1754//5123 1806//5123 1755//5123
f 1185//5120 1184//5120 1805//5120
f 966//5111 859//5111 916//5111
f 966//5112 916//5112 967//5112
f 967//5113 916//5113 969//5113
f 1761//5128 1807//5128 1065//5128
f 1761//5129 1065//5129 1067//5129
f 852//5136 851//5136 1732//5136
f 1760//5127 1807//5127 1761//5127
f 1755//5124 1806//5124 1808//5124
f 1755//5125 1808//5125 1760//5125
f 1760//5126 1808//5126 1807//5126
f 1637//5558 669//5558 124//5558
f 1809//5980 1810//5980 1811//5980
f 1812//5981 725//5981 1810//5981
f 1810//5982 725//5982 724//5982
f 1809//5979 1813//5979 1810//5979
f 1814//5975 1815//5975 1813//5975
f 1811//5976 1816//5976 1809//5976
f 1814//5978 1813//5978 1809//5978
f 1817//5987 1818//5987 1819//5987
f 1819//5988 1818//5988 1820//5988
f 1819//5989 1820//5989 1821//5989
f 1820//5986 1822//5986 1823//5986
f 1810//5983 724//5983 1811//5983
f 1824//5984 1818//5984 1815//5984
f 1815//5985 1818//5985 1817//5985
f 1825//5965 1826//5965 1827//5965
f 1825//5966 1827//5966 1828//5966
f 1829//5967 1830//5967 1831//5967
f 1832//5964 1826//5964 1825//5964
f 1833//5961 1827//5961 1826//5961
f 1828//5962 1827//5962 1834//5962
f 1834//5963 1827//5963 1833//5963
f 1835//5972 1824//5972 1836//5972
f 1836//5973 1824//5973 1815//5973
f 1836//5974 1815//5974 1814//5974
f 1837//5971 1830//5971 1829//5971
f 1830//5968 1838//5968 1831//5968
f 1831//5969 1838//5969 1839//5969
f 1838//5970 1830//5970 1837//5970
f 1840//6008 1841//6008 1842//6008
f 1842//6009 1841//6009 1843//6009
f 1842//6010 1843//6010 1844//6010
f 1820//6007 1824//6007 1835//6007
f 1845//6004 1816//6004 1811//6004
f 1845//6005 1811//6005 724//6005
f 1818//6006 1824//6006 1820//6006
f 1846//6015 1847//6015 1848//6015
f 1847//6016 1849//6016 1850//6016
f 1850//6017 1849//6017 1851//6017
f 1852//6014 1847//6014 1846//6014
f 1847//6011 1850//6011 1848//6011
f 1844//6012 1843//6012 1852//6012
f 1852//6013 1843//6013 1847//6013
f 1853//5994 1835//5994 1854//5994
f 1854//5995 1835//5995 1836//5995
f 1854//5996 1836//5996 1855//5996
f 1856//5993 1835//5993 1853//5993
f 1821//5990 1820//5990 1823//5990
f 1822//5991 1820//5991 1856//5991
f 1856//5992 1820//5992 1835//5992
f 1857//6001 1845//6001 723//6001
f 723//6002 1845//6002 724//6002
f 1858//6003 1816//6003 1845//6003
f 1836//6000 1858//6000 1855//6000
f 1809//5997 1816//5997 1814//5997
f 1814//5998 1816//5998 1858//5998
f 1814//5999 1858//5999 1836//5999
f 1859//5960 1860//5960 1861//5960
f 1862//5916 1863//5916 1864//5916
f 1864//5917 1863//5917 1865//5917
f 1863//5918 1862//5918 1866//5918
f 1863//5915 1866//5915 1865//5915
f 1867//5912 1868//5912 1869//5912
f 1869//5913 1868//5913 1870//5913
f 1869//5914 1870//5914 1871//5914
f 1872//5923 1873//5923 1874//5923
f 1875//5924 1876//5924 1877//5924
f 1877//5925 1876//5925 1878//5925
f 1872//5922 1875//5922 1873//5922
f 1866//5919 1862//5919 1879//5919
f 1875//5920 1877//5920 1873//5920
f 1876//5921 1875//5921 1872//5921
f 1880//5902 1881//5902 1882//5902
f 1882//5903 1881//5903 1883//5903
f 1868//5904 1884//5904 1870//5904
f 1885//5901 1886//5901 1887//5901
f 1880//5898 1882//5898 1886//5898
f 1881//5899 1880//5899 1885//5899
f 1885//5900 1880//5900 1886//5900
f 1888//5909 1889//5909 1890//5909
f 1868//5910 1867//5910 1884//5910
f 1884//5911 1867//5911 1891//5911
f 1892//5908 1889//5908 1888//5908
f 1870//5905 1884//5905 1889//5905
f 1871//5906 1870//5906 1892//5906
f 1892//5907 1870//5907 1889//5907
f 769//5944 1860//5944 1893//5944
f 1894//5951 1895//5951 1896//5951
f 1896//5952 1895//5952 1897//5952
f 769//5943 1895//5943 1860//5943
f 619//5940 1897//5940 726//5940
f 726//5941 1897//5941 1895//5941
f 726//5942 1895//5942 769//5942
f 1898//5957 1893//5957 1899//5957
f 1899//5958 1893//5958 1860//5958
f 1899//5959 1860//5959 1859//5959
f 1861//5956 1895//5956 1894//5956
f 1893//5953 1898//5953 1900//5953
f 1900//5954 1898//5954 1901//5954
f 1860//5955 1895//5955 1861//5955
f 1902//5930 1903//5930 1904//5930
f 1904//5931 1903//5931 1905//5931
f 1906//5932 1907//5932 1908//5932
f 1909//5929 1903//5929 1902//5929
f 1903//5926 1910//5926 1905//5926
f 1911//5927 1910//5927 1909//5927
f 1909//5928 1910//5928 1903//5928
f 1912//5937 1907//5937 1913//5937
f 1900//5938 617//5938 1893//5938
f 1893//5939 617//5939 769//5939
f 1912//5936 1908//5936 1907//5936
f 1913//5933 1907//5933 1914//5933
f 1914//5934 1907//5934 1906//5934
f 1915//5935 1908//5935 1912//5935
f 1916//6106 1917//6106 1918//6106
f 1917//6107 1919//6107 1918//6107
f 1918//6108 1919//6108 1920//6108
f 1921//6105 1917//6105 1916//6105
f 1922//6102 1923//6102 1924//6102
f 1924//6103 1923//6103 1925//6103
f 1924//6104 1925//6104 1926//6104
f 1927//6113 1928//6113 452//6113
f 452//6114 1928//6114 453//6114
f 454//6116 453//6116 1929//6116
f 1930//6112 1928//6112 1927//6112
f 1920//6109 1919//6109 1931//6109
f 1931//6110 1919//6110 1928//6110
f 1931//6111 1928//6111 1930//6111
f 1932//6089 424//6089 433//6089
f 427//6093 425//6093 1933//6093
f 1933//6094 425//6094 1922//6094
f 1934//6088 1932//6088 433//6088
f 1935//6084 508//6084 510//6084
f 510//6086 509//6086 1934//6086
f 509//6087 1932//6087 1934//6087
f 508//6099 1932//6099 509//6099
f 425//6100 424//6100 1922//6100
f 1922//6101 424//6101 1923//6101
f 508//6098 424//6098 1932//6098
f 1933//6095 1922//6095 1936//6095
f 1936//6096 1922//6096 1937//6096
f 1923//6097 424//6097 508//6097
f 1938//6135 1939//6135 1940//6135
f 1940//6136 1939//6136 1941//6136
f 1938//6137 1942//6137 1939//6137
f 1943//6134 1938//6134 1940//6134
f 1944//6131 1942//6131 1945//6131
f 1945//6132 1942//6132 1943//6132
f 1942//6133 1938//6133 1943//6133
f 1946//6142 1425//6142 1384//6142
f 1946//6143 1384//6143 1947//6143
f 1947//6144 1384//6144 1383//6144
f 1948//6141 1425//6141 1946//6141
f 1939//6138 1942//6138 1944//6138
f 1401//6139 1425//6139 1440//6139
f 1440//6140 1425//6140 1948//6140
f 1921//6121 1916//6121 1949//6121
f 1921//6122 1949//6122 1950//6122
f 1950//6123 1949//6123 1951//6123
f 1952//6120 1953//6120 1950//6120
f 1929//6117 453//6117 1950//6117
f 1929//6118 1950//6118 1954//6118
f 1954//6119 1950//6119 1953//6119
f 1928//6128 1950//6128 453//6128
f 1939//6129 1944//6129 1941//6129
f 1941//6130 1944//6130 1945//6130
f 1928//6127 1921//6127 1950//6127
f 1950//6124 1951//6124 1952//6124
f 1919//6125 1917//6125 1921//6125
f 1919//6126 1921//6126 1928//6126
f 1935//6083 1923//6083 508//6083
f 1955//6036 1956//6036 1957//6036
f 1957//6037 1958//6037 1955//6037
f 1955//6038 1958//6038 1959//6038
f 1960//6035 1955//6035 1961//6035
f 1962//6032 1963//6032 1964//6032
f 1964//6033 1963//6033 1955//6033
f 1964//6034 1955//6034 1960//6034
f 1965//6043 1963//6043 1962//6043
f 1966//6044 1956//6044 1963//6044
f 1963//6045 1956//6045 1955//6045
f 1966//6042 1963//6042 1965//6042
f 1955//6039 1959//6039 1961//6039
f 1956//6040 1966//6040 1957//6040
f 1957//6041 1966//6041 1965//6041
f 1967//6022 1968//6022 1969//6022
f 1969//6023 1968//6023 1970//6023
f 1969//6024 1970//6024 1971//6024
f 1967//6021 1972//6021 1968//6021
f 1849//6018 1969//6018 1851//6018
f 1851//6019 1969//6019 1973//6019
f 1971//6020 1973//6020 1969//6020
f 1969//6029 1847//6029 1967//6029
f 1967//6030 1847//6030 1843//6030
f 1967//6031 1843//6031 1841//6031
f 1969//6028 1849//6028 1847//6028
f 1972//6025 1967//6025 1974//6025
f 1974//6026 1967//6026 1841//6026
f 1974//6027 1841//6027 1840//6027
f 1975//6073 1924//6073 1926//6073
f 1975//6074 1926//6074 1976//6074
f 1976//6075 1926//6075 1977//6075
f 1978//6072 1924//6072 1975//6072
f 1937//6069 1922//6069 1979//6069
f 1979//6070 1922//6070 1924//6070
f 1979//6071 1924//6071 1978//6071
f 1925//6080 1923//6080 1980//6080
f 1980//6081 1923//6081 1981//6081
f 1981//6082 1923//6082 1935//6082
f 1926//6079 1982//6079 1977//6079
f 1926//6076 1925//6076 1980//6076
f 1980//6077 1983//6077 1926//6077
f 1926//6078 1983//6078 1982//6078
f 1984//6056 1985//6056 473//6056
f 473//6057 1985//6057 1986//6057
f 473//6058 1986//6058 476//6058
f 1986//6055 483//6055 477//6055
f 474//6048 472//6048 1984//6048
f 1984//6049 472//6049 1985//6049
f 1987//6054 483//6054 1986//6054
f 472//6066 477//6066 1988//6066
f 1988//6067 477//6067 512//6067
f 474//6068 1984//6068 473//6068
f 1985//6062 1988//6062 1989//6062
f 476//6059 1986//6059 477//6059
f 1986//6060 1985//6060 1989//6060
f 472//6061 1988//6061 1985//6061
f 1646//5646 1693//5646 1721//5646
f 1646//5647 1721//5647 1648//5647
f 1648//5648 1721//5648 1690//5648
f 1645//5645 1693//5645 1646//5645
f 1677//5642 1692//5642 1694//5642
f 1677//5643 1694//5643 1645//5643
f 1645//5644 1694//5644 1693//5644
f 1320//5653 1709//5653 1619//5653
f 1619//5654 1709//5654 1722//5654
f 1619//5655 1722//5655 1682//5655
f 1320//5652 1319//5652 1709//5652
f 1648//5649 1690//5649 1670//5649
f 1670//5650 1690//5650 1327//5650
f 1670//5651 1327//5651 1326//5651
f 1729//5632 1675//5632 1296//5632
f 1729//5633 1296//5633 1286//5633
f 1643//5634 1727//5634 1726//5634
f 1728//5631 1675//5631 1729//5631
f 1711//5628 1618//5628 1634//5628
f 1711//5629 1634//5629 1728//5629
f 1728//5630 1634//5630 1675//5630
f 1678//5639 1686//5639 1676//5639
f 1676//5640 1686//5640 1692//5640
f 1676//5641 1692//5641 1677//5641
f 1678//5638 1687//5638 1686//5638
f 1643//5635 1726//5635 1644//5635
f 1644//5636 1726//5636 1687//5636
f 1644//5637 1687//5637 1678//5637
f 1624//5706 1700//5706 1708//5706
f 1624//5707 1708//5707 1630//5707
f 1630//5708 1708//5708 1699//5708
f 1625//5705 1700//5705 1624//5705
f 1622//5702 1635//5702 1701//5702
f 1622//5703 1701//5703 1625//5703
f 1625//5704 1701//5704 1700//5704
f 1633//5713 13//5713 12//5713
f 41//5730 40//5730 1705//5730
f 41//5731 1705//5731 1712//5731
f 1633//5712 1706//5712 13//5712
f 1630//5709 1699//5709 1631//5709
f 1631//5710 1699//5710 1706//5710
f 1631//5711 1706//5711 1633//5711
f 1666//5660 1725//5660 1724//5660
f 1666//5661 1724//5661 1667//5661
f 1667//5662 1724//5662 1720//5662
f 1681//5659 1725//5659 1666//5659
f 1682//5656 1722//5656 1723//5656
f 1682//5657 1723//5657 1681//5657
f 1681//5658 1723//5658 1725//5658
f 1669//5667 1717//5667 1680//5667
f 1680//5668 1717//5668 1716//5668
f 1680//5669 1716//5669 1679//5669
f 1669//5666 1719//5666 1717//5666
f 1667//5663 1720//5663 1668//5663
f 1668//5664 1720//5664 1719//5664
f 1668//5665 1719//5665 1669//5665
f 1710//5627 1618//5627 1711//5627
f 669//5577 680//5577 124//5577
f 124//5578 680//5578 675//5578
f 124//5579 675//5579 120//5579
f 32//5576 212//5576 28//5576
f 21//5573 209//5573 22//5573
f 22//5574 209//5574 212//5574
f 22//5575 212//5575 32//5575
f 124//5584 251//5584 1637//5584
f 1637//5585 251//5585 246//5585
f 1637//5586 246//5586 244//5586
f 103//5583 98//5583 104//5583
f 120//5580 675//5580 678//5580
f 120//5581 678//5581 103//5581
f 103//5582 678//5582 98//5582
f 115//5563 689//5563 705//5563
f 115//5564 705//5564 113//5564
f 219//5565 218//5565 107//5565
f 123//5562 689//5562 115//5562
f 678//5559 100//5559 98//5559
f 100//5560 678//5560 123//5560
f 123//5561 678//5561 689//5561
f 108//5570 46//5570 216//5570
f 108//5571 216//5571 21//5571
f 21//5572 216//5572 209//5572
f 105//5569 46//5569 108//5569
f 107//5566 218//5566 214//5566
f 107//5567 214//5567 105//5567
f 105//5568 214//5568 46//5568
f 1671//5611 1689//5611 1672//5611
f 1672//5612 1689//5612 1688//5612
f 1672//5613 1688//5613 1674//5613
f 1671//5610 1691//5610 1689//5610
f 700//5601 111//5601 702//5601
f 387//5608 386//5608 1691//5608
f 387//5609 1691//5609 1671//5609
f 383//5624 378//5624 1616//5624
f 383//5625 1616//5625 1710//5625
f 1710//5626 1616//5626 1618//5626
f 1673//5617 1295//5617 1293//5617
f 1674//5614 1688//5614 1695//5614
f 1674//5615 1695//5615 1673//5615
f 1673//5616 1695//5616 1295//5616
f 244//5591 250//5591 1637//5591
f 1637//5592 250//5592 1990//5592
f 1637//5593 1990//5593 1991//5593
f 113//5590 702//5590 111//5590
f 250//5587 249//5587 1990//5587
f 705//5588 709//5588 113//5588
f 113//5589 709//5589 702//5589
f 1327//5598 360//5598 706//5598
f 706//5599 360//5599 111//5599
f 706//5600 111//5600 700//5600
f 1327//5597 361//5597 360//5597
f 1991//5594 1990//5594 1992//5594
f 1991//5595 1992//5595 1993//5595
f 373//5596 361//5596 1327//5596
f 1994//5858 1995//5858 1996//5858
f 1997//5859 1998//5859 1999//5859
f 1999//5860 1998//5860 1994//5860
f 2000//5857 2001//5857 2002//5857
f 2003//5854 2004//5854 2005//5854
f 2005//5855 2004//5855 2001//5855
f 2005//5856 2001//5856 2000//5856
f 2006//5865 2007//5865 2008//5865
f 2008//5866 2007//5866 2009//5866
f 2010//5867 2011//5867 2012//5867
f 2006//5864 2013//5864 2007//5864
f 1999//5861 1994//5861 2014//5861
f 2014//5862 1994//5862 1996//5862
f 2015//5863 2013//5863 2006//5863
f 723//5841 2016//5841 2017//5841
f 723//5842 2017//5842 2018//5842
f 1812//5843 2019//5843 725//5843
f 2007//5840 2016//5840 723//5840
f 2007//5837 723//5837 2012//5837
f 2012//5838 723//5838 725//5838
f 2012//5839 725//5839 2020//5839
f 2021//5848 2022//5848 725//5848
f 725//5849 2022//5849 2023//5849
f 725//5850 2023//5850 2020//5850
f 725//5847 2024//5847 2021//5847
f 725//5844 2019//5844 2025//5844
f 2025//5845 2026//5845 725//5845
f 725//5846 2026//5846 2024//5846
f 2027//5888 2028//5888 2029//5888
f 2027//5889 2029//5889 2030//5889
f 2030//5890 2029//5890 2031//5890
f 2028//5887 2032//5887 2033//5887
f 2033//5884 2029//5884 2028//5884
f 653//5885 652//5885 2028//5885
f 2028//5886 652//5886 2032//5886
f 1998//5895 2034//5895 1994//5895
f 2034//5896 2035//5896 2036//5896
f 2036//5897 2035//5897 2037//5897
f 2035//5894 2034//5894 1998//5894
f 2030//5891 2031//5891 2038//5891
f 2034//5892 2036//5892 1994//5892
f 1994//5893 2036//5893 1995//5893
f 2028//5873 2039//5873 753//5873
f 2028//5874 753//5874 653//5874
f 2027//5876 2030//5876 2039//5876
f 2027//5872 2039//5872 2028//5872
f 2012//5868 2011//5868 2040//5868
f 2012//5869 2040//5869 2041//5869
f 2041//5870 2040//5870 2042//5870
f 2043//5881 2031//5881 2044//5881
f 2031//5882 2029//5882 2044//5882
f 2044//5883 2029//5883 2033//5883
f 2038//5880 2031//5880 2043//5880
f 2039//5877 2030//5877 2045//5877
f 2030//5878 2038//5878 2045//5878
f 2045//5879 2038//5879 2043//5879
f 2007//5836 2013//5836 2016//5836
f 684//5782 683//5782 1698//5782
f 684//5783 1698//5783 1628//5783
f 1628//5784 1698//5784 1696//5784
f 1612//5765 249//5765 248//5765
f 1613//5762 1992//5762 1990//5762
f 1613//5763 1990//5763 1612//5763
f 1612//5764 1990//5764 249//5764
f 1629//5789 1703//5789 1627//5789
f 1627//5790 1703//5790 1702//5790
f 1627//5791 1702//5791 1626//5791
f 1629//5788 1697//5788 1703//5788
f 1628//5785 1696//5785 1632//5785
f 1632//5786 1696//5786 1697//5786
f 1632//5787 1697//5787 1629//5787
f 1715//5736 1707//5736 1684//5736
f 1715//5737 1684//5737 1713//5737
f 1713//5738 1684//5738 1636//5738
f 1714//5735 1707//5735 1715//5735
f 1712//5732 1705//5732 1704//5732
f 1712//5733 1704//5733 1714//5733
f 1714//5734 1704//5734 1707//5734
f 1621//5759 1993//5759 1614//5759
f 1614//5760 1993//5760 1992//5760
f 1614//5761 1992//5761 1613//5761
f 1621//5758 1991//5758 1993//5758
f 1713//5739 1636//5739 1623//5739
f 1620//5756 1637//5756 1991//5756
f 1620//5757 1991//5757 1621//5757
f 2005//5826 482//5826 1999//5826
f 1999//5827 482//5827 483//5827
f 1999//5828 483//5828 652//5828
f 2005//5825 2046//5825 482//5825
f 483//5822 2047//5822 2048//5822
f 483//5823 2048//5823 2049//5823
f 2050//5824 2046//5824 2005//5824
f 1999//5833 2051//5833 1997//5833
f 2020//5834 2010//5834 2012//5834
f 2018//5835 1857//5835 723//5835
f 1999//5832 2052//5832 2051//5832
f 652//5829 483//5829 2049//5829
f 652//5830 2049//5830 2032//5830
f 2053//5831 2052//5831 1999//5831
f 2054//5812 2036//5812 2037//5812
f 2046//5813 512//5813 482//5813
f 483//5814 1987//5814 2055//5814
f 2054//5811 1905//5811 2036//5811
f 1626//5792 1702//5792 1649//5792
f 1626//5793 1649//5793 1615//5793
f 1904//5810 1905//5810 2054//5810
f 1999//5819 654//5819 753//5819
f 1999//5820 753//5820 2053//5820
f 2055//5821 2047//5821 483//5821
f 652//5818 654//5818 1999//5818
f 2000//5815 2056//5815 2005//5815
f 2005//5816 2056//5816 2057//5816
f 2005//5817 2057//5817 2050//5817
f 1468//6635 1472//6635 1469//6635
f 2058//8175 2059//8175 2060//8175
f 2058//8174 2061//8174 2059//8174
f 2062//8177 2063//8177 2064//8177
f 2065//8176 2066//8176 2067//8176
f 2068//8173 2061//8173 2058//8173
f 2069//8170 2070//8170 2071//8170
f 2072//8169 2070//8169 2069//8169
f 2068//8172 2071//8172 2061//8172
f 2069//8171 2071//8171 2068//8171
f 2073//8184 2074//8184 2075//8184
f 2076//8183 2074//8183 2073//8183
f 2077//8186 2075//8186 2078//8186
f 2073//8185 2075//8185 2077//8185
f 2076//8182 2079//8182 2074//8182
f 2064//8179 2080//8179 2081//8179
f 2064//8178 2063//8178 2080//8178
f 2081//8181 2079//8181 2076//8181
f 2081//8180 2080//8180 2079//8180
f 2082//8157 2083//8157 2084//8157
f 2082//8156 2058//8156 2083//8156
f 2085//8159 2064//8159 2086//8159
f 2061//8158 2087//8158 2059//8158
f 2088//8155 2058//8155 2082//8155
f 2089//8152 2069//8152 2068//8152
f 2090//8151 2069//8151 2089//8151
f 2088//8154 2068//8154 2058//8154
f 2089//8153 2068//8153 2088//8153
f 2091//8166 2073//8166 2077//8166
f 2092//8165 2073//8165 2091//8165
f 2072//8168 2077//8168 2070//8168
f 2091//8167 2077//8167 2072//8167
f 2092//8164 2076//8164 2073//8164
f 2086//8161 2081//8161 2093//8161
f 2086//8160 2064//8160 2081//8160
f 2093//8163 2076//8163 2092//8163
f 2093//8162 2081//8162 2076//8162
f 2065//8211 2094//8211 2066//8211
f 2065//8210 2095//8210 2094//8210
f 2096//8213 2097//8213 2098//8213
f 2099//8212 2100//8212 2101//8212
f 2102//8209 2095//8209 2065//8209
f 2078//8206 2103//8206 2104//8206
f 2075//8205 2103//8205 2078//8205
f 2102//8208 2104//8208 2095//8208
f 2078//8207 2104//8207 2102//8207
f 2105//8220 2106//8220 2107//8220
f 2108//8219 2106//8219 2105//8219
f 2109//8222 2107//8222 2110//8222
f 2105//8221 2107//8221 2109//8221
f 2108//8218 2111//8218 2106//8218
f 2098//8215 2112//8215 2113//8215
f 2098//8214 2097//8214 2112//8214
f 2113//8217 2111//8217 2108//8217
f 2113//8216 2112//8216 2111//8216
f 2061//8193 2067//8193 2087//8193
f 2061//8192 2065//8192 2067//8192
f 2114//8195 2098//8195 2063//8195
f 2095//8194 2115//8194 2094//8194
f 2071//8191 2065//8191 2061//8191
f 2070//8188 2078//8188 2102//8188
f 2077//8187 2078//8187 2070//8187
f 2071//8190 2102//8190 2065//8190
f 2070//8189 2102//8189 2071//8189
f 2074//8202 2105//8202 2109//8202
f 2079//8201 2105//8201 2074//8201
f 2075//8204 2109//8204 2103//8204
f 2074//8203 2109//8203 2075//8203
f 2079//8200 2108//8200 2105//8200
f 2063//8197 2113//8197 2080//8197
f 2063//8196 2098//8196 2113//8196
f 2080//8199 2108//8199 2079//8199
f 2080//8198 2113//8198 2108//8198
f 2116//8103 2117//8103 2118//8103
f 2117//8102 2085//8102 2086//8102
f 2119//8105 2120//8105 2121//8105
f 2119//8104 2116//8104 2120//8104
f 2085//8101 2062//8101 2064//8101
f 2096//8098 2122//8098 2097//8098
f 2122//8097 2123//8097 2124//8097
f 2062//8100 2114//8100 2063//8100
f 2114//8099 2096//8099 2098//8099
f 2125//8112 2126//8112 2127//8112
f 2128//8111 2126//8111 2125//8111
f 2129//8114 2127//8114 2130//8114
f 2125//8113 2127//8113 2129//8113
f 2128//8110 2131//8110 2126//8110
f 2121//8107 2132//8107 2133//8107
f 2121//8106 2120//8106 2132//8106
f 2133//8109 2131//8109 2128//8109
f 2133//8108 2132//8108 2131//8108
f 2129//8085 2134//8085 2135//8085
f 2136//8084 2134//8084 2129//8084
f 2125//8087 2135//8087 2137//8087
f 2129//8086 2135//8086 2125//8086
f 2136//8083 2138//8083 2134//8083
f 2139//8080 2140//8080 2141//8080
f 2139//8079 2142//8079 2140//8079
f 2141//8082 2138//8082 2136//8082
f 2141//8081 2140//8081 2138//8081
f 2121//8094 2143//8094 2119//8094
f 2121//8093 2144//8093 2143//8093
f 2123//8096 2145//8096 2146//8096
f 2145//8095 2147//8095 2148//8095
f 2133//8092 2144//8092 2121//8092
f 2128//8089 2137//8089 2149//8089
f 2125//8088 2137//8088 2128//8088
f 2133//8091 2149//8091 2144//8091
f 2128//8090 2149//8090 2133//8090
f 2150//8139 2151//8139 2152//8139
f 2150//8138 2082//8138 2151//8138
f 2117//8141 2086//8141 2118//8141
f 2058//8140 2060//8140 2083//8140
f 2153//8137 2082//8137 2150//8137
f 2130//8134 2089//8134 2088//8134
f 2127//8133 2089//8133 2130//8133
f 2153//8136 2088//8136 2082//8136
f 2130//8135 2088//8135 2153//8135
f 2154//8148 2091//8148 2072//8148
f 2155//8147 2091//8147 2154//8147
f 2090//8150 2072//8150 2069//8150
f 2154//8149 2072//8149 2090//8149
f 2155//8146 2092//8146 2091//8146
f 2118//8143 2093//8143 2156//8143
f 2118//8142 2086//8142 2093//8142
f 2156//8145 2092//8145 2155//8145
f 2156//8144 2093//8144 2092//8144
f 2139//8121 2152//8121 2157//8121
f 2139//8120 2150//8120 2152//8120
f 2116//8123 2118//8123 2120//8123
f 2082//8122 2084//8122 2151//8122
f 2141//8119 2150//8119 2139//8119
f 2136//8116 2130//8116 2153//8116
f 2129//8115 2130//8115 2136//8115
f 2141//8118 2153//8118 2150//8118
f 2136//8117 2153//8117 2141//8117
f 2126//8130 2154//8130 2090//8130
f 2131//8129 2154//8129 2126//8129
f 2127//8132 2090//8132 2089//8132
f 2126//8131 2090//8131 2127//8131
f 2131//8128 2155//8128 2154//8128
f 2120//8125 2156//8125 2132//8125
f 2120//8124 2118//8124 2156//8124
f 2132//8127 2155//8127 2131//8127
f 2132//8126 2156//8126 2155//8126
f 2158//8319 2159//8319 2160//8319
f 2158//8318 2161//8318 2159//8318
f 2160//8321 2162//8321 2163//8321
f 2160//8320 2159//8320 2162//8320
f 2164//8317 2161//8317 2158//8317
f 2165//8314 2166//8314 2167//8314
f 2168//8313 2166//8313 2165//8313
f 2164//8316 2167//8316 2161//8316
f 2165//8315 2167//8315 2164//8315
f 2169//8328 2170//8328 2171//8328
f 2172//8327 2173//8327 2174//8327
f 2175//8330 2176//8330 2177//8330
f 2178//8329 2176//8329 2175//8329
f 2172//8326 2179//8326 2173//8326
f 2163//8323 2180//8323 2181//8323
f 2163//8322 2162//8322 2180//8322
f 2181//8325 2179//8325 2172//8325
f 2181//8324 2180//8324 2179//8324
f 2182//8301 2160//8301 2183//8301
f 2182//8300 2158//8300 2160//8300
f 2183//8303 2163//8303 2184//8303
f 2183//8302 2160//8302 2163//8302
f 2185//8299 2158//8299 2182//8299
f 2186//8296 2165//8296 2164//8296
f 2148//8295 2165//8295 2186//8295
f 2185//8298 2164//8298 2158//8298
f 2186//8297 2164//8297 2185//8297
f 2179//8310 2187//8310 2173//8310
f 2188//8309 2174//8309 2189//8309
f 2168//8312 2175//8312 2166//8312
f 2190//8311 2175//8311 2168//8311
f 2188//8308 2172//8308 2174//8308
f 2184//8305 2181//8305 2191//8305
f 2184//8304 2163//8304 2181//8304
f 2191//8307 2172//8307 2188//8307
f 2191//8306 2181//8306 2172//8306
f 2192//8355 2193//8355 2194//8355
f 2192//8354 2195//8354 2193//8354
f 2194//8357 2196//8357 2197//8357
f 2194//8356 2193//8356 2196//8356
f 2198//8353 2195//8353 2192//8353
f 2177//8350 2199//8350 2200//8350
f 2176//8349 2199//8349 2177//8349
f 2198//8352 2200//8352 2195//8352
f 2177//8351 2200//8351 2198//8351
f 2201//8364 2202//8364 2203//8364
f 2169//8363 2204//8363 2170//8363
f 2205//8366 2206//8366 2207//8366
f 2208//8365 2206//8365 2205//8365
f 2169//8362 2209//8362 2204//8362
f 2197//8359 2210//8359 2211//8359
f 2197//8358 2196//8358 2210//8358
f 2211//8361 2209//8361 2169//8361
f 2211//8360 2210//8360 2209//8360
f 2161//8337 2194//8337 2159//8337
f 2161//8336 2192//8336 2194//8336
f 2159//8339 2197//8339 2162//8339
f 2159//8338 2194//8338 2197//8338
f 2167//8335 2192//8335 2161//8335
f 2166//8332 2177//8332 2198//8332
f 2175//8331 2177//8331 2166//8331
f 2167//8334 2198//8334 2192//8334
f 2166//8333 2198//8333 2167//8333
f 2209//8346 2212//8346 2204//8346
f 2179//8345 2171//8345 2187//8345
f 2176//8348 2205//8348 2199//8348
f 2213//8347 2205//8347 2176//8347
f 2179//8344 2169//8344 2171//8344
f 2162//8341 2211//8341 2180//8341
f 2162//8340 2197//8340 2211//8340
f 2180//8343 2169//8343 2179//8343
f 2180//8342 2211//8342 2169//8342
f 2099//8247 2214//8247 2100//8247
f 2099//8246 2215//8246 2214//8246
f 2123//8249 2146//8249 2124//8249
f 2216//8248 2217//8248 2218//8248
f 2219//8245 2215//8245 2099//8245
f 2110//8242 2220//8242 2221//8242
f 2107//8241 2220//8241 2110//8241
f 2219//8244 2221//8244 2215//8244
f 2110//8243 2221//8243 2219//8243
f 2222//8256 2223//8256 2224//8256
f 2225//8255 2223//8255 2222//8255
f 2226//8258 2224//8258 2227//8258
f 2222//8257 2224//8257 2226//8257
f 2225//8254 2228//8254 2223//8254
f 2124//8251 2229//8251 2230//8251
f 2124//8250 2146//8250 2229//8250
f 2230//8253 2228//8253 2225//8253
f 2230//8252 2229//8252 2228//8252
f 2095//8229 2101//8229 2115//8229
f 2095//8228 2099//8228 2101//8228
f 2122//8231 2124//8231 2097//8231
f 2215//8230 2231//8230 2214//8230
f 2104//8227 2099//8227 2095//8227
f 2103//8224 2110//8224 2219//8224
f 2109//8223 2110//8223 2103//8223
f 2104//8226 2219//8226 2099//8226
f 2103//8225 2219//8225 2104//8225
f 2106//8238 2222//8238 2226//8238
f 2111//8237 2222//8237 2106//8237
f 2107//8240 2226//8240 2220//8240
f 2106//8239 2226//8239 2107//8239
f 2111//8236 2225//8236 2222//8236
f 2097//8233 2230//8233 2112//8233
f 2097//8232 2124//8232 2230//8232
f 2112//8235 2225//8235 2111//8235
f 2112//8234 2230//8234 2225//8234
f 2232//8283 2233//8283 2234//8283
f 2216//8282 2189//8282 2217//8282
f 2235//8285 2236//8285 2237//8285
f 2236//8284 2232//8284 2238//8284
f 2216//8281 2188//8281 2189//8281
f 2227//8278 2191//8278 2239//8278
f 2227//8277 2184//8277 2191//8277
f 2239//8280 2188//8280 2216//8280
f 2239//8279 2191//8279 2188//8279
f 2147//8292 2190//8292 2168//8292
f 2190//8291 2178//8291 2175//8291
f 2148//8294 2168//8294 2165//8294
f 2147//8293 2168//8293 2148//8293
f 2178//8290 2213//8290 2176//8290
f 2240//8287 2241//8287 2242//8287
f 2241//8286 2235//8286 2243//8286
f 2213//8289 2208//8289 2205//8289
f 2208//8288 2240//8288 2206//8288
f 2215//8265 2218//8265 2231//8265
f 2215//8264 2216//8264 2218//8264
f 2146//8267 2148//8267 2186//8267
f 2145//8266 2148//8266 2146//8266
f 2221//8263 2216//8263 2215//8263
f 2220//8260 2227//8260 2239//8260
f 2226//8259 2227//8259 2220//8259
f 2221//8262 2239//8262 2216//8262
f 2220//8261 2239//8261 2221//8261
f 2223//8274 2183//8274 2224//8274
f 2223//8273 2182//8273 2183//8273
f 2224//8276 2184//8276 2227//8276
f 2224//8275 2183//8275 2184//8275
f 2228//8272 2182//8272 2223//8272
f 2229//8269 2186//8269 2185//8269
f 2146//8268 2186//8268 2229//8268
f 2228//8271 2185//8271 2182//8271
f 2229//8270 2185//8270 2228//8270
f 2157//8078 2142//8078 2139//8078
f 2244//7886 2245//7886 2246//7886
f 2244//7885 2247//7885 2245//7885
f 2246//7888 2248//7888 2249//7888
f 2246//7887 2245//7887 2248//7887
f 2250//7884 2247//7884 2244//7884
f 2251//7881 2252//7881 2253//7881
f 718//7880 2252//7880 2251//7880
f 2250//7883 2253//7883 2247//7883
f 2251//7882 2253//7882 2250//7882
f 2254//7895 2255//7895 2256//7895
f 2257//7894 2255//7894 2254//7894
f 722//7897 2258//7897 727//7897
f 2254//7896 2256//7896 2259//7896
f 2257//7893 2260//7893 2255//7893
f 2249//7890 2261//7890 2262//7890
f 2249//7889 2248//7889 2261//7889
f 2262//7892 2260//7892 2257//7892
f 2262//7891 2261//7891 2260//7891
f 2263//7868 2244//7868 2246//7868
f 2264//7867 2244//7867 2263//7867
f 2265//7870 2246//7870 2249//7870
f 2263//7869 2246//7869 2265//7869
f 2264//7866 2250//7866 2244//7866
f 2266//7863 2251//7863 2267//7863
f 2254//7862 2259//7862 2268//7862
f 2267//7865 2250//7865 2264//7865
f 2267//7864 2251//7864 2250//7864
f 2269//7877 2254//7877 2270//7877
f 2269//7876 2257//7876 2254//7876
f 2270//7879 2268//7879 2271//7879
f 2270//7878 2254//7878 2268//7878
f 2272//7875 2257//7875 2269//7875
f 2273//7872 2249//7872 2262//7872
f 2265//7871 2249//7871 2273//7871
f 2272//7874 2262//7874 2257//7874
f 2273//7873 2262//7873 2272//7873
f 2274//7922 2255//7922 2275//7922
f 2274//7921 2256//7921 2255//7921
f 1574//7924 1576//7924 2276//7924
f 2275//7923 2255//7923 2277//7923
f 2278//7920 2256//7920 2274//7920
f 2279//7917 2280//7917 2260//7917
f 2261//7916 2279//7916 2260//7916
f 2260//7919 2277//7919 2255//7919
f 2260//7918 2280//7918 2277//7918
f 2281//7931 2282//7931 2283//7931
f 2284//7930 2282//7930 2281//7930
f 2285//7933 2286//7933 2287//7933
f 2281//7932 2283//7932 2288//7932
f 2289//7929 2282//7929 2284//7929
f 1552//7926 1557//7926 2290//7926
f 1557//7925 1574//7925 2291//7925
f 2292//7928 2282//7928 2289//7928
f 2293//7927 2294//7927 2295//7927
f 2253//7904 2296//7904 2247//7904
f 2253//7903 2297//7903 2296//7903
f 2247//7906 2298//7906 2299//7906
f 2296//7905 2298//7905 2247//7905
f 2300//7902 2297//7902 2253//7902
f 2258//7899 2301//7899 2252//7899
f 727//7898 2258//7898 2252//7898
f 2252//7901 2300//7901 2253//7901
f 2252//7900 2301//7900 2300//7900
f 2248//7913 2302//7913 2261//7913
f 2248//7912 2303//7912 2302//7912
f 2261//7915 2304//7915 2279//7915
f 2302//7914 2304//7914 2261//7914
f 2305//7911 2303//7911 2248//7911
f 2299//7908 2306//7908 2245//7908
f 2247//7907 2299//7907 2245//7907
f 2245//7910 2305//7910 2248//7910
f 2245//7909 2306//7909 2305//7909
f 2307//7814 2308//7814 2309//7814
f 2310//7813 2308//7813 2307//7813
f 2311//7816 2309//7816 2312//7816
f 2307//7815 2309//7815 2311//7815
f 2310//7812 2313//7812 2308//7812
f 2314//7809 2315//7809 2316//7809
f 2317//7808 2318//7808 2319//7808
f 2316//7811 2313//7811 2310//7811
f 2316//7810 2315//7810 2313//7810
f 2320//7823 2317//7823 2321//7823
f 2320//7822 2322//7822 2317//7822
f 2321//7825 2319//7825 2323//7825
f 2321//7824 2317//7824 2319//7824
f 2324//7821 2322//7821 2320//7821
f 2325//7818 2312//7818 2326//7818
f 2311//7817 2312//7817 2325//7817
f 2324//7820 2326//7820 2322//7820
f 2325//7819 2326//7819 2324//7819
f 2327//7796 2307//7796 2311//7796
f 2328//7795 2307//7795 2327//7795
f 2329//7798 2311//7798 2325//7798
f 2327//7797 2311//7797 2329//7797
f 2328//7794 2310//7794 2307//7794
f 2330//7791 2316//7791 2331//7791
f 2321//7790 2323//7790 2332//7790
f 2331//7793 2310//7793 2328//7793
f 2331//7792 2316//7792 2310//7792
f 2333//7805 2321//7805 2334//7805
f 2333//7804 2320//7804 2321//7804
f 2334//7807 2332//7807 2335//7807
f 2334//7806 2321//7806 2332//7806
f 2336//7803 2320//7803 2333//7803
f 2337//7800 2325//7800 2324//7800
f 2329//7799 2325//7799 2337//7799
f 2336//7802 2324//7802 2320//7802
f 2337//7801 2324//7801 2336//7801
f 2338//7850 2263//7850 2265//7850
f 2339//7849 2263//7849 2338//7849
f 2340//7852 2265//7852 2273//7852
f 2338//7851 2265//7851 2340//7851
f 2339//7848 2264//7848 2263//7848
f 719//7845 2267//7845 2341//7845
f 2270//7844 2271//7844 2342//7844
f 2341//7847 2264//7847 2339//7847
f 2341//7846 2267//7846 2264//7846
f 2343//7859 2270//7859 2344//7859
f 2343//7858 2269//7858 2270//7858
f 2344//7861 2342//7861 2345//7861
f 2344//7860 2270//7860 2342//7860
f 2346//7857 2269//7857 2343//7857
f 2347//7854 2273//7854 2272//7854
f 2340//7853 2273//7853 2347//7853
f 2346//7856 2272//7856 2269//7856
f 2347//7855 2272//7855 2346//7855
f 2308//7832 2338//7832 2340//7832
f 2313//7831 2338//7831 2308//7831
f 2309//7834 2340//7834 2347//7834
f 2308//7833 2340//7833 2309//7833
f 2313//7830 2339//7830 2338//7830
f 2348//7827 2341//7827 2315//7827
f 2344//7826 2345//7826 2349//7826
f 2315//7829 2339//7829 2313//7829
f 2315//7828 2341//7828 2339//7828
f 2322//7841 2344//7841 2317//7841
f 2322//7840 2343//7840 2344//7840
f 2317//7843 2349//7843 2318//7843
f 2317//7842 2344//7842 2349//7842
f 2326//7839 2343//7839 2322//7839
f 2312//7836 2347//7836 2346//7836
f 2309//7835 2347//7835 2312//7835
f 2326//7838 2346//7838 2343//7838
f 2312//7837 2346//7837 2326//7837
f 2350//8030 2351//8030 2352//8030
f 2350//8029 2353//8029 2351//8029
f 2352//8032 2354//8032 2355//8032
f 2352//8031 2351//8031 2354//8031
f 2356//8028 2353//8028 2350//8028
f 2357//8025 2358//8025 2359//8025
f 2360//8024 2358//8024 2357//8024
f 2356//8027 2359//8027 2353//8027
f 2357//8026 2359//8026 2356//8026
f 1557//8039 2291//8039 2290//8039
f 2361//8038 2362//8038 2363//8038
f 2290//8041 2364//8041 2365//8041
f 2290//8040 2291//8040 2364//8040
f 2361//8037 2366//8037 2362//8037
f 2355//8034 2367//8034 2368//8034
f 2355//8033 2354//8033 2367//8033
f 2368//8036 2366//8036 2361//8036
f 2368//8035 2367//8035 2366//8035
f 2279//8012 2355//8012 2280//8012
f 2369//8011 1576//8011 1564//8011
f 2280//8014 2368//8014 2277//8014
f 2280//8013 2355//8013 2368//8013
f 2369//8010 2276//8010 1576//8010
f 2370//8007 2371//8007 2372//8007
f 2370//8006 2373//8006 2371//8006
f 2372//8009 2276//8009 2369//8009
f 2372//8008 2371//8008 2276//8008
f 2374//8021 2290//8021 2365//8021
f 1552//8020 2290//8020 2374//8020
f 2360//8023 2365//8023 2358//8023
f 2374//8022 2365//8022 2360//8022
f 2274//8019 2363//8019 2278//8019
f 2275//8016 2368//8016 2361//8016
f 2277//8015 2368//8015 2275//8015
f 2274//8018 2361//8018 2363//8018
f 2275//8017 2361//8017 2274//8017
f 2375//8066 2376//8066 2377//8066
f 2375//8065 2378//8065 2376//8065
f 2377//8068 2379//8068 2380//8068
f 2377//8067 2376//8067 2379//8067
f 2381//8064 2378//8064 2375//8064
f 2364//8061 2371//8061 2373//8061
f 2291//8060 2371//8060 2364//8060
f 2381//8063 2373//8063 2378//8063
f 2364//8062 2373//8062 2381//8062
f 2382//8075 2282//8075 2292//8075
f 2383//8074 2282//8074 2382//8074
f 2157//8077 2384//8077 2142//8077
f 2382//8076 2292//8076 2385//8076
f 2383//8073 2283//8073 2282//8073
f 2380//8070 2386//8070 2387//8070
f 2380//8069 2379//8069 2386//8069
f 2387//8072 2283//8072 2383//8072
f 2387//8071 2386//8071 2283//8071
f 2353//8048 2377//8048 2380//8048
f 2359//8047 2377//8047 2353//8047
f 2351//8050 2380//8050 2387//8050
f 2353//8049 2380//8049 2351//8049
f 2359//8046 2375//8046 2377//8046
f 2365//8043 2381//8043 2358//8043
f 2365//8042 2364//8042 2381//8042
f 2358//8045 2375//8045 2359//8045
f 2358//8044 2381//8044 2375//8044
f 2366//8057 2385//8057 2362//8057
f 2366//8056 2382//8056 2385//8056
f 2291//8059 2276//8059 2371//8059
f 1574//8058 2276//8058 2291//8058
f 2367//8055 2382//8055 2366//8055
f 2354//8052 2387//8052 2383//8052
f 2351//8051 2387//8051 2354//8051
f 2367//8054 2383//8054 2382//8054
f 2354//8053 2383//8053 2367//8053
f 2388//7958 2287//7958 2389//7958
f 2285//7957 2287//7957 2388//7957
f 2390//7960 2389//7960 2391//7960
f 2388//7959 2389//7959 2390//7959
f 2297//7956 2357//7956 2296//7956
f 2301//7953 2360//7953 2300//7953
f 2301//7952 2374//7952 2360//7952
f 2297//7955 2360//7955 2357//7955
f 2300//7954 2360//7954 2297//7954
f 2392//7967 1566//7967 1570//7967
f 2392//7966 2393//7966 1566//7966
f 2298//7969 2357//7969 2356//7969
f 2296//7968 2357//7968 2298//7968
f 2394//7965 2393//7965 2392//7965
f 2395//7962 2391//7962 2396//7962
f 2390//7961 2391//7961 2395//7961
f 2394//7964 2396//7964 2393//7964
f 2395//7963 2396//7963 2394//7963
f 2397//7940 2398//7940 2399//7940
f 1528//7939 2398//7939 2397//7939
f 1552//7942 2374//7942 722//7942
f 2397//7941 2399//7941 2400//7941
f 1528//7938 2401//7938 2398//7938
f 2395//7935 2402//7935 2390//7935
f 2287//7934 2286//7934 2403//7934
f 1555//7937 2401//7937 1528//7937
f 2397//7936 2400//7936 2404//7936
f 2397//7949 2392//7949 1570//7949
f 2404//7948 2392//7948 2397//7948
f 2258//7951 2374//7951 2301//7951
f 2397//7950 1570//7950 1528//7950
f 2404//7947 2394//7947 2392//7947
f 2402//7944 2395//7944 2405//7944
f 722//7943 2374//7943 2258//7943
f 2405//7946 2394//7946 2404//7946
f 2405//7945 2395//7945 2394//7945
f 2304//7994 2355//7994 2279//7994
f 2304//7993 2352//7993 2355//7993
f 2293//7996 2283//7996 2386//7996
f 2288//7995 2283//7995 2293//7995
f 2302//7992 2352//7992 2304//7992
f 2305//7989 2350//7989 2303//7989
f 2393//7988 1564//7988 1566//7988
f 2303//7991 2352//7991 2302//7991
f 2303//7990 2350//7990 2352//7990
f 2406//8003 2378//8003 2407//8003
f 2406//8002 2376//8002 2378//8002
f 2407//8005 2373//8005 2370//8005
f 2407//8004 2378//8004 2373//8004
f 2408//8001 2376//8001 2406//8001
f 2294//7998 2386//7998 2379//7998
f 2293//7997 2386//7997 2294//7997
f 2408//8000 2379//8000 2376//8000
f 2294//7999 2379//7999 2408//7999
f 2409//7976 2408//7976 2403//7976
f 2409//7975 2294//7975 2408//7975
f 2403//7978 2406//7978 2287//7978
f 2403//7977 2408//7977 2406//7977
f 2295//7974 2294//7974 2409//7974
f 2299//7971 2356//7971 2306//7971
f 2298//7970 2356//7970 2299//7970
f 2306//7973 2350//7973 2305//7973
f 2306//7972 2356//7972 2350//7972
f 2396//7985 2372//7985 2369//7985
f 2391//7984 2372//7984 2396//7984
f 2393//7987 2369//7987 1564//7987
f 2396//7986 2369//7986 2393//7986
f 2391//7983 2370//7983 2372//7983
f 2287//7980 2407//7980 2389//7980
f 2287//7979 2406//7979 2407//7979
f 2389//7982 2370//7982 2391//7982
f 2389//7981 2407//7981 2370//7981
f 2410//8752 2411//8752 2412//8752
f 2413//8751 2411//8751 2410//8751
f 2414//8754 2412//8754 2415//8754
f 2410//8753 2412//8753 2414//8753
f 2413//8750 2416//8750 2411//8750
f 2417//8747 2418//8747 2419//8747
f 2417//8746 2420//8746 2418//8746
f 2419//8749 2416//8749 2413//8749
f 2419//8748 2418//8748 2416//8748
f 2421//8761 2422//8761 2423//8761
f 2421//8760 2424//8760 2422//8760
f 2423//8763 2425//8763 2420//8763
f 2423//8762 2422//8762 2425//8762
f 2426//8759 2424//8759 2421//8759
f 2427//8756 2428//8756 2429//8756
f 2430//8755 2428//8755 2427//8755
f 2426//8758 2429//8758 2424//8758
f 2427//8757 2429//8757 2426//8757
f 2431//8734 2432//8734 2433//8734
f 2434//8733 2432//8733 2431//8733
f 2435//8736 2430//8736 2427//8736
f 2436//8735 2430//8735 2435//8735
f 2434//8732 2435//8732 2432//8732
f 2437//8729 2436//8729 2438//8729
f 2439//8728 2433//8728 2432//8728
f 2438//8731 2435//8731 2434//8731
f 2438//8730 2436//8730 2435//8730
f 2440//8743 2423//8743 2441//8743
f 2440//8742 2421//8742 2423//8742
f 2441//8745 2420//8745 2417//8745
f 2441//8744 2423//8744 2420//8744
f 2439//8741 2421//8741 2440//8741
f 2432//8738 2427//8738 2426//8738
f 2435//8737 2427//8737 2432//8737
f 2439//8740 2426//8740 2421//8740
f 2432//8739 2426//8739 2439//8739
f 2442//8788 2443//8788 2444//8788
f 2445//8787 2443//8787 2442//8787
f 2446//8790 2444//8790 2447//8790
f 2442//8789 2444//8789 2446//8789
f 2445//8786 2448//8786 2443//8786
f 2425//8783 2449//8783 2450//8783
f 2425//8782 2451//8782 2449//8782
f 2450//8785 2448//8785 2445//8785
f 2450//8784 2449//8784 2448//8784
f 2452//8797 2453//8797 2454//8797
f 2452//8796 2455//8796 2453//8796
f 2454//8799 2456//8799 2451//8799
f 2454//8798 2453//8798 2456//8798
f 2457//8795 2455//8795 2452//8795
f 2458//8792 2447//8792 2459//8792
f 2446//8791 2447//8791 2458//8791
f 2460//8794 2459//8794 2461//8794
f 2458//8793 2459//8793 2460//8793
f 2411//8770 2442//8770 2446//8770
f 2416//8769 2442//8769 2411//8769
f 2412//8772 2446//8772 2458//8772
f 2411//8771 2446//8771 2412//8771
f 2416//8768 2445//8768 2442//8768
f 2420//8765 2450//8765 2418//8765
f 2420//8764 2425//8764 2450//8764
f 2418//8767 2445//8767 2416//8767
f 2418//8766 2450//8766 2445//8766
f 2424//8779 2454//8779 2422//8779
f 2424//8778 2452//8778 2454//8778
f 2422//8781 2451//8781 2425//8781
f 2422//8780 2454//8780 2451//8780
f 2429//8777 2452//8777 2424//8777
f 2415//8774 2458//8774 2460//8774
f 2412//8773 2458//8773 2415//8773
f 2429//8776 2457//8776 2452//8776
f 2428//8775 2457//8775 2429//8775
f 2462//8680 2463//8680 2464//8680
f 2465//8679 2463//8679 2462//8679
f 2466//8682 2464//8682 2467//8682
f 2462//8681 2464//8681 2466//8681
f 2465//8678 2468//8678 2463//8678
f 2469//8675 2470//8675 2471//8675
f 2469//8674 2472//8674 2470//8674
f 2471//8677 2468//8677 2465//8677
f 2471//8676 2470//8676 2468//8676
f 2473//8689 2474//8689 2475//8689
f 2473//8688 2476//8688 2474//8688
f 2475//8691 2477//8691 2472//8691
f 2475//8690 2474//8690 2477//8690
f 2478//8687 2476//8687 2473//8687
f 2479//8684 2467//8684 2480//8684
f 2466//8683 2467//8683 2479//8683
f 2481//8686 2480//8686 2482//8686
f 2479//8685 2480//8685 2481//8685
f 2483//8662 2462//8662 2466//8662
f 2484//8661 2462//8661 2483//8661
f 2485//8664 2466//8664 2479//8664
f 2483//8663 2466//8663 2485//8663
f 2484//8660 2465//8660 2462//8660
f 2486//8657 2471//8657 2487//8657
f 2486//8656 2469//8656 2471//8656
f 2487//8659 2465//8659 2484//8659
f 2487//8658 2471//8658 2465//8658
f 2488//8671 2475//8671 2489//8671
f 2488//8670 2473//8670 2475//8670
f 2489//8673 2472//8673 2469//8673
f 2489//8672 2475//8672 2472//8672
f 2490//8669 2473//8669 2488//8669
f 2491//8666 2479//8666 2481//8666
f 2485//8665 2479//8665 2491//8665
f 2490//8668 2478//8668 2473//8668
f 2492//8667 2478//8667 2490//8667
f 2419//8716 2493//8716 2417//8716
f 2419//8715 2494//8715 2493//8715
f 2417//8718 2495//8718 2496//8718
f 2493//8717 2495//8717 2417//8717
f 2497//8714 2494//8714 2419//8714
f 2498//8711 2499//8711 2413//8711
f 2410//8710 2498//8710 2413//8710
f 2413//8713 2497//8713 2419//8713
f 2413//8712 2499//8712 2497//8712
f 2440//8725 2500//8725 2439//8725
f 2440//8724 2501//8724 2500//8724
f 2439//8727 2502//8727 2433//8727
f 2500//8726 2502//8726 2439//8726
f 2503//8723 2501//8723 2440//8723
f 2496//8720 2504//8720 2441//8720
f 2417//8719 2496//8719 2441//8719
f 2441//8722 2503//8722 2440//8722
f 2441//8721 2504//8721 2503//8721
f 2463//8698 2505//8698 2506//8698
f 2468//8697 2505//8697 2463//8697
f 2464//8700 2506//8700 2507//8700
f 2463//8699 2506//8699 2464//8699
f 2468//8696 2508//8696 2505//8696
f 2472//8693 2509//8693 2470//8693
f 2472//8692 2477//8692 2509//8692
f 2470//8695 2508//8695 2468//8695
f 2470//8694 2509//8694 2508//8694
f 2410//8707 2414//8707 2510//8707
f 2482//8706 2511//8706 2512//8706
f 2410//8709 2513//8709 2498//8709
f 2510//8708 2513//8708 2410//8708
f 2480//8705 2511//8705 2482//8705
f 2467//8702 2507//8702 2514//8702
f 2464//8701 2507//8701 2467//8701
f 2480//8704 2514//8704 2511//8704
f 2467//8703 2514//8703 2480//8703
f 2461//8896 2515//8896 2516//8896
f 2517//8895 2515//8895 2461//8895
f 2460//8898 2516//8898 2518//8898
f 2461//8897 2516//8897 2460//8897
f 2519//8894 2520//8894 2521//8894
f 2522//8891 2523//8891 2524//8891
f 2522//8890 2525//8890 2523//8890
f 2524//8893 2520//8893 2519//8893
f 2524//8892 2523//8892 2520//8892
f 2517//8905 2526//8905 2527//8905
f 2528//8904 2526//8904 2517//8904
f 2529//8907 2530//8907 2531//8907
f 2517//8906 2527//8906 2515//8906
f 2414//8903 2532//8903 2510//8903
f 2415//8900 2518//8900 2533//8900
f 2460//8899 2518//8899 2415//8899
f 2414//8902 2533//8902 2532//8902
f 2415//8901 2533//8901 2414//8901
f 2534//8878 2535//8878 2536//8878
f 2537//8877 2535//8877 2534//8877
f 2538//8880 2536//8880 2539//8880
f 2534//8879 2536//8879 2538//8879
f 2537//8876 2512//8876 2535//8876
f 2540//8873 2524//8873 2529//8873
f 2540//8872 2522//8872 2524//8872
f 2482//8875 2512//8875 2537//8875
f 2529//8874 2524//8874 2519//8874
f 2541//8887 2542//8887 2543//8887
f 2541//8886 2544//8886 2542//8886
f 2543//8889 2525//8889 2522//8889
f 2543//8888 2542//8888 2525//8888
f 2545//8885 2544//8885 2541//8885
f 2546//8882 2539//8882 2547//8882
f 2538//8881 2539//8881 2546//8881
f 2545//8884 2547//8884 2544//8884
f 2546//8883 2547//8883 2545//8883
f 2548//8939 1369//8939 2549//8939
f 2548//8938 1333//8938 1369//8938
f 1368//8941 2550//8941 2551//8941
f 2549//8940 1369//8940 1370//8940
f 2552//8937 1333//8937 2548//8937
f 1343//8931 1216//8931 1341//8931
f 1373//8929 1237//8929 1343//8929
f 2552//8936 1332//8936 1333//8936
f 2553//8935 1332//8935 2552//8935
f 1332//8948 2554//8948 1342//8948
f 1332//8947 2553//8947 2554//8947
f 1342//8950 2555//8950 2556//8950
f 1342//8949 2554//8949 2555//8949
f 2556//8946 1341//8946 1342//8946
f 2557//8943 1370//8943 1368//8943
f 2549//8942 1370//8942 2557//8942
f 2558//8945 1368//8945 2551//8945
f 2557//8944 1368//8944 2558//8944
f 2529//8914 2559//8914 2560//8914
f 2519//8913 2559//8913 2529//8913
f 2521//8916 2561//8916 2562//8916
f 2529//8915 2560//8915 2530//8915
f 2528//8912 2563//8912 2526//8912
f 2529//8909 2564//8909 2565//8909
f 2531//8908 2564//8908 2529//8908
f 2528//8911 2565//8911 2563//8911
f 2529//8910 2565//8910 2528//8910
f 2519//8923 2566//8923 2567//8923
f 2521//8922 2566//8922 2519//8922
f 2550//8927 1368//8927 1373//8927
f 2519//8924 2567//8924 2568//8924
f 2521//8921 2569//8921 2566//8921
f 2519//8918 2570//8918 2571//8918
f 2568//8917 2570//8917 2519//8917
f 2562//8920 2569//8920 2521//8920
f 2519//8919 2571//8919 2559//8919
f 2572//8824 2573//8824 2574//8824
f 2575//8823 2573//8823 2572//8823
f 2576//8826 2574//8826 2577//8826
f 2572//8825 2574//8825 2576//8825
f 2575//8822 2578//8822 2573//8822
f 2456//8819 2579//8819 2580//8819
f 2456//8818 2581//8818 2579//8818
f 2580//8821 2578//8821 2575//8821
f 2580//8820 2579//8820 2578//8820
f 2582//8833 2583//8833 2517//8833
f 2582//8832 2584//8832 2583//8832
f 2491//8835 2481//8835 2581//8835
f 2517//8834 2583//8834 2528//8834
f 2585//8831 2584//8831 2582//8831
f 2586//8828 2577//8828 2587//8828
f 2576//8827 2577//8827 2586//8827
f 2585//8830 2587//8830 2584//8830
f 2586//8829 2587//8829 2585//8829
f 2443//8806 2572//8806 2576//8806
f 2448//8805 2572//8805 2443//8805
f 2444//8808 2576//8808 2586//8808
f 2443//8807 2576//8807 2444//8807
f 2448//8804 2575//8804 2572//8804
f 2451//8801 2580//8801 2449//8801
f 2451//8800 2456//8800 2580//8800
f 2449//8803 2575//8803 2448//8803
f 2449//8802 2580//8802 2575//8802
f 2455//8815 2491//8815 2453//8815
f 2461//8814 2582//8814 2517//8814
f 2453//8817 2581//8817 2456//8817
f 2453//8816 2491//8816 2581//8816
f 2459//8813 2582//8813 2461//8813
f 2447//8810 2586//8810 2585//8810
f 2444//8809 2586//8809 2447//8809
f 2459//8812 2585//8812 2582//8812
f 2447//8811 2585//8811 2459//8811
f 2588//8860 2534//8860 2538//8860
f 2589//8859 2534//8859 2588//8859
f 2590//8862 2538//8862 2546//8862
f 2588//8861 2538//8861 2590//8861
f 2589//8858 2537//8858 2534//8858
f 2481//8855 2482//8855 2591//8855
f 2528//8854 2540//8854 2529//8854
f 2591//8857 2537//8857 2589//8857
f 2591//8856 2482//8856 2537//8856
f 2592//8869 2543//8869 2593//8869
f 2592//8868 2541//8868 2543//8868
f 2593//8871 2522//8871 2540//8871
f 2593//8870 2543//8870 2522//8870
f 2594//8867 2541//8867 2592//8867
f 2595//8864 2546//8864 2545//8864
f 2590//8863 2546//8863 2595//8863
f 2594//8866 2545//8866 2541//8866
f 2595//8865 2545//8865 2594//8865
f 2573//8842 2588//8842 2590//8842
f 2578//8841 2588//8841 2573//8841
f 2574//8844 2590//8844 2595//8844
f 2573//8843 2590//8843 2574//8843
f 2578//8840 2589//8840 2588//8840
f 2581//8837 2591//8837 2579//8837
f 2581//8836 2481//8836 2591//8836
f 2579//8839 2589//8839 2578//8839
f 2579//8838 2591//8838 2589//8838
f 2584//8851 2593//8851 2583//8851
f 2584//8850 2592//8850 2593//8850
f 2583//8853 2540//8853 2528//8853
f 2583//8852 2593//8852 2540//8852
f 2587//8849 2592//8849 2584//8849
f 2577//8846 2595//8846 2594//8846
f 2574//8845 2595//8845 2577//8845
f 2587//8848 2594//8848 2592//8848
f 2577//8847 2594//8847 2587//8847
f 2596//8655 2469//8655 2486//8655
f 2597//8463 2598//8463 2599//8463
f 2600//8462 2598//8462 2597//8462
f 2601//8465 2599//8465 2602//8465
f 2597//8464 2599//8464 2601//8464
f 2600//8461 2603//8461 2598//8461
f 2604//8458 2605//8458 2606//8458
f 2604//8457 2607//8457 2605//8457
f 2606//8460 2603//8460 2600//8460
f 2606//8459 2605//8459 2603//8459
f 2233//8472 2608//8472 2234//8472
f 2233//8471 2609//8471 2608//8471
f 2234//8474 2610//8474 2607//8474
f 2234//8473 2608//8473 2610//8473
f 2611//8470 2612//8470 2613//8470
f 2614//8467 2602//8467 2615//8467
f 2601//8466 2602//8466 2614//8466
f 2611//8469 2615//8469 2612//8469
f 2614//8468 2615//8468 2611//8468
f 2616//8445 2597//8445 2617//8445
f 2616//8444 2600//8444 2597//8444
f 2617//8447 2601//8447 2618//8447
f 2617//8446 2597//8446 2601//8446
f 2619//8443 2600//8443 2616//8443
f 2620//8440 2604//8440 2606//8440
f 2237//8439 2604//8439 2620//8439
f 2619//8442 2606//8442 2600//8442
f 2620//8441 2606//8441 2619//8441
f 2232//8454 2234//8454 2238//8454
f 2621//8453 2622//8453 2623//8453
f 2238//8456 2607//8456 2604//8456
f 2238//8455 2234//8455 2607//8455
f 2621//8452 2611//8452 2622//8452
f 2618//8449 2614//8449 2624//8449
f 2618//8448 2601//8448 2614//8448
f 2624//8451 2611//8451 2621//8451
f 2624//8450 2614//8450 2611//8450
f 2625//8499 2626//8499 2627//8499
f 2628//8498 2626//8498 2625//8498
f 2629//8501 2627//8501 2630//8501
f 2625//8500 2627//8500 2629//8500
f 2628//8497 2631//8497 2626//8497
f 2632//8494 2633//8494 2634//8494
f 2632//8493 2635//8493 2633//8493
f 2634//8496 2631//8496 2628//8496
f 2634//8495 2633//8495 2631//8495
f 2636//8508 2478//8508 2637//8508
f 2476//8507 2638//8507 2639//8507
f 2637//8510 2492//8510 2640//8510
f 2637//8509 2478//8509 2492//8509
f 2476//8506 2641//8506 2638//8506
f 2642//8503 2643//8503 2478//8503
f 2629//8502 2630//8502 2644//8502
f 2478//8505 2641//8505 2476//8505
f 2478//8504 2643//8504 2641//8504
f 2598//8481 2645//8481 2646//8481
f 2603//8480 2645//8480 2598//8480
f 2599//8483 2646//8483 2647//8483
f 2598//8482 2646//8482 2599//8482
f 2603//8479 2648//8479 2645//8479
f 2607//8476 2649//8476 2605//8476
f 2607//8475 2610//8475 2649//8475
f 2605//8478 2648//8478 2603//8478
f 2605//8477 2649//8477 2648//8477
f 2650//8490 2651//8490 2652//8490
f 2639//8489 2653//8489 2476//8489
f 2652//8492 2635//8492 2632//8492
f 2650//8491 2652//8491 2632//8491
f 2615//8488 2654//8488 2612//8488
f 2602//8485 2647//8485 2655//8485
f 2599//8484 2647//8484 2602//8484
f 2615//8487 2655//8487 2654//8487
f 2602//8486 2655//8486 2615//8486
f 2656//8391 2657//8391 2658//8391
f 2656//8390 2659//8390 2657//8390
f 2658//8393 2660//8393 2661//8393
f 2658//8392 2657//8392 2660//8392
f 2662//8389 2659//8389 2656//8389
f 2207//8386 2663//8386 2664//8386
f 2206//8385 2663//8385 2207//8385
f 2662//8388 2664//8388 2659//8388
f 2207//8387 2664//8387 2662//8387
f 2665//8400 2666//8400 2667//8400
f 2201//8399 2668//8399 2202//8399
f 2242//8402 2243//8402 2669//8402
f 2241//8401 2243//8401 2242//8401
f 2201//8398 2670//8398 2668//8398
f 2661//8395 2671//8395 2672//8395
f 2661//8394 2660//8394 2671//8394
f 2672//8397 2670//8397 2201//8397
f 2672//8396 2671//8396 2670//8396
f 2195//8373 2658//8373 2193//8373
f 2195//8372 2656//8372 2658//8372
f 2193//8375 2661//8375 2196//8375
f 2193//8374 2658//8374 2661//8374
f 2200//8371 2656//8371 2195//8371
f 2199//8368 2207//8368 2662//8368
f 2205//8367 2207//8367 2199//8367
f 2200//8370 2662//8370 2656//8370
f 2199//8369 2662//8369 2200//8369
f 2670//8382 2673//8382 2668//8382
f 2209//8381 2203//8381 2212//8381
f 2206//8384 2242//8384 2663//8384
f 2240//8383 2242//8383 2206//8383
f 2209//8380 2201//8380 2203//8380
f 2196//8377 2672//8377 2210//8377
f 2196//8376 2661//8376 2672//8376
f 2210//8379 2201//8379 2209//8379
f 2210//8378 2672//8378 2201//8378
f 2674//8427 2617//8427 2675//8427
f 2674//8426 2616//8426 2617//8426
f 2675//8429 2618//8429 2676//8429
f 2675//8428 2617//8428 2618//8428
f 2677//8425 2616//8425 2674//8425
f 2669//8422 2620//8422 2619//8422
f 2243//8421 2620//8421 2669//8421
f 2677//8424 2619//8424 2616//8424
f 2669//8423 2619//8423 2677//8423
f 2611//8436 2613//8436 2622//8436
f 2665//8435 2678//8435 2666//8435
f 2237//8438 2238//8438 2604//8438
f 2236//8437 2238//8437 2237//8437
f 2665//8434 2621//8434 2678//8434
f 2676//8431 2624//8431 2679//8431
f 2676//8430 2618//8430 2624//8430
f 2679//8433 2621//8433 2665//8433
f 2679//8432 2624//8432 2621//8432
f 2659//8409 2675//8409 2657//8409
f 2659//8408 2674//8408 2675//8408
f 2657//8411 2676//8411 2660//8411
f 2657//8410 2675//8410 2676//8410
f 2664//8407 2674//8407 2659//8407
f 2663//8404 2669//8404 2677//8404
f 2242//8403 2669//8403 2663//8403
f 2664//8406 2677//8406 2674//8406
f 2663//8405 2677//8405 2664//8405
f 2621//8418 2623//8418 2678//8418
f 2670//8417 2667//8417 2673//8417
f 2243//8420 2237//8420 2620//8420
f 2235//8419 2237//8419 2243//8419
f 2670//8416 2665//8416 2667//8416
f 2660//8413 2679//8413 2671//8413
f 2660//8412 2676//8412 2679//8412
f 2671//8415 2665//8415 2670//8415
f 2671//8414 2679//8414 2665//8414
f 2633//8607 2635//8607 2680//8607
f 2428//8606 2681//8606 2457//8606
f 2680//8609 2682//8609 2683//8609
f 2680//8608 2635//8608 2682//8608
f 2684//8605 2681//8605 2428//8605
f 2685//8602 2686//8602 2687//8602
f 2688//8601 2686//8601 2685//8601
f 2684//8604 2687//8604 2681//8604
f 2685//8603 2687//8603 2684//8603
f 2689//8616 2690//8616 2691//8616
f 2692//8615 2690//8615 2689//8615
f 2693//8618 2691//8618 2694//8618
f 2689//8617 2691//8617 2693//8617
f 2692//8614 2695//8614 2690//8614
f 2683//8611 2696//8611 2697//8611
f 2683//8610 2682//8610 2696//8610
f 2697//8613 2695//8613 2692//8613
f 2697//8612 2696//8612 2695//8612
f 2698//8589 2680//8589 2699//8589
f 2698//8588 2633//8588 2680//8588
f 2699//8591 2683//8591 2700//8591
f 2699//8590 2680//8590 2683//8590
f 2631//8587 2633//8587 2698//8587
f 2701//8584 2685//8584 2684//8584
f 2702//8583 2685//8583 2701//8583
f 2430//8586 2684//8586 2428//8586
f 2701//8585 2684//8585 2430//8585
f 2703//8598 2689//8598 2693//8598
f 2704//8597 2689//8597 2703//8597
f 2688//8600 2693//8600 2686//8600
f 2703//8599 2693//8599 2688//8599
f 2704//8596 2692//8596 2689//8596
f 2700//8593 2697//8593 2705//8593
f 2700//8592 2683//8592 2697//8592
f 2705//8595 2692//8595 2704//8595
f 2705//8594 2697//8594 2692//8594
f 2706//8643 2483//8643 2707//8643
f 2706//8642 2484//8642 2483//8642
f 2707//8645 2485//8645 2455//8645
f 2707//8644 2483//8644 2485//8644
f 2708//8641 2484//8641 2706//8641
f 2694//8638 2486//8638 2487//8638
f 2691//8637 2486//8637 2694//8637
f 2708//8640 2487//8640 2484//8640
f 2694//8639 2487//8639 2708//8639
f 2709//8652 2488//8652 2489//8652
f 2710//8651 2488//8651 2709//8651
f 2596//8654 2489//8654 2469//8654
f 2709//8653 2489//8653 2596//8653
f 2710//8650 2490//8650 2488//8650
f 2652//8647 2492//8647 2711//8647
f 2455//8646 2485//8646 2491//8646
f 2711//8649 2490//8649 2710//8649
f 2711//8648 2492//8648 2490//8648
f 2681//8625 2707//8625 2457//8625
f 2681//8624 2706//8624 2707//8624
f 2635//8627 2652//8627 2682//8627
f 2457//8626 2707//8626 2455//8626
f 2687//8623 2706//8623 2681//8623
f 2686//8620 2694//8620 2708//8620
f 2693//8619 2694//8619 2686//8619
f 2687//8622 2708//8622 2706//8622
f 2686//8621 2708//8621 2687//8621
f 2690//8634 2709//8634 2596//8634
f 2695//8633 2709//8633 2690//8633
f 2691//8636 2596//8636 2486//8636
f 2690//8635 2596//8635 2691//8635
f 2695//8632 2710//8632 2709//8632
f 2682//8629 2711//8629 2696//8629
f 2682//8628 2652//8628 2711//8628
f 2696//8631 2710//8631 2695//8631
f 2696//8630 2711//8630 2710//8630
f 2712//8535 2713//8535 2714//8535
f 2715//8534 2712//8534 2714//8534
f 2714//8537 2716//8537 2717//8537
f 2714//8536 2713//8536 2716//8536
f 2715//8533 2718//8533 2712//8533
f 2719//8530 2720//8530 2721//8530
f 2722//8529 2720//8529 2719//8529
f 2721//8532 2718//8532 2715//8532
f 2719//8531 2721//8531 2715//8531
f 2723//8544 2724//8544 2725//8544
f 2726//8543 2724//8543 2723//8543
f 2727//8546 2725//8546 2728//8546
f 2723//8545 2725//8545 2727//8545
f 2726//8542 2627//8542 2724//8542
f 2717//8539 2729//8539 2728//8539
f 2716//8538 2729//8538 2717//8538
f 2630//8541 2627//8541 2726//8541
f 2717//8540 2728//8540 2725//8540
f 2492//8517 2730//8517 2731//8517
f 2652//8516 2730//8516 2492//8516
f 2732//8519 2436//8519 2437//8519
f 2492//8518 2731//8518 2640//8518
f 2652//8515 2733//8515 2730//8515
f 2478//8512 2734//8512 2735//8512
f 2636//8511 2734//8511 2478//8511
f 2651//8514 2733//8514 2652//8514
f 2478//8513 2735//8513 2642//8513
f 2736//8526 2737//8526 2738//8526
f 2739//8525 2736//8525 2738//8525
f 2738//8528 2722//8528 2719//8528
f 2738//8527 2737//8527 2722//8527
f 2739//8524 2740//8524 2736//8524
f 2732//8521 2741//8521 2742//8521
f 2437//8520 2741//8520 2732//8520
f 2742//8523 2740//8523 2739//8523
f 2732//8522 2742//8522 2739//8522
f 2743//8571 2699//8571 2744//8571
f 2743//8570 2698//8570 2699//8570
f 2744//8573 2700//8573 2745//8573
f 2744//8572 2699//8572 2700//8572
f 2746//8569 2698//8569 2743//8569
f 2436//8566 2701//8566 2430//8566
f 2732//8565 2701//8565 2436//8565
f 2746//8568 2631//8568 2698//8568
f 2626//8567 2631//8567 2746//8567
f 2747//8580 2703//8580 2688//8580
f 2748//8579 2703//8579 2747//8579
f 2702//8582 2688//8582 2685//8582
f 2747//8581 2688//8581 2702//8581
f 2748//8578 2704//8578 2703//8578
f 2745//8575 2705//8575 2749//8575
f 2745//8574 2700//8574 2705//8574
f 2749//8577 2704//8577 2748//8577
f 2749//8576 2705//8576 2704//8576
f 2717//8553 2744//8553 2714//8553
f 2717//8552 2743//8552 2744//8552
f 2714//8555 2745//8555 2715//8555
f 2714//8554 2744//8554 2745//8554
f 2725//8551 2743//8551 2717//8551
f 2724//8548 2626//8548 2746//8548
f 2627//8547 2626//8547 2724//8547
f 2725//8550 2746//8550 2743//8550
f 2724//8549 2746//8549 2725//8549
f 2739//8562 2747//8562 2702//8562
f 2738//8561 2747//8561 2739//8561
f 2732//8564 2702//8564 2701//8564
f 2739//8563 2702//8563 2732//8563
f 2738//8560 2748//8560 2747//8560
f 2715//8557 2749//8557 2719//8557
f 2715//8556 2745//8556 2749//8556
f 2719//8559 2748//8559 2738//8559
f 2719//8558 2749//8558 2748//8558
f 2750//7021 2751//7021 2752//7021
f 2750//7020 2753//7020 2751//7020
f 2752//7023 2754//7023 2755//7023
f 2752//7022 2751//7022 2754//7022
f 2756//7019 2753//7019 2750//7019
f 2757//7016 2758//7016 2759//7016
f 2760//7015 2758//7015 2757//7015
f 2761//7018 2759//7018 2762//7018
f 2757//7017 2759//7017 2761//7017
f 2763//7030 2764//7030 2765//7030
f 2766//7029 2764//7029 2763//7029
f 2767//7032 2765//7032 2768//7032
f 2763//7031 2765//7031 2767//7031
f 2766//7028 2769//7028 2764//7028
f 2755//7025 2770//7025 2771//7025
f 2755//7024 2754//7024 2770//7024
f 2771//7027 2769//7027 2766//7027
f 2771//7026 2770//7026 2769//7026
f 2772//7003 2752//7003 2773//7003
f 2772//7002 2750//7002 2752//7002
f 2773//7005 2755//7005 2774//7005
f 2773//7004 2752//7004 2755//7004
f 2775//7001 2750//7001 2772//7001
f 2776//6998 2757//6998 2761//6998
f 2777//6997 2757//6997 2776//6997
f 2775//7000 2756//7000 2750//7000
f 2778//6999 2756//6999 2775//6999
f 2779//7012 2763//7012 2767//7012
f 2780//7011 2763//7011 2779//7011
f 2760//7014 2767//7014 2758//7014
f 2779//7013 2767//7013 2760//7013
f 2780//7010 2766//7010 2763//7010
f 2774//7007 2771//7007 2781//7007
f 2774//7006 2755//7006 2771//7006
f 2781//7009 2766//7009 2780//7009
f 2781//7008 2771//7008 2766//7008
f 2782//7057 2783//7057 2784//7057
f 2782//7056 2785//7056 2783//7056
f 2786//7059 2787//7059 2788//7059
f 2784//7058 2783//7058 2789//7058
f 2790//7055 2785//7055 2782//7055
f 2768//7052 2791//7052 2792//7052
f 2765//7051 2791//7051 2768//7051
f 2790//7054 2792//7054 2785//7054
f 2768//7053 2792//7053 2790//7053
f 2793//7066 2794//7066 2795//7066
f 2796//7065 2794//7065 2793//7065
f 2797//7068 2795//7068 2798//7068
f 2793//7067 2795//7067 2797//7067
f 2796//7064 2799//7064 2794//7064
f 2788//7061 2800//7061 2801//7061
f 2788//7060 2787//7060 2800//7060
f 2801//7063 2799//7063 2796//7063
f 2801//7062 2800//7062 2799//7062
f 2753//7039 2786//7039 2751//7039
f 2762//7038 2782//7038 2784//7038
f 2751//7041 2788//7041 2754//7041
f 2751//7040 2786//7040 2788//7040
f 2759//7037 2782//7037 2762//7037
f 2758//7034 2768//7034 2790//7034
f 2767//7033 2768//7033 2758//7033
f 2759//7036 2790//7036 2782//7036
f 2758//7035 2790//7035 2759//7035
f 2764//7048 2793//7048 2797//7048
f 2769//7047 2793//7047 2764//7047
f 2765//7050 2797//7050 2791//7050
f 2764//7049 2797//7049 2765//7049
f 2769//7046 2796//7046 2793//7046
f 2754//7043 2801//7043 2770//7043
f 2754//7042 2788//7042 2801//7042
f 2770//7045 2796//7045 2769//7045
f 2770//7044 2801//7044 2796//7044
f 2802//6949 2803//6949 2804//6949
f 2802//6948 2805//6948 2803//6948
f 2804//6951 2806//6951 2807//6951
f 2803//6950 2806//6950 2804//6950
f 2808//6947 2805//6947 2802//6947
f 2809//6944 2810//6944 2811//6944
f 2812//6943 2809//6943 2811//6943
f 2811//6946 2808//6946 2802//6946
f 2811//6945 2810//6945 2808//6945
f 2813//6958 2814//6958 2807//6958
f 2815//6957 2814//6957 2813//6957
f 2816//6960 2817//6960 2818//6960
f 2819//6959 2817//6959 2816//6959
f 2815//6956 2816//6956 2814//6956
f 2820//6953 2819//6953 2821//6953
f 2804//6952 2807//6952 2814//6952
f 2821//6955 2816//6955 2815//6955
f 2821//6954 2819//6954 2816//6954
f 2822//6931 2823//6931 2824//6931
f 2825//6930 2826//6930 2827//6930
f 2822//6933 2828//6933 2829//6933
f 2824//6932 2828//6932 2822//6932
f 2830//6929 2826//6929 2825//6929
f 2831//6926 2832//6926 2833//6926
f 2834//6925 2832//6925 2831//6925
f 2830//6928 2833//6928 2826//6928
f 2831//6927 2833//6927 2830//6927
f 2835//6940 2836//6940 2812//6940
f 2835//6939 2837//6939 2836//6939
f 2812//6942 2838//6942 2809//6942
f 2836//6941 2838//6941 2812//6941
f 2839//6938 2837//6938 2835//6938
f 2829//6935 2840//6935 2841//6935
f 2822//6934 2829//6934 2841//6934
f 2841//6937 2839//6937 2835//6937
f 2841//6936 2840//6936 2839//6936
f 2842//6985 2773//6985 2843//6985
f 2842//6984 2772//6984 2773//6984
f 2843//6987 2774//6987 2844//6987
f 2843//6986 2773//6986 2774//6986
f 2845//6983 2772//6983 2842//6983
f 2818//6980 2778//6980 2775//6980
f 2817//6979 2778//6979 2818//6979
f 2845//6982 2775//6982 2772//6982
f 2818//6981 2775//6981 2845//6981
f 2846//6994 2779//6994 2760//6994
f 2847//6993 2779//6993 2846//6993
f 2777//6996 2760//6996 2757//6996
f 2846//6995 2760//6995 2777//6995
f 2847//6992 2780//6992 2779//6992
f 2844//6989 2781//6989 2848//6989
f 2844//6988 2774//6988 2781//6988
f 2848//6991 2780//6991 2847//6991
f 2848//6990 2781//6990 2780//6990
f 2802//6967 2843//6967 2811//6967
f 2802//6966 2842//6966 2843//6966
f 2811//6969 2844//6969 2812//6969
f 2811//6968 2843//6968 2844//6968
f 2804//6965 2842//6965 2802//6965
f 2814//6962 2818//6962 2845//6962
f 2816//6961 2818//6961 2814//6961
f 2804//6964 2845//6964 2842//6964
f 2814//6963 2845//6963 2804//6963
f 2822//6976 2846//6976 2777//6976
f 2841//6975 2846//6975 2822//6975
f 2823//6978 2777//6978 2776//6978
f 2822//6977 2777//6977 2823//6977
f 2841//6974 2847//6974 2846//6974
f 2812//6971 2848//6971 2835//6971
f 2812//6970 2844//6970 2848//6970
f 2835//6973 2847//6973 2841//6973
f 2835//6972 2848//6972 2847//6972
f 1553//7165 2849//7165 2850//7165
f 1553//7164 2851//7164 2849//7164
f 2850//7167 2852//7167 2853//7167
f 2850//7166 2849//7166 2852//7166
f 1554//7163 2851//7163 1553//7163
f 2854//7160 2855//7160 2856//7160
f 2855//7159 2854//7159 2857//7159
f 2850//7162 2853//7162 2858//7162
f 2859//7161 2860//7161 2861//7161
f 2862//7174 2863//7174 2864//7174
f 2865//7173 2862//7173 2864//7173
f 2864//7176 2866//7176 2867//7176
f 2864//7175 2863//7175 2866//7175
f 2865//7172 2868//7172 2862//7172
f 2869//7169 2870//7169 2871//7169
f 2872//7168 2870//7168 2869//7168
f 2871//7171 2868//7171 2865//7171
f 2869//7170 2871//7170 2865//7170
f 2873//7147 2872//7147 2869//7147
f 2872//7146 2874//7146 2870//7146
f 2875//7149 2876//7149 2877//7149
f 2876//7148 2873//7148 2878//7148
f 2871//7145 2823//7145 2868//7145
f 2874//7142 2824//7142 2870//7142
f 2776//7141 2868//7141 2823//7141
f 2870//7144 2823//7144 2871//7144
f 2870//7143 2824//7143 2823//7143
f 2879//7156 2880//7156 2881//7156
f 2882//7155 2880//7155 2879//7155
f 2881//7158 2883//7158 2884//7158
f 2881//7157 2880//7157 2883//7157
f 2882//7154 2885//7154 2880//7154
f 2886//7151 2887//7151 2888//7151
f 2875//7150 2887//7150 2886//7150
f 2889//7153 2885//7153 2882//7153
f 2889//7152 2890//7152 2885//7152
f 2891//7201 2892//7201 2893//7201
f 2891//7200 2894//7200 2892//7200
f 2893//7203 2895//7203 2896//7203
f 2893//7202 2892//7202 2895//7202
f 2897//7199 2894//7199 2891//7199
f 2898//7196 2867//7196 2899//7196
f 2900//7195 2867//7195 2898//7195
f 2897//7198 2899//7198 2894//7198
f 2898//7197 2899//7197 2897//7197
f 2901//7210 2902//7210 498//7210
f 2901//7209 2903//7209 2902//7209
f 2860//7212 2859//7212 2858//7212
f 2904//7211 1561//7211 1563//7211
f 2895//7208 2903//7208 2901//7208
f 2896//7205 2901//7205 2905//7205
f 2896//7204 2895//7204 2901//7204
f 2895//7207 2906//7207 2903//7207
f 2907//7206 2906//7206 2895//7206
f 2908//7183 2909//7183 2894//7183
f 2899//7182 2908//7182 2894//7182
f 2894//7185 2910//7185 2892//7185
f 2894//7184 2909//7184 2910//7184
f 2899//7181 2911//7181 2908//7181
f 2867//7178 2912//7178 2913//7178
f 2866//7177 2912//7177 2867//7177
f 2913//7180 2911//7180 2899//7180
f 2867//7179 2913//7179 2899//7179
f 2914//7192 2865//7192 2864//7192
f 2878//7191 2865//7191 2914//7191
f 2900//7194 2864//7194 2867//7194
f 2914//7193 2864//7193 2900//7193
f 2878//7190 2869//7190 2865//7190
f 2892//7187 2915//7187 2907//7187
f 2910//7186 2915//7186 2892//7186
f 2873//7189 2869//7189 2878//7189
f 2892//7188 2907//7188 2895//7188
f 2916//7093 2917//7093 2918//7093
f 2916//7092 2919//7092 2917//7092
f 2918//7095 2920//7095 2921//7095
f 2918//7094 2917//7094 2920//7094
f 2922//7091 2919//7091 2916//7091
f 2798//7088 2923//7088 2924//7088
f 2795//7087 2923//7087 2798//7087
f 2922//7090 2924//7090 2919//7090
f 2798//7089 2924//7089 2922//7089
f 2925//7102 2926//7102 2927//7102
f 2928//7101 2926//7101 2925//7101
f 2929//7104 2927//7104 2930//7104
f 2925//7103 2927//7103 2929//7103
f 2928//7100 2827//7100 2926//7100
f 2921//7097 2931//7097 2932//7097
f 2921//7096 2920//7096 2931//7096
f 2825//7099 2827//7099 2928//7099
f 2932//7098 2931//7098 2933//7098
f 2785//7075 2918//7075 2783//7075
f 2785//7074 2916//7074 2918//7074
f 2783//7077 2921//7077 2789//7077
f 2783//7076 2918//7076 2921//7076
f 2792//7073 2916//7073 2785//7073
f 2791//7070 2798//7070 2922//7070
f 2797//7069 2798//7069 2791//7069
f 2792//7072 2922//7072 2916//7072
f 2791//7071 2922//7071 2792//7071
f 2794//7084 2925//7084 2929//7084
f 2799//7083 2925//7083 2794//7083
f 2795//7086 2929//7086 2923//7086
f 2794//7085 2929//7085 2795//7085
f 2799//7082 2928//7082 2925//7082
f 2787//7079 2825//7079 2800//7079
f 2789//7078 2921//7078 2932//7078
f 2800//7081 2928//7081 2799//7081
f 2800//7080 2825//7080 2928//7080
f 2789//7129 2909//7129 2784//7129
f 2789//7128 2910//7128 2909//7128
f 2784//7131 2908//7131 2911//7131
f 2909//7130 2908//7130 2784//7130
f 2915//7127 2910//7127 2789//7127
f 2906//7124 2907//7124 2932//7124
f 2933//7123 2906//7123 2932//7123
f 2932//7126 2915//7126 2789//7126
f 2932//7125 2907//7125 2915//7125
f 2761//7138 2863//7138 2776//7138
f 2761//7137 2866//7137 2863//7137
f 2776//7140 2862//7140 2868//7140
f 2863//7139 2862//7139 2776//7139
f 2912//7136 2866//7136 2761//7136
f 2911//7133 2913//7133 2762//7133
f 2784//7132 2911//7132 2762//7132
f 2762//7135 2912//7135 2761//7135
f 2762//7134 2913//7134 2912//7134
f 2919//7111 2934//7111 2917//7111
f 2919//7110 2935//7110 2934//7110
f 2917//7113 2936//7113 2920//7113
f 2917//7112 2934//7112 2936//7112
f 2924//7109 2935//7109 2919//7109
f 2923//7106 2930//7106 2937//7106
f 2929//7105 2930//7105 2923//7105
f 2924//7108 2937//7108 2935//7108
f 2923//7107 2937//7107 2924//7107
f 499//7120 2902//7120 2933//7120
f 498//7119 2902//7119 499//7119
f 2933//7122 2903//7122 2906//7122
f 2902//7121 2903//7121 2933//7121
f 2933//7118 2938//7118 499//7118
f 2920//7115 2939//7115 2931//7115
f 2920//7114 2936//7114 2939//7114
f 2931//7117 2938//7117 2933//7117
f 2931//7116 2939//7116 2938//7116
f 2834//6924 2940//6924 2832//6924
f 2941//6732 2942//6732 2943//6732
f 2941//6731 2944//6731 2942//6731
f 2943//6734 2945//6734 2946//6734
f 2943//6733 2942//6733 2945//6733
f 2947//6730 2944//6730 2941//6730
f 2948//6727 2949//6727 2950//6727
f 2951//6726 2949//6726 2948//6726
f 2947//6729 2950//6729 2944//6729
f 2948//6728 2950//6728 2947//6728
f 2952//6741 2953//6741 500//6741
f 2954//6740 2953//6740 2952//6740
f 2955//6743 2819//6743 2820//6743
f 2952//6742 500//6742 497//6742
f 2954//6739 2956//6739 2953//6739
f 2946//6736 2957//6736 2958//6736
f 2946//6735 2945//6735 2957//6735
f 2958//6738 2956//6738 2954//6738
f 2958//6737 2957//6737 2956//6737
f 1437//6714 2959//6714 2960//6714
f 1439//6713 2959//6713 1437//6713
f 1447//6716 2960//6716 2961//6716
f 1437//6715 2960//6715 1447//6715
f 1439//6712 2962//6712 2959//6712
f 1479//6709 2963//6709 1478//6709
f 1479//6708 2964//6708 2963//6708
f 1478//6711 2962//6711 1439//6711
f 1478//6710 2963//6710 2962//6710
f 1453//6723 1422//6723 1421//6723
f 1483//6722 1422//6722 1453//6722
f 2951//6725 1449//6725 2949//6725
f 1453//6724 1421//6724 1451//6724
f 1483//6721 1465//6721 1422//6721
f 1450//6718 2961//6718 2949//6718
f 1447//6717 2961//6717 1450//6717
f 1562//6720 1465//6720 1483//6720
f 1450//6719 2949//6719 1449//6719
f 2965//6768 2966//6768 2967//6768
f 2968//6767 2966//6767 2965//6767
f 2969//6770 2967//6770 2970//6770
f 2965//6769 2967//6769 2969//6769
f 2968//6766 2948//6766 2966//6766
f 2971//6763 2972//6763 2970//6763
f 2973//6762 2972//6762 2971//6762
f 2951//6765 2948//6765 2968//6765
f 2971//6764 2970//6764 2967//6764
f 2971//6777 2974//6777 2975//6777
f 2971//6776 2976//6776 2974//6776
f 2975//6779 2977//6779 2978//6779
f 2975//6778 2974//6778 2977//6778
f 2967//6775 2976//6775 2971//6775
f 2966//6772 2947//6772 2979//6772
f 2948//6771 2947//6771 2966//6771
f 2967//6774 2979//6774 2976//6774
f 2966//6773 2979//6773 2967//6773
f 2980//6750 2981//6750 2982//6750
f 2983//6749 2980//6749 2982//6749
f 2982//6752 2984//6752 2985//6752
f 2982//6751 2981//6751 2984//6751
f 2983//6748 2986//6748 2980//6748
f 2955//6745 2987//6745 2988//6745
f 2820//6744 2987//6744 2955//6744
f 2988//6747 2986//6747 2983//6747
f 2955//6746 2988//6746 2983//6746
f 2989//6759 2990//6759 2975//6759
f 2978//6758 2989//6758 2975//6758
f 2975//6761 2973//6761 2971//6761
f 2975//6760 2990//6760 2973//6760
f 2978//6757 2991//6757 2989//6757
f 2985//6754 2992//6754 2993//6754
f 2984//6753 2992//6753 2985//6753
f 2993//6756 2991//6756 2978//6756
f 2985//6755 2993//6755 2978//6755
f 2994//6660 2995//6660 2996//6660
f 2997//6659 2995//6659 2994//6659
f 2998//6662 2996//6662 2945//6662
f 2994//6661 2996//6661 2998//6661
f 2997//6658 1387//6658 2995//6658
f 1379//6655 1378//6655 1404//6655
f 2996//6654 2957//6654 2945//6654
f 1404//6657 1387//6657 2997//6657
f 1404//6656 1378//6656 1387//6656
f 2999//6669 2997//6669 2994//6669
f 3000//6668 2997//6668 2999//6668
f 3001//6671 2994//6671 2998//6671
f 2999//6670 2994//6670 3001//6670
f 3000//6667 1404//6667 2997//6667
f 1381//6664 1403//6664 1464//6664
f 2998//6663 2945//6663 2942//6663
f 1464//6666 1404//6666 3000//6666
f 1464//6665 1403//6665 1404//6665
f 500//6642 2953//6642 1460//6642
f 3002//6641 3003//6641 3004//6641
f 1460//6644 3002//6644 1375//6644
f 1460//6643 2953//6643 3002//6643
f 3002//6640 2956//6640 3003//6640
f 1469//6637 3005//6637 1466//6637
f 1469//6636 1472//6636 3005//6636
f 2953//6639 2956//6639 3002//6639
f 3003//6638 2956//6638 2957//6638
f 2995//6651 3004//6651 3003//6651
f 1387//6650 3004//6650 2995//6650
f 2996//6653 3003//6653 2957//6653
f 2995//6652 3003//6652 2996//6652
f 1387//6649 1418//6649 3004//6649
f 1375//6646 3004//6646 1420//6646
f 1375//6645 3002//6645 3004//6645
f 1386//6648 1418//6648 1387//6648
f 1420//6647 3004//6647 1418//6647
f 2962//6696 3006//6696 2959//6696
f 2962//6695 3007//6695 3006//6695
f 2959//6698 3008//6698 2960//6698
f 2959//6697 3006//6697 3008//6697
f 2963//6694 3007//6694 2962//6694
f 2964//6691 1477//6691 3009//6691
f 1476//6690 1477//6690 2964//6690
f 2963//6693 3009//6693 3007//6693
f 2964//6692 3009//6692 2963//6692
f 1465//6705 1476//6705 1468//6705
f 1465//6704 1474//6704 1476//6704
f 1468//6707 2964//6707 1479//6707
f 1468//6706 1476//6706 2964//6706
f 1562//6703 1474//6703 1465//6703
f 2960//6700 3010//6700 2961//6700
f 2960//6699 3008//6699 3010//6699
f 2961//6702 2950//6702 2949//6702
f 2961//6701 3010//6701 2950//6701
f 3009//6678 1464//6678 3000//6678
f 1477//6677 1464//6677 3009//6677
f 3007//6680 3000//6680 2999//6680
f 3009//6679 3000//6679 3007//6679
f 1477//6676 1388//6676 1464//6676
f 3011//6673 2998//6673 2942//6673
f 3001//6672 2998//6672 3011//6672
f 1390//6675 1388//6675 1477//6675
f 3011//6674 2942//6674 2944//6674
f 3010//6687 2944//6687 2950//6687
f 3010//6686 3011//6686 2944//6686
f 1476//6689 1394//6689 1477//6689
f 1475//6688 1394//6688 1476//6688
f 3008//6685 3011//6685 3010//6685
f 3006//6682 2999//6682 3001//6682
f 3007//6681 2999//6681 3006//6681
f 3008//6684 3001//6684 3011//6684
f 3006//6683 3001//6683 3008//6683
f 3012//6876 3013//6876 3014//6876
f 3015//6875 3013//6875 3012//6875
f 3016//6878 3014//6878 3017//6878
f 3012//6877 3014//6877 3016//6877
f 3015//6874 3018//6874 3013//6874
f 2958//6871 2954//6871 3019//6871
f 2753//6870 3020//6870 2786//6870
f 3019//6873 3018//6873 3015//6873
f 3019//6872 2954//6872 3018//6872
f 3021//6885 3022//6885 3023//6885
f 3021//6884 3024//6884 3022//6884
f 3023//6887 3025//6887 3020//6887
f 3023//6886 3022//6886 3025//6886
f 3026//6883 3024//6883 3021//6883
f 3027//6880 3017//6880 3028//6880
f 3016//6879 3017//6879 3027//6879
f 3026//6882 3028//6882 3024//6882
f 3027//6881 3028//6881 3026//6881
f 3029//6858 3012//6858 3016//6858
f 3030//6857 3012//6857 3029//6857
f 3031//6860 3016//6860 3027//6860
f 3029//6859 3016//6859 3031//6859
f 3030//6856 3015//6856 3012//6856
f 3032//6853 3019//6853 3033//6853
f 3032//6852 2958//6852 3019//6852
f 3033//6855 3015//6855 3030//6855
f 3033//6854 3019//6854 3015//6854
f 3034//6867 3023//6867 3035//6867
f 3034//6866 3021//6866 3023//6866
f 3035//6869 3020//6869 2753//6869
f 3035//6868 3023//6868 3020//6868
f 3036//6865 3021//6865 3034//6865
f 3037//6862 3027//6862 3026//6862
f 3031//6861 3027//6861 3037//6861
f 3036//6864 3026//6864 3021//6864
f 3037//6863 3026//6863 3036//6863
f 3038//6912 497//6912 3039//6912
f 2952//6911 497//6911 3038//6911
f 3040//6914 3039//6914 744//6914
f 3038//6913 3039//6913 3040//6913
f 2787//6910 2830//6910 2825//6910
f 3025//6907 2831//6907 3041//6907
f 3025//6906 2834//6906 2831//6906
f 3041//6909 2830//6909 2787//6909
f 3041//6908 2831//6908 2830//6908
f 3042//6921 3043//6921 3044//6921
f 3042//6920 3045//6920 3043//6920
f 3044//6923 2940//6923 2834//6923
f 3044//6922 3043//6922 2940//6922
f 3046//6919 3045//6919 3042//6919
f 3047//6916 744//6916 745//6916
f 3040//6915 744//6915 3047//6915
f 3046//6918 745//6918 3045//6918
f 3047//6917 745//6917 3046//6917
f 3013//6894 3038//6894 3040//6894
f 3018//6893 3038//6893 3013//6893
f 3014//6896 3040//6896 3047//6896
f 3013//6895 3040//6895 3014//6895
f 3018//6892 2952//6892 3038//6892
f 3020//6889 3041//6889 2786//6889
f 3020//6888 3025//6888 3041//6888
f 2954//6891 2952//6891 3018//6891
f 2786//6890 3041//6890 2787//6890
f 3024//6903 3044//6903 3022//6903
f 3024//6902 3042//6902 3044//6902
f 3022//6905 2834//6905 3025//6905
f 3022//6904 3044//6904 2834//6904
f 3028//6901 3042//6901 3024//6901
f 3017//6898 3047//6898 3046//6898
f 3014//6897 3047//6897 3017//6897
f 3028//6900 3046//6900 3042//6900
f 3017//6899 3046//6899 3028//6899
f 3048//6804 3049//6804 3050//6804
f 3051//6803 3049//6803 3048//6803
f 3052//6806 3050//6806 3053//6806
f 3048//6805 3050//6805 3052//6805
f 3051//6802 3054//6802 3049//6802
f 2977//6799 3055//6799 3056//6799
f 2977//6798 3057//6798 3055//6798
f 3056//6801 3054//6801 3051//6801
f 3056//6800 3055//6800 3054//6800
f 3058//6813 3059//6813 3060//6813
f 3058//6812 2943//6812 3059//6812
f 3060//6815 3061//6815 3057//6815
f 3060//6814 3059//6814 3061//6814
f 2941//6811 2943//6811 3058//6811
f 3062//6808 3053//6808 3063//6808
f 3052//6807 3053//6807 3062//6807
f 2817//6810 3063//6810 2778//6810
f 3062//6809 3063//6809 2817//6809
f 2983//6786 3048//6786 3052//6786
f 2982//6785 3048//6785 2983//6785
f 2955//6788 3052//6788 3062//6788
f 2983//6787 3052//6787 2955//6787
f 2982//6784 3051//6784 3048//6784
f 2978//6781 3056//6781 2985//6781
f 2978//6780 2977//6780 3056//6780
f 2985//6783 3051//6783 2982//6783
f 2985//6782 3056//6782 3051//6782
f 2976//6795 3060//6795 2974//6795
f 2976//6794 3058//6794 3060//6794
f 2974//6797 3057//6797 2977//6797
f 2974//6796 3060//6796 3057//6796
f 2979//6793 3058//6793 2976//6793
f 2819//6790 3062//6790 2817//6790
f 2955//6789 3062//6789 2819//6789
f 2979//6792 2941//6792 3058//6792
f 2947//6791 2941//6791 2979//6791
f 3064//6840 3029//6840 3031//6840
f 3065//6839 3029//6839 3064//6839
f 3066//6842 3031//6842 3037//6842
f 3064//6841 3031//6841 3066//6841
f 3065//6838 3030//6838 3029//6838
f 3061//6835 3033//6835 3067//6835
f 3061//6834 3032//6834 3033//6834
f 3067//6837 3030//6837 3065//6837
f 3067//6836 3033//6836 3030//6836
f 3068//6849 3035//6849 2756//6849
f 3068//6848 3034//6848 3035//6848
f 2946//6851 2958//6851 3032//6851
f 2756//6850 3035//6850 2753//6850
f 3069//6847 3034//6847 3068//6847
f 3070//6844 3037//6844 3036//6844
f 3066//6843 3037//6843 3070//6843
f 3069//6846 3036//6846 3034//6846
f 3070//6845 3036//6845 3069//6845
f 3049//6822 3064//6822 3066//6822
f 3054//6821 3064//6821 3049//6821
f 3050//6824 3066//6824 3070//6824
f 3049//6823 3066//6823 3050//6823
f 3054//6820 3065//6820 3064//6820
f 3057//6817 3067//6817 3055//6817
f 3057//6816 3061//6816 3067//6816
f 3055//6819 3065//6819 3054//6819
f 3055//6818 3067//6818 3065//6818
f 2943//6831 2946//6831 3059//6831
f 2778//6830 3068//6830 2756//6830
f 3059//6833 3032//6833 3061//6833
f 3059//6832 2946//6832 3032//6832
f 3063//6829 3068//6829 2778//6829
f 3053//6826 3070//6826 3069//6826
f 3050//6825 3070//6825 3053//6825
f 3063//6828 3069//6828 3068//6828
f 3053//6827 3069//6827 3063//6827
f 3071//7598 3072//7598 3073//7598
f 3074//7597 3072//7597 3071//7597
f 3075//7600 3073//7600 3076//7600
f 3071//7599 3073//7599 3075//7599
f 3074//7596 3077//7596 3072//7596
f 3078//7593 3079//7593 3080//7593
f 3078//7592 3081//7592 3079//7592
f 3080//7595 3077//7595 3074//7595
f 3080//7594 3079//7594 3077//7594
f 3082//7607 3083//7607 3084//7607
f 3082//7606 3085//7606 3083//7606
f 3084//7609 3086//7609 3081//7609
f 3084//7608 3083//7608 3086//7608
f 3087//7605 3085//7605 3082//7605
f 3088//7602 3089//7602 3090//7602
f 3075//7601 3076//7601 3091//7601
f 3087//7604 3092//7604 3085//7604
f 3093//7603 3092//7603 3087//7603
f 3094//7580 3071//7580 3075//7580
f 3095//7579 3071//7579 3094//7579
f 3096//7582 3075//7582 3097//7582
f 3094//7581 3075//7581 3096//7581
f 3095//7578 3074//7578 3071//7578
f 3098//7575 3080//7575 3099//7575
f 3098//7574 3078//7574 3080//7574
f 3099//7577 3074//7577 3095//7577
f 3099//7576 3080//7576 3074//7576
f 3100//7589 3084//7589 3101//7589
f 3100//7588 3082//7588 3084//7588
f 3101//7591 3081//7591 3078//7591
f 3101//7590 3084//7590 3081//7590
f 3102//7587 3082//7587 3100//7587
f 3073//7584 3103//7584 3076//7584
f 3096//7583 3097//7583 3104//7583
f 3102//7586 3087//7586 3082//7586
f 3105//7585 3087//7585 3102//7585
f 3106//7634 3107//7634 3108//7634
f 3109//7633 3107//7633 3106//7633
f 3088//7636 3108//7636 3110//7636
f 3106//7635 3108//7635 3088//7635
f 3109//7632 3111//7632 3107//7632
f 3086//7629 3112//7629 3113//7629
f 3086//7628 3114//7628 3112//7628
f 3113//7631 3111//7631 3109//7631
f 3113//7630 3112//7630 3111//7630
f 3115//7643 3116//7643 3117//7643
f 3115//7642 3118//7642 3116//7642
f 3117//7645 3119//7645 3114//7645
f 3117//7644 3116//7644 3119//7644
f 3120//7641 3118//7641 3115//7641
f 3121//7638 3122//7638 3123//7638
f 3088//7637 3110//7637 3089//7637
f 3120//7640 3124//7640 3118//7640
f 3125//7639 3124//7639 3120//7639
f 3072//7616 3106//7616 3088//7616
f 3077//7615 3106//7615 3072//7615
f 3073//7618 3088//7618 3090//7618
f 3072//7617 3088//7617 3073//7617
f 3077//7614 3109//7614 3106//7614
f 3081//7611 3113//7611 3079//7611
f 3081//7610 3086//7610 3113//7610
f 3079//7613 3109//7613 3077//7613
f 3079//7612 3113//7612 3109//7612
f 3085//7625 3117//7625 3083//7625
f 3085//7624 3115//7624 3117//7624
f 3083//7627 3114//7627 3086//7627
f 3083//7626 3117//7626 3114//7626
f 3092//7623 3115//7623 3085//7623
f 3108//7620 3126//7620 3110//7620
f 3073//7619 3090//7619 3103//7619
f 3092//7622 3120//7622 3115//7622
f 3127//7621 3120//7621 3092//7621
f 3093//7526 3127//7526 3092//7526
f 3127//7525 3125//7525 3120//7525
f 3128//7528 3105//7528 3102//7528
f 3105//7527 3093//7527 3087//7527
f 3125//7524 3129//7524 3124//7524
f 3130//7521 3131//7521 3132//7521
f 3133//7520 3134//7520 3135//7520
f 3129//7523 3136//7523 3137//7523
f 3136//7522 3130//7522 3138//7522
f 3139//7535 3140//7535 3141//7535
f 3139//7534 3142//7534 3140//7534
f 3141//7537 3143//7537 3144//7537
f 3141//7536 3140//7536 3143//7536
f 3134//7533 3142//7533 3139//7533
f 720//7530 3145//7530 3146//7530
f 3145//7529 3128//7529 3147//7529
f 3134//7532 3146//7532 3142//7532
f 720//7531 3146//7531 3134//7531
f 3148//7508 3149//7508 3144//7508
f 3150//7507 3148//7507 3144//7507
f 3144//7510 3151//7510 3141//7510
f 3144//7509 3149//7509 3151//7509
f 3150//7506 3152//7506 3148//7506
f 3153//7503 3154//7503 3155//7503
f 3156//7502 3154//7502 3153//7502
f 3155//7505 3152//7505 3150//7505
f 3153//7504 3155//7504 3150//7504
f 721//7517 720//7517 3157//7517
f 3139//7516 3135//7516 3134//7516
f 3157//7519 3134//7519 3133//7519
f 3157//7518 720//7518 3134//7518
f 3139//7515 3158//7515 3135//7515
f 3141//7512 3159//7512 3160//7512
f 3151//7511 3159//7511 3141//7511
f 3160//7514 3158//7514 3139//7514
f 3141//7513 3160//7513 3139//7513
f 3161//7562 3094//7562 3096//7562
f 3162//7561 3094//7561 3161//7561
f 3163//7564 3096//7564 3164//7564
f 3161//7563 3096//7563 3163//7563
f 3162//7560 3095//7560 3094//7560
f 3143//7557 3099//7557 3165//7557
f 3143//7556 3098//7556 3099//7556
f 3165//7559 3095//7559 3162//7559
f 3165//7558 3099//7558 3095//7558
f 3166//7571 3101//7571 3167//7571
f 3166//7570 3100//7570 3101//7570
f 3167//7573 3078//7573 3098//7573
f 3167//7572 3101//7572 3078//7572
f 3147//7569 3100//7569 3166//7569
f 3075//7566 3091//7566 3097//7566
f 3163//7565 3164//7565 3168//7565
f 3147//7568 3102//7568 3100//7568
f 3128//7567 3102//7567 3147//7567
f 3169//7544 3161//7544 3163//7544
f 3153//7543 3161//7543 3169//7543
f 3170//7546 3163//7546 3168//7546
f 3169//7545 3163//7545 3170//7545
f 3153//7542 3162//7542 3161//7542
f 3144//7539 3165//7539 3150//7539
f 3144//7538 3143//7538 3165//7538
f 3150//7541 3162//7541 3153//7541
f 3150//7540 3165//7540 3162//7540
f 3142//7553 3167//7553 3140//7553
f 3142//7552 3166//7552 3167//7552
f 3140//7555 3098//7555 3143//7555
f 3140//7554 3167//7554 3098//7554
f 3146//7551 3166//7551 3142//7551
f 3096//7548 3104//7548 3164//7548
f 3170//7547 3168//7547 3171//7547
f 3146//7550 3147//7550 3166//7550
f 3145//7549 3147//7549 3146//7549
f 3172//7742 3173//7742 3174//7742
f 3175//7741 3173//7741 3172//7741
f 3176//7744 3174//7744 3177//7744
f 3172//7743 3174//7743 3176//7743
f 3175//7740 3178//7740 3173//7740
f 3179//7737 3180//7737 3181//7737
f 3182//7736 3183//7736 3184//7736
f 3181//7739 3178//7739 3175//7739
f 3181//7738 3180//7738 3178//7738
f 3185//7751 3182//7751 3186//7751
f 3185//7750 3187//7750 3182//7750
f 3186//7753 3184//7753 3188//7753
f 3186//7752 3182//7752 3184//7752
f 3189//7749 3187//7749 3185//7749
f 3190//7746 3177//7746 3191//7746
f 3176//7745 3177//7745 3190//7745
f 3189//7748 3191//7748 3187//7748
f 3190//7747 3191//7747 3189//7747
f 3192//7724 3172//7724 3176//7724
f 3193//7723 3172//7723 3192//7723
f 3194//7726 3176//7726 3190//7726
f 3192//7725 3176//7725 3194//7725
f 3193//7722 3175//7722 3172//7722
f 3131//7719 3181//7719 3132//7719
f 3131//7718 3179//7718 3181//7718
f 3132//7721 3175//7721 3193//7721
f 3132//7720 3181//7720 3175//7720
f 3195//7733 3186//7733 3196//7733
f 3195//7732 3185//7732 3186//7732
f 3196//7735 3188//7735 3197//7735
f 3196//7734 3186//7734 3188//7734
f 3198//7731 3185//7731 3195//7731
f 3199//7728 3190//7728 3189//7728
f 3194//7727 3190//7727 3199//7727
f 3198//7730 3189//7730 3185//7730
f 3199//7729 3189//7729 3198//7729
f 3200//7778 2327//7778 2329//7778
f 3201//7777 2327//7777 3200//7777
f 3202//7780 2329//7780 2337//7780
f 3200//7779 2329//7779 3202//7779
f 3201//7776 2328//7776 2327//7776
f 3203//7773 2331//7773 3204//7773
f 2334//7772 2335//7772 3205//7772
f 3204//7775 2328//7775 3201//7775
f 3204//7774 2331//7774 2328//7774
f 3206//7787 2334//7787 3207//7787
f 3206//7786 2333//7786 2334//7786
f 3207//7789 3205//7789 3208//7789
f 3207//7788 2334//7788 3205//7788
f 3209//7785 2333//7785 3206//7785
f 3210//7782 2337//7782 2336//7782
f 3202//7781 2337//7781 3210//7781
f 3209//7784 2336//7784 2333//7784
f 3210//7783 2336//7783 3209//7783
f 3173//7760 3200//7760 3202//7760
f 3178//7759 3200//7759 3173//7759
f 3174//7762 3202//7762 3210//7762
f 3173//7761 3202//7761 3174//7761
f 3178//7758 3201//7758 3200//7758
f 3211//7755 3204//7755 3180//7755
f 3207//7754 3208//7754 3212//7754
f 3180//7757 3201//7757 3178//7757
f 3180//7756 3204//7756 3201//7756
f 3187//7769 3207//7769 3182//7769
f 3187//7768 3206//7768 3207//7768
f 3182//7771 3212//7771 3183//7771
f 3182//7770 3207//7770 3212//7770
f 3191//7767 3206//7767 3187//7767
f 3177//7764 3210//7764 3209//7764
f 3174//7763 3210//7763 3177//7763
f 3191//7766 3209//7766 3206//7766
f 3177//7765 3209//7765 3191//7765
f 3213//7670 3214//7670 3215//7670
f 3216//7669 3214//7669 3213//7669
f 3121//7672 3215//7672 3217//7672
f 3213//7671 3215//7671 3121//7671
f 3216//7668 3218//7668 3214//7668
f 3119//7665 3219//7665 3220//7665
f 3119//7664 3221//7664 3219//7664
f 3220//7667 3218//7667 3216//7667
f 3220//7666 3219//7666 3218//7666
f 3222//7679 3223//7679 3224//7679
f 3222//7678 3225//7678 3223//7678
f 3224//7681 3226//7681 3221//7681
f 3224//7680 3223//7680 3226//7680
f 3137//7677 3225//7677 3222//7677
f 3227//7674 3228//7674 3229//7674
f 3121//7673 3217//7673 3122//7673
f 3137//7676 3138//7676 3225//7676
f 3136//7675 3138//7675 3137//7675
f 3107//7652 3213//7652 3121//7652
f 3111//7651 3213//7651 3107//7651
f 3108//7654 3121//7654 3123//7654
f 3107//7653 3121//7653 3108//7653
f 3111//7650 3216//7650 3213//7650
f 3114//7647 3220//7647 3112//7647
f 3114//7646 3119//7646 3220//7646
f 3112//7649 3216//7649 3111//7649
f 3112//7648 3220//7648 3216//7648
f 3118//7661 3224//7661 3116//7661
f 3118//7660 3222//7660 3224//7660
f 3116//7663 3221//7663 3119//7663
f 3116//7662 3224//7662 3221//7662
f 3124//7659 3222//7659 3118//7659
f 3215//7656 3230//7656 3217//7656
f 3108//7655 3123//7655 3126//7655
f 3124//7658 3137//7658 3222//7658
f 3129//7657 3137//7657 3124//7657
f 3231//7706 3196//7706 3227//7706
f 3231//7705 3195//7705 3196//7705
f 3227//7708 3197//7708 3228//7708
f 3227//7707 3196//7707 3197//7707
f 3232//7704 3195//7704 3231//7704
f 3233//7701 3199//7701 3198//7701
f 3226//7700 3199//7700 3233//7700
f 3232//7703 3198//7703 3195//7703
f 3233//7702 3198//7702 3232//7702
f 3203//7715 2330//7715 2331//7715
f 2330//7714 2314//7714 2316//7714
f 3179//7717 3211//7717 3180//7717
f 3211//7716 3203//7716 3204//7716
f 2314//7713 2348//7713 2315//7713
f 2266//7710 718//7710 2251//7710
f 718//7709 727//7709 2252//7709
f 2348//7712 719//7712 2341//7712
f 719//7711 2266//7711 2267//7711
f 3214//7688 3231//7688 3227//7688
f 3218//7687 3231//7687 3214//7687
f 3215//7690 3227//7690 3229//7690
f 3214//7689 3227//7689 3215//7689
f 3218//7686 3232//7686 3231//7686
f 3221//7683 3233//7683 3219//7683
f 3221//7682 3226//7682 3233//7682
f 3219//7685 3232//7685 3218//7685
f 3219//7684 3233//7684 3232//7684
f 3223//7697 3192//7697 3194//7697
f 3225//7696 3192//7696 3223//7696
f 3226//7699 3194//7699 3199//7699
f 3223//7698 3194//7698 3226//7698
f 3225//7695 3193//7695 3192//7695
f 3130//7692 3132//7692 3138//7692
f 3215//7691 3229//7691 3230//7691
f 3138//7694 3193//7694 3225//7694
f 3138//7693 3132//7693 3193//7693
f 3169//7501 3156//7501 3153//7501
f 3234//7309 2896//7309 3235//7309
f 3234//7308 2893//7308 2896//7308
f 3235//7311 2905//7311 3236//7311
f 3235//7310 2896//7310 2905//7310
f 3237//7307 2893//7307 3234//7307
f 3238//7304 2897//7304 2891//7304
f 3239//7303 2897//7303 3238//7303
f 3237//7306 2891//7306 2893//7306
f 3238//7305 2891//7305 3237//7305
f 2905//7318 1376//7318 1417//7318
f 2905//7317 1374//7317 1376//7317
f 3240//7320 3241//7320 3242//7320
f 3243//7319 3244//7319 3245//7319
f 2901//7316 1374//7316 2905//7316
f 3236//7313 1417//7313 1419//7313
f 3236//7312 2905//7312 1417//7312
f 2901//7315 1459//7315 1374//7315
f 498//7314 1459//7314 2901//7314
f 3246//7291 3235//7291 3236//7291
f 3247//7290 3235//7290 3246//7290
f 3248//7293 3236//7293 1409//7293
f 3246//7292 3236//7292 3248//7292
f 3247//7289 3234//7289 3235//7289
f 3249//7286 3237//7286 3250//7286
f 3249//7285 3238//7285 3237//7285
f 3250//7288 3234//7288 3247//7288
f 3250//7287 3237//7287 3234//7287
f 3251//7300 2900//7300 2898//7300
f 3252//7299 2900//7299 3251//7299
f 3239//7302 2898//7302 2897//7302
f 3251//7301 2898//7301 3239//7301
f 3252//7298 2914//7298 2900//7298
f 2876//7295 2878//7295 2877//7295
f 3248//7294 1409//7294 1377//7294
f 2877//7297 2914//7297 3252//7297
f 2877//7296 2878//7296 2914//7296
f 1556//7345 3240//7345 1558//7345
f 1559//7344 3240//7344 1556//7344
f 3253//7347 3254//7347 3255//7347
f 3256//7346 3254//7346 3253//7346
f 1559//7343 3241//7343 3240//7343
f 1526//7340 3257//7340 3258//7340
f 1525//7339 3257//7339 1526//7339
f 1551//7342 3241//7342 1559//7342
f 1526//7341 3258//7341 1527//7341
f 3259//7354 3260//7354 3261//7354
f 3262//7353 3260//7353 3259//7353
f 3263//7356 3261//7356 3264//7356
f 3259//7355 3261//7355 3263//7355
f 3262//7352 1573//7352 3260//7352
f 3257//7349 3255//7349 3265//7349
f 3253//7348 3255//7348 3257//7348
f 1575//7351 1573//7351 3262//7351
f 3257//7350 3265//7350 3266//7350
f 3255//7327 3254//7327 3267//7327
f 1550//7326 1571//7326 3268//7326
f 3269//7329 3257//7329 3266//7329
f 3258//7328 3257//7328 3269//7328
f 1567//7325 1565//7325 3270//7325
f 1573//7322 1572//7322 3260//7322
f 1551//7321 721//7321 3157//7321
f 1569//7324 1568//7324 3271//7324
f 1577//7323 1575//7323 3262//7323
f 3272//7336 3273//7336 3274//7336
f 3275//7335 3273//7335 3272//7335
f 1525//7338 3253//7338 3257//7338
f 1529//7337 3253//7337 1525//7337
f 3276//7334 3277//7334 3278//7334
f 3278//7331 3279//7331 3276//7331
f 3280//7330 3281//7330 3282//7330
f 3276//7333 3283//7333 3277//7333
f 3284//7332 3283//7332 3276//7332
f 3285//7237 3286//7237 3287//7237
f 3288//7236 3286//7236 3285//7236
f 3289//7239 3287//7239 1392//7239
f 3285//7238 3287//7238 3289//7238
f 3288//7235 3290//7235 3286//7235
f 2857//7232 3291//7232 2855//7232
f 2857//7231 3292//7231 3291//7231
f 2855//7234 3290//7234 3288//7234
f 2855//7233 3291//7233 3290//7233
f 3292//7246 3293//7246 3291//7246
f 3292//7245 3294//7245 3293//7245
f 3291//7248 3295//7248 3290//7248
f 3291//7247 3293//7247 3295//7247
f 3296//7244 3294//7244 3292//7244
f 3297//7241 1380//7241 1382//7241
f 3289//7240 1392//7240 1395//7240
f 3296//7243 2883//7243 3294//7243
f 2884//7242 2883//7242 3296//7242
f 3298//7219 2855//7219 3288//7219
f 2856//7218 2855//7218 3298//7218
f 2861//7221 3288//7221 3285//7221
f 3298//7220 3288//7220 2861//7220
f 3289//7217 1395//7217 1560//7217
f 2858//7214 2904//7214 2850//7214
f 2858//7213 2859//7213 2904//7213
f 2850//7216 1563//7216 1553//7216
f 2850//7215 2904//7215 1563//7215
f 2884//7228 3296//7228 3299//7228
f 3287//7227 1389//7227 1392//7227
f 3299//7230 3292//7230 2857//7230
f 3299//7229 3296//7229 3292//7229
f 2904//7226 1560//7226 1561//7226
f 2859//7223 3285//7223 3289//7223
f 2861//7222 3285//7222 2859//7222
f 2904//7225 3289//7225 1560//7225
f 2859//7224 3289//7224 2904//7224
f 3297//7273 3248//7273 1402//7273
f 3300//7272 3248//7272 3297//7272
f 3236//7275 1419//7275 1409//7275
f 3297//7274 1402//7274 1380//7274
f 3300//7271 3246//7271 3248//7271
f 3293//7268 3247//7268 3295//7268
f 3293//7267 3250//7267 3247//7267
f 3295//7270 3246//7270 3300//7270
f 3295//7269 3247//7269 3246//7269
f 3301//7282 3239//7282 3302//7282
f 3301//7281 3251//7281 3239//7281
f 3302//7284 3238//7284 3249//7284
f 3302//7283 3239//7283 3238//7283
f 3303//7280 3251//7280 3301//7280
f 2887//7277 2877//7277 3252//7277
f 2875//7276 2877//7276 2887//7276
f 3303//7279 3252//7279 3251//7279
f 2887//7278 3252//7278 3303//7278
f 3248//7255 1377//7255 1402//7255
f 3287//7254 1382//7254 1389//7254
f 2890//7257 2887//7257 3303//7257
f 2888//7256 2887//7256 2890//7256
f 3287//7253 3297//7253 1382//7253
f 3290//7250 3300//7250 3286//7250
f 3290//7249 3295//7249 3300//7249
f 3286//7252 3297//7252 3287//7252
f 3286//7251 3300//7251 3297//7251
f 2883//7264 3249//7264 3294//7264
f 2883//7263 3302//7263 3249//7263
f 3294//7266 3250//7266 3293//7266
f 3294//7265 3249//7265 3250//7265
f 2880//7262 3302//7262 2883//7262
f 2885//7259 3303//7259 3301//7259
f 2890//7258 3303//7258 2885//7258
f 2880//7261 3301//7261 3302//7261
f 2885//7260 3301//7260 2880//7260
f 3304//7453 3305//7453 3306//7453
f 3304//7452 3307//7452 3305//7452
f 3306//7455 3308//7455 3309//7455
f 3306//7454 3305//7454 3308//7454
f 3310//7451 3307//7451 3304//7451
f 3311//7448 3312//7448 3313//7448
f 3314//7447 3312//7447 3311//7447
f 3310//7450 3313//7450 3307//7450
f 3311//7449 3313//7449 3310//7449
f 3315//7462 3272//7462 3316//7462
f 3315//7461 3275//7461 3272//7461
f 3317//7464 3316//7464 3272//7464
f 3318//7463 3316//7463 3317//7463
f 3319//7460 3275//7460 3315//7460
f 3309//7457 3320//7457 3243//7457
f 3309//7456 3308//7456 3320//7456
f 3243//7459 3321//7459 3244//7459
f 3243//7458 3320//7458 3321//7458
f 3322//7435 3323//7435 3324//7435
f 3325//7434 3326//7434 3154//7434
f 3324//7437 3327//7437 3328//7437
f 3324//7436 3323//7436 3327//7436
f 3329//7433 3326//7433 3325//7433
f 3330//7430 3331//7430 3332//7430
f 3333//7429 3331//7429 3330//7429
f 3329//7432 3332//7432 3326//7432
f 3330//7431 3332//7431 3329//7431
f 3334//7444 3254//7444 3256//7444
f 3267//7443 3254//7443 3334//7443
f 3314//7446 3256//7446 3312//7446
f 3334//7445 3256//7445 3314//7445
f 3320//7442 3335//7442 3321//7442
f 3325//7439 3156//7439 3336//7439
f 3154//7438 3156//7438 3325//7438
f 3320//7441 3336//7441 3335//7441
f 3325//7440 3336//7440 3320//7440
f 3310//7489 3337//7489 3311//7489
f 3310//7488 3284//7488 3337//7488
f 3311//7491 3338//7491 3282//7491
f 3311//7490 3337//7490 3338//7490
f 3304//7487 3284//7487 3310//7487
f 3306//7484 3339//7484 3340//7484
f 3309//7483 3339//7483 3306//7483
f 3304//7486 3340//7486 3284//7486
f 3306//7485 3340//7485 3304//7485
f 3170//7498 3335//7498 3169//7498
f 3170//7497 3321//7497 3335//7497
f 3169//7500 3336//7500 3156//7500
f 3335//7499 3336//7499 3169//7499
f 3244//7496 3321//7496 3170//7496
f 3282//7493 3341//7493 3280//7493
f 3282//7492 3338//7492 3341//7492
f 3171//7495 3244//7495 3170//7495
f 3245//7494 3244//7494 3171//7494
f 3342//7471 3318//7471 3317//7471
f 3343//7470 3318//7470 3342//7470
f 3339//7473 3317//7473 3328//7473
f 3342//7472 3317//7472 3339//7472
f 3324//7469 3344//7469 3322//7469
f 3328//7466 3272//7466 3274//7466
f 3317//7465 3272//7465 3328//7465
f 3324//7468 3274//7468 3344//7468
f 3328//7467 3274//7467 3324//7467
f 3243//7480 3343//7480 3342//7480
f 3245//7479 3343//7479 3243//7479
f 3309//7482 3342//7482 3339//7482
f 3243//7481 3342//7481 3309//7481
f 3284//7478 3323//7478 3283//7478
f 3340//7475 3328//7475 3327//7475
f 3339//7474 3328//7474 3340//7474
f 3284//7477 3327//7477 3323//7477
f 3340//7476 3327//7476 3284//7476
f 3332//7381 3345//7381 3346//7381
f 3331//7380 3345//7380 3332//7380
f 3158//7383 3160//7383 3264//7383
f 3332//7382 3346//7382 3326//7382
f 3331//7379 3347//7379 3345//7379
f 3348//7376 3349//7376 3350//7376
f 3348//7375 3271//7375 3349//7375
f 3350//7378 3347//7378 3331//7378
f 3350//7377 3349//7377 3347//7377
f 3271//7390 3262//7390 3349//7390
f 3271//7389 1577//7389 3262//7389
f 3349//7392 3259//7392 3347//7392
f 3349//7391 3262//7391 3259//7391
f 1568//7388 1577//7388 3271//7388
f 3264//7385 3159//7385 3351//7385
f 3264//7384 3160//7384 3159//7384
f 3351//7387 3151//7387 3149//7387
f 3351//7386 3159//7386 3151//7386
f 1572//7363 1558//7363 3260//7363
f 3242//7362 3135//7362 3158//7362
f 3260//7365 3240//7365 3261//7365
f 3260//7364 1558//7364 3240//7364
f 3242//7361 3133//7361 3135//7361
f 1551//7358 3157//7358 3241//7358
f 3263//7357 3264//7357 3351//7357
f 3241//7360 3133//7360 3242//7360
f 3241//7359 3157//7359 3133//7359
f 1565//7372 1569//7372 3270//7372
f 3282//7371 3314//7371 3311//7371
f 3270//7374 3271//7374 3348//7374
f 3270//7373 1569//7373 3271//7373
f 3282//7370 3334//7370 3314//7370
f 3261//7367 3242//7367 3264//7367
f 3261//7366 3240//7366 3242//7366
f 3281//7369 3334//7369 3282//7369
f 3264//7368 3242//7368 3158//7368
f 3346//7417 3148//7417 3152//7417
f 3149//7416 3148//7416 3346//7416
f 3326//7419 3152//7419 3155//7419
f 3346//7418 3152//7418 3326//7418
f 3308//7415 3325//7415 3320//7415
f 3305//7412 3330//7412 3329//7412
f 3307//7411 3330//7411 3305//7411
f 3308//7414 3329//7414 3325//7414
f 3305//7413 3329//7413 3308//7413
f 3352//7426 3348//7426 3350//7426
f 3353//7425 3348//7425 3352//7425
f 3333//7428 3350//7428 3331//7428
f 3352//7427 3350//7427 3333//7427
f 3353//7424 3270//7424 3348//7424
f 1571//7421 1567//7421 3268//7421
f 3326//7420 3155//7420 3154//7420
f 3268//7423 3270//7423 3353//7423
f 3268//7422 1567//7422 3270//7422
f 3276//7399 3338//7399 3337//7399
f 3279//7398 3338//7398 3276//7398
f 1529//7401 1550//7401 3253//7401
f 3276//7400 3337//7400 3284//7400
f 3346//7397 3351//7397 3149//7397
f 3347//7394 3263//7394 3345//7394
f 3347//7393 3259//7393 3263//7393
f 3345//7396 3351//7396 3346//7396
f 3345//7395 3263//7395 3351//7395
f 3313//7408 3352//7408 3333//7408
f 3312//7407 3352//7407 3313//7407
f 3307//7410 3333//7410 3330//7410
f 3313//7409 3333//7409 3307//7409
f 3312//7406 3353//7406 3352//7406
f 3253//7403 3268//7403 3256//7403
f 3253//7402 1550//7402 3268//7402
f 3256//7405 3353//7405 3312//7405
f 3256//7404 3268//7404 3353//7404
f 2042//1400 1897//1400 619//1400
f 619//1401 617//1401 2042//1401
f 2042//1402 617//1402 1900//1402
f 2042//1399 1908//1399 1897//1399
f 2004//1396 1831//1396 2001//1396
f 2042//1397 2040//1397 1906//1397
f 1906//1398 1908//1398 2042//1398
f 2042//1403 1900//1403 2004//1403
f 737//1408 3354//1408 736//1408
f 736//1409 3354//1409 3355//1409
f 736//1410 3355//1410 613//1410
f 737//1407 3356//1407 3354//1407
f 2004//1404 1900//1404 1833//1404
f 2004//1405 1833//1405 1826//1405
f 733//1406 3356//1406 737//1406
f 2004//1395 1829//1395 1831//1395
f 1889//1384 1884//1384 2008//1384
f 2008//1385 1884//1385 1886//1385
f 2008//1386 1886//1386 1882//1386
f 1866//1383 1889//1383 2008//1383
f 1995//1380 2036//1380 1996//1380
f 1996//1381 2036//1381 1905//1381
f 1996//1382 1905//1382 1910//1382
f 1910//1387 1873//1387 1996//1387
f 2005//1392 1999//1392 2003//1392
f 2003//1393 1999//1393 2014//1393
f 1826//1394 1829//1394 2004//1394
f 2008//1391 1865//1391 1866//1391
f 1996//1388 1873//1388 1877//1388
f 1996//1389 1877//1389 2008//1389
f 2008//1390 1877//1390 1865//1390
f 729//1431 3357//1431 3358//1431
f 729//1432 3358//1432 714//1432
f 714//1433 3358//1433 3359//1433
f 728//1430 3357//1430 729//1430
f 730//1427 3360//1427 3361//1427
f 730//1428 3361//1428 728//1428
f 728//1429 3361//1429 3357//1429
f 714//1434 3359//1434 711//1434
f 3362//1439 3363//1439 3364//1439
f 3362//1440 3364//1440 3365//1440
f 3365//1441 3364//1441 3366//1441
f 712//1438 3363//1438 3362//1438
f 711//1435 3359//1435 3367//1435
f 711//1436 3367//1436 712//1436
f 712//1437 3367//1437 3363//1437
f 496//1426 3360//1426 730//1426
f 492//1415 3368//1415 3369//1415
f 492//1416 3369//1416 485//1416
f 485//1417 3369//1417 3370//1417
f 490//1414 3368//1414 492//1414
f 613//1411 3355//1411 3371//1411
f 613//1412 3371//1412 490//1412
f 490//1413 3371//1413 3368//1413
f 485//1418 3370//1418 486//1418
f 495//1423 3372//1423 3373//1423
f 495//1424 3373//1424 496//1424
f 496//1425 3373//1425 3360//1425
f 488//1422 3372//1422 495//1422
f 486//1419 3370//1419 3374//1419
f 486//1420 3374//1420 488//1420
f 488//1421 3374//1421 3372//1421
f 573//1331 3375//1331 571//1331
f 571//1332 3375//1332 3376//1332
f 571//1333 3376//1333 568//1333
f 573//1330 3377//1330 3375//1330
f 537//1327 3378//1327 3379//1327
f 3380//1328 623//1328 540//1328
f 768//1329 3377//1329 573//1329
f 568//1334 3376//1334 3381//1334
f 564//1339 3382//1339 569//1339
f 569//1340 3382//1340 821//1340
f 569//1341 821//1341 532//1341
f 564//1338 3383//1338 3382//1338
f 568//1335 3381//1335 566//1335
f 566//1336 3381//1336 3383//1336
f 566//1337 3383//1337 564//1337
f 537//1326 881//1326 3378//1326
f 547//1315 3384//1315 562//1315
f 562//1316 3384//1316 3385//1316
f 562//1317 3385//1317 560//1317
f 547//1314 3386//1314 3384//1314
f 522//1311 3387//1311 546//1311
f 546//1312 3387//1312 3386//1312
f 546//1313 3386//1313 547//1313
f 560//1318 3385//1318 3388//1318
f 552//1323 3389//1323 550//1323
f 550//1324 3389//1324 556//1324
f 545//1325 881//1325 537//1325
f 552//1322 3390//1322 3389//1322
f 560//1319 3388//1319 558//1319
f 558//1320 3388//1320 3390//1320
f 558//1321 3390//1321 552//1321
f 2004//1369 1536//1369 2042//1369
f 2014//1370 1996//1370 1531//1370
f 1531//1371 1996//1371 2008//1371
f 2004//1368 1516//1368 1536//1368
f 1516//1365 2003//1365 2014//1365
f 1516//1366 2014//1366 1531//1366
f 2003//1367 1516//1367 2004//1367
f 1531//1372 2008//1372 1541//1372
f 2007//1377 2012//1377 2009//1377
f 2009//1378 2012//1378 2041//1378
f 1882//1379 2006//1379 2008//1379
f 1536//1376 2041//1376 2042//1376
f 1541//1373 2008//1373 2009//1373
f 1541//1374 2009//1374 1536//1374
f 1536//1375 2009//1375 2041//1375
f 1171//1360 776//1360 556//1360
f 535//1346 3391//1346 3392//1346
f 535//1347 3392//1347 521//1347
f 521//1348 3392//1348 3393//1348
f 528//1345 3391//1345 535//1345
f 532//1342 821//1342 3394//1342
f 532//1343 3394//1343 528//1343
f 528//1344 3394//1344 3391//1344
f 521//1349 3393//1349 518//1349
f 525//1354 3380//1354 540//1354
f 545//1358 775//1358 884//1358
f 545//1359 884//1359 881//1359
f 527//1353 3380//1353 525//1353
f 518//1350 3393//1350 3395//1350
f 518//1351 3395//1351 527//1351
f 527//1352 3395//1352 3380//1352
f 3396//1576 3397//1576 3398//1576
f 3398//1577 3397//1577 3399//1577
f 3398//1578 3399//1578 3400//1578
f 3396//1575 3401//1575 3397//1575
f 596//1572 3402//1572 610//1572
f 610//1573 3402//1573 3401//1573
f 610//1574 3401//1574 3396//1574
f 3400//1579 3399//1579 3403//1579
f 3404//1584 3405//1584 601//1584
f 601//1585 3405//1585 3406//1585
f 601//1586 3406//1586 417//1586
f 3404//1583 3407//1583 3405//1583
f 3400//1580 3403//1580 3408//1580
f 3408//1581 3403//1581 3407//1581
f 3408//1582 3407//1582 3404//1582
f 596//1571 3409//1571 3402//1571
f 582//1560 3410//1560 584//1560
f 584//1561 3410//1561 3411//1561
f 584//1562 3411//1562 579//1562
f 582//1559 3412//1559 3410//1559
f 593//1556 3413//1556 581//1556
f 581//1557 3413//1557 3412//1557
f 581//1558 3412//1558 582//1558
f 579//1563 3411//1563 3414//1563
f 585//1568 3415//1568 586//1568
f 586//1569 3415//1569 3409//1569
f 586//1570 3409//1570 596//1570
f 585//1567 3416//1567 3415//1567
f 579//1564 3414//1564 587//1564
f 587//1565 3414//1565 3416//1565
f 587//1566 3416//1566 585//1566
f 442//1607 3417//1607 3418//1607
f 442//1608 3418//1608 443//1608
f 443//1609 3418//1609 3419//1609
f 448//1606 3417//1606 442//1606
f 447//1603 3420//1603 3421//1603
f 447//1604 3421//1604 448//1604
f 448//1605 3421//1605 3417//1605
f 443//1610 3419//1610 445//1610
f 456//1615 3422//1615 457//1615
f 457//1616 3422//1616 3423//1616
f 457//1617 3423//1617 459//1617
f 456//1614 3424//1614 3422//1614
f 3419//1611 3425//1611 445//1611
f 445//1612 3425//1612 3424//1612
f 445//1613 3424//1613 456//1613
f 468//1602 3420//1602 447//1602
f 420//1591 3426//1591 3427//1591
f 420//1592 3427//1592 464//1592
f 464//1593 3427//1593 3428//1593
f 418//1590 3426//1590 420//1590
f 417//1587 3406//1587 3429//1587
f 417//1588 3429//1588 418//1588
f 418//1589 3429//1589 3426//1589
f 464//1594 3428//1594 466//1594
f 470//1599 3430//1599 3431//1599
f 470//1600 3431//1600 468//1600
f 468//1601 3431//1601 3420//1601
f 462//1598 3430//1598 470//1598
f 466//1595 3428//1595 3432//1595
f 466//1596 3432//1596 462//1596
f 462//1597 3432//1597 3430//1597
f 738//1462 3433//1462 739//1462
f 739//1463 3433//1463 3434//1463
f 739//1464 3434//1464 740//1464
f 738//1461 3435//1461 3433//1461
f 731//1458 3436//1458 741//1458
f 741//1459 3436//1459 3435//1459
f 741//1460 3435//1460 738//1460
f 740//1465 3434//1465 3437//1465
f 743//1470 3438//1470 734//1470
f 734//1471 3438//1471 3439//1471
f 734//1472 3439//1472 735//1472
f 743//1469 3440//1469 3438//1469
f 740//1466 3437//1466 742//1466
f 742//1467 3437//1467 3440//1467
f 742//1468 3440//1468 743//1468
f 731//1457 3441//1457 3436//1457
f 3442//1446 3443//1446 3444//1446
f 3444//1447 3443//1447 3445//1447
f 3444//1448 3445//1448 713//1448
f 3442//1445 3446//1445 3443//1445
f 3365//1442 3366//1442 3447//1442
f 3447//1443 3366//1443 3446//1443
f 3447//1444 3446//1444 3442//1444
f 713//1449 3445//1449 3448//1449
f 715//1454 3449//1454 716//1454
f 716//1455 3449//1455 3441//1455
f 716//1456 3441//1456 731//1456
f 715//1453 3450//1453 3449//1453
f 713//1450 3448//1450 717//1450
f 717//1451 3448//1451 3450//1451
f 717//1452 3450//1452 715//1452
f 549//1522 743//1522 548//1522
f 548//1523 743//1523 734//1523
f 548//1524 734//1524 531//1524
f 551//1513 741//1513 738//1513
f 565//1500 3444//1500 567//1500
f 567//1501 3444//1501 713//1501
f 557//1512 741//1512 551//1512
f 491//1540 492//1540 493//1540
f 595//1553 3451//1553 3452//1553
f 595//1554 3452//1554 593//1554
f 593//1555 3452//1555 3413//1555
f 3453//1552 3451//1552 595//1552
f 493//1541 492//1541 485//1541
f 590//1550 3454//1550 3453//1550
f 3453//1551 3454//1551 3451//1551
f 565//1499 3442//1499 3444//1499
f 733//1477 3455//1477 3356//1477
f 494//1478 496//1478 541//1478
f 541//1479 496//1479 730//1479
f 732//1476 3455//1476 733//1476
f 735//1473 3439//1473 3456//1473
f 735//1474 3456//1474 732//1474
f 732//1475 3456//1475 3455//1475
f 529//1491 712//1491 3362//1491
f 533//1496 3447//1496 570//1496
f 570//1497 3447//1497 3442//1497
f 570//1498 3442//1498 565//1498
f 533//1495 3365//1495 3447//1495
f 529//1492 3362//1492 530//1492
f 530//1493 3362//1493 3365//1493
f 530//1494 3365//1494 533//1494
f 522//1310 3457//1310 3387//1310
f 3458//1135 2609//1135 2190//1135
f 2190//1136 2609//1136 2233//1136
f 2190//1137 2233//1137 2213//1137
f 3459//1134 3460//1134 2145//1134
f 2143//1131 3461//1131 2119//1131
f 2119//1132 3461//1132 2145//1132
f 2119//1133 2145//1133 2123//1133
f 2213//1138 2233//1138 2232//1138
f 3462//1143 3463//1143 2190//1143
f 2240//1144 2208//1144 2241//1144
f 2241//1145 2208//1145 2213//1145
f 2119//1142 2096//1142 2114//1142
f 2213//1139 2232//1139 2236//1139
f 2123//1140 2122//1140 2119//1140
f 2119//1141 2122//1141 2096//1141
f 2213//1130 2178//1130 2190//1130
f 1883//1119 2006//1119 1882//1119
f 1884//1120 1891//1120 1886//1120
f 1886//1121 1891//1121 1887//1121
f 2015//1118 2006//1118 1883//1118
f 1834//1115 1833//1115 3464//1115
f 3464//1116 1833//1116 1900//1116
f 3464//1117 1900//1117 1901//1117
f 1890//1122 1889//1122 3465//1122
f 3466//1127 1877//1127 1878//1127
f 1910//1128 1911//1128 1873//1128
f 1873//1129 1911//1129 1874//1129
f 3466//1126 1865//1126 1877//1126
f 3465//1123 1889//1123 1866//1123
f 3465//1124 1866//1124 1879//1124
f 1864//1125 1865//1125 3466//1125
f 3467//1166 3468//1166 3458//1166
f 3458//1167 3468//1167 3469//1167
f 3463//1168 3470//1168 2190//1168
f 2145//1165 3471//1165 3459//1165
f 3461//1162 3472//1162 3473//1162
f 3461//1163 3473//1163 2145//1163
f 2145//1164 3473//1164 3471//1164
f 2190//1169 3470//1169 3474//1169
f 3458//1174 3475//1174 3476//1174
f 3458//1175 3476//1175 3477//1175
f 3477//1176 3476//1176 3478//1176
f 3469//1173 3475//1173 3458//1173
f 2190//1170 3474//1170 3458//1170
f 3458//1171 3474//1171 3479//1171
f 3458//1172 3479//1172 3467//1172
f 3480//1161 3472//1161 3461//1161
f 2085//1150 2119//1150 2062//1150
f 2062//1151 2119//1151 2114//1151
f 3481//1152 3482//1152 3461//1152
f 2085//1149 2116//1149 2119//1149
f 2241//1146 2213//1146 2235//1146
f 2235//1147 2213//1147 2236//1147
f 2117//1148 2116//1148 2085//1148
f 3461//1153 3482//1153 3480//1153
f 2147//1158 3483//1158 2190//1158
f 2190//1159 3483//1159 3484//1159
f 2190//1160 3484//1160 3462//1160
f 2147//1157 3485//1157 3483//1157
f 3460//1154 3486//1154 2145//1154
f 2145//1155 3486//1155 3485//1155
f 2145//1156 3485//1156 2147//1156
f 3487//1073 3488//1073 3489//1073
f 3487//1074 3489//1074 3490//1074
f 3490//1075 3489//1075 3491//1075
f 3492//1072 3488//1072 3487//1072
f 2018//1069 3493//1069 1857//1069
f 1904//1070 3494//1070 3492//1070
f 3492//1071 3494//1071 3488//1071
f 3490//1076 3491//1076 3495//1076
f 3496//1081 3497//1081 1983//1081
f 3496//1082 1983//1082 1980//1082
f 3498//1083 3499//1083 3500//1083
f 3501//1080 3497//1080 3496//1080
f 3495//1077 3491//1077 3502//1077
f 3495//1078 3502//1078 3501//1078
f 3501//1079 3502//1079 3497//1079
f 2017//1068 3493//1068 2018//1068
f 3503//1057 3498//1057 3500//1057
f 3503//1058 3500//1058 3504//1058
f 3504//1059 3500//1059 3505//1059
f 3506//1056 3498//1056 3503//1056
f 3507//1053 3508//1053 3509//1053
f 3507//1054 3509//1054 3423//1054
f 3423//1055 3509//1055 3510//1055
f 3504//1060 3505//1060 3511//1060
f 1954//1065 3512//1065 1929//1065
f 2016//1066 3513//1066 2017//1066
f 2017//1067 3513//1067 3493//1067
f 3514//1064 3512//1064 1954//1064
f 3511//1061 3505//1061 3515//1061
f 3511//1062 3515//1062 3514//1062
f 3514//1063 3515//1063 3512//1063
f 1839//1104 2001//1104 1831//1104
f 1837//1105 1829//1105 3516//1105
f 3516//1106 1829//1106 1826//1106
f 2002//1103 2001//1103 1839//1103
f 3506//1100 3517//1100 3498//1100
f 3498//1101 3517//1101 3518//1101
f 3498//1102 3518//1102 3519//1102
f 3516//1107 1826//1107 1832//1107
f 1914//1112 1906//1112 3520//1112
f 3520//1113 1906//1113 2040//1113
f 3520//1114 2040//1114 2011//1114
f 3521//1111 1908//1111 1915//1111
f 1896//1108 1897//1108 3522//1108
f 3522//1109 1897//1109 1908//1109
f 3522//1110 1908//1110 3521//1110
f 3506//1099 3523//1099 3517//1099
f 3515//1088 3524//1088 3525//1088
f 3515//1089 3525//1089 3526//1089
f 3526//1090 3525//1090 2527//1090
f 3505//1087 3524//1087 3515//1087
f 3500//1084 3499//1084 3527//1084
f 3500//1085 3527//1085 3505//1085
f 3505//1086 3527//1086 3524//1086
f 3526//1091 2527//1091 2526//1091
f 3513//1096 3528//1096 3493//1096
f 3493//1097 3528//1097 3523//1097
f 3493//1098 3523//1098 3506//1098
f 3513//1095 3529//1095 3528//1095
f 1883//1092 1881//1092 2016//1092
f 2016//1093 1881//1093 3529//1093
f 2016//1094 3529//1094 3513//1094
f 439//1260 3530//1260 1085//1260
f 439//1261 1085//1261 434//1261
f 434//1262 1085//1262 3531//1262
f 428//1259 3530//1259 439//1259
f 430//1256 3532//1256 3533//1256
f 430//1257 3533//1257 428//1257
f 428//1258 3533//1258 3530//1258
f 434//1263 3531//1263 437//1263
f 415//1268 3534//1268 3535//1268
f 415//1269 3535//1269 407//1269
f 407//1270 3535//1270 3536//1270
f 412//1267 3534//1267 415//1267
f 437//1264 3531//1264 3537//1264
f 437//1265 3537//1265 412//1265
f 412//1266 3537//1266 3534//1266
f 432//1255 3532//1255 430//1255
f 506//1244 3538//1244 502//1244
f 449//1245 890//1245 591//1245
f 591//1246 890//1246 3539//1246
f 504//1243 3538//1243 506//1243
f 505//1240 3540//1240 3541//1240
f 505//1241 3541//1241 504//1241
f 504//1242 3541//1242 3538//1242
f 591//1247 3539//1247 3542//1247
f 507//1252 3543//1252 3544//1252
f 507//1253 3544//1253 432//1253
f 432//1254 3544//1254 3532//1254
f 511//1251 3543//1251 507//1251
f 3545//1248 181//1248 451//1248
f 622//1249 3546//1249 511//1249
f 511//1250 3546//1250 3543//1250
f 605//1299 3547//1299 600//1299
f 600//1300 3547//1300 3548//1300
f 600//1301 3548//1301 597//1301
f 605//1298 3549//1298 3547//1298
f 538//1295 3550//1295 603//1295
f 603//1296 3550//1296 3549//1296
f 603//1297 3549//1297 605//1297
f 597//1302 3548//1302 3551//1302
f 544//1307 3552//1307 524//1307
f 524//1308 3552//1308 3457//1308
f 524//1309 3457//1309 522//1309
f 544//1306 830//1306 3552//1306
f 597//1303 3551//1303 602//1303
f 602//1304 3551//1304 830//1304
f 602//1305 830//1305 544//1305
f 538//1294 3553//1294 3550//1294
f 449//1278 643//1278 888//1278
f 449//1279 888//1279 890//1279
f 70//1280 642//1280 502//1280
f 421//1274 3545//1274 451//1274
f 407//1271 3536//1271 410//1271
f 410//1272 3536//1272 3545//1272
f 410//1273 3545//1273 421//1273
f 1171//1286 1168//1286 776//1286
f 536//1291 3554//1291 539//1291
f 539//1292 3554//1292 3553//1292
f 539//1293 3553//1293 538//1293
f 536//1290 3379//1290 3554//1290
f 573//1287 553//1287 768//1287
f 3389//1288 1171//1288 556//1288
f 537//1289 3379//1289 536//1289
f 3555//1197 3556//1197 3557//1197
f 3557//1198 3556//1198 3558//1198
f 3557//1199 3558//1199 3559//1199
f 3555//1196 3560//1196 3556//1196
f 645//1193 3561//1193 629//1193
f 629//1194 3561//1194 3560//1194
f 629//1195 3560//1195 3555//1195
f 3559//1200 3558//1200 3562//1200
f 70//1206 72//1206 642//1206
f 511//1207 503//1207 622//1207
f 3538//1208 70//1208 502//1208
f 618//1204 770//1204 616//1204
f 3559//1201 3562//1201 3461//1201
f 3461//1202 3562//1202 3563//1202
f 3461//1203 3563//1203 3481//1203
f 645//1192 3564//1192 3561//1192
f 3565//1181 3566//1181 662//1181
f 662//1182 3566//1182 3567//1182
f 662//1183 3567//1183 663//1183
f 3565//1180 3568//1180 3566//1180
f 3477//1177 3478//1177 3569//1177
f 3569//1178 3478//1178 3568//1178
f 3569//1179 3568//1179 3565//1179
f 663//1184 3567//1184 3570//1184
f 656//1189 3571//1189 657//1189
f 657//1190 3571//1190 3564//1190
f 657//1191 3564//1191 645//1191
f 656//1188 3572//1188 3571//1188
f 663//1185 3570//1185 659//1185
f 659//1186 3570//1186 3572//1186
f 659//1187 3572//1187 656//1187
f 479//1229 3573//1229 481//1229
f 481//1230 3573//1230 3574//1230
f 481//1231 3574//1231 513//1231
f 479//1228 3575//1228 3573//1228
f 478//1225 1094//1225 480//1225
f 480//1226 1094//1226 3575//1226
f 480//1227 3575//1227 479//1227
f 513//1232 3574//1232 3576//1232
f 517//1237 3577//1237 516//1237
f 516//1238 3577//1238 3540//1238
f 516//1239 3540//1239 505//1239
f 517//1236 3578//1236 3577//1236
f 513//1233 3576//1233 515//1233
f 515//1234 3576//1234 3578//1234
f 515//1235 3578//1235 517//1235
f 478//1224 3579//1224 1094//1224
f 575//1213 3580//1213 607//1213
f 607//1214 3580//1214 3581//1214
f 607//1215 3581//1215 606//1215
f 575//1212 3582//1212 3580//1212
f 591//1209 3542//1209 577//1209
f 577//1210 3542//1210 3582//1210
f 577//1211 3582//1211 575//1211
f 606//1216 3581//1216 3583//1216
f 612//1221 3584//1221 611//1221
f 611//1222 3584//1222 3579//1222
f 611//1223 3579//1223 478//1223
f 612//1220 3585//1220 3584//1220
f 606//1217 3583//1217 609//1217
f 609//1218 3583//1218 3585//1218
f 609//1219 3585//1219 612//1219
f 459//1618 3423//1618 3510//1618
f 3586//2136 3587//2136 3588//2136
f 3588//2137 3587//2137 3589//2137
f 3588//2138 3589//2138 3590//2138
f 3586//2135 3591//2135 3587//2135
f 3592//2132 3593//2132 3594//2132
f 3594//2133 3593//2133 3591//2133
f 3594//2134 3591//2134 3586//2134
f 3590//2139 3589//2139 3595//2139
f 3596//2144 3597//2144 3598//2144
f 3598//2145 3597//2145 3599//2145
f 3598//2146 3599//2146 3600//2146
f 3596//2143 3601//2143 3597//2143
f 3590//2140 3595//2140 3602//2140
f 3602//2141 3595//2141 3601//2141
f 3602//2142 3601//2142 3596//2142
f 3592//2131 3603//2131 3593//2131
f 3604//2120 3605//2120 3606//2120
f 3606//2121 3605//2121 3607//2121
f 3606//2122 3607//2122 3608//2122
f 3604//2119 3609//2119 3605//2119
f 3610//2116 3611//2116 3612//2116
f 3612//2117 3611//2117 3609//2117
f 3612//2118 3609//2118 3604//2118
f 3608//2123 3607//2123 3613//2123
f 3614//2128 3615//2128 3616//2128
f 3616//2129 3615//2129 3603//2129
f 3616//2130 3603//2130 3592//2130
f 3614//2127 3617//2127 3615//2127
f 3608//2124 3613//2124 3618//2124
f 3618//2125 3613//2125 3617//2125
f 3618//2126 3617//2126 3614//2126
f 3619//2201 3620//2201 3621//2201
f 3619//2202 3621//2202 3622//2202
f 3622//2203 3621//2203 3623//2203
f 3624//2200 3620//2200 3619//2200
f 3625//2197 3626//2197 3627//2197
f 3625//2198 3627//2198 3624//2198
f 3624//2199 3627//2199 3620//2199
f 3622//2204 3623//2204 3628//2204
f 3629//2209 3630//2209 3631//2209
f 3629//2210 3631//2210 3632//2210
f 3632//2211 3631//2211 3633//2211
f 3634//2208 3630//2208 3629//2208
f 3628//2205 3623//2205 3635//2205
f 3628//2206 3635//2206 3634//2206
f 3634//2207 3635//2207 3630//2207
f 3636//2196 3626//2196 3625//2196
f 3637//2185 3638//2185 3639//2185
f 3637//2186 3639//2186 3640//2186
f 3640//2187 3639//2187 3641//2187
f 3642//2184 3638//2184 3637//2184
f 3600//2147 3599//2147 3643//2147
f 3644//2182 3645//2182 3642//2182
f 3642//2183 3645//2183 3638//2183
f 3640//2188 3641//2188 3646//2188
f 3647//2193 3648//2193 3649//2193
f 3647//2194 3649//2194 3636//2194
f 3636//2195 3649//2195 3626//2195
f 3650//2192 3648//2192 3647//2192
f 3646//2189 3641//2189 3651//2189
f 3646//2190 3651//2190 3650//2190
f 3650//2191 3651//2191 3648//2191
f 3652//2074 3653//2074 3654//2074
f 3654//2075 3653//2075 3655//2075
f 3600//2076 3643//2076 3656//2076
f 3652//2073 3657//2073 3653//2073
f 674//2070 3658//2070 3659//2070
f 3659//2071 3658//2071 3657//2071
f 3659//2072 3657//2072 3652//2072
f 3656//2077 3643//2077 3660//2077
f 3661//2082 3662//2082 3663//2082
f 3663//2083 3662//2083 3664//2083
f 3663//2084 3664//2084 3665//2084
f 3661//2081 3666//2081 3662//2081
f 3656//2078 3660//2078 3667//2078
f 3667//2079 3660//2079 3666//2079
f 3667//2080 3666//2080 3661//2080
f 674//2069 673//2069 3658//2069
f 3655//2046 3668//2046 3669//2046
f 3669//2047 3670//2047 3655//2047
f 3655//2048 3670//2048 3671//2048
f 3655//2045 3672//2045 3668//2045
f 3673//2042 3674//2042 3675//2042
f 3673//2043 3675//2043 3676//2043
f 3653//2044 3672//2044 3655//2044
f 3655//2049 3671//2049 3677//2049
f 3678//2054 3679//2054 3680//2054
f 3680//2055 3679//2055 691//2055
f 3680//2056 691//2056 690//2056
f 3678//2053 3681//2053 3679//2053
f 3675//2050 3682//2050 3676//2050
f 3676//2051 3682//2051 3681//2051
f 3676//2052 3681//2052 3678//2052
f 3683//2105 3684//2105 3685//2105
f 3683//2106 3685//2106 3686//2106
f 3686//2107 3685//2107 3687//2107
f 3688//2104 3684//2104 3683//2104
f 3689//2101 3690//2101 3691//2101
f 3689//2102 3691//2102 3688//2102
f 3688//2103 3691//2103 3684//2103
f 3686//2108 3687//2108 3692//2108
f 3693//2113 3694//2113 3695//2113
f 3693//2114 3695//2114 3610//2114
f 3610//2115 3695//2115 3611//2115
f 3696//2112 3694//2112 3693//2112
f 3692//2109 3687//2109 3697//2109
f 3692//2110 3697//2110 3696//2110
f 3696//2111 3697//2111 3694//2111
f 3698//2100 3690//2100 3689//2100
f 3699//2089 3700//2089 3701//2089
f 3699//2090 3701//2090 3702//2090
f 3702//2091 3701//2091 3703//2091
f 3704//2088 3700//2088 3699//2088
f 3665//2085 3664//2085 3705//2085
f 3665//2086 3705//2086 3704//2086
f 3704//2087 3705//2087 3700//2087
f 3702//2092 3703//2092 3706//2092
f 3707//2097 3708//2097 3709//2097
f 3707//2098 3709//2098 3698//2098
f 3698//2099 3709//2099 3690//2099
f 3710//2096 3708//2096 3707//2096
f 3706//2093 3703//2093 3711//2093
f 3706//2094 3711//2094 3710//2094
f 3710//2095 3711//2095 3708//2095
f 3712//2294 3713//2294 3714//2294
f 3714//2295 3713//2295 3715//2295
f 3714//2296 3715//2296 3716//2296
f 3712//2293 3717//2293 3713//2293
f 3718//2290 3719//2290 3720//2290
f 3720//2291 3719//2291 3717//2291
f 3720//2292 3717//2292 3712//2292
f 3716//2297 3715//2297 3721//2297
f 3722//2302 3723//2302 3724//2302
f 3724//2303 3723//2303 3725//2303
f 3724//2304 3725//2304 3726//2304
f 3722//2301 3727//2301 3723//2301
f 3716//2298 3721//2298 3728//2298
f 3728//2299 3721//2299 3727//2299
f 3728//2300 3727//2300 3722//2300
f 3718//2289 3729//2289 3719//2289
f 3635//2278 3730//2278 3630//2278
f 3630//2279 3730//2279 3731//2279
f 3630//2280 3731//2280 3631//2280
f 3635//2277 3732//2277 3730//2277
f 3621//2274 3733//2274 3623//2274
f 3623//2275 3733//2275 3732//2275
f 3623//2276 3732//2276 3635//2276
f 3631//2281 3731//2281 3734//2281
f 3735//2286 3736//2286 3737//2286
f 3737//2287 3736//2287 3729//2287
f 3737//2288 3729//2288 3718//2288
f 3735//2285 3738//2285 3736//2285
f 3631//2282 3734//2282 3633//2282
f 3633//2283 3734//2283 3738//2283
f 3633//2284 3738//2284 3735//2284
f 3645//2325 3739//2325 3740//2325
f 3740//2326 3741//2326 3742//2326
f 3742//2327 3741//2327 3743//2327
f 3744//2324 3739//2324 3645//2324
f 3745//2321 3746//2321 3747//2321
f 3745//2322 3747//2322 3744//2322
f 3744//2323 3747//2323 3739//2323
f 3742//2328 3743//2328 3748//2328
f 3749//2333 3750//2333 3751//2333
f 3749//2334 3751//2334 3752//2334
f 3752//2335 3751//2335 3753//2335
f 3754//2332 3750//2332 3749//2332
f 3748//2329 3743//2329 3755//2329
f 3748//2330 3755//2330 3754//2330
f 3754//2331 3755//2331 3750//2331
f 3756//2320 3746//2320 3745//2320
f 3757//2309 3758//2309 3759//2309
f 3757//2310 3759//2310 3760//2310
f 3760//2311 3759//2311 3761//2311
f 3762//2308 3758//2308 3757//2308
f 3726//2305 3725//2305 3763//2305
f 3726//2306 3763//2306 3762//2306
f 3762//2307 3763//2307 3758//2307
f 3760//2312 3761//2312 3764//2312
f 3765//2317 3766//2317 3767//2317
f 3765//2318 3767//2318 3756//2318
f 3756//2319 3767//2319 3746//2319
f 3768//2316 3766//2316 3765//2316
f 3764//2313 3761//2313 3769//2313
f 3764//2314 3769//2314 3768//2314
f 3768//2315 3769//2315 3766//2315
f 3770//2232 3724//2232 3771//2232
f 3771//2233 3724//2233 3726//2233
f 3771//2234 3726//2234 3772//2234
f 3770//2231 3722//2231 3724//2231
f 3773//2228 3728//2228 3774//2228
f 3774//2229 3728//2229 3722//2229
f 3774//2230 3722//2230 3770//2230
f 3772//2235 3726//2235 3762//2235
f 3775//2240 3760//2240 3776//2240
f 3776//2241 3760//2241 3764//2241
f 3776//2242 3764//2242 3777//2242
f 3775//2239 3757//2239 3760//2239
f 3772//2236 3762//2236 3778//2236
f 3778//2237 3762//2237 3757//2237
f 3778//2238 3757//2238 3775//2238
f 3773//2227 3716//2227 3728//2227
f 3779//2216 3737//2216 3780//2216
f 3780//2217 3737//2217 3718//2217
f 3780//2218 3718//2218 3781//2218
f 3779//2215 3735//2215 3737//2215
f 3632//2212 3633//2212 3782//2212
f 3782//2213 3633//2213 3735//2213
f 3782//2214 3735//2214 3779//2214
f 3781//2219 3718//2219 3720//2219
f 3783//2224 3714//2224 3784//2224
f 3784//2225 3714//2225 3716//2225
f 3784//2226 3716//2226 3773//2226
f 3783//2223 3712//2223 3714//2223
f 3781//2220 3720//2220 3785//2220
f 3785//2221 3720//2221 3712//2221
f 3785//2222 3712//2222 3783//2222
f 3648//2263 3749//2263 3752//2263
f 3648//2264 3752//2264 3649//2264
f 3649//2265 3752//2265 3786//2265
f 3651//2262 3749//2262 3648//2262
f 3641//2259 3748//2259 3754//2259
f 3641//2260 3754//2260 3651//2260
f 3651//2261 3754//2261 3749//2261
f 3649//2266 3786//2266 3626//2266
f 3620//2271 3787//2271 3788//2271
f 3620//2272 3788//2272 3621//2272
f 3621//2273 3788//2273 3733//2273
f 3627//2270 3787//2270 3620//2270
f 3626//2267 3786//2267 3789//2267
f 3626//2268 3789//2268 3627//2268
f 3627//2269 3789//2269 3787//2269
f 3639//2258 3748//2258 3641//2258
f 3790//2247 3765//2247 3756//2247
f 3790//2248 3756//2248 3791//2248
f 3791//2249 3756//2249 3745//2249
f 3792//2246 3765//2246 3790//2246
f 3777//2243 3764//2243 3768//2243
f 3777//2244 3768//2244 3792//2244
f 3792//2245 3768//2245 3765//2245
f 3791//2250 3745//2250 3793//2250
f 3638//2255 3740//2255 3742//2255
f 3638//2256 3742//2256 3639//2256
f 3639//2257 3742//2257 3748//2257
f 3645//2254 3740//2254 3638//2254
f 3793//2251 3745//2251 3744//2251
f 3793//2252 3744//2252 3644//2252
f 3644//2253 3744//2253 3645//2253
f 3794//2041 3674//2041 3673//2041
f 3795//1875 3619//1875 3655//1875
f 3655//1876 3619//1876 3622//1876
f 2521//1877 2520//1877 3636//1877
f 2644//1874 2874//1874 2872//1874
f 2644//1871 2875//1871 2886//1871
f 2630//1872 2824//1872 2644//1872
f 2644//1873 2824//1873 2874//1873
f 3636//1878 2520//1878 3647//1878
f 2525//1883 2542//1883 3646//1883
f 3646//1884 2542//1884 2544//1884
f 3646//1885 2544//1885 3640//1885
f 3650//1882 2525//1882 3646//1882
f 3647//1879 2520//1879 2523//1879
f 3647//1880 2523//1880 3650//1880
f 3650//1881 2523//1881 2525//1881
f 2644//1870 2876//1870 2875//1870
f 2939//1859 3043//1859 2938//1859
f 2938//1860 3043//1860 499//1860
f 2926//1861 2827//1861 2927//1861
f 2939//1858 2934//1858 3043//1858
f 2826//1855 2833//1855 2827//1855
f 2827//1856 2833//1856 2832//1856
f 2936//1857 2934//1857 2939//1857
f 2927//1862 2827//1862 2832//1862
f 2937//1867 2934//1867 2935//1867
f 2872//1868 2873//1868 2644//1868
f 2873//1869 2876//1869 2644//1869
f 2937//1866 3043//1866 2934//1866
f 2927//1863 2832//1863 2930//1863
f 2930//1864 2832//1864 3043//1864
f 2930//1865 3043//1865 2937//1865
f 3624//1906 2521//1906 3625//1906
f 3625//1907 2521//1907 3636//1907
f 2476//1908 2653//1908 3774//1908
f 3624//1905 2561//1905 2521//1905
f 3795//1902 3796//1902 3619//1902
f 3619//1903 3796//1903 2561//1903
f 3619//1904 2561//1904 3624//1904
f 3774//1909 2653//1909 3797//1909
f 2474//1914 3772//1914 2477//1914
f 2477//1915 3772//1915 3778//1915
f 2477//1916 3778//1916 2509//1916
f 2474//1913 3771//1913 3772//1913
f 3774//1910 3770//1910 2476//1910
f 2476//1911 3770//1911 3771//1911
f 2476//1912 3771//1912 2474//1912
f 3792//1901 2507//1901 3777//1901
f 3642//1890 2539//1890 2536//1890
f 3642//1891 2536//1891 3644//1891
f 3644//1892 2536//1892 2535//1892
f 3637//1889 2539//1889 3642//1889
f 3640//1886 2544//1886 2547//1886
f 3640//1887 2547//1887 3637//1887
f 3637//1888 2547//1888 2539//1888
f 3644//1893 2535//1893 3793//1893
f 3790//1898 2511//1898 2514//1898
f 3790//1899 2514//1899 3792//1899
f 3792//1900 2514//1900 2507//1900
f 3791//1897 2511//1897 3790//1897
f 3793//1894 2535//1894 2512//1894
f 3793//1895 2512//1895 3791//1895
f 3791//1896 2512//1896 2511//1896
f 435//1686 3398//1686 436//1686
f 436//1687 3398//1687 3400//1687
f 436//1688 3400//1688 438//1688
f 435//1685 3396//1685 3398//1685
f 413//1682 610//1682 414//1682
f 414//1683 610//1683 3396//1683
f 414//1684 3396//1684 435//1684
f 438//1689 3400//1689 3408//1689
f 605//1702 491//1702 603//1702
f 538//1705 493//1705 484//1705
f 537//1712 494//1712 545//1712
f 431//1693 3404//1693 601//1693
f 438//1690 3408//1690 429//1690
f 429//1691 3408//1691 3404//1691
f 429//1692 3404//1692 431//1692
f 450//1671 584//1671 579//1671
f 590//1623 3798//1623 3454//1623
f 465//1632 466//1632 461//1632
f 461//1633 466//1633 462//1633
f 590//1622 3799//1622 3798//1622
f 459//1619 3510//1619 589//1619
f 589//1620 3510//1620 3799//1620
f 589//1621 3799//1621 590//1621
f 446//1642 448//1642 514//1642
f 608//1660 3453//1660 594//1660
f 594//1661 3453//1661 595//1661
f 583//1670 584//1670 450//1670
f 608//1659 590//1659 3453//1659
f 514//1643 448//1643 442//1643
f 514//1644 442//1644 441//1644
f 588//1658 590//1658 608//1658
f 1481//1840 1412//1840 2532//1840
f 2532//1841 1412//1841 1411//1841
f 2532//1842 1411//1842 1432//1842
f 517//1827 469//1827 446//1827
f 432//1809 431//1809 423//1809
f 502//1818 465//1818 506//1818
f 506//1819 465//1819 461//1819
f 2951//1843 2510//1843 1449//1843
f 499//1850 3043//1850 3045//1850
f 3039//1851 497//1851 744//1851
f 745//1854 499//1854 3045//1854
f 2832//1847 2940//1847 3043//1847
f 1449//1844 2510//1844 2532//1844
f 1449//1845 2532//1845 1436//1845
f 1436//1846 2532//1846 1432//1846
f 439//1804 438//1804 428//1804
f 568//1737 567//1737 574//1737
f 556//1746 557//1746 550//1746
f 550//1747 557//1747 551//1747
f 532//1730 533//1730 569//1730
f 545//1713 494//1713 541//1713
f 521//1723 520//1723 534//1723
f 528//1728 530//1728 532//1728
f 562//1755 561//1755 549//1755
f 449//1785 583//1785 450//1785
f 415//1795 409//1795 413//1795
f 412//1798 414//1798 437//1798
f 591//1784 583//1784 449//1784
f 562//1756 549//1756 547//1756
f 609//1774 608//1774 606//1774
f 607//1777 594//1777 578//1777
f 2494//1999 2972//1999 2493//1999
f 2493//2000 2972//2000 2973//2000
f 2493//2001 2973//2001 2495//2001
f 2494//1998 2970//1998 2972//1998
f 2499//1995 2969//1995 2497//1995
f 2497//1996 2969//1996 2970//1996
f 2497//1997 2970//1997 2494//1997
f 2495//2002 2973//2002 2990//2002
f 2504//2007 2991//2007 2503//2007
f 2503//2008 2991//2008 2993//2008
f 2503//2009 2993//2009 2501//2009
f 2504//2006 2989//2006 2991//2006
f 2495//2003 2990//2003 2496//2003
f 2496//2004 2990//2004 2989//2004
f 2496//2005 2989//2005 2504//2005
f 2499//1994 2965//1994 2969//1994
f 2813//1983 2740//1983 2815//1983
f 2815//1984 2740//1984 2742//1984
f 2815//1985 2742//1985 2821//1985
f 2813//1982 2736//1982 2740//1982
f 2806//1979 2737//1979 2807//1979
f 2807//1980 2737//1980 2736//1980
f 2807//1981 2736//1981 2813//1981
f 2821//1986 2742//1986 2741//1986
f 2513//1991 2968//1991 2498//1991
f 2498//1992 2968//1992 2965//1992
f 2498//1993 2965//1993 2499//1993
f 2513//1990 2951//1990 2968//1990
f 2821//1987 2741//1987 2820//1987
f 2820//1988 2741//1988 2437//1988
f 2510//1989 2951//1989 2513//1989
f 3679//2030 3657//2030 3658//2030
f 3679//2031 3658//2031 691//2031
f 691//2032 3658//2032 673//2032
f 3681//2029 3657//2029 3679//2029
f 3682//2026 3672//2026 3653//2026
f 3682//2027 3653//2027 3681//2027
f 3681//2028 3653//2028 3657//2028
f 691//2033 673//2033 692//2033
f 3800//2038 3801//2038 3673//2038
f 3673//2039 3801//2039 3802//2039
f 3673//2040 3802//2040 3794//2040
f 695//2037 668//2037 708//2037
f 692//2034 673//2034 666//2034
f 692//2035 666//2035 695//2035
f 695//2036 666//2036 668//2036
f 3675//2025 3672//2025 3682//2025
f 2502//2014 2984//2014 2981//2014
f 2502//2015 2981//2015 2433//2015
f 2433//2016 2981//2016 2980//2016
f 2500//2013 2984//2013 2502//2013
f 2501//2010 2993//2010 2992//2010
f 2501//2011 2992//2011 2500//2011
f 2500//2012 2992//2012 2984//2012
f 2433//2017 2980//2017 2431//2017
f 2438//2022 2988//2022 2987//2022
f 2438//2023 2987//2023 2437//2023
f 2437//2024 2987//2024 2820//2024
f 2434//2021 2988//2021 2438//2021
f 2431//2018 2980//2018 2986//2018
f 2431//2019 2986//2019 2434//2019
f 2434//2020 2986//2020 2988//2020
f 3780//1937 694//1937 696//1937
f 3780//1938 696//1938 3779//1938
f 3779//1939 696//1939 707//1939
f 3781//1936 694//1936 3780//1936
f 3785//1933 3680//1933 690//1933
f 3785//1934 690//1934 3781//1934
f 3781//1935 690//1935 694//1935
f 3779//1940 707//1940 3782//1940
f 3629//1945 667//1945 674//1945
f 3629//1946 674//1946 3634//1946
f 3634//1947 674//1947 3659//1947
f 3632//1944 667//1944 3629//1944
f 3782//1941 707//1941 665//1941
f 3782//1942 665//1942 3632//1942
f 3632//1943 665//1943 667//1943
f 3783//1932 3680//1932 3785//1932
f 2505//1921 3776//1921 3777//1921
f 2505//1922 3777//1922 2506//1922
f 2506//1923 3777//1923 2507//1923
f 2508//1920 3776//1920 2505//1920
f 2509//1917 3778//1917 3775//1917
f 2509//1918 3775//1918 2508//1918
f 2508//1919 3775//1919 3776//1919
f 3797//1924 3803//1924 3774//1924
f 3784//1929 3676//1929 3678//1929
f 3784//1930 3678//1930 3783//1930
f 3783//1931 3678//1931 3680//1931
f 3773//1928 3676//1928 3784//1928
f 3774//1925 3803//1925 3673//1925
f 3774//1926 3673//1926 3773//1926
f 3773//1927 3673//1927 3676//1927
f 2809//1968 2713//1968 2712//1968
f 2809//1969 2712//1969 2810//1969
f 2810//1970 2712//1970 2718//1970
f 2838//1967 2713//1967 2809//1967
f 2836//1964 2729//1964 2716//1964
f 2836//1965 2716//1965 2838//1965
f 2838//1966 2716//1966 2713//1966
f 2810//1971 2718//1971 2808//1971
f 2803//1976 2720//1976 2722//1976
f 2803//1977 2722//1977 2806//1977
f 2806//1978 2722//1978 2737//1978
f 2805//1975 2720//1975 2803//1975
f 2808//1972 2718//1972 2721//1972
f 2808//1973 2721//1973 2805//1973
f 2805//1974 2721//1974 2720//1974
f 2837//1963 2729//1963 2836//1963
f 3622//1952 3654//1952 3655//1952
f 2824//1953 2630//1953 2828//1953
f 2828//1954 2630//1954 2726//1954
f 3622//1951 3652//1951 3654//1951
f 3634//1948 3659//1948 3628//1948
f 3628//1949 3659//1949 3652//1949
f 3628//1950 3652//1950 3622//1950
f 2828//1955 2726//1955 2829//1955
f 2839//1960 2727//1960 2728//1960
f 2839//1961 2728//1961 2837//1961
f 2837//1962 2728//1962 2729//1962
f 2840//1959 2727//1959 2839//1959
f 2829//1956 2726//1956 2723//1956
f 2829//1957 2723//1957 2840//1957
f 2840//1958 2723//1958 2727//1958
f 823//387 3395//387 3393//387
f 823//388 3394//388 821//388
f 3375//389 3377//389 822//389
f 829//386 3385//386 3384//386
f 822//383 3376//383 3375//383
f 3390//384 3388//384 829//384
f 829//385 3388//385 3385//385
f 822//390 3377//390 876//390
f 3384//395 3386//395 829//395
f 829//396 3386//396 3387//396
f 829//397 3387//397 3457//397
f 3389//394 1169//394 1171//394
f 822//391 876//391 829//391
f 829//392 876//392 1169//392
f 829//393 1169//393 3389//393
f 822//382 3381//382 3376//382
f 1198//371 829//371 1196//371
f 1196//372 829//372 831//372
f 1196//373 831//373 1579//373
f 1198//370 3804//370 829//370
f 1579//367 1597//367 1582//367
f 1582//368 1597//368 3804//368
f 1582//369 3804//369 1198//369
f 1579//374 831//374 1597//374
f 831//379 883//379 823//379
f 823//380 883//380 1078//380
f 3383//381 3381//381 822//381
f 3389//378 3390//378 829//378
f 3377//375 768//375 876//375
f 3383//376 822//376 3382//376
f 3382//377 822//377 821//377
f 823//420 822//420 1410//420
f 823//421 1410//421 1405//421
f 1080//422 1396//422 1399//422
f 1079//418 1410//418 822//418
f 3550//415 3553//415 831//415
f 831//416 3553//416 3554//416
f 831//417 3554//417 3379//417
f 1405//423 1080//423 823//423
f 3805//428 1029//428 1028//428
f 3805//429 1028//429 822//429
f 822//430 1028//430 1396//430
f 1596//427 1029//427 3805//427
f 823//424 1080//424 1399//424
f 823//425 1399//425 1596//425
f 1596//426 1399//426 1029//426
f 883//414 3378//414 881//414
f 823//402 3392//402 3391//402
f 823//403 3391//403 3394//403
f 3457//404 3552//404 829//404
f 3393//401 3392//401 823//401
f 1078//398 623//398 823//398
f 823//399 623//399 3380//399
f 823//400 3380//400 3395//400
f 829//405 3552//405 830//405
f 831//411 3549//411 3550//411
f 831//412 3379//412 883//412
f 883//413 3379//413 3378//413
f 831//410 3547//410 3549//410
f 831//407 830//407 3551//407
f 831//408 3551//408 3548//408
f 3548//409 3547//409 831//409
f 843//318 1340//318 1349//318
f 1348//319 933//319 1349//319
f 1349//320 933//320 935//320
f 1365//317 1340//317 843//317
f 1303//314 1307//314 1306//314
f 1372//315 1336//315 1365//315
f 1365//316 1336//316 1340//316
f 1349//321 935//321 843//321
f 843//326 995//326 998//326
f 1000//327 990//327 998//327
f 998//328 990//328 1001//328
f 928//325 995//325 843//325
f 843//322 935//322 928//322
f 932//323 993//323 928//323
f 928//324 993//324 995//324
f 1305//313 1307//313 1303//313
f 1338//300 1329//300 1331//300
f 1338//301 1331//301 1131//301
f 1131//302 1331//302 1307//302
f 1337//299 1329//299 1338//299
f 920//296 1360//296 1362//296
f 920//297 1362//297 1131//297
f 1131//298 1362//298 1338//298
f 1283//303 1285//303 1309//303
f 1134//308 843//308 1130//308
f 1129//311 1131//311 1305//311
f 1305//312 1131//312 1307//312
f 1283//307 843//307 1134//307
f 1311//304 1365//304 843//304
f 1309//305 1311//305 1283//305
f 1283//306 1311//306 843//306
f 896//356 3806//356 1595//356
f 896//357 1595//357 1484//357
f 1484//358 1595//358 854//358
f 898//355 3806//355 896//355
f 1484//351 854//351 853//351
f 853//353 855//353 898//353
f 898//354 855//354 3806//354
f 1593//359 1595//359 1591//359
f 866//364 3804//364 1597//364
f 866//365 1597//365 1593//365
f 1593//366 1597//366 1595//366
f 868//363 3804//363 866//363
f 1591//360 1595//360 3806//360
f 1591//361 3806//361 868//361
f 868//362 3806//362 3804//362
f 856//349 845//349 857//349
f 785//333 843//333 787//333
f 787//334 843//334 839//334
f 787//335 839//335 780//335
f 785//332 1203//332 843//332
f 998//329 1001//329 843//329
f 843//330 1001//330 839//330
f 784//331 1203//331 785//331
f 780//336 839//336 841//336
f 858//344 854//344 842//344
f 842//345 854//345 843//345
f 844//348 845//348 856//348
f 858//343 873//343 854//343
f 857//337 845//337 860//337
f 860//338 845//338 855//338
f 861//341 854//341 873//341
f 2881//513 2884//513 1872//513
f 1872//514 2884//514 3299//514
f 1872//515 3299//515 2857//515
f 1872//512 2886//512 2888//512
f 3807//509 2629//509 2644//509
f 3807//510 2644//510 1872//510
f 1872//511 2644//511 2886//511
f 2857//516 2854//516 1872//516
f 1872//521 2889//521 2882//521
f 3298//522 2861//522 1872//522
f 1872//523 2861//523 2860//523
f 1872//520 2890//520 2889//520
f 1872//517 2854//517 2856//517
f 1872//518 2856//518 3298//518
f 2888//519 2890//519 1872//519
f 2625//508 2629//508 3807//508
f 3492//497 1911//497 1909//497
f 3492//498 1909//498 1902//498
f 2851//499 1554//499 1876//499
f 3807//496 1911//496 3492//496
f 3492//493 1902//493 1904//493
f 1872//494 1874//494 3807//494
f 3807//495 1874//495 1911//495
f 1876//500 1554//500 1878//500
f 3807//505 2628//505 2625//505
f 2881//506 1872//506 2879//506
f 2879//507 1872//507 2882//507
f 3807//504 2634//504 2628//504
f 1878//501 1554//501 1452//501
f 1878//502 1452//502 3466//502
f 2632//503 2634//503 3807//503
f 1430//544 1429//544 3465//544
f 3465//545 1429//545 1415//545
f 3465//546 1415//546 1414//546
f 3465//543 1458//543 1430//543
f 3525//540 2533//540 2518//540
f 1473//541 1461//541 3465//541
f 3465//542 1461//542 1458//542
f 1414//547 1457//547 3465//547
f 1879//552 1421//552 3465//552
f 3465//553 1421//553 1423//553
f 3465//554 1423//554 1424//554
f 1879//551 1451//551 1421//551
f 3465//548 1457//548 1456//548
f 3465//549 1456//549 1463//549
f 1862//550 1451//550 1879//550
f 3525//539 2532//539 2533//539
f 1876//528 2852//528 2849//528
f 1876//529 2849//529 2851//529
f 3466//530 1452//530 1864//530
f 1872//527 2852//527 1876//527
f 1872//524 2860//524 2858//524
f 2858//525 2853//525 1872//525
f 1872//526 2853//526 2852//526
f 1864//531 1452//531 1451//531
f 1480//536 3465//536 1462//536
f 1462//537 3465//537 1463//537
f 1481//538 2532//538 3525//538
f 3525//535 2515//535 2527//535
f 1864//532 1451//532 1862//532
f 2518//533 2516//533 3525//533
f 3525//534 2516//534 2515//534
f 1442//451 3808//451 874//451
f 874//452 3808//452 3809//452
f 874//453 3809//453 871//453
f 1442//450 1607//450 3808//450
f 863//447 1607//447 1454//447
f 1454//448 1607//448 1610//448
f 1441//449 1607//449 1442//449
f 871//454 3809//454 1608//454
f 3809//459 877//459 892//459
f 3809//460 892//460 1608//460
f 1608//461 892//461 891//461
f 3810//458 877//458 3809//458
f 871//455 1608//455 1441//455
f 1441//456 1608//456 1607//456
f 878//457 877//457 3810//457
f 863//446 3808//446 1607//446
f 1482//435 3805//435 1188//435
f 1188//436 3805//436 3811//436
f 1188//437 3811//437 1189//437
f 1482//434 1596//434 3805//434
f 822//431 1396//431 1079//431
f 1079//432 1396//432 1080//432
f 1426//433 1596//433 1482//433
f 1189//438 3811//438 1610//438
f 1448//443 3811//443 862//443
f 862//444 3811//444 3808//444
f 862//445 3808//445 863//445
f 1448//442 1610//442 3811//442
f 1189//439 1610//439 1426//439
f 1426//440 1610//440 1596//440
f 1454//441 1610//441 1448//441
f 2045//482 3489//482 2053//482
f 2045//483 2053//483 2039//483
f 2039//484 2053//484 753//484
f 2043//481 3489//481 2045//481
f 1904//478 2054//478 3494//478
f 2044//479 3491//479 2043//479
f 2043//480 3491//480 3489//480
f 2035//485 1998//485 3812//485
f 2035//490 3812//490 2037//490
f 2037//491 3812//491 3813//491
f 2037//492 3813//492 2054//492
f 3814//489 2051//489 2052//489
f 3812//486 1998//486 1997//486
f 3812//487 1997//487 3814//487
f 3814//488 1997//488 2051//488
f 1881//477 1885//477 3529//477
f 878//466 1609//466 891//466
f 3815//467 3816//467 3817//467
f 1933//468 1936//468 3818//468
f 3810//465 1609//465 878//465
f 1608//462 891//462 1609//462
f 1530//463 1532//463 3810//463
f 3810//464 1532//464 1609//464
f 1961//469 1959//469 3819//469
f 1643//474 1642//474 3820//474
f 1894//475 1896//475 3522//475
f 1913//476 1914//476 3520//476
f 3821//473 3488//473 3494//473
f 454//470 1929//470 3512//470
f 3822//471 3823//471 3824//471
f 3825//472 3826//472 3827//472
f 922//295 1360//295 920//295
f 1519//119 3810//119 3809//119
f 1519//120 3809//120 1514//120
f 1514//121 3809//121 3808//121
f 1518//118 3810//118 1519//118
f 1084//115 1352//115 1602//115
f 1533//116 1530//116 1518//116
f 1518//117 1530//117 3810//117
f 1514//122 3808//122 1515//122
f 1523//127 3805//127 822//127
f 1523//128 822//128 1520//128
f 1520//129 822//129 829//129
f 1517//126 3805//126 1523//126
f 1515//123 3808//123 3811//123
f 1515//124 3811//124 1517//124
f 1517//125 3811//125 3805//125
f 393//114 183//114 182//114
f 795//91 782//91 793//91
f 793//92 782//92 788//92
f 807//97 803//97 805//97
f 985//86 983//86 982//86
f 921//79 924//79 919//79
f 919//80 924//80 926//80
f 987//85 983//85 985//85
f 805//98 803//98 802//98
f 393//111 889//111 888//111
f 888//112 643//112 393//112
f 393//113 643//113 183//113
f 1083//106 775//106 625//106
f 625//103 624//103 1083//103
f 882//104 884//104 1083//104
f 1083//105 884//105 775//105
f 1539//150 3828//150 1534//150
f 1534//151 3828//151 3829//151
f 1534//152 3829//152 1535//152
f 1539//149 1093//149 3828//149
f 1548//146 3830//146 1538//146
f 1538//147 3830//147 1093//147
f 1538//148 1093//148 1539//148
f 1535//153 3829//153 3831//153
f 1586//158 764//158 1606//158
f 1606//159 764//159 771//159
f 1603//160 1543//160 1611//160
f 1542//157 3832//157 1540//157
f 1535//154 3831//154 1537//154
f 1537//155 3831//155 3832//155
f 1537//156 3832//156 1542//156
f 1548//145 3833//145 3830//145
f 1522//134 3806//134 1546//134
f 1546//135 3806//135 855//135
f 1546//136 855//136 1544//136
f 1522//133 3804//133 3806//133
f 1520//130 829//130 1521//130
f 1521//131 829//131 3804//131
f 1521//132 3804//132 1522//132
f 1544//137 855//137 845//137
f 1549//142 1173//142 1547//142
f 1547//143 1173//143 3833//143
f 1547//144 3833//144 1548//144
f 1549//141 1131//141 1173//141
f 1544//138 845//138 1545//138
f 1545//139 845//139 1131//139
f 1545//140 1131//140 1549//140
f 1348//23 1336//23 1237//23
f 1237//24 1336//24 1372//24
f 1282//25 1217//25 1285//25
f 1348//22 1334//22 1336//22
f 993//19 1000//19 996//19
f 996//20 1000//20 997//20
f 1350//21 1334//21 1348//21
f 1285//26 1217//26 1237//26
f 1333//31 1204//31 1369//31
f 1369//32 1204//32 1203//32
f 804//33 1204//33 1333//33
f 1312//30 1372//30 1364//30
f 1285//27 1237//27 1309//27
f 1309//28 1237//28 1372//28
f 1309//29 1372//29 1312//29
f 993//18 1237//18 1000//18
f 841//7 838//7 988//7
f 988//8 990//8 841//8
f 841//9 990//9 1237//9
f 932//6 927//6 1361//6
f 990//3 1000//3 1237//3
f 1369//4 1203//4 1237//4
f 1237//5 1203//5 784//5
f 841//10 1237//10 780//10
f 1361//15 933//15 932//15
f 932//16 933//16 1237//16
f 932//17 1237//17 993//17
f 933//14 1348//14 1237//14
f 780//11 1237//11 784//11
f 780//12 784//12 778//12
f 778//13 784//13 786//13
f 1333//54 984//54 835//54
f 793//55 788//55 835//55
f 835//56 788//56 781//56
f 992//53 984//53 1333//53
f 1333//50 923//50 992//50
f 985//51 982//51 992//51
f 992//52 982//52 984//52
f 835//57 781//57 1333//57
f 1308//68 1330//68 1363//68
f 1356//73 1359//73 1339//73
f 1339//74 1359//74 1358//74
f 1328//67 1330//67 1308//67
f 1333//58 781//58 812//58
f 1333//59 812//59 804//59
f 804//60 812//60 802//60
f 922//49 923//49 1333//49
f 1337//38 1339//38 1358//38
f 812//39 805//39 802//39
f 1360//40 922//40 1333//40
f 1306//37 1363//37 1329//37
f 1218//34 1323//34 1216//34
f 1216//35 1323//35 1303//35
f 1308//36 1363//36 1306//36
f 1358//41 1360//41 1337//41
f 1306//46 1216//46 1303//46
f 919//47 926//47 922//47
f 922//48 926//48 923//48
f 1329//45 1216//45 1306//45
f 1337//42 1360//42 1333//42
f 1337//43 1333//43 1329//43
f 1329//44 1333//44 1216//44
f 3535//245 3534//245 1084//245
f 1084//246 3534//246 3537//246
f 1084//247 3537//247 3531//247
f 1084//244 181//244 3545//244
f 1602//241 903//241 392//241
f 1602//242 392//242 1084//242
f 1084//243 392//243 181//243
f 3580//248 3582//248 1095//248
f 1498//253 1602//253 1501//253
f 1501//254 1602//254 3830//254
f 1501//255 3830//255 1344//255
f 903//252 3539//252 890//252
f 1095//249 3582//249 3542//249
f 1095//250 3542//250 903//250
f 903//251 3542//251 3539//251
f 1095//240 903//240 1602//240
f 1093//228 1355//228 1086//228
f 1093//229 1086//229 900//229
f 900//230 1086//230 3546//230
f 3830//227 1355//227 1093//227
f 1086//224 3544//224 3543//224
f 1602//225 1352//225 3830//225
f 3830//226 1352//226 1355//226
f 900//231 3546//231 622//231
f 1093//236 3575//236 1094//236
f 1095//238 1094//238 3579//238
f 1095//239 3579//239 3584//239
f 3573//235 3575//235 1093//235
f 71//232 70//232 1093//232
f 1093//233 70//233 3538//233
f 1093//234 3538//234 3541//234
f 845//284 806//284 783//284
f 845//285 783//285 1131//285
f 1131//286 783//286 794//286
f 885//283 806//283 845//283
f 845//280 804//280 885//280
f 812//281 781//281 806//281
f 806//282 781//282 783//282
f 986//287 992//287 923//287
f 1131//292 991//292 986//292
f 1131//293 986//293 925//293
f 925//294 986//294 923//294
f 794//291 991//291 1131//291
f 925//288 920//288 1131//288
f 835//289 984//289 794//289
f 794//290 984//290 991//290
f 845//279 1204//279 804//279
f 1498//260 1601//260 1602//260
f 1502//261 1601//261 1505//261
f 1505//262 1601//262 3833//262
f 1345//259 1601//259 1498//259
f 1344//256 3830//256 3833//256
f 1344//257 3833//257 1345//257
f 1345//258 3833//258 1601//258
f 1505//263 3833//263 1175//263
f 1201//272 1173//272 1131//272
f 1202//275 1130//275 1199//275
f 1199//276 1130//276 1174//276
f 1200//271 1173//271 1201//271
f 1175//264 3833//264 1173//264
f 1172//267 1174//267 1502//267
f 1502//268 1174//268 1601//268
f 1594//181 1600//181 3831//181
f 1594//182 3831//182 635//182
f 635//183 3831//183 3829//183
f 1489//180 1600//180 1594//180
f 640//177 3831//177 1600//177
f 640//178 1600//178 1587//178
f 1587//179 1600//179 1578//179
f 635//184 3829//184 633//184
f 1486//189 1598//189 3829//189
f 1486//190 3829//190 116//190
f 116//191 3829//191 3828//191
f 1492//188 1598//188 1486//188
f 633//185 3829//185 1598//185
f 633//186 1598//186 1489//186
f 1489//187 1598//187 1600//187
f 639//176 3831//176 640//176
f 1603//165 764//165 1543//165
f 1543//166 764//166 1586//166
f 1543//167 1586//167 1578//167
f 771//164 3832//164 1606//164
f 1611//161 1543//161 1540//161
f 1611//162 1540//162 771//162
f 771//163 1540//163 3832//163
f 1578//168 1586//168 760//168
f 1588//173 1578//173 3832//173
f 1588//174 3832//174 639//174
f 639//175 3832//175 3831//175
f 1587//172 1578//172 1588//172
f 1578//169 760//169 3832//169
f 3832//170 760//170 762//170
f 3832//171 762//171 1606//171
f 1093//212 3574//212 3573//212
f 1095//213 3581//213 3580//213
f 3536//214 1084//214 3545//214
f 1093//211 3576//211 3574//211
f 1093//208 3540//208 3577//208
f 1093//209 3577//209 3578//209
f 3578//210 3576//210 1093//210
f 1086//216 1085//216 3530//216
f 1095//221 3583//221 3581//221
f 3533//222 3532//222 1086//222
f 1086//223 3532//223 3544//223
f 1095//220 3585//220 3583//220
f 1086//217 3530//217 3533//217
f 1086//218 3543//218 3546//218
f 3584//219 3585//219 1095//219
f 3541//207 3540//207 1093//207
f 1509//196 1599//196 1510//196
f 1510//197 1599//197 3828//197
f 1510//198 3828//198 76//198
f 1492//195 1599//195 1598//195
f 116//192 3828//192 95//192
f 95//193 3828//193 1599//193
f 95//194 1599//194 1492//194
f 76//199 3828//199 1093//199
f 900//204 71//204 1093//204
f 3531//205 1085//205 1084//205
f 3536//206 3535//206 1084//206
f 1509//203 1095//203 1599//203
f 76//200 1093//200 74//200
f 74//201 1093//201 1095//201
f 74//202 1095//202 1509//202
f 1424//555 1467//555 3465//555
f 3495//887 3363//887 3367//887
f 3440//888 3437//888 2552//888
f 2552//889 3437//889 3434//889
f 3495//886 3364//886 3363//886
f 3501//883 3446//883 3495//883
f 3495//884 3446//884 3366//884
f 3366//885 3364//885 3495//885
f 2552//890 3434//890 3433//890
f 3495//895 3359//895 3358//895
f 3495//896 3358//896 3357//896
f 2548//897 3372//897 3374//897
f 3367//894 3359//894 3495//894
f 2548//891 3368//891 3371//891
f 3435//892 3436//892 3501//892
f 3501//893 3436//893 3441//893
f 3501//882 3443//882 3446//882
f 3511//871 1822//871 3504//871
f 3504//872 1822//872 1856//872
f 3504//873 1856//873 1853//873
f 3511//870 2551//870 1822//870
f 1954//867 2558//867 3514//867
f 3514//868 2558//868 2551//868
f 3514//869 2551//869 3511//869
f 2548//874 3356//874 2552//874
f 2552//879 3439//879 3438//879
f 2552//880 3438//880 3440//880
f 3445//881 3443//881 3501//881
f 3456//878 3439//878 2552//878
f 2552//875 3356//875 3455//875
f 2552//876 3455//876 3456//876
f 3448//877 3445//877 3501//877
f 3374//918 3370//918 2548//918
f 2548//919 3370//919 3369//919
f 2548//920 3369//920 3368//920
f 2548//917 3373//917 3372//917
f 3495//914 3361//914 3360//914
f 3495//915 3360//915 2548//915
f 2548//916 3360//916 3373//916
f 3273//921 3275//921 3834//921
f 3835//926 3836//926 3837//926
f 3835//927 3837//927 1617//927
f 1617//928 3837//928 3838//928
f 3839//925 3836//925 3835//925
f 3834//922 3275//922 3840//922
f 3834//923 3840//923 3839//923
f 3839//924 3840//924 3836//924
f 3357//913 3361//913 3495//913
f 2552//902 3435//902 3501//902
f 2552//903 3501//903 2553//903
f 2553//904 3501//904 3496//904
f 3433//901 3435//901 2552//901
f 3371//898 3355//898 2548//898
f 2548//899 3355//899 3354//899
f 2548//900 3354//900 3356//900
f 2553//905 3496//905 2554//905
f 3441//910 3449//910 3501//910
f 3501//911 3449//911 3450//911
f 3501//912 3450//912 3448//912
f 3841//909 510//909 1934//909
f 2554//906 3496//906 3842//906
f 2554//907 3842//907 3841//907
f 3841//908 3842//908 510//908
f 1987//825 1986//825 2055//825
f 2055//826 1986//826 3843//826
f 2055//827 3843//827 1976//827
f 2055//824 1977//824 2047//824
f 1981//821 3496//821 1980//821
f 3497//822 1982//822 1983//822
f 1976//823 1977//823 2055//823
f 1976//828 3843//828 3844//828
f 3818//833 3822//833 3824//833
f 3818//834 3824//834 1933//834
f 1933//835 3824//835 3845//835
f 3846//832 3822//832 3818//832
f 1976//829 3844//829 1975//829
f 1975//830 3844//830 1979//830
f 1975//831 1979//831 1978//831
f 1981//820 3842//820 3496//820
f 1957//809 1965//809 3827//809
f 3825//810 3827//810 3847//810
f 3847//811 3827//811 1965//811
f 3848//808 1852//808 1846//808
f 3849//805 1842//805 1844//805
f 3849//806 1844//806 3848//806
f 3848//807 1844//807 1852//807
f 3847//812 1965//812 3850//812
f 3845//817 3841//817 1934//817
f 433//818 426//818 427//818
f 1935//819 3842//819 1981//819
f 3842//816 1935//816 510//816
f 1965//813 1962//813 3850//813
f 3850//814 1962//814 1964//814
f 3850//815 1964//815 1960//815
f 3490//856 2549//856 1951//856
f 1951//857 2549//857 2557//857
f 1951//858 2557//858 1952//858
f 3490//855 2548//855 2549//855
f 1949//852 3487//852 3490//852
f 1949//853 3490//853 1951//853
f 3495//854 2548//854 3490//854
f 1952//859 2557//859 1953//859
f 1341//864 3851//864 1343//864
f 1953//865 2557//865 1954//865
f 1954//866 2557//866 2558//866
f 1341//863 3852//863 3851//863
f 3841//860 2555//860 2554//860
f 3852//861 2556//861 2555//861
f 2556//862 3852//862 1341//862
f 1916//851 3487//851 1949//851
f 3853//840 1931//840 1930//840
f 3853//841 1930//841 452//841
f 452//842 1930//842 1927//842
f 1920//839 1931//839 3853//839
f 1933//836 3845//836 427//836
f 427//837 3845//837 1934//837
f 427//838 1934//838 433//838
f 1916//843 1918//843 3487//843
f 3807//848 3853//848 3854//848
f 3854//849 3853//849 452//849
f 3854//850 452//850 454//850
f 3807//847 1920//847 3853//847
f 3487//844 1918//844 3492//844
f 3492//845 1918//845 1920//845
f 3492//846 1920//846 3807//846
f 3855//1011 1968//1011 1972//1011
f 3855//1012 1972//1012 3856//1012
f 3856//1013 1972//1013 1974//1013
f 3857//1010 1968//1010 3855//1010
f 3851//1007 1971//1007 1970//1007
f 3851//1008 1970//1008 3857//1008
f 3857//1009 1970//1009 1968//1009
f 3856//1014 1974//1014 2026//1014
f 3858//1019 3849//1019 1750//1019
f 3858//1020 1750//1020 1748//1020
f 2555//1021 3841//1021 3852//1021
f 2024//1018 3849//1018 3858//1018
f 2026//1015 1974//1015 1840//1015
f 2026//1016 1840//1016 2024//1016
f 2024//1017 1840//1017 3849//1017
f 1973//1006 1971//1006 3851//1006
f 3817//995 3859//995 3860//995
f 3817//996 3860//996 3861//996
f 3861//997 3860//997 3862//997
f 1793//994 3859//994 3817//994
f 1643//991 1989//991 1727//991
f 1727//992 1989//992 1988//992
f 1727//993 1988//993 512//993
f 3861//998 3862//998 3863//998
f 3850//1003 3864//1003 3865//1003
f 3850//1004 3865//1004 3847//1004
f 3847//1005 3865//1005 3866//1005
f 3867//1002 3864//1002 3850//1002
f 3863//999 3862//999 3848//999
f 3863//1000 3848//1000 3867//1000
f 3867//1001 3848//1001 3864//1001
f 3491//1042 2049//1042 2048//1042
f 3491//1043 2048//1043 3502//1043
f 3502//1044 2048//1044 2047//1044
f 2044//1041 2049//1041 3491//1041
f 1343//1038 3432//1038 3428//1038
f 2033//1039 2032//1039 2044//1039
f 2044//1040 2032//1040 2049//1040
f 3502//1045 2047//1045 3497//1045
f 3868//1050 3869//1050 3870//1050
f 3870//1051 3869//1051 3508//1051
f 3870//1052 3508//1052 3507//1052
f 3868//1049 2019//1049 3869//1049
f 3497//1046 2047//1046 1977//1046
f 3497//1047 1977//1047 1982//1047
f 2025//1048 2019//1048 3868//1048
f 1343//1037 3871//1037 3432//1037
f 3847//1026 3824//1026 3823//1026
f 3847//1027 3823//1027 3825//1027
f 2026//1028 2025//1028 3856//1028
f 3866//1025 3824//1025 3847//1025
f 3852//1022 3841//1022 3845//1022
f 3852//1023 3845//1023 3866//1023
f 3866//1024 3845//1024 3824//1024
f 3856//1029 2025//1029 3868//1029
f 3857//1034 3507//1034 3851//1034
f 3851//1035 3507//1035 3871//1035
f 3851//1036 3871//1036 1343//1036
f 3857//1033 3870//1033 3507//1033
f 3856//1030 3868//1030 3855//1030
f 3855//1031 3868//1031 3870//1031
f 3855//1032 3870//1032 3857//1032
f 3872//949 3873//949 3874//949
f 3874//950 3873//950 3875//950
f 3874//951 3875//951 3819//951
f 3872//948 1861//948 3873//948
f 3872//945 3876//945 3877//945
f 3872//946 3877//946 2289//946
f 1859//947 1861//947 3872//947
f 3819//952 3875//952 3861//952
f 1960//957 3867//957 3850//957
f 1642//958 1647//958 3820//958
f 3820//959 1647//959 3838//959
f 1960//956 3863//956 3867//956
f 3819//953 3861//953 1961//953
f 1961//954 3861//954 3863//954
f 1961//955 3863//955 1960//955
f 3874//944 3876//944 3872//944
f 3875//933 3815//933 3817//933
f 3875//934 3817//934 3861//934
f 1958//935 1957//935 3878//935
f 3873//932 3815//932 3875//932
f 1617//929 3838//929 1647//929
f 1894//930 3522//930 3873//930
f 3873//931 3522//931 3815//931
f 3878//936 1957//936 3827//936
f 3819//941 1958//941 3878//941
f 3819//942 3878//942 3874//942
f 3874//943 3878//943 3876//943
f 1959//940 1958//940 3819//940
f 3878//937 3827//937 3876//937
f 3876//938 3827//938 2140//938
f 3876//939 2140//939 2142//939
f 2023//980 3879//980 3880//980
f 2023//981 3880//981 2020//981
f 2020//982 3880//982 2010//982
f 2022//979 3879//979 2023//979
f 2021//976 3858//976 3881//976
f 2021//977 3881//977 2022//977
f 2022//978 3881//978 3879//978
f 1936//983 1937//983 3818//983
f 3820//988 3844//988 3843//988
f 3820//989 3843//989 1643//989
f 1643//990 3843//990 1989//990
f 3882//987 3844//987 3820//987
f 3818//984 1937//984 1979//984
f 3818//985 1979//985 3882//985
f 3882//986 1979//986 3844//986
f 2024//975 3858//975 2021//975
f 3883//964 1391//964 1440//964
f 3883//965 1440//965 3840//965
f 3840//966 1440//966 1948//966
f 3884//963 1391//963 3883//963
f 3820//960 3838//960 3882//960
f 1947//961 1383//961 3884//961
f 3884//962 1383//962 1391//962
f 3840//967 1948//967 3836//967
f 3838//972 1947//972 3884//972
f 3838//973 3884//973 3882//973
f 3882//974 3884//974 3818//974
f 3837//971 1947//971 3838//971
f 3836//968 1948//968 1946//968
f 3836//969 1946//969 3837//969
f 3837//970 1946//970 1947//970
f 1840//804 1842//804 3849//804
f 2057//638 2056//638 1716//638
f 1617//639 1679//639 3835//639
f 1837//640 3516//640 1838//640
f 1685//637 1727//637 512//637
f 1839//634 2056//634 2000//634
f 1839//635 2000//635 2002//635
f 1986//636 1989//636 3843//636
f 1838//641 3516//641 3835//641
f 2057//646 1718//646 1683//646
f 2057//647 1683//647 2050//647
f 2050//648 1683//648 1685//648
f 1716//645 1718//645 2057//645
f 1838//642 3835//642 2056//642
f 2056//643 3835//643 1679//643
f 2056//644 1679//644 1716//644
f 1838//633 2056//633 1839//633
f 1899//622 3885//622 1898//622
f 1898//623 3885//623 3834//623
f 1898//624 3834//624 1901//624
f 1859//621 3885//621 1899//621
f 3816//618 1912//618 1913//618
f 1861//619 1894//619 3873//619
f 3872//620 3885//620 1859//620
f 1825//625 1828//625 3839//625
f 1825//630 3839//630 1832//630
f 1832//631 3839//631 3835//631
f 1832//632 3835//632 3516//632
f 3834//629 3464//629 1901//629
f 3839//626 1828//626 1834//626
f 3839//627 1834//627 3834//627
f 3834//628 1834//628 3464//628
f 2053//669 3886//669 3887//669
f 2053//670 3887//670 2052//670
f 2052//671 3887//671 3888//671
f 3489//668 3886//668 2053//668
f 3494//665 3889//665 3821//665
f 3488//666 3821//666 3489//666
f 3489//667 3821//667 3886//667
f 2052//672 3888//672 3814//672
f 2143//677 2144//677 3826//677
f 3826//678 2144//678 2149//678
f 3826//679 2149//679 3827//679
f 3826//676 3461//676 2143//676
f 1885//673 3890//673 3529//673
f 3529//674 3890//674 3891//674
f 3529//675 3891//675 3528//675
f 3494//664 3813//664 3889//664
f 1804//653 3848//653 3862//653
f 1744//654 1766//654 3880//654
f 3880//655 1766//655 3816//655
f 1804//652 3849//652 3848//652
f 2050//649 1685//649 2046//649
f 2046//650 1685//650 512//650
f 1750//651 3849//651 1804//651
f 3816//656 1766//656 1793//656
f 1742//661 3881//661 3858//661
f 1742//662 3858//662 1748//662
f 2054//663 3813//663 3494//663
f 1744//660 3881//660 1742//660
f 3816//657 1793//657 3817//657
f 3880//658 3879//658 1744//658
f 1744//659 3879//659 3881//659
f 3527//576 1871//576 1892//576
f 3892//577 1869//577 3519//577
f 3519//578 1869//578 3499//578
f 3527//575 1869//575 1871//575
f 1888//572 3524//572 3527//572
f 1888//573 3527//573 1892//573
f 3499//574 1869//574 3527//574
f 3519//579 3499//579 3498//579
f 3892//584 1867//584 1869//584
f 2015//585 1883//585 2013//585
f 2013//586 1883//586 2016//586
f 3892//583 1891//583 1867//583
f 1885//580 1887//580 3890//580
f 3890//581 1887//581 1891//581
f 3890//582 1891//582 3892//582
f 1940//571 3524//571 1888//571
f 3465//560 1471//560 1473//560
f 1945//561 3525//561 1941//561
f 1941//562 3525//562 3524//562
f 3465//559 1472//559 1471//559
f 3465//556 1467//556 1466//556
f 3465//557 1466//557 3005//557
f 3005//558 1472//558 3465//558
f 1941//563 3524//563 1940//563
f 1945//568 3465//568 3525//568
f 3525//569 3465//569 1480//569
f 3525//570 1480//570 1481//570
f 1945//567 1890//567 3465//567
f 1940//564 1888//564 1943//564
f 1943//565 1888//565 1890//565
f 1943//566 1890//566 1945//566
f 1813//607 3869//607 1810//607
f 1810//608 3869//608 2019//608
f 1810//609 2019//609 1812//609
f 1813//606 3508//606 3869//606
f 3508//603 1817//603 1819//603
f 3508//604 1819//604 1821//604
f 1815//605 3508//605 1813//605
f 3816//610 1913//610 3880//610
f 3815//615 3521//615 1915//615
f 3815//616 1915//616 3816//616
f 3816//617 1915//617 1912//617
f 3522//614 3521//614 3815//614
f 3880//611 1913//611 3520//611
f 3880//612 3520//612 2010//612
f 2010//613 3520//613 2011//613
f 1815//602 1817//602 3508//602
f 3506//591 1854//591 1855//591
f 3506//592 1855//592 3493//592
f 3493//593 1855//593 1858//593
f 3503//590 1854//590 3506//590
f 3493//587 1845//587 1857//587
f 3504//588 1853//588 3503//588
f 3503//589 1853//589 1854//589
f 3493//594 1858//594 1845//594
f 3509//599 1823//599 3893//599
f 3893//600 1823//600 2550//600
f 3893//601 2550//601 1373//601
f 3509//598 1821//598 1823//598
f 2550//595 1823//595 1822//595
f 2550//596 1822//596 2551//596
f 3508//597 1821//597 3509//597
f 1343//762 3429//762 3406//762
f 1343//763 3406//763 3405//763
f 1373//764 1343//764 3399//764
f 3426//761 3429//761 1343//761
f 3871//758 3430//758 3432//758
f 3428//759 3427//759 1343//759
f 1343//760 3427//760 3426//760
f 3893//765 3412//765 3413//765
f 1373//770 3397//770 3401//770
f 1373//771 3401//771 3402//771
f 3402//772 3409//772 1373//772
f 3399//769 3397//769 1373//769
f 3405//766 3407//766 1343//766
f 1343//767 3407//767 3403//767
f 1343//768 3403//768 3399//768
f 3871//757 3431//757 3430//757
f 3872//746 2400//746 2399//746
f 3423//747 3422//747 3507//747
f 3422//748 3424//748 3507//748
f 3872//745 2404//745 2400//745
f 3872//742 2390//742 2402//742
f 3872//743 2402//743 2405//743
f 2405//744 2404//744 3872//744
f 3507//749 3424//749 3425//749
f 3871//754 3417//754 3421//754
f 3871//755 3421//755 3420//755
f 3420//756 3431//756 3871//756
f 3418//753 3417//753 3871//753
f 3507//750 3425//750 3871//750
f 3871//751 3425//751 3419//751
f 3871//752 3419//752 3418//752
f 3894//793 3512//793 3515//793
f 3894//794 3515//794 3526//794
f 3848//795 1846//795 3864//795
f 3895//792 3512//792 3894//792
f 3807//789 2650//789 2632//789
f 3854//790 454//790 3895//790
f 3895//791 454//791 3512//791
f 3864//796 1846//796 1848//796
f 3866//801 1851//801 3852//801
f 3852//802 1851//802 1973//802
f 3852//803 1973//803 3851//803
f 3866//800 1850//800 1851//800
f 3864//797 1848//797 3865//797
f 3865//798 1848//798 1850//798
f 3865//799 1850//799 3866//799
f 3807//788 3896//788 2650//788
f 3893//777 3451//777 3454//777
f 3510//778 3509//778 3799//778
f 3799//779 3509//779 3893//779
f 3893//776 3452//776 3451//776
f 1373//773 3409//773 3415//773
f 1373//774 3415//774 3416//774
f 3413//775 3452//775 3893//775
f 3799//780 3893//780 3798//780
f 3893//785 3411//785 3410//785
f 3893//786 3410//786 3412//786
f 3854//787 3896//787 3807//787
f 1373//784 3411//784 3893//784
f 3798//781 3893//781 3454//781
f 3416//782 3414//782 1373//782
f 1373//783 3414//783 3411//783
f 3846//700 2610//700 2608//700
f 3273//701 3834//701 3274//701
f 3274//702 3834//702 3344//702
f 3846//699 2649//699 2610//699
f 2645//696 2648//696 3883//696
f 3883//697 2648//697 2649//697
f 3883//698 2649//698 3846//698
f 3275//703 3319//703 3840//703
f 3266//708 3265//708 3834//708
f 3834//709 3265//709 3255//709
f 3834//710 3255//710 3267//710
f 3834//707 3269//707 3266//707
f 3840//704 3319//704 3897//704
f 3840//705 3897//705 2654//705
f 3258//706 3269//706 3834//706
f 3883//695 2647//695 2646//695
f 3827//684 2138//684 2140//684
f 3877//685 3876//685 2384//685
f 2384//686 3876//686 2142//686
f 3827//683 2134//683 2138//683
f 3827//680 2149//680 2137//680
f 3827//681 2137//681 2135//681
f 2135//682 2134//682 3827//682
f 3884//687 3883//687 3818//687
f 2609//692 3846//692 2608//692
f 3840//693 2655//693 3883//693
f 3883//694 2655//694 2647//694
f 3458//691 3846//691 2609//691
f 3818//688 3883//688 3846//688
f 2654//689 2655//689 3840//689
f 2646//690 2645//690 3883//690
f 2403//731 2286//731 3872//731
f 3872//732 2286//732 2285//732
f 3872//733 2285//733 2388//733
f 3872//730 2409//730 2403//730
f 3872//727 2288//727 2293//727
f 2293//728 2295//728 3872//728
f 3872//729 2295//729 2409//729
f 2398//734 2401//734 3872//734
f 3834//739 1524//739 1527//739
f 3834//740 1527//740 3258//740
f 2388//741 2390//741 3872//741
f 3885//738 1524//738 3834//738
f 3872//735 2401//735 1555//735
f 3872//736 1555//736 3885//736
f 3885//737 1555//737 1524//737
f 3872//726 2281//726 2288//726
f 3834//715 3278//715 3277//715
f 3834//716 3277//716 3283//716
f 3283//717 3323//717 3834//717
f 3279//714 3278//714 3834//714
f 3280//711 3341//711 3834//711
f 3834//712 3341//712 3338//712
f 3834//713 3338//713 3279//713
f 3834//718 3323//718 3322//718
f 2399//723 2398//723 3872//723
f 3872//724 2289//724 2284//724
f 2284//725 2281//725 3872//725
f 3834//722 3281//722 3280//722
f 3834//719 3322//719 3344//719
f 3267//720 3334//720 3834//720
f 3834//721 3334//721 3281//721
f 3752//2336 3753//2336 3786//2336
f 3891//4034 3898//4034 3899//4034
f 3891//4035 3899//4035 3900//4035
f 3891//4036 255//4036 3901//4036
f 3902//4033 3898//4033 3891//4033
f 3903//4030 3904//4030 3891//4030
f 3891//4031 3904//4031 3905//4031
f 3891//4032 3905//4032 3906//4032
f 3906//4037 3907//4037 3891//4037
f 253//4042 255//4042 257//4042
f 257//4043 255//4043 3891//4043
f 257//4044 3891//4044 3908//4044
f 3528//4041 3909//4041 3910//4041
f 3891//4038 3907//4038 3911//4038
f 3891//4039 3911//4039 3528//4039
f 3528//4040 3911//4040 3909//4040
f 3891//4029 3912//4029 3902//4029
f 3910//4018 3523//4018 3528//4018
f 257//4019 259//4019 253//4019
f 3913//4020 3891//4020 3914//4020
f 328//4017 125//4017 325//4017
f 156//4014 126//4014 147//4014
f 147//4015 126//4015 125//4015
f 147//4016 125//4016 328//4016
f 3914//4021 3891//4021 3900//4021
f 3913//4026 3915//4026 3916//4026
f 3890//4027 3917//4027 3891//4027
f 3891//4028 3917//4028 3912//4028
f 3913//4025 305//4025 3915//4025
f 293//4022 303//4022 146//4022
f 146//4023 303//4023 305//4023
f 146//4024 305//4024 3913//4024
f 3918//4065 3919//4065 3920//4065
f 3901//4066 3921//4066 3891//4066
f 3891//4067 3921//4067 3922//4067
f 1245//4064 3923//4064 3918//4064
f 1250//4061 3924//4061 1241//4061
f 1241//4062 3924//4062 3925//4062
f 1241//4063 3925//4063 1233//4063
f 3891//4068 3922//4068 3903//4068
f 293//4073 146//4073 136//4073
f 136//4074 146//4074 145//4074
f 136//4075 145//4075 127//4075
f 154//4072 404//4072 400//4072
f 3903//4069 3922//4069 3926//4069
f 3903//4070 3926//4070 154//4070
f 154//4071 3926//4071 404//4071
f 1250//4060 3927//4060 3924//4060
f 162//4049 307//4049 312//4049
f 162//4050 312//4050 164//4050
f 164//4051 166//4051 3928//4051
f 1271//4048 307//4048 162//4048
f 3908//4045 3891//4045 3913//4045
f 3908//4046 3913//4046 3929//4046
f 3929//4047 3913//4047 3916//4047
f 3928//4052 166//4052 1248//4052
f 160//4057 3930//4057 1252//4057
f 1252//4058 3930//4058 3927//4058
f 1252//4059 3927//4059 1250//4059
f 160//4056 3931//4056 3930//4056
f 164//4053 3928//4053 162//4053
f 162//4054 3928//4054 3931//4054
f 162//4055 3931//4055 160//4055
f 132//3972 311//3972 313//3972
f 132//3973 313//3973 1240//3973
f 1240//3974 313//3974 148//3974
f 134//3971 311//3971 132//3971
f 1275//3968 308//3968 1254//3968
f 1254//3969 308//3969 311//3969
f 1254//3970 311//3970 134//3970
f 1240//3975 148//3975 1236//3975
f 161//3980 163//3980 3932//3980
f 3932//3981 163//3981 1249//3981
f 3932//3982 1249//3982 1251//3982
f 233//3979 3933//3979 234//3979
f 3934//3976 3935//3976 1257//3976
f 1257//3977 3935//3977 3933//3977
f 1257//3978 3933//3978 233//3978
f 1275//3967 1270//3967 308//3967
f 3936//3956 1244//3956 3937//3956
f 3937//3957 1244//3957 1247//3957
f 3937//3958 1247//3958 3938//3958
f 3936//3955 1242//3955 1244//3955
f 1260//3952 3939//3952 3940//3952
f 1260//3953 3940//3953 1269//3953
f 3941//3954 1242//3954 3936//3954
f 3938//3959 1247//3959 1253//3959
f 3942//3964 167//3964 3932//3964
f 3932//3965 167//3965 161//3965
f 1272//3966 1270//3966 1275//3966
f 3942//3963 165//3963 167//3963
f 3938//3960 1253//3960 3943//3960
f 3943//3961 1253//3961 165//3961
f 3943//3962 165//3962 3942//3962
f 1214//4003 228//4003 155//4003
f 3934//4004 1257//4004 3944//4004
f 3944//4005 1257//4005 1266//4005
f 1212//4002 228//4002 1214//4002
f 237//3999 348//3999 335//3999
f 237//4000 335//4000 1212//4000
f 1212//4001 335//4001 228//4001
f 3944//4006 1266//4006 1268//4006
f 3945//4011 3946//4011 3947//4011
f 295//4012 294//4012 156//4012
f 156//4013 294//4013 126//4013
f 3945//4010 3948//4010 3946//4010
f 1251//4007 1232//4007 3932//4007
f 3932//4008 1232//4008 3948//4008
f 3932//4009 3948//4009 3945//4009
f 239//3998 348//3998 237//3998
f 1257//3987 1264//3987 1266//3987
f 3944//3988 3949//3988 3934//3988
f 148//3989 150//3989 1236//3989
f 230//3986 155//3986 228//3986
f 1232//3983 1234//3983 3948//3983
f 399//3984 153//3984 230//3984
f 230//3985 153//3985 155//3985
f 1236//3990 150//3990 325//3990
f 1224//3995 222//3995 221//3995
f 1224//3996 221//3996 239//3996
f 239//3997 221//3997 348//3997
f 1227//3994 222//3994 1224//3994
f 1236//3991 325//3991 125//3991
f 344//3992 329//3992 1227//3992
f 1227//3993 329//3993 222//3993
f 3908//4170 3950//4170 260//4170
f 3908//4171 260//4171 257//4171
f 3517//4172 3523//4172 3910//4172
f 3929//4169 3950//4169 3908//4169
f 3916//4166 3951//4166 3952//4166
f 3916//4167 3952//4167 3929//4167
f 3929//4168 3952//4168 3950//4168
f 3517//4173 3910//4173 3953//4173
f 3954//4178 3911//4178 3907//4178
f 3954//4179 3907//4179 3955//4179
f 3955//4180 3907//4180 3906//4180
f 3956//4177 3911//4177 3954//4177
f 3953//4174 3910//4174 3909//4174
f 3953//4175 3909//4175 3956//4175
f 3956//4176 3909//4176 3911//4176
f 3915//4165 3951//4165 3916//4165
f 3957//4148 3926//4148 3922//4148
f 3957//4149 3922//4149 3958//4149
f 3958//4150 3922//4150 3921//4150
f 405//4147 3926//4147 3957//4147
f 152//4138 231//4138 1219//4138
f 1219//4139 231//4139 1213//4139
f 405//4146 404//4146 3926//4146
f 3958//4151 3921//4151 3959//4151
f 305//4162 304//4162 3960//4162
f 305//4163 3960//4163 3915//4163
f 3915//4164 3960//4164 3951//4164
f 3961//4155 255//4155 254//4155
f 3959//4152 3921//4152 3901//4152
f 3959//4153 3901//4153 3961//4153
f 3961//4154 3901//4154 255//4154
f 3962//4201 3902//4201 3963//4201
f 3963//4202 3902//4202 3912//4202
f 3963//4203 3912//4203 3964//4203
f 3962//4200 3898//4200 3902//4200
f 3965//4197 3899//4197 3966//4197
f 3966//4198 3899//4198 3898//4198
f 3966//4199 3898//4199 3962//4199
f 3964//4204 3912//4204 3917//4204
f 3942//4241 3967//4241 3943//4241
f 3943//4242 3967//4242 3920//4242
f 3943//4243 3920//4243 3938//4243
f 3942//4240 3928//4240 3967//4240
f 3964//4205 3917//4205 3968//4205
f 3968//4206 3917//4206 3890//4206
f 3968//4207 3890//4207 3892//4207
f 3965//4196 3900//4196 3899//4196
f 3969//4185 3904//4185 3970//4185
f 3970//4186 3904//4186 3903//4186
f 3970//4187 3903//4187 3971//4187
f 3969//4184 3905//4184 3904//4184
f 3955//4181 3906//4181 3972//4181
f 3972//4182 3906//4182 3905//4182
f 3972//4183 3905//4183 3969//4183
f 3971//4188 3903//4188 154//4188
f 3973//4193 3914//4193 3974//4193
f 3974//4194 3914//4194 3900//4194
f 3974//4195 3900//4195 3965//4195
f 3973//4192 3913//4192 3914//4192
f 3971//4189 154//4189 153//4189
f 156//4190 146//4190 3913//4190
f 156//4191 3913//4191 3973//4191
f 1274//4096 1271//4096 1273//4096
f 242//4097 1221//4097 224//4097
f 224//4098 1221//4098 1226//4098
f 1274//4095 307//4095 1271//4095
f 133//4092 310//4092 1238//4092
f 1238//4093 310//4093 307//4093
f 1238//4094 307//4094 1274//4094
f 3975//4099 1267//4099 3976//4099
f 3977//4104 1258//4104 1255//4104
f 3977//4105 1255//4105 3978//4105
f 3978//4106 1255//4106 235//4106
f 3979//4103 1258//4103 3977//4103
f 3976//4100 1267//4100 1265//4100
f 3976//4101 1265//4101 3979//4101
f 3979//4102 1265//4102 1258//4102
f 133//4091 316//4091 310//4091
f 3928//4080 3918//4080 3920//4080
f 3928//4081 3920//4081 3967//4081
f 145//4082 327//4082 127//4082
f 1245//4079 3918//4079 3928//4079
f 1248//4076 1246//4076 3928//4076
f 3928//4077 1246//4077 1243//4077
f 3928//4078 1243//4078 1245//4078
f 127//4083 327//4083 326//4083
f 1239//4088 151//4088 135//4088
f 135//4089 151//4089 316//4089
f 135//4090 316//4090 133//4090
f 1239//4087 149//4087 151//4087
f 127//4084 326//4084 1235//4084
f 1235//4085 326//4085 149//4085
f 1235//4086 149//4086 1239//4086
f 347//4127 238//4127 1213//4127
f 347//4128 1213//4128 231//4128
f 3980//4129 3981//4129 3982//4129
f 236//4126 238//4126 347//4126
f 226//4123 1259//4123 3983//4123
f 3983//4124 1259//4124 1261//4124
f 3983//4125 1261//4125 1263//4125
f 3982//4130 3981//4130 3983//4130
f 154//4135 402//4135 229//4135
f 154//4136 229//4136 152//4136
f 152//4137 229//4137 231//4137
f 400//4134 402//4134 154//4134
f 3982//4131 3983//4131 3984//4131
f 3984//4132 3983//4132 1263//4132
f 3984//4133 1263//4133 1276//4133
f 346//4122 1226//4122 315//4122
f 3983//4111 224//4111 226//4111
f 232//4112 315//4112 1225//4112
f 1225//4113 315//4113 1226//4113
f 3983//4110 242//4110 224//4110
f 3978//4107 235//4107 3985//4107
f 3985//4108 235//4108 242//4108
f 3985//4109 242//4109 3983//4109
f 232//4114 1225//4114 223//4114
f 345//4119 224//4119 343//4119
f 343//4120 224//4120 1226//4120
f 343//4121 1226//4121 346//4121
f 349//4118 236//4118 347//4118
f 223//4115 1225//4115 1223//4115
f 223//4116 1223//4116 349//4116
f 349//4117 1223//4117 236//4117
f 1262//3951 3939//3951 1260//3951
f 3197//3711 2217//3711 2189//3711
f 3822//3712 3986//3712 3823//3712
f 3823//3713 3986//3713 3987//3713
f 3188//3710 2217//3710 3197//3710
f 3184//3707 2231//3707 2218//3707
f 3184//3708 2218//3708 3188//3708
f 3188//3709 2218//3709 2217//3709
f 3987//3714 3988//3714 3823//3714
f 655//3719 661//3719 658//3719
f 658//3720 661//3720 664//3720
f 3989//3721 3988//3721 630//3721
f 655//3718 628//3718 661//3718
f 3823//3715 3988//3715 3990//3715
f 3823//3716 3990//3716 3825//3716
f 660//3717 628//3717 655//3717
f 3183//3706 2231//3706 3184//3706
f 2332//3695 2066//3695 2094//3695
f 2332//3696 2094//3696 2335//3696
f 2335//3697 2094//3697 2115//3697
f 2323//3694 2066//3694 2332//3694
f 2319//3691 2087//3691 2067//3691
f 2319//3692 2067//3692 2323//3692
f 2323//3693 2067//3693 2066//3693
f 2335//3698 2115//3698 3205//3698
f 3212//3703 2100//3703 2214//3703
f 3212//3704 2214//3704 3183//3704
f 3183//3705 2214//3705 2231//3705
f 3208//3702 2100//3702 3212//3702
f 3205//3699 2115//3699 2101//3699
f 3205//3700 2101//3700 3208//3700
f 3208//3701 2101//3701 2100//3701
f 3990//3754 3559//3754 3826//3754
f 3990//3755 3826//3755 3825//3755
f 173//3756 3483//3756 184//3756
f 3988//3753 3559//3753 3990//3753
f 3989//3750 3555//3750 3557//3750
f 3989//3751 3557//3751 3988//3751
f 3988//3752 3557//3752 3559//3752
f 184//3757 3483//3757 3485//3757
f 177//3762 3460//3762 686//3762
f 686//3763 3460//3763 3459//3763
f 686//3764 3459//3764 175//3764
f 177//3761 3486//3761 3460//3761
f 184//3758 3485//3758 180//3758
f 180//3759 3485//3759 3486//3759
f 180//3760 3486//3760 177//3760
f 630//3749 3555//3749 3989//3749
f 3846//3726 3458//3726 3477//3726
f 3986//3727 3822//3727 3846//3727
f 3559//3728 3461//3728 3826//3728
f 628//3725 3991//3725 661//3725
f 630//3722 3988//3722 3987//3722
f 630//3723 3987//3723 628//3723
f 628//3724 3987//3724 3991//3724
f 3846//3729 3477//3729 3986//3729
f 3991//3734 3565//3734 662//3734
f 3991//3735 662//3735 661//3735
f 630//3748 629//3748 3555//3748
f 3987//3733 3565//3733 3991//3733
f 3986//3730 3477//3730 3569//3730
f 3986//3731 3569//3731 3987//3731
f 3987//3732 3569//3732 3565//3732
f 2666//3649 3097//3649 3091//3649
f 2666//3650 3091//3650 2667//3650
f 2667//3651 3091//3651 3076//3651
f 2678//3648 3097//3648 2666//3648
f 2623//3645 3164//3645 3104//3645
f 2623//3646 3104//3646 2678//3646
f 2678//3647 3104//3647 3097//3647
f 2667//3652 3076//3652 2673//3652
f 2202//3657 3090//3657 3089//3657
f 2202//3658 3089//3658 2203//3658
f 2203//3659 3089//3659 3110//3659
f 2668//3656 3090//3656 2202//3656
f 2673//3653 3076//3653 3103//3653
f 2673//3654 3103//3654 2668//3654
f 2668//3655 3103//3655 3090//3655
f 2622//3644 3164//3644 2623//3644
f 3125//3633 2314//3633 3129//3633
f 3129//3634 2314//3634 2330//3634
f 3129//3635 2330//3635 3203//3635
f 3125//3632 3093//3632 2314//3632
f 3145//3629 3093//3629 3128//3629
f 3128//3630 3093//3630 3105//3630
f 3127//3631 3093//3631 3125//3631
f 3136//3636 3129//3636 3130//3636
f 2613//3641 3171//3641 3168//3641
f 2613//3642 3168//3642 2622//3642
f 2622//3643 3168//3643 3164//3643
f 2612//3640 3171//3640 2613//3640
f 3130//3637 3129//3637 3203//3637
f 3130//3638 3203//3638 3131//3638
f 3131//3639 3203//3639 3179//3639
f 2268//3680 2151//3680 2271//3680
f 2271//3681 2151//3681 2084//3681
f 2271//3682 2084//3682 2342//3682
f 2268//3679 2152//3679 2151//3679
f 2256//3676 2157//3676 2259//3676
f 2259//3677 2157//3677 2152//3677
f 2259//3678 2152//3678 2268//3678
f 2342//3683 2084//3683 2083//3683
f 2349//3688 2059//3688 2318//3688
f 2318//3689 2059//3689 2087//3689
f 2318//3690 2087//3690 2319//3690
f 2349//3687 2060//3687 2059//3687
f 2342//3684 2083//3684 2345//3684
f 2345//3685 2083//3685 2060//3685
f 2345//3686 2060//3686 2349//3686
f 2189//3675 3228//3675 3197//3675
f 2204//3664 3123//3664 2170//3664
f 2170//3665 3123//3665 3122//3665
f 2170//3666 3122//3666 2171//3666
f 2204//3663 3126//3663 3123//3663
f 2203//3660 3110//3660 2212//3660
f 2212//3661 3110//3661 3126//3661
f 2212//3662 3126//3662 2204//3662
f 2171//3667 3122//3667 3217//3667
f 2173//3672 3229//3672 2174//3672
f 2174//3673 3229//3673 3228//3673
f 2174//3674 3228//3674 2189//3674
f 2173//3671 3230//3671 3229//3671
f 2171//3668 3217//3668 2187//3668
f 2187//3669 3217//3669 3230//3669
f 2187//3670 3230//3670 2173//3670
f 3971//3909 3961//3909 254//3909
f 3974//3910 260//3910 3973//3910
f 3973//3911 260//3911 3950//3911
f 3971//3908 3959//3908 3961//3908
f 3974//3905 3965//3905 260//3905
f 260//3906 3965//3906 3966//3906
f 3958//3907 3959//3907 3971//3907
f 3973//3912 3950//3912 3952//3912
f 156//3917 304//3917 295//3917
f 3972//3918 3969//3918 3519//3918
f 3519//3919 3969//3919 3970//3919
f 156//3916 3960//3916 304//3916
f 3952//3913 3951//3913 3973//3913
f 3973//3914 3951//3914 3960//3914
f 3973//3915 3960//3915 156//3915
f 3519//3904 3954//3904 3955//3904
f 3983//3883 3933//3883 3985//3883
f 258//3892 256//3892 259//3892
f 259//3893 256//3893 253//3893
f 3992//3882 3933//3882 3983//3882
f 173//3827 3484//3827 3483//3827
f 3942//3868 3932//3868 3928//3868
f 3928//3869 3932//3869 3931//3869
f 3968//3896 3892//3896 3519//3896
f 3518//3901 3953//3901 3956//3901
f 3518//3902 3956//3902 3519//3902
f 3519//3903 3956//3903 3954//3903
f 3517//3900 3953//3900 3518//3900
f 3955//3897 3972//3897 3519//3897
f 256//3898 258//3898 254//3898
f 254//3899 258//3899 260//3899
f 342//3940 1222//3940 227//3940
f 227//3941 1222//3941 1220//3941
f 227//3942 1220//3942 234//3942
f 342//3939 1227//3939 1222//3939
f 161//3936 309//3936 308//3936
f 161//3937 308//3937 1270//3937
f 344//3938 1227//3938 342//3938
f 234//3943 3933//3943 227//3943
f 1256//3948 3993//3948 3994//3948
f 1256//3949 3994//3949 1262//3949
f 1262//3950 3994//3950 3939//3950
f 225//3947 3993//3947 1256//3947
f 227//3944 3933//3944 3992//3944
f 227//3945 3992//3945 225//3945
f 225//3946 3992//3946 3993//3946
f 167//3935 309//3935 161//3935
f 3964//3924 254//3924 3963//3924
f 3963//3925 254//3925 260//3925
f 3963//3926 260//3926 3962//3926
f 3964//3923 3971//3923 254//3923
f 3519//3920 3970//3920 3968//3920
f 3968//3921 3970//3921 3971//3921
f 3968//3922 3971//3922 3964//3922
f 3962//3927 260//3927 3966//3927
f 3971//3932 3957//3932 3958//3932
f 329//3933 314//3933 222//3933
f 3935//3934 3995//3934 3933//3934
f 3971//3931 405//3931 3957//3931
f 399//3928 401//3928 153//3928
f 153//3929 401//3929 405//3929
f 153//3930 405//3930 3971//3930
f 138//3785 3556//3785 3560//3785
f 138//3786 3560//3786 144//3786
f 144//3787 3560//3787 3561//3787
f 140//3784 3556//3784 138//3784
f 169//3781 3562//3781 3558//3781
f 169//3782 3558//3782 140//3782
f 140//3783 3558//3783 3556//3783
f 144//3788 3561//3788 139//3788
f 141//3793 3571//3793 3572//3793
f 141//3794 3572//3794 174//3794
f 174//3795 3572//3795 3570//3795
f 137//3792 3571//3792 141//3792
f 139//3789 3561//3789 3564//3789
f 139//3790 3564//3790 137//3790
f 137//3791 3564//3791 3571//3791
f 186//3780 3562//3780 169//3780
f 193//3769 3473//3769 3472//3769
f 193//3770 3472//3770 194//3770
f 194//3771 3472//3771 3480//3771
f 176//3768 3473//3768 193//3768
f 175//3765 3459//3765 3471//3765
f 175//3766 3471//3766 176//3766
f 176//3767 3471//3767 3473//3767
f 194//3772 3480//3772 189//3772
f 185//3777 3481//3777 3563//3777
f 185//3778 3563//3778 186//3778
f 186//3779 3563//3779 3562//3779
f 190//3776 3481//3776 185//3776
f 189//3773 3480//3773 3482//3773
f 189//3774 3482//3774 190//3774
f 190//3775 3482//3775 3481//3775
f 188//3816 3479//3816 187//3816
f 187//3817 3479//3817 3474//3817
f 187//3818 3474//3818 129//3818
f 188//3815 3467//3815 3479//3815
f 143//3812 3468//3812 142//3812
f 142//3813 3468//3813 3467//3813
f 142//3814 3467//3814 188//3814
f 129//3819 3474//3819 3470//3819
f 171//3824 3462//3824 170//3824
f 170//3825 3462//3825 3484//3825
f 170//3826 3484//3826 173//3826
f 171//3823 3463//3823 3462//3823
f 129//3820 3470//3820 178//3820
f 178//3821 3470//3821 3463//3821
f 178//3822 3463//3822 171//3822
f 143//3811 3469//3811 3468//3811
f 179//3800 3566//3800 191//3800
f 191//3801 3566//3801 3568//3801
f 191//3802 3568//3802 192//3802
f 179//3799 3567//3799 3566//3799
f 174//3796 3570//3796 172//3796
f 172//3797 3570//3797 3567//3797
f 172//3798 3567//3798 179//3798
f 192//3803 3568//3803 3478//3803
f 128//3808 3475//3808 131//3808
f 131//3809 3475//3809 3469//3809
f 131//3810 3469//3810 143//3810
f 128//3807 3476//3807 3475//3807
f 192//3804 3478//3804 130//3804
f 130//3805 3478//3805 3476//3805
f 130//3806 3476//3806 128//3806
f 3938//4244 3920//4244 3919//4244
f 3996//4680 3997//4680 3998//4680
f 3996//4681 3998//4681 3999//4681
f 3999//4682 3998//4682 4000//4682
f 4001//4679 3997//4679 3996//4679
f 1229//4676 1228//4676 4002//4676
f 1229//4677 4002//4677 4001//4677
f 4001//4678 4002//4678 3997//4678
f 3999//4683 4000//4683 4003//4683
f 4004//4688 4005//4688 4006//4688
f 4004//4689 4006//4689 4007//4689
f 4007//4690 4006//4690 4008//4690
f 4009//4687 4005//4687 4004//4687
f 4003//4684 4000//4684 4010//4684
f 4003//4685 4010//4685 4009//4685
f 4009//4686 4010//4686 4005//4686
f 4011//4675 1231//4675 1230//4675
f 4012//4664 4013//4664 4014//4664
f 4012//4665 4014//4665 4015//4665
f 4015//4666 4014//4666 4016//4666
f 4017//4663 4013//4663 4012//4663
f 4018//4660 4019//4660 4020//4660
f 4018//4661 4020//4661 4017//4661
f 4017//4662 4020//4662 4013//4662
f 4015//4667 4016//4667 4021//4667
f 4022//4672 4023//4672 4024//4672
f 4022//4673 4024//4673 4011//4673
f 4011//4674 4024//4674 1231//4674
f 4025//4671 4023//4671 4022//4671
f 4021//4668 4016//4668 4026//4668
f 4021//4669 4026//4669 4025//4669
f 4025//4670 4026//4670 4023//4670
f 4027//4751 4028//4751 4029//4751
f 4029//4752 4028//4752 4030//4752
f 4029//4753 4030//4753 4031//4753
f 4027//4750 4032//4750 4028//4750
f 4033//4747 4034//4747 4035//4747
f 4035//4748 4034//4748 4032//4748
f 4035//4749 4032//4749 4027//4749
f 4031//4754 4030//4754 1181//4754
f 4036//4791 4037//4791 4038//4791
f 4038//4792 4037//4792 4039//4792
f 4038//4793 4039//4793 4040//4793
f 4036//4790 4041//4790 4037//4790
f 4031//4755 1181//4755 1178//4755
f 1073//4788 1072//4788 4041//4788
f 1073//4789 4041//4789 4036//4789
f 4033//4746 4042//4746 4034//4746
f 1194//4735 4043//4735 4044//4735
f 4044//4736 4043//4736 4045//4736
f 4044//4737 4045//4737 4046//4737
f 1194//4734 1192//4734 4043//4734
f 4007//4691 4008//4691 4047//4691
f 4047//4692 4008//4692 3813//4692
f 4047//4693 3813//4693 3812//4693
f 4046//4738 4045//4738 4048//4738
f 4049//4743 4050//4743 4051//4743
f 4051//4744 4050//4744 4052//4744
f 4051//4745 4052//4745 4053//4745
f 4049//4742 4054//4742 4050//4742
f 4046//4739 4048//4739 4055//4739
f 4055//4740 4048//4740 4054//4740
f 4055//4741 4054//4741 4049//4741
f 1099//4606 1150//4606 1013//4606
f 1099//4607 1013//4607 1105//4607
f 1105//4608 1013//4608 1156//4608
f 1107//4605 1150//4605 1099//4605
f 1018//4602 1143//4602 1016//4602
f 1158//4603 1151//4603 1107//4603
f 1107//4604 1151//4604 1150//4604
f 1105//4609 1156//4609 1041//4609
f 1023//4614 1155//4614 1024//4614
f 1177//4615 1179//4615 4042//4615
f 4042//4616 1179//4616 4030//4616
f 1042//4613 1155//4613 1023//4613
f 1041//4610 1156//4610 1153//4610
f 1041//4611 1153//4611 1042//4611
f 1042//4612 1153//4612 1155//4612
f 1018//4601 1145//4601 1143//4601
f 4042//4590 1056//4590 1060//4590
f 4042//4591 1060//4591 1177//4591
f 1179//4592 1181//4592 4030//4592
f 1108//4589 1056//4589 4042//4589
f 1116//4586 1111//4586 4056//4586
f 4056//4587 1111//4587 1115//4587
f 4056//4588 1115//4588 4057//4588
f 792//4593 1230//4593 832//4593
f 4058//4598 4056//4598 4059//4598
f 4059//4599 4056//4599 4057//4599
f 1127//4600 1145//4600 1018//4600
f 4058//4597 4060//4597 4056//4597
f 832//4594 1230//4594 1024//4594
f 832//4595 1024//4595 1155//4595
f 4061//4596 4060//4596 4058//4596
f 800//4649 4062//4649 4063//4649
f 4063//4650 4062//4650 4064//4650
f 4063//4651 4064//4651 4065//4651
f 800//4648 799//4648 4062//4648
f 4066//4639 4067//4639 4068//4639
f 4068//4640 4067//4640 1208//4640
f 4068//4641 1208//4641 1205//4641
f 4065//4652 4064//4652 4069//4652
f 4070//4657 1211//4657 1210//4657
f 3887//4658 3886//4658 4019//4658
f 3887//4659 4019//4659 4018//4659
f 4070//4656 4071//4656 1211//4656
f 4065//4653 4069//4653 4072//4653
f 4072//4654 4069//4654 4071//4654
f 4072//4655 4071//4655 4070//4655
f 4066//4638 4073//4638 4067//4638
f 1229//4621 811//4621 810//4621
f 1229//4622 810//4622 1125//4622
f 1125//4623 810//4623 1016//4623
f 801//4620 811//4620 1229//4620
f 4042//4617 4030//4617 4034//4617
f 4034//4618 4030//4618 4028//4618
f 4034//4619 4028//4619 4032//4619
f 1125//4624 1016//4624 1126//4624
f 4074//4635 4075//4635 4076//4635
f 4076//4636 4075//4636 4073//4636
f 4076//4637 4073//4637 4066//4637
f 4074//4634 4077//4634 4075//4634
f 1126//4625 1016//4625 1143//4625
f 796//4632 791//4632 4077//4632
f 796//4633 4077//4633 4074//4633
f 976//4928 1281//4928 973//4928
f 1279//4929 1278//4929 837//4929
f 1792//4930 1797//4930 1794//4930
f 976//4927 1290//4927 1281//4927
f 909//4924 1790//4924 930//4924
f 1012//4925 1791//4925 1792//4925
f 1053//4926 1290//4926 976//4926
f 837//4931 1278//4931 828//4931
f 846//4936 965//4936 917//4936
f 1004//4937 1008//4937 1796//4937
f 1796//4938 1008//4938 1009//4938
f 846//4935 1058//4935 965//4935
f 828//4932 1278//4932 1045//4932
f 828//4933 1045//4933 820//4933
f 848//4934 1058//4934 846//4934
f 909//4923 1785//4923 1790//4923
f 978//4912 1002//4912 1796//4912
f 1796//4913 1002//4913 1004//4913
f 978//4914 1796//4914 950//4914
f 950//4911 979//4911 978//4911
f 953//4908 964//4908 981//4908
f 953//4909 981//4909 950//4909
f 950//4910 981//4910 979//4910
f 950//4915 1796//4915 1788//4915
f 907//4920 1782//4920 905//4920
f 905//4921 1782//4921 1785//4921
f 905//4922 1785//4922 909//4922
f 907//4919 1787//4919 1782//4919
f 950//4916 1788//4916 911//4916
f 911//4917 1788//4917 1787//4917
f 911//4918 1787//4918 907//4918
f 970//4959 917//4959 968//4959
f 968//4960 917//4960 965//4960
f 1638//4961 1298//4961 1639//4961
f 970//4958 913//4958 917//4958
f 961//4955 914//4955 1006//4955
f 1006//4956 914//4956 913//4956
f 1006//4957 913//4957 970//4957
f 1639//4962 1298//4962 1301//4962
f 1661//4967 1297//4967 1665//4967
f 1665//4968 1297//4968 1051//4968
f 1665//4969 1051//4969 1654//4969
f 1661//4966 1317//4966 1297//4966
f 1639//4963 1301//4963 1663//4963
f 1663//4964 1301//4964 1317//4964
f 1663//4965 1317//4965 1661//4965
f 961//4954 954//4954 914//4954
f 904//4943 973//4943 826//4943
f 826//4944 973//4944 1281//4944
f 826//4945 1281//4945 824//4945
f 1796//4942 1794//4942 1795//4942
f 1796//4939 1009//4939 1012//4939
f 1012//4940 1792//4940 1796//4940
f 1796//4941 1792//4941 1794//4941
f 824//4946 1281//4946 1279//4946
f 964//4951 943//4951 960//4951
f 960//4952 943//4952 954//4952
f 960//4953 954//4953 961//4953
f 964//4950 949//4950 943//4950
f 824//4947 1279//4947 816//4947
f 816//4948 1279//4948 837//4948
f 957//4949 949//4949 964//4949
f 1738//4860 1737//4860 1796//4860
f 1796//4861 1737//4861 1788//4861
f 1802//4868 1804//4868 3862//4868
f 1653//4847 1655//4847 1654//4847
f 1066//4836 1032//4836 1068//4836
f 1068//4837 1032//4837 1025//4837
f 1652//4846 1655//4846 1653//4846
f 1800//4869 1026//4869 3860//4869
f 1771//4874 1800//4874 3860//4874
f 1771//4875 3860//4875 1772//4875
f 1772//4876 3860//4876 3859//4876
f 1799//4873 1800//4873 1771//4873
f 1798//4870 1799//4870 1771//4870
f 1032//4871 1066//4871 1065//4871
f 1802//4872 3862//4872 1803//4872
f 4078//4809 1115//4809 1114//4809
f 4079//4798 4056//4798 4060//4798
f 4079//4799 4060//4799 4080//4799
f 4080//4800 4060//4800 4061//4800
f 4081//4797 4082//4797 4083//4797
f 4040//4794 4039//4794 4084//4794
f 4040//4795 4084//4795 4081//4795
f 4081//4796 4084//4796 4082//4796
f 4080//4801 4061//4801 4085//4801
f 4086//4806 4059//4806 4057//4806
f 4086//4807 4057//4807 4078//4807
f 4078//4808 4057//4808 1115//4808
f 4087//4805 4059//4805 4086//4805
f 4085//4802 4061//4802 4058//4802
f 4085//4803 4058//4803 4087//4803
f 4087//4804 4058//4804 4059//4804
f 1781//4897 1806//4897 1805//4897
f 1781//4898 1805//4898 1061//4898
f 1061//4899 1805//4899 1184//4899
f 1808//4896 1806//4896 1781//4896
f 3860//4893 1780//4893 3862//4893
f 3862//4894 1780//4894 1779//4894
f 3862//4895 1779//4895 1803//4895
f 1061//4900 1184//4900 1183//4900
f 1065//4905 3860//4905 1026//4905
f 1065//4906 1026//4906 1032//4906
f 957//4907 964//4907 953//4907
f 1807//4904 3860//4904 1065//4904
f 949//4901 940//4901 943//4901
f 1808//4902 1781//4902 1807//4902
f 1807//4903 1781//4903 3860//4903
f 3860//4892 1776//4892 1780//4892
f 3859//4881 1774//4881 1773//4881
f 3859//4882 1773//4882 1775//4882
f 1775//4883 1770//4883 3859//4883
f 1793//4880 1774//4880 3859//4880
f 1772//4877 3859//4877 1768//4877
f 1781//4878 1778//4878 3860//4878
f 3860//4879 1778//4879 1777//4879
f 3859//4884 1770//4884 1769//4884
f 1771//4889 852//4889 1801//4889
f 1771//4890 1801//4890 1798//4890
f 1777//4891 1776//4891 3860//4891
f 1058//4888 852//4888 1771//4888
f 3859//4885 1769//4885 1768//4885
f 848//4886 850//4886 1058//4886
f 1058//4887 850//4887 852//4887
f 1104//4585 1087//4585 1075//4585
f 1162//4419 4053//4419 1106//4419
f 1106//4420 4053//4420 4033//4420
f 1106//4421 4033//4421 1057//4421
f 4053//4418 1139//4418 1136//4418
f 1106//4415 1133//4415 1160//4415
f 1106//4416 1160//4416 1162//4416
f 1162//4417 1139//4417 4053//4417
f 1057//4422 4033//4422 4035//4422
f 1176//4427 4029//4427 1180//4427
f 1180//4428 4029//4428 4031//4428
f 1180//4429 4031//4429 1178//4429
f 1176//4426 4027//4426 4029//4426
f 1057//4423 4035//4423 1055//4423
f 1055//4424 4035//4424 4027//4424
f 1055//4425 4027//4425 1176//4425
f 1102//4414 1133//4414 1106//4414
f 3889//4403 4013//4403 3821//4403
f 3821//4404 4013//4404 4020//4404
f 3821//4405 4020//4405 4019//4405
f 3889//4402 4014//4402 4013//4402
f 3889//4399 4000//4399 3998//4399
f 3889//4400 1208//4400 4067//4400
f 4016//4401 4014//4401 3889//4401
f 1206//4406 1208//4406 1210//4406
f 4072//4411 4002//4411 4065//4411
f 1074//4412 1128//4412 1102//4412
f 1102//4413 1128//4413 1133//4413
f 4070//4410 4002//4410 4072//4410
f 1210//4407 1208//4407 3889//4407
f 1210//4408 3889//4408 4070//4408
f 4070//4409 3889//4409 4002//4409
f 1019//4450 1144//4450 1017//4450
f 1017//4451 1144//4451 1146//4451
f 1017//4452 1146//4452 1020//4452
f 1019//4449 1135//4449 1144//4449
f 4053//4446 4046//4446 4049//4446
f 4053//4447 4049//4447 4051//4447
f 1124//4448 1135//4448 1019//4448
f 1020//4453 1146//4453 1122//4453
f 1098//4458 1128//4458 1096//4458
f 1096//4459 1128//4459 1074//4459
f 1096//4460 1074//4460 1076//4460
f 1098//4457 1121//4457 1128//4457
f 1020//4454 1122//4454 1033//4454
f 1033//4455 1122//4455 1121//4455
f 1033//4456 1121//4456 1098//4456
f 1194//4445 4046//4445 4053//4445
f 3889//4434 4075//4434 4024//4434
f 4024//4435 4075//4435 4077//4435
f 4024//4436 4077//4436 1231//4436
f 3889//4433 4073//4433 4075//4433
f 1194//4430 4044//4430 4046//4430
f 4046//4431 4055//4431 4049//4431
f 4067//4432 4073//4432 3889//4432
f 1231//4437 4077//4437 791//4437
f 1136//4442 1140//4442 4053//4442
f 4053//4443 1140//4443 1191//4443
f 4053//4444 1191//4444 1194//4444
f 809//4441 1124//4441 1019//4441
f 1231//4438 791//4438 790//4438
f 808//4439 1228//4439 809//4439
f 809//4440 1228//4440 1124//4440
f 3993//4297 3980//4297 3994//4297
f 3994//4298 3980//4298 3982//4298
f 3994//4299 3982//4299 3939//4299
f 3993//4296 3981//4296 3980//4296
f 3945//4277 3931//4277 3932//4277
f 3992//4294 3983//4294 3981//4294
f 3992//4295 3981//4295 3993//4295
f 3939//4300 3982//4300 3984//4300
f 1268//4321 3975//4321 3944//4321
f 3944//4322 3975//4322 3976//4322
f 3944//4323 3976//4323 3949//4323
f 1268//4320 1267//4320 3975//4320
f 3939//4301 3984//4301 3940//4301
f 3940//4302 3984//4302 1276//4302
f 3940//4303 1276//4303 1269//4303
f 3945//4276 3930//4276 3931//4276
f 3936//4249 3923//4249 3941//4249
f 3941//4250 3923//4250 1245//4250
f 3941//4251 1245//4251 1242//4251
f 3936//4248 3918//4248 3923//4248
f 3938//4245 3919//4245 3937//4245
f 3937//4246 3919//4246 3918//4246
f 3937//4247 3918//4247 3936//4247
f 1234//4268 1233//4268 3925//4268
f 3946//4273 3927//4273 3947//4273
f 3947//4274 3927//4274 3930//4274
f 3947//4275 3930//4275 3945//4275
f 3946//4272 3924//4272 3927//4272
f 1234//4269 3925//4269 3948//4269
f 3948//4270 3925//4270 3924//4270
f 3948//4271 3924//4271 3946//4271
f 1228//4388 800//4388 4002//4388
f 4002//4389 800//4389 4063//4389
f 4002//4390 4063//4390 4065//4390
f 1228//4387 798//4387 800//4387
f 4002//4384 3889//4384 3997//4384
f 3997//4385 3889//4385 3998//4385
f 808//4386 798//4386 1228//4386
f 3813//4391 4008//4391 3889//4391
f 3889//4396 4026//4396 4016//4396
f 4005//4397 4010//4397 3889//4397
f 3889//4398 4010//4398 4000//4398
f 3889//4395 4023//4395 4026//4395
f 3889//4392 4008//4392 4006//4392
f 3889//4393 4006//4393 4005//4393
f 4024//4394 4023//4394 3889//4394
f 1210//4383 1215//4383 1206//4383
f 3935//4328 3977//4328 3978//4328
f 3935//4329 3978//4329 3995//4329
f 3995//4330 3978//4330 3985//4330
f 3934//4327 3977//4327 3935//4327
f 3949//4324 3976//4324 3979//4324
f 3949//4325 3979//4325 3934//4325
f 3934//4326 3979//4326 3977//4326
f 3995//4331 3985//4331 3933//4331
f 4052//4374 4042//4374 4053//4374
f 4053//4375 4042//4375 4033//4375
f 4019//4382 3886//4382 3821//4382
f 4083//4361 4056//4361 4079//4361
f 1206//4350 1215//4350 1207//4350
f 1207//4351 1215//4351 1209//4351
f 4082//4360 4056//4360 4083//4360
f 1167//4543 1107//4543 1038//4543
f 1167//4544 1038//4544 1164//4544
f 1116//4545 1112//4545 1111//4545
f 1158//4542 1107//4542 1167//4542
f 4011//4539 796//4539 4074//4539
f 4011//4540 4074//4540 4076//4540
f 1151//4541 1148//4541 1150//4541
f 4056//4546 4082//4546 1164//4546
f 1089//4551 4039//4551 1090//4551
f 1090//4552 4039//4552 4037//4552
f 1090//4553 4037//4553 1071//4553
f 1089//4550 4084//4550 4039//4550
f 1164//4547 4082//4547 1092//4547
f 1092//4548 4082//4548 4084//4548
f 1092//4549 4084//4549 1089//4549
f 1230//4538 796//4538 4011//4538
f 3814//4527 4025//4527 4022//4527
f 3814//4528 4022//4528 4047//4528
f 4047//4529 4022//4529 4011//4529
f 4021//4526 4025//4526 3814//4526
f 4001//4523 4062//4523 1229//4523
f 1229//4524 4062//4524 799//4524
f 1229//4525 799//4525 801//4525
f 4047//4530 4011//4530 4007//4530
f 4009//4535 1211//4535 4003//4535
f 792//4536 789//4536 1230//4536
f 1230//4537 789//4537 796//4537
f 4004//4534 1211//4534 4009//4534
f 4007//4531 4011//4531 1205//4531
f 4007//4532 1205//4532 4004//4532
f 4004//4533 1205//4533 1211//4533
f 1038//4574 1034//4574 1164//4574
f 1164//4575 1034//4575 1037//4575
f 1164//4576 1037//4576 4056//4576
f 1021//4573 1127//4573 1018//4573
f 1044//4570 1123//4570 1120//4570
f 1044//4571 1120//4571 1021//4571
f 1021//4572 1120//4572 1127//4572
f 4056//4577 1037//4577 1118//4577
f 1103//4582 1132//4582 1123//4582
f 1103//4583 1123//4583 1104//4583
f 1104//4584 1123//4584 1087//4584
f 1108//4581 1132//4581 1103//4581
f 4056//4578 1118//4578 1116//4578
f 1159//4579 1161//4579 1108//4579
f 1108//4580 1161//4580 1132//4580
f 1097//4569 1123//4569 1044//4569
f 4045//4558 1141//4558 4048//4558
f 4048//4559 1141//4559 1138//4559
f 4048//4560 1138//4560 4054//4560
f 4045//4557 1192//4557 1141//4557
f 1071//4554 4037//4554 4041//4554
f 1071//4555 4041//4555 1072//4555
f 4043//4556 1192//4556 4045//4556
f 4054//4561 1138//4561 1137//4561
f 4052//4566 1159//4566 4042//4566
f 4042//4567 1159//4567 1108//4567
f 1123//4568 1097//4568 1087//4568
f 4052//4565 1142//4565 1159//4565
f 4054//4562 1137//4562 4050//4562
f 4050//4563 1137//4563 1142//4563
f 4050//4564 1142//4564 4052//4564
f 1015//4481 1109//4481 1157//4481
f 1163//4482 1165//4482 1166//4482
f 1166//4483 1165//4483 1039//4483
f 1015//4480 1100//4480 1109//4480
f 1147//4477 1101//4477 1014//4477
f 1014//4478 1101//4478 1100//4478
f 1014//4479 1100//4479 1015//4479
f 1166//4484 1039//4484 1152//4484
f 1070//4489 1073//4489 4036//4489
f 1109//4490 1040//4490 1157//4490
f 1157//4491 1040//4491 1043//4491
f 4083//4488 1088//4488 1077//4488
f 1152//4485 1039//4485 1149//4485
f 1165//4486 1091//4486 4083//4486
f 4083//4487 1091//4487 1088//4487
f 1101//4476 1149//4476 1039//4476
f 4086//4465 1110//4465 4087//4465
f 4087//4466 1110//4466 1113//4466
f 4087//4467 1113//4467 4085//4467
f 4086//4464 1114//4464 1110//4464
f 1036//4461 1035//4461 1165//4461
f 1165//4462 1035//4462 1039//4462
f 4078//4463 1114//4463 4086//4463
f 4085//4468 1113//4468 1117//4468
f 4079//4473 1036//4473 4083//4473
f 4083//4474 1036//4474 1165//4474
f 1147//4475 1149//4475 1101//4475
f 4079//4472 1119//4472 1036//4472
f 4085//4469 1117//4469 4080//4469
f 4080//4470 1117//4470 1119//4470
f 4080//4471 1119//4471 4079//4471
f 3814//4512 4012//4512 4015//4512
f 3996//4513 3999//4513 1211//4513
f 1211//4514 3999//4514 4003//4514
f 3814//4511 4017//4511 4012//4511
f 3887//4508 4018//4508 3888//4508
f 3888//4509 4018//4509 4017//4509
f 3888//4510 4017//4510 3814//4510
f 4076//4515 4066//4515 4011//4515
f 4001//4520 4071//4520 4069//4520
f 4069//4521 4064//4521 4001//4521
f 4001//4522 4064//4522 4062//4522
f 4001//4519 1211//4519 4071//4519
f 4011//4516 4066//4516 4068//4516
f 4011//4517 4068//4517 1205//4517
f 3996//4518 1211//4518 4001//4518
f 1205//4507 1209//4507 1211//4507
f 4081//4496 4036//4496 4038//4496
f 4081//4497 4038//4497 4040//4497
f 790//4498 834//4498 1231//4498
f 4083//4495 4036//4495 4081//4495
f 1157//4492 1043//4492 1154//4492
f 1077//4493 1070//4493 4083//4493
f 4083//4494 1070//4494 4036//4494
f 1231//4499 834//4499 833//4499
f 4047//4504 3812//4504 3814//4504
f 4015//4505 4021//4505 3814//4505
f 1207//4506 1209//4506 1205//4506
f 1022//4503 1154//4503 1043//4503
f 1231//4500 833//4500 1031//4500
f 1031//4501 833//4501 1154//4501
f 1031//4502 1154//4502 1022//4502
f 4088//2668 4089//2668 4090//2668
f 4090//2669 4089//2669 4091//2669
f 4090//2670 4091//2670 4092//2670
f 4088//2667 4093//2667 4089//2667
f 4094//2664 4095//2664 4096//2664
f 4096//2665 4095//2665 4093//2665
f 4096//2666 4093//2666 4088//2666
f 4092//2671 4091//2671 4097//2671
f 4098//2676 4099//2676 4100//2676
f 4100//2677 4099//2677 4101//2677
f 4100//2678 4101//2678 4102//2678
f 4098//2675 4103//2675 4099//2675
f 4092//2672 4097//2672 4104//2672
f 4104//2673 4097//2673 4103//2673
f 4104//2674 4103//2674 4098//2674
f 4094//2663 4105//2663 4095//2663
f 4106//2652 4107//2652 4108//2652
f 4108//2653 4107//2653 4109//2653
f 4108//2654 4109//2654 4110//2654
f 4106//2651 4111//2651 4107//2651
f 4112//2648 4113//2648 4114//2648
f 4114//2649 4113//2649 4111//2649
f 4114//2650 4111//2650 4106//2650
f 4110//2655 4109//2655 4115//2655
f 4116//2660 4117//2660 4118//2660
f 4118//2661 4117//2661 4105//2661
f 4118//2662 4105//2662 4094//2662
f 4116//2659 4119//2659 4117//2659
f 4110//2656 4115//2656 4120//2656
f 4120//2657 4115//2657 4119//2657
f 4120//2658 4119//2658 4116//2658
f 4121//2699 4122//2699 4123//2699
f 4121//2700 4123//2700 4124//2700
f 4124//2701 4123//2701 4125//2701
f 4126//2698 4122//2698 4121//2698
f 4127//2695 4128//2695 4129//2695
f 4127//2696 4129//2696 4126//2696
f 4126//2697 4129//2697 4122//2697
f 4124//2702 4125//2702 4130//2702
f 4131//2707 4132//2707 4133//2707
f 4131//2708 4133//2708 4134//2708
f 4134//2709 4133//2709 4135//2709
f 4136//2706 4132//2706 4131//2706
f 4130//2703 4125//2703 4137//2703
f 4130//2704 4137//2704 4136//2704
f 4136//2705 4137//2705 4132//2705
f 4138//2694 4128//2694 4127//2694
f 4139//2683 4140//2683 4141//2683
f 4139//2684 4141//2684 4142//2684
f 4142//2685 4141//2685 4143//2685
f 4144//2682 4140//2682 4139//2682
f 4102//2679 4101//2679 4145//2679
f 4102//2680 4145//2680 4144//2680
f 4144//2681 4145//2681 4140//2681
f 4143//2686 4146//2686 4147//2686
f 4148//2691 4149//2691 4150//2691
f 4148//2692 4150//2692 4138//2692
f 4138//2693 4150//2693 4128//2693
f 4151//2690 4149//2690 4148//2690
f 4147//2687 4146//2687 4152//2687
f 4147//2688 4152//2688 4151//2688
f 4151//2689 4152//2689 4149//2689
f 4153//2606 4100//2606 4154//2606
f 4154//2607 4100//2607 4102//2607
f 4154//2608 4102//2608 4155//2608
f 4153//2605 4098//2605 4100//2605
f 4156//2602 4104//2602 4157//2602
f 4157//2603 4104//2603 4098//2603
f 4157//2604 4098//2604 4153//2604
f 4155//2609 4102//2609 4144//2609
f 4142//2614 4143//2614 4158//2614
f 4158//2615 4143//2615 4147//2615
f 4158//2616 4147//2616 4159//2616
f 4160//2613 4139//2613 4142//2613
f 4155//2610 4144//2610 4161//2610
f 4161//2611 4144//2611 4139//2611
f 4161//2612 4139//2612 4160//2612
f 4156//2601 4092//2601 4104//2601
f 4162//2590 4118//2590 4163//2590
f 4163//2591 4118//2591 4094//2591
f 4163//2592 4094//2592 4164//2592
f 4162//2589 4116//2589 4118//2589
f 4165//2586 4120//2586 4166//2586
f 4166//2587 4120//2587 4116//2587
f 4166//2588 4116//2588 4162//2588
f 4164//2593 4094//2593 4096//2593
f 4167//2598 4090//2598 4168//2598
f 4168//2599 4090//2599 4092//2599
f 4168//2600 4092//2600 4156//2600
f 4167//2597 4088//2597 4090//2597
f 4164//2594 4096//2594 4169//2594
f 4169//2595 4096//2595 4088//2595
f 4169//2596 4088//2596 4167//2596
f 4170//2637 4131//2637 4134//2637
f 4170//2638 4134//2638 4171//2638
f 4171//2639 4134//2639 4172//2639
f 4173//2636 4131//2636 4170//2636
f 4174//2633 4130//2633 4136//2633
f 4174//2634 4136//2634 4173//2634
f 4173//2635 4136//2635 4131//2635
f 4171//2640 4172//2640 4175//2640
f 4176//2645 4177//2645 4178//2645
f 4176//2646 4178//2646 4112//2646
f 4112//2647 4178//2647 4113//2647
f 4179//2644 4177//2644 4176//2644
f 4175//2641 4172//2641 4180//2641
f 4175//2642 4180//2642 4179//2642
f 4179//2643 4180//2643 4177//2643
f 4181//2632 4130//2632 4174//2632
f 4182//2621 4148//2621 4138//2621
f 4182//2622 4138//2622 4183//2622
f 4183//2623 4138//2623 4127//2623
f 4184//2620 4148//2620 4182//2620
f 4159//2617 4147//2617 4151//2617
f 4159//2618 4151//2618 4184//2618
f 4184//2619 4151//2619 4148//2619
f 4183//2624 4127//2624 4185//2624
f 4186//2629 4121//2629 4124//2629
f 4186//2630 4124//2630 4181//2630
f 4181//2631 4124//2631 4130//2631
f 4187//2628 4121//2628 4186//2628
f 4185//2625 4127//2625 4126//2625
f 4185//2626 4126//2626 4187//2626
f 4187//2627 4126//2627 4121//2627
f 4188//2792 3643//2792 4189//2792
f 4189//2793 3643//2793 3599//2793
f 4189//2794 3599//2794 4190//2794
f 4188//2791 3660//2791 3643//2791
f 4191//2788 3666//2788 4192//2788
f 4192//2789 3666//2789 3660//2789
f 4192//2790 3660//2790 4188//2790
f 4190//2795 3599//2795 3597//2795
f 4193//2800 3595//2800 4194//2800
f 4194//2801 3595//2801 3589//2801
f 4194//2802 3589//2802 4195//2802
f 4193//2799 3601//2799 3595//2799
f 4190//2796 3597//2796 4196//2796
f 4196//2797 3597//2797 3601//2797
f 4196//2798 3601//2798 4193//2798
f 4191//2787 3662//2787 3666//2787
f 4137//2776 3703//2776 4132//2776
f 4132//2777 3703//2777 3701//2777
f 4132//2778 3701//2778 4133//2778
f 4137//2775 3711//2775 3703//2775
f 4123//2772 3708//2772 4125//2772
f 4125//2773 3708//2773 3711//2773
f 4125//2774 3711//2774 4137//2774
f 4133//2779 3701//2779 3700//2779
f 4197//2784 3664//2784 4198//2784
f 4198//2785 3664//2785 3662//2785
f 4198//2786 3662//2786 4191//2786
f 4197//2783 3705//2783 3664//2783
f 4133//2780 3700//2780 4135//2780
f 4135//2781 3700//2781 3705//2781
f 4135//2782 3705//2782 4197//2782
f 4199//2823 3609//2823 3611//2823
f 4199//2824 3611//2824 4200//2824
f 4200//2825 3611//2825 3695//2825
f 4201//2822 3609//2822 4199//2822
f 4202//2819 3607//2819 3605//2819
f 4202//2820 3605//2820 4201//2820
f 4201//2821 3605//2821 3609//2821
f 4200//2826 3695//2826 4203//2826
f 4204//2831 3673//2831 4205//2831
f 4205//2832 3673//2832 4206//2832
f 4206//2833 3673//2833 3803//2833
f 3800//2830 3673//2830 4204//2830
f 4203//2827 3695//2827 3694//2827
f 4203//2828 3694//2828 4146//2828
f 4146//2829 3694//2829 3697//2829
f 4207//2818 3607//2818 4202//2818
f 4208//2807 3591//2807 3593//2807
f 4208//2808 3593//2808 4209//2808
f 4209//2809 3593//2809 3603//2809
f 4210//2806 3591//2806 4208//2806
f 4195//2803 3589//2803 3587//2803
f 4195//2804 3587//2804 4210//2804
f 4210//2805 3587//2805 3591//2805
f 4209//2810 3603//2810 4211//2810
f 4212//2815 3617//2815 3613//2815
f 4212//2816 3613//2816 4207//2816
f 4207//2817 3613//2817 3607//2817
f 4213//2814 3617//2814 4212//2814
f 4211//2811 3603//2811 3615//2811
f 4211//2812 3615//2812 4213//2812
f 4213//2813 3615//2813 3617//2813
f 4119//2730 4194//2730 4117//2730
f 4117//2731 4194//2731 4195//2731
f 4117//2732 4195//2732 4105//2732
f 4119//2729 4193//2729 4194//2729
f 4109//2726 4196//2726 4115//2726
f 4115//2727 4196//2727 4193//2727
f 4115//2728 4193//2728 4119//2728
f 4105//2733 4195//2733 4210//2733
f 4093//2738 4209//2738 4089//2738
f 4089//2739 4209//2739 4211//2739
f 4089//2740 4211//2740 4091//2740
f 4093//2737 4208//2737 4209//2737
f 4105//2734 4210//2734 4095//2734
f 4095//2735 4210//2735 4208//2735
f 4095//2736 4208//2736 4093//2736
f 4109//2725 4190//2725 4196//2725
f 4180//2714 4198//2714 4177//2714
f 4177//2715 4198//2715 4191//2715
f 4177//2716 4191//2716 4178//2716
f 4180//2713 4197//2713 4198//2713
f 4134//2710 4135//2710 4172//2710
f 4172//2711 4135//2711 4197//2711
f 4172//2712 4197//2712 4180//2712
f 4178//2717 4191//2717 4192//2717
f 4111//2722 4189//2722 4107//2722
f 4107//2723 4189//2723 4190//2723
f 4107//2724 4190//2724 4109//2724
f 4111//2721 4188//2721 4189//2721
f 4178//2718 4192//2718 4113//2718
f 4113//2719 4192//2719 4188//2719
f 4113//2720 4188//2720 4111//2720
f 4149//2761 3687//2761 3685//2761
f 4149//2762 3685//2762 4150//2762
f 4150//2763 3685//2763 3684//2763
f 4152//2760 3687//2760 4149//2760
f 4143//2757 4203//2757 4146//2757
f 4146//2758 3697//2758 4152//2758
f 4152//2759 3697//2759 3687//2759
f 4150//2764 3684//2764 4128//2764
f 4122//2769 3690//2769 3709//2769
f 4122//2770 3709//2770 4123//2770
f 4123//2771 3709//2771 3708//2771
f 4129//2768 3690//2768 4122//2768
f 4128//2765 3684//2765 3691//2765
f 4128//2766 3691//2766 4129//2766
f 4129//2767 3691//2767 3690//2767
f 4141//2756 4203//2756 4143//2756
f 4103//2745 4212//2745 4207//2745
f 4103//2746 4207//2746 4099//2746
f 4099//2747 4207//2747 4202//2747
f 4097//2744 4212//2744 4103//2744
f 4091//2741 4211//2741 4213//2741
f 4091//2742 4213//2742 4097//2742
f 4097//2743 4213//2743 4212//2743
f 4099//2748 4202//2748 4101//2748
f 4140//2753 4199//2753 4200//2753
f 4140//2754 4200//2754 4141//2754
f 4141//2755 4200//2755 4203//2755
f 4145//2752 4199//2752 4140//2752
f 4101//2749 4202//2749 4201//2749
f 4101//2750 4201//2750 4145//2750
f 4145//2751 4201//2751 4199//2751
f 4165//2585 4110//2585 4120//2585
f 4214//2419 4215//2419 4216//2419
f 4214//2420 4216//2420 4217//2420
f 4217//2421 4216//2421 4218//2421
f 4219//2418 4215//2418 4214//2418
f 4220//2415 4221//2415 4222//2415
f 4220//2416 4222//2416 4219//2416
f 4219//2417 4222//2417 4215//2417
f 4217//2422 4218//2422 4223//2422
f 4224//2427 4225//2427 4226//2427
f 4224//2428 4226//2428 4227//2428
f 4227//2429 4226//2429 4228//2429
f 4229//2426 4225//2426 4224//2426
f 4223//2423 4218//2423 4230//2423
f 4223//2424 4230//2424 4229//2424
f 4229//2425 4230//2425 4225//2425
f 4231//2414 4221//2414 4220//2414
f 3750//2403 4232//2403 4233//2403
f 3750//2404 4233//2404 3751//2404
f 3751//2405 4233//2405 4234//2405
f 3755//2402 4232//2402 3750//2402
f 3743//2399 4235//2399 4236//2399
f 3743//2400 4236//2400 3755//2400
f 3755//2401 4236//2401 4232//2401
f 3751//2406 4234//2406 3753//2406
f 4237//2411 4238//2411 4239//2411
f 4237//2412 4239//2412 4231//2412
f 4231//2413 4239//2413 4221//2413
f 4240//2410 4238//2410 4237//2410
f 3753//2407 4234//2407 4241//2407
f 3753//2408 4241//2408 4240//2408
f 4240//2409 4241//2409 4238//2409
f 4242//2450 4243//2450 4244//2450
f 4244//2451 4243//2451 4245//2451
f 4244//2452 4245//2452 4246//2452
f 4242//2449 4247//2449 4243//2449
f 4248//2446 4249//2446 4250//2446
f 4250//2447 4249//2447 4247//2447
f 4250//2448 4247//2448 4242//2448
f 4246//2453 4245//2453 4251//2453
f 4252//2458 4253//2458 4254//2458
f 4254//2459 4253//2459 4255//2459
f 4254//2460 4255//2460 4256//2460
f 4252//2457 4257//2457 4253//2457
f 4246//2454 4251//2454 4258//2454
f 4258//2455 4251//2455 4257//2455
f 4258//2456 4257//2456 4252//2456
f 4248//2445 4259//2445 4249//2445
f 4260//2434 4261//2434 4262//2434
f 4262//2435 4261//2435 4263//2435
f 4262//2436 4263//2436 4264//2436
f 4260//2433 4265//2433 4261//2433
f 4227//2430 4228//2430 4266//2430
f 4266//2431 4228//2431 4265//2431
f 4266//2432 4265//2432 4260//2432
f 4264//2437 4263//2437 4267//2437
f 4268//2442 4269//2442 4270//2442
f 4270//2443 4269//2443 4259//2443
f 4270//2444 4259//2444 4248//2444
f 4268//2441 4271//2441 4269//2441
f 4264//2438 4267//2438 4272//2438
f 4272//2439 4267//2439 4271//2439
f 4272//2440 4271//2440 4268//2440
f 3736//2357 4224//2357 4227//2357
f 3736//2358 4227//2358 3729//2358
f 3729//2359 4227//2359 4266//2359
f 3738//2356 4224//2356 3736//2356
f 3734//2353 4223//2353 4229//2353
f 3734//2354 4229//2354 3738//2354
f 3738//2355 4229//2355 4224//2355
f 3729//2360 4266//2360 3719//2360
f 3713//2365 4262//2365 4264//2365
f 3713//2366 4264//2366 3715//2366
f 3715//2367 4264//2367 4272//2367
f 3717//2364 4262//2364 3713//2364
f 3719//2361 4266//2361 4260//2361
f 3719//2362 4260//2362 3717//2362
f 3717//2363 4260//2363 4262//2363
f 3731//2352 4223//2352 3734//2352
f 3787//2341 4237//2341 4231//2341
f 3787//2342 4231//2342 3788//2342
f 3788//2343 4231//2343 4220//2343
f 3789//2340 4237//2340 3787//2340
f 3786//2337 3753//2337 4240//2337
f 3786//2338 4240//2338 3789//2338
f 3789//2339 4240//2339 4237//2339
f 3788//2344 4220//2344 3733//2344
f 3730//2349 4214//2349 4217//2349
f 3730//2350 4217//2350 3731//2350
f 3731//2351 4217//2351 4223//2351
f 3732//2348 4214//2348 3730//2348
f 3733//2345 4220//2345 4219//2345
f 3733//2346 4219//2346 3732//2346
f 3732//2347 4219//2347 4214//2347
f 3766//2388 4254//2388 3767//2388
f 3767//2389 4254//2389 4256//2389
f 3767//2390 4256//2390 3746//2390
f 3766//2387 4252//2387 4254//2387
f 3761//2384 4258//2384 3769//2384
f 3769//2385 4258//2385 4252//2385
f 3769//2386 4252//2386 3766//2386
f 3746//2391 4256//2391 4273//2391
f 3739//2396 4274//2396 3740//2396
f 3740//2397 4274//2397 3741//2397
f 3741//2398 4235//2398 3743//2398
f 3739//2395 4275//2395 4274//2395
f 3746//2392 4273//2392 3747//2392
f 3747//2393 4273//2393 4275//2393
f 3747//2394 4275//2394 3739//2394
f 3761//2383 4246//2383 4258//2383
f 3727//2372 4270//2372 3723//2372
f 3723//2373 4270//2373 4248//2373
f 3723//2374 4248//2374 3725//2374
f 3727//2371 4268//2371 4270//2371
f 3715//2368 4272//2368 3721//2368
f 3721//2369 4272//2369 4268//2369
f 3721//2370 4268//2370 3727//2370
f 3725//2375 4248//2375 4250//2375
f 3758//2380 4244//2380 3759//2380
f 3759//2381 4244//2381 4246//2381
f 3759//2382 4246//2382 3761//2382
f 3758//2379 4242//2379 4244//2379
f 3725//2376 4250//2376 3763//2376
f 3763//2377 4250//2377 4242//2377
f 3763//2378 4242//2378 3758//2378
f 4276//2543 4142//2543 4158//2543
f 4276//2544 4158//2544 4277//2544
f 4277//2545 4158//2545 4159//2545
f 4160//2542 4142//2542 4276//2542
f 4278//2539 4155//2539 4161//2539
f 4278//2540 4161//2540 4235//2540
f 4235//2541 4161//2541 4160//2541
f 4277//2546 4159//2546 4279//2546
f 4280//2551 4182//2551 4183//2551
f 4280//2552 4183//2552 4281//2552
f 4281//2553 4183//2553 4185//2553
f 4282//2550 4182//2550 4280//2550
f 4279//2547 4159//2547 4184//2547
f 4279//2548 4184//2548 4282//2548
f 4282//2549 4184//2549 4182//2549
f 4283//2538 4155//2538 4278//2538
f 4257//2527 4167//2527 4168//2527
f 4257//2528 4168//2528 4253//2528
f 4253//2529 4168//2529 4156//2529
f 4251//2526 4167//2526 4257//2526
f 4245//2523 4164//2523 4169//2523
f 4245//2524 4169//2524 4251//2524
f 4251//2525 4169//2525 4167//2525
f 4253//2530 4156//2530 4255//2530
f 4284//2535 4153//2535 4154//2535
f 4284//2536 4154//2536 4283//2536
f 4283//2537 4154//2537 4155//2537
f 4285//2534 4153//2534 4284//2534
f 4255//2531 4156//2531 4157//2531
f 4255//2532 4157//2532 4285//2532
f 4285//2533 4157//2533 4153//2533
f 4286//2574 4176//2574 4287//2574
f 4287//2575 4176//2575 4112//2575
f 4287//2576 4112//2576 4288//2576
f 4286//2573 4179//2573 4176//2573
f 4289//2570 4175//2570 4290//2570
f 4290//2571 4175//2571 4179//2571
f 4290//2572 4179//2572 4286//2572
f 4288//2577 4112//2577 4114//2577
f 4291//2582 4108//2582 4292//2582
f 4292//2583 4108//2583 4110//2583
f 4292//2584 4110//2584 4165//2584
f 4291//2581 4106//2581 4108//2581
f 4288//2578 4114//2578 4293//2578
f 4293//2579 4114//2579 4106//2579
f 4293//2580 4106//2580 4291//2580
f 4289//2569 4171//2569 4175//2569
f 4294//2558 4186//2558 4295//2558
f 4295//2559 4186//2559 4181//2559
f 4295//2560 4181//2560 4296//2560
f 4294//2557 4187//2557 4186//2557
f 4281//2554 4185//2554 4297//2554
f 4297//2555 4185//2555 4187//2555
f 4297//2556 4187//2556 4294//2556
f 4296//2561 4181//2561 4174//2561
f 4298//2566 4170//2566 4299//2566
f 4299//2567 4170//2567 4171//2567
f 4299//2568 4171//2568 4289//2568
f 4298//2565 4173//2565 4170//2565
f 4296//2562 4174//2562 4300//2562
f 4300//2563 4174//2563 4173//2563
f 4300//2564 4173//2564 4298//2564
f 4238//2481 4280//2481 4281//2481
f 4238//2482 4281//2482 4239//2482
f 4239//2483 4281//2483 4297//2483
f 4241//2480 4280//2480 4238//2480
f 4234//2477 4279//2477 4282//2477
f 4234//2478 4282//2478 4241//2478
f 4241//2479 4282//2479 4280//2479
f 4239//2484 4297//2484 4221//2484
f 4215//2489 4295//2489 4296//2489
f 4215//2490 4296//2490 4216//2490
f 4216//2491 4296//2491 4300//2491
f 4222//2488 4295//2488 4215//2488
f 4221//2485 4297//2485 4294//2485
f 4221//2486 4294//2486 4222//2486
f 4222//2487 4294//2487 4295//2487
f 4233//2476 4279//2476 4234//2476
f 4275//2465 4284//2465 4283//2465
f 4275//2466 4283//2466 4274//2466
f 4274//2467 4283//2467 4278//2467
f 4273//2464 4284//2464 4275//2464
f 4256//2461 4255//2461 4285//2461
f 4256//2462 4285//2462 4273//2462
f 4273//2463 4285//2463 4284//2463
f 4274//2468 4278//2468 3741//2468
f 4232//2473 4276//2473 4277//2473
f 4232//2474 4277//2474 4233//2474
f 4233//2475 4277//2475 4279//2475
f 4236//2472 4276//2472 4232//2472
f 3741//2469 4278//2469 4235//2469
f 4235//2470 4160//2470 4236//2470
f 4236//2471 4160//2471 4276//2471
f 4271//2512 4292//2512 4269//2512
f 4269//2513 4292//2513 4165//2513
f 4269//2514 4165//2514 4259//2514
f 4271//2511 4291//2511 4292//2511
f 4263//2508 4293//2508 4267//2508
f 4267//2509 4293//2509 4291//2509
f 4267//2510 4291//2510 4271//2510
f 4259//2515 4165//2515 4166//2515
f 4247//2520 4163//2520 4243//2520
f 4243//2521 4163//2521 4164//2521
f 4243//2522 4164//2522 4245//2522
f 4247//2519 4162//2519 4163//2519
f 4259//2516 4166//2516 4249//2516
f 4249//2517 4166//2517 4162//2517
f 4249//2518 4162//2518 4247//2518
f 4263//2507 4288//2507 4293//2507
f 4230//2496 4299//2496 4225//2496
f 4225//2497 4299//2497 4289//2497
f 4225//2498 4289//2498 4226//2498
f 4230//2495 4298//2495 4299//2495
f 4216//2492 4300//2492 4218//2492
f 4218//2493 4300//2493 4298//2493
f 4218//2494 4298//2494 4230//2494
f 4226//2499 4289//2499 4290//2499
f 4265//2504 4287//2504 4261//2504
f 4261//2505 4287//2505 4288//2505
f 4261//2506 4288//2506 4263//2506
f 4265//2503 4286//2503 4287//2503
f 4226//2500 4290//2500 4228//2500
f 4228//2501 4290//2501 4286//2501
f 4228//2502 4286//2502 4265//2502
f 4206//2834 3803//2834 4301//2834
f 4302//3459 4303//3459 4304//3459
f 4304//3460 4303//3460 4305//3460
f 4304//3461 4305//3461 4306//3461
f 4307//3458 4308//3458 4302//3458
f 4309//3455 4310//3455 4311//3455
f 4311//3456 4310//3456 4308//3456
f 4311//3457 4308//3457 4307//3457
f 4306//3462 4305//3462 4312//3462
f 4313//3467 4314//3467 4315//3467
f 4315//3468 4314//3468 4316//3468
f 4315//3469 4316//3469 4317//3469
f 4313//3466 4318//3466 4314//3466
f 4306//3463 4312//3463 4319//3463
f 4319//3464 4312//3464 4318//3464
f 4319//3465 4318//3465 4313//3465
f 4309//3454 4320//3454 4310//3454
f 4321//3443 4322//3443 4323//3443
f 4323//3444 4322//3444 4324//3444
f 4323//3445 4324//3445 4325//3445
f 4321//3442 4326//3442 4322//3442
f 4327//3439 4328//3439 4329//3439
f 4329//3440 4328//3440 4326//3440
f 4329//3441 4326//3441 4321//3441
f 4325//3446 4324//3446 4330//3446
f 4331//3451 4332//3451 4333//3451
f 4333//3452 4332//3452 4320//3452
f 4333//3453 4320//3453 4309//3453
f 4331//3450 4334//3450 4332//3450
f 4325//3447 4330//3447 4335//3447
f 4335//3448 4330//3448 4334//3448
f 4335//3449 4334//3449 4331//3449
f 4336//3490 4337//3490 4338//3490
f 4336//3491 4338//3491 4339//3491
f 4339//3492 4338//3492 4340//3492
f 4341//3489 4337//3489 4336//3489
f 4342//3486 4343//3486 4344//3486
f 4342//3487 4344//3487 4341//3487
f 4341//3488 4344//3488 4337//3488
f 4339//3493 4340//3493 4345//3493
f 4346//3498 4347//3498 4348//3498
f 4346//3499 4348//3499 4349//3499
f 4349//3500 4348//3500 4350//3500
f 4351//3497 4347//3497 4346//3497
f 4345//3494 4340//3494 4352//3494
f 4345//3495 4352//3495 4351//3495
f 4351//3496 4352//3496 4347//3496
f 4353//3485 4343//3485 4342//3485
f 4354//3474 4355//3474 4356//3474
f 4354//3475 4356//3475 4357//3475
f 4357//3476 4356//3476 4358//3476
f 4359//3473 4355//3473 4354//3473
f 4317//3470 4316//3470 4360//3470
f 4317//3471 4360//3471 4359//3471
f 4359//3472 4360//3472 4355//3472
f 4357//3477 4358//3477 4361//3477
f 4362//3482 4363//3482 4364//3482
f 4362//3483 4364//3483 4353//3483
f 4353//3484 4364//3484 4343//3484
f 4365//3481 4363//3481 4362//3481
f 4361//3478 4358//3478 4366//3478
f 4361//3479 4366//3479 4365//3479
f 4365//3480 4366//3480 4363//3480
f 4367//3397 4315//3397 4368//3397
f 4368//3398 4315//3398 4317//3398
f 4368//3399 4317//3399 4369//3399
f 4367//3396 4313//3396 4315//3396
f 4370//3393 4319//3393 4371//3393
f 4371//3394 4319//3394 4313//3394
f 4371//3395 4313//3395 4367//3395
f 4369//3400 4317//3400 4359//3400
f 4372//3405 4357//3405 4373//3405
f 4373//3406 4357//3406 4361//3406
f 4373//3407 4361//3407 4374//3407
f 4372//3404 4354//3404 4357//3404
f 4369//3401 4359//3401 4375//3401
f 4375//3402 4359//3402 4354//3402
f 4375//3403 4354//3403 4372//3403
f 4370//3392 4306//3392 4319//3392
f 4376//3381 4333//3381 4377//3381
f 4377//3382 4333//3382 4309//3382
f 4377//3383 4309//3383 4378//3383
f 4376//3380 4331//3380 4333//3380
f 4379//3377 4335//3377 4380//3377
f 4380//3378 4335//3378 4331//3378
f 4380//3379 4331//3379 4376//3379
f 4378//3384 4309//3384 4311//3384
f 4381//3389 4304//3389 4382//3389
f 4382//3390 4304//3390 4306//3390
f 4382//3391 4306//3391 4370//3391
f 4381//3388 4302//3388 4304//3388
f 4378//3385 4311//3385 4383//3385
f 4383//3386 4311//3386 4307//3386
f 4307//3387 4302//3387 4381//3387
f 4384//3428 4346//3428 4349//3428
f 4384//3429 4349//3429 4385//3429
f 4385//3430 4349//3430 4386//3430
f 4387//3427 4346//3427 4384//3427
f 4388//3424 4345//3424 4351//3424
f 4388//3425 4351//3425 4387//3425
f 4387//3426 4351//3426 4346//3426
f 4385//3431 4386//3431 4389//3431
f 4390//3436 4391//3436 4392//3436
f 4390//3437 4392//3437 4327//3437
f 4327//3438 4392//3438 4328//3438
f 4393//3435 4391//3435 4390//3435
f 4389//3432 4386//3432 4394//3432
f 4389//3433 4394//3433 4393//3433
f 4393//3434 4394//3434 4391//3434
f 4395//3423 4345//3423 4388//3423
f 4396//3412 4362//3412 4353//3412
f 4396//3413 4353//3413 4397//3413
f 4397//3414 4353//3414 4342//3414
f 4398//3411 4362//3411 4396//3411
f 4374//3408 4361//3408 4365//3408
f 4374//3409 4365//3409 4398//3409
f 4398//3410 4365//3410 4362//3410
f 4397//3415 4342//3415 4399//3415
f 4400//3420 4336//3420 4339//3420
f 4400//3421 4339//3421 4395//3421
f 4395//3422 4339//3422 4345//3422
f 4401//3419 4336//3419 4400//3419
f 4399//3416 4342//3416 4341//3416
f 4399//3417 4341//3417 4401//3417
f 4401//3418 4341//3418 4336//3418
f 4402//3583 3592//3583 4403//3583
f 4403//3584 3592//3584 3594//3584
f 4403//3585 3594//3585 4404//3585
f 4402//3582 3616//3582 3592//3582
f 4405//3579 3614//3579 4406//3579
f 4406//3580 3614//3580 3616//3580
f 4406//3581 3616//3581 4402//3581
f 4404//3586 3594//3586 3586//3586
f 4407//3591 3590//3591 4408//3591
f 4408//3592 3590//3592 3602//3592
f 4408//3593 3602//3593 4409//3593
f 4407//3590 3588//3590 3590//3590
f 4404//3587 3586//3587 4410//3587
f 4410//3588 3586//3588 3588//3588
f 4410//3589 3588//3589 4407//3589
f 4405//3578 3618//3578 3614//3578
f 4352//3567 3610//3567 4347//3567
f 4347//3568 3610//3568 3612//3568
f 4347//3569 3612//3569 4348//3569
f 4352//3566 3693//3566 3610//3566
f 4338//3563 3696//3563 4340//3563
f 4340//3564 3696//3564 3693//3564
f 4340//3565 3693//3565 4352//3565
f 4348//3570 3612//3570 3604//3570
f 4411//3575 3608//3575 4412//3575
f 4412//3576 3608//3576 3618//3576
f 4412//3577 3618//3577 4405//3577
f 4411//3574 3606//3574 3608//3574
f 4348//3571 3604//3571 4350//3571
f 4350//3572 3604//3572 3606//3572
f 4350//3573 3606//3573 4411//3573
f 3315//3614 3316//3614 3897//3614
f 3897//3615 3316//3615 3318//3615
f 3897//3616 3318//3616 2654//3616
f 3245//3613 2612//3613 2654//3613
f 3877//3610 2362//3610 2385//3610
f 3897//3611 3319//3611 3315//3611
f 3171//3612 2612//3612 3245//3612
f 2654//3617 3318//3617 3343//3617
f 720//3626 2348//3626 2314//3626
f 720//3627 2314//3627 3145//3627
f 3145//3628 2314//3628 3093//3628
f 719//3625 2348//3625 720//3625
f 2654//3618 3343//3618 3245//3618
f 2266//3620 719//3620 718//3620
f 3203//3624 3211//3624 3179//3624
f 3877//3609 2363//3609 2362//3609
f 4413//3598 3598//3598 3600//3598
f 4413//3599 3600//3599 4414//3599
f 4414//3600 3600//3600 3656//3600
f 4415//3597 3598//3597 4413//3597
f 4409//3594 3602//3594 3596//3594
f 4409//3595 3596//3595 4415//3595
f 4415//3596 3596//3596 3598//3596
f 4414//3601 3656//3601 4303//3601
f 2256//3606 2278//3606 2384//3606
f 2384//3607 2278//3607 2363//3607
f 2384//3608 2363//3608 3877//3608
f 2384//3605 2157//3605 2256//3605
f 4303//3602 3656//3602 3667//3602
f 2289//3603 3877//3603 2292//3603
f 2292//3604 3877//3604 2385//3604
f 4334//3521 4408//3521 4332//3521
f 4332//3522 4408//3522 4409//3522
f 4332//3523 4409//3523 4320//3523
f 4334//3520 4407//3520 4408//3520
f 4324//3517 4410//3517 4330//3517
f 4330//3518 4410//3518 4407//3518
f 4330//3519 4407//3519 4334//3519
f 4320//3524 4409//3524 4415//3524
f 4308//3529 4414//3529 4302//3529
f 4302//3530 4414//3530 4303//3530
f 4303//3531 3667//3531 4305//3531
f 4308//3528 4413//3528 4414//3528
f 4320//3525 4415//3525 4310//3525
f 4310//3526 4415//3526 4413//3526
f 4310//3527 4413//3527 4308//3527
f 4324//3516 4404//3516 4410//3516
f 4394//3505 4412//3505 4391//3505
f 4391//3506 4412//3506 4405//3506
f 4391//3507 4405//3507 4392//3507
f 4394//3504 4411//3504 4412//3504
f 4349//3501 4350//3501 4386//3501
f 4386//3502 4350//3502 4411//3502
f 4386//3503 4411//3503 4394//3503
f 4392//3508 4405//3508 4406//3508
f 4326//3513 4403//3513 4322//3513
f 4322//3514 4403//3514 4404//3514
f 4322//3515 4404//3515 4324//3515
f 4326//3512 4402//3512 4403//3512
f 4392//3509 4406//3509 4328//3509
f 4328//3510 4406//3510 4402//3510
f 4328//3511 4402//3511 4326//3511
f 4363//3552 3698//3552 3689//3552
f 4363//3553 3689//3553 4364//3553
f 4364//3554 3689//3554 3688//3554
f 4366//3551 3698//3551 4363//3551
f 4358//3548 3710//3548 3707//3548
f 4358//3549 3707//3549 4366//3549
f 4366//3550 3707//3550 3698//3550
f 4364//3555 3688//3555 4343//3555
f 4337//3560 3686//3560 3692//3560
f 4337//3561 3692//3561 4338//3561
f 4338//3562 3692//3562 3696//3562
f 4344//3559 3686//3559 4337//3559
f 4343//3556 3688//3556 3683//3556
f 4343//3557 3683//3557 4344//3557
f 4344//3558 3683//3558 3686//3558
f 4356//3547 3710//3547 4358//3547
f 4318//3536 3663//3536 3665//3536
f 4318//3537 3665//3537 4314//3537
f 4314//3538 3665//3538 3704//3538
f 4312//3535 3663//3535 4318//3535
f 4305//3532 3667//3532 3661//3532
f 4305//3533 3661//3533 4312//3533
f 4312//3534 3661//3534 3663//3534
f 4314//3539 3704//3539 4316//3539
f 4355//3544 3702//3544 3706//3544
f 4355//3545 3706//3545 4356//3545
f 4356//3546 3706//3546 3710//3546
f 4360//3543 3702//3543 4355//3543
f 4316//3540 3704//3540 3699//3540
f 4316//3541 3699//3541 4360//3541
f 4360//3542 3699//3542 3702//3542
f 4379//3376 4325//3376 4335//3376
f 4416//2917 3795//2917 4417//2917
f 4417//2918 3795//2918 3655//2918
f 4417//2919 3655//2919 3677//2919
f 4418//2916 4419//2916 4420//2916
f 3854//2913 3895//2913 4421//2913
f 4421//2914 3895//2914 4419//2914
f 4421//2915 4419//2915 4418//2915
f 4416//2920 4422//2920 3795//2920
f 3796//2925 4423//2925 2561//2925
f 2561//2926 4423//2926 4424//2926
f 2561//2927 4424//2927 2562//2927
f 3796//2924 4425//2924 4423//2924
f 3795//2921 4422//2921 4426//2921
f 3795//2922 4426//2922 3796//2922
f 4426//2923 4425//2923 3796//2923
f 4427//2912 4428//2912 4429//2912
f 4430//2901 4431//2901 4432//2901
f 4432//2902 4431//2902 4433//2902
f 4432//2903 4433//2903 4434//2903
f 4430//2900 4435//2900 4431//2900
f 4436//2897 4437//2897 4438//2897
f 4438//2898 4437//2898 4435//2898
f 4438//2899 4435//2899 4430//2899
f 4439//2904 4440//2904 4433//2904
f 4440//2909 4427//2909 4429//2909
f 4420//2910 4419//2910 4427//2910
f 4427//2911 4419//2911 4428//2911
f 4440//2908 4441//2908 4427//2908
f 4433//2905 4440//2905 4442//2905
f 4433//2906 4442//2906 4434//2906
f 4439//2907 4441//2907 4440//2907
f 2526//2948 2563//2948 3526//2948
f 3526//2949 2563//2949 2565//2949
f 3894//2950 3526//2950 4419//2950
f 4442//2947 2560//2947 2559//2947
f 4440//2944 2531//2944 2530//2944
f 4440//2945 2530//2945 4442//2945
f 4442//2946 2530//2946 2560//2946
f 4419//2951 3526//2951 2565//2951
f 336//3028 4443//3028 337//3028
f 323//3092 397//3092 4444//3092
f 323//3093 4444//3093 318//3093
f 321//3027 4443//3027 336//3027
f 4419//2952 2565//2952 4428//2952
f 4428//2953 2565//2953 2564//2953
f 4428//2954 2564//2954 2531//2954
f 4429//2943 2531//2943 4440//2943
f 2566//2932 4445//2932 2567//2932
f 4445//2933 4438//2933 2567//2933
f 2567//2934 4438//2934 4430//2934
f 2569//2931 4445//2931 2566//2931
f 2562//2928 4424//2928 4446//2928
f 2562//2929 4446//2929 2569//2929
f 2569//2930 4446//2930 4445//2930
f 2567//2935 4430//2935 2568//2935
f 4430//2940 2571//2940 2570//2940
f 4430//2941 2570//2941 2568//2941
f 4429//2942 4428//2942 2531//2942
f 4432//2939 2571//2939 4430//2939
f 4434//2936 4442//2936 2559//2936
f 4434//2937 2559//2937 4432//2937
f 4432//2938 2559//2938 2571//2938
f 4427//2855 2734//2855 4420//2855
f 2637//2856 2640//2856 4418//2856
f 4420//2857 2734//2857 4418//2857
f 4441//2854 2734//2854 4427//2854
f 4439//2851 2642//2851 2735//2851
f 4439//2852 2735//2852 4441//2852
f 4441//2853 2735//2853 2734//2853
f 4418//2858 2734//2858 2636//2858
f 4418//2863 2730//2863 4421//2863
f 4421//2864 2730//2864 2733//2864
f 4421//2865 2733//2865 3896//2865
f 4418//2862 2731//2862 2730//2862
f 4418//2859 2636//2859 2637//2859
f 3896//2860 3854//2860 4421//2860
f 2640//2861 2731//2861 4418//2861
f 2643//2850 2642//2850 4439//2850
f 2653//2839 4447//2839 3797//2839
f 3797//2840 4447//2840 4448//2840
f 3797//2841 4448//2841 3803//2841
f 2653//2838 4436//2838 4447//2838
f 2638//2835 4437//2835 2639//2835
f 2639//2836 4437//2836 4436//2836
f 2639//2837 4436//2837 2653//2837
f 3803//2842 4448//2842 4449//2842
f 2641//2847 4431//2847 2638//2847
f 2638//2848 4431//2848 4435//2848
f 2638//2849 4435//2849 4437//2849
f 2641//2846 4433//2846 4431//2846
f 3803//2843 4449//2843 4301//2843
f 2641//2844 2643//2844 4439//2844
f 4439//2845 4433//2845 2641//2845
f 4426//2886 4206//2886 4301//2886
f 4426//2887 4301//2887 4425//2887
f 4449//2888 4424//2888 4301//2888
f 4205//2885 4206//2885 4426//2885
f 4205//2882 4426//2882 4204//2882
f 4204//2883 4426//2883 4422//2883
f 4204//2884 4422//2884 4416//2884
f 4301//2889 4424//2889 4423//2889
f 4424//2894 4448//2894 4447//2894
f 4424//2895 4447//2895 4446//2895
f 4446//2896 4447//2896 4445//2896
f 4449//2893 4448//2893 4424//2893
f 4301//2890 4423//2890 4425//2890
f 4447//2891 4436//2891 4445//2891
f 4445//2892 4436//2892 4438//2892
f 4417//2881 4204//2881 4416//2881
f 3801//2870 3677//2870 3671//2870
f 3671//2871 3670//2871 3801//2871
f 3801//2872 3670//2872 3669//2872
f 4417//2869 3677//2869 3801//2869
f 3896//2866 2733//2866 2651//2866
f 3896//2867 2651//2867 2650//2867
f 3895//2868 3894//2868 4419//2868
f 3801//2873 3669//2873 3802//2873
f 3674//2878 3672//2878 3675//2878
f 3801//2879 3800//2879 4417//2879
f 4417//2880 3800//2880 4204//2880
f 3674//2877 3668//2877 3672//2877
f 3802//2874 3669//2874 3668//2874
f 3802//2875 3668//2875 3794//2875
f 3794//2876 3668//2876 3674//2876
f 647//3334 4372//3334 4373//3334
f 647//3335 4373//3335 648//3335
f 648//3336 4373//3336 4374//3336
f 646//3333 4372//3333 647//3333
f 649//3330 4369//3330 4375//3330
f 649//3331 4375//3331 646//3331
f 646//3332 4375//3332 4372//3332
f 648//3337 4374//3337 650//3337
f 756//3342 4396//3342 4397//3342
f 756//3343 4397//3343 754//3343
f 754//3344 4397//3344 4399//3344
f 651//3341 4396//3341 756//3341
f 650//3338 4374//3338 4398//3338
f 650//3339 4398//3339 651//3339
f 651//3340 4398//3340 4396//3340
f 627//3329 4369//3329 649//3329
f 4450//3318 4381//3318 4382//3318
f 4450//3319 4382//3319 4451//3319
f 4451//3320 4382//3320 4370//3320
f 4452//3317 4381//3317 4450//3317
f 4453//3314 4378//3314 4383//3314
f 4383//3315 4307//3315 4452//3315
f 4452//3316 4307//3316 4381//3316
f 4451//3321 4370//3321 614//3321
f 626//3326 4367//3326 4368//3326
f 626//3327 4368//3327 627//3327
f 627//3328 4368//3328 4369//3328
f 615//3325 4367//3325 626//3325
f 614//3322 4370//3322 4371//3322
f 614//3323 4371//3323 615//3323
f 615//3324 4371//3324 4367//3324
f 752//3365 4390//3365 774//3365
f 774//3366 4390//3366 4327//3366
f 774//3367 4327//3367 772//3367
f 752//3364 4393//3364 4390//3364
f 750//3361 4389//3361 751//3361
f 751//3362 4389//3362 4393//3362
f 751//3363 4393//3363 752//3363
f 772//3368 4327//3368 4329//3368
f 777//3373 4323//3373 4454//3373
f 4454//3374 4323//3374 4325//3374
f 4454//3375 4325//3375 4379//3375
f 777//3372 4321//3372 4323//3372
f 772//3369 4329//3369 773//3369
f 773//3370 4329//3370 4321//3370
f 773//3371 4321//3371 777//3371
f 750//3360 4385//3360 4389//3360
f 757//3349 4400//3349 758//3349
f 758//3350 4400//3350 4395//3350
f 758//3351 4395//3351 749//3351
f 757//3348 4401//3348 4400//3348
f 754//3345 4399//3345 755//3345
f 755//3346 4399//3346 4401//3346
f 755//3347 4401//3347 757//3347
f 749//3352 4395//3352 4388//3352
f 747//3357 4384//3357 748//3357
f 748//3358 4384//3358 4385//3358
f 748//3359 4385//3359 750//3359
f 747//3356 4387//3356 4384//3356
f 749//3353 4388//3353 746//3353
f 746//3354 4388//3354 4387//3354
f 746//3355 4387//3355 747//3355
f 4443//3170 4455//3170 4456//3170
f 4456//3171 4453//3171 4457//3171
f 4457//3172 4453//3172 4458//3172
f 4459//3169 4455//3169 4443//3169
f 4460//3166 4461//3166 4462//3166
f 4460//3167 4462//3167 4459//3167
f 4459//3168 4462//3168 4455//3168
f 4457//3173 4458//3173 4463//3173
f 117//3178 4464//3178 31//3178
f 77//3231 638//3231 79//3231
f 79//3232 638//3232 4465//3232
f 398//3177 4464//3177 117//3177
f 4463//3174 4458//3174 4466//3174
f 4463//3175 4466//3175 398//3175
f 398//3176 4466//3176 4464//3176
f 4444//3165 4461//3165 4460//3165
f 321//3098 4459//3098 4443//3098
f 4443//3099 4456//3099 337//3099
f 337//3100 4456//3100 4457//3100
f 319//3097 4459//3097 321//3097
f 318//3094 4444//3094 4460//3094
f 318//3095 4460//3095 319//3095
f 319//3096 4460//3096 4459//3096
f 337//3101 4457//3101 331//3101
f 397//3162 79//3162 4467//3162
f 397//3163 4467//3163 4444//3163
f 4444//3164 4467//3164 4461//3164
f 78//3161 79//3161 397//3161
f 331//3102 4457//3102 4463//3102
f 331//3103 4463//3103 333//3103
f 333//3104 4463//3104 398//3104
f 638//3303 4454//3303 4465//3303
f 4465//3304 4454//3304 4379//3304
f 4465//3305 4379//3305 4468//3305
f 638//3302 777//3302 4454//3302
f 4464//3249 4451//3249 31//3249
f 31//3250 4451//3250 614//3250
f 31//3251 614//3251 7//3251
f 4468//3306 4379//3306 4380//3306
f 4469//3311 4377//3311 4470//3311
f 4470//3312 4377//3312 4378//3312
f 4470//3313 4378//3313 4453//3313
f 4469//3310 4376//3310 4377//3310
f 4468//3307 4380//3307 4471//3307
f 4471//3308 4380//3308 4376//3308
f 4471//3309 4376//3309 4469//3309
f 4464//3248 4450//3248 4451//3248
f 4461//3237 4471//3237 4462//3237
f 4462//3238 4471//3238 4469//3238
f 4462//3239 4469//3239 4455//3239
f 4461//3236 4468//3236 4471//3236
f 79//3233 4465//3233 4467//3233
f 4467//3234 4465//3234 4468//3234
f 4467//3235 4468//3235 4461//3235
f 4455//3240 4469//3240 4470//3240
f 4458//3245 4452//3245 4466//3245
f 4466//3246 4452//3246 4450//3246
f 4466//3247 4450//3247 4464//3247
f 4458//3244 4383//3244 4452//3244
f 4455//3241 4470//3241 4456//3241
f 4456//3242 4470//3242 4453//3242
f 4453//3243 4383//3243 4458//3243
####################################
`
export const MODEL_CUBE = `####################################
# netfabb Wavefront export         #
####################################
mtllib 10cm_test_cube_(analysisadded).mtl
v -1.45435 0.00000 100.00000
v -1.45435 100.00000 100.00000
v -1.45435 0.00000 0.00000
v 98.54565 0.00000 100.00000
v 98.54565 0.00000 0.00000
v 98.54565 100.00000 0.00000
v 98.54565 100.00000 100.00000
v -1.45435 100.00000 0.00000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
usemtl material_1
f 1//9 2//9 3//9
f 4//8 5//8 6//8
f 7//7 4//7 6//7
f 1//12 3//12 5//12
f 4//11 1//11 5//11
f 2//10 8//10 3//10
f 4//3 7//3 1//3
f 5//2 3//2 8//2
f 6//1 5//1 8//1
f 7//6 6//6 8//6
f 2//5 7//5 8//5
f 7//4 2//4 1//4
####################################
`
export const MODEL_CUBE_TEN_CM_GREEN = `####################################
# netfabb Wavefront export         #
####################################
mtllib 10cm_test_cube_green.mtl
v 0.00000 0.00000 100.00000
v 0.00000 100.00000 100.00000
v 0.00000 0.00000 0.00000
v 100.00000 0.00000 100.00000
v 100.00000 0.00000 0.00000
v 100.00000 100.00000 0.00000
v 100.00000 100.00000 100.00000
v 0.00000 100.00000 0.00000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
usemtl material_1
f 1//9 2//9 3//9
f 4//8 5//8 6//8
f 7//7 4//7 6//7
f 1//12 3//12 5//12
f 4//11 1//11 5//11
f 2//10 8//10 3//10
f 4//3 7//3 1//3
f 5//2 3//2 8//2
f 6//1 5//1 8//1
f 7//6 6//6 8//6
f 2//5 7//5 8//5
f 7//4 2//4 1//4
####################################
`
export const MODEL_CUBE_TEN_CM_RED = `####################################
# netfabb Wavefront export         #
####################################
mtllib 10cm_test_cube_red.mtl
v 0.00000 0.00000 100.00000
v 0.00000 100.00000 100.00000
v 0.00000 0.00000 0.00000
v 100.00000 0.00000 100.00000
v 100.00000 0.00000 0.00000
v 100.00000 100.00000 0.00000
v 100.00000 100.00000 100.00000
v 0.00000 100.00000 0.00000
vn 0.000 0.000 -1.000
vn 0.000 0.000 -1.000
vn 0.000 0.000 1.000
vn 0.000 0.000 1.000
vn 0.000 1.000 0.000
vn 0.000 1.000 0.000
vn 1.000 0.000 0.000
vn 1.000 0.000 0.000
vn -1.000 0.000 0.000
vn -1.000 0.000 0.000
vn 0.000 -1.000 0.000
vn 0.000 -1.000 0.000
usemtl material_1
f 1//9 2//9 3//9
f 4//8 5//8 6//8
f 7//7 4//7 6//7
f 1//12 3//12 5//12
f 4//11 1//11 5//11
f 2//10 8//10 3//10
f 4//3 7//3 1//3
f 5//2 3//2 8//2
f 6//1 5//1 8//1
f 7//6 6//6 8//6
f 2//5 7//5 8//5
f 7//4 2//4 1//4
####################################
`
