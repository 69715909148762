import config from '../config'
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function getPart(partNumber) {
  let response
  try {
    response = await getPartRequest(partNumber)
  } catch (err) {
    // log & rethrow
    logUnexpected(err, { partNumber }, 'getPartRequest')
    throw err
  }

  let result
  try {
    result = parseGetPartResponse(response)
  } catch (err) {
    // log & rethrow
    logUnexpected(err, { response }, 'parseGetPartResponse', { partNumber })
    throw err
  }

  try{
    if(typeof result.mfgIssues === "string"){
      result.mfgIssues = JSON.parse(result.mfgIssues)
    }
  }
  catch(e){}
  try{
    if(typeof result.mfgAnalysisIssues === "string"){
      result.mfgAnalysisIssues = JSON.parse(result.mfgAnalysisIssues)
    }
  }
  catch(e){}
  return result
}

async function getPartRequest(partNumber) {
  const response = await axios({
      method: 'POST',
      validateStatus: () => true,
      url: config.GET_PART_ENDPOINT,
      data: {
        id: partNumber,
        token: config.MICROSERVICE_TOKEN,
      },
  })
  return {status: response.status, data: response.data}
}

function parseGetPartResponse(response) {
  if (response.status === 200) {
    if (response.data.statusCode === '200') {
      if (response.data.body) {
        return response.data.body
      }
    } else {
      throw Error(`Unexpected DataButler response statusCode: ${response.data.statusCode}`)
    }

    throw new Error(`Unexpected data shape: ${JSON.stringify(response.data)}`)
  } else {
    throw new Error(`Unexpected HTTP status code: ${response.status}`)
  }
}
