import PART_GROUP_MODEL from './PartGroupModel.json' // @data-rectification
import PART_MODEL from './PartModel.json' // @data-rectification
import replaceNonBreakingSpaces from './replaceNonBreakingSpaces.js'
import * as s3 from '../../Components/QuoteTool/cloudObjectStorage'
import {
  applyDefaultValuesToNewPart,
  applyDefaultValuesToNewPartGroup,
  checkFileTypeIncludeUnits,
  createPart,
  createPartGroup,
  updateWithQuoteTypeDefaults,
  validateFile,
} from '../../utils'

export async function createNewPartGroupFromFile(file, project, applyValuesToAll, MATERIAL_TRANSLATIONS){
  let newPartGroup = JSON.parse(JSON.stringify(PART_GROUP_MODEL))
  newPartGroup.project = project.projectNumber
  newPartGroup = updateWithQuoteTypeDefaults(newPartGroup,project.customerAppQuoteType)
  newPartGroup = applyDefaultValuesToNewPartGroup(newPartGroup, applyValuesToAll, MATERIAL_TRANSLATIONS) //If any 'apply value to all' fields are selected use those values for default
  newPartGroup.productionToolPartConfigurations = []

  const newPart = await createNewPartFromFile(file, applyValuesToAll)
  newPartGroup.part = newPart

  try {
    // create the part in the database
    let partGroupNumber = await createPartGroup(newPartGroup.part.partNumber)
    newPartGroup.partGroupNumber = partGroupNumber
  } catch(error) {
    throw new Error(`${file.name} : Failed to create part group in database`)
  }

  return newPartGroup
}

async function createNewPartFromFile(file, applyValuesToAll){
  const newPart = await setNewPartDefaultValues(file, applyValuesToAll)
  let partFromDatabase
  try {
    partFromDatabase = await createPart(newPart)
  } catch(error) {
    throw new Error(`${file.name} : Failed to create part in database`)
  }

  return partFromDatabase
}

export async function setNewPartDefaultValues(file, applyValuesToAll){
  // validate the file before uploading. Only allow file extensions that we
  // approve and block mime types that we have blacklisted
  try {
    await validateFile(file)
  } catch(err) {
    throw new Error(`${file.name} : ${err.message}`)
  }

  let newPart = JSON.parse(JSON.stringify(PART_MODEL))
  newPart = applyDefaultValuesToNewPart(newPart, applyValuesToAll) //If any 'apply value to all' fields are selected use those values for default

  newPart.fileName = replaceNonBreakingSpaces(file.name)
  newPart.fileSize = file.size

  return newPart
}
