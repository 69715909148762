const axios = require('axios')
const config = require('../../config.js')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function updateCustomerAddress(customer){
  let response
  try{
    response = await updateCustomerAddressRequest(customer)
  } catch (err){
    logUnexpected(err, { customer }, 'updateCustomerAddressRequest')
    throw err
  }

  let result
  try{
    result = parseUpdateCustomerAddressResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseUpdateCustomerAddressResponse', { customer })
    throw err
  }

  return result
}

function updateCustomerAddressRequest(customer){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.UPDATE_CUSTOMER_ADDRESS_ENDPOINT,
    data: {
      token: config.MICROSERVICE_TOKEN,
      customer,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseUpdateCustomerAddressResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.statusCode === '200' && response.data.body){
      return response.data.body
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
