import React from 'react'
import {Typography} from '@material-ui/core'
import {CloudUpload} from '@material-ui/icons'
import Dropzone from 'react-dropzone'
const BYTES_IN_A_MB = 1024*1024

const FileDragAndDrop = ({currentlyLoading,dragAndDropType,maxFileSizeAllowedInBytes,uploadParts}) => {
  return (
    <Dropzone maxSize={maxFileSizeAllowedInBytes} onDrop={uploadParts}>
      {({getRootProps,getInputProps,isDragActive}) => (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography color="primary" gutterBottom={true} variant="h5">
            {currentlyLoading ? "Loading..." :
             isDragActive ? "Drop the files here..." :
             "Drag and drop files here, or click to select files to upload"
            }
          </Typography>
          {dragAndDropType === 'parts' ? `STEP | STP | SLDPRT | STL | OBJ | IPT | 3DXML | PRT | IGS | X_T | 3MF | PDF | DXF` : null}
          {dragAndDropType === 'attachments' ? `PDF | DXF | DWG | PNG` : null}
          <CloudUpload
            color="primary"
            fontSize="large"
          />
          {maxFileSizeAllowedInBytes/BYTES_IN_A_MB} MB Maximum File Size
        </div>
      )}
    </Dropzone>
  )
}

export default FileDragAndDrop

