var moment = require('moment-business-days')

export const formatDateForDatePicker = (date) => {
  const parsedDate = moment(date, "MM/DD/YYYY")
  return parsedDate.format("YYYY-MM-DD")
}

export const parseDateFromDatePicker = (date) => {
  if(!date){
    return ""
  }
  const parsedDate = moment(date, "YYYY-MM-DD")
  return parsedDate.format("MM/DD/YYYY")
}

