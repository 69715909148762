import { TranslateProcessId, TranslateFinishId } from '../utils'
const {TranslateMaterialId} = require('../Components/Common/TranslationTableMaterial.js')

export function addPartDisplayValues(partCollection, MATERIAL_TRANSLATIONS) {
  for (var row of partCollection) {
    if (row.processId) {
      row.process = TranslateProcessId(row.processId)
      if (row.materialId) {
        row.material = TranslateMaterialId(row.processId, row.materialId, MATERIAL_TRANSLATIONS)
      }
      if (row.finishId) {
        row.finishing = TranslateFinishId(row.processId, row.finishId)
      }
    }
  }
  return partCollection
}
