import React from 'react'
import {Snackbar} from '@material-ui/core'

const AppWideSnackbar = props =>{
    return(
      <Snackbar
        autoHideDuration={30000}
        message={props.message}
        onClose={props.close}
        open={props.open}
        style={{marginBottom:"24px"}}
      />
    )
}

export default AppWideSnackbar
