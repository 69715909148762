const axios = require('axios')
const config = require('../../../config.js')

export default async function downloadFilesAsZip(files){
  const response = await downloadFilesAsZipRequest(files)
  const result = parseDownloadFilesAsZipResponse(response)

  return result
}

function downloadFilesAsZipRequest(files){
  return axios({
    method: 'POST',
    responseType: 'blob',
    url: config.DOWNLOAD_FILES_ENDPOINT,
    validateStatus: () => true,
    data: {
      files,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

async function parseDownloadFilesAsZipResponse(response) {
  if(response.status === 400){
    try{
      const errorMessage = await response.data.text()
      throw new Error(`Error parsing project zip response: ${errorMessage}`)
    } catch(e) {
      throw new Error(`Error parsing project zip response`)
    }
  }

  return response.data
}
