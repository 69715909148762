const { logUnexpected } = require('./logUnexpected.js')
const axios = require('axios')
const HTTP_ERROR_CODES = require('./HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('./throwErrorParsedFromKnownStatusCode.js')

export async function calculateInjectionMoldingData(s3Id) {
  let response
  try {
    response = await calculateInjectionMoldingDataRequest(s3Id)
  } catch (err) {
    logUnexpected(err, { s3Id }, 'calculateInjectionMoldingDataRequest')
    throw err
  }

  let result
  try {
    result = parseCalculateInjectionMoldingData(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseCalculateInjectionMoldingData', { s3Id })
    throw err
  }

  return result
}

async function calculateInjectionMoldingDataRequest(s3Id) {
  const response = await axios({
    method: 'post',
    validateStatus: () => true,
    url: '/im/calculateData',
    data: { s3Id: s3Id, },
  })

  return { data: response.data, status: response.status }
}

function parseCalculateInjectionMoldingData(response) {
  if (response.status === 200) {
    if (response.data.success) {
      if (response.data.success.injectionMoldingData) {
        return { injectionMoldingData: response.data.success.injectionMoldingData }
      }
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
