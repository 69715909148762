import React from "react"
import {Button,TextField} from "@material-ui/core"
import {
  FormattedFileNameAtom,
  PartDimensionsAtom,
  NotesAtom,
  TextButtonWithIcon,
  ThumbnailOrPlaceHolderAtom,
} from "./partCardAtoms"
import {appendInfillIfNeeded} from '../../shared/appendInfillIfNeeded.js'
import CustomerPartConfiguration from './CustomerPartConfiguration'
import {formatAttachmentsList} from '../../utils/formatAttachmentsList'
import {formatFileDimensions,formatFileDimensionsForToolTip} from '../../utils/formatFileDimensions'
import {TranslateProcessId} from '../../utils'
const {TranslateMaterialId} = require('../Common/TranslationTableMaterial.js')

export default function ToolingReadOnly({ MATERIAL_TRANSLATIONS, purchasedTool }){
  const { partGroup, projects, remainingLife } = purchasedTool
  let material = TranslateMaterialId(partGroup.processId, partGroup.materialId, MATERIAL_TRANSLATIONS)
  material = appendInfillIfNeeded(material, partGroup.processId, partGroup.infillPercentage)

  return(
    <div className="tooling-read-only">
      <div className="thumbnail-atom" style={{ gridArea: "thumb" }}>
        <ThumbnailOrPlaceHolderAtom
          hasModel={partGroup.part.s3ObjFileId ? true : false}
          onClick={()=>{}}
          thumbnailS3id={partGroup.part.thumbnailS3id}
        />
      </div>
      <div className="file-name-atom" style={{ gridArea: "fname" }}>
        <FormattedFileNameAtom
          fileName={partGroup.part.fileName}
        />
      </div>
      <div style={{ gridArea: "pdims" }}>
        <PartDimensionsAtom
          formattedDimensionsLabel={formatFileDimensions(partGroup.part)}
          formattedDimensionsToolTipLabel={formatFileDimensionsForToolTip(partGroup.part)}
        />
      </div>
      <div className="unit-selection-atom" style={{ gridArea: "units" }}>
        <ReadOnlyField label="Units" value={partGroup.part.units}/>
      </div>
      <div className="process-select-atom" style={{ gridArea: "prces" }}>
        <ReadOnlyField label="Process" value={TranslateProcessId(partGroup.processId)}/>
      </div>
      <div style={{ justifyContent: "normal", gridArea: "mater" }}>
        <ReadOnlyField label="Material" value={material}/>
      </div>
      <div className="quantity-input-atom" style={{gridArea: "gtdlf" }}>
        <ReadOnlyField label="Guaranteed Life" value={partGroup.actualGuaranteedLife}/>
      </div>
      <div style={{gridArea: "lifer" }}>
        <ReadOnlyField label="Life Remaining" value={remainingLife} style={{minWidth:"4em"}}/>
      </div>
      <div style={{ gridArea: "postp" }} className="truncated-list-container-parent">
        Post Processes: {partGroup.finishing}
      </div>
      <div style={{ gridArea: "atchs" }} className="truncated-list-container-parent" >
        {formatAttachmentsList(partGroup.attachments)}
      </div>
      <div style={{ gridArea: "projs", display: 'flex', alignItems: 'center', gap: '0.5em' }}>
        Projects: {projects.map(project => `${project.projectNumber} (${project.projectTotal} parts)`).join(', ')}
      </div>
      <div style={{ gridArea: "notes" }}>
        <NotesAtom
          disabled={true}
          partNotes={partGroup.part.partNotes}
        />
      </div>
    </div>
  )
}

const ReadOnlyField = ({ label, value, style }) => {
  return(
    <TextField label={label} value={value} disabled style={style} />
  )
}

// SHOWCASE STUFF
const _ = require('lodash')
ToolingReadOnly.exampleProps = _.cloneDeep(CustomerPartConfiguration.exampleProps)
ToolingReadOnly.exampleProps.partGroup.guaranteedLife = 10000
ToolingReadOnly.exampleProps.partGroup.toolPrice = 5025.56
ToolingReadOnly.Showcase = Showcase
function Showcase() {
  return(
    <>
      <ToolingReadOnly { ...ToolingReadOnly.exampleProps } devBackdoors={{}}/>
    </>
  )
}

ToolingReadOnly.defaultProps = {
  devBackdoors: {} /* prevent misindices */
}
