export const TO_FROM_TYPES = {
  autotiv: 'Autotiv',
  customer: 'Customer',
  heldAtSupplier: 'Held at Supplier',
  intermediate: 'Intermediate',
  supplier: 'Supplier',
}

export const SUPPLIER_FROM_TYPE_ARRAY = [
  TO_FROM_TYPES.supplier,
  TO_FROM_TYPES.autotiv,
  TO_FROM_TYPES.heldAtSupplier,
  TO_FROM_TYPES.intermediate,
]

export const SUPPLIER_FROM_TYPE_ARRAY_SHIPMENT = [
  TO_FROM_TYPES.autotiv,
  TO_FROM_TYPES.heldAtSupplier,
  TO_FROM_TYPES.intermediate,
]

export const SUPPLIER_TO_TYPE_ARRAY = [
  TO_FROM_TYPES.autotiv,
  TO_FROM_TYPES.heldAtSupplier,
  TO_FROM_TYPES.intermediate,
  TO_FROM_TYPES.customer,
]

export const SUPPLIER_TO_TYPE_ARRAY_MAKEORDER = [
  TO_FROM_TYPES.heldAtSupplier,
]

