import React,{Fragment,useState} from 'react'
import ActionLink from '../Common/ActionLink.js'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import {Info} from '@material-ui/icons'

export default function CheckoutButtonDiv({
  checkoutButtonDisabled,
  checkoutHandler,
  downloadQuote,
  isCalculatingPrices,
  manualReview,
  pauseCalculations,
  project,
  reviewReasons,
  submittingForRfq
}){
  return(
    <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
      <Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ManualRfqReasonsIcon
            manualReview={manualReview}
            project={project}
            reviewReasons={reviewReasons}
          />
          <CheckoutButtonWithOptionalTooltip
            checkoutButtonDisabled={checkoutButtonDisabled || pauseCalculations}
            checkoutHandler={checkoutHandler}
            manualReview={manualReview}
            submittingForRfq={submittingForRfq}
          />
        </div>
        {checkoutButtonDisabled ?
         <div>
           <Typography variant="caption">
             Please fill out Lead Time and all Part Data
           </Typography>
         </div>
         : null}
        {manualReview || isCalculatingPrices ? null :
         <DownloadQuoteLink
           downloadQuote={downloadQuote}
           isCalculatingPrices={isCalculatingPrices}
         />
        }
      </Fragment>
    </div>
  )
}

function DownloadQuoteLink({downloadQuote,isCalculatingPrices}){
  const [isDownloading, setIsDownloading] = useState(false)
  return (
    <div style = {{paddingTop: '0.5em'}}>
      {isDownloading || isCalculatingPrices ? <CircularProgress size="0.9em"/> :
       <ActionLink
         action={() => {
           setIsDownloading(true)
           downloadQuote()
             .finally(() => setIsDownloading(false))
         }}
         label="Download Quote"
         theme="secondary"
       />}
    </div>
  )
}

function CheckoutButtonWithOptionalTooltip({
  checkoutButtonDisabled,
  checkoutHandler,
  manualReview,
  submittingForRfq,
}){
  return checkoutButtonDisabled ?
    <IncompleteProjectTooltip>
      {CheckoutButton()}
    </IncompleteProjectTooltip>
    :
    CheckoutButton()

  /* HELPER THUNKS */
  function CheckoutButton(){
    return(
      <Button
        color="secondary"
        disabled={checkoutButtonDisabled}
        onClick={checkoutHandler}
        variant="contained"
      >
        { manualReview ?
          (submittingForRfq ? 'Submitting Manual RFQ...' : 'Submit for Manual RFQ') :
          'Proceed to Checkout'
        }
      </Button>
    )
  }
}

function IncompleteProjectTooltip(props){
  return(
      <HtmlTooltip enterDelay={400} placement="bottom" title={
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project configuration is incomplete. To proceed please review the top left corner of each part row.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ color: 'red' }}>For red statuses user action is required.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ color: 'blue' }}>For blue statuses please wait, system is still processing.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>
      }>
        <div> { /* NEEDS TO BE IN A DIV; wont show-up otherwise */ }
          {props.children}
        </div>
      </HtmlTooltip>
  )
}

function ManualRfqReasonsIcon({manualReview,reviewReasons,}){
  if(manualReview){
    let manualRfqTitle = null
    if(reviewReasons.projectReasons){
      manualRfqTitle = (
        <Typography>
          {reviewReasons.projectReasons.join()} <br/><br/> Please submit for Manual RFQ to receive pricing.
        </Typography>
      )
    }

    return(
      <HtmlTooltip
        enterDelay={400}
        placement="bottom"
        title={manualRfqTitle}
      >
        <Info className="informative" />
      </HtmlTooltip>
    )
  }
  else{
    return("")
  }
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  }
}))(Tooltip)

