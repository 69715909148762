import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function createOvermoldedInsertConfig(overmoldedInsertConfig){
  let response
  try{
    response = await createOvermoldedInsertConfigRequest(overmoldedInsertConfig)
  } catch (err){
    logUnexpected(err, { overmoldedInsertConfig }, 'createOvermoldedInsertConfigRequest')
    throw err
  }

  let result
  try{
    result = parseCreateOvermoldedInsertConfigResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseCreateOvermoldedInsertConfigResponse', { overmoldedInsertConfig })
    throw err
  }

  return result
}

function createOvermoldedInsertConfigRequest(overmoldedInsertConfig){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.CREATE_OVERMOLDEDINSERT_ENDPOINT,
    data: {
      overmoldedInsertConfig,
      token: config.MICROSERVICE_TOKEN,
    }
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseCreateOvermoldedInsertConfigResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.overmoldedInsertConfig){
      return response.data.overmoldedInsertConfig
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
