const COLORS = [
  {label: 'Black', value: 'black', colorSets: ['opaque','transparent','highTemp','black']},
  {label: 'Natural', value: 'natural', colorSets: ['opaque','highTemp']},
  {label: 'Transparent', value: 'transparent', colorSets: ['transparent']},
  {label: 'Pantone 11-0601 TCX Bright White', value: 'pantone110601tcx', colorSets: ['opaque','transparent']},
  {label: 'Pantone 11-4001 TCX Brilliant White', value: 'pantone114001tcx', colorSets: ['opaque','transparent']},
  {label: 'Pantone 11-4302 TCX Cannoli Cream', value: 'pantone114302tcx', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 11-0608 TCX Coconut Milk', value: 'pantone110608tcx', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 11-0607 TCX Sugar Swizzle', value: 'pantone110607tcx', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 11-4801 TCX Tofu', value: 'pantone114801tcx', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 100 C', value: 'pantone100c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 101 C', value: 'pantone101c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 102 C', value: 'pantone102c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 103 C', value: 'pantone103c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 104 C', value: 'pantone104c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 105 C', value: 'pantone105c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 106 C', value: 'pantone106c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 107 C', value: 'pantone107c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 108 C', value: 'pantone108c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 109 C', value: 'pantone109c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 110 C', value: 'pantone110c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 111 C', value: 'pantone111c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 112 C', value: 'pantone112c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 113 C', value: 'pantone113c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 114 C', value: 'pantone114c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 115 C', value: 'pantone115c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 116 C', value: 'pantone116c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 117 C', value: 'pantone117c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 118 C', value: 'pantone118c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 119 C', value: 'pantone119c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 120 C', value: 'pantone120c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 121 C', value: 'pantone121c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 122 C', value: 'pantone122c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 123 C', value: 'pantone123c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 124 C', value: 'pantone124c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 125 C', value: 'pantone125c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 126 C', value: 'pantone126c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 127 C', value: 'pantone127c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 128 C', value: 'pantone128c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 129 C', value: 'pantone129c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 130 C', value: 'pantone130c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 131 C', value: 'pantone131c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 132 C', value: 'pantone132c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 133 C', value: 'pantone133c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 134 C', value: 'pantone134c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 135 C', value: 'pantone135c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 136 C', value: 'pantone136c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 137 C', value: 'pantone137c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 138 C', value: 'pantone138c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 139 C', value: 'pantone139c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 140 C', value: 'pantone140c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 141 C', value: 'pantone141c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 142 C', value: 'pantone142c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 143 C', value: 'pantone143c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 144 C', value: 'pantone144c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 145 C', value: 'pantone145c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 146 C', value: 'pantone146c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 147 C', value: 'pantone147c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 148 C', value: 'pantone148c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 149 C', value: 'pantone149c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 150 C', value: 'pantone150c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 151 C', value: 'pantone151c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 152 C', value: 'pantone152c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 153 C', value: 'pantone153c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 154 C', value: 'pantone154c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 155 C', value: 'pantone155c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 156 C', value: 'pantone156c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 157 C', value: 'pantone157c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 158 C', value: 'pantone158c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 159 C', value: 'pantone159c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 160 C', value: 'pantone160c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 162 C', value: 'pantone162c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 163 C', value: 'pantone163c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 164 C', value: 'pantone164c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 165 C', value: 'pantone165c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 166 C', value: 'pantone166c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 167 C', value: 'pantone167c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 168 C', value: 'pantone168c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 169 C', value: 'pantone169c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 170 C', value: 'pantone170c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 171 C', value: 'pantone171c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 172 C', value: 'pantone172c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 173 C', value: 'pantone173c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 174 C', value: 'pantone174c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 175 C', value: 'pantone175c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 176 C', value: 'pantone176c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 177 C', value: 'pantone177c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 178 C', value: 'pantone178c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 179 C', value: 'pantone179c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 180 C', value: 'pantone180c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 181 C', value: 'pantone181c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 182 C', value: 'pantone182c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 183 C', value: 'pantone183c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 184 C', value: 'pantone184c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 185 C', value: 'pantone185c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 186 C', value: 'pantone186c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 187 C', value: 'pantone187c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 188 C', value: 'pantone188c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 189 C', value: 'pantone189c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 190 C', value: 'pantone190c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 191 C', value: 'pantone191c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 192 C', value: 'pantone192c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 193 C', value: 'pantone193c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 194 C', value: 'pantone194c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 195 C', value: 'pantone195c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 196 C', value: 'pantone196c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 197 C', value: 'pantone197c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 198 C', value: 'pantone198c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 199 C', value: 'pantone199c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 200 C', value: 'pantone200c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 201 C', value: 'pantone201c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 202 C', value: 'pantone202c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 203 C', value: 'pantone203c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 204 C', value: 'pantone204c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 205 C', value: 'pantone205c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 206 C', value: 'pantone206c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 207 C', value: 'pantone207c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 208 C', value: 'pantone208c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 209 C', value: 'pantone209c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 210 C', value: 'pantone210c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 211 C', value: 'pantone211c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 212 C', value: 'pantone212c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 213 C', value: 'pantone213c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 214 C', value: 'pantone214c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 215 C', value: 'pantone215c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 216 C', value: 'pantone216c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 217 C', value: 'pantone217c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 218 C', value: 'pantone218c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 219 C', value: 'pantone219c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 220 C', value: 'pantone220c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 221 C', value: 'pantone221c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 222 C', value: 'pantone222c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 223 C', value: 'pantone223c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 224 C', value: 'pantone224c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 225 C', value: 'pantone225c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 226 C', value: 'pantone226c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 227 C', value: 'pantone227c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 228 C', value: 'pantone228c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 229 C', value: 'pantone229c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 230 C', value: 'pantone230c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 231 C', value: 'pantone231c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 232 C', value: 'pantone232c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 233 C', value: 'pantone233c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 234 C', value: 'pantone234c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 235 C', value: 'pantone235c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 236 C', value: 'pantone236c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 237 C', value: 'pantone237c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 238 C', value: 'pantone238c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 239 C', value: 'pantone239c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 240 C', value: 'pantone240c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 241 C', value: 'pantone241c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 242 C', value: 'pantone242c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 243 C', value: 'pantone243c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 244 C', value: 'pantone244c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 245 C', value: 'pantone245c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 246 C', value: 'pantone246c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 247 C', value: 'pantone247c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 248 C', value: 'pantone248c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 249 C', value: 'pantone249c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 250 C', value: 'pantone250c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 251 C', value: 'pantone251c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 252 C', value: 'pantone252c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 253 C', value: 'pantone253c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 254 C', value: 'pantone254c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 255 C', value: 'pantone255c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 256 C', value: 'pantone256c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 257 C', value: 'pantone257c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 258 C', value: 'pantone258c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 259 C', value: 'pantone259c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 260 C', value: 'pantone260c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 261 C', value: 'pantone261c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 262 C', value: 'pantone262c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 263 C', value: 'pantone263c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 264 C', value: 'pantone264c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 265 C', value: 'pantone265c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 266 C', value: 'pantone266c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 267 C', value: 'pantone267c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 268 C', value: 'pantone268c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 269 C', value: 'pantone269c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 270 C', value: 'pantone270c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 271 C', value: 'pantone271c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 272 C', value: 'pantone272c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 273 C', value: 'pantone273c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 274 C', value: 'pantone274c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 275 C', value: 'pantone275c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 276 C', value: 'pantone276c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 277 C', value: 'pantone277c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 278 C', value: 'pantone278c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 279 C', value: 'pantone279c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 280 C', value: 'pantone280c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 281 C', value: 'pantone281c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 282 C', value: 'pantone282c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 283 C', value: 'pantone283c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 284 C', value: 'pantone284c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 285 C', value: 'pantone285c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 286 C', value: 'pantone286c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 287 C', value: 'pantone287c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 288 C', value: 'pantone288c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 289 C', value: 'pantone289c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 290 C', value: 'pantone290c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 291 C', value: 'pantone291c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 292 C', value: 'pantone292c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 293 C', value: 'pantone293c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 294 C', value: 'pantone294c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 295 C', value: 'pantone295c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 296 C', value: 'pantone296c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 297 C', value: 'pantone297c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 298 C', value: 'pantone298c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 299 C', value: 'pantone299c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 300 C', value: 'pantone300c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 301 C', value: 'pantone301c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 302 C', value: 'pantone302c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 303 C', value: 'pantone303c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 304 C', value: 'pantone304c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 305 C', value: 'pantone305c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 306 C', value: 'pantone306c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 307 C', value: 'pantone307c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 308 C', value: 'pantone308c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 309 C', value: 'pantone309c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 310 C', value: 'pantone310c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 311 C', value: 'pantone311c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 312 C', value: 'pantone312c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 313 C', value: 'pantone313c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 314 C', value: 'pantone314c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 315 C', value: 'pantone315c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 316 C', value: 'pantone316c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 317 C', value: 'pantone317c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 318 C', value: 'pantone318c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 319 C', value: 'pantone319c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 320 C', value: 'pantone320c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 321 C', value: 'pantone321c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 322 C', value: 'pantone322c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 323 C', value: 'pantone323c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 324 C', value: 'pantone324c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 325 C', value: 'pantone325c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 326 C', value: 'pantone326c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 327 C', value: 'pantone327c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 328 C', value: 'pantone328c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 329 C', value: 'pantone329c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 330 C', value: 'pantone330c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 331 C', value: 'pantone331c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 332 C', value: 'pantone332c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 333 C', value: 'pantone333c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 334 C', value: 'pantone334c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 335 C', value: 'pantone335c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 336 C', value: 'pantone336c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 337 C', value: 'pantone337c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 338 C', value: 'pantone338c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 339 C', value: 'pantone339c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 340 C', value: 'pantone340c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 341 C', value: 'pantone341c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 342 C', value: 'pantone342c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 343 C', value: 'pantone343c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 344 C', value: 'pantone344c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 345 C', value: 'pantone345c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 346 C', value: 'pantone346c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 347 C', value: 'pantone347c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 348 C', value: 'pantone348c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 349 C', value: 'pantone349c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 350 C', value: 'pantone350c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 351 C', value: 'pantone351c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 352 C', value: 'pantone352c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 353 C', value: 'pantone353c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 354 C', value: 'pantone354c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 355 C', value: 'pantone355c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 356 C', value: 'pantone356c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 357 C', value: 'pantone357c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 358 C', value: 'pantone358c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 359 C', value: 'pantone359c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 360 C', value: 'pantone360c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 361 C', value: 'pantone361c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 362 C', value: 'pantone362c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 363 C', value: 'pantone363c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 364 C', value: 'pantone364c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 365 C', value: 'pantone365c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 366 C', value: 'pantone366c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 367 C', value: 'pantone367c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 368 C', value: 'pantone368c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 369 C', value: 'pantone369c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 370 C', value: 'pantone370c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 371 C', value: 'pantone371c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 372 C', value: 'pantone372c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 373 C', value: 'pantone373c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 374 C', value: 'pantone374c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 375 C', value: 'pantone375c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 376 C', value: 'pantone376c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 377 C', value: 'pantone377c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 378 C', value: 'pantone378c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 379 C', value: 'pantone379c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 380 C', value: 'pantone380c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 381 C', value: 'pantone381c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 382 C', value: 'pantone382c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 383 C', value: 'pantone383c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 384 C', value: 'pantone384c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 385 C', value: 'pantone385c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 386 C', value: 'pantone386c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 387 C', value: 'pantone387c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 388 C', value: 'pantone388c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 389 C', value: 'pantone389c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 390 C', value: 'pantone390c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 391 C', value: 'pantone391c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 392 C', value: 'pantone392c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 393 C', value: 'pantone393c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 394 C', value: 'pantone394c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 395 C', value: 'pantone395c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 396 C', value: 'pantone396c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 397 C', value: 'pantone397c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 398 C', value: 'pantone398c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 399 C', value: 'pantone399c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 400 C', value: 'pantone400c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 401 C', value: 'pantone401c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 402 C', value: 'pantone402c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 403 C', value: 'pantone403c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 404 C', value: 'pantone404c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 405 C', value: 'pantone405c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 406 C', value: 'pantone406c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 407 C', value: 'pantone407c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 408 C', value: 'pantone408c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 409 C', value: 'pantone409c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 410 C', value: 'pantone410c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 411 C', value: 'pantone411c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 412 C', value: 'pantone412c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 413 C', value: 'pantone413c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 414 C', value: 'pantone414c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 415 C', value: 'pantone415c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 416 C', value: 'pantone416c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 417 C', value: 'pantone417c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 418 C', value: 'pantone418c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 419 C', value: 'pantone419c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 420 C', value: 'pantone420c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 421 C', value: 'pantone421c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 422 C', value: 'pantone422c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 423 C', value: 'pantone423c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 424 C', value: 'pantone424c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 425 C', value: 'pantone425c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 426 C', value: 'pantone426c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 427 C', value: 'pantone427c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 428 C', value: 'pantone428c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 429 C', value: 'pantone429c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 430 C', value: 'pantone430c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 431 C', value: 'pantone431c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 432 C', value: 'pantone432c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 433 C', value: 'pantone433c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 434 C', value: 'pantone434c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 435 C', value: 'pantone435c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 436 C', value: 'pantone436c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 437 C', value: 'pantone437c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 438 C', value: 'pantone438c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 439 C', value: 'pantone439c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 440 C', value: 'pantone440c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 441 C', value: 'pantone441c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 442 C', value: 'pantone442c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 443 C', value: 'pantone443c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 444 C', value: 'pantone444c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 445 C', value: 'pantone445c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 446 C', value: 'pantone446c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 447 C', value: 'pantone447c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 448 C', value: 'pantone448c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 449 C', value: 'pantone449c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 450 C', value: 'pantone450c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 451 C', value: 'pantone451c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 452 C', value: 'pantone452c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 453 C', value: 'pantone453c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 454 C', value: 'pantone454c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 455 C', value: 'pantone455c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 456 C', value: 'pantone456c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 457 C', value: 'pantone457c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 458 C', value: 'pantone458c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 459 C', value: 'pantone459c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 460 C', value: 'pantone460c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 461 C', value: 'pantone461c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 462 C', value: 'pantone462c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 463 C', value: 'pantone463c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 464 C', value: 'pantone464c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 465 C', value: 'pantone465c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 466 C', value: 'pantone466c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 467 C', value: 'pantone467c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 468 C', value: 'pantone468c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 469 C', value: 'pantone469c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 470 C', value: 'pantone470c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 471 C', value: 'pantone471c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 472 C', value: 'pantone472c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 473 C', value: 'pantone473c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 474 C', value: 'pantone474c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 475 C', value: 'pantone475c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 476 C', value: 'pantone476c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 477 C', value: 'pantone477c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 478 C', value: 'pantone478c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 479 C', value: 'pantone479c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 480 C', value: 'pantone480c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 481 C', value: 'pantone481c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 482 C', value: 'pantone482c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 483 C', value: 'pantone483c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 484 C', value: 'pantone484c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 485 C', value: 'pantone485c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 486 C', value: 'pantone486c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 487 C', value: 'pantone487c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 488 C', value: 'pantone488c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 489 C', value: 'pantone489c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 490 C', value: 'pantone490c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 491 C', value: 'pantone491c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 492 C', value: 'pantone492c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 493 C', value: 'pantone493c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 494 C', value: 'pantone494c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 495 C', value: 'pantone495c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 496 C', value: 'pantone496c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 497 C', value: 'pantone497c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 498 C', value: 'pantone498c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 499 C', value: 'pantone499c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 500 C', value: 'pantone500c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 501 C', value: 'pantone501c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 502 C', value: 'pantone502c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 503 C', value: 'pantone503c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 504 C', value: 'pantone504c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 505 C', value: 'pantone505c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 506 C', value: 'pantone506c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 507 C', value: 'pantone507c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 508 C', value: 'pantone508c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 509 C', value: 'pantone509c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 510 C', value: 'pantone510c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 511 C', value: 'pantone511c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 512 C', value: 'pantone512c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 513 C', value: 'pantone513c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 514 C', value: 'pantone514c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 515 C', value: 'pantone515c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 516 C', value: 'pantone516c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 517 C', value: 'pantone517c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 518 C', value: 'pantone518c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 519 C', value: 'pantone519c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 520 C', value: 'pantone520c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 521 C', value: 'pantone521c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 522 C', value: 'pantone522c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 523 C', value: 'pantone523c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 524 C', value: 'pantone524c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 525 C', value: 'pantone525c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 526 C', value: 'pantone526c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 527 C', value: 'pantone527c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 528 C', value: 'pantone528c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 529 C', value: 'pantone529c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 530 C', value: 'pantone530c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 531 C', value: 'pantone531c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 532 C', value: 'pantone532c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 533 C', value: 'pantone533c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 534 C', value: 'pantone534c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 535 C', value: 'pantone535c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 536 C', value: 'pantone536c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 537 C', value: 'pantone537c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 538 C', value: 'pantone538c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 539 C', value: 'pantone539c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 540 C', value: 'pantone540c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 541 C', value: 'pantone541c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 542 C', value: 'pantone542c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 543 C', value: 'pantone543c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 544 C', value: 'pantone544c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 545 C', value: 'pantone545c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 546 C', value: 'pantone546c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 547 C', value: 'pantone547c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 548 C', value: 'pantone548c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 549 C', value: 'pantone549c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 550 C', value: 'pantone550c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 551 C', value: 'pantone551c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 552 C', value: 'pantone552c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 553 C', value: 'pantone553c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 554 C', value: 'pantone554c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 555 C', value: 'pantone555c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 556 C', value: 'pantone556c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 557 C', value: 'pantone557c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 558 C', value: 'pantone558c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 559 C', value: 'pantone559c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 560 C', value: 'pantone560c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 561 C', value: 'pantone561c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 562 C', value: 'pantone562c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 563 C', value: 'pantone563c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 564 C', value: 'pantone564c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 565 C', value: 'pantone565c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 566 C', value: 'pantone566c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 567 C', value: 'pantone567c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 568 C', value: 'pantone568c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 569 C', value: 'pantone569c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 570 C', value: 'pantone570c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 571 C', value: 'pantone571c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 572 C', value: 'pantone572c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 573 C', value: 'pantone573c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 574 C', value: 'pantone574c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 575 C', value: 'pantone575c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 576 C', value: 'pantone576c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 577 C', value: 'pantone577c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 578 C', value: 'pantone578c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 579 C', value: 'pantone579c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 580 C', value: 'pantone580c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 581 C', value: 'pantone581c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 582 C', value: 'pantone582c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 583 C', value: 'pantone583c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 584 C', value: 'pantone584c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 585 C', value: 'pantone585c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 586 C', value: 'pantone586c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 587 C', value: 'pantone587c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 588 C', value: 'pantone588c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 589 C', value: 'pantone589c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 590 C', value: 'pantone590c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 591 C', value: 'pantone591c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 592 C', value: 'pantone592c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 593 C', value: 'pantone593c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 594 C', value: 'pantone594c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 595 C', value: 'pantone595c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 596 C', value: 'pantone596c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 597 C', value: 'pantone597c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 598 C', value: 'pantone598c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 599 C', value: 'pantone599c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 600 C', value: 'pantone600c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 601 C', value: 'pantone601c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 602 C', value: 'pantone602c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 603 C', value: 'pantone603c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 604 C', value: 'pantone604c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 605 C', value: 'pantone605c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 606 C', value: 'pantone606c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 607 C', value: 'pantone607c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 608 C', value: 'pantone608c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 609 C', value: 'pantone609c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 610 C', value: 'pantone610c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 611 C', value: 'pantone611c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 612 C', value: 'pantone612c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 613 C', value: 'pantone613c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 614 C', value: 'pantone614c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 615 C', value: 'pantone615c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 616 C', value: 'pantone616c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 617 C', value: 'pantone617c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 618 C', value: 'pantone618c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 619 C', value: 'pantone619c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 620 C', value: 'pantone620c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 621 C', value: 'pantone621c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 622 C', value: 'pantone622c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 623 C', value: 'pantone623c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 624 C', value: 'pantone624c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 625 C', value: 'pantone625c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 626 C', value: 'pantone626c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 627 C', value: 'pantone627c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 628 C', value: 'pantone628c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 629 C', value: 'pantone629c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 630 C', value: 'pantone630c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 631 C', value: 'pantone631c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 632 C', value: 'pantone632c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 633 C', value: 'pantone633c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 634 C', value: 'pantone634c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 635 C', value: 'pantone635c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 636 C', value: 'pantone636c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 637 C', value: 'pantone637c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 638 C', value: 'pantone638c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 639 C', value: 'pantone639c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 640 C', value: 'pantone640c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 641 C', value: 'pantone641c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 642 C', value: 'pantone642c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 643 C', value: 'pantone643c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 644 C', value: 'pantone644c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 645 C', value: 'pantone645c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 646 C', value: 'pantone646c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 647 C', value: 'pantone647c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 648 C', value: 'pantone648c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 649 C', value: 'pantone649c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 650 C', value: 'pantone650c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 651 C', value: 'pantone651c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 652 C', value: 'pantone652c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 653 C', value: 'pantone653c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 654 C', value: 'pantone654c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 655 C', value: 'pantone655c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 656 C', value: 'pantone656c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 657 C', value: 'pantone657c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 658 C', value: 'pantone658c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 659 C', value: 'pantone659c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 660 C', value: 'pantone660c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 661 C', value: 'pantone661c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 662 C', value: 'pantone662c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 663 C', value: 'pantone663c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 664 C', value: 'pantone664c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 665 C', value: 'pantone665c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 666 C', value: 'pantone666c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 667 C', value: 'pantone667c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 668 C', value: 'pantone668c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 669 C', value: 'pantone669c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 670 C', value: 'pantone670c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 671 C', value: 'pantone671c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 672 C', value: 'pantone672c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 673 C', value: 'pantone673c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 674 C', value: 'pantone674c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 675 C', value: 'pantone675c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 676 C', value: 'pantone676c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 677 C', value: 'pantone677c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 678 C', value: 'pantone678c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 679 C', value: 'pantone679c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 680 C', value: 'pantone680c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 681 C', value: 'pantone681c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 682 C', value: 'pantone682c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 683 C', value: 'pantone683c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 684 C', value: 'pantone684c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 685 C', value: 'pantone685c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 686 C', value: 'pantone686c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 687 C', value: 'pantone687c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 688 C', value: 'pantone688c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 689 C', value: 'pantone689c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 690 C', value: 'pantone690c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 691 C', value: 'pantone691c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 692 C', value: 'pantone692c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 693 C', value: 'pantone693c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 694 C', value: 'pantone694c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 695 C', value: 'pantone695c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 696 C', value: 'pantone696c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 697 C', value: 'pantone697c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 698 C', value: 'pantone698c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 699 C', value: 'pantone699c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 700 C', value: 'pantone700c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 701 C', value: 'pantone701c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 702 C', value: 'pantone702c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 703 C', value: 'pantone703c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 704 C', value: 'pantone704c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 705 C', value: 'pantone705c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 706 C', value: 'pantone706c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 707 C', value: 'pantone707c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 708 C', value: 'pantone708c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 709 C', value: 'pantone709c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 710 C', value: 'pantone710c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 711 C', value: 'pantone711c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 712 C', value: 'pantone712c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 713 C', value: 'pantone713c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 714 C', value: 'pantone714c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 715 C', value: 'pantone715c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 716 C', value: 'pantone716c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 717 C', value: 'pantone717c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 718 C', value: 'pantone718c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 719 C', value: 'pantone719c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 720 C', value: 'pantone720c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 721 C', value: 'pantone721c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 722 C', value: 'pantone722c', colorSets: ['opaque','transparent']},
  {label: 'Pantone 723 C', value: 'pantone723c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 724 C', value: 'pantone724c', colorSets: ['opaque','transparent','highTemp']},
  {label: 'Pantone 725 C', value: 'pantone725c', colorSets: ['opaque','transparent','highTemp']},
]

module.exports = {COLORS}
