const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function getProjectQuotePdf(project, pricingData, selectedLeadTime){
  let response
  try{
    response = await getProjectQuotePdfRequest(project, pricingData, selectedLeadTime)
  } catch (err){
    logUnexpected(err, { project, pricingData, selectedLeadTime }, 'getProjectQuotePdfRequest')
    throw err
  }

  let result
  try{
    result = parseGetProjectQuotePdfResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseGetProjectQuotePdfResponse', { project, pricingData, selectedLeadTime })
    throw err
  }

  return result
}

function getProjectQuotePdfRequest(project, pricingData, selectedLeadTime){
  return axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/documents/create/quote',
    data: {
      pricingData,
      project,
      selectedLeadTime,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseGetProjectQuotePdfResponse(response) {
  if(response.status === 200){

    if(response.data && response.data.base64Pdf){
      return response.data.base64Pdf
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }

  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
