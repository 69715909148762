import React,{useEffect} from 'react'
import {CircularProgress,Fade} from '@material-ui/core'
import Logo from '../../Images/logoDark.png'

const LoadingPageNewProj = ({loadProjectPromise,pageType,projectNumber}) => {

  useEffect(() => {
    if(pageType === 'project'){
      loadProjectPromise(projectNumber)
    }
  }, ['ONMOUNT'])

  return(
    <div style={{ height: '100vh', margin: 0, textAlign: 'center' }}>
      <h1 style={{ marginTop: '25vh' }}>CREATING NEW PROJECT</h1>
      <CircularProgress />
      <br/>
      <Fade in={true} appear={true}>
        <img alt="logo" style={{ marginTop: '5vh', width: '40vw' }} src={Logo}/>
      </Fade>
    </div>
  )
}

export default LoadingPageNewProj
