import { addPartDisplayValues, checkFileTypeIncludeUnits, getDefaultFinish } from '../utils'
const {getDefaultMaterialId} = require('../Components/Common/TranslationTableMaterial.js')
/*
  Any this.state.applyValuesToAll fields are filled use those values for default field values
  Takes a single partRow object as a parameter
*/
export function applyDefaultValuesToNewPartGroup(partRow, applyValuesToAll, MATERIAL_TRANSLATIONS) {
  partRow.processId = applyValuesToAll.forAllProcess ? applyValuesToAll.forAllProcess : partRow.processId
  partRow.materialId = getDefaultMaterialId(partRow.processId, MATERIAL_TRANSLATIONS)
  partRow.finishId = getDefaultFinish(partRow.processId)

  partRow.materialId = applyValuesToAll.forAllMaterial ? applyValuesToAll.forAllMaterial : partRow.materialId
  if(['injMoldingPart', 'casting'].includes(partRow.processId)){
    partRow.materialIdProductionToolIsDesignedFor = partRow.materialId
    partRow.materialId = ''
  }
  partRow.quantity = applyValuesToAll.forAllQuantity ? applyValuesToAll.forAllQuantity : partRow.quantity
  partRow = addPartDisplayValues([partRow], MATERIAL_TRANSLATIONS)[0] // Update display values to match new values (ex: update part.process to match part.processId)

  return partRow
}

export function applyDefaultValuesToNewPart(part, applyValuesToAll){
  let initialUnits = applyValuesToAll.forAllUnits ? applyValuesToAll.forAllUnits : ""
  if(checkFileTypeIncludeUnits(part.fileName)){
    initialUnits = "mm"
  }
  part.units = initialUnits

  return part
}
