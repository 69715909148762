import React, { useContext, useEffect, useState, Fragment } from "react"
import {appendInfillIfNeeded} from '../../shared/appendInfillIfNeeded.js'
import {COLORS} from '../../shared/COLORS.js'
import { formatPrice } from '../../utils'
import {ThumbnailOrPlaceHolderAtom} from "../QuoteTool/partCardAtoms"
import getManufacturabilityIssues from '../../shared/getManufacturabilityIssues.js'
import {getTolerancesLabel} from '../../shared/getTolerancesLabel.js'
import {PartViewerModal} from '../QuoteTool/PartViewer'
import {postProcessesAsString} from '../../shared/postProcessesAsString.js'
import {SnackBarContext} from '../Common/SnackBarStore'
const {TranslateMaterialId} = require('../../Components/Common/TranslationTableMaterial.js')

const PartGroupsSummaryTableBody = ({ MATERIAL_TRANSLATIONS, partGroups, postProcessData, toleranceClassifications }) => {
  const openSnackBarWithMessage = useContext(SnackBarContext)
  const [isPartModalOpen, setIsPartModalOpen] = useState(false)
  const [partGroupInViewer, setPartGroupInViewer] = useState(null)

  const openPartModalHandler = partGroup => {
    if (!partGroup.part.s3ObjFileId){
      openSnackBarWithMessage(`Missing file. Please contact us at contact@autotiv.com to receive your quote!`)
      return // Dont try to open modal
    }

    setPartGroupInViewer(partGroup)
    setIsPartModalOpen(true)
  }

  return(
    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      { partGroups.map(
        (partGroup, index) => 
          <PartGroupSummaryRows
            key={`partGroupSummaryRow-${partGroup.partGroupNumber}`}
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            openPartModalHandler={openPartModalHandler}
            openSnackBarWithMessage={openSnackBarWithMessage}
            partGroup={partGroup}
            postProcessOptionsFromDb={postProcessData}
            toleranceClassifications={toleranceClassifications}
          />
      )}
      <PartViewerModal
        handleClose={() => setIsPartModalOpen(false)}
        open={isPartModalOpen}
        partGroup={partGroupInViewer}
        postProcessData={postProcessData}
      />
    </div>
  )
}

const PartGroupSummaryRows = ({MATERIAL_TRANSLATIONS, openPartModalHandler, openSnackBarWithMessage, partGroup, postProcessOptionsFromDb, toleranceClassifications}) => {
  return(
    <Fragment>
      <PartGroupSummaryRow
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        openPartModalHandler={openPartModalHandler}
        openSnackBarWithMessage={openSnackBarWithMessage}
        partGroup={partGroup}
        postProcessOptionsFromDb={postProcessOptionsFromDb}
        toleranceClassifications={toleranceClassifications}
      />
      <BorderRow/>
      {partGroup.productionToolPartConfigurations
       .filter(ptpc => ptpc.processId === partGroup.processId)
       .map(ptpc => { return (
         <Fragment
           key={`PtpcSummaryRow-${ptpc.productionToolPartConfigurationId}`}
         >
         <PtpcSummaryRow
           MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
           openPartModalHandler={openPartModalHandler}
           partGroup={partGroup}
           ptpc={ptpc}
           postProcessOptionsFromDb={postProcessOptionsFromDb}
         />
         <BorderRow/>
         </Fragment>
       )})
      }
    </Fragment>
  )
}

const PartGroupSummaryRow = ({MATERIAL_TRANSLATIONS, openPartModalHandler, openSnackBarWithMessage, partGroup, postProcessOptionsFromDb, toleranceClassifications}) => {
  return(
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <div>
        <ThumbnailOrPlaceHolderAtom
          isAtRisk={false}
          hasModel={partGroup.part.s3ObjFileId ? true : false}
          onClick={() => openPartModalHandler(partGroup)}
          size='small'
          thumbnailS3id={partGroup.part.thumbnailS3id}
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
        <PartGroupDetailsTableRow
          MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
          partGroup={ partGroup }
        />
      { partGroup.postProcesses.length > 0 ?
        <PostProcessesRow
          postProcesses={partGroup.postProcesses}
          POST_PROCESS_OPTIONS={postProcessOptionsFromDb.POST_PROCESS_OPTIONS}
        />
        : null
      }
      { partGroup.tolerances.length ? <TolerancesRow tolerances={partGroup.tolerances} toleranceClassifications={toleranceClassifications}/> : null }
      { partGroup.attachments.length ? <AttachmentsRow attachments={partGroup.attachments}/> : null }
      { partGroup.part.partNotes ? <NotesTableRow notes={ partGroup.part.partNotes }/> : null }
      {/*
        Invoking getManufacturabilityIssues once would be more efficient than this way of calling it multiple times,
        but this would require refactoring to a class component (lame) or using react hooks (very cool) to add state to persist
        the list of issues
      */}
      { getManufacturabilityIssues(partGroup, postProcessOptionsFromDb.POST_PROCESS_OPTIONS).length ?
        <ManufacturabilityIssuesTableRow manufacturabilityIssues={ getManufacturabilityIssues( partGroup, postProcessOptionsFromDb.POST_PROCESS_OPTIONS ) }/>
        :
        null
      }
      {/*
        Returning multiple table rows because the spec calls for it, but it unfortunately this creates border issues.
        Because we're using rows for BOTH part group details AND associated notes and manufacturability issues we must
        disable bottom borders then apply a border at the very end, which is accomplished with BorderRow
      */}
    </div>
    </div>
  )
}

const PtpcSummaryRow = ({MATERIAL_TRANSLATIONS, openPartModalHandler, partGroup, postProcessOptionsFromDb, ptpc}) => {
  return(
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <ThumbnailOrPlaceHolderAtom
        isAtRisk={false}
        hasModel={partGroup.part.s3ObjFileId ? true : false}
        onClick={() => openPartModalHandler(partGroup)}
        size='small'
        thumbnailS3id={partGroup.part.thumbnailS3id}
      />
      <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
      <PtpcDetailsTableRow
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        partGroup={partGroup}
        ptpc={ptpc}
      />
      { ptpc.postProcesses.length > 0 ?
        <PostProcessesRow
          postProcesses={ptpc.postProcesses}
          POST_PROCESS_OPTIONS={postProcessOptionsFromDb.POST_PROCESS_OPTIONS}
        />
        : null
      }
      { ptpc.attachments.length ? <AttachmentsRow attachments={ptpc.attachments}/> : null }
      { ptpc.notes ? <NotesTableRow notes={ ptpc.notes }/> : null }
      {/*
        Returning multiple table rows because the spec calls for it, but it unfortunately this creates border issues.
        Because we're using rows for BOTH part group details AND associated notes and manufacturability issues we must
        disable bottom borders then apply a border at the very end, which is accomplished with BorderRow
      */}
      </div>
    </div>
  )
}

const PostProcessesRow = ({postProcesses, POST_PROCESS_OPTIONS}) => {
  return(
    <div className="partGroup-summary-table-row">
      <SameLineLabelTableCell label="Post Processes:" value={ postProcessesAsString(postProcesses, POST_PROCESS_OPTIONS) }/>
    </div>
  )
}

const AttachmentsRow = ({attachments}) => {
  const attachmentsString = attachments.map(a => a.fileName).join()
  return (
    <div className="partGroup-summary-table-row">
      <SameLineLabelTableCell label="Attachments:" value={attachmentsString}/>
    </div>
  )
}

const TolerancesRow = ({tolerances, toleranceClassifications}) => {
  const tolerancesString = getTolerancesLabel(tolerances, toleranceClassifications).join(" ")
  return (
    <div className="partGroup-summary-table-row">
      <SameLineLabelTableCell label="Tolerances:" value={tolerancesString}/>
    </div>
  )
}

const BorderRow = () => {
  return(
    <hr style={{ height: '1px', backgroundColor: 'lightgray', width: '100%' }}/>
  )
}

const LabelTableCell = ({ label, moneyValue, style={}, value }) => {
  return(
    <div>
      <div style={{ fontWeight: 'bold',marginBottom: '8px',textAlign: (moneyValue ? 'right' : 'left'), ...style }}>
        { label }
      </div>
      <div style={{ textAlign: (moneyValue ? 'right' : 'left') }}>
        { value }
      </div>
    </div>
  )
}

const PartGroupDetailsTableRow = ({ MATERIAL_TRANSLATIONS, partGroup }) => {
  const materialId = partGroup.processId === 'injMoldingPart' ? partGroup.materialIdProductionToolIsDesignedFor : partGroup.material
  let material = TranslateMaterialId(partGroup.processId, materialId, MATERIAL_TRANSLATIONS)
  material = appendInfillIfNeeded(material, partGroup.processId, partGroup.infillPercentage)

  return(
    <div className="partGroup-summary-table-row">
      <LabelTableCell
        label={ partGroup.part.fileName }
        style={{ width: '25em' }}
        value={`${getProcessDisplay(partGroup)}, ${material}`}
      />
      <LabelTableCell
        label={ "Quantity" }
        value={ partGroup.quantity }
      />
      <LabelTableCell
        label={ "Unit Price" }
        value={ formatPrice(partGroup.unitPrice) }
      />
      <LabelTableCell
        label={ "Extended Price" }
        value={ formatPrice(Number(partGroup.quantity)*Number(partGroup.unitPrice)) }
      />
    </div>
  )
}

function getProcessDisplay(partGroup, isPtpc){
  if(partGroup.processId === 'injMoldingPart' && !isPtpc){
    return 'Injection Mold'
  }
  return partGroup.process
}

const PtpcDetailsTableRow = ({ MATERIAL_TRANSLATIONS, partGroup, ptpc }) => {
  return(
    <div className="partGroup-summary-table-row">
      <LabelTableCell
        label={ partGroup.part.fileName }
        style={{ width: '25em' }}
        value={`${getProcessDisplay(partGroup, true)}, ${TranslateMaterialId(partGroup.processId, ptpc.materialId, MATERIAL_TRANSLATIONS)}, ${getColorDisplayFromColor(ptpc.color)}`}
      />
      <LabelTableCell
        label={ "Quantity" }
        value={ ptpc.quantity }
      />
      <LabelTableCell
        label={ "Unit Price" }
        value={ formatPrice(ptpc.unitPrice) }
      />
      <LabelTableCell
        label={ "Extended Price" }
        value={ formatPrice(Number(ptpc.quantity)*Number(ptpc.unitPrice)) }
      />
    </div>
  )
}

const getColorDisplayFromColor = (color) => {
  const colorMaybe = COLORS
        .find(colorObj => colorObj.value === color)

  return colorMaybe ? colorMaybe.label : ''
}

const SameLineLabelTableCell = ({ label, value }) => {
  return(
      <div>
        <span style={{fontWeight:'bold',marginRight:'8px'}}>
          { label }
        </span>
        {value}
      </div>
  )
}

const NotesTableRow = ({ notes }) => {
  return(
    <div className="partGroup-summary-table-row">
      <SameLineLabelTableCell label="Notes:" value={ notes }/>
    </div>
  )
}

const ManufacturabilityIssuesTableRow = ({ manufacturabilityIssues }) => {
  return(
    <div>
      <span style={{fontWeight:'bold',marginRight:'8px'}}>
        Manufacturability Risks Accepted:
      </span>
      <ul style={{paddingLeft: '1.5em', marginTop: '0em'}}>
        { manufacturabilityIssues.map(issue => {
          return (
            <li>{issue.text}
              { issue.subIssues ?
                <ul>
                  {issue.subIssues.map(subIssue => <li>{subIssue}</li>)}
                </ul>
                : null
              }
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default PartGroupsSummaryTableBody
