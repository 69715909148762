const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')
const HTTP_ERROR_CODES = require('../../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../../utils/throwErrorParsedFromKnownStatusCode.js')

// Sends a PDF file as a base64 encodeded string to be peristently stored on the backend
export async function savePdfFileToGoogleDrive(fileName, fileContents, fileLocation) {
  let response
  try{
    response = await savePdfFileToGoogleDriveRequest(fileName, fileContents, fileLocation)
  } catch (err) {
    logUnexpected(err, { fileName, fileContents, fileLocation }, 'savePdfFileToGoogleDriveRequest')
    throw err
  }

  try{
    parseSavePdfFileToGoogleDriveResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseSavePdfFileToGoogleDriveResponse', { fileName, fileContents, fileLocation })
    throw err
  }

  return
}

async function savePdfFileToGoogleDriveRequest(fileName, fileContents, fileLocation) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/documents/save',
    data: {
      fileName: fileName,
      parentFolderId: fileLocation,
      pdf: fileContents,
    }
  })

  return { data: response.data, status: response.status }
}

function parseSavePdfFileToGoogleDriveResponse(response) {
  if (response.status === 200) {
    if (response.data.success) {
      return response.data.success
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
