import React from 'react'
import { InputAdornment, MenuItem, Switch, TextField, Typography } from '@material-ui/core'
import limitToTwoDecimals from '../QuoteTool/limitToTwoDecimals.js'
import { NumberField } from "../QuoteTool/NumberField.js"

export default function DmeShippingOverrides(props){
  const { onChangeShippingPriceOverride, project, setProjectFieldAndSave } = props
  const onChangeCustomShippingPrice = e => {
    const newShippingPrice = limitToTwoDecimals(e.target.value)
    if(newShippingPrice !== project.customShippingPrice){
      // Because we limit decimals to 2 for a price, it's possible for a change request to
      //   be triggered even if the value isn't actually changed.
      // Therefore we only want to trigger a state update if the value is actually changing
      setProjectFieldAndSave("customShippingPrice", newShippingPrice)
    }
  }

  const onShippingStep = props.checkoutStep === 2

  return (
    <div style={{display:'flex', flexDirection: 'column', gap:'0.5em'}}>
      <div className="dme-shipping-overrides-contents">
        <div className="dme-shipping-overrides-contents-item">
          <div style={{width:'4em'}}>
            <Switch
              checked={project.overrides.shippingPrice === "custom"}
              color="secondary"
              disabled={!onShippingStep}
              onChange={onChangeShippingPriceOverride}
              style={{marginLeft:'0em'}}
            />
          </div>
        </div>
         <div className="dme-shipping-overrides-contents-item">
           {project.overrides.shippingPrice === "custom" ?
           <NumberField
             disabled={!onShippingStep}
             label="Custom Shipping Price"
             InputProps={{
               startAdornment: <InputAdornment position="start">$</InputAdornment>,
             }}
             onChange={onChangeCustomShippingPrice}
             style={{width:'12em'}}
             value={project.customShippingPrice}
           />
            :
            <Typography variant="subtitle1">
              Custom Shipping Price
            </Typography>
           }
         </div>
      </div>

      {project.overrides.shippingPrice === "custom" ?
       <div className="dme-shipping-overrides-contents">
         <div style={{width:'4em'}}>
           <Switch
             checked={project.isCustomShippingMethodUsed}
             color="secondary"
             disabled={props.areShippingOptionsLoading || !onShippingStep}
             onChange={ev => setProjectFieldAndSave("isCustomShippingMethodUsed", ev.target.checked)}
             style={{marginLeft:'0em'}}
           />
         </div>

         {project.isCustomShippingMethodUsed ?
            <TextField
              disabled={!onShippingStep}
              label="Custom Shipping Method"
              onChange={ev => setProjectFieldAndSave("customShippingMethod", ev.target.value)}
              style={{width:'20em'}}
              value={project.customShippingMethod}
            />
          :
          <Typography variant="subtitle1">
            Custom Shipping Method
          </Typography>
         }
       </div>
       :
       null
      }

      {project.isCustomShippingMethodUsed ?
       <div className="dme-shipping-overrides-contents">
         <div style={{width:'4em'}}/>
         <TextField
           disabled={!onShippingStep}
           InputProps={{
             endAdornment: <InputAdornment position="end">Business Days</InputAdornment>,
           }}
           label="Days in Transit"
           onChange={ev => setProjectFieldAndSave("customShippingDays", ev.target.value)}
           style={{width:'14em'}}
           value={project.customShippingDays}
         />
       </div>
       : null}

    </div>
  )
}
