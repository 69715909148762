const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')
const HTTP_ERROR_CODES = require('../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../utils/throwErrorParsedFromKnownStatusCode.js')

export default async function sendLogisticsQuoteEmail(project, projectAnalysisResults){
  let response
  try{
    response = await sendLogisticsQuoteEmailRequest(project, projectAnalysisResults)
  } catch (err){
    logUnexpected(err, { project, projectAnalysisResults }, 'sendLogisticsQuoteEmailRequest')
    throw err
  }

  let result
  try{
    result = parseSendLogisticsQuoteEmailResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseSendLogisticsQuoteEmailResponse', { project, projectAnalysisResults })
    throw err
  }

  return result
}

function sendLogisticsQuoteEmailRequest(project, projectAnalysisResults){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/emails/logistics-quote',
    data: {
      project,
      projectAnalysisResults,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseSendLogisticsQuoteEmailResponse(response){
  if (response.status === 200) {
    if (response.data && response.data.success) {
      return response.data.success
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
