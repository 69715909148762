import React from 'react'
import {AppBar} from '@material-ui/core'
import AppToolBar from '../Common/AppToolBar'

const TopAppBar = ({ changeUserType, user }) => {
  return(
    <AppBar
      position="sticky" // DXI: we want it to always be at top, and we want "free" offset
    >
      <AppToolBar loggedIn={true} user={user} changeUserType={changeUserType}/>
    </AppBar>
  )
}

export default TopAppBar
