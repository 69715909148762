import React,{useState} from 'react'
import { Button } from '@material-ui/core'
import ProjectFolderLinkButton from '../Common/ProjectFolderLinkButton.js'
import RefreshFilesButton from './RefreshFilesButton.js'
import retryFolders from './retryFolders.js'

export default function GdriveButtons({openSnackBarWithMessage, project}){
  return (
    project.projectFolderLink ?
      <>
        <ProjectFolderLinkButton
          projectFolderLink={project.projectFolderLink}
          size="medium"
        />
        <RefreshFilesButton
          openSnackBarWithMessage={openSnackBarWithMessage}
          projectNumber={project.projectNumber}
        />
      </>
    :
    <RetryFoldersButton
      customerName={project.customer.customerName}
      openSnackBarWithMessage={openSnackBarWithMessage}
      projectNumber={project.projectNumber}
    />
  )
}

function RetryFoldersButton({customerName, openSnackBarWithMessage, projectNumber}){
  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => {
    setIsLoading(true)
    retryFolders(customerName, projectNumber)
      .then(() => openSnackBarWithMessage('Please refresh the page to check for folder id'))
      .catch(e => openSnackBarWithMessage(e.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return(
    <Button
      color="secondary"
      disabled={isLoading}
      onClick={onClick}
      variant='contained'
    >
      { isLoading ? 'Retrying...' : 'Retry Gdrive Folders' }
    </Button>
  )
}
