import React,{Component,Fragment} from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {AppBar,Paper,Toolbar,Typography,withStyles} from '@material-ui/core'
import AppToolBar from '../Common/AppToolBar'
import {
  formatPrice,
  getOrderDateFromCurrentTime,
  loadProject,
  TranslateExpediteLevelToValue,
} from '../../utils'
import cloneProject from '../QuoteTool/cloneProject.js'
import getFileNameFromProject from '../QuoteTool/getFileNameFromProject.js'
import getProjectQuotePdf from '../QuoteTool/getProjectQuotePdf.js'
import downloadPdfToLocalMachineFromBase64 from '../../utils/downloadPdfToLocalMachineFromBase64.js'
import getProjectSummaryPdf from './getProjectSummaryPdf.js'
import getShippingOptionsArray from '../Checkout/getShippingOptionsArray.js'
import holidayArray from '../../../src/shared/holidayArray.json'
import Logo from '../../Images/logo.png'
import {Link} from '@reach/router'
import makeInvoice from '../Checkout/makeInvoice.js'
import DmeSummarySections from './DmeSummarySections.js'
import PartGroupsSummaryTableBody from '../Common/PartGroupsSummaryTableBody.js'
import {placeOrderAsDme} from '../Checkout/placeOrder'
import saveProjectAndNestedObjects from '../ShipmentTracking/saveProjectAndNestedObjects.js'
import updateProjectManager from '../Common/updateProjectManager.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
import withProject from '../Common/withProject.js'
const moment = require('moment-business-days');
const DATE_FORMAT = 'MM/DD/YYYY' // https://momentjs.com/docs/#/parsing/string-format/

class ProjectReviewPage extends Component {
  componentDidMount(){
    // this prevents users from using browser forward/back pages to navigate to Checkout page
    this.props.disableUserNavigationToCheckoutPage()
  }

  setProjectFieldAndSave = (name, value) => {
    this.props.setProject({ ...this.props.project, [name]: value }, { saveProject: true })
  }

  takeProjectFromCustomer = () => {
    const callback = () => this.props.navigateToProject(this.props.project.projectNumber)

    this.props.setProject({ ...this.props.project, customerReady: false }, { callback, dontRecalc: true, saveProject: true })
  }

  downloadProjectSummaryPdf = async () => {
    const fileContents = await getProjectSummaryPdf(this.props.project)

    downloadPdfToLocalMachineFromBase64(fileContents, `Project ${this.props.project.projectNumber} Summary.pdf`)
  }

  downloadQuotePdf = async () => {
    let fileContents
    if(this.props.reviewReasons && this.props.reviewReasons.projectReasons.length && this.props.reviewReasons.partsReasons.length){
      this.props.openSnackBarHandler('Pricing is not currently available for this project')
    } else {
      const selectedLeadTime = 0
      fileContents = await getProjectQuotePdf(this.props.project, this.props.pricingData, selectedLeadTime)
    }

    let currentQuoteRevision = +this.props.project.lastQuoteRevision + 1
    currentQuoteRevision = Number.isNaN(currentQuoteRevision) ? 0 : currentQuoteRevision

    const fileName = getFileNameFromProject(this.props.project, '', currentQuoteRevision)
    downloadPdfToLocalMachineFromBase64(fileContents, `${fileName}.pdf`)

    this.props.setProject({ ...this.props.project, lastQuoteRevision: currentQuoteRevision }, { dontRecalc: true, saveProject: true })
  }

  placeOrderFromWaitingForPayment = async () => {
    let project = {...this.props.project}

    project.projectStatus = "Building"
    project.quoteStatus = "Won"

    project.orderDate = getOrderDateFromCurrentTime()

    const leadTime = project.sampleLeadTime ? project.sampleLeadTime : project.paidLeadTime
    project.paidShipDate = calculateShipDate(leadTime, project.orderDate)

    project.paidArrivalDate = await calculateArrivalDate(project)

    const selectedPricingData = JSON.parse(project.selectedPricingData)
    const pricingDataMaybe = selectedPricingData.pricingData
    if(!pricingDataMaybe){
      this.props.openSnackBarHandler("Missing pricingData, maybe project was moved to Waiting for Payment by the old app?")
    }

    const leadTimePricePair = pricingDataMaybe.leadTimePricePairs
          .find(ltpp => ltpp.price === selectedPricingData.selectedLeadTime)

    const makeOrders = project.isLeadTimeLocked ? JSON.parse(project.lockedMakeOrders) : leadTimePricePair.makeOrders
    return placeOrderAsDme(project, makeOrders)
      .then(projectToSave => saveProjectAndNestedObjects(projectToSave))
      .then(async () => {
        const updatedProject = await loadProject(project.projectNumber, this.props.user)
        this.props.setProject(updatedProject)
      })
      .catch(e => {
        this.props.openSnackBarHandler(e.message)
      })
  }

  setUserAsDme = () => {
    try{
      const project = updateProjectManager(deepCopy(this.props.project), this.props.user.email)
      this.props.setProject(project, { saveProject: true, dontRecalc: true })
    } catch(e) {
      this.props.openSnackBarHandler(e.message)
    }
  }

  editProject = () => {
    const callback = () => this.props.navigateToProject(this.props.project.projectNumber)
    this.props.setProject({
      ...this.props.project,
      projectStatus: 'Quoting',
      previouslyBookedProject: JSON.stringify(this.props.project),
    }, { callback, dontRecalc: true, saveProject: true })
  }

  cancelProject = () => {
    if(this.props.project.makeOrders.length || this.props.project.shipments.length){
      this.props.openSnackBarHandler("You can only cancel Projects that have no Make Orders or Shipments")
    } else {
      const callback = () => {
        this.props.navigateToProject(this.props.project.projectNumber)
        this.props.openSnackBarHandler("Make sure to remove any unneeded Invoices from Quickbooks")
      }
      this.props.setProject({
        ...this.props.project,
        projectStatus: 'Quoting',
      }, { callback, dontRecalc: true, saveProject: true })
    }
  }

  makeBookedProjectInvoice = () => {
    const isWaitingForPaymentInvoice = false
    makeInvoice(this.props.project, this.props.MATERIAL_TRANSLATIONS, isWaitingForPaymentInvoice)
      .then(() => this.props.openSnackBarHandler('Invoice data sent to Zapier!'))
      .catch(e => this.props.openSnackBarHandler(`Error sending data to Zapier: ${e.message}`))
  }

  cloneProject = async () => {
    const userIsDme = this.props.user.type === USER_TYPES.DME
    return cloneProject(this.props.project, userIsDme)
      .then(projectNumber => {
        this.props.navigateToProject(projectNumber)
      })
      .catch(err => {
        this.props.openSnackBarHandler(err.message)
      })
  }

  render(props){
    return(
      <Fragment>
        <TopBar
          changeUserType={this.props.changeUserType}
          classes={this.props.classes}
          user={this.props.user}
        />
        <div className={this.props.classes.container}>
          <Typography color="textPrimary" gutterBottom variant="h3">
            Project {this.props.project.projectNumber} Summary
          </Typography>
          <ProjectSummaryCards
            cancelProject={this.cancelProject}
            classes={this.props.classes}
            cloneProject={this.cloneProject}
            downloadProjectSummaryPdf={this.downloadProjectSummaryPdf}
            downloadQuotePdf={this.downloadQuotePdf}
            editProject={this.editProject}
            makeBookedProjectInvoice={this.makeBookedProjectInvoice}
            MATERIAL_TRANSLATIONS={this.props.MATERIAL_TRANSLATIONS}
            navigateToRemakeRequestPage={this.props.navigateToRemakeRequestPage}
            navigateToShipmentTrackingPage={this.props.navigateToShipmentTrackingPage}
            placeOrderFromWaitingForPayment={this.placeOrderFromWaitingForPayment}
            postProcessData={this.props.postProcessData}
            project={this.props.project}
            reviewReasons={this.props.reviewReasons}
            setProjectFieldAndSave={this.setProjectFieldAndSave}
            setUserAsDme={this.setUserAsDme}
            takeProjectFromCustomer={this.takeProjectFromCustomer}
            user={this.props.user}
          />
        </div>
      </Fragment>
    )
  }
}

function TopBar({ changeUserType, classes, user }){
  return(
    <AppBar position="static">
      <AppToolBar changeUserType={changeUserType} changeUserloggedIn={true} showHomeLink={true} user={user}/>
    </AppBar>
  )
}

function ProjectSummaryCards({
  cancelProject,
  classes,
  cloneProject,
  downloadProjectSummaryPdf,
  downloadQuotePdf,
  editProject,
  project,
  makeBookedProjectInvoice,
  MATERIAL_TRANSLATIONS,
  navigateToRemakeRequestPage,
  navigateToShipmentTrackingPage,
  placeOrderFromWaitingForPayment,
  postProcessData,
  reviewReasons,
  setProjectFieldAndSave,
  setUserAsDme,
  takeProjectFromCustomer,
  user,
}){
  const ORDER_DATE = project.orderDate ? project.orderDate : getOrderDateFromCurrentTime()
  const CUSTOMER_PROJECT_STATUS = project.projectStatus === "Building" ? "In Production" : (project.orderSubmittedType === "checkedOut" ? "Awating Autotiv Review" : project.projectStatus)
  const HAS_REVIEW_REASONS = reviewReasons.partsReasons.length > 0 || reviewReasons.projectReasons.length > 0

  return (
    <>
      { user.type === USER_TYPES.DME ?
        <DmeSummarySections
          cancelProject={cancelProject}
          classes={classes}
          cloneProject={cloneProject}
          downloadProjectSummaryPdf={downloadProjectSummaryPdf}
          downloadQuotePdf={downloadQuotePdf}
          editProject={editProject}
          makeBookedProjectInvoice={makeBookedProjectInvoice}
          navigateToRemakeRequestPage={navigateToRemakeRequestPage}
          navigateToShipmentTrackingPage={navigateToShipmentTrackingPage}
          placeOrderFromWaitingForPayment={placeOrderFromWaitingForPayment}
          project={project}
          setProjectFieldAndSave={setProjectFieldAndSave}
          takeProjectFromCustomer={takeProjectFromCustomer}
          user={user}
        />
        : null }

      { user.type === USER_TYPES.DME ?
      <CustomerInfoCard
        classes={classes}
        project={project}
        setUserAsDme={setUserAsDme}
      />
        : null }

      { 
        (user.type === USER_TYPES.DME ?
          <DmeToolsSections
            classes={classes}
            CUSTOMER_PROJECT_STATUS={CUSTOMER_PROJECT_STATUS}
            ORDER_DATE={ORDER_DATE}
            project={project}
          />
          :
         <>
           <BookedProjectTable
             classes={classes}
             CUSTOMER_PROJECT_STATUS={CUSTOMER_PROJECT_STATUS}
             ORDER_DATE={ORDER_DATE}
             project={project}
           />
           {project.projectStatus === "Waiting for Payment" || project.projectStatus === "Building" ?
            <ShippingBillingPaper
              classes={classes}
              project={project}
            />
            : null}
         </>
        )
      }
      <PartsTableCard
        classes={classes}
        HAS_REVIEW_REASONS={HAS_REVIEW_REASONS}
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        postProcessData={postProcessData}
        project={project}
      />
      <ShipmentsCard
        classes={classes}
        project={project}
      />
    </>
  )
}

function CustomerInfoCard({
  classes,
  project,
  setUserAsDme,
}){
  return(
    <Paper className={classes.paperSection} elevation={4}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>

        <div>
          <div>
            <Typography variant="subtitle2"><b>Customer</b></Typography>
            <Typography variant="subtitle2">{project.customer.emailAndName}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2"><b>Contacted Through</b></Typography>
            <Typography variant="subtitle2">{project.contactedThrough ? project.contactedThrough : '-'}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2"><b>Customer Facing Notes</b></Typography>
            <Typography variant="subtitle2">{project.customerNotes ? project.customerNotes : '-'}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2"><b>Supplier Facing Notes</b></Typography>
            <Typography variant="subtitle2">{project.supplierNotes ? project.supplierNotes : '-'}</Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography variant="subtitle2"><b>Project Manager</b></Typography>
            <Typography variant="subtitle2">{project.projectManager && project.projectManager.name && project.projectManager.name.first ? project.projectManager.name.first : '-'}</Typography>
            <Button
              color="primary"
              onClick={setUserAsDme}
              size="small"
              variant="contained"
            >
              Make me the Project Manager
            </Button>
          </div>
          <div>
            <Typography variant="subtitle2"><b>Logistics Lead</b></Typography>
            <Typography variant="subtitle2">{project.operationsPerson && project.operationsPerson.length ? project.operationsPerson[0].identifier : '-'}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2"><b>Company Account Executive</b></Typography>
            <Typography variant="subtitle2">{project.customer.company && project.customer.company.accountExecutive && project.customer.company.accountExecutive[0] ? project.customer.company.accountExecutive[0].identifier : '-'}</Typography>
          </div>
        </div>

      </div>
    </Paper>
  )
}

function DmeToolsSections({
  classes,
  CUSTOMER_PROJECT_STATUS,
  ORDER_DATE,
  project,
}){
  return (
    <>
      {project.projectStatus === "Quoting" ?
       <QuotingProjectTable
         classes={classes}
         CUSTOMER_PROJECT_STATUS={CUSTOMER_PROJECT_STATUS}
         project={project}
       />
       : null}

      {project.projectStatus === "Waiting for Payment" || project.projectStatus === "Building" ?
       <>
         <BookedProjectTable
           classes={classes}
           CUSTOMER_PROJECT_STATUS={CUSTOMER_PROJECT_STATUS}
           ORDER_DATE={ORDER_DATE}
           project={project}
         />
         <ShippingBillingPaper
           classes={classes}
           project={project}
         />
       </>
       : null}
    </>
  )
}

function QuotingProjectTable({ classes, CUSTOMER_PROJECT_STATUS, project }){
  return(
    <Paper className={classes.paperSection} elevation={4}>
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Project Status</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{CUSTOMER_PROJECT_STATUS}</td>
          </tr>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Requested Lead Time</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{project.leadTimeRequest ? project.leadTimeRequest : `-`}</td>
          </tr>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Project Notes</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{project.customerNotes ? `${project.customerNotes}` : `-`}</td>
          </tr>
          {project.attachments.length ?
           <tr>
             <td style={{fontWeight:'bold',paddingTop:'16px'}}>Attachments</td>
             <td style={{paddingTop:'16px',textAlign:'right'}}>
               {project.attachments.map(attachment=>{
                 return(
                   <Fragment key={attachment.fileId}>
                     {attachment.fileName}<br/>
                   </Fragment>
                 )
               })}
             </td>
           </tr> : null
          }
        </tbody>
      </table>
    </Paper>
  )
}

function BookedProjectTable({ classes, CUSTOMER_PROJECT_STATUS, ORDER_DATE, project }){
  let orderDate = ORDER_DATE
  let leadTime = project.paidExpediteLevel ? `${TranslateExpediteLevelToValue(project)} Business Day(s)` : null

  orderDate = project.orderDate
  leadTime = project.paidLeadTime

  const shipMethod = project.isCustomShippingMethodUsed ? project.customShippingMethod : project.paidShipMethod

  return (
    <Paper className={classes.paperSection} elevation={4}>
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Project Status</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{CUSTOMER_PROJECT_STATUS}</td>
          </tr>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Order Date</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{orderDate ? orderDate : '-'}</td>
          </tr>
          {project.samplesLeadTime ?
           <tr>
             <td style={{fontWeight:'bold',paddingTop:'16px'}}>Samples Lead Time</td>
             <td style={{paddingTop:'16px',textAlign:'right'}}>{project.samplesLeadTime}</td>
           </tr>
           :
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Production Lead Time</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{leadTime ? leadTime : `-`}</td>
          </tr>
          }
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>{project.samplesLeadTime ? 'Expected Samples Ship Date ' :'Expected Ship Date'}</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{project.paidShipDate ? project.paidShipDate : `-`}</td>
          </tr>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Shipping Method</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{shipMethod? `${shipMethod}` : `-`}</td>
          </tr>
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>{project.samplesLeadTime ? 'Expected Samples Delivery Date' :'Expected Delivery Date'}</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{project.paidArrivalDate ? project.paidArrivalDate : '-'}</td>
          </tr>
          {project.samplesLeadTime ?
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Production Lead Time</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>{leadTime ? leadTime : `-`}</td>
          </tr>
           : null }
          <tr>
            <td style={{fontWeight:'bold',paddingTop:'16px'}}>Shipping Carrier & Account</td>
            <td style={{paddingTop:'16px',textAlign:'right'}}>
              {project.shipProvider} {project.shipAccountNumber ? `- ${project.shipAccountNumber}`:null}
            </td>
          </tr>
          {project.customerNotes ?
           <tr>
             <td style={{fontWeight:'bold',paddingTop:'16px'}}>Project Notes</td>
             <td style={{paddingTop:'16px',textAlign:'right'}}>{project.customerNotes}</td>
           </tr> : null
          }
          {project.attachments.length ?
           <tr>
             <td style={{fontWeight:'bold',paddingTop:'16px'}}>Attachments</td>
             <td style={{paddingTop:'16px',textAlign:'right'}}>
               {project.attachments.map(attachment=>{
                 return(
                   <Fragment>
                     {attachment.fileName}<br/>
                   </Fragment>
                 )
               })}
             </td>
           </tr> : null
          }
        </tbody>
      </table>
    </Paper>
  )
}

function ShippingBillingPaper({ classes, project }){
  return (
    <Paper className={classes.paperSection} elevation={4}>
      <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
        <div style={{margin:'16px'}}>
          <span style={{fontWeight:'bold'}}>Ship to:</span><br/>
          <table style={{width:'100%'}}>
            <tbody>
              <tr>
                <td style={{paddingTop:'8px'}}>
                  {project.shipName ? project.shipName : '<No shipping name given>'}<br/>
                </td>
              </tr>
              {project.shipCompany ?
               <tr>
                 <td style={{paddingTop:'8px'}}>
                   {project.shipCompany}<br/>
                 </td>
               </tr>
               : null}
              <tr>
                <td style={{paddingTop:'8px'}}>
                  {project.shipAddress.street ? project.shipAddress.street : '<No street given>'}<br/>
                </td>
              </tr>
              {project.shipAddress.street2 ?
               <tr>
                 <td style={{paddingTop:'8px'}}>
                   {project.shipAddress.street2}<br/>
                 </td>
               </tr>
               : null}
              <tr>
                <td style={{paddingTop:'8px'}}>
                  {project.shipAddress.city ? project.shipAddress.city : '<No city given>'},{" "}
                  {project.shipAddress.state ? project.shipAddress.state : '<No state given>'}{" "}
                  {project.shipAddress.zip ? project.shipAddress.zip : '<No zip code given>'}<br/>
                </td>
              </tr>
              <tr>
                <td style={{paddingTop:'8px'}}>
                  {project.shipAddress.country ? project.shipAddress.country : '<No country given>'}<br/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{margin:'16px'}}>
          <table style={{width:'100%'}}>
            <tbody>
              {project.projectStatus === 'Quoting' ? null :
               <tr>
                 <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Payment Method</td>
                 <td style={{paddingTop:'16px'}}>{project.paymentLocation}</td>
               </tr>
              }
              {project.projectStatus !== 'Quoting' && project.paymentLocation === 'PO' ?
               <tr>
                 <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>PO #</td>
                 <td style={{paddingTop:'16px'}}>{project.paymentReferenceNumber}</td>
               </tr> : null
              }
              <tr>
                <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Billing Name</td>
                <td style={{paddingTop:'16px'}}>{project.billingName}</td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Billing Email</td>
                <td style={{paddingTop:'16px'}}>{project.billingEmail}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <hr/>
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Billing Company</td>
                <td style={{paddingTop:'16px'}}>{project.customer.company ? project.customer.company.companyName : ''}</td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Credit Terms</td>
                <td style={{paddingTop:'16px'}}>{project.customer.company ? project.customer.company.creditTerms : ''}</td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold',paddingRight:'8px',paddingTop:'16px'}}>Max Order Credit</td>
                <td style={{paddingTop:'16px'}}>{project.customer.company ? project.customer.company.creditLimit : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Paper>
  )
}

function PartsTableCard({ classes, HAS_REVIEW_REASONS, MATERIAL_TRANSLATIONS, postProcessData, project }){
  return(
    <Accordion className={classes.paperSection} elevation={4} defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <span style={{fontWeight:'bold'}}>Parts</span>
      </AccordionSummary>
      <AccordionDetails style={{width: '100%'}}>

        <div style={{marginRight: '3em', width: '100%'}}>
          <PartGroupsSummaryTableBody
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            partGroups={project.partGroups}
            postProcessData={postProcessData}
          />
          <div>
            { (project.expediteFee > 0 && project.expediteFee !== "N/A") ?
              <div className="partGroup-summary-table-total-row">
                <span style={{fontWeight:'bold'}}>Expedite</span>
                <div style={{textAlign:'right'}}>
                  {project.expediteFee ? formatPrice(project.expediteFee) : '-'}
                </div>
              </div>
              : null }
            { project.quoteAdjustment > 0 ?
              <div className="partGroup-summary-table-total-row">
                <span style={{fontWeight:'bold'}}>Quote Adjustment</span>
                <div style={{textAlign:'right'}}>
                  {project.quoteAdjustment ? formatPrice(project.quoteAdjustment) : '-'}
                </div>
              </div>
              : null }

            <CostTableRowMaybe
              hasReviewReasons={HAS_REVIEW_REASONS}
              label="Subtotal"
              price={project.subTotal}
            />

            { project.discount > 0 ?
              <CostTableRowMaybe
                hasReviewReasons={HAS_REVIEW_REASONS}
                label={project.promoCode ? `Discount (${project.promoCode})` : "Discount"}
                price={0-project.discount}
              />
              : null }

            <CostTableRowMaybe
              hasReviewReasons={HAS_REVIEW_REASONS}
              label="Shipping"
              price={project.shippingRevenue}
            />

            <CostTableRowMaybe
              hasReviewReasons={HAS_REVIEW_REASONS}
              label="Total"
              price={project.totalSale}
            />

          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const CostTableRowMaybe = ({hasReviewReasons,label,price}) => {
  return(
    hasReviewReasons ?
      null :
      <div className="partGroup-summary-table-total-row" style={{color: price < 0 ? 'red' : 'black'}}>
          <span style={{fontWeight:'bold'}}>{label}</span>
        <div style={{textAlign:'right'}}>
          {price ? formatPrice(price) : '-'}
        </div>
      </div>
  )
}

const calculateShipDate = (leadTime, projectOrderDate) => {
  if(leadTime > 0 && leadTime <Infinity){
    const orderDate = moment(projectOrderDate, DATE_FORMAT)
    const shipDate = calculateDateFromBusinessDayOffset(orderDate, leadTime, holidayArray).format(DATE_FORMAT)

    return shipDate
  } else {
    throw Error(`Lead time: ${leadTime} is an invalid value`)
  }
}

function calculateDateFromBusinessDayOffset(date, businessDays, holidays){
  moment.updateLocale('us', {
    holidays: holidays,
    holidayFormat: DATE_FORMAT,
  })
  let isWeekend = !date.isBusinessDay()
  if(date.isHoliday() || isWeekend){
    businessDays ++
  }
  return date.businessAdd(businessDays)
}

const calculateArrivalDate = async (project) => {
  const shippingOptions = await getShippingOptionsArray(project, project.paidShipDate)

  const shipOptionSelected = shippingOptions.find(option => {
    const method = project.isCustomShippingMethodUsed ? 'custom' : project.paidShipMethod
    return (option.shipMethod === method)
  })

  return shipOptionSelected.arrivalDate
}

function ShipmentsCard({ classes, project }){
  const filteredShipments = project.shipments
        .filter(shipment => shipment.shipmentTo === 'Customer')
        .filter(shipment => typeof shipment.trackingNumberLink === 'string')

  return (
    <Paper className={classes.paperSection} elevation={4}>
      <Typography style={{marginBottom: '0.5em'}}><b>Shipments</b></Typography>
      {filteredShipments.length ? filteredShipments.map(shipment => {
        return (
          <Typography key={'shipment' + shipment.shipmentNumber} variant="body2">
            <a href={shipment.trackingNumberLink} target="_blank">{shipment.shipmentNumber} Tracking Link</a>
          </Typography>
        )
      })
       :
      <Typography variant="body2"> No shipments have been picked up by a carrier yet </Typography>
      }
      <br/>
      <Typography variant="body2"> If you have any questions please contact us at contact@autotiv.com </Typography>
    </Paper>
  )
}

const deepCopy = (array) => JSON.parse(JSON.stringify(array))

const STYLES = theme => ({
  container:{
    marginBottom: theme.spacing(4),
    marginLeft: '10vw',
    marginRight: '10vw',
    marginTop: '5vh'
  },
  paperSection:{
    marginBottom: theme.spacing(3),
    minWidth: '400px',
    padding: '16px',
    width: '65vw'
  },
  links:{
    color:'white',
    '&:visited':{
      color:'white',
    },
    '&:hover':{
      color:'#dedede',
    },
  },
  logo:{
    height: '40px'
  }
})

export default withStyles(STYLES)(withProject(ProjectReviewPage))
