const se = require('serialize-error')
const axios = require('axios')
const { retryWithExponentialBackoffFactory } = require('./retryWithExponentialBackoffFactory.js')
const HTTP_ERROR_CODES = require('./HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('./throwErrorParsedFromKnownStatusCode.js')

/*
  For some reason, the production build would fail and say that this file needs to be exported
  using the 'export' syntax instead of module.exports. Not sure why, but doing that allowed the
  build to succeed.
*/
export async function logUnexpected(error, inputThatTriggeredError, fxnName, context) {

  const makeCall = () => logUnexpectedInternal(error, inputThatTriggeredError, fxnName, context)
  const retry = retryWithExponentialBackoffFactory(makeCall)
  return makeCall()

  async function logUnexpectedInternal(error, inputThatTriggeredError, fxnName, context) {

    // Serialize the javascript Error type into an Object that can be serialized into JSON to be sent
    // to the backend
    const plainOldObjectError = se.serializeError(error)

    let response
    try {
      response = await logUnexpectedRequest(plainOldObjectError, inputThatTriggeredError, fxnName, context)
    } catch(err) {

      // retry this request on errors by default because its important that we try to get it through
      // so that we get the error report and its not the end of the world if we accidentally send a
      // duplicate request and have the error email sent twice
      return retry(err)

      throw err
    }

    try {
      parseLogUnexpectedResponse(response)
    } catch(err) {

      // retry this request on errors by default because its important that we try to get it through
      // so that we get the error report and its not the end of the world if we accidentally send a
      // duplicate request and have the error email sent twice
      return retry(err)

      throw err
    }

    return
  }
}

async function logUnexpectedRequest(plainOldObjectError, inputThatTriggeredError, fxnName, context) {
  const response = await axios({
    method: 'post',
    validateStatus: () => true,
    url: '/report/errors/unexpected',
    data: {
      error: plainOldObjectError,
      inputThatTriggeredError: inputThatTriggeredError,
      fxnName: fxnName,
      context: context,
    }
  })

  return { status: response.status, data: response.data }
}

function parseLogUnexpectedResponse(response) {
  if (response.status === 200) {
    // happy path
    if (response.data.success === 'Success') return

    // case of a 200 HTTP status but the response body didnt contain the expected result
    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  // Non-200 status code. This is probably an error returned by our AWS infrastructure rather than
  // our application
  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}

