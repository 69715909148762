import frg from '../../../feature-release-guards.js'
import { Auth } from 'aws-amplify'
import { sendErrorEmail } from '../../../utils/util.js'

export function login(email, password) {
  if(frg.emailCaseDesensitize){
    email = makeEmailLowercaseBeforeSendingToCognito(email)
  }
  return Auth.signIn(email, password)
    .catch(error => {
      /* If login failed for some reason */
      // catch regex errors from amplify and make them easier to read
      if (error.message.includes('validation error')) {
        if (error.message.includes('userName')) {
          error.message = 'This is not a valid email. Please try again'
        }
        throw new Error('This is not a valid email. Please try again')
      }

      throw error
    })
}

export function signUp(email, password, firstName, lastName) {
  const name = firstName + " " + lastName
  if(frg.emailCaseDesensitize){
    email = makeEmailLowercaseBeforeSendingToCognito(email)
  }
  return Auth.signUp({
    username: email,
    password: password,
    attributes: {
      "name": name,
      "custom:firstName": firstName,
      "custom:lastName": lastName
    }
  })
  .catch((err) => {
    // catch regex errors from amplify and make them easier to read
    if (err.message.includes('validation error')) {
      if (err.message.includes('userName')) {
        throw new Error('This is not a valid email. Please try again')
      }
      else if (err.message.includes('password')) {
        if (err.message.includes('^[\\S]+.*[\\S]+$')) {
          throw new Error('Password cannot begin or end with a whitespace')
        }
      }
    }

    throw err
  })
}

export function changePassword(oldPassword, newPassword) {
  return Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, oldPassword, newPassword))
    .catch((err) => {
      if(err.message === 'Incorrect username or password.'){
        throw Error('Old Password is incorrect')
      }
      // catch regex errors from amplify and make them easier to read
      else if (err.message.includes('validation error')) {
        let whichPassword = ''
        if (err.message.includes('proposedPassword')) {
          whichPassword = 'New password'
        }
        if (err.message.includes('previousPassword')) {
          whichPassword = 'Old password'
        }
        // password contains a whitespace error
        if (err.message.includes('^[\\S]+.*[\\S]+$')) {
          throw Error(whichPassword + ' cannot begin or end with a whitespace')
        }
      }

      throw err
    })
}

/*
  Tells Cognito to send a forgot password code email to the users email and also handles errors from Cognito
*/
export async function forgotPasswordSendCode(email) {
  if(frg.emailCaseDesensitize){
    email = makeEmailLowercaseBeforeSendingToCognito(email)
  }
  try {
    await Auth.forgotPassword(email)
  } catch(err) {
    if (err.code === "UserNotFoundException") {
      throw new Error(err.code) // will parse this above to route to signup
    } else {
      throw new Error('This is not a valid email. Please try again')
    }
  }
}

export function forgotPasswordConfirmCode(email, code, newPassword) {
  if (frg.emailCaseDesensitize) {
  email = makeEmailLowercaseBeforeSendingToCognito(email)
  }
  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

export async function logout() {
  return Auth.signOut()
    .catch((err) => {
      sendErrorEmail({
        error: err.message,
        info: 'Failed to log customer out of Customer Web App',
      })
      throw err
    })
}

function makeEmailLowercaseBeforeSendingToCognito(email) {
  return email.toLowerCase()
}
