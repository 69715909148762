import React from "react"
import PostProcessConfig from './PostProcessConfig.js'

export default function DmeConfigMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  return(
    <PostProcessConfig
      className="dmeInput"
      configSelectOptions={postProcessOption.dmeInputs.dmeConfig.dmeConfigSelectOptions}
      configNameValue={'dmeConfig'}
      configTitle={postProcessOption.dmeInputs.dmeConfig.dmeConfigTitle}
      configType={postProcessOption.dmeInputs.dmeConfig.dmeConfigType}
      postProcessObject={postProcessObject}
      setSpecificPostProcessingField={setSpecificPostProcessingField}
    />
  )
}
