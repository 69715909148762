export default function updateProjectWithEasyAccessDataForKnackViews(project){
  project.process = getProcessFromPartGroups(project.partGroups)
  project.materials = getUniqueMaterialsListFromPartGroups(project.partGroups)

  project.numberOfUniqueParts = project.partGroups.length
  project.partsListForQb = createFormattedStringFromPartList(project.partGroups)

  return project
}

function getProcessFromPartGroups(partGroups){
  const processesArray = partGroups
        .map(partGroup => partGroup.process)

  const numberOfUniqueProcesses = processesArray
        .filter(onlyUnique)
        .length

  if(numberOfUniqueProcesses === 0){
    return ""
  }
  if(numberOfUniqueProcesses > 1){
    return "Multi Process"
  }
  return processesArray[0]
}

function getUniqueMaterialsListFromPartGroups(partGroups){
  return partGroups
    .map(partGroup => partGroup.material)
    .filter(onlyUnique)
    .join()
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function createFormattedStringFromPartList(partGroups){
  return partGroups.map(
    ptg => `${ptg.quantity} x ${ptg.material}: ${ptg.part.fileName}`
  )
    .join('<br />')
}

