import React,{Fragment} from 'react'
import { NativeSelect as Select, Paper, Typography } from '@material-ui/core'
import { getDataTableRecord } from './getDataTableRecord.js'
import INTERMEDIATE_INFO from './INTERMEDIATE_INFO.json'

export default function AdditionalServiceCard({activities, onChangeProjectFieldHandler, project}){
  return (
    <Paper align="center" style={{ padding: '0.5em' }}>
      <Typography align="center" variant="h6"> {project.asParam_serviceType ? project.asParam_serviceType : 'No'} Additional Service </Typography>
      <br/>

      { project.asParam_serviceType === 'Internal' ?
        <InternalServiceCard
          activities={activities}
          onChangeProjectFieldHandler={onChangeProjectFieldHandler}
          project={project}
        /> : null }

      { project.asParam_serviceType === 'External' ? <ExternalServiceCard project={project} /> : null }
    </Paper>

  )
}

function InternalServiceCard({activities, onChangeProjectFieldHandler, project}){
  const ActivityOption = ({activityNumber}) => { return <option key={activityNumber} value={activityNumber}>{activityNumber}</option> }
  const ActivityOptionMaybe = (activity) => { return activity.makeOrder ? <ActivityOption activityNumber={activity.activityNumber} /> : null }

  return (
    <Select
      align="center"
      name="asParam_internalServiceShipment"
      onChange={onChangeProjectFieldHandler}
      value={project.asParam_internalServiceShipment}
    >
      <option key='empty' value=''/>
      {activities.map(ActivityOptionMaybe)}
    </Select>
  )
}

function ExternalServiceCard({project}){
  return (
    <Fragment>
      <b>Shipping Method to Intermediate:</b> {project.asParam_shipMethodToInt}<br/>
      <br/>
      <b>Intermediate Supplier:</b> {project.asParam_supplier ? getDataTableRecord(INTERMEDIATE_INFO, 'supplierId', project.asParam_supplier).shipCompany : 'Not filled out' }<br/>
      <br/>
      <b>Lead time for Intermediate:</b> {project.asParam_finishingDays}
    </Fragment>

  )
}
