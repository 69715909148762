import React, { useState, useEffect } from "react"
import { InputAdornment } from "@material-ui/core"
import { NumberField } from "./NumberField.js"

export default function({ onChange, partGroup }){
  const [ infillPercentage, setInfillPercentage ] = useState(partGroup.infillPercentage)

  useEffect(() => {
    setInfillPercentage(partGroup.infillPercentage)
  }, [partGroup])

  const onBlur = () => {
    onChange({
      target: {
        name: 'infillPercentage',
        value: infillPercentage,
      }
    })
  }

  const onChangeLocal = e => {
    setInfillPercentage(e.target.value)
  }

  return(
    <NumberField
      InputProps={{
        endAdornment: <InputAdornment children="%" position="end"/>
      }}
      label="Infill %"
      name="infillPercentage"
      onBlur={onBlur}
      onChange={onChangeLocal}
      styles={{width:"3em"}}
      value={infillPercentage}
    />
  )
}
