import React, { Fragment } from 'react'
import { Snackbar } from '@material-ui/core'

export default function withSnackbar(WrappedComponent){
  return class extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        snackbarOpen : false,
        snackbarMessage: ''
      }
    }

    openSnackbarWithMessage = message => {
      this.setState({
        snackbarOpen : true,
        snackbarMessage: message
      })
    }

    closeSnackbar = () => {
      this.setState({
        snackbarOpen : false,
        snackbarMessage: ''
      })
    }

    render(){
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            openSnackbarWithMessage={this.openSnackbarWithMessage}
          />
          <Snackbar
            autoHideDuration={10000}
            open={this.state.snackbarOpen}
            onClose={this.closeSnackbar}
            message={this.state.snackbarMessage}
            style={{marginBottom:"80px"}}
          />
        </Fragment>
      )
    }
  }
}
