import React from "react"
import { NumberField } from "./NumberField.js"

export function PercentageField(props){
  const onChange = (event) => {
    const {value} = event.target
    event.target.value = value/100

    props.onChange(event)
  }

  const percentageValue = 100 * props.value

  return (
    <NumberField
      {...props}
      onChange={onChange}
      value={percentageValue}
    />
  )
}
