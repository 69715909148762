module.exports = {
  appendInfillIfNeeded,
}

function appendInfillIfNeeded(material, processId, infillPercentage){
  if(processId === 'fdm'){
    return `${infillPercentage}% Infill ${material}`
  }

  return material
}
