import React,{Fragment,useState} from 'react'
import {Button,Checkbox,CircularProgress,FormControlLabel,TextField,Typography,withStyles} from '@material-ui/core'
import { Link } from "@reach/router"
import { useToggledBoolean } from '../../utils'
var validator = require("email-validator");

const hasCharacters = /[a-zA-Z]/

const CreateAccount = ({ classes, popupMessage, signUp }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [revealPasswordText, setRevealPassword] = useToggledBoolean(false)
  const [isCurrentlySigningUp, setIsCurrentlySigningUp] = useState('')

  /*
    signUpHandler validates email and password and calls the signUp prop function
  */
  const signUpHandler = async () => {
    if (!validator.validate(email)) {
      popupMessage('Please enter a valid email address')
      return
    }
    if (password.length < 8) {
      popupMessage('Password must be at least 8 characters long')
      return
    }
    if (!hasCharacters.test(firstName)){
      popupMessage('First name must not be blank')
      return
    }
    if (!hasCharacters.test(lastName)){
      popupMessage('Last name must not be blank')
      return
    }

    setIsCurrentlySigningUp(true)
    await signUp(email,password,firstName,lastName)
    setIsCurrentlySigningUp(false)
  }

  const HEADER_PLACEHOLDER = (isCurrentlySigningUp) ? "AUTHENTICATING" : "SIGN UP"
  const HIDE_OR_REVEAL_PASSWORD = (revealPasswordText) ? "text" : "password"
  return(
    <Fragment>
      <div align="center">
        <div className={classes.loadingBox}>
          {isCurrentlySigningUp && <CircularProgress className={classes.progress} color="secondary" size={200}/>}
        </div>

        <div className={classes.loginDiv}>
          <Typography className={classes.paperHeader} color="textPrimary" variant="h4">
            {HEADER_PLACEHOLDER}
          </Typography>
          <TextField
            autoFocus={true}
            className={classes.textField}
            disabled={isCurrentlySigningUp}
            inputProps={{min: "0"}}
            label="Email"
            margin="dense"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){signUpHandler()}
            }}
            type="text"
            value={email||''}
          /><br/>
          <TextField
            className={classes.textField}
            disabled={isCurrentlySigningUp}
            inputProps={{min: "0"}}
            label="Password - minumum 8 characters"
            margin="dense"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){signUpHandler()}
            }}
            type={HIDE_OR_REVEAL_PASSWORD}
            value={password||''}
          /><br/>
          <div className={classes.flexBox}>
            <div className={classes.flexBoxLeft}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={revealPasswordText}
                    onChange={setRevealPassword}
                    value="show password"
                  />
                }
                label="Show Password"
              />
            </div>
          </div><br/>
          <TextField
            className={classes.textField}
            disabled={isCurrentlySigningUp}
            inputProps={{min: "0"}}
            label="First Name"
            margin="dense"
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){signUpHandler()}
            }}
            value={firstName||''}
          /><br/>
          <TextField
            className={classes.textField}
            disabled={isCurrentlySigningUp}
            inputProps={{min: "0"}}
            label="Last Name"
            margin="dense"
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){signUpHandler()}
            }}
            value={lastName||''}
          /><br/>
          <Button
            className={classes.buttonSubmit}
            color="secondary"
            disabled={isCurrentlySigningUp}
            onClick={signUpHandler}
            style={{minWidth:'100px'}}
            variant="contained"
          >
            SIGN UP
          </Button><br/>
          Already have an account? Log in <Link to={`../?${(new URLSearchParams(window.location.search)).toString()}`}>here</Link>.
        </div>
      </div>
    </Fragment>
  )
}

const styles = theme => ({
  buttonSubmit:{
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  flexBox:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexBoxLeft:{
    paddingLeft: theme.spacing(5)
  },
  loadingBox:{
    height:'300px',
  },
  loginDiv: {
    marginBottom: '10%',
    width: '600px',
  },
  paperHeader:{
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign:'center'
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: '520px'
  }
})

export default withStyles(styles)(CreateAccount)
