module.exports = {getNoBidTolerancesString}

function getNoBidTolerancesString(tolerances){
  const noBidTolerances = tolerances
        .filter(t => t.classification === 'noBid')

  const noBidTolerancesString = noBidTolerances.map(t => {
    return `[${t.featureNumber}. +${Number(t.revisedUpperBound).toFixed(4)} -${Number(t.revisedLowerBound).toFixed(4)}]`
  })
        .join(', ')

  return noBidTolerancesString
}
