import React from "react"
import { TextField } from "@material-ui/core"
import { DeleteButtonAtom } from "./partCardAtoms"
export const OvermoldInsertRow = ({ deleteInsertHandler, insert, onChange }) => {
  return(
    <div className="overmold-insert-row-div">
      <div>
        <TextField
          label="# of Inserts per Part"
          name="qtyInsertsPerPart"
          onChange={onChange}
          value={insert.qtyInsertsPerPart}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="# of Inserts per Pack"
          name="qtyInsertsPerPack"
          onChange={onChange}
          value={insert.qtyInsertsPerPack}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Cost per Pack($)"
          name="costPerPack"
          onChange={onChange}
          value={insert.costPerPack}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Volume of Each Insert"
          name="volume"
          onChange={onChange}
          value={insert.volume}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Surface of Each Insert"
          name="surfaceArea"
          onChange={onChange}
          value={insert.surfaceArea}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Insert X"
          name="xDimension"
          onChange={onChange}
          value={insert.xDimension}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Insert Y"
          name="yDimension"
          onChange={onChange}
          value={insert.yDimension}
          variant="outlined"
        />
      </div>
      <div>
        <TextField
          label="Insert Z"
          name="zDimension"
          onChange={onChange}
          value={insert.zDimension}
          variant="outlined"
        />
      </div>
      <div>
        <DeleteButtonAtom deleteHandler={deleteInsertHandler}/>
      </div>
    </div>
  )
}
