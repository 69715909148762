const axios = require('axios')
const { logUnexpected } = require('../../../../utils/logUnexpected.js')
const { retryWithExponentialBackoffFactory } = require('../../../../utils/retryWithExponentialBackoffFactory.js')
const HTTP_ERROR_CODES = require('../../../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../../../utils/throwErrorParsedFromKnownStatusCode.js')

export async function getTemporaryS3DownloadUrl(key) {

  const makeCall = () => getTemporaryS3DownloadUrlInternal(key)
  const retry = retryWithExponentialBackoffFactory(makeCall)
  return makeCall()

  async function getTemporaryS3DownloadUrlInternal(key) {
    let response
    try {
      response = await getTemporaryS3DownloadUrlRequest(key)
    } catch (err) {

      if (err.message === 'Network Error') {
        return retry(err)
      }

      logUnexpected(err, { key }, 'getTemporaryS3DownloadUrlRequest')
      throw err
    }

    let result
    try {
      result = parseGetTemporaryS3DownloadUrlResponse(response)
    } catch(err) {
      logUnexpected(err, { response }, 'parseGetTemporaryS3DownloadUrlResponse', { key })
      throw err
    }

    return result
  }
}

function parseGetTemporaryS3DownloadUrlResponse(response) {
  if (response.status === 200) {
    if (response.data.success && response.data.success.url) {
      return response.data.success.url
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}

async function getTemporaryS3DownloadUrlRequest(key) {
  const response = await axios({
    method: 'get',
    url: `/files/presign/download/${key}`,
    timeout: 10000,
    validateStatus: () => true,
  })

  return { data: response.data, status: response.status }
}
