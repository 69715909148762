import { useEffect, useRef } from "react"

export function useNonMountEffect( callBack, dependencyArray ) {
  const firstUpdate = useRef(true);
  useEffect(()=>{
    if (firstUpdate.current) { // if component is mounting, skip this useEffect block
      firstUpdate.current = false
      return
    }
    callBack()
  }, dependencyArray)
}
