import React from "react"
import {Button,InputAdornment,TextField} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Autocomplete from '@material-ui/lab/Autocomplete'
import CustomerConfigMaybe from './CustomerConfigMaybe.js'
import DmeApprovalCheckbox from '../../DmeApprovalCheckbox.js'
import DmeConfigMaybe from './DmeConfigMaybe.js'
import USER_TYPES from '../../../../utils/USER_TYPES.js'

import { NumberField } from '../../NumberField.js'

export default function PostProcessInputRow({deletePostProcess, postProcessObject, postProcessOptionsFromDb, setSpecificPostProcessingField, user}){
    const postProcessOption = postProcessOptionsFromDb.POST_PROCESS_OPTIONS
        .find(ppo => ppo.id === postProcessObject.postProcessId)

  return (
    <div style={{display: 'flex', gap: '1em', alignItems: 'baseline'}}>
      <InputField
        postProcessOption={postProcessOption}
        POST_PROCESS_OPTIONS={postProcessOptionsFromDb.POST_PROCESS_OPTIONS}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      <CustomerConfigMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      {user.type === USER_TYPES.DME ?
      <>
      <DmeConfigMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      <PercentageOfPartMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      <AverageLengthOfSpecialOpMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      <HardwarePackQtyMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      <HardwareCostPerPackMaybe
        postProcessObject={postProcessObject}
        postProcessOption={postProcessOption}
        setSpecificPostProcessingField={setSpecificPostProcessingField}
      />
      </>
       : null }
      <DeleteIconButton
        deletePostProcess={deletePostProcess}
        postProcessObject={postProcessObject}
      />
      {user.type === USER_TYPES.DME ?
       <DmeApprovalCheckboxDiv
         postProcessObject={postProcessObject}
         setSpecificPostProcessingField={setSpecificPostProcessingField}
       />
       : null }
    </div>
  )
}

function DmeApprovalCheckboxDiv(props){
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <DmeApprovalCheckbox
        isDmeReviewed={props.postProcessObject.isDmeReviewed}
        onChangeCheckbox={e => props.setSpecificPostProcessingField('isDmeReviewed', e.target.checked)}
      />
    </div>
  )
}

export function InputField({postProcessOption, POST_PROCESS_OPTIONS, setSpecificPostProcessingField}){
  return(
    <Autocomplete
      onChange={(_event, newValue) => {
        setSpecificPostProcessingField('postProcessId', newValue.id)
      }}

      /* options config */
      options={
        POST_PROCESS_OPTIONS
          .sort((a,b) => a.display.displayOrder - b.display.displayOrder)
      }
      getOptionLabel={option => option.display.name}
      getOptionSelected={(optionToCompare,selectedOption)=>{
        return optionToCompare.id === selectedOption.id // selected option is passed from 'value' field
      }}
      value={postProcessOption}

      /* This is the config of the each option the user sees in the list */
      renderOption={option =>
        <option value={option.id}>
          { option.display.name }
        </option>
      }

      /* This is the config of the element the user sees */
      renderInput={
        (params) => <TextField {...params} style={{background: 'white'}} size="small" variant="standard" />
      }

      /* Prevents a blank value from being entered */
      disableClearable={true}

      style={{width: '20%'}}
    />
  )}

function PercentageOfPartMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  if(postProcessOption.dmeInputs.dmeConfigOptions.isPercentageOfPart){
    return (
      <NumberField
        className="dmeInput"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        label='Percentage of Part'
        name='percentageOfPart'
        onChange={e => setSpecificPostProcessingField('percentageOfPart', e.target.value)}
        styles={{width:'8em'}}
        value={postProcessObject.percentageOfPart}
        size='small'
        variant='filled'
      />
    )
  } else {
    return null
  }
}

function AverageLengthOfSpecialOpMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  if(postProcessOption.dmeInputs.dmeConfigOptions.isAverageLengthOfSpecialOp){
    return (
      <NumberField
        className="dmeInput"
        label='Average Length Of Special Op'
        name='averageLengthOfSpecialOp'
        onChange={e => setSpecificPostProcessingField('averageLengthOfSpecialOp', e.target.value)}
        styles={{width:'13em'}}
        value={postProcessObject.averageLengthOfSpecialOp}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        size='small'
        variant='filled'
      />
    )
  } else {
    return null
  }
}

function HardwarePackQtyMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  if(postProcessOption.dmeInputs.dmeConfigOptions.isHardwarePackQty){
    return (
      <NumberField
        className="dmeInput"
        label='Hardware Pack Qty'
        name='hardwarePackQty'
        onChange={e => {
          const integer = e.target.value.replace(/[^0-9]/g, "") // ensure this number is an integer
          setSpecificPostProcessingField('hardwarePackQty', integer)
        }}
        styles={{width:'9em'}}
        value={postProcessObject.hardwarePackQty}
        size='small'
        variant='filled'
      />
    )
  } else {
    return null
  }
}

function HardwareCostPerPackMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  if(postProcessOption.dmeInputs.dmeConfigOptions.isHardwareCostPerPack){
    return (
      <NumberField
        className="dmeInput"
        label='Hardware Cost Per Pack'
        name='hardwareCostPerPack'
        onChange={e => setSpecificPostProcessingField('hardwareCostPerPack', e.target.value)}
        styles={{width:'11em'}}
        value={postProcessObject.hardwareCostPerPack}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        size='small'
        variant='filled'
      />
    )
  } else {
    return null
  }
}

function DeleteIconButton({deletePostProcess, postProcessObject}){
  return(
    <Button
      onClick={() => deletePostProcess(postProcessObject.id)}
      style={{minWidth: '0px'}}
    >
      <DeleteForeverIcon />
    </Button>
  )
}
