import { generateActivityNumbers } from './generateActivityNumbers'
import { TO_FROM_TYPES } from './supplierTypeArrays.js'

export function generateActivities(project) {
    let activities = makeActivities(project)
    activities = generateActivityNumbers(activities, project)
    return activities
}

function makeActivities(project) {
    let makeOrders = project.makeOrders.sort((a,b) => a.sortOrder - b.sortOrder)
    let shipments  =  project.shipments.sort((a,b) => a.sortOrder - b.sortOrder)
    const makeOrderCount = makeOrders.length
    let nextActivityIndex = makeOrderCount
    let activities = []

    activities = makeOrders.map((mo) => { // Iterate through make orders, creating activities
      const activity = makeOrderToNewActivity(mo)
      activity.partBatches = getPartBatchesMatchingPartGroups(activity.partBatches, mo.ptpcPartBatches, project, 'makeOrder')

      return activity
    })
    //Loop through each shipment object and compare to existing activities
    for (var s = 0; s < shipments.length; s++) {
        //For each shipment [i], compare the associated make order with the make orders object
        let shipment = shipments[s]
        //Do this by looping through each make order and compare. If one matches, paste there. If none matches, paste afterwards
        let added = false
        for (var a = 0; a < activities.length; a++) {
            let existingMatch = 0
            if (shipment.makeOrder.length && activities[a].makeOrder) {
                if (shipment.makeOrder[0].id === activities[a].makeOrder.id) {
                    existingMatch = 1
                }
            }
            let newMatch = 0
            let makeOrder = activities[a].makeOrder
            if (makeOrder) {
                if (shipment.tempActivityNumber === makeOrder.tempActivityNumber && shipment.tempActivityNumber !== undefined) {
                    newMatch = 1
                }
            }
            if (existingMatch || newMatch) {
                activities = addShipmentToActivities(activities, a, project, s)
                activities[a].partBatches = decorateLineItemsWithShipmentIds(activities[a].partBatches, shipment.ptpcPartBatches)

                added = true
            }
        }
        if (!added) { //If no matching make order is found add new shipment?
            activities = addShipmentToActivities(activities, nextActivityIndex, project, s)
            if (shipment.partBatches && shipment.partBatches.length) {
              activities[a].partBatches = shipment.partBatches
            } else {
              activities[a].partBatches = []
            }

            activities[a].partBatches = getPartBatchesMatchingPartGroups(activities[a].partBatches, shipment.ptpcPartBatches, project, 'shipment')

            nextActivityIndex += 1
        }
    }
    return activities
}

function makeOrderToNewActivity(makeOrder) {
    let newActivity = {}

    newActivity.makeOrder = makeOrder
    newActivity.makeOrder.reprintBool = makeOrder.reprintBool ? makeOrder.reprintBool : false
    newActivity.makeOrder.purchaseOrderDate = makeOrder.purchaseOrderDate ? makeOrder.purchaseOrderDate : '' //Provide default for purchaseOrderDate
    newActivity.makeOrder.leadTime = makeOrder.leadTime ? makeOrder.leadTime : '' //Provide default for leadTime
    newActivity.makeOrder.moSupplierNotes = makeOrder.moSupplierNotes ? makeOrder.moSupplierNotes : '' //Add default for moSupplierNotes
    newActivity.makeOrder.moOurNotes = makeOrder.moOurNotes ? makeOrder.moOurNotes : ''
    newActivity.activityNumber = makeOrder.makeOrderNumber
    newActivity.activityFrom = makeOrder.makeOrderFrom ? makeOrder.makeOrderFrom : ''
    newActivity.activityTo = TO_FROM_TYPES.heldAtSupplier
    newActivity.supplier = makeOrder.supplier ? makeOrder.supplier : [{ identifier: '', id: '' }] //Adding a default supplier if none exists

    if (makeOrder.partBatches && makeOrder.partBatches.length) {
        newActivity.partBatches = makeOrder.partBatches
    }
    else {
        newActivity.partBatches = []
    }

    return newActivity
}

function addShipmentToActivities(activities, activityIndex, project, shipmentIndex) {
    let newActivity = {}
    if (!activities[activityIndex]) { //If this activity doesn't exist yet, create it
        activities[activityIndex] = newActivity
    }


    let shipment = project.shipments[shipmentIndex]
    activities[activityIndex].shipment = shipment
    activities[activityIndex].shipment.paidShipDate = shipment.paidShipDate ? shipment.paidShipDate : '' //If paid ship date exists use it, else apply empty string
    activities[activityIndex].activityNumber = shipment.shipmentNumber
    activities[activityIndex].activityFrom = shipment.shipmentFrom ? shipment.shipmentFrom : '' //If shipmentFrom value exists
    activities[activityIndex].activityTo = shipment.shipmentTo
    activities[activityIndex].supplier = shipment.supplier ? shipment.supplier : [{ identifier: '', id: '' }]

    return activities
}

function getPartBatchesMatchingPartGroups(partBatches, ptpcPartBatches, project, ptpcType){
  return project.partGroups
    .flatMap(partGroupToPartBatches)

  function partGroupToPartBatches(partGroup){
    const partBatchMaybe = partBatches.find(
      partBatch => partBatch.partGroup === partGroup.partGroupNumber
    )

    const newPartBatch = {
      partGroup: partGroup.partGroupNumber,
      quantity: "",
      customUnitPrice: "",
    }

    const partBatch = partBatchMaybe ? partBatchMaybe : newPartBatch

    return [partBatch, ...getPtpcPartBatchesFromPartGroup(partGroup, ptpcPartBatches, ptpcType)]
  }
}

function decorateLineItemsWithShipmentIds(lineItems, ptpcPartBatches){
  return lineItems
    .map(lineItem => {
      if(lineItem.productionToolPartConfigurationId){
        const ptpcPartBatch = ptpcPartBatches
              .find(ptpcPartBatch => ptpcPartBatch.productionToolPartConfigurationId === lineItem.productionToolPartConfigurationId)
        if(ptpcPartBatch){
          lineItem.shipmentId = ptpcPartBatch.id
        }
      }
      return lineItem
    })
}

function getPtpcPartBatchesFromPartGroup(partGroup, ptpcPartBatches, ptpcType){
  return partGroup.productionToolPartConfigurations
    .map(ptpc => {
      const partBatchMaybe = ptpcPartBatches.find(
        ptpcPartBatch => ptpcPartBatch.productionToolPartConfigurationId === ptpc.productionToolPartConfigurationId
      )

      if(partBatchMaybe){
        if(ptpcType === 'makeOrder'){
          partBatchMaybe.makeOrderId = partBatchMaybe.id
        }
        if(ptpcType === 'shipment'){
          partBatchMaybe.shipmentId = partBatchMaybe.id
        }

        return partBatchMaybe
      }

      const newPartBatch = {
        productionToolPartConfigurationId: ptpc.productionToolPartConfigurationId,
        quantity: "",
        customUnitPrice: "",
      }

      return newPartBatch
    })
}
