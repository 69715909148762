import React, { useContext, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { CloudCircle } from '@material-ui/icons'
import { NumberField } from "../QuoteTool/NumberField.js"
import {SnackBarContext} from '../Common/SnackBarStore'

export const LoadProjectForm = ({ navigateToProject, openCreateProjectModal }) =>{
  const [ projectNumber, setProjectNumber ] = useState()
  const openSnackBarWithMessage = useContext(SnackBarContext)

  function navigateToProjectHandler(){
    if(projectNumber){
      navigateToProject(projectNumber)
    }
    else{
      openSnackBarWithMessage("Enter a project number")
    }
  }

  return(
    <div style={{ width:"100%", display: "flex", justifyContent: "center" }}>
      <div className="load-project-container">
        <CloudIcon/>

        <Typography align="center" variant="h4">
          Load a Project
        </Typography>

        <NumberField
          label="Project to Load"
          onChange={ e => setProjectNumber(e.target.value) }
          onKeyPress={(ev) => {
            if (ev.key === 'Enter'){ navigateToProjectHandler() }
          }}
          value={ projectNumber }
        />

        <div style={{ display:"flex", justifyContent:"space-between" }}>
          <Button
            color="primary"
            onClick={ openCreateProjectModal }
            size="large"
            variant="contained"
          >
            CREATE PROJECT
          </Button>

          <Button
            color="secondary"
            onClick={ navigateToProjectHandler }
            variant="contained"
          >
            LOAD
          </Button>
        </div>
      </div>
    </div>
  )
}

const CloudIcon = () => {
  return(
    <div style={{ display: "flex", justifyContent:"center", paddingTop:"1em" }}>
      <CloudCircle color="primary" fontSize="large"/>
    </div>
  )
}
