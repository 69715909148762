module.exports = {
  postProcessesAsString,
}

function postProcessesAsString(postProcesses, POST_PROCESS_OPTIONS){
  const getDisplayValueFromId = (postProcess) => {
    const postProcessOption = POST_PROCESS_OPTIONS
      .find(ppo => ppo.id === postProcess.postProcessId)

    const customerConfig = postProcessOption.customerInput
    const dmeConfig = postProcessOption.dmeInputs.dmeConfig

    let string = postProcessOption.display.name

    if(customerConfig.customerConfigurationPreamble){
      string += ` ${customerConfig.customerConfigurationPreamble}`
    }
    if(customerConfig.isDisplayedCustomerConfiguration){
      string += ` ${postProcess.customerConfig}`
    }
    if(dmeConfig.dmeConfigurationPreamble){
      string += ` ${dmeConfig.dmeConfigurationPreamble}`
    }
    if(dmeConfig.isDisplayedDmeConfiguration){
      string += ` ${postProcess.dmeConfig}`
    }

    return string
  }

  if(postProcesses.length === 0){
    return "Standard"
  }

  return postProcesses
    .map(getDisplayValueFromId)
    .join(', ')
}
