import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export default async function createProductionToolPartConfigurationAttachment(attachment) {
  let response
  try {
    response = await createProductionToolPartConfigurationAttachmentRequest(attachment)
  } catch (err) {
    logUnexpected(err, { attachment } , 'createProductionToolPartConfigurationAttachmentRequest')
    throw err
  }

  let result
  try {
    result = parseCreateProductionToolPartConfigurationAttachmentResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseCreateProductionToolPartConfigurationAttachmentResponse', { attachment })
    throw err
  }

  return result
}

function createProductionToolPartConfigurationAttachmentRequest(attachment) {
  console.log({
    attachment,
    token: config.MICROSERVICE_TOKEN,
    CREATE_PTPC_ATTACHMENT_ENDPOINT: config.CREATE_PTPC_ATTACHMENT_ENDPOINT,
  })
  const payload = {
    attachment,
    token: config.MICROSERVICE_TOKEN,
  }

  return axios({
    method: 'POST',
    url: config.CREATE_PTPC_ATTACHMENT_ENDPOINT,
    timeout: 10000,
    validateStatus: () => true,
    data: payload,
  })
    .then( rawResponse => ({ data: rawResponse.data, status: rawResponse.status}))
}

function parseCreateProductionToolPartConfigurationAttachmentResponse(response) {
  if ( response.status === 200) {
    if(response.data && response.data.attachment){
      return response.data.attachment
    } else {
      const unexpectedDataShapeError = new Error('Unexpected data shape')
      unexpectedDataShapeError.response = response
      throw unexpectedDataShapeError
    }
  } else {
    const unexpectedStatusCodeError = new Error(`Unexpected status code: ${response.status}`)
    unexpectedStatusCodeError.response = response
    throw unexpectedStatusCodeError
  }
}
