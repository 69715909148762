const axios = require('axios')
const config = require('../../../config.js')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function addOrderToShipstation(order){
  let response
  try{
    response = await addOrderToShipstationRequest(order)
  } catch (err){
    logUnexpected(err, { order }, 'addOrderToShipstationRequest')
    throw err
  }

  let result
  try{
    result = parseAddOrderToShipstationResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseAddOrderToShipstationResponse', { order })
    throw err
  }

  return result
}

function addOrderToShipstationRequest(order){
  return axios({
    auth: {
      username: config.SHIPSTATION_KEY,
      password: config.SHIPSTATION_SECRET,
    },
    method: 'POST',
    validateStatus: () => true,
    url: 'https://ssapi.shipstation.com/orders/createorder',
    data: order,
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseAddOrderToShipstationResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.orderId){
      return response.data
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
