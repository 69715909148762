import { partAnalysisStatus } from '../utils'

/*
  validateProjectForCheckout iterates through the rows in the part table and checks that required fields are filled
  if they are the checkout button is enabled, if not it is disabled
*/
export function validateProjectForCheckout(project, selectedLeadTime, reviewReasons, postProcessData) {
  const PROJECT_REASONS_EXIST = reviewReasons ? (reviewReasons.projectReasons ? (reviewReasons.projectReasons.length ? true : false) : false) : false
  const PART_REASONS_EXIST = reviewReasons ? (reviewReasons.partsReasons ? (reviewReasons.partsReasons.length ? true : false) : false) : false
  const MANUAL_REVIEW_REQUIRED = (PROJECT_REASONS_EXIST || PART_REASONS_EXIST) ? true : false
  let PROJECT_DATA_VALID = false

  if (MANUAL_REVIEW_REQUIRED) {
    PROJECT_DATA_VALID = project.leadTimeRequest ? true : false
  }
  else if (project.isLeadTimeLocked){
    PROJECT_DATA_VALID = project.lockedProductionLeadTimeDays > 0
  }
  else {
    PROJECT_DATA_VALID = selectedLeadTime > 0
  }

  const PART_GROUP_DATA_VALID = project.partGroups.every(partGroup => {
    return validatePartGroup(partGroup, postProcessData)
  }) && project.partGroups.length
  let validData = PROJECT_DATA_VALID && PART_GROUP_DATA_VALID
  let invalidData = !validData
  return invalidData
}

/*
 validatePartGroup takes a partGroup object as a parameter and checks the required fields
 It returns a boolean
*/
function validatePartGroup(partGroup, postProcessData) {
    const {
        CAN_BE_ANALYZED,
        PARAM_EXTRACT_FINISHED,
        HAS_MANUFACTURABILITY_ISSUES,
        WALL_THICKNESS_TASK_FINISHED,
        HAS_THIN_WALLS,
        HAD_ISSUES_ANALYZING_PARAMS,
    } = partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)

    let partGroupDataIsValid = true
    // Make sure all required fields are filled out
    partGroupDataIsValid = partGroup.processId ? partGroupDataIsValid : false
    partGroupDataIsValid = partGroup.quantity ? partGroupDataIsValid : false
    partGroupDataIsValid = partGroup.finishId ? partGroupDataIsValid : false

  const materialToCheck = ['injMoldingPart','casting'].includes(partGroup.processId) ? partGroup.materialIdProductionToolIsDesignedFor : partGroup.materialId
    partGroupDataIsValid = materialToCheck ? partGroupDataIsValid : false

    // If a part can be analyzed...
    if (CAN_BE_ANALYZED && !HAD_ISSUES_ANALYZING_PARAMS) {
        // Parts that can be analyzed should have units selected
        partGroupDataIsValid = partGroup.part.units ? partGroupDataIsValid : false

        // ...make sure all analyses have finished
        const mfgAnalysisDone = (PARAM_EXTRACT_FINISHED && WALL_THICKNESS_TASK_FINISHED)
        partGroupDataIsValid = mfgAnalysisDone ? partGroupDataIsValid : false
        
        // If part is at risk, make sure risk has been accepted
        const hasManufacturabilityIssues = HAS_MANUFACTURABILITY_ISSUES || HAS_THIN_WALLS
        const riskNotAccepted = !partGroup.part.riskAccepted
        const atRiskNotApproved = hasManufacturabilityIssues && riskNotAccepted
        partGroupDataIsValid = atRiskNotApproved ? false : partGroupDataIsValid
    }
    return partGroupDataIsValid
}
