import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { CustomerSelectAutoComplete } from '../HomePage/CustomerSelectAutocomplete.js'

export default function ChangeCustomerButton({updateCustomer}){
  const [customer, setCustomer] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setCustomer('')
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setCustomer('')
    setIsModalOpen(false)
  }

  const updateCustomerHandler = () => {
    updateCustomer(customer)
    setIsModalOpen(false)
  }

  return(
    <div>
      <Button
        color="primary"
        onClick={openModal}
        size="small"
        variant="contained"
      >
        Change Customer
      </Button>
      <ChangeCustomerModal
        closeModal={closeModal}
        customer={customer}
        open={isModalOpen}
        setCustomer={setCustomer}
        updateCustomerHandler={updateCustomerHandler}
      />
    </div>
  )
}

function ChangeCustomerModal({
  closeModal,
  customer,
  open,
  setCustomer,
  updateCustomerHandler,
}){
  return(
    <Modal
      open={open}
      onClose={closeModal}
    >
      <div
        style={{
          position: 'absolute', /* DXI: necessary for modal to be in true middle */
          left: '25vw',
          width: '50vw',
          top: '20vh',
          height: '60vh',
        }}
      >
        <Paper style={{ display: 'flex', flexDirection: 'column', gap: '2em', padding: '2em 3em' }}>
          <Typography align="center" variant="h4">
            Update Customer
          </Typography>

          <CustomerSelectAutoComplete
            customer={customer}
            setCustomer={setCustomer}
          />

          <div style={{ display:'flex', gap: '6em', justifyContent: 'center', padding: '1em' }}>
            <Button color="default" onClick={closeModal} style={{width:"8em"}} variant="contained">
              Cancel
            </Button>
            <Button color="secondary" disabled={!customer} onClick={updateCustomerHandler} style={{width:"8em"}} variant="contained">
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </Modal>
  )
}

