const axios = require('axios')
const config = require('../config.js')

export default async function downloadInternalFilesAsZip(internalFiles){
  const response = await downloadInternalFilesAsZipRequest(internalFiles)
  const result = await parseDownloadInternalFilesAsZip(response)
  return result
}

async function downloadInternalFilesAsZipRequest(internalFiles){
  return axios({
    responseType: 'blob',
    method: 'POST',
    validateStatus: () => true,
    data: {
      internalFiles,
      token: config.MICROSERVICE_TOKEN,
    },
    url: config.DOWNLOAD_INTERNAL_FILES_ENDPOINT,
  })
}

async function parseDownloadInternalFilesAsZip(response){
  if(response.status === 400){
    try{
      const errorMessage = await response.data.text()
      throw new Error(`Error parsing internal files zip response: ${errorMessage}`)
    } catch(e) {
      throw new Error(`Error parsing internal files zip response`)
    }
  }

  return response.data
}
