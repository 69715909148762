import React from "react"
import DeleteIcon from "@material-ui/icons/Delete"
import { IconButton, makeStyles } from "@material-ui/core"
import { CustomTooltip } from "."

export const DeleteButtonAtom = ({ deleteHandler, tooltipTitle }) => {
  const iconHover = makeStyles( theme => ({
    iconHover: {
      "&:hover": {
        color: theme.palette.primary.dark,
      }
    }
  }))().iconHover

  return (
    <CustomTooltip enterDelay={400} title={tooltipTitle ? tooltipTitle : "Delete"}>
      <IconButton tabIndex="-1" color="primary" className={iconHover} onClick={deleteHandler}>
        <DeleteIcon/>
      </IconButton>
    </CustomTooltip>
  )
}
