import axios from 'axios'
import config from '../../config.js'
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function remainingProductionToolLife(partGroup){
  let response
  try {
    response = await remainingProductionToolLifeRequest(partGroup)
  } catch (err) {
    logUnexpected(err, null, partGroup, 'remainingProductionToolLifeRequest')
    throw err
  }

  let result
  try {
    result = parseRemainingProductionToolLifeResponse(response)
  } catch (err) {
    logUnexpected(err, partGroup, response, 'parseRemainingProductionToolLifeResponse')
    throw err
  }

  return result
}

async function remainingProductionToolLifeRequest(partGroup) {
  const token = config.MICROSERVICE_TOKEN
  const response = await axios({
    method: 'post',
    validateStatus: () => true,
    url: config.GET_PURCHASED_TOOL_DATA_ENDPOINT,
    data: { partGroup, token },
  })

  return { data: response.data, status: response.status }
}

function parseRemainingProductionToolLifeResponse(response) {
  if (response.status === 200){
    if(response.data.statusCode === '200' && response.data.body){
      return response.data.body
    }
    else {
      const unexpectedDataShapeError = Error('Unexpected data shape')
      unexpectedDataShapeError.data = response.data
      throw unexpectedDataShapeError
    }
  } else {
    throw Error(`Unexpected status code: ${response.status}`)
  }
}
