import getManufacturabilityIssues from '../shared/getManufacturabilityIssues.js'

export function updateProjectWithAtRiskBool(project, POST_PROCESS_OPTIONS) {
  project.partGroups = project.partGroups.map(ptg => updatePartGroupWithAtRiskBool(ptg, POST_PROCESS_OPTIONS))
  return project
}

function updatePartGroupWithAtRiskBool(partGroup, POST_PROCESS_OPTIONS){
  const manufacturabilityIssues = getManufacturabilityIssues(partGroup, POST_PROCESS_OPTIONS)
  partGroup.part.atRiskPartBool = Boolean(manufacturabilityIssues.length)
  return partGroup
}
