import React,{Fragment} from 'react'
import {formatPrice} from '../../utils'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { 
  Tabs, 
  Tab, 
} from '@material-ui/core' // stuff for doing Tabs
import splitPartsList from './splitPartsList.js'

const PROJECT_TABLE_COLUMNS = [
  'Quote #',
  'Project Status',
  'Date Created',
  'Process',
  'Parts List',
  'Subtotal',
]

export default function ReviewTableWithTabs(props) {
  const { navigateToProject, projects, ...other } = props
  const [value, setValue] = React.useState(0);

  const handleChange = (_event, newValue) => setValue(newValue)

  return (
    <Paper elevation={10} className="projectReview" {...other}>
      <Tabs className="tabsBar" value={value} onChange={handleChange}>
        <Tab label="All projects"/>
        <Tab label="Quotes"/>
        <Tab label="Orders"/>
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProjectTableMaybe
          navigateToProject={navigateToProject}
          projectsList={projects}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProjectTableMaybe
          navigateToProject={navigateToProject}
          projectsList={projects.filter(project=>project.projectStatus !== "Building")}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProjectTableMaybe
          navigateToProject={navigateToProject}
          projectsList={projects.filter(project=>project.projectStatus === "Building")}
        />
      </TabPanel>
    </Paper>
  )
}

const ProjectTableMaybe = ({ 
  navigateToProject,
  projectsList,
}) => (
  projectsList.length > 0 ?
  <>
    <ProjectTablePaper
      navigateToProject={navigateToProject}
      projectsList={projectsList}
    />
  </> : null
)

const ProjectTablePaper = ({ navigateToProject, projectsList }) => {
  return(
    <Table>
      <ProjectTableColumnsTableHead className="projectReviewTableHeader" />
      <ProjectTableBody
        navigateToProject={navigateToProject}
        projectsList={projectsList}
      />
    </Table>
  )
}


const ProjectTableColumnsTableHead = (props) => {
  const { ...other } = props

  const textAlignmentRule = {
    'Subtotal': "price-text",
    'Parts List': "list-text",
  }
  return(
    <TableHead { ...other }>
      <TableRow>
        { PROJECT_TABLE_COLUMNS.map( 
          column => (
            <TableCell className={textAlignmentRule[column]} key={column}>
              {column}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

const ProjectTableBody = ({ navigateToProject, projectsList }) => {
  return(
    <TableBody className="projectReviewTableBody">
      {projectsList.map((project,index) =>{
        return(
          <ProjectTableRow
            key={String(project.projectNumber)}
            navigateToProject={navigateToProject}
            project={project}
          />
      )})}
    </TableBody>
  )
}

const ProjectTableRow = ({ navigateToProject , project }) => {
  return(
    <TableRow>
      <TableCell>
        <Button 
          onClick={()=>{navigateToProject(project.projectNumber)}}
          color="primary"
          size="medium"
          style={{ border: "1px solid"}} /* DXI: looks good, 2px is too much, 0.5px too little */
          variant="outlined" 
        >
          {project.projectNumber}
        </Button>
      </TableCell>
      <TableCell>
        {project.projectStatus === "Building" ? "Received by Autotiv" : project.projectStatus}
      </TableCell>
      <TableCell>
        {project.dateCreated}
      </TableCell>
      <TableCell>
        {project.process}
      </TableCell>
      <PartsListTableCell
        project={project}
      />
      <TableCell className="price-text">
        {formatPrice(project.totalSale)}
      </TableCell>
    </TableRow>
  )
}

const PartsListTableCell = (props) => {
  const { project } = props
  return(
    <TableCell className="list-text">
      { splitPartsList(project.partsListForQb).map((partRow,index)=>{
        return(
          <Fragment key={String(index) + String(partRow)}>
            {partRow}<br/>
          </Fragment>)
      })}
    </TableCell>
  )
}

// Helper for configuring the "hidden" property in conjunction with MUI's tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

ReviewTableWithTabs.exampleProps = 
{
  "projects": [
    {
      "projectNumber": 39933,
      "dateCreated": "04/30/2021",
      "partsListForQb": "7 x White Nylon 12: P_XXX_0306_01.3mf<br />1 x White Nylon 12: tiny_shell.stl<br />1 x White Nylon 12: step.step<br />1 x White Nylon 12: solidworks.sldprt<br />1 x White Nylon 12: parasolid.x_t<br />1 x White Nylon 12: wallthickness.stl",
      "process": "SLS",
      "projectStatus": "Quoting",
      "totalSale": "3284.58"
    },
    {
      "projectNumber": 39932,
      "dateCreated": "04/30/2021",
      "partsListForQb": "",
      "process": "",
      "projectStatus": "Quoting",
      "totalSale": ""
    },
    {
      "projectNumber": 39828,
      "dateCreated": "04/28/2021",
      "partsListForQb": "1 x White Nylon 12: P_XXX_0306_01.3mf<br />1 x White Nylon 12: tiny_shell.stl<br />1 x White Nylon 12: step.step<br />1 x White Nylon 12: solidworks.sldprt<br />1 x White Nylon 12: parasolid.x_t<br />1 x White Nylon 12: wallthickness.stl",
      "process": "SLS",
      "projectStatus": "Quoting",
      "totalSale": "2954.58"
    },
    {
      "projectNumber": 39795,
      "dateCreated": "04/27/2021",
      "partsListForQb": "1 x ABS - White: step.step<br />1 x ABS - White: solidworks.sldprt<br />1 x ABS - White: parasolid.x_t<br />1 x ABS - White: wallthickness.stl",
      "process": "",
      "projectStatus": "Quoting",
      "totalSale": ""
    },
    {
      "projectNumber": 32169,
      "dateCreated": "06/11/2020",
      "partsListForQb": "7 x (High Res) White Tough and Durable: 1-10x-triangle_slope_for_deposition_1.5mm-converted.stl",
      "process": "SLA",
      "projectStatus": "Quoting",
      "totalSale": "120.40"
    }
  ],
  "navigateToProject": (...args) =>console.log("navigateToProject(", args.reduce( (a, i) => a + "," + i ), ")")
}
