import React,{Fragment,useContext,useEffect,useState} from 'react'
import {Divider,Button,Tab,Tabs,Tooltip,Typography} from '@material-ui/core'
import {determinePartRowTypeToReturn} from '../determinePartRowTypeToReturn.js'
import {needsManufacturabilityIssuesApproved} from '../../../utils/needsManufacturabilityIssuesApproved.js'
import {partAnalysisStatus} from '../../../utils'
import PartDetailsMaybe from './PartDetailsMaybe.js'
import makeNewPartViewer from './partGroupViewerInstance.js'
import {SnackBarContext} from '../../Common/SnackBarStore'

function PartViewer(props){
  const [currentMaterialType,setCurrentMaterialType] = useState("")
  const [modelViewingMode,setModelViewingMode] = useState("")
  const [modelViewer, setModelViewer] = useState(null)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  let el

  useEffect(()=>{
    const modelViewerProm = makeNewPartViewer(el,props.partGroup,props.postProcessData)
      .then(({ defaultMaterial, defaultMode, newPartViewer }) => {
        setCurrentMaterialType(defaultMaterial)
        setModelViewingMode(defaultMode)
        setModelViewer(newPartViewer)
        return newPartViewer
      })

    const cleanUp = async () => {
      const modelViewer = await modelViewerProm
      modelViewer.cleanUp()
    }

    return cleanUp
  },['ONMOUNT'])

  /*
    changeViewingMode switches the rendering of the 3D model between wireframe, solid, and x-ray
    The material-ui tabs return a numeric value to identify which has been selected (0-2)
  */
  function changeViewingMode(viewingModeIndex){
    modelViewer.changeViewingMode(viewingModeIndex)
    setModelViewingMode(viewingModeIndex)
  }

  async function changeMaterial(materialType){
    try{
      const viewingModeIndex = await modelViewer.changeMaterialType(materialType)
      // must re-set the viewing mode again, as changeMaterialType will set it to solid by default.
      modelViewer.changeViewingMode(modelViewingMode)
      setCurrentMaterialType(materialType)
    } catch(e){
      openSnackBarWithMessage(`This part is too complex (${e.message}) to show the arrows view. We can still manufacture this part if you accept the risks! Please email contact@autotiv.com if you need further assistance.`)
    }
  }

  const status = partAnalysisStatus(props.partGroup, props.postProcessData.POST_PROCESS_OPTIONS)
  const ViewingModeTab = ({label}) => <Tab key={label} label={label}/>
  return(
    <Fragment>
      <Tabs
        onChange={(_event, tabIndex) => changeViewingMode(tabIndex)}
        value={modelViewingMode}
      >
        { modelViewer ? modelViewer.viewingModes.map(ViewingModeTab) : null }
      </Tabs>
      <div className="model-viewer-and-material-list-tabs">
        <div style={{display:'flex',flexDirection:"column",gap:"1em",padding:"1em",minWidth:"20vw"}}>
          <Typography variant="h5">
            Model Views
          </Typography>
            <ManyListItems
              changeMaterial={changeMaterial}
              currentMaterialType={currentMaterialType}
              listItemsData={modelViewer ? modelViewer.listItemsData : []}
            />
          <Divider/>
          <PartDetailsMaybe
            onChange={props.onChange}
            partGroup={props.partGroup}
            postProcessData={props.postProcessData}
            riskAccepted={props.partGroup.part.riskAccepted}
            rowType={determinePartRowTypeToReturn(needsManufacturabilityIssuesApproved(status), status, props.partGroup.part)}
          />
        </div>
        {/*
           This div is used as a reference for the modal viewer resizing eventListener in componentWillUnmount
           This is something to do with Three.js, perhaps you could get inner window width from Document.window
           or maybe even react-matches
         */}
        <div
          className="model-viewer"
          ref={ref => el = ref}
        >
          {modelViewer ? null : <div>LOADING...</div> }
        </div>
      </div>
    </Fragment>
  )
}

export default PartViewer

function ManyListItems({
  currentMaterialType,
  changeMaterial,
  listItemsData,
}){
  return listItemsData.map(props =>
    <MaybeListItem
      {...props}
      changeMaterial={changeMaterial}
      currentMaterialType={currentMaterialType}
      key={`ListItem-${props.label}`}
    />
  )
}

const MaybeListItem = ({ content, contentStyle, isDisplayed, materialType, label, changeMaterial, currentMaterialType }) => {
  const secondary = !content ? null :
    <span style={contentStyle ? contentStyle : null}>
      {content}
    </span>

  const selected = currentMaterialType === materialType
  const onClick = () => {
    if(!selected){
      changeMaterial(materialType)
    }
  }
  const ButtonThunk = () =>
        <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
          <Button
            color={selected?'secondary':'primary'}
            disabled={selected}
            onClick={onClick}
            variant="contained"
          >
            {label}
          </Button>
          <Typography>
            {secondary}
          </Typography>
        </div>

  const ButtonMaybeTooltip = () => selected ? <Tooltip enterDelay={1000} enterNextDelay={1500} placement="left" title={'Selected'}>{ButtonThunk()}</Tooltip> : ButtonThunk()

  return !isDisplayed ? null : ButtonMaybeTooltip()
}
