import React, { Fragment } from "react"
import { CircularProgress } from "@material-ui/core"
import { FormattedPriceText } from "./partCardAtoms"
import ManualRfqTextWithTooltip from './ManualRfqTextWithTooltip.js'

const PriceAndManualRfqOrLoadingIndicatorAtom = ({ isCalculatingPrices, isCondensed, partGroup, partGroupPrices, partReasons }) => {
  let unitPrice
  const partGroupPriceMaybe = partGroupPrices && partGroupPrices.find(ptg => ptg.partGroupNumber === partGroup.partGroupNumber)
  if(partGroupPriceMaybe){
    unitPrice = partGroupPriceMaybe ? partGroupPriceMaybe.unitPrice : 0 // to accomodate for parts that are new and dont have prices yet
  } else {
    isCalculatingPrices = true
  }
  return (
    <div className="price-and-manual-rfq-or-loading">
      {isCalculatingPrices ?
       <CircularProgress style={{textAlign: 'center', size: isCondensed ? '0.9em' : ''}}/>
       :
       <PriceAndManualRfqReasonsFragment
         partGroup={partGroup}
         partReasons={partReasons}
         unitPrice={unitPrice}
       />
      }
    </div>
  )
}

const PriceAndManualRfqReasonsFragment = ({ partReasons, partGroup, unitPrice }) => (
  partReasons.length > 0 ?
  ( <ManualRfqTextAndIconWithInformativeTooltip partGroup={partGroup} partReasons={partReasons}/> )
  :
  ( <FormattedPriceText unitPrice={unitPrice} quantity={partGroup.quantity}/> )
)

const ManualRfqTextAndIconWithInformativeTooltip = ({ partGroup, partReasons }) => (
  <Fragment>
    <div align="center">
      -- ea.<br/>
    </div>
    <ManualRfqTextWithTooltip
      partGroup={partGroup}
    />
  </Fragment>
)

export default PriceAndManualRfqOrLoadingIndicatorAtom
