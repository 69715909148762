import { downloadFileFromS3 } from './cloudObjectStorage/downloadFileFromS3'
import { partAnalysisStatus } from '../../utils'

export async function downloadWallThicknessFiles(partGroup, postProcessData) {
  const { objKey, mtlKey } = getFileKeysToDownload(partGroup, postProcessData)

  const objFile = await downloadFileFromS3(objKey)

  let mtlFile
  if (mtlKey) {
    mtlFile = await downloadFileFromS3(mtlKey)
  } else {
    mtlFile = null
  }

  return { objFile, mtlFile }
}

function getFileKeysToDownload(partGroup, postProcessData) {
  const part = partGroup.part
  let objKey, mtlKey

  const {NEEDS_WALL_THICKNESS,WALL_THICKNESS_TASK_FINISHED} = partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)
  if (NEEDS_WALL_THICKNESS && WALL_THICKNESS_TASK_FINISHED) {

    if (part.units === 'mm') {
      if (part.wallThicknessOBJFileMM && part.wallThicknessMaterialFileMM) {
        objKey = part.wallThicknessOBJFileMM
        mtlKey = part.wallThicknessMaterialFileMM
      } else {
        // just return the obj file with no texture if there are no wall thickness files
        objKey = part.s3ObjFileId
        mtlKey = null
      }
    } else if (part.units === "inches") {
      if (part.wallThicknessOBJFileInches && part.wallThicknessMaterialFileInches) {
        objKey = part.wallThicknessOBJFileInches
        mtlKey = part.wallThicknessMaterialFileInches
      } else {
        // just return the obj file with no texture if there are no wall thickness files
        objKey = part.s3ObjFileId
        mtlKey = null
      }
    }

  } else {
    objKey = partGroup.part.s3ObjFileId
    mtlKey = null
  }

  return { objKey, mtlKey }
}
