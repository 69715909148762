// Creates a function that allows you to easily retry an HTTP request without the HTTP request
// needing to handle all the logic for handling exponential backoff sleeps between retries
export function retryWithExponentialBackoffFactory(fn, retryAtMost = 5) {
  let retryCounter = 0

  return async function(err) {
    if (retryCounter >= retryAtMost) throw err

    // seconds to sleep between retries (exponential backoff): 1 - 2 - 4 - 8 - 16 - 32 - 64 ... etc
    await sleep(2 ** retryCounter * 1000)
    retryCounter++
    return fn()
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
