import USER_TYPES from './USER_TYPES.js'

export function projectCanBeOpenedByQuotePage(projectStatus, customerReady, userType){
  const projectIsInQuoting = projectStatus === 'Quoting'
  
  if(userType === USER_TYPES.DME){
    return projectIsInQuoting && !customerReady
  } else {
    return projectIsInQuoting && customerReady
  }
}
