import React from 'react'
import {Paper,Table,TableBody,TableCell,TableRow,Typography} from '@material-ui/core'
import MaybeTruncatedValue from './MaybeTruncatedValue.js'

function ProjectShipmentDataCard(props) {
  const { project , ...otherProps } = props;

  return (
    <Paper
      {...otherProps}
    >
      <Table className="shipment-row-height">
        <TableBody>
          <TableRow>
            <TableCell className="key-cell" colSpan="2">
              <Typography variant="h6">
                Project Shipping Data
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="key-cell">
              First Ship Date
            </TableCell>
            <TableCell>
              {project.paidShipDate}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="key-cell">
              Ship Method
            </TableCell>
            <TableCell>
              {project.isCustomShippingMethodUsed ? project.customShippingMethod : (project.isCustomShippingMethodUsed ? project.customShippingMethod : project.paidShipMethod)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="key-cell">
              Arrival Date
            </TableCell>
            <TableCell>
              {project.paidArrivalDate}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="key-cell">
              Customer Notes
            </TableCell>
            <TableCell>
              <MaybeTruncatedValue
                value={project.customerNotes}
                length={320}
              />
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </Paper>
  )
}

export default ProjectShipmentDataCard

