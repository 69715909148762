import config from "../config"
const { sendErrorEmail } = require('./util.js')

/*
  updateWholeProjectPromise calls the back end update project service to update projects discreetly when changes are made
  reponds with either "200" for successful or "400" if unsuccessful
*/
export function updateWholeProjectPromise(projectToSave) {
  let updateGdriveLocations = projectToSave.updateGdriveLocations ? projectToSave.updateGdriveLocations : false
  delete projectToSave.updateGdriveLocations
  delete projectToSave.updateId
  return fetch(config.UPDATE_PROJECT_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        "token": config.MICROSERVICE_TOKEN,
        "updateLocation": "customer-app",
        "updateGdriveLocations": updateGdriveLocations,
        "project": projectToSave
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json",
      }
    })
    .then(res => {
      return res.json()
    })
    .then(parsedResponse => {
      if (parsedResponse.statusCode === "200") {
        return (parsedResponse.statusCode)
      }
      else {
        throw (parsedResponse)
      }
      /*
        Not necessary to save response and update state, as it would create strange behavior
        for example :
          - User makes change to parts table > update service is called
          - While waiting on response, user makes second change
          - Response from first update comes and we update state with the response project, erasing second change to project
      */
    })
    .catch((e) => {
      sendErrorEmail(e)
      return ("400")
    })
}
