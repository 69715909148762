const axios = require('axios')
const uuid = require('uuid').v4
const { logUnexpected } = require('../../utils/logUnexpected.js')

const DEBOUNCE_TIME_MS = 2000
let lastCall

export default async function getToleranceClassifications(project, pauseCalculations){
  if(pauseCalculations){
    return []
  }

  return new Promise (function (resolve, reject) {
    const myCall = uuid()
    lastCall = myCall
    setTimeout(() => {
      if(myCall === lastCall){
        resolve(getToleranceClassificationsCall(project))
      }
    }, DEBOUNCE_TIME_MS)
  })
}

async function getToleranceClassificationsCall(project){
  let response
  try{
    response = await getToleranceClassificationsRequest(project)
  } catch (err){
    logUnexpected(err, { project }, 'getToleranceClassificationsRequest')
    throw err
  }

  let result
  try{
    result = parseGetToleranceClassificationsResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseGetToleranceClassificationsResponse', { project })
    throw err
  }

  return result
}

function getToleranceClassificationsRequest(project){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/project/getToleranceClassifications',
    data: {
      project
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseGetToleranceClassificationsResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
