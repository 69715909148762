import config from "../../config"
import axios from "axios"
const {logUnexpected} = require('../../utils/logUnexpected.js')

export async function getCustomerList(partialString){
  partialString = String(partialString).trim()
  let response
  try{
    response = await getCustomerListRequest(partialString)
  }
  catch(err){
    logUnexpected(err, { partialString }, "getCustomerListRequest")
    throw err
  }

  let parsedResponse
  try{
    parsedResponse = getCustomerListParser(response)
  }
  catch(err){
    logUnexpected(err, { response }, "getCustomerListParser", { partialString })
    throw err
  }
  return parsedResponse
}

async function getCustomerListRequest(partialString){

  return axios({
    method: 'POST',
    url: config.RETRIEVE_CUSTOMER_LIST,
    timeout: 10000,
    validateStatus: () => true,
    data: {
      "partialCustomerName": partialString,
      "token": config.MICROSERVICE_TOKEN,
    },
   })
     .then( rawResponse => ({ data: rawResponse.data, status: rawResponse.status}))
}

function getCustomerListParser(response){
  if(response.status === 200){
    return response.data.body.customers
  }
  else{
    throw new Error('There was a problem retrieving customer list')
  }
}
