// Validate all environment variables
// TODO: after refactor, should have smarter checks (which check regex's) and default values
// TODO: add smart validation only when there are multiple environments (e.g. test & prod)
// ... since then there would be multiple .envs files, instead of just one tested
// ... OR when we add actual "configurable" configuration data (e.g. true/false X_FEATURE)

let configVars = {
  CLONE_PROJECT_ENDPOINT: process.env.REACT_APP_CLONE_PROJECT_ENDPOINT,
  CREATE_ATTACHMENT_ENDPOINT: process.env.REACT_APP_CREATE_ATTACHMENT_ENDPOINT,
  CREATE_FILE_ENDPOINT: process.env.REACT_APP_CREATE_FILE_ENDPOINT,
  CREATE_OVERMOLDEDINSERT_ENDPOINT: process.env.REACT_APP_CREATE_OVERMOLDEDINSERT_ENDPOINT,
  CREATE_POSTPROCESSCONFIG_ENDPOINT: process.env.REACT_APP_CREATE_POSTPROCESSCONFIG_ENDPOINT,
  CREATE_PROJECT_ENDPOINT: process.env.REACT_APP_CREATE_PROJECT_ENDPOINT,
  CREATE_PTPC_ATTACHMENT_ENDPOINT: process.env.REACT_APP_CREATE_PTPC_ATTACHMENT_ENDPOINT,
  CREATE_PTPC_ENDPOINT: process.env.REACT_APP_CREATE_PTPC_ENDPOINT,
  CREATE_TOLERANCE_ENDPOINT: process.env.REACT_APP_CREATE_TOLERANCE_ENDPOINT,
  CUSTOMER_APP_CLIENT_ID: process.env.REACT_APP_CUSTOMER_APP_CLIENT_ID,
  ERROR_EMAIL_ENDPOINT: process.env.REACT_APP_ERROR_EMAIL_ENDPOINT,
  CREATE_PART_ENDPOINT: process.env.REACT_APP_CREATE_PART_ENDPOINT,
  CREATE_PARTGROUP_ENDPOINT: process.env.REACT_APP_CREATE_PARTGROUP_ENDPOINT,
  DELETE_FILE_ENDPOINT: process.env.REACT_APP_DELETE_FILE_ENDPOINT,
  DELETE_OVERMOLDEDINSERT_ENDPOINT: process.env.REACT_APP_DELETE_OVERMOLDEDINSERT_ENDPOINT,
  DELETE_POSTPROCESSCONFIG_ENDPOINT: process.env.REACT_APP_DELETE_POSTPROCESSCONFIG_ENDPOINT,
  DELETE_PTPC_ATTACHMENT_ENDPOINT: process.env.REACT_APP_DELETE_PTPC_ATTACHMENT_ENDPOINT,
  DELETE_PTPC_ENDPOINT: process.env.REACT_APP_DELETE_PTPC_ENDPOINT,
  DELETE_TOLERANCE_ENDPOINT: process.env.REACT_APP_DELETE_TOLERANCE_ENDPOINT,
  DOWNLOAD_ALL_PROJECT_FILES_ENDPOINT: process.env.REACT_APP_DOWNLOAD_ALL_PROJECT_FILES_ENDPOINT,
  DOWNLOAD_ALL_QUOTES_ENDPOINT: process.env.REACT_APP_DOWNLOAD_ALL_QUOTES_ENDPOINT,
  DOWNLOAD_FILES_ENDPOINT: process.env.REACT_APP_DOWNLOAD_FILES_ENDPOINT,
  DOWNLOAD_INTERNAL_FILES_ENDPOINT: process.env.REACT_APP_DOWNLOAD_INTERNAL_FILES_ENDPOINT,
  DOWNLOAD_PROJECT_FILES_HISTORY_ENDPOINT: process.env.REACT_APP_DOWNLOAD_PROJECT_FILES_HISTORY_ENDPOINT,
  GET_CUSTOMER_PROJECTS: process.env.REACT_APP_GET_CUSTOMER_PROJECTS,
  GET_PURCHASED_PRODUCTION_TOOLS_ENDPOINT: process.env.REACT_APP_GET_PURCHASED_PRODUCTION_TOOLS_ENDPOINT,
  GET_PART_ENDPOINT: process.env.REACT_APP_GET_PART_ENDPOINT,
  LOAD_PROJECT_ENDPOINT: process.env.REACT_APP_LOAD_PROJECT_ENDPOINT,
  LOAD_USER_ENDPOINT: process.env.REACT_APP_LOAD_USER_ENDPOINT,
  MICROSERVICE_TOKEN: process.env.REACT_APP_MICROSERVICE_TOKEN,
  REFRESH_GDRIVE_FILES_ENDPOINT: process.env.REACT_APP_REFRESH_GDRIVE_FILES_ENDPOINT,
  REGION: process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : 'us-east-1',
  GET_PURCHASED_TOOL_DATA_ENDPOINT: process.env.REACT_APP_GET_PURCHASED_TOOL_DATA_ENDPOINT,
  REMOVE_ATTACHMENT_ENDPOINT: process.env.REACT_APP_REMOVE_ATTACHMENT_ENDPOINT,
  REMOVE_PART_GROUP: process.env.REACT_APP_REMOVE_PART_GROUP,
  REPLACE_PART_FILE_ENDPOINT: process.env.REACT_APP_REPLACE_PART_FILE_ENDPOINT,
  RETRIEVE_CUSTOMER_LIST: process.env.REACT_APP_RETRIEVE_CUSTOMER_LIST,
  RETRY_GDRIVE_FOLDERS_ENDPOINT: process.env.REACT_APP_RETRY_GDRIVE_FOLDERS_ENDPOINT,
  SAVE_FULL_PROJECT_ENDPOINT: process.env.REACT_APP_SAVE_FULL_PROJECT_ENDPOINT,
  SHIPSTATION_KEY: process.env.REACT_APP_SHIPSTATION_KEY,
  SHIPSTATION_SECRET: process.env.REACT_APP_SHIPSTATION_SECRET,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  STRIPE_PUBLIC_KEY_TEST: process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST,
  UPDATE_CUSTOMER_ADDRESS_ENDPOINT: process.env.REACT_APP_UPDATE_CUSTOMER_ADDRESS_ENDPOINT,
  UPDATE_OVERMOLDEDINSERT_ENDPOINT: process.env.REACT_APP_UPDATE_OVERMOLDEDINSERT_ENDPOINT,
  UPDATE_PART_GROUP_ENDPOINT: process.env.REACT_APP_UPDATE_PART_GROUP_ENDPOINT,
  UPDATE_POSTPROCESSCONFIG_ENDPOINT: process.env.REACT_APP_UPDATE_POSTPROCESSCONFIG_ENDPOINT,
  UPDATE_PROJECT_ENDPOINT: process.env.REACT_APP_UPDATE_PROJECT_ENDPOINT,
  UPDATE_PROJECT_ONLY_ENDPOINT: process.env.REACT_APP_UPDATE_PROJECT_ONLY_ENDPOINT,
  UPDATE_PTPC_ENDPOINT: process.env.REACT_APP_UPDATE_PTPC_ENDPOINT,
  UPDATE_TOLERANCE_ENDPOINT: process.env.REACT_APP_UPDATE_TOLERANCE_ENDPOINT,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  START_GEOM_ANALYSIS_ENDPOINT_DIM_ONLY: process.env.REACT_APP_START_GEOM_ANALYSIS_ENDPOINT_DIM_ONLY,
  START_GEOM_ANALYSIS_ENDPOINT: process.env.REACT_APP_START_GEOM_ANALYSIS_ENDPOINT,
  START_IM_WTA: process.env.REACT_APP_START_IM_WTA,
  GEOMETRY_ANALYZER_PING_ENDPOINT: process.env.REACT_APP_GEOMETRY_ANALYZER_PING_ENDPOINT,
  TYPE: process.env.REACT_APP_TYPE,
}

function validateEnvars(configVarsObject) {
    // eslint-disable-next-line 
  for ( let key of Object.keys(configVarsObject) ) {
    if (configVarsObject[key] === undefined) { throw new Error(`Environment variable: ${key} is undefined, but required`) }
  }
  // This code runs in the browser so we should only expose secret info if its in development mode
  if ( process.env.NODE_ENV === 'development' ) {
    console.log('configuration data:')
    console.log(configVars)
  }
  return configVarsObject
}


module.exports = validateEnvars(configVars)
