import React from "react"
import { InputAdornment, Typography } from "@material-ui/core"
import { NumberField } from "./NumberField"

export default function DmeSheetMetal(props){
  const { onChange, partGroup } = props
  const onChangeHandler = e => onChange(e, partGroup.partGroupNumber)

  return (
    <div
      className="dme-sheet-metal"
    >
      <Typography variant="h6" className="dme-section-header">
        Sheet Metal Fields
      </Typography>
      <div className="dme-sheet-metal-form">
        <NumberField
          label="Estimated length of cutting path"
          InputProps={{
            endAdornment: <InputAdornment children="mm" position="end"/>
          }}
          name="sheetMetalCutPathLength"
          onChange={onChangeHandler}
          styles={{width:"14em"}}
          value={partGroup.part.sheetMetalCutPathLength}
        />
        <NumberField
          label="Stock X"
          InputProps={{
            endAdornment: <InputAdornment children="mm" position="end"/>
          }}
          name="sheetMetalStockX"
          onChange={onChangeHandler}
          styles={{width:"6em"}}
          value={partGroup.part.sheetMetalStockX}
        />
        <NumberField
          label="Stock Y"
          InputProps={{
            endAdornment: <InputAdornment children="mm" position="end"/>
          }}
          name="sheetMetalStockY"
          onChange={onChangeHandler}
          styles={{width:"6em"}}
          value={partGroup.part.sheetMetalStockY}
        />
        <NumberField
          label="Stock Thickness"
          InputProps={{
            endAdornment: <InputAdornment children="mm" position="end"/>
          }}
          name="sheetMetalStockThickness"
          onChange={onChangeHandler}
          styles={{width:"6em"}}
          value={partGroup.part.sheetMetalStockThickness}
        />
        <NumberField
          label="# of Bends"
          name="sheetMetalNumberOfBends"
          onChange={onChangeHandler}
          styles={{width:"6em"}}
          value={partGroup.part.sheetMetalNumberOfBends}
        />
        <NumberField
          label="# of Welds"
          name="sheetMetalNumberOfWelds"
          onChange={onChangeHandler}
          styles={{width:"6em"}}
          value={partGroup.part.sheetMetalNumberOfWelds}
        />
      </div>
    </div>
  )
}

