export default function calculateWallThicknessPercentage(partGroup){
  const unitSuffix = (partGroup.part.units === 'mm' ? 'MM' : 'Inches')
  const surfaceAreaBelowThreshold = partGroup.part['netfabbWallThicknessSurfaceAreaBelowThreshold' + unitSuffix]
  const totalSurfaceArea = partGroup.part['netfabbWallThicknessTotalSurfaceArea' + unitSuffix]

  const wallThicknessPercentage =
        Number(totalSurfaceArea) !== 0 ?
        surfaceAreaBelowThreshold / totalSurfaceArea * 100
        : 0

  return wallThicknessPercentage
}
