module.exports = {calculateLockedProjectTotal}

function calculateLockedProjectTotal(project){
  let runningTotal = 0

  runningTotal = project.partGroups
    .reduce(partGroupsToPrice, runningTotal)

  runningTotal = project.partGroups
    .flatMap(partGroupToPtpcs)
    .reduce(ptpcsToPrice, runningTotal)

  runningTotal += +project.lockedLeadTimeExpediteFee

  return runningTotal.toFixed(2)
}

function partGroupsToPrice(runningTotal, partGroup){
  const subtotal = partGroup.quantity * partGroup.lockedUnitPrice
  return runningTotal + subtotal
}

function ptpcsToPrice(runningTotal, ptpc){
  const subtotal = ptpc.quantity * ptpc.lockedUnitPrice
  return runningTotal + subtotal
}

function partGroupToPtpcs(partGroup){
  return partGroup.productionToolPartConfigurations
    .filter(ptpc => ptpc.processId === partGroup.processId)
}
