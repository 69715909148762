import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function createPostProcessConfig(postProcessConfig){
  let response
  try{
    response = await createPostProcessConfigRequest(postProcessConfig)
  } catch (err){
    logUnexpected(err, { postProcessConfig }, 'createPostProcessConfigRequest')
    throw err
  }

  let result
  try{
    result = parseCreatePostProcessConfigResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseCreatePostProcessConfigResponse', { postProcessConfig })
    throw err
  }

  return result
}

function createPostProcessConfigRequest(postProcessConfig){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.CREATE_POSTPROCESSCONFIG_ENDPOINT,
    data: {
      postProcessConfig,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseCreatePostProcessConfigResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.postProcessConfig){
      return response.data.postProcessConfig
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
