const { logUnexpected } = require('../../../../utils/logUnexpected.js')
const axios = require('axios')

export async function directUpload(part, tempUploadUrl, tempUploadFields) {
  let response
  try {
    response = await directUploadRequest(part, tempUploadUrl, tempUploadFields)
  } catch (err) {
    logUnexpected(err, { part, tempUploadUrl, tempUploadFields }, 'directUploadRequest')
    throw err
  }

  try {
    parseDirectUploadResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseDirectUploadResponse', { part, tempUploadUrl, tempUploadFields })
    throw err
  }

  return tempUploadFields.key
}

async function directUploadRequest(part, tempUploadUrl, tempUploadFields) {
  const formData = new FormData()
  Object.entries(tempUploadFields).forEach(([k, v]) => {
    formData.append(k, v)
  })

  formData.append('file', part)

  const response = await axios.post(tempUploadUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      // custom metadata
      'x-amz-meta-fileName': part.name,
    },
    validateStatus: () => true,
  })

  return { status: response.status, data: response.data }
}

function parseDirectUploadResponse(response) {
  if (response.status === 204) return

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
