import React, { useContext, useState } from 'react'
//DXI: NativeSelect looks better in dropdown menu
import {IconButton,Menu,MenuItem,Paper,NativeSelect as Select,Table,TableBody,TableCell,TableRow,TextField,Typography} from '@material-ui/core'
import makeShipLabel from './makeShipLabel.js'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SelectOptionShipping from './SelectOptionShipping.js'
import {SnackBarContext} from '../../Common/SnackBarStore'
import {CLOSED_SUPPLIER_ARRAY,DOMESTIC_SUPPLIER_ARRAY,INTERNATIONAL_SUPPLIER_ARRAY} from '../TranslationTableSuppliers.js'
import {SUPPLIER_FROM_TYPE_ARRAY,SUPPLIER_TO_TYPE_ARRAY,SUPPLIER_FROM_TYPE_ARRAY_SHIPMENT} from '../supplierTypeArrays.js'
import {formatDateForDatePicker} from '../datePickerDataParsing.js'

const ShipmentDataCard = ( props ) => {
  const { activity, onChange, project, ...otherProps } = props

  //Provides a yellow or white bg style object
  let renderColor = (activity.makeOrder && activity.makeOrder.reprintBool ? {backgroundColor:'lightblue'} : {backgroundColor:'white'})
  return (
    <Paper
      {...otherProps}
    >
      <Table
        style={renderColor}
        className="shipment-row-height"
        size="small"
      >
        <TableBody>
          <ShipmentNumberRow
            activity={activity}
            project={project}
          />

          <SupplierTypeRow
            activity={activity}
            onChange={onChange}
          />

          <SupplierRow
            activity={activity}
            onChange={onChange}
          />

          <ShippingToRow
            activity={activity}
            onChange={onChange}
          />

          <PaidShipDateRow
            activity={activity}
            onChange={onChange}
          />

          <PaidShippingMethodRow
            activity={activity}
            onChange={onChange}
          />

          <PaidArrivalDateRow
            activity={activity}
          />

          <ActualShipDateRow
            activity={activity}
            onChange={onChange}
          />

          <ActualShippingMethodRow
            activity={activity}
            onChange={onChange}
          />

          <ActualArrivalDateRow
            activity={activity}
          />

          <PackslipNotesRow
            activity={activity}
            onChange={onChange}
          />

          <InternalOnlyNotesRow
            activity={activity}
            onChange={onChange}
          />
        </TableBody>
      </Table>
    </Paper>
  )
}

const ShipmentNumberRow = ({ activity, project }) => {
  return (
    <TableRow>
      <TableCell
        colSpan="2"
        className="table-header-row"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{width: '2em'}}/>
          <Typography align="center" variant="h6">
            Shipment {activity.activityNumber}
          </Typography>
          <div style={{width: '2em', display: 'flex', alignItems: 'center'}}>
            <ShipmentActionsMenu
              activity={activity}
              project={project}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}

const ShipmentActionsMenu = ({ activity, project }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  const makeShipLabelHandler = () => {
    handleClose()
    openSnackBarWithMessage("Pushing to Shipstation...")

    makeShipLabel(activity.shipment, project)
      .then(() => openSnackBarWithMessage("Order pushed to Shipstation!"))
      .catch(e => openSnackBarWithMessage("Error: " + e.message))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick} style={{padding:3}}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={makeShipLabelHandler}>Make Ship Label</MenuItem>
      </Menu>
    </div>
  )
}

const SupplierTypeRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Supplier Type
      </TableCell>
      <TableCell
        className={activity.activityFrom ? "" : "empty-input-cell"}>
        <Select
          name="activityFrom"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Supplier Type"
          style={{width: "100%"}}
          value={activity.activityFrom}
        >
          <option key="blank" value=""></option>
          {activity.makeOrder ?
          SUPPLIER_FROM_TYPE_ARRAY.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })
          :
          SUPPLIER_FROM_TYPE_ARRAY_SHIPMENT.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })
          }
        </Select>
      </TableCell>
    </TableRow>
  )
}

const SupplierRow = ({ activity, onChange }) => {
  const supplierId = activity.supplier[0] ? activity.supplier[0].id : ""
  return (
    <TableRow>
      <TableCell className="key-cell">
        Supplier
      </TableCell>
      <TableCell className={supplierId ? "" : "empty-input-cell"}>
        <Select
          name="supplierId"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          value={supplierId}
          style={{width: "100%"}}
        >
          <option value=""/>
          <option disabled value="domestic suppliers">Domestic</option>
          {DOMESTIC_SUPPLIER_ARRAY
           .map(supplier=>{
             return(
               <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
             )
           })}
          <option disabled value="blank line"/>
          <option disabled value="international suppliers">International</option>
          {INTERNATIONAL_SUPPLIER_ARRAY.map(supplier=>{
            return(
              <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
            )
          })}
          <option disabled value="blank line"/>
          <option disabled value="international suppliers">Closed</option>
          {CLOSED_SUPPLIER_ARRAY.map(supplier=>{
            return(
              <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
  )
}

const ShippingToRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Shipping To
      </TableCell>
      <TableCell className={activity.activityTo ? "" : "empty-input-cell"}>
        <Select
          name="activityTo"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Shipping To"
          style={{width: "100%"}}
          value={activity.activityTo}
        >
          <option key="blank" value=""></option>
          {SUPPLIER_TO_TYPE_ARRAY.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
  )
}

const PaidShipDateRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Paid Ship Date
      </TableCell>
      <TableCell className={activity.shipment.paidShipDate ? "" : "empty-input-cell"}>
        <TextField
          name="paidShipDate"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="mm/dd/yyyy"
          style={{width: "100%"}}
          type="date"
          value={formatDateForDatePicker(activity.shipment.paidShipDate)}
        />
      </TableCell>
    </TableRow>
  )
}

const PaidShippingMethodRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Paid Ship Method
      </TableCell>
      <TableCell className={activity.shipment.paidShipMethod ? "" : "empty-input-cell"}>
        <SelectOptionShipping
          name="paidShipMethod"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          value={activity.shipment.paidShipMethod}
          style={{width: '100%'}}
        />
      </TableCell>
    </TableRow>
  )
}

const PaidArrivalDateRow = ({ activity }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Paid Arrival Date
      </TableCell>
      <TableCell>
        {activity.shipment.paidArrivalDate}
      </TableCell>
    </TableRow>
  )
}

const ActualShipDateRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell" style={{backgroundColor:"darkgray"}}>
        Actual Ship Date
      </TableCell>
      <TableCell className={activity.shipment.actualShipDate ? "" : "empty-input-cell"} style={{backgroundColor:"gainsboro"}}>
        <TextField
          name="actualShipDate"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="mm/dd/yyyy"
          style={{width: "100%"}}
          type="date"
          value={formatDateForDatePicker(activity.shipment.actualShipDate)}
        />
      </TableCell>
    </TableRow>
  )
}

const ActualShippingMethodRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell" style={{backgroundColor:"darkgray"}}>
        Actual Ship Method
      </TableCell>
      <TableCell className={activity.shipment.actualShipMethod ? "" : "empty-input-cell"} style={{backgroundColor:"gainsboro"}}>
        <SelectOptionShipping
          name="actualShipMethod"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          value={activity.shipment.actualShipMethod}
          style={{width: '100%'}}
        />
      </TableCell>
    </TableRow>
  )
}

const ActualArrivalDateRow = ({ activity }) => {
  return (
    <TableRow>
      <TableCell className="key-cell" style={{backgroundColor:"darkgray"}}>
        Actual Arrival Date
      </TableCell>
      <TableCell style={{backgroundColor:"gainsboro"}}>
        {activity.shipment.actualArrivalDate}
      </TableCell>
    </TableRow>
  )
}

const PackslipNotesRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Packslip Notes
      </TableCell>
      <TableCell>
        <TextField
          name="shipSupplierNotes"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Packslip Notes"
          style={{width: "100%"}}
          type="text"
          value={activity.shipment.shipSupplierNotes}
        />
      </TableCell>
    </TableRow>
  )
}

const InternalOnlyNotesRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Internal Only Notes
      </TableCell>
      <TableCell>
        <TextField
          name="shipOurNotes"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Internal Only Notes"
          style={{width: "100%"}}
          type="text"
          value={activity.shipment.shipOurNotes}
        />
      </TableCell>
    </TableRow>
  )
}

export default ShipmentDataCard
