export function addNewShipmentToProject(project, tempActivityNumber) {
    let newShipmentObject = {
        paidArrivalDate: undefined,
        partBatches:[],
        ptpcPartBatches: [],
        makeOrder: [],
        paidShipDate: undefined,
        tempActivityNumber: tempActivityNumber,
        shipmentFrom: "",
        shipmentTo: ""
    }
    project.shipments = project.shipments.concat([newShipmentObject])
    return project
}
