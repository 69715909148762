import React,{Component,Fragment} from 'react'
import {Auth} from 'aws-amplify'
import {LoadingPage,ViewController} from '../'
import * as auth from '../../Components/Login/authentication'
import {navigate} from '@reach/router'
import {AppWideSnackbar} from '../Common'
import {Snackbar, Button} from '@material-ui/core'
import {loadUserTypeFromPostgres} from '../HomePage/loadUser.js'

class LoginController extends Component {
  constructor(props){
    super(props)
    this.state = {
      isAccountCreationSnackbarOpen: false,
      checkedToken: false,          // Determines when to load viewController
      loggedIn: false,              // Determines whether to show the login form
      snackBarMessage: '',          // A message to show the user in a snackbar popup
      snackBarOpen: false,          // Determines if the snackbar popup is currently open
      user: {},                     // Holds the user info
    }
  }

  changeUserType = userType => {
    const user = {...this.state.user}
    user.type = userType

    this.setState({ user })
  }
  /*
    componentDidMount is a react lifecycle hook that is triggered as soon as the component is mounted on the DOM
  */
  async componentDidMount(){
    // Run upon entering react app
    // to check if user is logged in
    try {
      const user = await Auth.currentSession()
      if (user) {
        await this.setLoginState(user.idToken.payload, user.accessToken.jwtToken)
      }
    } catch(error) {
      /* user not signed in */
      this.setState({
        checkedToken: true,
      })
    }
    // refresh access token every 30 minutes
    this.timer = setInterval(() => this.refreshToken(), 1800000)
  }

  /*
    Sets state user info and saves the access token to localStorage after logging in
  */
  setLoggedOutState = (callback) => {
    localStorage.removeItem('accessToken')
    this.setState({
      loggedIn: false,
      user: {}
    },callback)
  }

  setLoginState = async (user, token) => {
    user.type = await loadUserTypeFromPostgres(user.email)

    localStorage.setItem('accessToken', token)
    this.setState({
      checkedToken: true,
      loggedIn: true,
      user,
    })
  }

  /*
    refresh access token by calling Auth.currentSession()
    (Token refresh handled automatically by Cognito API)
  */
  refreshToken = async () => {
    try {
      const session = await Auth.currentSession()
      localStorage.setItem('accessToken', session.accessToken.jwtToken)
      return
    } catch (error) {
      if (this.state.loggedIn === true) {
        // If the user is logged in and Auth.currentSession()
        // throws an error then there was a cognito API error
      }
    }
  }

  openSnackBar = message => {
    this.setState({
      snackBarMessage: message,
      snackBarOpen: true
    })
  }
  closeAccountCreationSnackbar = () => {
    this.setState({
      isAccountCreationSnackbarOpen: false,
    })
  }
  openAccountCreationSnackbar = () => {
    this.setState({
      isAccountCreationSnackbarOpen: true,
    })
  }

  closeSnackBar = () => {
    this.setState({
      snackBarMessage: '',
      snackBarOpen: false
    })
  }

  loginHandler = async (email,password) => {
    auth.login(email,password)
      .then((user) => {
        this.setLoginState(
          user.signInUserSession.idToken.payload,
          user.signInUserSession.accessToken.jwtToken,
        )
      })
      .then(() => {
        const projectNumberMaybe = (new URLSearchParams(window.location.search)).get('project')
        if(projectNumberMaybe){
          navigate(`/quote/${projectNumberMaybe}`)
        } else {
          navigate('/')
        }
      })
      .catch((error) => {
        if (error.message === 'User does not exist.') {
          this.openAccountCreationSnackbar()
        } else {
          this.openSnackBar(`${error.message}`)
        }
      })
  }

  signUp = async (email,password,firstName,lastName) => {
    auth.signUp(email, password, firstName, lastName)
      .then(() => this.loginHandler(email,password))
      .catch(error => this.openSnackBar(`${error.message}`))
  }

  logoutHandler = async () => {
    auth.logout()
    // remove the auth token from the users browser and navigate them to the login page regardless
    // of the outcome of auth.logout() which calls out to cognito to revoke token on the backend
      .finally(() => {
        this.setLoggedOutState(() => navigate('/login'))
    })
  }

  // Sends an email to the customer with a code that they can enter to trigger new password creation
  forgotPasswordSendCodeHandler = async (email) => {
    try {
      await auth.forgotPasswordSendCode(email)
      // navigate to page for code confirmation
      // and pass email along to that component
      navigate(`confirmCode?${(new URLSearchParams(window.location.search)).toString()}`, {state: {email: email}})
      this.openSnackBar(`A code has been sent to your email! Enter it above`)
    } catch(err) {
      if (err.message ===  'UserNotFoundException') {
        this.openAccountCreationSnackbar()
      } else {
        this.openSnackBar(err.message)
      }
    }
    return
  }

  //Triggered after a user enters the code that they got in their forgot password email
  forgotPasswordConfirmCodeHandler = async (email, code, newPassword) => {
    auth.forgotPasswordConfirmCode(email, code, newPassword)
      .then(() => navigate(`../login?${(new URLSearchParams(window.location.search)).toString()}`))
      .then(() => this.openSnackBar(`Password successfully Changed!`))
      .catch((err) => this.openSnackBar(`${err.message}`))
  }

  changePasswordHandler = async (oldPassword, newPassword) => {
    auth.changePassword(oldPassword, newPassword)
      .then(() => navigate('../'))
      .then(() => this.openSnackBar('Password successfully Changed!'))
      .catch((err) => this.openSnackBar(err.message))
  }

  render(){
    return(
      <Fragment>
      {this.state.checkedToken ?
        <ViewController
          changePasswordHandler={this.changePasswordHandler}
          changeUserType={this.changeUserType}
          forgotPasswordSendCodeHandler={this.forgotPasswordSendCodeHandler}
          forgotPasswordConfirmCodeHandler={this.forgotPasswordConfirmCodeHandler}
          login={this.loginHandler}
          logoutHandler={this.logoutHandler}
          loggedIn={this.state.loggedIn}
          materialsData={this.props.materialsData}
          postProcessData={this.props.postProcessData}
          setLoggedOutState={this.setLoggedOutState}
          signUp={this.signUp}
          supportedBrowser={this.props.supportedBrowser}
          TOOL_MATERIALS={this.props.TOOL_MATERIALS}
          user={this.state.user}
        />
      : <LoadingPage/> }

        <Snackbar
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              variant="contained"
              onClick={()=>{
                navigate(`/login/createAccount?${(new URLSearchParams(window.location.search)).toString()}`)
                this.closeAccountCreationSnackbar()
              }}
            >
              Make an account here
            </Button>
          ]}
          autoHideDuration={15000}
          message={"User not found. Please check if email is correct."}
          onClose={this.closeAccountCreationSnackbar}
          open={this.state.isAccountCreationSnackbarOpen}
          style={{marginBottom:"24px"}}
        />

        <AppWideSnackbar
          message={this.state.snackBarMessage}
          close={this.closeSnackBar}
          open={this.state.snackBarOpen}
        />
      </Fragment>
    )
  }
}

export default LoginController
