import downloadFilesAsZip from './downloadFilesAsZip.js'
import downloadFileToLocalMachineFromBlob from '../../../utils/downloadFileToLocalMachineFromBlob.js'

export default async function downloadPartBatchFiles(activity, partGroups){
  const partBatchesInActivity = activity
        .partBatches
        .filter(pb => pb.quantity > 0)

  const partGroupsNumbersInActivity = partBatchesInActivity
        .map(pb => {
          if(pb.productionToolPartConfigurationId){
            const partGroup = partGroups.find(
              ptg => ptg.productionToolPartConfigurations.find(
                ptpc => ptpc.productionToolPartConfigurationId === pb.productionToolPartConfigurationId
              )
            )
            return partGroup.partGroupNumber
          } else {
            return pb.partGroup
          }
        })

  const uniquePartGroupNumbersInActivity = Array.from(new Set(partGroupsNumbersInActivity))

  const filesInActivity = uniquePartGroupNumbersInActivity
        .map(
          partGroupNumber => partGroups
            .find(ptg => Number(ptg.partGroupNumber) === Number(partGroupNumber))
        )
        .map(ptg => {
          return {
            fileName: ptg.part.fileName,
            s3Id: ptg.part.partFileS3,
          }
        })

  const fileName = `${activity.activityNumber}-files.zip`
  const zipFile = await downloadFilesAsZip(filesInActivity)
  downloadFileToLocalMachineFromBlob(zipFile, fileName)
}

