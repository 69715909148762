function businessLogicMfgIssues(partGroup, POST_PROCESS_OPTIONS) {
  let mfgIssues = []
  if (partGroup.processId === 'sls' && (partGroup.part.xDim > 140 || partGroup.part.yDim > 140 || partGroup.part.zDim > 140)) {
    const slsWarpMessage = 'SLS parts with a dimension over 140mm are prone to warping'
    mfgIssues.push(slsWarpMessage)
  }

  if(hasHeatTreatmentPostProcess(partGroup, POST_PROCESS_OPTIONS)){
    const heatTreatmentMessage = 'Parts with a heat treated post process are prone to warping'
    mfgIssues.push(heatTreatmentMessage)
  }

  return mfgIssues
}

function hasHeatTreatmentPostProcess(partGroup, POST_PROCESS_OPTIONS){
  return partGroup.postProcesses
    .some(postProcess => isHeatTreatmentPostProcess(postProcess, POST_PROCESS_OPTIONS))
}

function isHeatTreatmentPostProcess(postProcess, POST_PROCESS_OPTIONS){
  const postProcessOptionData = POST_PROCESS_OPTIONS
        .find(ppd => ppd.id === postProcess.postProcessId)

  return postProcessOptionData.display.type === 'heatTreatments'
}

module.exports = { businessLogicMfgIssues }
