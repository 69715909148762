import decorateProjectWithPrices from '../Components/Common/decorateProjectWithPrices.js'
import { v4 as uuid } from 'uuid'

let lastCall
export async function getPricingData(project, pauseCalculations){
  const myCall = uuid()
  lastCall = myCall

  if(pauseCalculations){
    return {
      isLatestUpdate: lastCall === myCall,
      pricingData: getEmptyPricingData(project),
      reviewReasons: {
        "projectReasons": ["Request failed with message: Calculations paused."],
        "partsReasons": project.partGroups
          .map(ptg => ({
            "partGroupNumber": ptg.partGroupNumber,
            "partReasons": ["Manual RFQ"]
          }))
      }
    }
  }

  try{
    const pricingData = await decorateProjectWithPrices(project)
    return {
      isLatestUpdate: lastCall === myCall,
      pricingData,
      reviewReasons: {
        projectReasons:[],          // Tracks project reasons for manual review
        partsReasons:[]             // Tracks individual part reasons for review
      }
    }
  } catch(e){
    return {
      isLatestUpdate: lastCall === myCall,
      pricingData: getEmptyPricingData(project),
      reviewReasons: {
        projectReasons:[e.message], // Tracks project reasons for manual review
        partsReasons:project.partGroups.map(ptg => ({partGroupNumber: ptg.partGroupNumber, partReasons:['Manual RFQ']}))             // Tracks individual part reasons for review
      }
    }
  }
}

function getEmptyPricingData(project){
  return ({
    toleranceClassifications: project.partGroups.flatMap(
      partGroup => partGroup.tolerances.map(
        tolerance => ({
          id: tolerance.id,
          classification: 'high', // options: "standard", "medium", "high", "nobid"
          revisedLowerBound: '0.02',
          revisedUpperBound: '0.04',
        })
      )
    ),
    leadTimePricePairs: [],
    ptpcPrices: project.partGroups
      .flatMap(ptg => ptg.productionToolPartConfigurations)
      .map(ptpc => ({
        id: ptpc.productionToolPartConfigurationId,
        unitPrice: 0,
      })),
    partGroupPrices: project.partGroups.map(
      partGroup => ({
        unitPrice: 0,
        partGroupNumber: partGroup.partGroupNumber,
      })
    )
  })
}

