// const testPoints = require('./testPoints.js')
// const groups = convertPointsToGroups(testPoints)

// groups.forEach(group=>{
  // console.log(group.length)
  // console.log(group)
// })

// A position value is the distance from the original, in x, y, or z direction. It is just a number.
// A vertex is a set of three positions in a 3d space [x,y,z]. It is just a point in space.
// A triangle is a set of three vertices [a,b,c] OR [ [x_a,y_a,z_a], [x_b,y_b,z_b], [x_c,y_c,z_c] ]
// A vertices array is all the triangles positions in a single array [ x_a, y_a, z_a, x_b, y_b, z_b, x_c, y_c, z_c ]
//  - vertices arrays can contain any number of triangles

// A vector is a difference between two points [delta_x,delta_y,delta_z]
// - vectors inherently convey a direction and a distance
// - a normalized vector has a length of 1
// - inverting a vector inverts the direction but doesnt change the length

export default function convertPointsToGroups(centeredModel){
  const POSITIONS_PER_VERTEX = 3
  const VERTICES_PER_TRIANGLE = 3

  const positionArray = centeredModel.children[0].geometry.attributes.position.array
  const [greenGroup,redGroup] = centeredModel.children[0].geometry.groups

  let vertices = chunkArray(positionArray,POSITIONS_PER_VERTEX)

  if(redGroup){
    vertices = vertices.splice(redGroup.start,redGroup.count)
  }

  let triangles = chunkArray(vertices,VERTICES_PER_TRIANGLE)

  if(triangles.length > 100000){
    throw new Error(`over 100,000 surfaces`)
  }

  let groups = []

  triangles.forEach((triangle,index)=>{
    if(index === 0){
      groups[0] = [triangle]
    }
    else{
      let matchingGroupsIndexes = getMatchingGroups(groups,triangle)
      let numberOfMatchingGroups = matchingGroupsIndexes.length

      switch(numberOfMatchingGroups){
      case 0:
        addAsNewGroup(groups,triangle)
        break
      case 1:
        addToExistingGroup(groups,triangle,matchingGroupsIndexes)
        break
      case 2:
      case 3:
        combineExistingGroups(groups,triangle,matchingGroupsIndexes)
        break
      default:
        throw new Error ("A triangle shouldnt match more than three others at the same time?/broken mesh?")
      }
    }
  })

  if(groups.length > 100){
    throw new Error(`over 100 thin walls`)
  }

  return groups
}

function chunkArray(array,chunkSize){
  validateArrayLength()

  return Array(array.length/chunkSize)
    .fill()
    .map((_el, index) => index * chunkSize)
    .map(index => array.slice(index, index + chunkSize))

  function validateArrayLength(){
    if(
      Math.ceil(array.length/chunkSize)*chunkSize
        !== array.length
    ){
      throw Error(`array of length ${array.length} not able to be split into chunks of size ${chunkSize}`)
    }
  }
}
  
function addAsNewGroup(groups,triangle){
  groups.push([triangle])
}

function addToExistingGroup(groups,triangle,matchingGroupsIndexes){
  groups[matchingGroupsIndexes[0]].push(triangle)
}

function combineExistingGroups(groups,triangle,matchingGroupsIndexes){
  // combine all matching groups into one
  let groupToMergeInto
  matchingGroupsIndexes.forEach((groupIndex,index)=>{
    if(index === 0) groupToMergeInto = groups[groupIndex]
    else {
      let {updatedGroups, removedGroup} = removeGroupFromGroups(groups,groupIndex)
      groupToMergeInto = groupToMergeInto.concat(removedGroup)
      groupToMergeInto.push(triangle)
    }
  })
  groups[matchingGroupsIndexes[0]] = groupToMergeInto
}

function removeGroupFromGroups(array,index){
  let deletedElementsArray = array.splice(index,1)
  return {
    updatedGroups:array,
    removedGroup:deletedElementsArray[0],
  }
}

function getMatchingGroups(groups, triangle){
  let matchingGroupIndexes = []
  groups.forEach((group,index)=>{
    if(triangleMatchesAnotherInGroup(triangle, group)){
      matchingGroupIndexes.push(index)
    }
  })
  return matchingGroupIndexes
}

function triangleMatchesAnotherInGroup(triangle, group){
  let areTrianglesAdjacent = false
  group.forEach((triangle_i)=>{
    if(triangle_i && trianglesAreAdjacent(triangle_i, triangle)){
      areTrianglesAdjacent = true
    }
  })
  return areTrianglesAdjacent
}

function trianglesAreAdjacent(triangle_a, triangle_b){
  // console.log({triangle_a,triangle_b})
  //triangle are adjacent if two of the three points are the same
  // if all three match then theyre technically 'similar' not 'adjacent',
  // but that can still return 'true' here since that should be the same group
  const a1 = triangle_a[0]
  const a2 = triangle_a[1]
  const a3 = triangle_a[2]
  const b1 = triangle_b[0]
  const b2 = triangle_b[1]
  const b3 = triangle_b[2]

  let matchingPoints = 0
  if(pointsMatch(a1,b1)) matchingPoints++
  if(pointsMatch(a1,b2)) matchingPoints++
  if(pointsMatch(a1,b3)) matchingPoints++
  if(pointsMatch(a2,b1)) matchingPoints++
  if(pointsMatch(a2,b2)) matchingPoints++
  if(pointsMatch(a2,b3)) matchingPoints++
  if(pointsMatch(a3,b1)) matchingPoints++
  if(pointsMatch(a3,b2)) matchingPoints++
  if(pointsMatch(a3,b3)) matchingPoints++

  return matchingPoints > 1
}

function pointsMatch(point_a, point_b){
  return (
    point_a[0] === point_b[0] &&
    point_a[1] === point_b[1] &&
    point_a[2] === point_b[2]
  )
}

