import React from 'react'
import {Tooltip} from '@material-ui/core'
import {Info} from '@material-ui/icons'

export default function MaybeTruncatedValue(props){
  if(props.value.length > props.length){
    return(
      <Tooltip title={props.value}>
        <div style={{color: '#39C0ED', display: 'flex'}}>
          <div>
            {props.value.substring(0,props.length)}...
          </div>
          <div>
            <Info style={{ fontSize: '1em' }}/>
          </div>
        </div>
      </Tooltip>
    )
  } else {
    return(
      <div>
        {props.value}
      </div>
    )
  }
}
