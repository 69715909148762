import React,{Fragment,useContext} from 'react'
import AttachmentsList from './AttachmentsList'
import {calculateLockedProjectTotal} from '../../shared/calculateLockedProjectTotal.js'
import {calculateShipDateFromOrderDateAndLeadTimeDays} from '../ShipmentTracking/calculateDateFromBusinessDayOffset.js'
import CheckoutButtonDiv from './CheckoutButtonDiv.js'
import CloneProjectButton from './CloneProjectButton.js'
import DmeApprovedNotesAtom from './DmeApprovedNotesAtom.js'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import {
  getOrderDateFromCurrentTime,
  partAnalysisStatus,
  validateProjectForCheckout,
} from '../../utils'
import { NumberField } from "./NumberField.js"
import projectManagerIsUnassigned from '../Common/projectManagerIsUnassigned.js'
import { ShipPriceConfigAutocomplete } from './ShipPriceConfigAutocomplete'
import { SnackBarContext } from '../Common/SnackBarStore.js'
import USER_TYPES from '../../utils/USER_TYPES.js'

export default function CustomerProjectBar({
  cloneProject,
  checkoutHandler,
  currentlyLoading,
  downloadQuote,
  isCalculatingPrices,
  isNotDmeApproved,
  leadTimePricePairs,
  onChange,
  pauseCalculations,
  postProcessData,
  project,
  removeAttachment,
  reviewReasons,
  saveAndNavigateToCheckout,
  selectedLeadTime,
  setPauseCalculations,
  setSelectedLeadTime,
  submitForManualRfq,
  submittingForRfq,
  uploadNewAttachment,
  user,
}){
  return (
    <div>
      {!project.partGroups.length ?
       <NoPartsHeaderBar projectNumber={project.projectNumber}/>
       :
       <WithPartsHeaderBar
         cloneProject={cloneProject}
         checkoutHandler={checkoutHandler}
         currentlyLoading={currentlyLoading}
         downloadQuote={downloadQuote}
         isCalculatingPrices={isCalculatingPrices}
         isNotDmeApproved={isNotDmeApproved}
         leadTimePricePairs={leadTimePricePairs}
         onChange={onChange}
         pauseCalculations={pauseCalculations}
         postProcessData={postProcessData}
         project={project}
         removeAttachment={removeAttachment}
         reviewReasons={reviewReasons}
         saveAndNavigateToCheckout={saveAndNavigateToCheckout}
         selectedLeadTime={selectedLeadTime}
         setPauseCalculations={setPauseCalculations}
         setSelectedLeadTime={setSelectedLeadTime}
         submitForManualRfq={submitForManualRfq}
         submittingForRfq={submittingForRfq}
         uploadNewAttachment={uploadNewAttachment}
         user={user}
       />
      }
    </div>
  )
}

const NoPartsHeaderBar = ({projectNumber}) => {
  return(
    <div style={{ display: "flex", backgroundColor: "white", color: "black", flexDirection: "column", padding: "2vh 2vw"  }}>
      <Typography color="textPrimary" variant="h5">
        Project # {projectNumber}
      </Typography>
      <Typography align="center" variant="h5">
        Please upload a part below to get started with your new project!
      </Typography>
    </div>
  )
}

const WithPartsHeaderBar = ({
  cloneProject,
  currentlyLoading,
  downloadQuote,
  isCalculatingPrices,
  isNotDmeApproved,
  leadTimePricePairs,
  onChange,
  pauseCalculations,
  postProcessData,
  project,
  removeAttachment,
  reviewReasons,
  saveAndNavigateToCheckout,
  selectedLeadTime,
  setPauseCalculations,
  setSelectedLeadTime,
  submitForManualRfq,
  submittingForRfq,
  uploadNewAttachment,
  user,
}) => {

  //check for nested fields before presuming they exist and validate them
  const PROJECT_REASONS_EXIST = reviewReasons ? (reviewReasons.projectReasons ? (reviewReasons.projectReasons.length ? true : false) : false) : false
  const PART_REASONS_EXIST = reviewReasons ? (reviewReasons.partsReasons ? (reviewReasons.partsReasons.length ? true : false) : false) : false
  const doReviewReasonsExist = (PROJECT_REASONS_EXIST || PART_REASONS_EXIST)

  const isFrontEndActionStillNeeded = validateProjectForCheckout(project, selectedLeadTime, reviewReasons, postProcessData)
  // checkoutButtonDisabled is used to disable checkout button conditionally
  let checkoutButtonDisabled = submittingForRfq || isFrontEndActionStillNeeded || isCalculatingPrices
  const anyPartsInAnalysis = project.partGroups.some(part=>{return(!partAnalysisStatus(part, postProcessData.POST_PROCESS_OPTIONS).PARAM_EXTRACT_FINISHED)})
  const manualReviewRequired = (!anyPartsInAnalysis && doReviewReasonsExist)
  checkoutButtonDisabled = anyPartsInAnalysis ? true : checkoutButtonDisabled
  checkoutButtonDisabled = checkoutButtonDisabled || (user.type === USER_TYPES.DME && isNotDmeApproved)

  const openSnackBarWithMessage = useContext(SnackBarContext)
  const checkoutHandler = () => {
    console.log('here')
    if(user.type === USER_TYPES.DME && projectManagerIsUnassigned(project.projectManager)){
      openSnackBarWithMessage('Must assign a Project Manager before checking out')
      return
    }
    if(doReviewReasonsExist){  // If manual review
      submitForManualRfq()
    }
    else{ // If no manual review required route to checkout page
      saveAndNavigateToCheckout()
    }
  }

  return(
    <div className="project-bar" >
      <div style={{ "gridArea": "projn", display: "flex", alignItems: "center", gap: "1em" }}>
        <Typography variant="h5">
          Project # {project.projectNumber}
        </Typography>
        { user.type === USER_TYPES.DME ?
          <CloneProjectButton
            cloneProject={cloneProject}
          />
          : null }
      </div>
      <div style={{ "gridArea": "instr" }}>
        <Typography align="center" variant="h6">
            { manualReviewRequired ? "Enter Requested Lead Time" : "Guaranteed Ship Date" }
        </Typography>
      </div>
      <div style={{ "gridArea": "dmstc", display: "flex", justifyContent: "flex-end", gap: "2em"}}>
        { user.type === USER_TYPES.DME ?
        <FormControlLabel
          control={
            <Checkbox
              checked={pauseCalculations}
              color="primary"
              onChange={() => setPauseCalculations(!pauseCalculations)}
            />
          }
          label="Pause Calcs"
          labelPlacement="end"
          style={{justifyContent: 'end', width:'10em'}}
        />
         : null }
        <DomesticOnlyCheckbox
          isDomesticSourcing={project.isDomesticSourcing}
          onChange={onChange}
        />
      </div>
      <div style={{ "gridArea": "submt", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <CheckoutButtonDiv
          checkoutButtonDisabled={checkoutButtonDisabled}
          checkoutHandler={checkoutHandler}
          downloadQuote={downloadQuote}
          isCalculatingPrices={isCalculatingPrices}
          manualReview={manualReviewRequired}
          pauseCalculations={pauseCalculations}
          project={project}
          reviewReasons={reviewReasons}
          submittingForRfq={submittingForRfq}
        />
      </div>
      <div style={{ "gridArea": "attch" }}>
        <AttachmentsList
          attachments={project.attachments}
          currentlyLoading={currentlyLoading}
          isNotDmeReviewed={user.type === USER_TYPES.DME && !project.isDmeReviewed}
          projectNumber={project.projectNumber}
          removeAttachment={removeAttachment}
          uploadNewAttachment={uploadNewAttachment}
        />
      </div>
      <div style={{ "gridArea": "notes", display: "flex" }}>
        <NotesRow
          isDmeReviewed={project.isDmeReviewed}
          onChange={onChange}
          user={user}
          value={project ? (project.customerNotes ? project.customerNotes : "") : ""}
        />
      </div>
      <div style={{ "gridArea": "leadt", display: "flex", justifyContent: "center", alignItems: "center", height: "7em" }}>
        { LeadTimeIndicator() }
      </div>
    </div>
  )

  function LeadTimeIndicator() {
    function hasPartsNeedingUnitsOrRiskAcceptance(){
      const isMissingRequiredFields = project.partGroups.some(partGroup => !partGroup.part.units || !partGroup.part.riskAccepted)
      return isMissingRequiredFields
    }

    function determineMessageToDisplay(){
      if(anyPartsInAnalysis){
        return "Extracting parameters..."
      }
      else if(hasPartsNeedingUnitsOrRiskAcceptance()){
        return "To generate ship dates, please fill out all red highlighted part fields below"
      }
    }

    return (
      <div>
        { manualReviewRequired ?
          <Fragment>
            <NumberField
              name="leadTimeRequest"
              onChange={onChange}
              value={project ? (project.leadTimeRequest ? project.leadTimeRequest : "") : ""}
            />
            <Typography style={{ textAlign: "center" }}>Business Days</Typography>
          </Fragment>
          :
          <Fragment>
            {anyPartsInAnalysis && hasPartsNeedingUnitsOrRiskAcceptance() ?
              <Typography><b>{determineMessageToDisplay()}</b></Typography>
              :
              <ShipDateInput
                leadTimePricePairs={leadTimePricePairs}
                isCalculatingPrices={isCalculatingPrices}
                onChange={onChange}
                pauseCalculations={pauseCalculations}
                prices={project.calculations}
                project={project}
                selectedLeadTime={selectedLeadTime}
                setPauseCalculations={setPauseCalculations}
                setSelectedLeadTime={setSelectedLeadTime}
                user={user}
              />
            }
          </Fragment>
        }
      </div>
    )
  }
}

const DomesticOnlyCheckbox = ({isDomesticSourcing, onChange}) => {
  const onChangeCheckbox = e => {
    const target = {
      name: e.target.name,
      value: e.target.checked,
    }
    onChange({target})
  }

  return(
    <FormControlLabel
      control={
        <Checkbox
          checked={isDomesticSourcing}
          name="isDomesticSourcing"
          onChange={onChangeCheckbox}
        />
      }
      label="Requires Domestic Production"
      labelPlacement="end"
    />
  )
}

const NotesRow = (props) => {
  const { isDmeReviewed, onChange, user, value } = props
  return(
    <DmeApprovedNotesAtom
      isDmeReviewed={isDmeReviewed}
      label="Project Notes"
      name="customerNotes"
      notes={value}
      onChangeCheckbox={e => {
        const target = {
          name: e.target.name,
          value: e.target.checked,
        }
        onChange({target})
      }}
      onChangeNotes={onChange}
      user={user}
      variant="outlined"
    />
  )
}

const ShipDateInput = props => {
  return(
         <ShipDateAutoComplete
           {...props}
         />
  )
}

const ShipDateAutoComplete = props => {
  if(props.isCalculatingPrices){
    return (
      <CircularProgress />
    )
  }

  if(props.project.isLeadTimeLocked){
    const dateFormat = "MM/DD/YYYY"
    const orderDate = props.project.orderDate ? props.project.orderDate : getOrderDateFromCurrentTime()
    const leadTime = props.project.lockedSampleLeadTimeDays > 0 ? props.project.lockedSampleLeadTimeDays : props.project.lockedProductionLeadTimeDays
    const shipDate = calculateShipDateFromOrderDateAndLeadTimeDays(orderDate, dateFormat, leadTime).format(dateFormat)

    return (
      <div style={{display:'flex', flexDirection:'column', gap:'0.5em'}}>
        <div>This project has custom pricing enabled</div>
        <div>Price: ${calculateLockedProjectTotal(props.project)}</div>
        <div>Production Lead Time: {props.project.lockedProductionLeadTimeDays}</div>
        { props.project.lockedSampleLeadTimeDays ?
          <div>Samples Lead Time: {props.project.lockedSampleLeadTimeDays}</div>
          : null
        }
        <div>Ship Date: {shipDate}</div>
      </div>
    )
  }

  return (props.leadTimePricePairs && props.leadTimePricePairs.length ?
    <ShipPriceConfigAutocomplete  // NEW CODE
      hasSampleLeadTime={checkIfSampleFieldExists(props.leadTimePricePairs)}
      leadTimePricePairs={props.leadTimePricePairs}
      setValue={props.setSelectedLeadTime} //value => props.onChange({target:{name:'leadTimeRequest', value}})}
      value={props.selectedLeadTime}
    />
      : <CircularProgress />)
}

function checkIfSampleFieldExists(leadTimePricePairs) {
  return leadTimePricePairs.some(ltpp => ltpp.sampleLeadTime > 0)
}

