export function addNewMakeOrderToProject(project, tempActivityNumber) {
    let newMakeOrderObject = {
        tempActivityNumber: tempActivityNumber,
        makeOrderFrom: "",
        makeOrderTo: "",
        partBatches: [],
        ptpcPartBatches: [],
    }
    project.makeOrders = project.makeOrders.concat([newMakeOrderObject])
    return project
}
