// Change this file for react hot-loading-toggling
import guards from './frgs.json'


function validateGuardsAsBoolean(guards) {
  for (const [_, value] of Object.entries(guards)) { // eslint-disable-line no-unused-vars
    if (!(value === true || value === false)) {
      throw new Error("FRGs must be Boolean")
    }
  }
}
validateGuardsAsBoolean(guards)

function setAllKeysToFalse(obj) {
  let newobj = {}
  for (const key in obj) { // eslint-disable-line no-unused-vars
    newobj[key] = false
  }
  return newobj
}

let exportedGuards = guards // must rename at some point to prevent importing and export the same variable name
if (process.env.NODE_ENV === "prod" || process.env.REACT_APP_DISABLE_ALL_GUARDED_FEATURES === 'true') {
  exportedGuards = setAllKeysToFalse(guards)
}

export default exportedGuards

// USAGE:
// const frg = require('.../feature-release-guards.js').default
// import frg from '.../feature-release-guards.js'
// if (frg.appHeaderBar) {
//   // new code
// } else {
//   // old code
// }
