import React, {useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default function CloneProjectButton({cloneProject}){
  const [ isCloningProject, setIsCloningProject ] = useState(false)
  const [ isAlertDialogOpen, setIsAlertDialogOpen ] = useState(false)

  const handleClickOpen = () => {
    setIsAlertDialogOpen(true)
  }

  const handleClose = () => {
    setIsAlertDialogOpen(false)
  }

  const handleSubmit = () => {
    setIsCloningProject(true)
    cloneProject()
      .finally(() => setIsCloningProject(false))
  }

  return (
    <>
      <Button onClick={handleClickOpen}>
        Clone
      </Button>
      <AlertDialog
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isCloningProject={isCloningProject}
        open={isAlertDialogOpen}
      />
    </>
  )
}

function AlertDialog({ handleClose, handleSubmit, isCloningProject, open }){
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Clone Project
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will create a new project with the same parts, and then redirect you to that page.
          <br/><br/>
          Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center', gap: '3em' }}>
        <Button
          autoFocus
          disabled={isCloningProject}
          onClick={handleClose}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={isCloningProject}
          onClick={handleSubmit}
          variant="contained"
        >
          { isCloningProject ? 'Cloning...' : 'Clone Project' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
