import React, {useContext,useState} from 'react'
import { Button, Card, FormControlLabel, InputAdornment, MenuItem, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import AttachmentsModal from './AttachmentsModal.js'
import ChangeCustomerButton from './ChangeCustomerButton.js'
import downloadProjectFileHistory from './downloadProjectFileHistory.js'
import GdriveButtons from '../Common/GdriveButtons.js'
import { NumberField } from "./NumberField"
import {SnackBarContext} from '../Common/SnackBarStore'
import { NotesAtom } from './partCardAtoms'
import projectHasMarkupValues from './projectHasMarkupValues.js'
import { TextButtonWithIcon } from "./partCardAtoms/TextButtonWithIcon.js"
import DownloadQuoteButton from './DownloadQuoteButton.js'
import DownloadAllProjectFilesButton from './DownloadAllProjectFilesButton.js'
import DownloadAllQuotesButton from './DownloadAllQuotesButton.js'
import DownloadCsvButton from './DownloadCsvButton.js'
import DownloadInternalFilesButton from './DownloadInternalFilesButton.js'
import projectManagerIsUnassigned from '../Common/projectManagerIsUnassigned.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
var moment = require('moment-business-days')

export default function DmeProjectBar({
  deleteInternalFile,
  downloadAllProjectFiles,
  downloadAllQuotes,
  downloadInternalFiles,
  downloadQuote,
  isCalculatingPrices,
  isMarkupProject,
  isNotDmeApproved,
  onChange,
  priceLockProject,
  project,
  quotePrefix,
  reviewReasons,
  selectedLeadTime,
  sendProjectToCustomer,
  sendQuoteToLogistics,
  setIsMarkupProject,
  setQuotePrefix,
  setUserAsDme,
  updateCustomer,
  uploadInternalFile,
  user,
}){
  const openSnackBarWithMessage = useContext(SnackBarContext)

  return (
    <div className="dme-project-bar">
      <CustomerSection
        deleteInternalFile={deleteInternalFile}
        downloadAllQuotes={downloadAllQuotes}
        downloadAllProjectFiles={downloadAllProjectFiles}
        downloadInternalFiles={downloadInternalFiles}
        downloadQuote={downloadQuote}
        isCalculatingPrices={isCalculatingPrices}
        isNotDmeApproved={isNotDmeApproved}
        onChange={onChange}
        openSnackBarWithMessage={openSnackBarWithMessage}
        project={project}
        quotePrefix={quotePrefix}
        reviewReasons={reviewReasons}
        sendProjectToCustomer={sendProjectToCustomer}
        sendQuoteToLogistics={sendQuoteToLogistics}
        setQuotePrefix={setQuotePrefix}
        setUserAsDme={setUserAsDme}
        updateCustomer={updateCustomer}
        uploadInternalFile={uploadInternalFile}
        user={user}
      />
      {project.isLeadTimeLocked ? null :
      <MarkupSection
        isMarkupProject={isMarkupProject}
        onChange={onChange}
        openSnackBarWithMessage={openSnackBarWithMessage}
        project={project}
        setIsMarkupProject={setIsMarkupProject}
      />
      }
      <ProjectLockSection
        isCalculatingPrices={isCalculatingPrices}
        onChange={onChange}
        openSnackBarWithMessage={openSnackBarWithMessage}
        priceLockProject={priceLockProject}
        project={project}
        selectedLeadTime={selectedLeadTime}
      />
    </div>
  )
}

function CustomerSection({
  deleteInternalFile,
  downloadAllProjectFiles,
  downloadAllQuotes,
  downloadInternalFiles,
  downloadQuote,
  isCalculatingPrices,
  isNotDmeApproved,
  onChange,
  openSnackBarWithMessage,
  project,
  quotePrefix,
  reviewReasons,
  sendProjectToCustomer,
  sendQuoteToLogistics,
  setQuotePrefix,
  setUserAsDme,
  updateCustomer,
  uploadInternalFile,
  user,
}){
  return (
    <div className="dme-project-bar-customer-section">
      <div style={{gridArea: 'custr'}}>
        <CustomerData
          onChange={onChange}
          project={project}
          setUserAsDme={setUserAsDme}
          updateCustomer={updateCustomer}
        />
      </div>
      <div style={{gridArea: 'btton', display: 'flex', flexDirection: 'column', gap: '1em'}}>
        <div style={{display: 'flex', gap: '1em', justifyContent: 'end'}}>
          <div>
            <div style={{display:'flex', gap:'1em'}}>
              <GdriveButtons
                openSnackBarWithMessage={openSnackBarWithMessage}
                project={project}
              />
            </div>
          </div>
          <div>
            <MaybeSendCustomerQuoteButton
              isCalculatingPrices={isCalculatingPrices}
              isNotDmeApproved={isNotDmeApproved}
              openSnackBarWithMessage={openSnackBarWithMessage}
              project={project}
              reviewReasons={reviewReasons}
              sendProjectToCustomer={sendProjectToCustomer}
              sendQuoteToLogistics={sendQuoteToLogistics}
              user={user}
            />
          </div>
        </div>
        <Card style={{padding: '0.5em', gap: '0.5em', display: 'flex', flexDirection: 'column'}} variant="outlined">
          <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
            <Typography variant="subtitle1">
              Customer Files
            </Typography>
            <TextField
              onChange={e => setQuotePrefix(e.target.value)}
              label="Quote Prefix"
              size="small"
              variant="outlined"
              value={quotePrefix}
            />
            <DownloadQuoteButton
              color="primary"
              disabled={isCalculatingPrices}
              downloadQuote={downloadQuote}
            />
            <DownloadAllQuotesButton
              color="primary"
              disabled={isCalculatingPrices}
              downloadAllQuotes={downloadAllQuotes}
            />
            <DownloadAllProjectFilesButton
              disabled={isCalculatingPrices}
              downloadAllProjectFiles={downloadAllProjectFiles}
            />
            <DownloadCsvButton
              project={project}
            />
          </div>
        </Card>
        <InternalFilesCard
          deleteInternalFile={deleteInternalFile}
          disabled={isCalculatingPrices}
          downloadInternalFiles={downloadInternalFiles}
          project={project}
          uploadInternalFile={uploadInternalFile}
        />
      </div>
      <div style={{gridArea: 'notes'}}>
        <NotesAtom
          label="Supplier Notes"
          name="supplierNotes"
          onChange={onChange}
          value={project.supplierNotes}
        />
      </div>
    </div>
  )
}

function InternalFilesCard({ deleteInternalFile, disabled, downloadInternalFiles, project, uploadInternalFile }){
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const prefix = project.internalFiles.length > 0 ? '' : 'Click to Upload'
  const internalFilesString = prefix + project.internalFiles.map(f => f.fileName).join(', ')
  return(
    <Card style={{padding: '0.5em', gap: '0.5em', display: 'flex', flexDirection: 'column'}} variant="outlined">
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', justifyContent: 'start', gap: '1em'}}>
          <Typography variant="subtitle1">
            Internal Files
          </Typography>
          <TextButtonWithIcon onClick={() => setIsModalOpen(true)} buttonText={internalFilesString} disabled={disabled} isNotDmeReviewed={false}/>
        </div>
        <div style={{display: 'flex', justifyContent: 'end', gap: '1em'}}>
          <DownloadInternalFilesButton
            disabled={disabled}
            downloadInternalFiles={downloadInternalFiles}
          />
          <Button
            color="primary"
            onClick={() => downloadProjectFileHistory(project)}
            variant="contained"
          >
            Download File History
          </Button>
        </div>
      </div>
      <InternalFilesModal/>
    </Card>
  )

  function InternalFilesModal(){
    return (
      <AttachmentsModal
        attachments={project.internalFiles}
        currentlyLoading={false}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        partName="Internal File"
        projectNumber={'12345'}
        removeAttachment={deleteInternalFile}
        uploadParts={uploadInternalFile}
      />
    )
  }
}

function CustomerData({ onChange, project, setUserAsDme, updateCustomer }){
  return (
    <div>
      <div style={{ display: 'flex', gap: '2em' }}>
        <Typography> <b>Customer:</b> {`${project.customer.customerName} - ${project.customer.email}`} </Typography>
        <ChangeCustomerButton
          updateCustomer={updateCustomer}
        />
      </div>
      {project.customer.customerNotes ?
       <div style={{display:'flex'}}>
         <Typography style={{background: 'yellow'}}> <b>Customer Notes:</b> {project.customer.customerNotes} </Typography>
       </div>
       : null}
      <CompanyData
        company={project.customer.company}
      />
      <div style={{display: 'flex', gap: '3em'}}>
        <Typography> <b>Project Manager:</b> {project.projectManager.name.first} </Typography>
        <Button
          color="primary"
          onClick={() => setUserAsDme()}
          size="small"
          variant="contained"
        >
          Make me the Project Manager
        </Button>
      </div>
      <div style={{display: 'flex', gap: '0.5em', alignItems: 'baseline'}}>
        <b>Order Date:</b>
        <TextField
          onChange={e => {
            const newDate = e.target.value ? moment(e.target.value, "YYYY-MM-DD").format("MM/DD/YYYY") : ''
            const target = {
              name: "orderDate",
              value: newDate
            }
            onChange({target})
          }
          }
          placeholder="mm/dd/yyyy"
          type="date"
          value={moment(project.orderDate, "MM/DD/YYYY").format("YYYY-MM-DD")}
        />
      </div>
    </div>
  )
}

function CompanyData({ company }){
  if(!company){
    return 'No Company'
  }
  return (
    <>
      <Typography> <b>Company:</b> {company.companyName} </Typography>
      {company.companyNotes ?
       <div style={{display:'flex'}}>
         <Typography style={{background: 'yellow'}}> <b>Company Notes:</b> {company.companyNotes} </Typography>
       </div>
       : null}
      <Typography>
        <b>Company Credit Terms:</b> {company.creditTerms}<br/>
      </Typography>
      <Typography>
        <b>Company Credit Limit:</b> {company.creditLimit}
      </Typography>
    </>
  )
}

function MaybeSendCustomerQuoteButton({ isCalculatingPrices, isNotDmeApproved, openSnackBarWithMessage, project, sendProjectToCustomer, sendQuoteToLogistics, reviewReasons, user }){
  const [isSending, setIsSending] = useState(false)
  const isManualRfq = reviewReasons.projectReasons.length > 0

  if(project.isLeadTimeLocked) return null // don't display if lead time is locked

  const buttonText = isManualRfq ? 'Request Quote from Logistics' : 'Send Customer Quote Email'
  const onClick = () => {
    if(user.type === USER_TYPES.DME && projectManagerIsUnassigned(project.projectManager)){
      return openSnackBarWithMessage('Must assign a Project Manager before sending to customer')
    }
    const fxn = isManualRfq ? sendQuoteToLogistics : sendProjectToCustomer
    setIsSending(true)
    fxn()
      .catch(e => openSnackBarWithMessage(e.message))
      .finally(() => setIsSending(false))
  }

  const ButtonThunk = () =>
    <Button
      color="primary"
      disabled={isCalculatingPrices || isSending}
      onClick={onClick}
      variant="contained"
    >
      {isSending ? 'Sending...' : buttonText}
    </Button>

  if(isNotDmeApproved){
    return (
      <Tooltip title={<Typography>DME must approve everything before sending back to customer</Typography>}>
        <div>
          {ButtonThunk()}
        </div>
      </Tooltip>
    )
  }
  return ButtonThunk()
}

function MarkupSection({ isMarkupProject, onChange, openSnackBarWithMessage, project, setIsMarkupProject }){
  // if any values exist, it's just always on. A click otherwise says can't turn off
  // if values don't exist, allow to toggle on locally to enter an initial value
  function toggleMarkupMaybe(){
    if(isMarkupProject){
      if(projectHasMarkupValues(project)){
        openSnackBarWithMessage("Project or parts have markup data. Please remove it if don't want this to be a markup project")
      } else {
        setIsMarkupProject(false)
      }
    } else {
      setIsMarkupProject(true)
    }
  }

  return (
    <div className="dme-project-bar-markup-section">
      <div>
        <FormControlLabel
          checked={isMarkupProject}
          color="secondary"
          control={<Switch/>}
          label="Markup Quote"
          labelPlacement="start"
          onChange={() => toggleMarkupMaybe()}
          style={{marginLeft:'0em'}}
        />
      </div>
      {isMarkupProject ?
       <div className="dme-project-bar-markup-sub-section">
         <div>
           <TextField
             label="Markup Supplier"
             name="markupSupplierId"
             onChange={onChange}
             select
             style={{minWidth:"16em"}}
             variant="outlined"
             value={project.markupSupplierId}
           >
             <MenuItem value={'autotiv_economy'}> Autotiv Economy </MenuItem>
             <MenuItem value={'3dPrinting_china_economy'}> 3D Printing China Economy </MenuItem>
             <MenuItem value={'lowVolumeMachining_china_economy'}> Low Volume Machining China Economy </MenuItem>
             <MenuItem value={'lowVolumeInjectionMolding_china_economy'}> Low Volume Injection Molding China Economy </MenuItem>
           </TextField>
         </div>

         <div>
           <NumberField
             label="Markup Lead Time Quantity 1"
             name="markupLeadTimeQuantity1"
             onChange={onChange}
             style={{minWidth:"16em"}}
             variant="outlined"
             value={project.markupLeadTimeQuantity1}
           />
         </div>

         <div>
           <NumberField
             label="Markup Lead Time Quantity X"
             name="markupLeadTimeQuantityX"
             onChange={onChange}
             style={{minWidth:"16em"}}
             variant="outlined"
             value={project.markupLeadTimeQuantityX}
           />
         </div>
       </div>
       : null}
    </div>
  )
}

function ProjectLockSection({ isCalculatingPrices, onChange, openSnackBarWithMessage, priceLockProject, project, selectedLeadTime }){
  const toggleLeadTimeLockMaybe = (value) => {
    if(value === true){
      if(!selectedLeadTime){
        openSnackBarWithMessage("Please select a lead time to price lock to")
        return
      }

      priceLockProject()
    } else {
      const target = {
        name: 'isLeadTimeLocked',
        value,
      }
      onChange({target})
    }
  }

  return (
    <div className="dme-project-bar-project-lock-section">
      <div>
        <FormControlLabel
          checked={project.isLeadTimeLocked}
          color="secondary"
          control={<Switch/>}
          disabled={isCalculatingPrices}
          label="Price and Lead Time Lock"
          labelPlacement="start"
          onChange={e => toggleLeadTimeLockMaybe(e.target.checked)}
          style={{marginLeft:'0em'}}
        />
      </div>
      {project.isLeadTimeLocked ?
       <div className="dme-project-bar-project-lock-sub-section">
         <ProductionLeadTime
           lockedProductionLeadTimeDays={project.lockedProductionLeadTimeDays}
           onChange={onChange}
         />
         <SampleLeadTime
           lockedSampleLeadTimeDays={project.lockedSampleLeadTimeDays}
           onChange={onChange}
         />
         <ExpediteFee
           lockedLeadTimeExpediteFee={project.lockedLeadTimeExpediteFee}
           onChange={onChange}
         />
       </div>
       : null }
    </div>
  )
}

function ProductionLeadTime({ lockedProductionLeadTimeDays, onChange }){
  return (
    <div>
      <NumberField
         InputProps={{
           style: {width: '15em'},
           endAdornment: <InputAdornment position="end">Business Days</InputAdornment>,
          }}
        label="Production Lead Time"
        name="lockedProductionLeadTimeDays"
        onChange={onChange}
        value={lockedProductionLeadTimeDays}
        variant="outlined"
      />
    </div>
  )
}

function SampleLeadTime({ lockedSampleLeadTimeDays, onChange }){
  return (
    <div>
      <NumberField
        InputProps={{
          style: {width: '15em'},
          endAdornment: <InputAdornment position="end">Business Days</InputAdornment>,
        }}
        label="Samples Lead Time"
        name="lockedSampleLeadTimeDays"
        onChange={onChange}
        value={lockedSampleLeadTimeDays}
        variant="outlined"
      />
    </div>
  )
}

function ExpediteFee({ lockedLeadTimeExpediteFee, onChange }){
  return (
    <div>
      <NumberField
        label="Expedite Fee"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          style: {width: '12em'},
        }}
        name="lockedLeadTimeExpediteFee"
        onChange={onChange}
        variant="outlined"
        value={lockedLeadTimeExpediteFee}
      />
    </div>
  )
}
