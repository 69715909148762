const axios = require('axios')
const { logUnexpected } = require('../../../../utils/logUnexpected.js')

export async function directDownload(url) {
  let response
  try {
    response = await directDownloadRequest(url)
  } catch (err) {
    logUnexpected(err, { url }, 'directDownloadRequest')
    throw err
  }

  let result
  try {
    result = parseDirectDownloadResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseDirectDownloadResponse', { url })
    throw err
  }

  return result
}

async function directDownloadRequest(temporaryS3Url) {
  const response = await axios({
    responseType: 'blob',
    method: 'GET',
    validateStatus: () => true,
    url: temporaryS3Url,
  })
  return { status: response.status, data: response.data }
}

function parseDirectDownloadResponse(response) {
  if (response.status === 200) {
    if (response.data) return response.data

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
