export function convertDimensionsEtcInPartGroup(ptg) {
  const INCHES_TO_MM = 25.4
  const MM_TO_MM = 1 // just to be explicit

  let P = ptg.part

  const ONE_DIM_MULITPLIER = P.units === 'inches' ? INCHES_TO_MM : MM_TO_MM

  const M1 = ONE_DIM_MULITPLIER
  const M2 = M1 * M1
  const M3 = M1 * M1 * M1

  P.xDim = P.xDimUnits * M1
  P.yDim = P.yDimUnits * M1
  P.zDim = P.zDimUnits * M1

  P.partSurfaceArea = P.partSurfaceAreaUnits * M2

  P.partVolume = P.partVolumeUnits * M3

  P.xDim = P.xDim.toFixed(2)
  P.yDim = P.yDim.toFixed(2)
  P.zDim = P.zDim.toFixed(2)
  P.partSurfaceArea = P.partSurfaceArea.toFixed(2)
  P.partVolume = P.partVolume.toFixed(2)

  return ptg
}
