import { useState } from 'react'
import { Button } from '@material-ui/core'

export default function DownloadAllProjectFilesButton({ disabled, downloadAllProjectFiles }){
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownloadAllProjectFilesClick = () => {
    setIsDownloading(true)
    downloadAllProjectFiles()
      .finally(() => setIsDownloading(false))
  }

  return (
    <Button
      color="primary"
      disabled={disabled || isDownloading}
      onClick={handleDownloadAllProjectFilesClick}
      variant="contained"
    >
      { isDownloading ? 'Downloading...' : 'Download Files' }
    </Button>
  )
}
