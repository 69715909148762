export const DOMESTIC_SUPPLIER_ARRAY = [
  { id: "5abbf7222045bb474b63d3a5", identifier: "Autotiv" },
  { id: "5abbf71f2045bb474b63d38a", identifier: "QuickParts" },
  { id: "5abbf71f2045bb474b63d38d", identifier: "QuickParts Tulsa" },
  { id: "5abbf7202045bb474b63d393", identifier: "3D Printer Experience" },
  { id: "5abbf7202045bb474b63d396", identifier: "Incept 3D" },
  { id: "5abbf71e2045bb474b63d386", identifier: "Varia 3D" },
  { id: "5abbf7212045bb474b63d39c", identifier: "SIMT" },
  { id: "5abbf7222045bb474b63d3a8", identifier: "ProtoFinish" },
  { id: "5abbf7232045bb474b63d3ab", identifier: "Bills Garage" },
  { id: "5abbf7232045bb474b63d3ae", identifier: "3DPA" },
  { id: "5e4c5bff601b6e00155069da", identifier: "Additive-Services" },
  { id: "60020445097960001bf14d22", identifier: "Allied Power Group" },
  { id: "5abbf7242045bb474b63d3b7", identifier: "APM Mach" },

  { id: "5e41cf652c4823001b7244f0", identifier: "BestMachineInc" },
  { id: "5abbf7242045bb474b63d3ba", identifier: "Cimquest" },
  { id: "5be20d25a450510d6dd1c996", identifier: "Custom Welding & Fabrication, Inc." },
  { id: "5abbf7242045bb474b63d3bd", identifier: "DEKA" },
  { id: "5c2e3910200f694be0136364", identifier: "DI Labs" },
  { id: "5abbf7252045bb474b63d3c0", identifier: "DigitizeDesign" },
  { id: "5abbf7252045bb474b63d3c3", identifier: "Diversified Manufacturing Technologies" },
  { id: "5abbf7262045bb474b63d3c6", identifier: "East Tower Design" },
  { id: "5abbf7262045bb474b63d3c9", identifier: "Erwin Precision" },
  { id: "5abbf7262045bb474b63d3cc", identifier: "Fathom" },
  { id: "5e595f82918d8d0015533143", identifier: "Forecast3D" },
  { id: "5abbf7272045bb474b63d3cf", identifier: "Goddard" },
  { id: "5abbf7272045bb474b63d3d2", identifier: "GPI" },
  { id: "5abbf7272045bb474b63d3d5", identifier: "i3D MFG" },
  { id: "5b204ea4e93c37464cbc21ab", identifier: "Laser Imaging" },
  { id: "5abbf7282045bb474b63d3df", identifier: "Nw Rapid" },
  { id: "5abbf7282045bb474b63d3e2", identifier: "Precision Expedited" },
  { id: "5abbf7292045bb474b63d3e8", identifier: "Protolabs" },
  { id: "5abbf7292045bb474b63d3eb", identifier: "Prototek" },
  { id: "5d156e7977f930000ebe2a21", identifier: "RapidPSI" },
  { id: "6217baa5e070fa001e830c96", identifier: "Realize Inc" },
  { id: "5dbaf41a1558cf0015c3ab00", identifier: "Repliform" },
  { id: "62df38dc89f42700211a0de9", identifier: "Saber Machine Design Corp." },
  { id: "5abbf72a2045bb474b63d3ee", identifier: "Seacoast Machine" },
  { id: "5abbf72a2045bb474b63d3f1", identifier: "SNL Creative" },
  { id: "5abbf72a2045bb474b63d3f4", identifier: "STP Performance Coating" },
  { id: "5abbf72b2045bb474b63d3f7", identifier: "Stratasys" },
  { id: "5abbf72b2045bb474b63d3fd", identifier: "Twin City EDM & Mfg." },
  { id: "5abbf72c2045bb474b63d400", identifier: "Vaupell" },
]

export const INTERNATIONAL_SUPPLIER_ARRAY = [
  { id: "5abbf7202045bb474b63d390", identifier: "SunPe" },
  { id: "5abbf7232045bb474b63d3b1", identifier: "AdvPrototype" },
  { id: "5abbf7242045bb474b63d3b4", identifier: "Agile" },
  { id: "620ecf4b5d855c001ed09f59", identifier: "Better-Mold Industrial" },
  { id: "5f625373d33ff1001529d127", identifier: "BOYI Prototypes" },
  { id: "606dec3e93d08d001dc1a571", identifier: "Danke Mold" },
  { id: "5df3a01bad36cc0019cd734f", identifier: "Deed 3D" },
  { id: "5e555f66da277a0015446053", identifier: "dg-jianqiaocnc" },
  { id: "5e555f1cd4110e0015973ea8", identifier: "fast-proto" },
  { id: "61c9bb0c213f19001f5f0d75", identifier: "Great Jump" },
  { id: "5e555f3afed8b50015dc9207", identifier: "grefeemold" },
  { id: "5e555fc1deb4ce0015c4ee34", identifier: "hlhprototypes" },
  { id: "5e555f50c201380017a58b44", identifier: "jacprototypes" },
  { id: "5d09441b21c551000ae8c604", identifier: "JasonMould" },
  { id: "5c095fb6b2c7af10c7b0fb97", identifier: "Kings 3D" },
  { id: "5e555f7e5b6aa70016d1464a", identifier: "kintec-machining" },
  { id: "5ea85cadb0ccf00015591677", identifier: "Lam Technology Co" },
  { id: "61f16ca727f200001f5cff70", identifier: "Lucassen Precision" },
  { id: "5f5a4aceb980600015eb4a6c", identifier: "Maquinados Josar" },
  { id: "5ee7d76de6a10400186d018a", identifier: "maquinadosmty" },
  { id: "5e555ed7a6272c00150b643a", identifier: "myttechnology" },
  { id: "5abbf7282045bb474b63d3dc", identifier: "None" },
  { id: "5d1cecdffc9d440010af64dd", identifier: "Northern Industrial Design" },
  { id: "60353204956852001d2912d1", identifier: "Osking Industries" },
  { id: "61f992c5f20e27001fe5b531", identifier: "Power Moulding Limited" },
  { id: "5abbf7292045bb474b63d3e5", identifier: "Proto3000" },
  { id: "5e555fa2071d7a0016dea0d3", identifier: "rapidprototyping" },
  { id: "5d156e98f82b94000a6c9a95", identifier: "RP Group" },
  { id: "5c09969a44839211564527a1", identifier: "Seal-RP" },
  { id: "61435bf154603d001e20ce48", identifier: "Selead Prototype Technology" },
  { id: "5d80c755f83f800013fbe961", identifier: "SENSES" },
  { id: "634c5b12f626300022a21759", identifier: "Shenzhen Bellflower Technology" },
  { id: "61436a48192537001f40cb6f", identifier: "Shiny Prototype Technology" },
  { id: "5daf19f95b345100193a8f30", identifier: "Team Rapid Tooling" },
  { id: "618005fc7e446d001ff42a73", identifier: "Top Rapid" },
  { id: "5da631823aec5e0018aa957e", identifier: "Universe Plastic" },
  { id: "5abbf72c2045bb474b63d403", identifier: "Volkan" },
  { id: "5abbf72c2045bb474b63d406", identifier: "Xometry" },
  { id: "5ed69f6fec95690015167e19", identifier: "ZTL Tech" },
]

export const CLOSED_SUPPLIER_ARRAY = [
  { id: "5abbf7212045bb474b63d39f", identifier: "Form 3D" },
  { id: "5abbf7282045bb474b63d3d8", identifier: "Impressive Prototypes" },
  { id: "5abbf72b2045bb474b63d3fa", identifier: "TMP 3D" },
]

export const retrieveSupplierObject = supplierId => {
  const SUPPLIER_ARRAY = DOMESTIC_SUPPLIER_ARRAY.concat(INTERNATIONAL_SUPPLIER_ARRAY).concat(CLOSED_SUPPLIER_ARRAY)
  let translation = SUPPLIER_ARRAY.find(supplier =>{
    return (supplier.id === supplierId)
  })
  return translation
}
