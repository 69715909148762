export function generateSelectComponentOptions(process, materialList) {
    let selectComponentOptions
    if (process) {
        const materialsListFormattedForSelectComponent = materialList.map(formatMaterialsForSelectComponent)
        selectComponentOptions = materialsListFormattedForSelectComponent
    }
    else {
        const requiredPlaceholderIfNoOptionsAreAvailable = [{ value: "", label: "" }]
        selectComponentOptions = requiredPlaceholderIfNoOptionsAreAvailable
    }
    return selectComponentOptions
}

function formatMaterialsForSelectComponent(material) {
    return {
        label: material.display,
        value: material.id,
        whiteListed: material.whiteListed,
    }
}
