import ACTIVITY_TYPES from '../ACTIVITY_TYPES.js'
import { TO_FROM_TYPES } from '../supplierTypeArrays.js'

export function leftToMake(project, activities, partGroupId) {
    let partGroup = getPartGroup(project.partGroups, partGroupId)
    let totalPartGroupQuantity = partGroup.quantity

    let quantityOfPartAlreadyMade = calculateMadePartQuantity(activities, partGroupId)
    let remaining = totalPartGroupQuantity - quantityOfPartAlreadyMade
    return remaining
}

export function leftToShip(project, activities, partGroupId) {
    let partGroup = getPartGroup(project.partGroups, partGroupId)

    if(partGroup.processId === 'injMoldingPart' || partGroup.processId === 'casting'){
      return 'N/A'
    }

    let totalPartGroupQuantity = partGroup.quantity

    const quantityOfPartAlreadyShipped = calculateShippedPartQuantity(activities, partGroupId)
    let remaining = totalPartGroupQuantity - quantityOfPartAlreadyShipped
    return remaining
}

export function leftToMakePtpc(project, activities, productionToolPartConfigurationId){
  const ptpc = getPtpc(project.partGroups, productionToolPartConfigurationId)
  const totalPtpcQuantity = ptpc.quantity

  const quantityOfPtpcAlreadyMade = calculateMadePtpcQuantity(activities, productionToolPartConfigurationId)
  const remaining = totalPtpcQuantity - quantityOfPtpcAlreadyMade
  return remaining
}

export function leftToShipPtpc(project, activities, productionToolPartConfigurationId){
  const ptpc = getPtpc(project.partGroups, productionToolPartConfigurationId)
  const totalPtpcQuantity = ptpc.quantity

  const quantityOfPtpcAlreadyShipped = calculateShippedPtpcQuantity(activities, productionToolPartConfigurationId)
  const remaining = totalPtpcQuantity - quantityOfPtpcAlreadyShipped
  return remaining
}

function getPtpc(partGroups, productionToolPartConfigurationId){
  const ptpcs = partGroups.flatMap(ptg => ptg.productionToolPartConfigurations)
  const ptpcMaybe = ptpcs.find(ptpc => ptpc.productionToolPartConfigurationId === productionToolPartConfigurationId)

  if(!ptpcMaybe){
    throw new Error("Production Tool Part Configuration not found")
  }

  return ptpcMaybe
}

function calculateMadePtpcQuantity(activities, productionToolPartConfigurationId){
  return activities.map(
    activity => extractMadePtpcQuantity(activity, productionToolPartConfigurationId)
  )
    .reduce(sumAllElements, 0)
}

function extractMadePtpcQuantity(activity, productionToolPartConfigurationId){
    if (activity.activityFrom === TO_FROM_TYPES.supplier) {
        return calculatePtpcQuantityInActivity(activity, productionToolPartConfigurationId)
    }
    else {
        return 0
    }
}

function calculateShippedPtpcQuantity(activities, productionToolPartConfigurationId){
  return activities.map(
    activity => extractShippedPtpcQuantity(activity, productionToolPartConfigurationId)
  )
    .reduce(sumAllElements, 0)
}

function extractShippedPtpcQuantity(activity, productionToolPartConfigurationId){
  if (activity.activityTo === TO_FROM_TYPES.customer) {
    return calculatePtpcQuantityInActivity(activity, productionToolPartConfigurationId)
  }
  else {
    return 0
  }
}

function calculatePtpcQuantityInActivity(activity, productionToolPartConfigurationId){
  const activityType = getActivityType(activity)
  if(activityType === ACTIVITY_TYPES.REPRINT || activityType === ACTIVITY_TYPES.SAMPLES){
    return 0
  }

  const partBatch = activity.partBatches.find(
    partBatch => partBatch.productionToolPartConfigurationId === productionToolPartConfigurationId
  )
  if (partBatch && partBatch.quantity > 0) {
    return partBatch.quantity
  }
  else {
    return 0
  }
}

function getPartGroup(partGroups, partGroupNumber) {
    const partGroup = partGroups.find(
        partGroup => partGroup.partGroupNumber === partGroupNumber
    )
    if (partGroup === undefined) {
        throw new Error("Part Group not found in partGroups")
    }
    return partGroup
}

function calculateMadePartQuantity(activities, partGroupId) {
    return activities.map(
            activity => extractMadePartQuantity(activity, partGroupId)
        )
        .reduce(sumAllElements, 0)
}

function extractMadePartQuantity(activity, partGroupNumber) {
    if (activity.activityFrom === TO_FROM_TYPES.supplier) {
        return calculatePartQuantityInActivity(activity, partGroupNumber)
    }
    else {
        return 0
    }
}

function calculateShippedPartQuantity(activities, partGroupId) {
    return activities.map(
            activity => extractShippedPartQuantity(activity, partGroupId)
        )
        .reduce(sumAllElements, 0)
}

function extractShippedPartQuantity(activity, partGroupNumber) {
    const includesShippedParts = getActivityType(activity) === ACTIVITY_TYPES.NORMAL

    if (activity.activityTo === TO_FROM_TYPES.customer && includesShippedParts) {
        return calculatePartQuantityInActivity(activity, partGroupNumber)
    }
    else {
        return 0
    }
}

function sumAllElements(a, b) {
    return +a + +b
}

function calculatePartQuantityInActivity(activity, partGroupNumber) {
    const activityType = getActivityType(activity)

    const partBatch = activity.partBatches.find(
        partBatch => partBatch.partGroup === partGroupNumber
    )
    if (partBatch && partBatch.quantity > 0) {
        return partBatch.quantity
    }
    else {
        return 0
    }
}

function getActivityType(activity){
  if(activity.makeOrder && activity.makeOrder.reprintBool){
    return ACTIVITY_TYPES.REPRINT
  }
  if(activity.makeOrder && activity.makeOrder.isSamplesOrder){
    return ACTIVITY_TYPES.SAMPLES
  }
  return ACTIVITY_TYPES.NORMAL
}
