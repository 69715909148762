import axios from 'axios'
import downloadFileToLocalMachineFromBlob from '../../utils/downloadFileToLocalMachineFromBlob.js'
const config = require('../../config.js')

export default async function downloadProjectFileHistory(project){
  const fileName = `${project.projectNumber}-files-history.zip`
  const zipFile = await downloadProjectFileHistoryAsZip(project.projectNumber, project.partGroups)
  downloadFileToLocalMachineFromBlob(zipFile, fileName)
}

async function downloadProjectFileHistoryAsZip(projectNumber, partGroups){
  const response = await downloadProjectFileHistoryAsZipRequest(projectNumber, partGroups)
  const result = await parseDownloadProjectFileHistoryAsZipResponse(response)

  return result
}

async function downloadProjectFileHistoryAsZipRequest(projectNumber, partGroups){
  return axios({
    responseType: 'blob',
    method: 'POST',
    validateStatus: () => true,
    data: {
      projectNumber,
      partGroups,
      token: config.MICROSERVICE_TOKEN,
    },
    url: config.DOWNLOAD_PROJECT_FILES_HISTORY_ENDPOINT,
  })
}

async function parseDownloadProjectFileHistoryAsZipResponse(response){
  if(response.status === 400){
    try{
      const errorMessage = await response.data.text()
      throw new Error(`Error parsing project zip response: ${errorMessage}`)
    } catch(e) {
      throw new Error(`Error parsing project zip response`)
    }
  }

  return response.data
}
