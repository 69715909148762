const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export async function placeOrderAsDme(project, ltppMakeOrders, isRebooking){
  const projectToSave = await applyMakeOrdersToProject(project, ltppMakeOrders, isRebooking)
  return projectToSave
}

async function applyMakeOrdersToProject(project, ltppMakeOrders, isRebooking){
  let response
  try{
    response = await placeOrderAsDmeRequest(project, ltppMakeOrders, isRebooking)
  } catch (err){
    logUnexpected(err, { project, ltppMakeOrders, isRebooking }, 'placeOrderAsDmeRequest')
    throw err
  }

  let result
  try{
    result = parsePlaceOrderAsDmeResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parsePlaceOrderAsDmeResponse', { project, ltppMakeOrders, isRebooking })
    throw err
  }

  return result
}

function placeOrderAsDmeRequest(project, ltppMakeOrders, isRebooking){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/placeOrder/dme',
    data: {
      isRebooking,
      ltppMakeOrders,
      project,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parsePlaceOrderAsDmeResponse({data,status}) {
  if(status === 200){
    if(data && data.success && data.success.project){
      return data.success.project
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${status}`)
    unhandledStatusCodeError.responseData = data
    throw unhandledStatusCodeError
  }
}
