export function formatAttachmentsList(attachmentsList) {
  const TRUNCATION_THRESHOLD_CHARACTERS = 130
  function main() {
    const attachmentsToFormatExist = Boolean(attachmentsList.length)

    if (attachmentsToFormatExist) {
      return possiblyTruncatedAttachmentsListString()
    }
    else {
      return `Attachments: Drawing required to produce tapped holes, finishes, and tolerances requirements`
    }
  }
  function possiblyTruncatedAttachmentsListString() {
    let attachmentsString = concatenateAttachmentsWithTrailingComma(attachmentsList)
    if ( attachmentsString.length > TRUNCATION_THRESHOLD_CHARACTERS ) {
      return attachmentsString.substr(0, TRUNCATION_THRESHOLD_CHARACTERS) + "..." // truncate + indicator
    } else {
      return attachmentsString
    }
  }
  function concatenateAttachmentsWithTrailingComma() {
    const numAttachments = attachmentsList.length
    const attachmentsString = attachmentsList.map(a => a.fileName).join(', ')
    return `Attachments [${numAttachments}] ${attachmentsString}`
  }
  return main()
}
