import React,{useContext} from "react"
import { Button } from "@material-ui/core"
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { DrawingDimensionsSelectAtom } from "./DrawingDimensionsSelectAtom.js"
import {SnackBarContext} from '../../Common/SnackBarStore.js'
import {ToleranceRow} from './Tolerances'
import {getTolerancesLabel} from '../../../shared/getTolerancesLabel.js'
import {AccordionSection} from "../AccordionSection.js"
import USER_TYPES from '../../../utils/USER_TYPES.js'

export const TolerancesAtom = ({ isCalculatingPrices, onChange, partGroup, toleranceClassifications, tolerancesCrud, user }) => {
  const openSnackBarWithMessage = useContext(SnackBarContext)

  const copyTolerancesToClipboard = () => {
    const toleranceGoogleSheetRows = partGroup.tolerances
          .map(t => toleranceToGoogleSheetRow(t, partGroup, toleranceClassifications))
          .join('\n')

    navigator.clipboard.writeText(toleranceGoogleSheetRows)
    openSnackBarWithMessage(`Tolerance rows copied to clipboard!`)
  }

  if(user.type === USER_TYPES.DME){
    return(
      <AccordionSection className="dmeInput" label={`Tolerances: ${getTolerancesLabel(partGroup.tolerances, toleranceClassifications).join(' ')}`}>
        <DrawingDimensionsSelectAtom
          onChange={onChange}
          value={partGroup.drawingUnits}
        />

        {ToleranceRows(partGroup.tolerances, tolerancesCrud, toleranceClassifications, isCalculatingPrices, partGroup.drawingUnits, user)}

        <div style={{ display:'flex', gap: '2em', marginTop: "1em" }}>
          <Button
            onClick={()=> tolerancesCrud().create()}
            size="small"
            startIcon={<AddCircleIcon size="small"/>}
            style={{
              justifyContent: "flex-start",
              textDecoration: "underline",
              textTransform: "none",
            }}
          >
            Add new tolerance
          </Button>
          <div>
            { user.type === USER_TYPES.DME ?
              <Button
                onClick={copyTolerancesToClipboard}
                size="small"
                variant="contained"
              >
                Copy
              </Button>
              : null }
          </div>
        </div>
      </AccordionSection>
    )
  } else {
    if(partGroup.tolerances.length === 0){
      return null
    }
    return(
      <div>
        Tolerances: {getTolerancesLabel(partGroup.tolerances, toleranceClassifications).join(' ')}
      </div>
    )
  }
}

const ToleranceRows = (tolerances, tolerancesCrud, toleranceClassifications, isCalculatingPrices, drawingUnits, user) => {
  return tolerances.map(tolerance =>
    <ToleranceRow
      key={tolerance.id}
      classification={tolerance.classification}
      deleteTolerance={() => tolerancesCrud(tolerance.id).delete()}
      helpMessage={tolerance.helpMessage}
      isCalculatingPrices={isCalculatingPrices}
      onChange={ev=>tolerancesCrud(tolerance.id).update(ev.target.name, ev.target.value)}
      tolerance={tolerance}
      toleranceClassification={getToleranceClassification(toleranceClassifications, tolerance.id)}
      drawingUnits={drawingUnits}
      user={user}
    />
  )
}

function toleranceToGoogleSheetRow(t, partGroup, toleranceClassifications){
  const TAB = `	`
  const dataArray = [ partGroup.drawingUnits, '', t.featureNumber, t.quantity, t.type, t.nominal, t.upperBound, t.lowerBound ]

  const classification = getToleranceClassification(toleranceClassifications, t.id)
  if(classification.classification === 'noBid'){
    dataArray.push(classification.revisedUpperBound)
    dataArray.push(classification.revisedLowerBound)
  }

  return dataArray.join(TAB)
}


function getToleranceClassification(toleranceClassifications, id){
  let classificationObj = toleranceClassifications && toleranceClassifications.find(tc => tc.id === id)
  if(classificationObj){
    return classificationObj
  } else {
    return {
      classification: 'pending',
      helpMessage: 'Pending',
    }
  }
}
