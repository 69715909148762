import React from "react"
import PostProcessConfig from './PostProcessConfig.js'

export default function CustomerConfigMaybe({postProcessObject, postProcessOption, setSpecificPostProcessingField}){
  return(
    <PostProcessConfig
      configSelectOptions={postProcessOption.customerInput.customerConfigSelectOptions}
      configNameValue={'customerConfig'}
      configTitle={postProcessOption.customerInput.customerConfigTitle}
      configType={postProcessOption.customerInput.customerConfigType}
      postProcessObject={postProcessObject}
      setSpecificPostProcessingField={setSpecificPostProcessingField}
    />
  )
}
