import React from 'react'
import { Button, Paper } from '@material-ui/core'
import AdditionalServiceCard from './AdditionalServiceCard.js'
import ProjectFolderLinkButton from '../../Common/ProjectFolderLinkButton.js'

const ActionColumn = ({ activities, newActivityHandler, onChangeProjectFieldHandler, onDoneButtonClick, project, style }) => {
  return (
    <div style={style}>
      <CustomerCard
        customer={project.customer}
        onDoneButtonClick={onDoneButtonClick}
        projectFolderLink={project.projectFolderLink}
      />
      <br/>
      <Paper style={{
        display:'grid',
        gap: '1em',
        padding: '1em',
        gridTemplateRows: '1fr 1fr 1fr',
        justifyContent: 'center',
      }} >
        <AddMakeOrderButton
          newActivityHandler={newActivityHandler}
        />
        <AddShipmentButton
          newActivityHandler={newActivityHandler}
        />
        <AddBothButton
          newActivityHandler={newActivityHandler}
        />
      </Paper>
      <br/>
      <AdditionalServiceCard
        activities={activities}
        onChangeProjectFieldHandler={onChangeProjectFieldHandler}
        project={project}
      />
    </div>
  )
}

const AddMakeOrderButton = ({ newActivityHandler }) => {
  return (
    <Button
      color="primary"
      onClick={()=>{newActivityHandler("makeOrder")}}
      variant="contained"
    >
      Add Make Order
    </Button>
  )
}

const AddShipmentButton = ({ newActivityHandler }) => {
  return (
      <Button
        color="primary"
        onClick={()=>{newActivityHandler("shipment")}}
        variant="contained"
      >
        Add Shipment
      </Button>
  )
}

const AddBothButton = ({ newActivityHandler }) => {
  return (
    <Button
      color="secondary"
      onClick={()=>{newActivityHandler("both")}}
      variant="contained"
    >
      Add Both
    </Button>
  )
}

const CustomerCard = ({
  customer,
  onDoneButtonClick,
  projectFolderLink,
}) => {
  return(
    <Paper className="action-column-customer-card">
        <div className="action-column-customer-card-header"> Customer </div>
        <div> {customer.customerName} </div>
        <div className="action-column-customer-card-header"> Email </div>
        <div> {customer.email} </div>
        <div className="action-column-customer-card-header"> Company </div>
        <div> {customer.company ? customer.company.companyName : ''} </div>
        <div className="action-column-customer-card-header"> Notes </div>
        <div> {customer.customerNotes} </div>
      <div>
        <ProjectFolderLinkButton
          projectFolderLink={projectFolderLink}
        />
      </div>
      <div>
        <Button
          color="primary"
          onClick={onDoneButtonClick}
          size="small"
          variant="contained"
        >
          Back to summary page
        </Button>
      </div>
    </Paper>
  )
}

export default ActionColumn
