import { useState } from 'react'
import { Button } from '@material-ui/core'

export default function DownloadInternalFilesButton({ disabled, downloadInternalFiles }){
  const [isDownloading, setIsDownloading] = useState(false)

  const onClickHandler = () => {
    setIsDownloading(true)
    downloadInternalFiles()
      .finally(() => setIsDownloading(false))
  }

  return (
    <Button
      color="primary"
      disabled={disabled || isDownloading}
      onClick={onClickHandler}
      variant="contained"
    >
      { isDownloading ? 'Downloading...' : 'Download Internal Files' }
    </Button>
  )
}
