import React,{useState, useContext, useEffect} from 'react'
import {Button} from '@material-ui/core'
import config from "../../config"
import ReviewTableWithTabs from './ReviewTableWithTabs.js'
import ProcessCards from './ProcessCards.js'
import TopAppBar from '../Common/TopAppBar'
import {LoadingPageNewProj, Footer} from '../Common'
import { LoadProjectForm } from './LoadProjectForm.js'
import { CreateProjectModal } from './CreateProjectModal.js'
import { createProjectAsCustomer, createProjectAsDme } from '../HomePage/createProject.js'
import {SnackBarContext} from '../Common/SnackBarStore'
import frg from '../../feature-release-guards.js'
import loadUser from './loadUser.js'
import USER_TYPES from '../../utils/USER_TYPES.js'

const {sendErrorEmail} = require('../../utils/util.js')

export default function HomePage(props) {
  const { changeUserType, user, navigateToProject } = props
  const [projects, setProjects] = useState([])
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false)
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  useEffect(() => {

    // So we display them in the projects table
    getCustomerProjectsForUserEmail(user.email)
      .then( projects => setProjects(projects) )

  }, []) // empty array ensures effect only runs once upon mounting

  const createAndNavigateToProject = (customerAppQuoteType, dmeProjectDetails) => {
    setIsCreatingNewProject(true)

    let newProjectProm
    if(dmeProjectDetails){
      newProjectProm = createProjectAsDme(dmeProjectDetails)
    } else {
      newProjectProm = createProjectAsCustomer(customerAppQuoteType, user.email, user['custom:firstName'], user['custom:lastName'])
    }

    newProjectProm
      .then(projectNumber => {
        navigateToProject(projectNumber)
      })
      .catch(handleCreateProjectError)
      .finally(() => setIsCreatingNewProject(false))

    function handleCreateProjectError(error){
      openSnackBarWithMessage('Failed to create project. Please reach out to us directly at contact@autotiv.com to begin a new project!')
      sendErrorEmail(error)
    }
  }

  if(isCreatingNewProject){
    return (<LoadingPageNewProj/>)
  } else {
    return(
      <div className="pageContainer">
        <div className="pageContentContainer">

          <TopAppBar changeUserType={changeUserType} user={user}/>

          {frg.userDatabaseIntegration ?
           /* Only needed until loadUser is intergrated with auth layer */
           <Button
             onClick={() => {
               openSnackBarWithMessage('Loading...')
               loadUser(props.user.email)
                 .then(r => openSnackBarWithMessage('SUCCESS: ' + JSON.stringify(r)))
                 .catch(e => openSnackBarWithMessage('ERROR: ' + e.message))
             }}
             style={{ float: 'right' }}
             variant='contained'
           >
              Test User in new users table
            </Button>
            : null
          }

          <CreateProjectModal
            createProject={createAndNavigateToProject}
            open={isCreateProjectModalOpen}
            onClose={() => {setIsCreateProjectModalOpen(false)}}
          />

          <LoadProjectFormMaybe
            navigateToProject={navigateToProject}
            openCreateProjectModal={() => setIsCreateProjectModalOpen(true)}
            userType={user.type}
          />

          <ProcessCards
            createAndNavigateToProject={createAndNavigateToProject}
          />

          <ReviewTableWithTabs
            projects={projects}
            navigateToProject={navigateToProject}
          />

        </div>
        <Footer/>
      </div>
    )
  }
}

const LoadProjectFormMaybe = ({ navigateToProject, openCreateProjectModal, userType }) => {
  return(
    userType === USER_TYPES.DME?
      <LoadProjectForm
        navigateToProject={navigateToProject}
        openCreateProjectModal={openCreateProjectModal}
      />
      :
      null
  )
}

function getCustomerProjectsForUserEmail(userEmail) {

  return fetch(config.GET_CUSTOMER_PROJECTS, {
      method: 'post',
      body: JSON.stringify({
        "customerEmail": userEmail,
        "token": config.MICROSERVICE_TOKEN,
        "type":"customer-app"
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json",
      }
    })
    .then( response => {
      if (response.status === 200) {
        return response.json()

      }
      else {
        throw new Error(
        `There was a problem retrieving project list: ${response.status}`
      )
      }
    })
    .then( responseBody => {
      const projects = responseBody.body
      const sortedProjects = projects.sort(projectsByProjectNumberComparator)
      return sortedProjects
    })

  // This effectively sorts by creation date
  function projectsByProjectNumberComparator(a, b) { return b.projectNumber - a.projectNumber }
}

HomePage.Showcase = () => (
  <HomePage {...HomePage.exampleProps}/>
)

// Needs to go above the export
HomePage.exampleProps = {
  "createProject": (...args) =>console.log("createProject(", args.reduce( (a, i) => a + "," + i ), ")"),
  "currentlyLoading": false,
  "loadExistingProject": (...args) =>console.log("loadExistingProject(", args.reduce( (a, i) => a + "," + i ), ")"),
  "path": "/",
  "removeProjectFromState": (...args) =>console.log("removeProjectFromState(", args.reduce( (a, i) => a + "," + i, '' ), ")"),
  "snackbar": (...args) =>console.log("snackbar(", args.reduce( (a, i) => a + "," + i ), ")"),
  "user": {
    "type": "dme",
    "sub": "ea0f2c23-928f-4417-b7f5-942e204fb7e7",
    "email_verified": true,
    "custom:lastName": "Pagani",
    "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_15fViVABs",
    "cognito:username": "ea0f2c23-928f-4417-b7f5-942e204fb7e7",
    "aud": "2419hen1jem1oit2m7g46gj7e1",
    "event_id": "eb9686f4-dd60-4192-95d7-e820a18beebc",
    "custom:firstName": "Douglas",
    "token_use": "id",
    "auth_time": 1644521354,
    "name": "Douglas Pagerooni",
    "exp": 1645548228,
    "iat": 1645544628,
    "email": "doug@autotiv.com"
  },
  "uri": "/",
  "location": {
    "pathname": "/",
    "search": "",
    "hash": "",
    "href": "http://localhost:3000/",
    "origin": "http://localhost:3000",
    "protocol": "http:",
    "host": "localhost:3000",
    "hostname": "localhost",
    "port": "3000",
    "state": null,
    "key": "initial"
  },
  "navigate": (...args) =>console.log("navigate(", args.reduce( (a, i) => a + "," + i ), ")")
}
