import { useState } from 'react'
import { Button } from '@material-ui/core'

export default function DownloadAllQuotesButton({color, disabled, downloadAllQuotes, style}){
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownloadAllQuotesClick = () => {
    setIsDownloading(true)
    downloadAllQuotes()
      .finally(() => setIsDownloading(false))
  }

  return (
    <Button
      color={color ? color : "primary"}
      disabled={disabled || isDownloading}
      onClick={handleDownloadAllQuotesClick}
      style={style}
      variant="contained"
    >
    { isDownloading ? 'Downloading...' : 'Download Quotes' }
    </Button>
  )
}
