import React from 'react'
import {Link} from '@reach/router'
import Logo from '../../Images/logo.png'
import {Typography} from '@material-ui/core'

const Footer = (props) => {
  return(
    <div 
      className="page-footer" 
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}
    >
      <div style={{ display: "grid", justifyContent: "flex-start", alignItems: "center" }}>
        <Link to="/">
          <img 
            alt="logo" 
            src={Logo} 
            className="logo-link" 
            href="/"
          />
        </Link>
      </div>
      <div style={{ display: "grid", justifyContent: "center", alignItems: "center" }}>
        <a
          className="linkOverPrimaryColor"
          href="https://s3.amazonaws.com/autotiv.com/terms-and-conditions/Autotiv+Terms+and+Conditions.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          General Terms and Conditions
        </a>
      </div>
      <div style={{ display: "grid", justifyContent: "flex-end", alignItems: "center" }}>
        <Typography>
          &copy;{new Date().getFullYear()} Autotiv, All Rights Reserved
        </Typography>
      </div>
    </div>
  )
}

export default Footer
