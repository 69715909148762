import React from "react"
import { Button, Typography } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { OvermoldInsertRow } from "./OvermoldInsertRow.js"
export const OvermoldingSection = ({ inserts, overmoldingCrud }) => {
  return(
    <div>
      <Typography variant="subtitle1">
        Overmolding
      </Typography>
      <OvermoldInsertRows
        inserts={inserts}
        overmoldingCrud={overmoldingCrud}
      />
      <Button
        onClick={async ()=> {
          /* showAnimation() */
          await overmoldingCrud().create()
          /* stopAnimation() */
        }}
        size="small"
        startIcon={<Add style={{fontSize:"2em"}}/>}
      >
        Add an Insert
      </Button>
    </div>
  )
}

function OvermoldInsertRows({inserts, overmoldingCrud}){
  return inserts.map(insert =>
    <OvermoldInsertRow
      deleteInsertHandler={() => overmoldingCrud(insert.id).delete()}
      insert={insert}
      key={insert.id}
      onChange={ev=>overmoldingCrud(insert.id).update(ev.target.name, ev.target.value)}
    />
  )
}
