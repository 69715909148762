const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')
const HTTP_ERROR_CODES = require('../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../utils/throwErrorParsedFromKnownStatusCode.js')

export async function updateFoldersInGoogleDriveAndDecorateProjectWithFolderIds(project) {
  let result
  let response

  try {
    response = await updateFoldersRequest(project)
  } catch (err) {
    logUnexpected(err, { project }, "updateFoldersRequest")
    throw err
  }

  try {
    result = parseUpdateFolders(response)
  } catch (err) {
      logUnexpected(err, { response }, "parseUpdateFolders", { project })
      throw err
  }

  return result
}

function updateFoldersRequest(project) {
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: '/documents/folders/update',
        data: {
          project,
        }
    })
      .then(val => ({data: val.data, status: val.status}))
}

function parseUpdateFolders(response) {
  if (response.status === 200) {
    if (response.data.success && response.data.success.project) {
      return response.data.success.project
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
