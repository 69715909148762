import React,{useState} from 'react'
import {Button, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle }from '@material-ui/core'
import downloadPartBatchFiles from './downloadPartBatchFiles.js'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'

export default function PartsMenu({activity, partGroups, updateSupplierPricing}){
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUpdatePartsModal, setOpenUpdatePartsModal] = useState(false)

  const handleClickOpenUpdatePartsModal = () => {
    setOpenUpdatePartsModal(true)
    handleClose()
  }

  const handleClickDownloadFiles = () => {
    downloadPartBatchFiles(activity, partGroups)
    handleClose()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick} style={{padding:3}}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickDownloadFiles}>Download Files</MenuItem>
        <MenuItem onClick={handleClickOpenUpdatePartsModal}>Refresh Prices</MenuItem>
      </Menu>
      <AlertDialog
        activityNumber={activity.activityNumber}
        openUpdatePartsModal={openUpdatePartsModal}
        setOpenUpdatePartsModal={setOpenUpdatePartsModal}
        updateSupplierPricing={updateSupplierPricing}
      />
    </div>
  )
}

function AlertDialog({activityNumber, openUpdatePartsModal, setOpenUpdatePartsModal, updateSupplierPricing}){
  const handleClose = () => {
    setOpenUpdatePartsModal(false)
  }

  const onClickYes = () => {
    updateSupplierPricing(activityNumber)
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={openUpdatePartsModal}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to overwrite all Supplier Unit Prices for {activityNumber} with their defaults?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            No
          </Button>
          <Button onClick={onClickYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
