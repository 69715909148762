import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function removePartGroup(partGroupNumber) {
  let result
  let response
  try {
    response = await removePartGroupRequest(partGroupNumber)
  } catch (err) {
    logUnexpected(err, { partGroupNumber }, "removePartRequest")
    throw err
  }
  try {
    result = parseRemovePartGroupResponse(response)
  } catch (err) {
    // log & rethrow
    logUnexpected(err, { response }, "parseRemovePartResponse", { partGroupNumber })
    throw err
  }
  return result
}

function removePartGroupRequest(partGroupNumber) {
    partGroupNumber = Number(partGroupNumber)
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: config.REMOVE_PART_GROUP,
        data: {
          "token": config.MICROSERVICE_TOKEN,
          "partGroupNumber": partGroupNumber,
        }
    })
    .then(val => ({status: val.data.statusCode}))
}

function parseRemovePartGroupResponse(response) {
  if (response.status === "200") {
    return response
  } else {
    throw Error(`Unexpected status code while removing part : ${JSON.stringify(response.status)}`)
  }
}
