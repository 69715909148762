const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')
const { sendErrorEmail } = require('../../../utils/util.js')
const HTTP_ERROR_CODES = require('../../../utils/HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('../../../utils/throwErrorParsedFromKnownStatusCode.js')

// NOTE: probably best implemented as one encapsulating function (stripeCharge simply takes care of
// timeouts & retries as part of its MO)

// NOTE: the backend is confusingly called Stripe-plugin even though it only uses Stripe for credit
// card payments. The same endpoint is used for PO processing and doesn't use Stripe. We are looking
// to change this to two separate endpoints in the future. Before that happens this function could
// be split up into two separate functions with hard coded paymentMethods (PO and credit) that way
// you can tell in the frontend what major workflow you're executing.

// stripeChargeWithTimeoutAndRetry has a configurable timeout for the stripe charge request and will
// do a single retry if it times out. This function returns a promise and it will resolve if the PO
// call succeeds or if it can get the credit chargeId from Stripe
export function stripeChargeWithTimeoutAndRetry(project, paymentMethod, stripeToken, timeoutInMilliseconds) {

  // will store the unfinished stripe request promise if it times out so that
  // the response (which exclusively has the chargeId) may be able to be
  // recovered before the second subsequent timeout.
  let firstStripeChargePromise = stripeCharge(project, paymentMethod, stripeToken)

  return promiseWithTimeout(firstStripeChargePromise, timeoutInMilliseconds)
    .catch((err) => {
      if (err.message === 'token_already_used') throw err // got this on first try somehow
      if (err.message === 'timeout') {
        const secondStripeChargePromise = stripeCharge(project, paymentMethod, stripeToken)
        return promiseWithTimeout(secondStripeChargePromise, timeoutInMilliseconds)
          .catch(err => {
            if (err.message === "token_already_used") {
              //  It is possible that we can retrieve the value from the
              //  original request with which we became impatient.
              return promiseWithTimeout(firstStripeChargePromise, timeoutInMilliseconds)
                // this is a weird way to "communicate" with the upper-layer catch block
                // ... and to avoid the case where they both timeout
                .catch( (_) => { throw Error('token_already_used') })
            } else {
              throw err
            }
          })
      } else {
        throw err
      }
    })
    .catch((err) => {
      if (err.message === 'token_already_used') {
        alertDevOfTokenAlreadyUsedStripeError(project, stripeToken)
      }

      throw err
    })
}

// Rejects if the promise fails to complete within the timeout
function promiseWithTimeout(thePromise, timeoutInMilliseconds) {
  let timerId
  let timeoutPromise = new Promise( ( _ , reject) => {
    timerId = setTimeout(reject, timeoutInMilliseconds, Error("timeout"))
  }) 

  return Promise.race([
    timeoutPromise,
    thePromise
  ]).then((successfulResult) => {  // pass-through
    // clean-up the now-irrelevant timer just to be polite; not vital
    clearTimeout(timerId); return successfulResult;
  })

}

export async function stripeCharge(project, paymentMethod, stripeToken) {
  let response
  try {
    response = await stripeChargeRequest(project, paymentMethod, stripeToken)
  } catch (err) {
    logUnexpected(err, { project, paymentMethod, stripeToken }, 'stripeChargeRequest')
    throw err
  }

  let result
  try {
    result = parseStripeChargeResponse(response)
  } catch (err) {
    // Bail on any expected errors
    if (err.message === 'card_error') throw err
    if (err.message === 'token_already_used') throw err
    if (err.message === 'Purchase Failed') throw err

    logUnexpected(err, { response }, 'parseStripeChargeResponse', { project, paymentMethod, stripeToken })
    throw err
  }

  return result
}

async function stripeChargeRequest(project, paymentMethod, stripeToken) {
  const response = await axios({
      method: 'POST',
      validateStatus: () => true,
      url: '/charge',
      data: {
        project,
        paymentMethod,
        stripeToken,
      },
  })
  return {status: response.status, data: response.data}
}

function parseStripeChargeResponse(response) {
  if (response.status === 200) {
    if (response.data.success) {
      return response.data.success
    } else {
      throw Error(`Unexpected error response`)
    }
  } else {
    if (HTTP_ERROR_CODES.includes(response.status)) {

      // checking for expected errors from the backend application
      if (response.status === 500) {
        if (response.data.errorMessage === 'card_error') {
          throw new Error('card_error')
        }
        if (response.data.errorMessage === 'token_already_used') {
          throw new Error('token_already_used')
        }
        if (response.data.errorMessage === 'Purchase Failed') {
          throw new Error('Purchase Failed')
        }
      }

      throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
    }

    throw new Error(`Unexpected HTTP status code: ${response.status}`)
  }
}

function alertDevOfTokenAlreadyUsedStripeError(project, stripeToken) {
  const tokenAlreadyUsedExtraContext = {
    status: 'URGENT -- THIS SHOULD BE INVESTIGATED BY A HUMAN',
    info:
      `A customer got a token_already_used error from stripe which means that we tried to send a
      credit card charge request to stripe twice with the same token. token_already_used could
      be triggered by a failed-debounce or a timeout on the first attempt. This order should be
      investigated manually to find out if the order was booked and if the customer paid. The
      customer may have gotten an error message so its probably worth reaching out to them and
      letting them know the status of the order.`,
    project: project.projectNumber,
    customer: project.customerEmail,
    workflow: 'placeOrder',
    stripeErrorCode: 'token_already_used',
    stripeToken: stripeToken,
  }

  sendErrorEmail(tokenAlreadyUsedExtraContext)
}
