import React from 'react'
import FORMS from './FORMS.js'
import { ActivitiesForm } from './ActivitiesForm'
import BoxesForm from './BoxesForm'
import { PaperworkForm } from './PaperworkForm'

export default function ShipmentTracking(props){
  return (
    props.currentForm === FORMS.activities ?
      <ActivitiesForm
        activities={props.activities}
        changeForms={props.changeForms}
        currentlySaving={props.currentlySaving}
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        newActivityHandler={props.newActivityHandler}
        onChange={props.onChange}
        onChangeActivityType={props.onChangeActivityType}
        onChangeProjectFieldHandler={props.onChangeProjectFieldHandler}
        onChangePtpc={props.onChangePtpc}
        onDoneButtonClick={props.onDoneButtonClick}
        openBoxesForm={props.openBoxesForm}
        postProcessData={props.postProcessData}
        project={props.project}
        updateSupplierPricing={props.updateSupplierPricing}
      /> :
    (props.currentForm === FORMS.boxes ?
     <BoxesForm
       currentlySaving={props.currentlySaving}
       project={props.project}
       saveBoxesToShipment={props.saveBoxesToShipment}
       shipment={props.shipment}
     />
     :
     <PaperworkForm
       activities={props.activities}
       changeForms={props.changeForms}
       errorMessage={props.errorMessage}
       MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
       onDoneButtonClick={props.onDoneButtonClick}
       project={props.project}
       saveActivities={props.saveActivities}
     />
    )
  )
}
