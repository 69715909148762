import axios from 'axios'
const { logUnexpected } = require('../utils/logUnexpected.js')
const HTTP_ERROR_CODES = require('./HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('./throwErrorParsedFromKnownStatusCode.js')

export async function sendManualRfqEmailPromise(project, projectReasons, partsReasons) {
  let response
  try {
    response = await sendManualRfqEmailPromiseRequest(project, projectReasons, partsReasons)
  } catch (err) {
    logUnexpected(err, { project, projectReasons, partsReasons }, 'sendManualRfqEmailPromiseRequest')
    throw err
  }

  let result
  try {
    result = parseSendManualRfqEmailPromiseResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseSendManualRfqEmailPromiseResponse', {  project, projectReasons, partsReasons  })
    throw err
  }

  return
}

async function sendManualRfqEmailPromiseRequest(project, projectReasons, partsReasons) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/emails/manualRFQ',
    data: {
      project: project,
      projectAnalysisResults: projectReasons,
      partAnalysisResults: partsReasons,
    },
  })

  return { status: response.status, data: response.data }
}

function parseSendManualRfqEmailPromiseResponse(response) {
  if (response.status === 200) {
    if (response.data.success) {
      return response.data.success
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
