const axios = require('axios')
const config = require("../../config.js")
const { logUnexpected } = require('../../utils/logUnexpected.js')
const USER_TYPES = require('../../utils/USER_TYPES.js').default

export function loadUserTypeFromPostgres(email){
  return loadUser(email)
    .then(user => {
      // @data-rectification
      if(Object.values(USER_TYPES).includes(user.type)){
        return user.type
      } else {
        throw new Error('unknown user type provided')
      }
    })
    .catch(err => {
      // If we can't find the user then it's not an error, it's just not an enabled user
      return USER_TYPES.UNKNOWN
    })
}

export default async function loadUser(email){
  let response
  try{
    response = await loadUserRequest(email)
  } catch(error) {
    logUnexpected(error, {email}, 'loadUserRequest')
    throw error
  }

  let user
  try {
    user = parseLoadUserResponse(response)
  } catch(error) {
    if(error.message === 'user not found'){
      // known error: only dme users will be found until user databases are combined
      throw error
    }
    logUnexpected(error, { response }, 'parseLoadUserResponse', { email })
    throw error
  }

  return user
}

function loadUserRequest(email){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.LOAD_USER_ENDPOINT,
    data: {
      token: config.MICROSERVICE_TOKEN,
      userType:"Employee",
      email,
    }
  })
    .then(({data, status}) => ({data, status}))
}

function parseLoadUserResponse(response){
  if(response.status === 200){
    if(response.data && response.data.statusCode && response.data.statusCode === '201'){
      throw new Error('user not found')
    }
    if(response.data && response.data.body){
      return response.data.body
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
