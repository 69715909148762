import axios from 'axios'
const { logUnexpected } = require('../utils/logUnexpected.js')
const HTTP_ERROR_CODES = require('./HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('./throwErrorParsedFromKnownStatusCode.js')

export async function sendUploadTooLargeEmail(details) {
  let response
  try {
    response = await sendUploadTooLargeEmailRequest(details)
  } catch (err) {
    logUnexpected(err, { details }, 'sendUploadTooLargeEmailRequest')
    throw err
  }

  let result
  try {
    result = parseSendUploadTooLargeEmailResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseSendUploadTooLargeEmailResponse', { details })
    throw err
  }

  return
}

async function sendUploadTooLargeEmailRequest(details) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/emails/upload-too-large',
    data: {
      details: details,
    },
  })

  return { status: response.status, data: response.data }
}

function parseSendUploadTooLargeEmailResponse(response) {
  if (response.status === 200) {
    if (response.data.success) {
      return response.data.success
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
