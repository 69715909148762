import React from 'react'
import { Paper, Table, TableCell, TableRow, TableBody } from '@material-ui/core'
import {leftToMake, leftToMakePtpc, leftToShip, leftToShipPtpc} from './leftToMakeAndShipCalculators.js'

const LeftToMakeAndShipTable = ({activities, project, style}) => {
  return (
    <Paper style={style}>
      <Table>
        <TableBody className="table-rows-alternating-colors">
          <TableRow>
            <TableCell className="table-header-row">
              Left to Make
            </TableCell>
            <TableCell className="table-header-row">
              Left to Ship
            </TableCell>
          </TableRow>
          <LeftToMakeAndShipLineItemRows
            activities={activities}
            project={project}
          />
        </TableBody>
      </Table>
      <div
        /* DXI: This accounts for the offset added by the horizontal scrolling bar that is sometimes rendered with multiple activity columns */
        style={{ height: '1em' }}
      />
    </Paper>
  )
}

function LeftToMakeAndShipLineItemRows({activities, project}){
  return project.partGroups.flatMap((partGroup) => {
    const PartGroupRow = (
      <LeftToMakeAndShipRow
        activities={activities}
        key={partGroup.partGroupNumber}
        part={partGroup}
        project={project}
      />
    )
    const PtpcsRows = partGroup.productionToolPartConfigurations
          .map(ptpc =>
               <LeftToMakeAndShipPtpcRow
                 activities={activities}
                 key={ptpc.productionToolPartConfigurationId}
                 ptpc={ptpc}
                 project={project}
               />
              )

    return ([ PartGroupRow, ...PtpcsRows ])
  })
}

const LeftToMakeAndShipRow = ({activities,part,project}) => {
  const leftToMakeCount = leftToMake(project,activities,part.partGroupNumber)
  const leftToShipCount = leftToShip(project,activities,part.partGroupNumber)
  return (
    <TableRow className='fixed-part-row-height'>
      <TableCell style={leftToMakeCount > 0 ? {backgroundColor:'yellow'} : (leftToMakeCount < 0 ? {backgroundColor:'#FFCCCB'} : {})}>
        {leftToMakeCount}
      </TableCell>
      <TableCell style={leftToShipCount > 0 ? {backgroundColor:'yellow'} : (leftToShipCount < 0 ? {backgroundColor:'#FFCCCB'} : {})}>
        {leftToShipCount}
      </TableCell>
    </TableRow>
  )
}

const LeftToMakeAndShipPtpcRow = ({activities,project,ptpc}) => {
  const leftToMakeCount = leftToMakePtpc(project, activities, ptpc.productionToolPartConfigurationId)
  const leftToShipCount = leftToShipPtpc(project, activities, ptpc.productionToolPartConfigurationId)
  return (
    <TableRow className='fixed-part-row-height'>
      <TableCell style={leftToMakeCount > 0 ? {backgroundColor:'yellow'} : (leftToMakeCount < 0 ? {backgroundColor:'#FFCCCB'} : {})}>
        {leftToMakeCount}
      </TableCell>
      <TableCell style={leftToShipCount > 0 ? {backgroundColor:'yellow'} : (leftToShipCount < 0 ? {backgroundColor:'#FFCCCB'} : {})}>
        {leftToShipCount}
      </TableCell>
    </TableRow>
  )
}

export default LeftToMakeAndShipTable
