import getDimensionsInMM from '../Common/getDimensionsInMM.js'
import getShipInfo from './getShipInfo.js'
const {addToArrayIfUnique} = require('../../../src/shared/addToArrayIfUnique.js')
const {TranslateMaterialId} = require('../../Components/Common/TranslationTableMaterial.js')

export function updateProjectWithActivities(project, activities, MATERIAL_TRANSLATIONS) {
  for (var a = 0; a < activities.length; a++) {
    let activity = activities[a]
    if (activity.makeOrder) {
      for (var m = 0; m < project.makeOrders.length; m++) {
        let projectMakeOrder = project.makeOrders[m]
        let moIdsMatch = (activity.makeOrder.id === projectMakeOrder.id && projectMakeOrder.id !== undefined)
        let tempMoIdsMatch = (activity.makeOrder.tempActivityNumber === projectMakeOrder.tempActivityNumber && projectMakeOrder.tempActivityNumber !== undefined)
        if (moIdsMatch || tempMoIdsMatch) {
          project.makeOrders[m] = extractMakeOrderFromActivity(activity,project,MATERIAL_TRANSLATIONS)
        }
      }
    }
    if (activity.shipment) {
      for (var s = 0; s < project.shipments.length; s++) {
        let projectShipment = project.shipments[s]
        let shipIdMatch = (activity.shipment.id === projectShipment.id && projectShipment.id !== undefined)
        let tempShipIdsMatch = (activity.shipment.tempActivityNumber === projectShipment.tempActivityNumber && projectShipment.tempActivityNumber !== undefined)
        if (shipIdMatch || tempShipIdsMatch) {
          project.shipments[s] = extractShipmentFromActivity(activity,project,MATERIAL_TRANSLATIONS)
        }
      }
    }
  }
  project.makeOrders.forEach(decorateWithSortOrder)
  project.shipments.forEach(decorateWithSortOrder)
  return project
}

function decorateWithSortOrder(obj, index){
  obj.sortOrder = index
}

function extractMakeOrderFromActivity(activity,project,MATERIAL_TRANSLATIONS) {
  let makeOrder = activity.makeOrder
  makeOrder.makeOrderNumber = activity.activityNumber
  makeOrder.partBatches = activity.partBatches
    .filter(pb => pb.partGroup)
  makeOrder.ptpcPartBatches = activity.partBatches
    .filter(pb => pb.productionToolPartConfigurationId)
    .filter(pb => pb.makeOrderId || !pb.id) // no id means it's new and can be added
    .map(pb => ({
      id: pb.makeOrderId,
      customUnitPrice: pb.customUnitPrice,
      productionToolPartConfigurationId: pb.productionToolPartConfigurationId,
      quantity: pb.quantity,
    }))

  makeOrder.makeOrderFrom = activity.activityFrom
  makeOrder.makeOrderTo = activity.activityTo
  makeOrder.supplier = activity.supplier

  makeOrder.process = activity.process
  const partGroups = getPartGroupsForCalcsFromPartBatches(makeOrder.partBatches, project)
  const {
    isDyedSls,
    numberOfParts,
    materials,
    makeOrderValue,
    partOver350mmBool,
  } = calculatePartGroupData(partGroups,project,activity,MATERIAL_TRANSLATIONS)

  makeOrder.numberOfParts = numberOfParts
  makeOrder.materials = materials
  makeOrder.makeOrderValue = makeOrderValue
  makeOrder.partOver350mmBool = partOver350mmBool
  makeOrder.isDyedSls = isDyedSls

  return makeOrder
}

function extractShipmentFromActivity(activity,project,MATERIAL_TRANSLATIONS) {
  let shipment = activity.shipment
  shipment.shipmentNumber = activity.activityNumber
  shipment.partBatches = activity.partBatches
    .filter(pb => pb.partGroup)
  shipment.ptpcPartBatches = activity.partBatches
    .filter(pb => pb.productionToolPartConfigurationId)
    .filter(pb => pb.shipmentId || !pb.id) // no id means it's new and can be added
    .map(pb => ({
      id: pb.shipmentId,
      customUnitPrice: pb.customUnitPrice,
      productionToolPartConfigurationId: pb.productionToolPartConfigurationId,
      quantity: pb.quantity,
    }))

  shipment.shipmentFrom = activity.activityFrom
  shipment.shipmentTo = activity.activityTo
  shipment.supplier = activity.supplier

  const {
    shipAddress,
    shipCompany,
    shipName,
    labelType,
    shipProvider,
    shipAccountNumber,
    shipAccountNumberZipCode,
  } = getShipInfo(shipment.shipmentTo, project)

  shipment.shipAddress = shipAddress
  shipment.shipCompany = shipCompany
  shipment.shipName = shipName
  shipment.labelType = labelType
  shipment.shipProvider = shipProvider
  shipment.shipAccountNumber = shipAccountNumber
  shipment.shipAccountNumberZipCode = shipAccountNumberZipCode

  shipment.shipRevenue = '' //shipping Cost * (total bounding box of shipped parts / total bounding box of all parts)

  shipment.process = activity.process
  const partGroups = getPartGroupsForCalcsFromPartBatches(shipment.partBatches, project)
  const {
    materials,
    numberOfParts,
  } = calculatePartGroupData(partGroups,project,activity,MATERIAL_TRANSLATIONS)

  shipment.materials = materials
  shipment.numberOfParts = numberOfParts

  return shipment
}

function getPartGroupsForCalcsFromPartBatches(partBatches, projectCopy) {
  const project = JSON.parse(JSON.stringify(projectCopy))
  return partBatches.map(partBatch => {
    let partGroup

    if(partBatch.productionToolPartConfigurationId){
      partGroup = project.partGroups.find(
        ptg => ptg.productionToolPartConfigurations.find(
          ptpc => ptpc.productionToolPartConfigurationId === partBatch.productionToolPartConfigurationId
        )
      )
    } else {
      partGroup = project.partGroups
        .find(ptg => ptg.partGroupNumber === partBatch.partGroup)
    }

    partGroup.quantity = partBatch.quantity
    return partGroup
  })
  .filter(partGroup => partGroup.quantity > 0)
}

function calculatePartGroupData(partGroups,project,activity,MATERIAL_TRANSLATIONS) {
  let makeOrderValue, materialProcessId
  let materialsArray = []
  let numberOfParts = 0
  let partOver350mmBool = false
  let projectPartCostSubtotal = 0
  let projectTotal = project.totalSale

  partGroups.forEach(partGroup => {
    const partsValue = partGroup.quantity * partGroup.unitPrice
    projectPartCostSubtotal = projectPartCostSubtotal + partsValue
  })

  partGroups.forEach(partGroup => {
    numberOfParts = numberOfParts + +partGroup.quantity
    const { xDim, yDim, zDim } = getDimensionsInMM(partGroup)
    if(xDim > 350 || yDim > 350 || zDim > 350){
      partOver350mmBool = true
    }
    materialsArray = addToArrayIfUnique(materialsArray, partGroup.materialId)
    materialProcessId = partGroup.processId
    const partsValue = partGroup.quantity * partGroup.unitPrice
    makeOrderValue = projectTotal * (partsValue / projectPartCostSubtotal)
    makeOrderValue = activity.reprintBool ? 0 : makeOrderValue
  })

  let materials
  switch (materialsArray.length) {
    case 0:
      materials = ""
      break;

    case 1:
      materials = TranslateMaterialId(materialProcessId,materialsArray[0],MATERIAL_TRANSLATIONS)
      break;

    default:
      materials = "Order Has Multiple Materials"
  }

  const isDyedSls = partGroups.reduce((isPreviousPartDyedSls, ptg) => {
    const isSlsPart = ptg.processId === 'sls'
    const isDyedPart = ptg.postProcesses.some(pp => pp.postProcessId = "dyeing")
    const isDyedSlsPart = isSlsPart && isDyedPart
    return isPreviousPartDyedSls || isDyedSlsPart
  }, false)

  return {
    isDyedSls,
    numberOfParts,
    materials,
    makeOrderValue,
    partOver350mmBool
  }
}

