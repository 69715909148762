import { partAnalysisStatus } from '../../utils'
import { STATUS_BLUE } from "./partCardAtoms/STATUS_BLUE.js"

export const InjectionMoldingAnalysisFeedbackAtom = ({ partGroup, postProcessData }) => {
  const { feedbackLabel, feedbackColor } = generateFeedbackLabelAndColorTuple(partGroup, postProcessData)

  return(
    <div>
      <span style={ {color: feedbackColor, fontStyle: 'italic' } }>
        {feedbackLabel}
      </span>
    </div>
  )
}

function generateFeedbackLabelAndColorTuple(partGroup, postProcessData) {
  let feedbackLabel, feedbackColor

  const {
    NEEDS_INJECTION_MOLDING_WALL_THICKNESS,
    INJECTION_MOLDING_WALL_THICKNESS_FINISHED,
  } = partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)

  if (INJECTION_MOLDING_WALL_THICKNESS_FINISHED) {
    if (partGroup.part.averageWallThickness && partGroup.part.standardDeviationWallThickness) {
      return {
        feedbackLabel: 'Injection molding calculation complete',
        feedbackColor: 'green',
      }
    } else if (partGroup.part.injectionMoldingWallThicknessError) {
      return {
        feedbackLabel: `Failed to calculate injection molding fields. Try again or get data manually. Message: ${partGroup.part.injectionMoldingWallThicknessError}`,
        feedbackColor: 'red',
      }
    } else {
      return {
        feedbackLabel: '',
        feedbackColor: '',
      }
    }
  }

  return {
    feedbackLabel: 'Waiting for injection molding automation...',
    feedbackColor: STATUS_BLUE,
  }
}
