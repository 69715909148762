const { addToArrayIfUnique } = require('./addToArrayIfUnique.js')
const { businessLogicMfgIssues } = require('./businessLogicMfgIssues.js')

module.exports = getManufacturabilityIssues

function getManufacturabilityIssues(partGroup, POST_PROCESS_OPTIONS){
  const part = partGroup.part
  let mfgIssues = []

  const isProcess3dp = ['sla', 'sls', 'fdm', 'dmls', 'polyJet', 'mjf' ].includes(partGroup.processId)

  if(part.meshProperties && isProcess3dp){
    let meshIssues = []
    if (part.meshProperties.badEdges > 0) {
      meshIssues = addToArrayIfUnique(meshIssues,`${part.meshProperties.badEdges} bad edges`)
    }
    if (part.meshProperties.boundaryEdges > 0) {
      meshIssues = addToArrayIfUnique(meshIssues,`${part.meshProperties.boundaryEdges} boundary edges`)
    }
    if (part.meshProperties.isManifold === 'false') {
      meshIssues = addToArrayIfUnique(meshIssues,'Not manifold')
    }
    if (part.meshProperties.flippedTriangleCount > 0) {
      meshIssues = addToArrayIfUnique(meshIssues,`${part.meshProperties.flippedTriangleCount} flipped triangles`)
    }
    if (Number(part.meshProperties.shells) !== 1) {
      meshIssues = addToArrayIfUnique(meshIssues,`${part.meshProperties.shells} shells`)
    }

    if(meshIssues.length){
      mfgIssues = addToArrayIfUnique(mfgIssues, {
        text: `File health issues: We recommend uploading a repaired file to avoid the risk of our automated repair process altering your file`,
        subIssues: meshIssues
      })
    }
  }

  if(isProcess3dp){
    if(part.units === "mm"){
      if(part.wallThicknessResultsMM === false || part.wallThicknessResultsMM === 'false'){
        mfgIssues = addToArrayIfUnique(mfgIssues, { text: "Thin walls or tapers under 0.7 mm (displayed in red) likely won't fully form" })
      }
    }
    if(part.units === "inches"){
      if(part.wallThicknessResultsInches === false || part.wallThicknessResultsInches === 'false'){
        mfgIssues = addToArrayIfUnique(mfgIssues, { text: "Thin walls or tapers under 0.7 mm (displayed in red) likely won't fully form" })
      }
    }
  }

  const blMfgIssues = businessLogicMfgIssues(partGroup, POST_PROCESS_OPTIONS)
  blMfgIssues.forEach(blIssue => {
    mfgIssues = addToArrayIfUnique(mfgIssues, { text: blIssue })
  })

  if(part.dmeMarkedAtRiskPartBool){
    mfgIssues = addToArrayIfUnique(mfgIssues, { text: "DME marked as at-risk (see notes)" })
  }

  return mfgIssues
}
