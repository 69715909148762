export default function getDimensionsInMM(partGroup){
  const { M1, M2, M3 } = getConversionMultipliers(partGroup.part.units)

  const xDim = partGroup.part.xDimUnits * M1
  const yDim = partGroup.part.yDimUnits * M1
  const zDim = partGroup.part.zDimUnits * M1

  const partSurfaceArea = partGroup.part.partSurfaceAreaUnits * M2

  const partVolume = partGroup.part.partVolumeUnits * M3

  const dims = {
    xDim: xDim.toFixed(2),
    yDim: yDim.toFixed(2),
    zDim: zDim.toFixed(2),
    partSurfaceArea: partSurfaceArea.toFixed(2),
    partVolume: partVolume.toFixed(2),
  }

  return dims
}

function getConversionMultipliers(units){
  const INCHES_TO_MM = 25.4
  const MM_TO_MM = 1 // just to be explicit

  const oneDimMulitplier = units === 'inches' ? INCHES_TO_MM : MM_TO_MM

  const M1 = oneDimMulitplier
  const M2 = M1 * M1
  const M3 = M1 * M1 * M1

  return { M1, M2, M3 }
}
