import React,{Fragment} from 'react'
import {Button} from '@material-ui/core'
import {EditShippingAddressDiv} from './EditShippingAddressDiv.js'

export const ShippingAddressExpansionDetails = (props) => {
  /*
  EDIT_SHIP_DETAILS is a two part mechanism that checks if an existing ship address exists and considers the state.shipAddressReadOnly state
  state.shipAddressReadOnly is necessary as the user needs to be able to go back and revise the address at will regardless of existing data
  */
  const EDIT_SHIP_DETAILS = props.checkForExistingShipAddress() && props.shipAddressReadOnly
  return(
    <Fragment>
      {EDIT_SHIP_DETAILS ?
        <div style={{width:'100%'}}>
          <div style={{backgroundColor:'#E0E0E0',display:'flex',justifyContent:'space-between',padding:'5px'}}>
            <div style={{paddingLeft:'10px',paddingTop:'5px'}}>
              <span style={{fontWeight:'bold'}}>{props.project.shipName}</span>
              {` ${props.project.shipCompany}, ${props.project.shipAddress.street}, ${props.project.shipAddress.street2}, ${props.project.shipAddress.city}, ${props.project.shipAddress.state}, ${props.project.shipAddress.zip}, ${props.project.shipPhone}`}
            </div>
            <div>
              <Button onClick={()=>{setTimeout(()=>{props.editShipDetails()}, 175)}}>
                Edit
              </Button>
            </div>

          </div>
          <br/>
          <div align="right">
            <Button color="secondary" onClick={()=>{setTimeout(()=>{props.nextStepHandler()}, 175)}} style={{margin: '15px'}} variant="contained">
              Next
            </Button>
          </div>
        </div> :
        <EditShippingAddressDiv
          applyCustomerAddressToProject={props.applyCustomerAddressToProject}
          fieldsToHighlight={props.fieldsToHighlight}
          isSavingAddress={props.isSavingAddress}
          project={props.project}
          saveCustomerHandler={props.saveCustomerHandler}
          setProjectField={props.setProjectField}
          updateCustomerAddressHandler={props.updateCustomerAddressHandler}
          user={props.user}
        />
      }
    </Fragment>
  )
}
