import { TO_FROM_TYPES } from './supplierTypeArrays.js'

export function generateActivityNumbers(activities, project) {
    activities = generateActivityProcesses(activities, project)

    let existingActivityNumbers = []
    activities.forEach((activity) => {
        if (activity.activityNumber !== undefined || activity.activityNumber !== "") {
            // existingActivityNumbers.push(activity.activityNumber)
        }
    })
    const { processCount: numberOfProcessesInProject } = countNumberOfProcesses(project.partGroups)
    for (var a = 0; a < activities.length; a++) {
        let activity = { ...activities[a] }
        // if(activity.activityNumber === undefined || activity.activityNumber === "") {
        let number = generateActivityNumber(activities, numberOfProcessesInProject, a, project, existingActivityNumbers)
        activity.activityNumber = number
        existingActivityNumbers.push(number)
        // }
        activities[a] = activity
    }
    return activities
}

function generateActivityProcesses(activities, project) {
    for (let a = 0; a < activities.length; a++) {
        let activity = activities[a]
        let activityPartGroups = extractPartGroupsFromActivityForCalcs(activity, project.partGroups)
        const { firstProcess, processCount: num } = countNumberOfProcesses(activityPartGroups)
        let process
        switch (num) {
            case 0:
                process = "EMPTY"
                break;
            case 1:
                process = firstProcess
                break;
            default:
                process = "MULTI"
        }
        activity.process = process
    }
    return activities
}

function extractPartGroupsFromActivityForCalcs(activity, rawPartGroups) {
  const partGroups = JSON.parse(JSON.stringify(rawPartGroups))
  let partGroupsInActivity = []
  activity.partBatches.map(partBatch => {
    if(partBatch.quantity > 0){
      let partGroup

      if(partBatch.productionToolPartConfigurationId){
        partGroup = findPartGroupByPtpcId(partBatch.productionToolPartConfigurationId, partGroups)
      } else {
        partGroup = findPartGroupInPartGroups(partBatch.partGroup, partGroups)
      }

      partGroup.quantity = partBatch.quantity
      partGroupsInActivity.push(partGroup)
    }
  })
  return partGroupsInActivity
}

function findPartGroupInPartGroups(partGroupNumber, partGroups) {
    return partGroups.find(partGroup => partGroup.partGroupNumber === partGroupNumber)
}

function findPartGroupByPtpcId(productionToolPartConfigurationId, partGroups){
  return partGroups
    .find(
      ptg => ptg.productionToolPartConfigurations
        .find(
          ptpc => ptpc.productionToolPartConfigurationId === productionToolPartConfigurationId
        )
    )
}

function countNumberOfProcesses(partGroups) {
  const uniqueProcessSet = new Set()
  partGroups.forEach(({process, quantity}) => {
    if(quantity > 0){
      uniqueProcessSet.add(process)
    }
  })
  return {
    firstProcess: uniqueProcessSet.values().next().value,
    processCount: uniqueProcessSet.size,
  }
}

function generateActivityNumber(activities, numberOfProcessesInProject, a, project, existingActivityNumbers, i) {
    if (!i) {
        i = 0
    }
    let number = generateActivityNumberAddOn(activities, numberOfProcessesInProject, a, project, i)

    let existing = false
    existingActivityNumbers.forEach((existingNumber) => {
        if (number === existingNumber) {
            existing = true
        }
    })
    if (existing) {
        i += 1
        return generateActivityNumber(activities, numberOfProcessesInProject, a, project, existingActivityNumbers, i)
    }
    return number
}

function generateActivityNumberAddOn(activities, numberOfProcessesInProject, a, project, j) {
    let currentActivity = activities[a]
    let currentSubstring = getSubstring(currentActivity, numberOfProcessesInProject)
    let numberOfPrevious = j

    if (!numberOfPrevious) {
        numberOfPrevious = 0
    }
    for (var i = 0; i < a; i++) {
        let previousActivity = activities[i]
        let previousSubstring = getSubstring(previousActivity, numberOfProcessesInProject)
        if (previousSubstring === currentSubstring) {
            numberOfPrevious += 1
        }
    }
    let suffix
    if (numberOfPrevious) {
        let num = +numberOfPrevious + 1
        let addon = "." + num
        suffix = getSubstring(activities[a], numberOfProcessesInProject) + addon
    }
    else {
        suffix = getSubstring(activities[a], numberOfProcessesInProject)
    }
    let number = project.projectNumber + suffix
    return number
}

function getSubstring(activity, numberOfProcessesInProject) {
  let substring = ""
  let activityTo = activity.activityTo

  if(numberOfProcessesInProject > 1) {
    substring = substring + "." + activity.process
  }
  if(activityTo === TO_FROM_TYPES.intermediate) {
    substring = substring + ".INT"
  }
  if(activityTo === TO_FROM_TYPES.heldAtSupplier) {
    substring = substring + ".HOLD"
  }
  if(activityTo === TO_FROM_TYPES.autotiv) {
    substring = substring + ".ATV"
  }
  if(activity.makeOrder && activity.makeOrder.reprintBool){
    substring = substring + ".REPRINT"
  }

  return substring
}
