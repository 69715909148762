import React from 'react'
import {Card,CardActionArea,CardContent,CardMedia,Grid,Typography} from '@material-ui/core'

import IMAGE_FOR_CNC from '../../Images/card_CNC.png'
import IMAGE_FOR_3DP from '../../Images/card_3dPrinter.png'
import IMAGE_FOR_MOLDING from '../../Images/card_molding.png'
import IMAGE_FOR_DMLS from '../../Images/card_metal3d.png'
import IMAGE_FOR_URETHANE from '../../Images/card_urethane.png'
import IMAGE_FOR_SHEET_METAL from '../../Images/card_sheet.png'

const ProcessCardGrid = ({ createAndNavigateToProject }) => {
  const PROCESS_CARDS_PAYLOAD = [
    // applyAllSeeder, image import, jsx as title
    [ '3dPrinting', IMAGE_FOR_3DP, (<> Plastic 3D <br/> Printing </> ) ],
    [ "cnc", IMAGE_FOR_CNC, (<> CNC <br/> Machining </>) ],
    [ "injectionMolding", IMAGE_FOR_MOLDING, (<> Injection <br/> Molding </>) ],
    [ "dmls", IMAGE_FOR_DMLS, (<>Metal 3D <br/> Printing</>) ],
    [ "casting", IMAGE_FOR_URETHANE, (<>Urethane <br/> Casting </>) ],
    [ "sheetMetal", IMAGE_FOR_SHEET_METAL, ( <> Sheet <br/> Metal </> ) ],
  ]
  return(
    <Grid 
      container
      className="processCardGrid"
      justify="center" 
      spacing={3}
    >
      { PROCESS_CARDS_PAYLOAD.map( ( cardPayload, i ) => (
      <GridCard
        key={i}
        createAndNavigateToProject={createAndNavigateToProject}
        customerAppQuoteType={cardPayload[0]}
        image={cardPayload[1]}
      >
        { cardPayload[2] }
      </GridCard>
        ))
      }
    </Grid>
  )
}

const GridCard = props => {
  const action = (e)=>{props.createAndNavigateToProject(props.customerAppQuoteType)}
  return(
    <Grid 
      item 
      className="processGridItem"
      xs={4} md={2} // DXI: out of 6 cards we want neat subdivisions of width; parent-driven
    > 
      <Card raised onClick={action}>
          <CardActionArea>
            <CardMedia
              image={props.image}
              style={{ paddingTop: "100%" }}
              /* ^WHAT IS THIS DOING??
               * DXI: We want the image to "fill-out" the card
               * - by default, img height is 0
               * - This only works because it is actually injected as background image, not an img,
               *   therefore, setting padding actually helps since if it were
               *   an img, it would only expand to fill the content-box, not
               *   the border-exclusive-broder-box.
               * - this is the standard way of how you lock the aspect ratio 
               *   https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
               * - paddingTop, as a %, actually calculates relative to the WIDTH of the parent
               *   and we actually have width in its parent (which is a
               *   MediaDiv, one more layer besides the overall card), due to
               *   the text of its sibling element causing the overall card
               *   to have width  But in css, height is by default 0, even
               *   though it is a "new line" 
               */
            />
            <CardContent>
              <Typography align="center" variant="h5">
                {props.children}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
    </Grid>
  )
}



export default ProcessCardGrid
