import config from "./config"
import React,{useContext,useEffect,useState} from 'react'
import {LoginController} from './Components'
import LoadingPage from './Components/Common/LoadingPage.js'
import './global.css'
import { Amplify } from 'aws-amplify';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import frg from './feature-release-guards.js'
import getMaterialsData from './getMaterialsData.js'
import getPostProcessOptions from './Components/Common/getPostProcessOptions.js'

// Showcase-ready components
import CustomerPartConfiguration from './Components/QuoteTool/CustomerPartConfiguration.js'
import HomePage from './Components/HomePage/HomePage.js'
import PartCard from './Components/QuoteTool/PartCard.js'
import ReviewTableWithTabs from './Components/HomePage/ReviewTableWithTabs.js';
import PartTable from './Components/QuoteTool/PartTable.js'
import PartViewerModal from './Components/QuoteTool/PartViewer/PartViewerModal.js'
import ProductionTool from "./Components/QuoteTool/ProductionTool";
import ProjectBar from './Components/QuoteTool/ProjectBar.js'
import RemakeRequestPage from './Components/RemakeRequestPage'
import SelectMaterialAutoComplete from './Components/Common/SelectMaterialAutocomplete.js'
import SnackBarStore,{SnackBarContext} from './Components/Common/SnackBarStore.js'
import { ShipPriceConfigAutocomplete } from "./Components/QuoteTool/ShipPriceConfigAutocomplete";
import QuoteTool from './Components/QuoteTool/QuoteTool.js'
import UnderConstruction from './Components/Common/UnderConstruction.js'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#213249",
    },
    secondary: {
      main: '#24A56C',
    },
  },
});

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.CUSTOMER_APP_CLIENT_ID,
  },
})

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <SnackBarStore>
      { frg.showcaseOn ?
        // <CustomerPartConfiguration.Showcase />
        // <ProjectBar {...ProjectBar.exampleProps} />
        // <ReviewTableWithTabs { ...ReviewTableWithTabs.exampleProps } />
        //<PartCard.Showcase />
        //<ProductionTool.Showcase/>
        //<RemakeRequestPage.Showcase/>
        // <HomePage.Showcase/>
        //<SelectMaterialAutoComplete.Showcase />
        // <LoginController supportedBrowser={props.supportedBrowser} />
        // <PartTable {...PartTable.exampleProps } />
        // <PartViewerModal {...PartViewerModal.exampleProps } />
        // <ShipPriceConfigAutocomplete.Showcase/>
        <QuoteTool.Showcase/>
      :
        <PricingDataFetcher
          supportedBrowser={props.supportedBrowser}
        />
      }
      </SnackBarStore>
    </ThemeProvider>
  )
}
function Selector(props) {
  return (
    <div>select stuff</div>
  )
}

function PricingDataFetcher(props){
  // the app must wait for these because it wont work without this data
  const [materialsData, setMaterialsData] = useState(false)
  const [postProcessData, setPostProcessData] = useState(false)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  useEffect(()=>{
    getPostProcessOptions()
      .then(postProcessData => {
        setPostProcessData(postProcessData)
      })
      .catch(err => openSnackBarWithMessage(err))

    getMaterialsData()
      .then(materialsData => {
        setMaterialsData(materialsData)
      })
      .catch(err => openSnackBarWithMessage(err))
  },['ONMOUNT'])

  return (
    (materialsData && postProcessData) ?
    <LoginController
      materialsData={materialsData.materialsData}
      postProcessData={postProcessData}
      supportedBrowser={props.supportedBrowser}
      TOOL_MATERIALS={materialsData.TOOL_MATERIALS}
    />
    :
    <LoadingPage/>
  )
}

const IS_UNDER_CONSTRUCTION = false

const DefaultComponent = IS_UNDER_CONSTRUCTION ? UnderConstruction : App

export default DefaultComponent
