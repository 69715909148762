module.exports =  {
  "partGroups": [
    {
      "partGroupNumber": 194455,
      "part": {
        "partIdentifier": "",
        "partNumber": 189308,
        "knackRecordId": "",
        "fileName": "step.step",
        "partNotes": "",
        "xDim": "57.15",
        "yDim": "32.70",
        "zDim": "53.37",
        "partVolume": "44794.94",
        "partSurfaceArea": "13794.94",
        "atRiskPartBool": true,
        "partFile": "",
        "partFileS3": "160a5580-1cc7-4a49-a2ea-05c8c3276ad9",
        "partDrawing": "",
        "mfgIssues": [],
        "units": "mm",
        "wallThicknessOBJFile": "",
        "wallThicknessMaterialFile": "",
        "wallThicknessOBJFileMM": "ae0598fd-6069-46a5-98d4-b97781263749",
        "wallThicknessMaterialFileMM": "8a5fffd6-a57a-4bc1-9848-95d457747817",
        "wallThicknessResultsMM": false,
        "wallThicknessOBJFileInches": "",
        "wallThicknessMaterialFileInches": "",
        "wallThicknessResultsInches": "",
        "s3ObjFileId": "615fdb1f-307a-4aac-9839-377bcc20356e",
        "xDimUnits": "57.1500015258789",
        "yDimUnits": "32.6967029571533",
        "zDimUnits": "53.3684990406036",
        "partVolumeUnits": "44794.94140625",
        "partSurfaceAreaUnits": "13794.935546875",
        "mfgAnalysisIssues": [],
        "riskAccepted": true,
        "wallThicknessTaskIdMM": "",
        "wallThicknessTaskIdInches": "",
        "paramExtractError": "",
        "meshProperties": {
          "shells": "1",
          "badEdges": "0",
          "boundaryEdges": "0",
          "flippedTriangleCount": "0",
          "isManifold": "true"
        },
        "thumbnailS3id": "",
        "thinWallPercentageMM": "",
        "thinWallPercentageInches": "",
        "fileSize": "",
        "netfabbWallThickessTotalSurfaceAreaInches": "",
        "netfabbWallThickessTotalSurfaceAreaMM": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdMM": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": ""
      },
      "project": 39933,
      "makeOrder": "",
      "shipment": "",
      "knackRecordId": "",
      "process": "SLS",
      "material": "White Nylon 12",
      "description": "",
      "quantity": 1,
      "finishing": "Standard",
      "additionalServices": "",
      "unitPrice": "27.39",
      "unitCost": "",
      "customPrice": "",
      "markupPrice": "0.00",
      "dmlsParam_asBuiltX": "",
      "dmlsParam_asBuiltY": "",
      "dmlsParam_asBuiltZ": "",
      "dmlsParam_perPartFinishingTime": "",
      "dmlsParam_perPartSlicedMaterialVolume": "",
      "fdmParam_machine": "",
      "fdmParam_resolution": "",
      "fdmParam_infill": "",
      "fdmParam_perPartSlicedMaterialVolume": "",
      "fdmParam_perPartSupportMaterialVolume": "",
      "fdmParam_perPartFinishingTimeHour": "",
      "fdmParam_perPartFinishingTimeMin": "",
      "cncParam_perPartMachineTime": "",
      "cncParam_perPartDrilledHolesCount": "",
      "cncParam_perPartTappedHolesCount": "",
      "cncParam_toolCount": "",
      "cncParam_setupCount": "",
      "cncParam_perPartMaterialCostOffset": "",
      "cncParam_perPartMachiningCostOffset": "",
      "cncParam_setupCostOffset": "",
      "mkCncParam_totalPartGroupCost": "",
      "mkCastParam_masterCost": "",
      "mkCastParam_moldCost": "",
      "mkCastParam_totalPartGroupCost": "",
      "mkInjmdParam_moldCost": "",
      "mkInjmdParam_moldLife": "",
      "mkInjmdParam_totalPartGroupCost": "",
      "mkShtmlParam_totalPartGroupCost": "",
      "asParam_perPartUnitCost": "",
      "reprintQuantityRequested": "",
      "dmlsParam_useMarkupPrice": "",
      "mkCastParam_useMarkupPrice": "",
      "mkDmlsParam_totalPartGroupCost": "",
      "processId": "sls",
      "materialId": "slsWhiteNylon12",
      "finishId": "slsStandard",
      "selectedPrice": "calculated",
      "partGroupNotes": "",
      "atRiskBool": "",
      "riskAccepted": "",
      "attachments": [
        { fileName: "some-really-long-name-that-will-have-to-get-truncated-if-we-are-going-to-survive-the-apocalypse-of-lorem-ipsums-that-are-on-the-brink-of-destroying-this-society-which-we-hold-sacred.manifesto" },
        { fileName: "some-really-long-name-that-will-have-to-get-truncated-if-we-are-going-to-survive-the-apocalypse-of-lorem-ipsums-that-are-on-the-brink-of-destroying-this-society-which-we-hold-sacred.txt" },
        { fileName: "some-really-long-name-that-will-have-to-get-truncated-if-we-are-going-to-survive-the-apocalypse-of-lorem-ipsums-that-are-on-the-brink-of-destroying-this-society-which-we-hold-sacred.md" },
      ],
      "id": "1bc9f778",
      "userType": "customer"
    },
    {
      "partGroupNumber": 194456,
      "part": {
        "partIdentifier": "",
        "partNumber": 189309,
        "knackRecordId": "",
        "fileName": "solidworks.sldprt",
        "partNotes": "",
        "xDim": "57.15",
        "yDim": "53.37",
        "zDim": "32.70",
        "partVolume": "44794.54",
        "partSurfaceArea": "13795.06",
        "atRiskPartBool": true,
        "partFile": "",
        "partFileS3": "e312fb08-d481-43f6-af24-17628677242e",
        "partDrawing": "",
        "mfgIssues": [],
        "units": "mm",
        "wallThicknessOBJFile": "",
        "wallThicknessMaterialFile": "",
        "wallThicknessOBJFileMM": "1965f98b-583b-4401-b09d-132792dec1bc",
        "wallThicknessMaterialFileMM": "90632cd1-0d7d-463c-bd68-e6cd46cd2f15",
        "wallThicknessResultsMM": false,
        "wallThicknessOBJFileInches": "",
        "wallThicknessMaterialFileInches": "",
        "wallThicknessResultsInches": "",
        "s3ObjFileId": "db1a6bc1-21e9-4867-a4f7-4c871a2d68cd",
        "xDimUnits": "57.1500015258789",
        "yDimUnits": "53.3684990406036",
        "zDimUnits": "32.6967029571533",
        "partVolumeUnits": "44794.5390625",
        "partSurfaceAreaUnits": "13795.056640625",
        "mfgAnalysisIssues": [],
        "riskAccepted": true,
        "wallThicknessTaskIdMM": "",
        "wallThicknessTaskIdInches": "",
        "paramExtractError": "",
        "meshProperties": {
          "shells": "1",
          "badEdges": "0",
          "boundaryEdges": "0",
          "flippedTriangleCount": "0",
          "isManifold": "true"
        },
        "thumbnailS3id": "",
        "thinWallPercentageMM": "",
        "thinWallPercentageInches": "",
        "fileSize": "",
        "netfabbWallThickessTotalSurfaceAreaInches": "",
        "netfabbWallThickessTotalSurfaceAreaMM": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdMM": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": ""
      },
      "project": 39933,
      "makeOrder": "",
      "shipment": "",
      "knackRecordId": "",
      "process": "SLS",
      "material": "White Nylon 12",
      "description": "",
      "quantity": 1,
      "finishing": "Standard",
      "additionalServices": "",
      "unitPrice": "27.39",
      "unitCost": "",
      "customPrice": "",
      "markupPrice": "0.00",
      "dmlsParam_asBuiltX": "",
      "dmlsParam_asBuiltY": "",
      "dmlsParam_asBuiltZ": "",
      "dmlsParam_perPartFinishingTime": "",
      "dmlsParam_perPartSlicedMaterialVolume": "",
      "fdmParam_machine": "",
      "fdmParam_resolution": "",
      "fdmParam_infill": "",
      "fdmParam_perPartSlicedMaterialVolume": "",
      "fdmParam_perPartSupportMaterialVolume": "",
      "fdmParam_perPartFinishingTimeHour": "",
      "fdmParam_perPartFinishingTimeMin": "",
      "cncParam_perPartMachineTime": "",
      "cncParam_perPartDrilledHolesCount": "",
      "cncParam_perPartTappedHolesCount": "",
      "cncParam_toolCount": "",
      "cncParam_setupCount": "",
      "cncParam_perPartMaterialCostOffset": "",
      "cncParam_perPartMachiningCostOffset": "",
      "cncParam_setupCostOffset": "",
      "mkCncParam_totalPartGroupCost": "",
      "mkCastParam_masterCost": "",
      "mkCastParam_moldCost": "",
      "mkCastParam_totalPartGroupCost": "",
      "mkInjmdParam_moldCost": "",
      "mkInjmdParam_moldLife": "",
      "mkInjmdParam_totalPartGroupCost": "",
      "mkShtmlParam_totalPartGroupCost": "",
      "asParam_perPartUnitCost": "",
      "reprintQuantityRequested": "",
      "dmlsParam_useMarkupPrice": "",
      "mkCastParam_useMarkupPrice": "",
      "mkDmlsParam_totalPartGroupCost": "",
      "processId": "sls",
      "materialId": "slsWhiteNylon12",
      "finishId": "slsStandard",
      "selectedPrice": "calculated",
      "partGroupNotes": "",
      "atRiskBool": "",
      "riskAccepted": "",
      "attachments": [],
      "id": "376dfc60",
      "userType": "customer"
    },
    {
      "partGroupNumber": 194457,
      "part": {
        "partIdentifier": "",
        "partNumber": 189310,
        "knackRecordId": "",
        "fileName": "parasolid.x_t",
        "partNotes": "",
        "xDim": "237.45",
        "yDim": "293.53",
        "zDim": "109.11",
        "partVolume": "5220899.50",
        "partSurfaceArea": "623495.75",
        "atRiskPartBool": true,
        "partFile": "",
        "partFileS3": "7b06ae1a-645f-4ef4-8a73-912b45b3c485",
        "partDrawing": "",
        "mfgIssues": [
          "SLS parts with a dimension over 140mm are prone to warping"
        ],
        "units": "mm",
        "wallThicknessOBJFile": "",
        "wallThicknessMaterialFile": "",
        "wallThicknessOBJFileMM": "59d72bfe-1c2c-467b-8bff-5fc9d1cc039c",
        "wallThicknessMaterialFileMM": "5c0ca58b-c6d6-42c6-a0de-ef5673109b72",
        "wallThicknessResultsMM": false,
        "wallThicknessOBJFileInches": "",
        "wallThicknessMaterialFileInches": "",
        "wallThicknessResultsInches": "",
        "s3ObjFileId": "50003786-07b6-42ea-901a-b267472aa6fc",
        "xDimUnits": "237.447814941406",
        "yDimUnits": "293.529006958008",
        "zDimUnits": "109.111724853516",
        "partVolumeUnits": "5220899.5",
        "partSurfaceAreaUnits": "623495.75",
        "mfgAnalysisIssues": [],
        "riskAccepted": true,
        "wallThicknessTaskIdMM": "",
        "wallThicknessTaskIdInches": "",
        "paramExtractError": "",
        "meshProperties": {
          "shells": "8",
          "badEdges": "0",
          "boundaryEdges": "0",
          "flippedTriangleCount": "0",
          "isManifold": "true"
        },
        "thumbnailS3id": "",
        "thinWallPercentageMM": "",
        "thinWallPercentageInches": "",
        "fileSize": "",
        "netfabbWallThickessTotalSurfaceAreaInches": "",
        "netfabbWallThickessTotalSurfaceAreaMM": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdMM": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": ""
      },
      "project": 39933,
      "makeOrder": "",
      "shipment": "",
      "knackRecordId": "",
      "process": "SLS",
      "material": "White Nylon 12",
      "description": "",
      "quantity": 1,
      "finishing": "Standard",
      "additionalServices": "",
      "unitPrice": "2840.59",
      "unitCost": "",
      "customPrice": "",
      "markupPrice": "0.00",
      "dmlsParam_asBuiltX": "",
      "dmlsParam_asBuiltY": "",
      "dmlsParam_asBuiltZ": "",
      "dmlsParam_perPartFinishingTime": "",
      "dmlsParam_perPartSlicedMaterialVolume": "",
      "fdmParam_machine": "",
      "fdmParam_resolution": "",
      "fdmParam_infill": "",
      "fdmParam_perPartSlicedMaterialVolume": "",
      "fdmParam_perPartSupportMaterialVolume": "",
      "fdmParam_perPartFinishingTimeHour": "",
      "fdmParam_perPartFinishingTimeMin": "",
      "cncParam_perPartMachineTime": "",
      "cncParam_perPartDrilledHolesCount": "",
      "cncParam_perPartTappedHolesCount": "",
      "cncParam_toolCount": "",
      "cncParam_setupCount": "",
      "cncParam_perPartMaterialCostOffset": "",
      "cncParam_perPartMachiningCostOffset": "",
      "cncParam_setupCostOffset": "",
      "mkCncParam_totalPartGroupCost": "",
      "mkCastParam_masterCost": "",
      "mkCastParam_moldCost": "",
      "mkCastParam_totalPartGroupCost": "",
      "mkInjmdParam_moldCost": "",
      "mkInjmdParam_moldLife": "",
      "mkInjmdParam_totalPartGroupCost": "",
      "mkShtmlParam_totalPartGroupCost": "",
      "asParam_perPartUnitCost": "",
      "reprintQuantityRequested": "",
      "dmlsParam_useMarkupPrice": "",
      "mkCastParam_useMarkupPrice": "",
      "mkDmlsParam_totalPartGroupCost": "",
      "processId": "sls",
      "materialId": "slsWhiteNylon12",
      "finishId": "slsStandard",
      "selectedPrice": "calculated",
      "partGroupNotes": "",
      "atRiskBool": "",
      "riskAccepted": "",
      "attachments": [],
      "id": "a5c359fc",
      "userType": "customer"
    },
    {
      "partGroupNumber": 194458,
      "part": {
        "partIdentifier": "",
        "partNumber": 189311,
        "knackRecordId": "",
        "fileName": "wallthickness.stl",
        "partNotes": "",
        "xDim": "20.00",
        "yDim": "20.00",
        "zDim": "1.00",
        "partVolume": "300.00",
        "partSurfaceArea": "870.00",
        "atRiskPartBool": true,
        "partFile": "",
        "partFileS3": "acc4aba7-22f9-4e78-a62e-6b5584b20273",
        "partDrawing": "",
        "mfgIssues": [],
        "units": "mm",
        "wallThicknessOBJFile": "",
        "wallThicknessMaterialFile": "",
        "wallThicknessOBJFileMM": "eea04edf-ab81-4b50-a6d9-cd0f03e14a9a",
        "wallThicknessMaterialFileMM": "516004b7-162e-4694-b888-72919db0f175",
        "wallThicknessResultsMM": false,
        "wallThicknessOBJFileInches": "",
        "wallThicknessMaterialFileInches": "",
        "wallThicknessResultsInches": "",
        "s3ObjFileId": "179b4e97-c180-43f0-9783-0d9008d5b965",
        "xDimUnits": "20",
        "yDimUnits": "20",
        "zDimUnits": "1",
        "partVolumeUnits": "299.999969482422",
        "partSurfaceAreaUnits": "870",
        "mfgAnalysisIssues": [],
        "riskAccepted": true,
        "wallThicknessTaskIdMM": "fz2l2tq7r0W1P9JevOzF32n57wxZrQRI",
        "wallThicknessTaskIdInches": "fz2l2zywr6FjUxAF7r3Za9pvTpT0Bh2x",
        "paramExtractError": "",
        "meshProperties": {
          "shells": "1",
          "badEdges": "0",
          "boundaryEdges": "0",
          "flippedTriangleCount": "0",
          "isManifold": "true"
        },
        "thumbnailS3id": "",
        "thinWallPercentageMM": "",
        "thinWallPercentageInches": "",
        "fileSize": "",
        "netfabbWallThickessTotalSurfaceAreaInches": "",
        "netfabbWallThickessTotalSurfaceAreaMM": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
        "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
        "netfabbWallThicknessNumberOfClustersBelowThresholdMM": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
        "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": ""
      },
      "project": 39933,
      "makeOrder": "",
      "shipment": "",
      "knackRecordId": "",
      "process": "SLS",
      "material": "White Nylon 12",
      "description": "",
      "quantity": 1,
      "finishing": "Standard",
      "additionalServices": "",
      "unitPrice": "2.16",
      "unitCost": "",
      "customPrice": "",
      "markupPrice": "0.00",
      "dmlsParam_asBuiltX": "",
      "dmlsParam_asBuiltY": "",
      "dmlsParam_asBuiltZ": "",
      "dmlsParam_perPartFinishingTime": "",
      "dmlsParam_perPartSlicedMaterialVolume": "",
      "fdmParam_machine": "",
      "fdmParam_resolution": "",
      "fdmParam_infill": "",
      "fdmParam_perPartSlicedMaterialVolume": "",
      "fdmParam_perPartSupportMaterialVolume": "",
      "fdmParam_perPartFinishingTimeHour": "",
      "fdmParam_perPartFinishingTimeMin": "",
      "cncParam_perPartMachineTime": "",
      "cncParam_perPartDrilledHolesCount": "",
      "cncParam_perPartTappedHolesCount": "",
      "cncParam_toolCount": "",
      "cncParam_setupCount": "",
      "cncParam_perPartMaterialCostOffset": "",
      "cncParam_perPartMachiningCostOffset": "",
      "cncParam_setupCostOffset": "",
      "mkCncParam_totalPartGroupCost": "",
      "mkCastParam_masterCost": "",
      "mkCastParam_moldCost": "",
      "mkCastParam_totalPartGroupCost": "",
      "mkInjmdParam_moldCost": "",
      "mkInjmdParam_moldLife": "",
      "mkInjmdParam_totalPartGroupCost": "",
      "mkShtmlParam_totalPartGroupCost": "",
      "asParam_perPartUnitCost": "",
      "reprintQuantityRequested": "",
      "dmlsParam_useMarkupPrice": "",
      "mkCastParam_useMarkupPrice": "",
      "mkDmlsParam_totalPartGroupCost": "",
      "processId": "sls",
      "materialId": "slsWhiteNylon12",
      "finishId": "slsStandard",
      "selectedPrice": "calculated",
      "partGroupNotes": "",
      "atRiskBool": "",
      "riskAccepted": "",
      "attachments": [],
      "id": "fddf15e0",
      "userType": "customer"
    }
  ],
  "classes": {},
  "styles": {},
  "onChangeApplyAllFieldQuoteToolHandler": (event) => { console.log("onChange() handler was called with:","name: ", event.target.name, ",", "value: ", event.target.value)},
  "applyValuesToAll": {
    "forAllMaterial": "",
    "forAllProcess": "",
    "forAllUnits": ""
  },
  "reviewReasons": {
    "projectReasons": [
      "Project Attachments need to be reviewed before providing a quote. Submit to the Autotiv team to get a price!"
    ],
    "partsReasons": []
  },
  "uploadNewAttachment": (...args) =>console.log("uploadNewAttachment(", args.reduce( (a, i) => a + "," + i ), ")"),
  "removeAttachment": (...args) =>console.log("removeAttachment(", args.reduce( (a, i) => a + "," + i ), ")"),
  "openPartModal": (...args) =>console.log("openPartModal(", args.reduce( (a, i) => a + "," + i ), ")"),
  "onChangePartFieldQuoteToolHandler": (event) => { console.log("onChange() handler was called with:","name: ", event.target.name, ",", "value: ", event.target.value)},
  "deletePartGroup": (...args) =>console.log("deletePartGroup(", args.reduce( (a, i) => a + "," + i ), ")"),
  "currentlyLoading": false
}

