import React,{useState} from 'react'
import {CircularProgress,FormControl,InputLabel,Select} from '@material-ui/core'
import ManualRfqTextWithTooltip from './ManualRfqTextWithTooltip.js'
import {NumberField} from "./NumberField"
import SelectMaterialAutocomplete from "../Common/SelectMaterialAutocomplete.js"
import {formatAttachmentsList} from '../../utils/formatAttachmentsList'
import AttachmentsModal from "./AttachmentsModal"
import DmeApprovedNotesAtom from './DmeApprovedNotesAtom.js'
import {
  DeleteButtonAtom,
  FormattedPriceText,
  PartAttachmentsAtom,
  PostProcessesAtom,
} from "./partCardAtoms"
import {COLORS} from '../../shared/COLORS.js'
import USER_TYPES from '../../utils/USER_TYPES.js'

export default function ProductionToolPartConfiguration(props) {
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false)

  const ptpcPriceMaybe = props.ptpcPrices && props.ptpcPrices
        .find(ptpcPrice => ptpcPrice.productionToolPartConfigurationId === props.productionToolPartConfiguration.productionToolPartConfigurationId)
  const unitPrice = ptpcPriceMaybe ? ptpcPriceMaybe.unitPrice : 0 // to accomodate for parts that are new and dont have prices yet

  const uploadPtpcAttachmentFiles = files => {
    props.setPtpcField("isDmeReviewed", false)
    files.map(file => props.ptpcCrud.attachments().create(file, props.user.email, props.projectNumber))
  }

  return(
    <div className="production-tool-part-configuration">
      <div style={{ gridArea: "mater" }}>
        <SelectMaterialAutocomplete
          MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
          onChange={e => props.setPtpcField("materialId", e.value)}
          process={props.productionToolPartConfiguration.processId}
          user={props.user}
          value={props.productionToolPartConfiguration.materialId}
        />
      </div>
      <div style={{ gridArea: "color", justifySelf:"center" }}>
        <SelectOptionColor
          MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
          materialId={props.productionToolPartConfiguration.materialId}
          onChange={e => props.setPtpcField("color", e.target.value)}
          value={props.productionToolPartConfiguration.color}
        />
      </div>
      <div style={{ gridArea: "quant", justifySelf:"center"  }}>
        <QuantityInput
          onChange={e => props.setPtpcField("quantity", e.target.value)}
          value={props.productionToolPartConfiguration.quantity}
        />
      </div>
      <div style={{ gridArea: "price", justifySelf:"center"}}>
        {props.isCalculatingPrices ? <CircularProgress /> :
         (props.partReasons.length > 0 ?
          <ManualRfqTextWithTooltip
            colorOverride="black"
            isCondensed
            partGroup={props.partGroup}
          />
          :
          <FormattedPriceText
            unitPrice={unitPrice}
            quantity={props.productionToolPartConfiguration.quantity}
          />
         )
        }
      </div>
      <div style={{ gridArea: "delet", justifySelf:"center" }}>
        { props.isTheOnlyPtpc ? null :
          <DeleteButtonAtom
            deleteHandler={() => {
              props.deleteProductionToolPartConfiguration(
                props.productionToolPartConfiguration.productionToolPartConfigurationId,
                props.partGroup.partGroupNumber,
              )
            }}
            tooltipTitle="Delete this production tool"
            />
        }
      </div>
      <div style={{ gridArea: "postp" }} className="truncated-list-container-parent">
        <PostProcessesAtom
          materialId={props.productionToolPartConfiguration.materialId}
          postProcessData={props.postProcessData}
          postProcesses={props.productionToolPartConfiguration.postProcesses}
          postProcessesCrud={props.ptpcCrud.postProcesses}
          user={props.user}
        />
      </div>
      <div style={{ gridArea: "atchs" }} className="truncated-list-container-parent" >
        <PartAttachmentsAtom
          attachmentsList={formatAttachmentsList(props.productionToolPartConfiguration.attachments)}
          isNotDmeReviewed={props.user.type === USER_TYPES.DME && !props.productionToolPartConfiguration.isDmeReviewed}
          openAttachmentsModal={() => setIsAttachmentsModalOpen(true)}
        />
      </div>
      <div style={{ gridArea: "notes" }}>
        <DmeApprovedNotesAtom
          isDmeReviewed={props.productionToolPartConfiguration.isDmeReviewed}
          notes={props.productionToolPartConfiguration.notes}
          onChangeCheckbox={e => props.setPtpcField("isDmeReviewed", e.target.checked)}
          onChangeNotes={e => props.setPtpcField("notes", e.target.value)}
          user={props.user}
        />
      </div>
      <AttachmentsModal
        attachments={props.productionToolPartConfiguration.attachments}
        open={isAttachmentsModalOpen}
        onClose={() => setIsAttachmentsModalOpen(false)}
        partName={props.fileName + ' Configuration ' + props.number}
        removeAttachment={fileId => props.ptpcCrud.attachments(fileId).delete()}
        uploadParts={uploadPtpcAttachmentFiles}
      />
    </div>
  )
}

const SelectOptionColor = ({ MATERIAL_TRANSLATIONS, materialId, onChange, value }) => {
  const materialObject = MATERIAL_TRANSLATIONS
        .find(matObj => matObj.id === materialId)

  let materialFilteredColors = []
  if(materialObject){
    materialFilteredColors = COLORS
      .filter(color => color.colorSets
              .find(set => set === materialObject.colorSet)
             )
  }

  return(
    <FormControl style={{ width:"15em" }}>
      <InputLabel id='im-color-select'>
        Color
      </InputLabel>
      <Select
        labelId="im-color-select"
        native
        onChange={onChange}
        value={value}
      >
        {
          materialFilteredColors
          .map(color => <option key={color.value} value={color.value}>{color.label}</option>)
        }
      </Select>
    </FormControl>
  )
}

const QuantityInput = ({ onChange, value }) => {
  return(
    <NumberField
      label="Quantity"
      onChange={onChange}
      style={{ width:"10em" }}
      value={value}
    />
  )
}
