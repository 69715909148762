import { addNewMakeOrderToProject } from './addNewMakeOrderToProject.js'
import { addNewShipmentToProject } from './addNewShipmentToProject.js'
import { generateActivities } from './generateActivities.js'
import { updateProjectWithActivities } from './updateProjectWithActivities'
import { v4 as uuid } from 'uuid'

export function updateProjectAndActivitiesWithNewActivity(newActivityType, activity, project, activities, MATERIAL_TRANSLATIONS ) {
      const TEMP_ACTIVITY_NUMBER = uuid()
      let updatedProject = updateProjectWithActivities(project, activities,MATERIAL_TRANSLATIONS)
      if (newActivityType === 'shipment' && activity) {
            updatedProject = addNewShipmentToProject(updatedProject, TEMP_ACTIVITY_NUMBER)
            let shipment = updatedProject.shipments[updatedProject.shipments.length - 1]
            if (activity.makeOrder.id) {
                  shipment.makeOrder = [{ id: activity.makeOrder.id }]
            }
            else {
                  shipment.tempActivityNumber = activity.makeOrder.tempActivityNumber
            }
            shipment.shipmentFrom = activity.activityFrom
            shipment.shipmentTo = activity.activityTo
            shipment.supplier = activity.supplier
            shipment.partBatches = activity.partBatches
            newActivityType = "skipSwitchStatement"
      }

      switch (newActivityType) {
            case 'makeOrder':
                  updatedProject = addNewMakeOrderToProject(updatedProject, TEMP_ACTIVITY_NUMBER)
                  break
            case 'shipment':
                  updatedProject = addNewShipmentToProject(updatedProject, TEMP_ACTIVITY_NUMBER)
                  break
            case 'both':
                  updatedProject = addNewMakeOrderToProject(updatedProject, TEMP_ACTIVITY_NUMBER)
                  updatedProject = addNewShipmentToProject(updatedProject, TEMP_ACTIVITY_NUMBER)
                  break
            default:
                  break
      }
      return updatedProject
}
