import React from 'react'
import CenteredWrapper from './CenteredWrapper.js'
import Logo from '../../Images/logoDark.png'

const UnderConstruction = (props) => {
  return(
    <CenteredWrapper>
      <div>
        <img alt="logo" style={{width: '40vw'}} src={Logo}/>
        <h1>
          We are currently doing some website maintenance
        </h1>
        <h2>
          Please contact us directly at <a href="mailto:contact@autotiv.com">contact@autotiv.com</a> to submit a quote request!
        </h2>
        <h1 style={{color: 'red'}}>
          The website will be back by the morning of August 2
        </h1>
      </div>
    </CenteredWrapper>
  )
}

export default UnderConstruction
