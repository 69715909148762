module.exports = {determineProjectProcess}

function determineProjectProcess(project){
  let firstElementProcess = project.partGroups[0].process
  let allProcessesMatch = project.partGroups.every(part=>{
    return (part.process === firstElementProcess)
  })
  if(allProcessesMatch){
    return firstElementProcess
  }
  else{
    return "Multi-Process"
  }
}
