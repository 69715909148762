import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function createAttachment(attachment) {
  let result
  let response
  try {
    response = await createAttachmentRequest(attachment)
  } catch (err) {
    logUnexpected(err, { attachment }, "createAttachmentRequest")
    throw err
  }
  try {
    result = parseCreateAttachmentResponse(response)
  } catch (err) {
    if (false /* add retries here; check error-type & retryCounter */) {
      // retry stub
      // RECURSION
      // return await createAttachment(attachment, retryCounter)
    } else {
      // log & rethrow
      logUnexpected(err, { response }, "parseCreateAttachmentResponse", { attachment })
      throw err
    }
  }
  return result
}

function createAttachmentRequest(attachment) {
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: config.CREATE_ATTACHMENT_ENDPOINT,
        data: {
          "token": config.MICROSERVICE_TOKEN,
          "attachment": attachment
        }
    })
      .then(val => ({data: val.data, status: val.status}))
}

function parseCreateAttachmentResponse(response) {
  if (response.status === 200) {
    let attachment
    try {
      attachment = response.data.attachment
      if ( response.data.attachment.fileId === undefined) {
        throw Error('Attachment has no fileId')
      } else {
        return attachment
      }
    } catch (err) {
      throw Error(`Failed to create attachment: unexpected reason ${err.message}`)
    }
  } else {
    throw Error(`Unexpected Status Code: ${JSON.stringify(response.status)}`)
  }
}

