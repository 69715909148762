import React from 'react'
import ACTIVITY_TYPES from '../ACTIVITY_TYPES.js'
import { NumberField } from '../../QuoteTool/NumberField.js'
//DXI: NativeSelect looks better in dropdown menu
import {Paper,NativeSelect as Select,Table,TableBody,TableCell,TableRow,TextField,Typography} from '@material-ui/core'
import {CLOSED_SUPPLIER_ARRAY,DOMESTIC_SUPPLIER_ARRAY,INTERNATIONAL_SUPPLIER_ARRAY} from '../TranslationTableSuppliers.js'
import {SUPPLIER_FROM_TYPE_ARRAY,SUPPLIER_TO_TYPE_ARRAY,SUPPLIER_TO_TYPE_ARRAY_MAKEORDER} from '../supplierTypeArrays.js'
import {formatDateForDatePicker} from '../datePickerDataParsing.js'

const MakeOrderDataCard = ( props ) => {
  const {activity,onChange,onChangeActivityType, project, ...otherProps} = props
  //Provides a yellow or white bg style object
  let renderColor = activity.makeOrder.reprintBool ? {backgroundColor:'lightblue'} : {backgroundColor:'white'} 
  return (
    <Paper
      {...otherProps}
    >
      <Table
        style={renderColor}
        size="small"
        className="make-order-row-height"
      >
        <TableBody>
          <MakeOrderNumberRow
            activity={activity}
          />

          <SupplierTypeRow
            activity={activity}
            onChange={onChange}
          />

          <SupplierRow
            activity={activity}
            isDomesticSourcing={project.isDomesticSourcing}
            onChange={onChange}
          />

          <MakeOrderToRow
            activity={activity}
            onChange={onChange}
          />

          <MakeOrderDateRow
            activity={activity}
            onChange={onChange}
          />

          <SupplierLeadTimeRow
            activity={activity}
            onChange={onChange}
          />

          <SupplierShipDateRow
            activity={activity}
            onChange={onChange}
          />

          <PurchaseOrderNotesRow
            activity={activity}
            onChange={onChange}
          />

          <InternalMakeOrderNotesRow
            activity={activity}
            onChange={onChange}
          />

          <ReprintBoolRow
            activity={activity}
            onChangeActivityType={onChangeActivityType}
          />

        </TableBody>
      </Table>
    </Paper>
  )
}

const MakeOrderNumberRow = ({ activity }) => {
  return (
    <TableRow>
      <TableCell className={"table-header-row"} colSpan="3">
        <Typography align="center" variant="h6">
          Make Order {activity.activityNumber}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

const SupplierTypeRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Supplier Type
      </TableCell>
      <TableCell
        className={activity.activityFrom ? "" : "empty-input-cell"}
        colSpan="2"
      >
        <Select
          name="activityFrom"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          value={activity.activityFrom}
          style={{width: "100%"}}
        >
          <option key="blank" value=""></option>
          {SUPPLIER_FROM_TYPE_ARRAY.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
  )
}

const SupplierRow = ({ activity, isDomesticSourcing, onChange }) => {
  const supplierId = activity.supplier[0] ? activity.supplier[0].id : ""
  return (
    <TableRow>
      <TableCell style={isDomesticSourcing ? {background: 'lightcoral'} : null} className="key-cell">
        {isDomesticSourcing ? 'Domestic Supplier' : 'Supplier'}
      </TableCell>
      <TableCell
        className={supplierId ? "" : "empty-input-cell"}
        colSpan="2"
      >
        <Select
          name="supplierId"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          value={supplierId}
          style={{width: "100%"}}
        >
          <option value=""/>
          <option disabled value="domestic suppliers">Domestic</option>
          {DOMESTIC_SUPPLIER_ARRAY
           .map(supplier=>{
             return(
               <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
             )
           })}
          <option disabled value="blank line"/>
          <option disabled value="international suppliers">International</option>
          {INTERNATIONAL_SUPPLIER_ARRAY.map(supplier=>{
            return(
              <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
            )
          })}
          <option disabled value="blank line"/>
          <option disabled value="international suppliers">Closed</option>
          {CLOSED_SUPPLIER_ARRAY.map(supplier=>{
            return(
              <option key={supplier.id} value={supplier.id}>{supplier.identifier}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
  )
}

const MakeOrderToRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Make Order To
      </TableCell>
      <TableCell
        className={activity.activityTo ? "" : "empty-input-cell"}
        colSpan="2"
      >
        <Select
          name="activityTo"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Make Order To"
          style={{width: "100%"}}
          value={activity.activityTo}
        >
          <option key="blank" value=""></option>
          {activity.shipment ?
          SUPPLIER_TO_TYPE_ARRAY.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })
          :
          SUPPLIER_TO_TYPE_ARRAY_MAKEORDER.map(supplierType=>{
            return(
              <option key={supplierType} value={supplierType}>{supplierType}</option>
            )
          })}
        </Select>
      </TableCell>
    </TableRow>
  )
}

const MakeOrderDateRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Make Order Date
      </TableCell>
      <TableCell
        className={activity.makeOrder.purchaseOrderDate ? "" : "empty-input-cell"}
        colSpan="2"
      >
        <TextField
          name="purchaseOrderDate"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="mm/dd/yyyy"
          style={{width: "100%"}}
          type="date"
          value={formatDateForDatePicker(activity.makeOrder.purchaseOrderDate)}
        />
      </TableCell>
    </TableRow>
  )
}

const SupplierLeadTimeRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Supplier Lead Time
      </TableCell>
      <TableCell
        className={activity.makeOrder.leadTime ? "" : "empty-input-cell"}
        colSpan="2"
      >
        <NumberField
          name="leadTime"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Lead Time"
          style={{width: "100%"}}
          value={activity.makeOrder.leadTime}
        />
      </TableCell>
    </TableRow>
  )
}

const SupplierShipDateRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Supplier Ship Date
      </TableCell>
      <TableCell>
        {activity.makeOrder.moPaidShipDate}
      </TableCell>
    </TableRow>
  )
}

const PurchaseOrderNotesRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        PO Notes
      </TableCell>
      <TableCell
        colSpan="2"
      >
        <TextField
          name="moSupplierNotes"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Notes"
          style={{width: "100%"}}
          type="text"
          value={activity.makeOrder.moSupplierNotes}
        />
      </TableCell>
    </TableRow>
  )
}

const InternalMakeOrderNotesRow = ({ activity, onChange }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Internal Only Notes
      </TableCell>
      <TableCell
        colSpan="2"
      >
        <TextField
          name="moOurNotes"
          onChange={(e)=>{onChange(activity.activityNumber,e)}}
          placeholder="Notes"
          style={{width: "100%"}}
          type="text"
          value={activity.makeOrder.moOurNotes}
        />
      </TableCell>
    </TableRow>
  )
}

const ReprintBoolRow = ({ activity, onChangeActivityType }) => {
  return (
    <TableRow>
      <TableCell className="key-cell">
        Type
      </TableCell>
      <TableCell
        colSpan="2"
      >
        <Select
          onChange={e => onChangeActivityType(e, activity.activityNumber)}
          placeholder="Reprint Selector"
          style={{width: "100%"}}
          value={activity.makeOrder.reprintBool ? ACTIVITY_TYPES.REPRINT : (activity.makeOrder.isSamplesOrder ? ACTIVITY_TYPES.SAMPLES : ACTIVITY_TYPES.NORMAL)}
        >
          {
            Object.entries(ACTIVITY_TYPES)
              .map(
                ([key, value]) => <option key={key} value={value}>{value}</option>
              )
          }
        </Select>
      </TableCell>
    </TableRow>
  )
}

export default MakeOrderDataCard

