import React from "react"

export function formatFileDimensions(part) {
  const partDimensionsExist = anyPartDimensionsAreGreaterThanZero(part)

  if (partDimensionsExist) {
    const xDim = formatFileDimension(part.xDimUnits)
    const yDim = formatFileDimension(part.yDimUnits)
    const zDim = formatFileDimension(part.zDimUnits)
    const dimString =  (
      <>
        {xDim}&nbsp;x { yDim }&nbsp;x { zDim }
      </>
    )
    return dimString
  } else {
    return "No dimensions"
  }
}

function formatFileDimension(aDimension) {
  return (
    aDimension > 0 ?
      Number(aDimension).toFixed(2)
      : "0.00"
  )
}

function anyPartDimensionsAreGreaterThanZero(part) {
  return (part.xDimUnits>0 || part.yDimUnits>0 || part.zDimUnits>0)
}

export function formatFileDimensionsForToolTip(part) {
  const partDimensionsExist = anyPartDimensionsAreGreaterThanZero(part)

  if (partDimensionsExist) {
    const xDim = formatAPartDimensionForToolTip(part.xDimUnits, part.units)
    const yDim = formatAPartDimensionForToolTip(part.yDimUnits, part.units)
    const zDim = formatAPartDimensionForToolTip(part.zDimUnits, part.units)
    const convertedUnits = (part.units === "mm" ? "inches" : "mm")
    const dimString = xDim  + " x " + yDim + " x " + zDim + " " + convertedUnits
    return dimString
  }
  else{
    return "No dimensions"
  }
}

function formatAPartDimensionForToolTip(aDimension, units) {
  const MM_TO_INCHES_CONVERSION_FACTOR = 0.0393701
  const INCHES_TO_MM_CONVERSION_FACTOR = 25.4
  return (
    aDimension > 0 ?
      (units === "mm" ?
        (aDimension * MM_TO_INCHES_CONVERSION_FACTOR).toFixed(2)
        : (aDimension * INCHES_TO_MM_CONVERSION_FACTOR).toFixed(2))
      : "0.00"
  )
}
