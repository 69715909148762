const fileTypeFromBuffer = require('file-type')
const path = require('path-browserify')

/*
  Dont allow files not supported by Netfabb or with
  a blacklisted mimetype to be uploaded
*/
export async function validateFile(file) {
  validateExtension(file.name)
  await validateMimeType(file)
}

// Return if a file represents a part model or a part drawing. Part models can
// have the geometry analyzed to extract parameters. Part drawings need to be
// manually quoted
export function isModelOrDrawing(filename) {
  const ext = getFileExtension(filename)

  if (NETFABB_FILES.includes(ext)) {
    return 'model'
  }
  if (DRAWING_FILES.includes(ext)) {
    return 'drawing'
  }
  // These three errors were crashing react in dev mode but didnt when only throwing objects
  // Left here to figure out after the fact of adding in this feature
  // 1.
  // throw new Error(`${ext} file is not a part model or drawing`)
  throw ({message: `${ext} file is not a part model or drawing`})
}

// return the file extension if it is supported by Netfabb and throw an error
// if it's not
function validateExtension(filename) {
  const ext = getFileExtension(filename)

  // throw if the file has no extension
  if (!ext) {
    // 2.
    // throw new Error('File must contain a file extension')
    throw ({message:'File must contain a file extension'})
  }

  // throw if the file extension is not a type that we support
  if (!NETFABB_FILES.includes(ext) && !DRAWING_FILES.includes(ext)) {
    // 3.
    // throw new Error(`Unsupported file extension ${ext}`)
    throw ({message:`Unsupported file extension ${ext}`})
  }
  return ext
}

// attempt to extract mime type from the file and throw an error if the files
// mime type is blacklisted
function validateMimeType(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    // triggered when the File object is finished converting to a buffer
    reader.onload = async (e) => {
      const arrayBuffer = reader.result
      // get mimetype of file
      let fileInfo = await fileTypeFromBuffer(arrayBuffer)

      // if information cannot be extracted from the file just allow it
      if (!fileInfo) {
        return resolve()
      }

      // dont allow blacklisted mimetypes to be uploaded
      if (BLACKLISTED_MIMETYPES.includes(fileInfo.mime)) {
        return reject(new Error(`${fileInfo.mime} is not a supported mime type`))
      }

      return resolve()
    }
  })
}

export function getFileExtension(filename) {
  let ext = path.extname(filename)
  // remove the '.' in front of the extension
  ext = ext.substr(1).toUpperCase()
  return ext
}

export function checkFileTypeIncludeUnits(fileName) {
  const ext = getFileExtension(fileName)
  // if parts file has built in units
  let includesUnits = CAD_FILES.includes(ext.toUpperCase())
  return includesUnits
}

// files that are supported by Netfabb
const CAD_FILES = [
  // CAD
  "STEP","STP","VRML","WRL","IGES","IGS","IFC","JT",
  "IPT","IAM","MODEL","SESSION","DLV","EXP","CATPART","CATPRODUCT","CATSHAPE",
  "CGR","3DXML","ASM","NEU","PRT","XAS","XPR","X_B","X_T","XMT","XMT_TXT",
  "PRC","PRT","MF1","ARC","UNV","PKG","ASM","PWD","PSM","SLDASM","SLDPRT",
]

const MESH_FILES = [
  // MESH
  "STL","X3D","X3DB","GTS","OBJ","3DS","NCM","AMF","3MF","ZPR","PLY",
  "VRML","BINVOX",
]

const NETFABB_FILES = [...CAD_FILES, ...MESH_FILES]

const DRAWING_FILES = ["PDF", "DWG", "DXF", "VWX"]

const BLACKLISTED_MIMETYPES = [
  'application/x-msdownload', 'text/x-sh','text/x-python-script',
]
