import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getCustomerList } from './getCustomerList'
import { RequiredFieldAsterisk } from './RequiredFieldAsterisk'

export const CustomerSelectAutoComplete = ({ customer, setCustomer }) => {
  const [ customerList, setCustomerList ] = useState([])
  const [ customerPartialName, setCustomerPartialName ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(()=> {
    if(customerPartialName.length >= 3 && !isLoading && customerList.length === 0){
      setIsLoading(true)
      getCustomerList(customerPartialName)
        .then(customerList => {
          setCustomerList( customerList )
        })
        .finally(() => setIsLoading(false))
    }
  }, [ customerPartialName ])

  return(
    <Autocomplete
      autoFocus={true}
      disableClearable={ true }
      getOptionLabel={ option => option.emailAndName ? option.emailAndName : '' }
      getOptionSelected={ (optionToCompare,selectedOption) => optionToCompare.email === selectedOption.email }
      inputValue={ customerPartialName }
      noOptionsText={isLoading ? "Loading Customer List..." : "Search Customer..."}
      onInputChange={ (_event, newInputValue) => setCustomerPartialName(newInputValue) }
      onChange={ (_event, customer) => setCustomer(customer) }
      options={ customerList }
      renderInput={ (params) => <TextField {...params} autoFocus={true} label={<>Customer<RequiredFieldAsterisk/></>} variant="standard" /> }
      value={ customer }
    />
  )
}
