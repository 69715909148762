import getDimensionsInMM from '../Common/getDimensionsInMM.js'

export function getCncMillStockValues(partGroup){
  const { xDim, yDim, zDim } = getDimensionsInMM(partGroup)
  const cncStockX = Math.ceil(xDim)
  const cncStockY = Math.ceil(yDim)
  const cncStockZ = Math.ceil(zDim)

  return { cncStockX, cncStockY, cncStockZ }
}

export function getCncTurnStockValues(partGroup){
  const { xDim, yDim, zDim } = getDimensionsInMM(partGroup)
  const dims = [ Number(xDim), Number(yDim), Number(zDim) ]

  const farthestIndex = getFarthestDimIndex(dims)

  const farthest = dims.splice(farthestIndex, 1)
  const remainingDims = dims // renaming after the side-effect of splice

  const [ height ] = farthest

  const [ shorterDim, longerDim ] = remainingDims
        .sort(numbersSmallestToLargest)

  const cncStockInnerDiameter = 0
  const cncStockOuterDiameter = Math.ceil(longerDim)
  const cncStockZ = Math.ceil(height)

  return { cncStockInnerDiameter, cncStockOuterDiameter, cncStockZ }
}

function getFarthestDimIndex(dims){
  const sortedDims = [...dims]
        .sort(numbersSmallestToLargest)

  // small, medium, large
  const [s, m, l] = sortedDims
  const deltas = [l-m, l-s, m-s]

  const sortedDeltas = [...deltas]
        .sort(numbersSmallestToLargest)
  const smallestDelta = sortedDeltas[0]
  const smallestDeltaIndex = deltas
        .findIndex(d => d === smallestDelta)

  const farthestDim = sortedDims[smallestDeltaIndex]

  return dims.findIndex(d => {
    return d === farthestDim
  })
}

function numbersSmallestToLargest(numA, numB){
  return numA - numB
}

