import React from "react"
import { CustomTooltip } from "."

export const PartDimensionsAtom = ({ formattedDimensionsLabel, formattedDimensionsToolTipLabel }) => (
  <CustomTooltip title={ formattedDimensionsToolTipLabel }>
    <div style={{ fontStyle: "italic" }}>
      { formattedDimensionsLabel }
    </div>
  </CustomTooltip>
)
