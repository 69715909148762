import {
  getOrderDateFromCurrentTime,
} from '../../utils'
import {appendInfillIfNeeded} from '../../shared/appendInfillIfNeeded.js'
import {COLORS} from '../../shared/COLORS.js'
const {TranslateMaterialId} = require('../Common/TranslationTableMaterial.js')
const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function makeInvoiceInQuickbooks(project, MATERIAL_TRANSLATIONS, isWaitingForPaymentInvoice){
  let payload, response
  try{
    payload = getPayloadFromProject(project, MATERIAL_TRANSLATIONS, isWaitingForPaymentInvoice)
    response = await makeInvoiceInQuickbooksRequest(payload)
  } catch (err){
    logUnexpected(err, { project }, 'makeInvoiceInQuickbooksRequest')
    throw err
  }

  let result
  try{
    result = parseMakeInvoiceInQuickbooksResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseMakeInvoiceInQuickbooksResponse', { payload, project })
    throw err
  }

  return result
}

function makeInvoiceInQuickbooksRequest(payload){
  const formData = new FormData()
  Object.entries(payload)
    .forEach(([key, value]) => {
      formData.append(key, value)
    })

  return axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    validateStatus: () => true,
    url: 'https://hooks.zapier.com/hooks/catch/2167220/3dwcuen/',
    data: formData,
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseMakeInvoiceInQuickbooksResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.status){
      return response.data.status
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}

function getPayloadFromProject(project, MATERIAL_TRANSLATIONS, isWaitingForPaymentInvoice){
  const invoiceNumber = isWaitingForPaymentInvoice ? `${project.projectNumber}.WFP` : project.projectNumber
  const discount = project.discount > 0 ? project.discount : 0
  const data = {
    projectNumber: project.projectNumber,
    invoiceNumber,
    billingEmail: getBillingEmail(project.customer),
    dueDate: getOrderDateFromCurrentTime(),
    discount,
    amount: project.totalSale,
    paymentReferenceNumber: project.paymentReferenceNumber,
    lineItems: JSON.stringify(getLineItems(project, MATERIAL_TRANSLATIONS)), // must stringify arrays as a peculiarity of the zapier api
    shippingRevenue: project.shippingRevenue,
  }

  return data
}

function getBillingEmail(customer){
  switch(customer.email) {
    case "autotiv@all3dp.com":
      return "invoice@all3dp.com"
      break; // unnecessary after return, but good to keep here for future refactoring

    case "3dhubs@platform.usethis":
      return "fakeaddress@3dhubs.fake"
      break; // unnecessary after return, but good to keep here for future refactoring

    case "fictiv@platform.usethis":
      return "schuyler@fictiv.com, ar@fictiv.com"
      break; // unnecessary after return, but good to keep here for future refactoring

    case "3dexperiencemarketplace@platform.usethis":
      return "fake@makemarketplace.com"
      break; // unnecessary after return, but good to keep here for future refactoring

    default:
      return "accounting@autotiv.com"
    }
}

function getLineItems(project, MATERIAL_TRANSLATIONS){
  const lineItems = project.partGroups.flatMap(
    ptg => {
      const materialId = ptg.processId === 'injMoldingPart' ? ptg.materialIdProductionToolIsDesignedFor : ptg.materialId
      let material = TranslateMaterialId(ptg.processId, materialId, MATERIAL_TRANSLATIONS)
      material = appendInfillIfNeeded(material, ptg.processId, ptg.infillPercentage)

      const partGroupLineItem = {
        id: 1, // magic number from Quickbooks
        description: `${ptg.part.fileName} (${getProcessDisplay(ptg)}, ${material})`,
        quantity: ptg.quantity,
        price: ptg.quantity * ptg.unitPrice,
      }
      const ptpcLineItems = getPtpcLineItems(ptg, MATERIAL_TRANSLATIONS)

      return([partGroupLineItem, ...ptpcLineItems])
    }
  )

  if(project.expediteFee > 0){
    lineItems.push(
      {
        id: 1, // magic number from Quickbooks
        description: "Expedite Fee",
        quantity: 1,
        price: project.expediteFee,
      }
    )
  }

  if(project.paymentLocation === 'CC - Quote Tool'){
    lineItems.push(
      {
        id: 72, // magic number from Quickbooks
        description: "Stripe Fee",
        quantity: 1,
        price: calculateStripeFee(project.totalSale),
      }
    )
  }

  if(project.customer.email === 'autotiv@all3dp.com'){
    const fees = calculateAll3DPFees(project.subTotal)
    lineItems.push(
      {
        id: 104, // magic number from Quickbooks
        description: `All3DP Fee (prod: $${fees.prod} pmm: $${fees.prodMinusMargin}} pps: $${fees.prodPremiumShare} fee = pmm - prod - pps`,
        quantity: 1,
        price: fees.total,
      }
    )
  }

  return lineItems
}

function getPtpcLineItems(ptg, MATERIAL_TRANSLATIONS){
  const ptpcProcess = getProcessDisplay(ptg, true)

  return ptg.productionToolPartConfigurations.map(
    ptpc => {
      const ptpcMaterial = TranslateMaterialId(ptpc.processId, ptpc.materialId, MATERIAL_TRANSLATIONS)
      const ptpcColor = getPtpcColor(ptpc)
      return ({
        id: 1, // magic number from Quickbooks
        description: `${ptg.part.fileName} (${ptpcProcess}, ${ptpcMaterial}, ${ptpcColor})`,
        quantity: ptpc.quantity,
        price: ptpc.quantity * ptpc.unitPrice,
      })
    }
  )
}

function calculateAll3DPFees(subTotal){
  const ALL_3DP_MARGIN = .125

  const prod = roundToTwoDigits(subTotal / 1.2)
  const prodMinusMargin = roundToTwoDigits((1 - ALL_3DP_MARGIN) * prod)

  const totalProdPremiumShare = subTotal - prod // 20% fee charged to their customer, rounded to a price (2 digits)
  const prodPremiumShare = roundToTwoDigits(totalProdPremiumShare / 2) // our half of that, rounded to a price again

  const total = roundToTwoDigits(+prodMinusMargin - prod - prodPremiumShare)

  return {
    prod,
    prodMinusMargin,
    prodPremiumShare,
    total,
    subTotal,
  }
}

function roundToTwoDigits(number){
  const roundedNumber = Math.round(number * 100)/100
  const twoDigits = roundedNumber.toFixed(2)
  return twoDigits
}

function calculateStripeFee(totalSale){
  return (-0.3 + (-0.029 * totalSale))
}

function getProcessDisplay(partGroup, isPtpc){
  if(partGroup.processId === 'injMoldingPart'){
    return isPtpc ? 'IM Part' : 'Injection Mold'
  }
  if(partGroup.processId === 'casting'){
    return isPtpc ? 'Casting Part' : 'Casting Mold'
  }
  return partGroup.process
}

function getPtpcColor(ptpc){
  const colorObj = COLORS
    .find(colorObj => colorObj.value === ptpc.color)

  const ptpcColor = colorObj ? colorObj.label : ''
  return ptpcColor
}

