import React,{Fragment, useContext, useState} from 'react'
import { Button, Paper, TextField, Typography } from '@material-ui/core'
import CloneProjectButton from '../QuoteTool/CloneProjectButton.js'
import DownloadCsvButton from '../QuoteTool/DownloadCsvButton.js'
import DownloadQuoteButton from '../QuoteTool/DownloadQuoteButton.js'
import EditProjectButton from './EditProjectButton.js'
import GdriveButtons from '../Common/GdriveButtons.js'
import {SnackBarContext} from '../Common/SnackBarStore'
import sendChangeRequestEmail from './sendChangeRequestEmail.js'
import USER_TYPES from '../../utils/USER_TYPES.js'

export default function DmeSummarySections({
  cancelProject,
  classes,
  cloneProject,
  downloadProjectSummaryPdf,
  downloadQuotePdf,
  editProject,
  makeBookedProjectInvoice,
  navigateToRemakeRequestPage,
  navigateToShipmentTrackingPage,
  placeOrderFromWaitingForPayment,
  project,
  setProjectFieldAndSave,
  takeProjectFromCustomer,
  user,
}){
  const openSnackBarWithMessage = useContext(SnackBarContext)

  return (
    <Fragment>
      <Workflows
        cancelProject={cancelProject}
        cloneProject={cloneProject}
        downloadProjectSummaryPdf={downloadProjectSummaryPdf}
        downloadQuotePdf={downloadQuotePdf}
        editProject={editProject}
        project={project}
        classes={classes}
        makeBookedProjectInvoice={makeBookedProjectInvoice}
        navigateToRemakeRequestPage={navigateToRemakeRequestPage}
        navigateToShipmentTrackingPage={navigateToShipmentTrackingPage}
        openSnackBarWithMessage={openSnackBarWithMessage}
        placeOrderFromWaitingForPayment={placeOrderFromWaitingForPayment}
        takeProjectFromCustomer={takeProjectFromCustomer}
        user={user}
      />
      {project.projectStatus !== 'Quoting' ?
      <ChangeRequest
        classes={classes}
        openSnackBarWithMessage={openSnackBarWithMessage}
        project={project}
        setProjectFieldAndSave={setProjectFieldAndSave}
      />
       : null}
    </Fragment>
  )
}

function ChangeRequest({ classes, openSnackBarWithMessage, project, setProjectFieldAndSave }){
  const [ isSendingChangeRequest, setIsSendingChangeRequest ] = useState(false)

  const onChangeRequstButtonClick = () => {
    if(!project.changeRequestNotes.length){
      openSnackBarWithMessage('Add a message to send to logistics')
      return
    }

    setIsSendingChangeRequest(true)
    sendChangeRequestEmail(project)
      .then(() => openSnackBarWithMessage('Change request email sent to logistics!'))
      .catch(err => openSnackBarWithMessage(`Change request email failed to send: ${err.message}`))
      .finally(() => setIsSendingChangeRequest(false))
  }

  return (
    <Paper className={classes.paperSection} elevation={4}>
      <div className='dme-summary-sections-section'>
        <Typography variant='h6'>
          Change Request
        </Typography>
        <TextField
          label="Change Request Notes to Logistics"
          multiline
          name="changeRequestNotes"
          onChange={e => setProjectFieldAndSave("changeRequestNotes", e.target.value)}
          rowsMax={8}
          variant="outlined"
          value={project.changeRequestNotes}
        />
        <div>
          <Button
            color="secondary"
            disabled={isSendingChangeRequest}
            onClick={onChangeRequstButtonClick}
            variant='contained'
          >
            { isSendingChangeRequest ? 'Sending' : 'Submit' }
          </Button>
        </div>
      </div>
    </Paper>
  )
}

function Workflows({
  cancelProject,
  classes,
  cloneProject,
  downloadProjectSummaryPdf,
  downloadQuotePdf,
  editProject,
  makeBookedProjectInvoice,
  navigateToRemakeRequestPage,
  navigateToShipmentTrackingPage,
  openSnackBarWithMessage,
  placeOrderFromWaitingForPayment,
  project,
  takeProjectFromCustomer,
  user,
}){
  return (
    <Paper className={classes.paperSection} elevation={4}>
      <div className='dme-summary-sections-section'>
        <div style={{ display: 'flex', gap: '2em', alignItems: 'center' }}>
          <Typography variant='h6'>
            Project Status - {project.projectStatus}
          </Typography>
          { user.type === USER_TYPES.DME ?
            <CloneProjectButton
              cloneProject={cloneProject}
            />
            : null }
        </div>

        {project.projectStatus === 'Building' ?
        <div className='dme-summary-sections-additional-workflows'>
        <Typography variant='subtitle2'>
          Navigation
        </Typography>
          <>
          <Button
            color="primary"
            onClick={() => navigateToShipmentTrackingPage(project.projectNumber)}
            variant='contained'
          >
            Shipment Tracking
          </Button>
          <Button
            color="primary"
            onClick={() => navigateToRemakeRequestPage(project.projectNumber)}
            variant='contained'
          >
            Remake Request
          </Button>
           </>
        </div>
         : null
        }
        {project.projectStatus === 'Building' ?
         <div className='dme-summary-sections-additional-workflows'>
           <Typography variant='subtitle2'>
             Actions
           </Typography>
           <MakeBookedProjectInvoiceButton
             makeBookedProjectInvoice={makeBookedProjectInvoice}
           />
          <DownloadQuoteButton
            color="primary"
            downloadQuote={project.projectStatus === 'Quoting' ? downloadQuotePdf : downloadProjectSummaryPdf}
            label={project.projectStatus === 'Quoting' ? "Download Quote" : "Download Project Summary"}
          />
          <GdriveButtons
            openSnackBarWithMessage={openSnackBarWithMessage}
            project={project}
          />
           <DownloadCsvButton
             project={project}
           />
         </div>
         : null}
         <div className='dme-summary-sections-additional-workflows'>
           <Typography variant='subtitle2'>
             Change Status
           </Typography>
           {project.projectStatus === 'Quoting' ?
            <Button
              color="primary"
              onClick={takeProjectFromCustomer}
              variant='contained'
            >
              Make Status Awaiting Autotiv Review
            </Button>
            : null
           }

           {project.projectStatus === 'Waiting for Payment' ?
            <>
            <BookOrderButton
              placeOrderFromWaitingForPayment={placeOrderFromWaitingForPayment}
            />
            <Button
                color="primary"
                onClick={cancelProject}
                variant="contained"
              >
                Cancel Project
              </Button>
            </>
            : null
           }

           {project.projectStatus === 'Building' ?
            <>
              <EditProjectButton
                onClick={editProject}
              />
              <Button
                color="secondary"
                onClick={cancelProject}
                variant="contained"
              >
                Cancel Project
              </Button>
            </>
            : null}
         </div>
      </div>
    </Paper>
  )
}

function BookOrderButton({placeOrderFromWaitingForPayment}){
  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => {
    setIsLoading(true)
    placeOrderFromWaitingForPayment()
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Button
      color="secondary"
      disabled={isLoading}
      onClick={onClick}
      variant='contained'
    >
      { isLoading ? 'Booking...' : 'Book Paid Project' }
    </Button>
  )}

function MakeBookedProjectInvoiceButton({makeBookedProjectInvoice}){
  return(
    <Button
      color="primary"
      onClick={makeBookedProjectInvoice}
      variant="contained"
    >
      Make Invoice
    </Button>
  )
}
