import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export async function pollDatabaseForInjectionMoldingWallThicknessResult(partNumber) {
  const FIELD_SUCCESS = [ 'injectionMoldingWallThicknessResultFileId' ]
  const FIELD_FAIL = [ 'injectionMoldingWallThicknessError' ]

  const throttle = 3000
  const TWENTY_FIVE_MINUTES_IN_MS = 1500000
  const timeout = TWENTY_FIVE_MINUTES_IN_MS

  return pollDatabaseForResult(partNumber, FIELD_SUCCESS, FIELD_FAIL, throttle, timeout)
}
