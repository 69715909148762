import React,{ useCallback, useState } from "react"
import { TextField } from "@material-ui/core"
import debounce from "lodash/debounce";

export const NotesAtom = ({ disabled, label, name, onChange, value, ...otherProps }) => {
  const [ notes, setNotes ] = useState(value)

  /*
    In an attempt to reduce API calls to the back end
    we are trying to only update parent state once the
    user notes are done being typed.

    HOWEVER this creates a bug where a user can checkout
    with notes if the input does not lose focus.
    Hence below, we update parent state once, then switch to
    updating local state, until the input loses focus (onBlur)
  */

  const DEBOUNCE_TIME = 2000

  /*
    useCallback with no dependency array will only define the funcion once
    instead of on every render of this component. This allows _.debounce
    to maintain a single scope throughout the lifecycle of the component
    which is necessary for debounce to work properly
  */
  const onChangeDebounced = useCallback(debounce((e) => onChange(e), DEBOUNCE_TIME), [])

  const notesHandler = event => {
    if(event.target.value.length === 0 || event.target.value.length === 1){
      onChange(event)
      setNotes(event.target.value)
      return
    }
    else{
      onChangeDebounced(event)
      setNotes(event.target.value)
      return
    }
  }

  const onBlurHandler = event => {
    onChangeDebounced.cancel() // dont also update state in the future
    if(value !== notes){
      onChange(event)
    }
  }

  return(
    <TextField
      disabled={disabled}
      label={label ? label : "Part Notes"}
      multiline={true}
      name={name ? name : "partNotes"}
      onBlur={onBlurHandler}
      onChange={notesHandler}
      rowsMax={5}
      fullWidth={true}
      value={notes}
      variant="outlined"
      {...otherProps}
    />
  )
}
