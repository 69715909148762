import React,{useState} from 'react'
import { Button } from '@material-ui/core'
import refreshFiles from './refreshFiles.js'

export default function RefreshFilesButton({openSnackBarWithMessage,projectNumber}){
  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => {
    setIsLoading(true)
    refreshFiles(projectNumber)
      .catch(e => openSnackBarWithMessage(e.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return(
    <Button
      color="primary"
      disabled={isLoading}
      onClick={onClick}
      variant='contained'
    >
      { isLoading ? 'Refreshing...' : 'Refresh Gdrive Files' }
    </Button>
  )
}
