/*
  FORMAT_PRICE edits the a given number value to make sure it is formatted properly as a cost
  value should be displayed (ex: '56' becomes '$56.00')
*/
export function formatPrice(unitPrice){
  if(isNaN(unitPrice) || isNaN(String(unitPrice))){
    return ('-')
  }
  else if(0 > unitPrice){
    return `- $${ValueWithCommas(Number(-unitPrice).toFixed(2))}`
  }
  else{
    return `$${ValueWithCommas(Number(unitPrice).toFixed(2))}`
  }
}

/*
  VALUE_WITH_COMMAS adds commas at the hundreds, thousands (etc)
*/
export function ValueWithCommas(x){
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export default formatPrice
