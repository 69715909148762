import React from 'react'
import {Button,Typography,withStyles} from '@material-ui/core'

const PdfPagingBar = props => {
  const isPreviousDisabled = !(props.currentPage > 1)
  const isNextDisabled = !(props.currentPage < props.totalPages)
  return(
    <div className={props.classes.container}>
      <div className={props.classes.previousButton}>
        <Button
          color="secondary"
          disabled={isPreviousDisabled}
          onClick={props.previousPage}
          variant="outlined"
        >
          Previous
        </Button>
      </div>
      <div className={props.classes.text}>
        <Typography variant="body2">Page {props.currentPage} of {props.totalPages}</Typography>
      </div>
      <div className={props.classes.nextButton}>
        <Button
          color="secondary"
          disabled={isNextDisabled}
          onClick={props.nextPage}
          variant="outlined"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    minWidth: '600px',
  },
  previousButton:{
    paddingRight: theme.spacing(2)
  },
  nextButton:{
    paddingLeft: theme.spacing(2)
  },
  text:{
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
})

export default withStyles(styles)(PdfPagingBar)
