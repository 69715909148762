import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function deleteOvermoldedInsertConfig(overmoldedInsertConfigId){
  let response
  try{
    response = await deleteOvermoldedInsertConfigRequest(overmoldedInsertConfigId)
  } catch (err){
    logUnexpected(err, { overmoldedInsertConfigId }, 'deleteOvermoldedInsertConfigRequest')
    throw err
  }

  let result
  try{
    result = parseDeleteOvermoldedInsertConfigResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseDeleteOvermoldedInsertConfigResponse', { overmoldedInsertConfigId })
    throw err
  }

  return result
}

function deleteOvermoldedInsertConfigRequest(overmoldedInsertConfigId){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.DELETE_OVERMOLDEDINSERT_ENDPOINT,
    data: {
      overmoldedInsertConfigId,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseDeleteOvermoldedInsertConfigResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.statusMessage === 'OK'){
      return response.data.statusMessage
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
