export default {
  setProject: (...args) => console.log(...args),
  project: {
    "projectNumber": 48630,
    "visibleSheetNames": "[\"Quote Request\"]",
    "projectManager": {
      "id": "5a8468431711945b357128de",
      "account_status": "active",
      "approval_status": "approved",
      "profile_keys_raw": [
        {
          "id": "profile_16",
          "identifier": "Admins"
        },
        {
          "id": "profile_29",
          "identifier": "Autotiv Employees"
        },
        {
          "id": "profile_12",
          "identifier": "Project Managers"
        },
        {
          "id": "profile_28",
          "identifier": "Office Managers"
        }
      ],
      "name": {
        "first": "-",
        "last": ""
      },
      "email": "nick@autotiv.com",
      "password": "**********",
      "userStatus": "active",
      "userRoles": [
        "profile_16",
        "profile_29",
        "profile_12",
        "profile_28"
      ],
      "# of Open Quote follow ups due today or earlier": 4611
    },
    "customer": {
      "id": "59c96b2bca418922b2e7ce71",
      "emailAndName": "nick@autotiv.com - Nick Woodward",
      "email": "nick@autotiv.com",
      "customerName": "Nick Woodward",
      "firstName": "Nick",
      "lastName": "Woodward",
      "company": {
        "id": "59dc0aa7ad867f328b253c94",
        "companyDomainAndName": "autotiv.com - Autotiv MFG",
        "companyDomain": "autotiv.com",
        "companyName": "Autotiv MFG",
        "billingEmail": "accounting@autotiv.com",
        "creditTerms": "Net 30",
        "creditLimit": "$10,000",
        "accountStatus": "Bronze",
        "potentialAccountStatus": "Small Team",
        "whiteLabelBool": false,
        "whiteLabelAddress": {},
        "shipOnCustomerAccountBool": "",
        "customerAccountShipCompany": "FedEx",
        "customerAccountShipNumber": "938938938",
        "customerAccountShipZip": "03079",
        "resellerBool": false,
        "companyNotes": "note",
        "website": "autotiv.com",
        "timeZone": "(GMT-05:00) New York",
        "numberOfMechanicalEngineers": 0,
        "contactEmail": "",
        "coldCampaign": "",
        "bdr": [],
        "bdrWinDup": false,
        "bdrWinDate": "",
        "accountExecutive": [
          {
            "id": "5e56a6b2dbab78001518f5c0",
            "identifier": "Dylan "
          }
        ]
      },
      "phone": {
        "formatted": "(888) 369-5442 x808",
        "ext": "808",
        "full": "8883695442",
        "number": "3695442",
        "area": "888"
      },
      "shippingCompany": "Autotiv",
      "shippingContact": "Nick Woodward",
      "shippingAddress": {
        "street": "50A Northwestern Drive",
        "street2": "Suite 7",
        "city": "Salem",
        "state": "New Hampshire",
        "zip": "03079",
        "country": "USA"
      },
      "customerNotes": "coolest customer ever. he actually is a god",
      "timeZone": "ET",
      "numberOfOpenCareFollowUpTasks": 0,
      "cellPhoneNumber": {
        "formatted": "(603) 490-1064",
        "full": "6034901064",
        "number": "4901064",
        "area": "603"
      },
      "lastOrderDate": "09/20/2019",
      "lastQuotedDate": "09/19/2019",
      "numClosedProjects": 359,
      "numNonClosedProjects": 572,
      "numDeliveredProjects": 14,
      "notWorthContacting": false,
      "coldCampaign": "",
      "customerTotalSales": 1430.48632604625,
      "numberOfProjects": 113,
      "numberOfProjectsBuilding": 16,
      "customerTotalSalesUnlinked": 859.255
    },
    "contactedThrough": "Customer Web App",
    "projectFolderLink": "https://drive.google.com/drive/folders/1Y0w81yeJS5YIK35CIP8VRKRF_sgaRuAK",
    "projectStatus": "Quoting",
    "quoteStatus": "Quote Requested Review Required",
    "operationsPerson": "",
    "totalSale": "113.27",
    "shippingRevenue": "0.00",
    "customerNotes": "",
    "supplierNotes": "",
    "paidExpediteLevel": 4,
    "orderDate": null,
    "paidShipDate": null,
    "paidArrivalDate": null,
    "paidShipMethod": "",
    "labelType": "Autotiv Label",
    "shipProvider": "FedEx",
    "shipAccountNumber": "Autotiv",
    "shipAccountZipCode": "",
    "shipCompany": "",
    "shipName": "",
    "paymentLocation": "",
    "paymentReferenceNumber": "",
    "onlineOrderNumber": "",
    "billingName": "Nick Woodward",
    "billingEmail": "accounting@autotiv.com",
    "lastQuoteMadeDate": null,
    "quoteNotes": "",
    "expediteLeadTimeOption4": "5",
    "expediteLeadTimeOption3": "3",
    "expediteLeadTimeOption2": "2",
    "expediteLeadTimeOption1": "0",
    "lastQuoteRevision": 0,
    "mkCncParam_markup": "2.1",
    "mkCastParam_markup": "1.8",
    "mkInjmdParam_partsMarkup": "1.8",
    "mkInjmdParam_moldMarkup": "1.8",
    "mkShtmlParam_markup": "2.1",
    "asParam_serviceType": "",
    "leadTimeRequest": 5,
    "shippingMethodRequest": "",
    "process": "Multi Process",
    "materials": "ABS-Like Polyurethane,ABS - White,Aluminum,Ductile ABS - Help me select a grade,Aluminum 5052,Other - See Notes",
    "partsListForQb": "1 x ABS-Like Polyurethane: has_large_dimension.stl<br />1 x ABS - White: has_mfg_issues.stl<br />1 x Aluminum: has_thin_walls.stl<br />1 x Ductile ABS - Help me select a grade: no_issues.stl<br />1 x Aluminum 5052: no_issues.stl<br />1 x Other - See Notes: no_issues.stl",
    "asParam_internalServiceShipment": "",
    "asParam_supplier": "",
    "asParam_shipMethodToInt": "",
    "asParam_finishingMarkup": "",
    "asParam_finishingDays": "",
    "customShippingPrice": "",
    "quoteAdjustment": "",
    "numberOfUniqueParts": 6,
    "quoteFollowUpDate": "04/20/2022",
    "reprintRequestShipping": "",
    "reprintRequestLeadTime": "",
    "reprintRequestNotes": "",
    "markupParam_totalFreight": "",
    "markupParam_supplierLeadTime": "",
    "markupParam_additionalBufferDays": "",
    "mkDmlsParam_markup": "2.1",
    "expediteLeadTimeOverride4": "",
    "expediteLeadTimeOverride3": "",
    "expediteLeadTimeOverride2": "",
    "expediteLeadTimeOverride1": "",
    "customerTrackingNumber": "",
    "partsGoogleDriveFolderId": "11k0wDnG_43bsmDVmoF3On38OEiGzJpzx",
    "changeRequestNotes": "FAKE: TESTING",
    "calculatedShippingPrice": "0.00",
    "chinaSmallPackageEstimate": "30.00",
    "platformCustomerName": "",
    "platformCustomerPhone": "",
    "platformCustomerCompany": "",
    "customerId": "59c96b2bca418922b2e7ce71",
    "quotingFormulaVersion": "",
    "projectAutoIncrement": 21890,
    "customerEmail": "nick@autotiv.com",
    "shipPhone": "",
    "shipEmail": "nick@autotiv.com",
    "expediteFee": "0.00",
    "customerReady": false,
    "subTotal": "113.27",
    "fixedPartsGoogleDriveFolderId": "1Eu00b--HYvr55LXHPH3hvx8HalRB2t6i",
    "customerAppQuoteType": "3dPrinting",
    "orderSubmittedDate": null,
    "orderSubmittedType": "",
    "createdAt": "04/18/2022",
    "id": "625dcc4be8f6d400225b34bf",
    "shipAddress": {
      "street": "",
      "street2": "",
      "city": "",
      "state": "",
      "zip": "",
      "country": ""
    },
    "overrides": {
      "standardLeadTime": "calculated",
      "yellowLeadTime": "calculated",
      "orangeLeadTime": "calculated",
      "redLeadTime": "calculated",
      "shippingPrice": "calculated"
    },
    "partGroups": [
      {
        "partGroupNumber": 231867,
        "part": {
          "partIdentifier": "",
          "partNumber": 763728,
          "knackRecordId": "",
          "fileName": "has_large_dimension.stl",
          "partNotes": "",
          "xDim": "35.00",
          "yDim": "180.00",
          "zDim": "35.00",
          "partVolume": "165597.73",
          "partSurfaceArea": "35718.09",
          "atRiskPartBool": true,
          "partFile": "1t4TrwWSwrv9VwHK-FFlIu3FSzfsd-iS_",
          "partFileS3": "4cede687-dc07-42ac-84de-7a000ad3cf68",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "ce7036d7-5c33-4e6c-a423-4b8a339fbb1a",
          "wallThicknessMaterialFileMM": "025f2df3-dd55-4ad0-af20-743f4b463dfc",
          "wallThicknessResultsMM": true,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "4a33fd9e-4a78-463a-b7dc-11a729de5b07",
          "xDimUnits": "35",
          "yDimUnits": "180",
          "zDimUnits": "35",
          "partVolumeUnits": "165597.73",
          "partSurfaceAreaUnits": "35718.09",
          "mfgAnalysisIssues": [],
          "riskAccepted": true,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": {
            "shells": "1",
            "badEdges": "0",
            "boundaryEdges": "0",
            "flippedTriangleCount": "0",
            "isManifold": "true"
          },
          "thumbnailS3id": "4dbb80aa-7188-410e-b779-8bef64f745dc",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "1484",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "35718.0942382813",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 0,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
          "cncMillOrTurn": null,
          "cncNumberOfAxes": "",
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": ""
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "Casting",
        "material": "ABS-Like Polyurethane",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "81.16",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "0.00",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": true,
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "casting",
        "materialId": "castingAbsPolyu",
        "finishId": "castingStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "b1e3feef",
        "userType": "customer"
      },
      {
        "partGroupNumber": 231868,
        "part": {
          "partIdentifier": "",
          "partNumber": 763729,
          "knackRecordId": "",
          "fileName": "has_mfg_issues.stl",
          "partNotes": "",
          "xDim": "6.85",
          "yDim": "6.00",
          "zDim": "22.73",
          "partVolume": "50.40",
          "partSurfaceArea": "478.58",
          "atRiskPartBool": true,
          "partFile": "1Muk-KP9AzmnLgBVqwVXn4vxQ4Rlric-M",
          "partFileS3": "7e2e95c7-2ecc-4a54-8eba-719c788120bf",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "d80f70e2-9294-4921-90ea-af345aedd618",
          "wallThicknessMaterialFileMM": "b221a557-c924-4d80-80d7-abd264b7ed32",
          "wallThicknessResultsMM": false,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "d0396e9f-fd79-4df0-9485-1f47d46fce8a",
          "xDimUnits": "6.85",
          "yDimUnits": "6",
          "zDimUnits": "22.73",
          "partVolumeUnits": "50.4",
          "partSurfaceAreaUnits": "478.58",
          "mfgAnalysisIssues": [],
          "riskAccepted": true,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": "",
          "thumbnailS3id": "e7f15b4e-fd6f-426b-8530-8643901b946d",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "16334",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "478.577272911556",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0.0224502813071013",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 1,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0.0224502813071013",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
          "cncMillOrTurn": null,
          "cncNumberOfAxes": "",
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": ""
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "CNC",
        "material": "ABS - White",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "2.17",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "0.00",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "cnc",
        "materialId": "cncABSWhite",
        "finishId": "cncStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "34dde8a9",
        "userType": "customer"
      },
      {
        "partGroupNumber": 231869,
        "part": {
          "partIdentifier": "",
          "partNumber": 763730,
          "knackRecordId": "",
          "fileName": "has_thin_walls.stl",
          "partNotes": "",
          "xDim": "0.39",
          "yDim": "0.39",
          "zDim": "0.39",
          "partVolume": "0.06",
          "partSurfaceArea": "0.93",
          "atRiskPartBool": true,
          "partFile": "1GqIkPJQKMXWXIHjFEuAktW7Tot42HK08",
          "partFileS3": "6526b768-7f06-46d1-ad01-8b3b6a9d6a45",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "8926b455-3262-485f-a065-fd070542a0f1",
          "wallThicknessMaterialFileMM": "a70862e4-8dfb-4fe3-a239-a00c2410723f",
          "wallThicknessResultsMM": false,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "3845c73c-5e5e-4536-9d60-6a3b515f3e29",
          "xDimUnits": "0.39",
          "yDimUnits": "0.39",
          "zDimUnits": "0.39",
          "partVolumeUnits": "0.06",
          "partSurfaceAreaUnits": "0.93",
          "mfgAnalysisIssues": [],
          "riskAccepted": true,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": {
            "shells": "1",
            "badEdges": "0",
            "boundaryEdges": "0",
            "flippedTriangleCount": "0",
            "isManifold": "true"
          },
          "thumbnailS3id": "b8d12b78-b651-41d9-bd7d-5a0ca7672cfb",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "684",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "0.930001795291901",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0.930001795291901",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 1,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0.930001795291901",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
          "cncMillOrTurn": null,
          "cncNumberOfAxes": "",
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": ""
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "DMLS",
        "material": "Aluminum",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "2.00",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "0.00",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": true,
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "dmls",
        "materialId": "dmlsAluminum",
        "finishId": "dmlsStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "f47aee12",
        "userType": "customer"
      },
      {
        "partGroupNumber": 231870,
        "part": {
          "partIdentifier": "",
          "partNumber": 763731,
          "knackRecordId": "",
          "fileName": "no_issues.stl",
          "partNotes": "",
          "xDim": "73.99",
          "yDim": "11.00",
          "zDim": "94.95",
          "partVolume": "49708.54",
          "partSurfaceArea": "17601.20",
          "atRiskPartBool": true,
          "partFile": "1pF7slxhmReNTXaFTjxX-cEkoqBQrLWu6",
          "partFileS3": "250e9f35-2ba6-45e6-8d4f-a7fc12f4e81e",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "10db038a-7490-41c9-b4f0-44a466c9f65e",
          "wallThicknessMaterialFileMM": "81192fd5-d6f9-41c6-b8c4-787ddd582999",
          "wallThicknessResultsMM": true,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "ea0953c0-0e4a-4a4f-96b2-e990745f16ec",
          "xDimUnits": "73.99",
          "yDimUnits": "11",
          "zDimUnits": "94.95",
          "partVolumeUnits": "49708.54",
          "partSurfaceAreaUnits": "17601.2",
          "mfgAnalysisIssues": [],
          "riskAccepted": false,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": {
            "shells": "1",
            "badEdges": "0",
            "boundaryEdges": "0",
            "flippedTriangleCount": "0",
            "isManifold": "true"
          },
          "thumbnailS3id": "6cdf0c34-5396-4d0a-a72b-36f33d2a9f13",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "3484",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "17601.1999053955",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 0,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
          "cncMillOrTurn": null,
          "cncNumberOfAxes": "",
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": ""
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "Inj Mold: Part",
        "material": "Ductile ABS - Help me select a grade",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "27.94",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "0.00",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "injMoldingPart",
        "materialId": "injMoldingPartDuctileABSHelpmeselectagrade",
        "finishId": "injMoldingPartStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "80df07d1",
        "userType": "customer"
      },
      {
        "partGroupNumber": 231871,
        "part": {
          "partIdentifier": "",
          "partNumber": 763744,
          "knackRecordId": "",
          "fileName": "no_issues.stl",
          "partNotes": "",
          "xDim": "73.99",
          "yDim": "11.00",
          "zDim": "94.95",
          "partVolume": "49708.54",
          "partSurfaceArea": "17601.20",
          "atRiskPartBool": true,
          "partFile": "1kJVyLjusfdqInNjX5s1BZ0TBoSfQsRQZ",
          "partFileS3": "c8f597b1-e560-4a70-a96e-855de39f61ec",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "778a1f5a-fd6a-4cbc-89bf-885670fea17c",
          "wallThicknessMaterialFileMM": "30ca4954-1769-473b-aed4-188a3c916f63",
          "wallThicknessResultsMM": true,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "7fb7cdde-4764-41d1-bc35-e73339ba2775",
          "xDimUnits": "73.99",
          "yDimUnits": "11",
          "zDimUnits": "94.95",
          "partVolumeUnits": "49708.54",
          "partSurfaceAreaUnits": "17601.2",
          "mfgAnalysisIssues": [],
          "riskAccepted": false,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": {
            "shells": "1",
            "badEdges": "0",
            "boundaryEdges": "0",
            "flippedTriangleCount": "0",
            "isManifold": "true"
          },
          "thumbnailS3id": "ff9ae5d6-a6ef-43c3-902e-902eb03e876e",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "3484",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "17601.1999053955",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 0,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
          "cncMillOrTurn": null,
          "cncNumberOfAxes": "",
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": ""
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "Sheet Metal",
        "material": "Aluminum 5052",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "27.94",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "0.00",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "sheetMetal",
        "materialId": "sheetMetalAluminum5052",
        "finishId": "sheetMetalStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "5f1f8b8d",
        "userType": "customer"
      },
      {
        "partGroupNumber": 231872,
        "part": {
          "partIdentifier": "",
          "partNumber": 763751,
          "knackRecordId": "",
          "fileName": "no_issues.stl",
          "partNotes": "",
          "xDim": "1.00",
          "yDim": "1.00",
          "zDim": "1.00",
          "partVolume": "1.00",
          "partSurfaceArea": "1.00",
          "atRiskPartBool": true,
          "partFile": "",
          "partFileS3": "",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "",
          "wallThicknessMaterialFileMM": "",
          "wallThicknessResultsMM": null,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": null,
          "s3ObjFileId": "",
          "xDimUnits": "1",
          "yDimUnits": "1",
          "zDimUnits": "1",
          "partVolumeUnits": "1",
          "partSurfaceAreaUnits": "1",
          "mfgAnalysisIssues": [
            "Failed to process file dimensions"
          ],
          "riskAccepted": false,
          "wallThicknessTaskIdMM": "",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "Missing the following required values in param extract task message: [s3Id]",
          "meshProperties": "",
          "thumbnailS3id": "",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "",
          "netfabbWallThicknessTotalSurfaceAreaInches": null,
          "netfabbWallThicknessTotalSurfaceAreaMM": null,
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": null,
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": null,
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": null,
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": null,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": null,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": null,
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": null,
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": null,
          "cncMillOrTurn": null,
          "cncNumberOfAxes": null,
          "cncSetupsPerPart": null,
          "cncSmallestToolDiameter": null,
          "createdAt": "2022-04-18T20:41:37.516Z",
          "updatedAt": "2022-04-26T19:21:17.729Z"
        },
        "project": 48630,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "Inj Mold: Mold",
        "material": "Other - See Notes",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "0",
        "fdmParam_perPartFinishingTimeMin": "0",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "injMoldingMold",
        "materialId": "injMoldingMoldOther",
        "finishId": "injMoldingMoldStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": "",
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "numberOfSlides": "",
        "numberOfCollapsingCores": "",
        "averageWallThickness": "",
        "standardDeviationWallThickness": "",
        "productionToolPartConfigurations": [],
        "attachments": [],
        "id": "18be9193",
        "userType": "customer"
      }
    ],
    "makeOrders": [],
    "shipments": [],
    "quotes": [],
    "attachments": [],
    "calculations": {
      "leadTimePrices": []
    },
    "updateId": "2022-04-28T16:31:32.471Z"
  }
}
