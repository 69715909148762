const SHIPPING_METHODS = {
  NONE: "None",
  ONE_DAY: "1 Day",
  TWO_DAY: "2 Day",
  THREE_DAY: "3 Day",
  FIVE_DAY: "5 Day",
  NEXT_DAY_AIR_EARLY: "Next Day Air Early",
  NEXT_DAY_SAVER: "Next Day Saver",
  ONE_DAY_SATURDAY: "1 Day Saturday",
  GROUND_INTERNATIONAL_NA: "Ground International NA",
  INTERNATIONAL_NA: "International NA",
  INTERNATIONAL_EUR: "International EUR",
  INTERNATIONAL_ASIA: "International ASIA",
  PICKUP: "Pickup",
}

export default SHIPPING_METHODS
