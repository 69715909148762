import React, { Fragment, useState } from 'react'
import { AppWideSnackbar, DialogList } from '../Common'

/*
**************** DISCLAIMER ********************

This is not necessarily the best usage pattern for these components but was a valuable itermediate in ViewController cleanup

DialogList
- was only being used in two places, should be inlined unless we find a more global useage pattern for the component

AppWideSnackbar
- This will eventually be replaced by utilizing a global snackbar store
  We've chosen not to use the store for class compnents as the noise isnt any better than prop threading
  Once we have all functional components we should be able to standardize to the store only and remove AppWideSnackbar
*/

const withAlerts = (Component) => (props) => {
  const [dialogMessageArray, setDialogMessageArray] = useState([])
  const [dialogTitle, setDialogTitle] = useState('')
  const [isDialogListOpen, setIsDialogListOpen] = useState(false)

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  return (
    <Fragment>
      <Component
        openDialog={(messageArray, title) => {
          setDialogMessageArray(messageArray)
          setDialogTitle(title)
          setIsDialogListOpen(true)
        }}
        openSnackBarHandler={(message) => {
          setSnackBarMessage(message)
          setIsSnackbarOpen(true)
        }}
        closeSnackbar={() => setIsSnackbarOpen(false)}
        {...props}
      />
      <DialogList
        dialogMessageArray={dialogMessageArray}
        dialogTitle={dialogTitle}
        onClose={() => setIsDialogListOpen(false)}
        open={isDialogListOpen}
      />

      <AppWideSnackbar
        close={() => setIsSnackbarOpen(false)}
        message={snackBarMessage}
        open={isSnackbarOpen}
      />

    </Fragment>
  )
}

export default withAlerts

