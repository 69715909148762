import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function removeAttachment(fileId) {
  if(!fileId){
    throw new Error ("Missing fileId from removeAttachment arguments")
  }
  let result
  let response
  try {
    response = await removeAttachmentRequest(fileId)
  } catch (err) {
    logUnexpected(err, { fileId }, "removeAttachmentRequest")
    throw err
  }
  try {
    result = parseRemoveAttachmentResponse(response)
  } catch (err) {
    // log & rethrow
    logUnexpected(err, { response }, "parseRemoveAttachmentResponse", { fileId })
    throw err
  }
  return result
}

function removeAttachmentRequest(fileId) {
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: config.REMOVE_ATTACHMENT_ENDPOINT,
        data: {
          "token": config.MICROSERVICE_TOKEN,
          "fileId": fileId,
        }
    })
    .then(val => ({status: val.data.statusCode}))
}

function parseRemoveAttachmentResponse(response) {
  if (response.status === "200") {
    return response
  } else {
    throw Error(`Unexpected status code while removing attachment : ${JSON.stringify(response.status)}`)
  }
}
