import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export async function startInjectionMoldingWallThicknessTask(part) {
  let response
  try {
    response = await startInjectionMoldingWallThicknessTaskRequest(part)
  } catch (err) {
    logUnexpected(err, { part }, 'startInjectionMoldingWallThicknessTaskRequest')
    throw err
  }

  let result
  try {
    result = parseStartInjectionMoldingWallThicknessTaskResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseStartInjectionMoldingWallThicknessTaskResponse', { part })
    throw err
  }

  return result
}

async function startInjectionMoldingWallThicknessTaskRequest(part) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.START_IM_WTA,
    data: {
      fileSize: part.fileSize,
      message: {
        s3Id: part.partFileS3,
        fileName: part.fileName,
        partNumber: part.partNumber.toString(),
        taskName: 'im-wall-thickness',
      }
    },
  })
  return { status: response.status, data: response.data }
}

function parseStartInjectionMoldingWallThicknessTaskResponse(response) {
  if (response.status === 200) {
    if (response.data.data) {
      if (response.data.data.taskUUID) {
        return response.data.data.taskUUID
      }
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.respBody = response.data
    throw unexpectedBodyFor200Error
  } else if (response.status === 400) {
    let unexpectedError = new Error(`Unexpected error response message: ${response.data.message}`)
    unexpectedError.respBody = response.data
    throw unexpectedError
  } else {
    let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
    unexpectedStatusCodeError.respBody = response.data
    throw unexpectedStatusCodeError
  }
}
