import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SelectOption } from "./SelectOption.js"
import { NumberField } from "./NumberField.js"
import { PercentageField } from './PercentageField.js'

const calculateBoundingBox = part => part.xDimUnits * part.yDimUnits * part.zDimUnits

function calculateDmeEstimatedVolume(part){
  const boundingBox = calculateBoundingBox(part)
  return boundingBox * part.dmeEstimatedRatioOfVolumeToBoundingBox
}

function calculateBoundingBoxSurfaceArea(part){
  return (
    part.xDimUnits * part.yDimUnits
    + part.xDimUnits * part.zDimUnits
    + part.yDimUnits * part.zDimUnits
  ) * 2
}

function calculateDmeEstimatedSurfaceArea(part){
  return calculateBoundingBoxSurfaceArea(part) * part.dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea
}

const DmeGeneralOverrides = ({ onChange, partGroup }) => {
  const onChangeHandler = e => onChange(e, partGroup.partGroupNumber)
  return(
    <Accordion
      style={{ backgroundColor: 'pink', margin: 0 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        style={{ backgroundColor: 'lightpink' }}
      >
        <Typography variant="h6" className="dme-section-header">
          General Overrides
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="dme-general-info">
          <div className="dme-general-info-form">
            <NumberField
              className="dme-general-override-number-field"
              label={`X Dimension (${partGroup.part.units})`}
              name="xDimUnits"
              onChange={onChangeHandler}
              value={partGroup.part.xDimUnits}
            />
            <NumberField
              className="dme-general-override-number-field"
              label={`Y Dimension (${partGroup.part.units})`}
              name="yDimUnits"
              onChange={onChangeHandler}
              value={partGroup.part.yDimUnits}
            />
            <NumberField
              className="dme-general-override-number-field"
              label={`Z Dimension (${partGroup.part.units})`}
              name="zDimUnits"
              onChange={onChangeHandler}
              value={partGroup.part.zDimUnits}
            />
            <SelectOption
              className="dme-general-override-select-field"
              label="DME Marked At-Risk"
              name="dmeMarkedAtRiskPartBool"
              onChange={onChangeHandler}
              value={String(partGroup.part.dmeMarkedAtRiskPartBool)}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </SelectOption>
            <TextField
              className="dme-general-override-number-field"
              label="At Risk?"
              disabled
              value={partGroup.part.atRiskPartBool ? "Yes" : "No"}
            />
            <NumberField
              className="dme-general-override-number-field"
              label={`Volume (${partGroup.part.units})`}
              name="partVolumeUnits"
              onChange={onChangeHandler}
              value={partGroup.part.partVolumeUnits}
            />
            <NumberField
              className="dme-general-override-number-field"
              label={`Surface Area (${partGroup.part.units})`}
              name="partSurfaceAreaUnits"
              onChange={onChangeHandler}
              value={partGroup.part.partSurfaceAreaUnits}
            />
          </div>
          <Typography variant="subtitle1" className="dme-section-header">
            2D Drawing Inputs
          </Typography>
          <div className="dme-general-info-form">

            <div className="tools-tool-fields">
              <NumberField
                className="dme-general-override-drawing-input-field"
                disabled={true}
                label={`Bounding Box ${partGroup.part.units}^3`}
                value={calculateBoundingBox(partGroup.part)}
              />
              <PercentageField
                className="dme-general-override-drawing-input-field"
                label="% of Vol in BB"
                name="dmeEstimatedRatioOfVolumeToBoundingBox"
                onChange={onChangeHandler}
                value={partGroup.part.dmeEstimatedRatioOfVolumeToBoundingBox}
              />
              <NumberField
                className="dme-general-override-drawing-input-field"
                disabled={true}
                label={`Est Vol ${partGroup.part.units}^3`}
                name="estimatedVolume"
                value={calculateDmeEstimatedVolume(partGroup.part)}
              />
            </div>

            <div className="tools-tool-fields">
              <NumberField
                className="dme-general-override-drawing-input-field"
                disabled={true}
                label={`BB Surface Area ${partGroup.part.units}^2`}
                value={calculateBoundingBoxSurfaceArea(partGroup.part)}
              />
              <PercentageField
                className="dme-general-override-drawing-input-field"
                label="% of SA in BBSA"
                name="dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea"
                onChange={onChangeHandler}
                value={partGroup.part.dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea}
              />
              <NumberField
                className="dme-general-override-drawing-input-field"
                disabled={true}
                label={`Est Surface Area ${partGroup.part.units}^2`}
                name="estimatedSurfaceArea"
                value={calculateDmeEstimatedSurfaceArea(partGroup.part)}
              />
            </div>

          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DmeGeneralOverrides
