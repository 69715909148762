import { convertDimensionsEtcInPartGroup } from './convertDimensionsEtcInPartGroup.js'
/*
  unitHandling

  TL;DR: we have implicit "in what units are these numbers" qualification to our data, and we shouldn't.

  @data-rectification

  Context:
  - the output (xDimUnits, etc.) we have for the units (as "real"/original data) are the netfabb results
  - the derived (xDim, etc.) are what will be passed into pricing engine
  - why do we do this on load? so that we can pass into pricing-engine for recalculation 
  - this process should happen in quote tool at least once so that by the time
  it gets to the checkout page, all the units are "there"
  - 1 1 1 -- is that 1 cubic inch or 1 cubic inch?
  - if that's 1 cubic inch, we need to translate that to 25.4 mm and pass that into pricing engine
  - our pricing engine takes that in mm
  - xDimUnits == output from netfabb
  - xDim == what we will pass into pricing engine
  - Spreadsheets only use mm, but we arent in spreadsheets anymore

  Longterm: 
    like ~half of these fields should be grabbed from the database, and
  then derive from the remaining fields and the units (?). We should not have
  unitless dimensions *ever* in our app, and have to do this implicit
  interpretation.
    { units: cm, x: 11, y: 22, z: 33 }
    - or -
    ... cmDims: { x: 11, y: 22, z: 33 }
    ... inchesDims: { x: 11, y: 22, z: 33 }

    Then in the database:
    - unitsOfDimensions: ENUM{cm,in}
    - x: 1
    - y: 2
    - z: 3
*/
export const unitHandling = convertDimensionsEtcInPartGroupsCollection // @fixme to below
// export const convertDimsInEachPartGroup = convertDimensionsEtcInPartGroupsCollection
function convertDimensionsEtcInPartGroupsCollection(partCollection) {
  let ptgs = Array.from(partCollection) // @skeptical: this looks errant
  ptgs = ptgs.map(
    convertDimensionsEtcInPartGroup
  )

  return ptgs
}

