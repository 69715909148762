const {getNoBidTolerancesString} = require('./getNoBidTolerancesString.js')

module.exports = {getTolerancesLabel}

function getTolerancesLabel (tolerances, toleranceClassifications, isSupplierFacing) {
  // TODO Add in handling for different types of tolerances. Currently we
  // convert everything into a geometric tolerance.

  const dimensionalCount = tolerances.filter(tolerance=>tolerance.type === "dimensional").length
  // const angularCount = tolerances.filter(tolerance=>tolerance.type === "angular").length
  // const flatnessCount = tolerances.filter(tolerance=>tolerance.type === "flatness").length
  const geometricCount = tolerances.filter(tolerance=>tolerance.type === "geometric").length
  let label = tolerances.length ? `${tolerances.length} Features, ` : ""

  const tolerancesArray = []
  if(dimensionalCount){
    tolerancesArray.push(`${dimensionalCount} dimensional`)
  }
  // if(angularCount){
  //   tolerancesArray.push(`${angularCount} angular`)
  // }
  // if(flatnessCount){
  //   tolerancesArray.push(`${flatnessCount} flatness`)
  // }
  if(geometricCount){
    tolerancesArray.push(`${geometricCount} geometric`)
  }
  const tolerancesString = tolerancesArray.join(', ')
  label += tolerancesString
  if(label){
    label += '.'
  }

  if(toleranceClassifications){
    tolerances = tolerances.map(tolerance => {
      const tc = toleranceClassifications.find(tc => tc.id === tolerance.id)
      if(!tc){
        // while waiting for pricing engine
        return tolerance
      }

      const toleranceCopy = JSON.parse(JSON.stringify(tolerance)) // copy to not to append to original copy in state

      toleranceCopy.classification = tc.classification
      toleranceCopy.revisedUpperBound = tc.revisedUpperBound
      toleranceCopy.revisedLowerBound = tc.revisedLowerBound

      return toleranceCopy
    })
  }

  const labels = [label]
  const noBidTolerancesString = getNoBidTolerancesString(tolerances)

  if(noBidTolerancesString.length){
    if(isSupplierFacing){
      labels.push("The following dimensions labeled on the technical drawing must be held to the loosened specifications listed below:")
    } else {
      labels.push("The following dimensions labeled on your technical drawing are tighter than Autotiv's Quality Guarantee.")
      labels.push("Autotiv is guaranteeing these dimensions to the specifications listed below:")
    }

    labels.push(noBidTolerancesString)
  }

  return labels
}
