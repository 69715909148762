import React,{Fragment,useState} from 'react'
import {Button,Checkbox,CircularProgress,FormControlLabel,TextField,Typography,withStyles} from '@material-ui/core'
import { Link } from "@reach/router"
import { useToggledBoolean } from '../../utils'

function ChangePasswordPage({ changePassword, email, popupMessage, classes }){
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isOldPasswordRevealed, revealOldPasswordToggle] = useToggledBoolean(false)
  const [isNewPasswordRevealed, revealNewPasswordToggle] = useToggledBoolean(false)
  const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false)

  let changePasswordHandler = async () => {
    if (oldPassword && newPassword) {
      if (oldPassword.length < 8) {
        popupMessage('Old password must be at least 8 characters long')
        return
      }
      if (newPassword.length < 8) {
        popupMessage('New password must be at least 8 characters long')
        return
      }
      else{
        setIsCurrentlyLoading(true)
        await changePassword(oldPassword, newPassword)
        setIsCurrentlyLoading(false)
      }
    }
    else{
      popupMessage('Old and New password is required')
    }
  }

  return(
    <div align="center">
      <LoadingCircleMaybe classes={classes} currentlyLoading={isCurrentlyLoading}/>
      <FormWithLineBreaks
        classes={classes}
      >
        <HeaderText
          classes={classes}
          label={isCurrentlyLoading ? "AUTHENTICATING" : "Change Password"}
        />
        <EmailTextField classes={classes} email={email}/>
        <OldPasswordTextField
          changePasswordHandler={changePasswordHandler}
          classes={classes}
          isCurrentlyLoading={isCurrentlyLoading}
          isOldPasswordRevealed={isOldPasswordRevealed}
          oldPassword={oldPassword}
          onChange={setOldPassword}
        />
        <ShowPasswordCheckbox
          classes={classes}
          isPasswordRevealed={isOldPasswordRevealed}
          revealPasswordToggle={revealOldPasswordToggle}
        />
        <NewPasswordTextField
          changePasswordHandler={changePasswordHandler}
          classes={classes}
          isCurrentlyLoading={isCurrentlyLoading}
          isNewPasswordRevealed={isNewPasswordRevealed}
          newPassword={newPassword}
          onChange={setNewPassword}
        />
        <ShowPasswordCheckbox
          classes={classes}
          isPasswordRevealed={isNewPasswordRevealed}
          revealPasswordToggle={revealNewPasswordToggle}
        />
        <SubmitButton
          classes={classes}
          changePasswordHandler={changePasswordHandler}
          isCurrentlyLoading={isCurrentlyLoading}
        />
        <LinkHome/>
      </FormWithLineBreaks>
    </div>
  )
}

const EmailTextField = ({ classes, email }) => {
  return(
    <TextField
      className={classes.textField}
      disabled={true}
      inputProps={{min: "0"}}
      label="Email"
      margin="dense"
      name="email"
      type="text"
      value={email||''}
    />
  )
}

const OldPasswordTextField = ({ changePasswordHandler, classes, isCurrentlyLoading,
                                isOldPasswordRevealed, oldPassword, onChange }) => {
  const isTextFieldTypeTextOrPassword = isOldPasswordRevealed ? "text" : "password"
  return(
    <TextField
      autoFocus={true}
      className={classes.textField}
      disabled={isCurrentlyLoading}
      inputProps={{min: "0"}}
      label="Old Password - minimum 8 characters"
      margin="dense"
      name="oldPassword"
      onChange={e => onChange(e.target.value)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter'){changePasswordHandler()}
      }}
      type={isTextFieldTypeTextOrPassword}
      value={oldPassword||''}
    />
  )
}

const ShowPasswordCheckbox = ({ classes, isPasswordRevealed, revealPasswordToggle }) => {
  return(
    <div className={classes.flexBox}>
      <div className={classes.flexBoxLeft}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPasswordRevealed}
              onChange={revealPasswordToggle}
              value="show password"
            />
          }
          label="Show Password"
        />
      </div>
    </div>
  )
}

const NewPasswordTextField = ({ changePasswordHandler, classes, isCurrentlyLoading,
                                isNewPasswordRevealed, newPassword, onChange }) => {
  const isTextFieldTypeTextOrPassword = isNewPasswordRevealed ? "text" : "password"
  return(
    <TextField
      className={classes.textField}
      disabled={isCurrentlyLoading}
      inputProps={{min: "0"}}
      label="New Password - minimum 8 characters"
      margin="dense"
      name="newPassword"
      onChange={e => onChange(e.target.value)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter'){changePasswordHandler()}
      }}
      type={isTextFieldTypeTextOrPassword}
      value={newPassword||''}
    />
  )
}

const SubmitButton = ({ classes, changePasswordHandler, isCurrentlyLoading }) => {
  return(
    <Button
      className={classes.buttonSubmit}
      color="secondary"
      disabled={isCurrentlyLoading}
      onClick={changePasswordHandler}
      variant="contained"
    >
      SUBMIT
    </Button>
  )
}

const LoadingCircleMaybe = ({ classes, isCurrentlyLoading }) => {
  return(
    <div className={classes.loadingBox}>
      {isCurrentlyLoading && <CircularProgress color="secondary" size={200}/>}
    </div>
  )
}

const HeaderText = ({ classes, label }) => {
  return(
    <Typography className={classes.paperHeader} color="textPrimary" variant="h3">
      {label}
    </Typography>
  )
}

const LinkHome = () => {
  return(
    <Fragment>
      Don't need to change your password? Back to <Link to="../">Account Page</Link>.
    </Fragment>
  )
}

const FormWithLineBreaks = ({classes, children}) => {
  return(
    <div className={classes.loginDiv}>
      { children.map( (child, i) => {
          return (
            <Fragment key={i}> {/* fine to use simple index-key b/c children will never dynamically change */}
              {child}
              <br/>
            </Fragment>
          )
        })
      }
    </div>
  )
}

const styles = theme => ({
  buttonSubmit:{
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5),
    minWidth:'100px',
  },
  flexBox:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexBoxLeft:{
    paddingLeft: theme.spacing(5)
  },
  loadingBox:{
    height:'300px',
    paddingTop: '50px'
  },
  loginDiv: {
    marginBottom: '10%',
    width: '600px',
  },
  paperHeader:{
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign:'center'
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: '520px'
  }
})

export default withStyles(styles)(ChangePasswordPage)
