import { PROJECT_MANAGERS } from '../HomePage/PROJECT_MANAGERS.js'

export default function updateProjectManager(project, email){
  const projectManager = PROJECT_MANAGERS
        .find(pm => pm.email === email)

  if(email === project.projectManager.email){
    throw new Error('You are already the Project Manager!')
  }

  const knackProjectManagerObj = {
    id: projectManager.id,
    email: projectManager.email,
    name:{
      first: projectManager.name
    }
  }

  project.projectManager = knackProjectManagerObj

  return project
}
