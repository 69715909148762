import React from 'react'
import CenteredWrapper from './CenteredWrapper.js'

const WrongBrowser = (props) => {
  return(
    <CenteredWrapper>
      <div style={{}}>
        <h1>
          This browser is not supported by this application.
        </h1>
        <h2>
          Please contact us directly at <a href="mailto:contact@autotiv.com">contact@autotiv.com</a> to submit a quote request!
        </h2>
        <h3>List of supported browsers:<br/>
          Google Chrome (recommended)<br/>
          Safari<br/>
          Microsoft Edge<br/>
          Firefox<br/>
          Opera
        </h3>
      </div>
    </CenteredWrapper>
  )
}

export default WrongBrowser
