import React, {useState} from 'react'
import {Button,LinearProgress,MenuItem,Paper,Select,Typography} from '@material-ui/core'
import boxesForm from './boxesForm.css'
import EDIT_ALL_BOXES from './EDIT_ALL_BOXES.js'
import BoxesConfigurationTable from './BoxesConfigurationTable.js'

export default function BoxesForm(props){
  const initialBoxes = [...props.shipment.boxes]
        .sort(lowestToHighest)

  const [boxNumberToEdit, setBoxNumberToEdit] = useState(EDIT_ALL_BOXES)
  const [boxes, setBoxesState] = useState(initialBoxes)
  const setBoxes = boxes => {
    const boxesWithNumbers = boxes.map(decorateWithBoxNumbers)
    setBoxesState(boxesWithNumbers)
  }

  function addNewBox(){
    const partsInBox = props.project.partGroups
          .flatMap(partGroupToPartInBox)

    const boxesCopy = [...boxes]
    boxesCopy.push({ partsInBox })

    setBoxes(boxesCopy)
  }

  function setPartInBoxQuantity(boxIndex, partGroupNumber, quantity){
    const boxesCopy = [...boxes]
    const partInBox = boxesCopy[boxIndex]
          .partsInBox
          .find(pt => pt.partGroupNumber === partGroupNumber)
    partInBox.quantity = quantity

    setBoxes(boxesCopy)
  }

  function setPartInBoxPtpcQuantity(boxIndex, ptpcId, quantity){
    const boxesCopy = [...boxes]
    const partInBox = boxesCopy[boxIndex]
          .partsInBox
          .find(pt => pt.ptpcId === ptpcId)
    partInBox.quantity = quantity

    setBoxes(boxesCopy)
  }

  function deleteBox(boxNumber){
    const boxesCopy = [...boxes]
    const updatedBoxes = boxesCopy
          .filter(box => box.boxNumber !== boxNumber)

    setBoxes(updatedBoxes)
  }

  return (
    <div className='boxes-form'>
      <Paper className='boxes-form-paper'>
        <Typography variant='h6'>
          Shipment: {props.shipment.shipmentNumber}
        </Typography>
        <div className='boxes-form-paper-contents'>
          <Button
            color="primary"
            onClick={addNewBox}
            variant="contained"
          >
            Add New Box
          </Button>
          <BoxSelector
            boxes={boxes}
            boxNumberToEdit={boxNumberToEdit}
            setBoxNumberToEdit={setBoxNumberToEdit}
          />
        </div>
      </Paper>
      <div align="left" style={{width:'100%', overflowX: 'scroll'}}>
        <BoxesConfigurationTable
          boxes={boxes}
          boxNumberToEdit={boxNumberToEdit}
          deleteBox={deleteBox}
          project={props.project}
          setPartInBoxQuantity={setPartInBoxQuantity}
          setPartInBoxPtpcQuantity={setPartInBoxPtpcQuantity}
          shipmentId={props.shipment.id}
          style={{maxWidth: '100vw'}}
        />
        { props.currentlySaving ? <LinearProgress/> : null }
      </div>
      <Button
        color="primary"
        onClick={() => props.saveBoxesToShipment(props.shipment.id, boxes)}
        variant="contained"
      >
        Save and go back to Shipment Tracking
      </Button>
    </div>
  )
}

function lowestToHighest(a,b){ return a.boxNumber - b.boxNumber }

function decorateWithBoxNumbers(box, index){
  box.boxNumber = +index + 1
  return box
}

function partGroupToPartInBox({partGroupNumber, productionToolPartConfigurations: ptpcs}){
  const ptpcPartsInBox = ptpcs
        .map(ptpc => ({
          ptpcId:ptpc.productionToolPartConfigurationId,
          quantity: 0,
        }))
  return [
    {
      partGroupNumber,
      quantity: 0,
    },
    ...ptpcPartsInBox
  ]
}

function BoxSelector({boxes, boxNumberToEdit, setBoxNumberToEdit}){
  return (
    <div className='boxes-form-box-selector'>
      <Typography variant='h6'>
        Box to Edit:
      </Typography>
      <Select
        onChange={e => setBoxNumberToEdit(e.target.value)}
        value={boxNumberToEdit}
      >
        <MenuItem value={EDIT_ALL_BOXES}>All</MenuItem>
        {
          boxes.map(box =>
            <MenuItem key={box.boxNumber} value={box.boxNumber}>Box {box.boxNumber}</MenuItem>
          )
        }
      </Select>
    </div>
  )
}

