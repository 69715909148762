import axios from 'axios'
import config from '../../config.js'
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function getPurchasedProductionTools(customerEmail){
  let response
  try {
    response = await getPurchasedProductionToolsRequest(customerEmail)
  } catch (err) {
    logUnexpected(err, {customerEmail}, 'getPurchasedProductionToolsRequest')
    throw err
  }

  let result
  try {
    result = parseGetPurchasedProductionToolsResponse(response)
  } catch (err) {
    logUnexpected(err, {response}, 'parseGetPurchasedProductionToolsResponse', customerEmail)
    throw err
  }

  return result
}

async function getPurchasedProductionToolsRequest(customerEmail) {
  const token = config.MICROSERVICE_TOKEN
  const response = await axios({
    method: 'post',
    validateStatus: () => true,
    url: config.GET_PURCHASED_PRODUCTION_TOOLS_ENDPOINT,
    data: { customerEmail, token },
  })

  return { data: response.data, status: response.status }
}

function parseGetPurchasedProductionToolsResponse(response) {
  if (response.status === 200){
    if(response.data.statusCode === '200' && response.data.purchasedProductionTools){
      return response.data.purchasedProductionTools
    }
    else {
      const unexpectedDataShapeError = Error('Unexpected data shape')
      unexpectedDataShapeError.data = response.data
      throw unexpectedDataShapeError
    }
  } else {
    throw Error(`Unexpected status code: ${response.status}`)
  }
}
