import React from "react"
import { InputLabel, FormControl, Select } from "@material-ui/core"
import { RequiredFieldAsterisk } from '../HomePage/RequiredFieldAsterisk.js'

export const SelectOption = ({ className, children, label, name, onChange, required, value, styles={} }) => {
  const inputLabelId = `is-${name}-selector`  // associate label with input
  return(
    <FormControl style={{ ...styles }}>
      <InputLabel id={inputLabelId}>
        {label}{required && <RequiredFieldAsterisk/>}
      </InputLabel>
      <Select
        className={className}
        name={name}
        labelId={inputLabelId}
        native
        onChange={onChange}
        value={value ? value : ''}
      >
        { children }
      </Select>
    </FormControl>
  )
}
