import React,{useEffect,useState} from 'react'
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const ShipPriceConfigAutocomplete = ({ hasSampleLeadTime, leadTimePricePairs, setValue, value }) => {
  useEffect(() => {
    // clear selected ltpp when the ltpp options change
    // this is a UX decision where we don't want to guess which one to select
    if(value > 0){
      setValue(null)
    }
  }, [leadTimePricePairs])

  return(
    <>
      <Autocomplete
        disableClearable={true}
        onChange={(_event, newValue) => {
          setValue(newValue.price);
        }}
        options={leadTimePricePairByCost(leadTimePricePairs)}
        getOptionLabel={lptt=>determineOptionLabel(lptt, hasSampleLeadTime)}
        getOptionSelected={(optionToCompare,selectedOption) => optionToCompare.price === selectedOption.price}
        renderInput={(params) => <TextField {...params} error={!value} multiline label={value ? null : "Select lead time"} variant="standard" style={{width:"35em"}}/>}
        renderOption={ option => {
          if(hasSampleLeadTime){
            return(
              <div style={{borderBottom:"2px solid", width:"100%"}}>
                {option.sampleLeadTime &&
                  <div>
                    Samples: {businessDaysString(option.sampleLeadTime)} ({option.sampleShipDate})
                  </div>
                }
                Production: {businessDaysString(option.productionLeadTime)}
                <div>
                  Price: ${formatNumberToTwoDecimalStringWithThousandsComma(option.price)}
                </div>
              </div>
            )
          }
          else return <>{businessDaysString(option.productionLeadTime)} ({option.productionShipDate}) | Price: ${formatNumberToTwoDecimalStringWithThousandsComma(option.price)}</>
         }}
        value={value ? getLtpp(leadTimePricePairs, value) : null} // empty values must be 'null' for comonent to render no selected option
      />

      <div style={{padding:'4px', fontStyle:'italic'}}>
        Fastest Lead Time Available: {getFastestLeadTimeLabel(leadTimePricePairs)}
      </div>
      <div style={{padding:'4px', fontStyle:'italic'}}>
        Lowest Price Available: ${getLowestPriceAvailableLabel(leadTimePricePairs)}
      </div>
    </>
  )
}

function formatNumberToTwoDecimalStringWithThousandsComma(numberOrString){
  if(numberOrString){
    const numberWithTwoDecimalPlaces = Number(numberOrString.toFixed(2))
    return numberWithTwoDecimalPlaces.toLocaleString('en',{maximumFractionDigits:2,minimumFractionDigits:2})
  } else {
    return "Invalid Value" // Better to return a bad value here than to blow up the app with the error since this is being rendered
  }
}

const getLtpp = (ltpps, value) => {
  return ltpps.find(ltpp => ltpp.price === value)
}

const determineOptionLabel = (ltpp, hasSampleLeadTime) => {
  if(hasSampleLeadTime) {
    return (
`Samples: ${ltpp.sampleLeadTime} Business Days (${ltpp.sampleShipDate})
Production: ${ltpp.productionLeadTime} Business Days
Price: $${formatNumberToTwoDecimalStringWithThousandsComma(ltpp.price)}`
    )
  }
  else return `${businessDaysString(ltpp.productionLeadTime)} (${ltpp.productionShipDate}) | $${formatNumberToTwoDecimalStringWithThousandsComma(ltpp.price)}`
}

const leadTimePricePairByCost = leadTimePricePairs => {
  return leadTimePricePairs.sort((a,b) => a.price - b.price)
}

// fastest lead time is provided as the last element of the array > sample + production lead times
const getFastestLeadTimeLabel = leadTimePricePairs => {
  const fastestLtpp = leadTimePricePairs[leadTimePricePairs.length -1]
  if(fastestLtpp.sampleLeadTime > 0){
    const fastestLeadTime = fastestLtpp.productionLeadTime + fastestLtpp.sampleLeadTime
    return `${businessDaysString(fastestLeadTime)}`
  }
  else{
   const fastestLeadTime = fastestLtpp.productionLeadTime
    return `${businessDaysString(fastestLeadTime)}`
  }
}

const getLowestPriceAvailableLabel = leadTimePricePairs => {
  const lowestCost = leadTimePricePairs.reduce((previousValue,currentValue) => {
    if(currentValue.price > previousValue.price) return previousValue
    else return currentValue
  }).price
  return formatNumberToTwoDecimalStringWithThousandsComma(lowestCost)
}

const businessDaysString = leadTime => {
  return `${leadTime} Business Day${leadTime < 2 ? "" : "s"}`
}

ShipPriceConfigAutocomplete.examplePropsWithSampleLeadTime = {
  hasSampleLeadTime: true,
  leadTimePricePairs: [
    {productionLeadTime: 1, productionShipDate: '8/25/22', price: 96.22, sampleLeadTime: 1, sampleShipDate: "11/30/22"},
    {productionLeadTime: 2, productionShipDate: '8/26/22', price: 66.22, sampleLeadTime: 2, sampleShipDate: "11/31/22"},
    {productionLeadTime: 7, productionShipDate: '9/2/22', price: 24.27, sampleLeadTime: 3, sampleShipDate: "12/1/22"}
  ]
}

ShipPriceConfigAutocomplete.examplePropsWithoutSampleLeadTime = {
  hasSampleLeadTime: false,
  leadTimePricePairs: [
    {productionLeadTime: 1, productionShipDate: '8/25/22', price: 96.22, sampleLeadTime: 0, sampleShipDate: ""},
    {productionLeadTime: 2, productionShipDate: '8/26/22', price: 66.22, sampleLeadTime: 0, sampleShipDate: ""},
    {productionLeadTime: 7, productionShipDate: '9/2/22', price: 24.27, sampleLeadTime: 0, sampleShipDate: ""}
  ]
}

ShipPriceConfigAutocomplete.Showcase = Showcase

function Showcase() {
  const [value,setValue] = useState(ShipPriceConfigAutocomplete.examplePropsWithSampleLeadTime.leadTimePricePairs[0].productionLeadTime)
  return(
    <ShipPriceConfigAutocomplete {...ShipPriceConfigAutocomplete.examplePropsWithSampleLeadTime} setValue={setValue} value={value}/>
  )
}
