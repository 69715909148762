import React,{useState} from 'react'
import submitRemakeRequest from './submitRemakeRequest.js'
import withProject from '../Common/withProject.js'
import exampleProps from './RemakeRequestPage.exampleProps.js'
import { Button, Paper, Select, TextField, Typography } from '@material-ui/core'
import { NumberField } from "../QuoteTool/NumberField.js"
import TopAppBar from '../Common/TopAppBar'
import { Footer } from '../Common'
import SHIPPING_METHODS from './SHIPPING_METHODS.js'

function RemakeRequestPage(props) {
  const { navigateToSummaryPage, openSnackBarHandler, project, setProject, user } = props

  const setProjectField = (name, value) => {
    const projectCopy = {...project}

    projectCopy[name] = value

    setProject(projectCopy, { saveProject: true })
  }

  const setPartGroupField = (name, value, partGroupNumber) => {
    const projectCopy = {...project}
    const partGroup = projectCopy.partGroups
          .find(ptg => ptg.partGroupNumber === partGroupNumber)

    partGroup[name] = value

    setProject(projectCopy, { partGroupNumber, savePartGroup: true, saveProject: true })
  }

  const [isProcessing, setIsProcessing] = useState(false)
  const submitRemakeRequestHandler = () => {
    setIsProcessing(true)
    submitRemakeRequest(project)
      .then(() => openSnackBarHandler('Remake request email sent to logistics!'))
      .then(() => navigateToSummaryPage(project.projectNumber))
      .finally(() => setIsProcessing(false))
  }

  return (
    <div className="pageContainer remake-request">
      <div className="pageContentContainer">
        <TopAppBar user={user}/>
        <RemakeRequestPageContents
          project={project}
          setPartGroupField={setPartGroupField}
          setProjectField={setProjectField}
          submitRemakeRequestHandler={submitRemakeRequestHandler}
        />
        <Footer/>
      </div>
    </div>
  )
}

function RemakeRequestPageContents(props){
  const { project, setPartGroupField, setProjectField, submitRemakeRequestHandler } = props

  return (
    <div className="remake-request-contents">
      <Typography variant="h3">
        Remake Request for Project {project.projectNumber}
      </Typography>
      <ProjectContextPaper
        project={project}
      />
      <RemakeFieldsPaper
        project={project}
        setProjectField={setProjectField}
      />
      <RemakePartsTable
        project={project}
        setPartGroupField={setPartGroupField}
      />
      <Button
        color="secondary"
        onClick={submitRemakeRequestHandler}
        variant="contained"
      >
        Next
      </Button>
    </div>
  )
}

function ProjectContextPaper(props){
  const { project } = props

  return (
    <Paper
      className="remake-request-project-context"
    >
      <Typography variant="h5">
        Project Context
      </Typography>
      Customer: {project.customer.customerName}
    </Paper>
  )
}

function RemakeFieldsPaper(props){
  const { project, setProjectField } = props

  return (
    <Paper className="remake-request-fields-paper">
      <Typography variant="h5">
        Remake Fields
      </Typography>
      <div className="remake-request-fields-paper-div">
        <Typography>
          Remake Request Notes to Logistics
        </Typography>
        <TextField
          label="Notes"
          onChange={e => setProjectField("reprintRequestNotes", e.target.value)}
          style={{ width: "30vw" }}
          variant="standard"
          value={project.reprintRequestNotes}
        />
      </div>
      <div className="remake-request-fields-paper-div">
        <Typography>
          Remake Lead Time Request
        </Typography>
        <NumberField
          label="Business Days"
          onChange={e => setProjectField("reprintRequestLeadTime", e.target.value)}
          style={{ width: "30vw" }}
          variant="standard"
          value={project.reprintRequestLeadTime}
        />
      </div>
      <div className="remake-request-fields-paper-div">
        <Typography>
          Remake Shipping Request
        </Typography>
        <Select
          label="Notes"
          onChange={e => setProjectField("reprintRequestShipping", e.target.value)}
          native
          style={{ width: "30vw" }}
          variant="standard"
          value={project.reprintRequestShipping}
        >
          {Object.entries(SHIPPING_METHODS)
           .map(([key, value]) =>
                <option key={key} value={value}>{value}</option>
           )
          }
        </Select>
      </div>
    </Paper>
  )
}

function RemakePartsTable(props){
  const { project, setPartGroupField } = props

  return (
    <Paper className="remake-request-table-paper">
      <RemakePartsTableHeaderRow/>
      <RemakePartsTableBody
        project={project}
        setPartGroupField={setPartGroupField}
      />
    </Paper>
  )
}

function RemakePartsTableBody(props){
  const { project, setPartGroupField } = props

  const RemakePartsTableRowThunk = partGroup => {
    return(
      <RemakePartsTableRow
        key={`RemakePartsTableRow-${partGroup.partGroupNumber}`}
        partGroup={partGroup}
        setPartGroupField={setPartGroupField}
      />
    )
  }

  return project.partGroups
    .map(RemakePartsTableRowThunk)
}

function RemakePartsTableHeaderRow(props){
  return (
    <div className="remake-request-table-row">
      <div> Filename </div>
      <div> Process </div>
      <div> Material </div>
      <div> Post Process </div>
      <div> Additional Services </div>
      <div> At Risk? </div>
      <div> Notes </div>
      <div> Original Quantity </div>
      <div> Reprint Quantity </div>
    </div>
  )
}

function RemakePartsTableRow(props){
  const { partGroup, setPartGroupField } = props

  return(
    <div className="remake-request-table-row">
      <div> {partGroup.part.fileName} </div>
      <div> {partGroup.process} </div>
      <div> {partGroup.material} </div>
      <div> {partGroup.finishing} </div>
      <div> {partGroup.additionalServices} </div>
      <div> {partGroup.part.atRiskPartBool} </div>
      <div> {partGroup.part.notes} </div>
      <div> {partGroup.quantity} </div>
      <div>
        <NumberField
          label="Quantity"
          onChange={e => setPartGroupField("reprintQuantityRequested", e.target.value, partGroup.partGroupNumber)}
          value={partGroup.reprintQuantityRequested}
          variant="standard"
        />
      </div>
    </div>
  )
}

RemakeRequestPage.Showcase = Showcase
function Showcase() {
  return(
    <>
      <RemakeRequestPage { ...exampleProps } />
    </>
  )
}

export default withProject(RemakeRequestPage)
