const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')
const HTTP_ERROR_CODES = require('./HTTP_ERROR_CODES.js')
const throwErrorParsedFromKnownStatusCode = require('./throwErrorParsedFromKnownStatusCode.js')
const { getDefaultMaterials } = require('../Components/Common/TranslationTableMaterial.js')


export async function getPriceComparisonArray(partGroup, MATERIAL_TRANSLATIONS) {
  let result
  let response
  try {
    response = await getPriceComparisonArrayRequest(partGroup, MATERIAL_TRANSLATIONS)
  } catch (err) {
    logUnexpected(err, { partGroup }, "getPriceComparisonArrayRequest")
    throw err
  }
  try {
    result = parseGetPriceComparisonArrayResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, "parseGetPriceComparisonArrayResponse", { partGroup })
    throw err
  }
  return result
}

function getPriceComparisonArrayRequest(partGroup, MATERIAL_TRANSLATIONS) {
  return axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/quotes/partGroupPrices',
    data: {
      partGroup: partGroup,
      processAndMaterialIdsArray: getDefaultMaterials(MATERIAL_TRANSLATIONS),
    }
  })
    .then(val => ({data: val.data, status: val.status}))
}

function parseGetPriceComparisonArrayResponse(response) {
  if (response.status === 200) {
    if (response.data.success && response.data.success.priceComparisonArray) {
      return response.data.success.priceComparisonArray
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.responseBody = response.data
    throw unexpectedBodyFor200Error
  }

  if (HTTP_ERROR_CODES.includes(response.status)) {
    throw throwErrorParsedFromKnownStatusCode(response.status, response.data.errorMessage)
  }

  let unexpectedStatusCodeError = new Error(`Unexpected Status Code: ${response.status}`)
  unexpectedStatusCodeError.responseBody = response.data
  throw unexpectedStatusCodeError
}
