import React from "react"
import { Typography } from "@material-ui/core"
import { SelectOption } from "./SelectOption"
import { NumberField } from "./NumberField"
import { CNC_TYPES } from './CNC_TYPES.js'
import { RoughingTools, FinishingTools } from './Tools.js'
import CncStock from './CncStock.js'

const CNC_NUMBER_OF_AXES = {
  three: 3,
  five: 5,
}

const CNC_5_AXIS_COMPLEXITY = {
  high: "High",
  medium: "Medium",
  low: "Low"
}

const DmeCnc = (props) => {
  const onChangeHandler = e => props.onChange(e, props.partGroup.partGroupNumber)
  return(
    <div className="dme-cnc-info">
      <Typography variant="h6" className="dme-section-header">
        CNC Machining Fields
      </Typography>
      <CncFields
        onChangeHandler={onChangeHandler}
        partGroup={props.partGroup}
      />
      <CncRoughingTools
        addTool={props.addTool}
        onChangeToolField={props.onChangeToolField}
        partGroup={props.partGroup}
        deleteTool={props.deleteTool}
      />
      <CncFinishingTools
        addTool={props.addTool}
        onChangeToolField={props.onChangeToolField}
        partGroup={props.partGroup}
        deleteTool={props.deleteTool}
      />
      <CncStock
        onChangeHandler={onChangeHandler}
        part={props.partGroup.part}
      />
    </div>
  )
}

function CncFields(props){
  const { onChangeHandler, partGroup } = props

  return (
    <div className="dme-cnc-form">
      <SelectOption
        label="Mill vs Turn"
        name="cncMillOrTurn"
        onChange={onChangeHandler}
        styles={{minWidth:"8em"}}
        value={partGroup.part.cncMillOrTurn}
      >
        {Object.entries(CNC_TYPES).map(
          ([key, value]) => <option key={key} value={value}>{value}</option>
        )}
      </SelectOption>
      <SelectOption
        label="Requires Custom Fixturing"
        name="cncIsHeldInVice"
        onChange={onChangeHandler}
        styles={{width:"8em"}}
        value={String(partGroup.part.cncIsHeldInVice)}
      >
        {/* Boolean values must be strings when passed
           to the component but are stored as bools
           in the DB
         NOTE: values are inverted to match the name of this field on the front end
           so when it requirs custom fixtures, then it is Not held in a vice
           TODO: fix in the pricing database/pricing engine logic/customer db field name
        */}
        <option value={'true'}>No</option>
        <option value={'false'}>Yes</option>
      </SelectOption>
      <NumberField
        label="How many Setups per part"
        name="cncSetupsPerPart"
        onChange={onChangeHandler}
        styles={{width:"16em"}}
        value={partGroup.part.cncSetupsPerPart}
      />
      <SelectOption
        label="Number of Axes"
        name="cncNumberOfAxes"
        onChange={onChangeHandler}
        styles={{minWidth:"8em"}}
        value={partGroup.part.cncNumberOfAxes}
      >
        {Object.entries(CNC_NUMBER_OF_AXES)
          .map(([key, numberOfAxes]) =>
            <option key={key} value={numberOfAxes}>{numberOfAxes}</option>
          )
        }
      </SelectOption>
      {Number(partGroup.part.cncNumberOfAxes) === 5 &&
        <SelectOption
          label="5-axis complexity"
          name="cnc5AxisMillComplexity"
          onChange={onChangeHandler}
          styles={{minWidth:"8em"}}
          value={partGroup.part.cnc5AxisMillComplexity}
        >
          {Object.entries(CNC_5_AXIS_COMPLEXITY)
            .map(([key, complexity]) =>
              <option key={key} value={complexity}>{complexity}</option>
            )
          }
        </SelectOption>      
      }

      <NumberField
        disabled={true}
        label="Recommended Number of Axes"
        name="cncRecommendedNumberOfAxes"
        onChange={onChangeHandler}
        styles={{width:"16em"}}
        value={partGroup.part.cncSetupsPerPart}
      />
    </div>
  )
}

function CncRoughingTools(props){
  const { addTool, deleteTool, onChangeToolField, partGroup } = props

  return (
    <RoughingTools
      addTool={(defaultPercentage) => addTool(defaultPercentage, partGroup.partGroupNumber, 'cncRoughingTools')}
      deleteToolByIndex={toolIndex => deleteTool(partGroup.partGroupNumber, 'cncRoughingTools', toolIndex)}
      onChange={(event, toolIndex) => onChangeToolField(event, partGroup.partGroupNumber, toolIndex, 'cncRoughingTools')}
      tools={partGroup.part.cncRoughingTools}
      toolType='cncRoughingTools'
    />
  )
}

function CncFinishingTools(props){
  const { addTool, deleteTool, onChangeToolField, partGroup } = props

  return (
    <FinishingTools
      addTool={(defaultPercentage) => addTool(defaultPercentage, partGroup.partGroupNumber, 'cncFinishingTools')}
      deleteToolByIndex={toolIndex => deleteTool(partGroup.partGroupNumber, 'cncFinishingTools', toolIndex)}
      onChange={(event, toolIndex) => onChangeToolField(event, partGroup.partGroupNumber, toolIndex, 'cncFinishingTools')}
      tools={partGroup.part.cncFinishingTools}
      toolType='cncFinishingTools'
    />
  )
}

export default DmeCnc
