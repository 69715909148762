const { downloadBlobFromS3 } = require('./downloadBlobFromS3.js')


async function downloadFileFromS3Function(id) {
  const dataBlob = await downloadBlobFromS3(id)
  return await dataBlob.text()
}

function memoizeFunction(asyncFunction){
  return async (uuid, ...otherArgs) => {
    const localId = `${asyncFunction.name}${uuid}`

    const dataMaybe = await loadStringFromOpfs(localId)

    if(dataMaybe) return dataMaybe

    return asyncFunction(uuid, ...otherArgs)
      .then(async data => {
        await saveStringInOpfs(localId, data)
        return data
      })
  }
}

export const downloadFileFromS3 = memoizeFunction(downloadFileFromS3Function)

async function saveStringInOpfs(id, string){
  const storageRootFiles = await getOpfsStorageFilesDirectory()

  const newFile = await storageRootFiles.getFileHandle(id, { "create" : true });

  const wtr = await newFile.createWritable()
  try {
    await wtr.write(string)
  }
  finally {
    await wtr.close()
  }
}

async function loadStringFromOpfs(id){
  const storageRootFiles = await getOpfsStorageFilesDirectory()
  try{
    const savedFile = await storageRootFiles.getFileHandle(id)
    const file = await savedFile.getFile()
    const text = await new Response(file).text()
    return text
  } catch(e){
    if(e.message.includes('file or directory could not be found')){
      return null
    }
    throw e
  }
}


async function getOpfsStorageFilesDirectory(){
  try {
    const storageRoot = await navigator.storage.getDirectory()
    const DIR_NAME = "files" // can be any string
    const filesDir = await storageRoot.getDirectoryHandle(DIR_NAME, { "create" : true })
    return filesDir
  }
  catch(err){
    console.error(err)
    alert( "Couldn't open OPFS. See browser console.\n\n" + err )
    throw err
  }
}
