import React,{useEffect} from 'react'
import {Fade,withStyles} from '@material-ui/core'
import Logo from '../../Images/logoDark.png'

const LoadingPage = ({classes,loadProjectPromise,pageType,projectNumber}) => {

  useEffect(() => {
    if(pageType === 'project'){
      loadProjectPromise(projectNumber)
    }
  }, ['ONMOUNT'])

  return(
    <div className={classes.container}>
      <Fade in={true} appear={true}>
        <img alt="logo" className={classes.logo} src={Logo}/>
      </Fade>
    </div>
  )
}

const styles = theme => ({
  container: {
    height: '100vh',
    margin: 0,
    textAlign: 'center'
  },
  logo: {
    marginTop: '25vh',
    width: '40vw'
  }
})

export default withStyles(styles)(LoadingPage)
