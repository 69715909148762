import React from "react"
import { SelectOption } from "../SelectOption.js"

export const DrawingDimensionsSelectAtom = ({ onChange, value }) => {
  return(
    <SelectOption
      styles={{marginBottom:"1em",minWidth:"10em"}}
      label="Drawing Dimensions"
      name='drawingUnits'
      onChange={onChange}
      value={value}
    >
      <option value="mm">mm</option>
      <option value="inches">inches</option>
    </SelectOption>
  )
}
