const { logUnexpected } = require('../../../utils/logUnexpected.js')

export async function generatePoPdf(project, makeOrderNumber) {
  let response
  try{
    response = await generatePoPdfRequest(project, makeOrderNumber)
  } catch (err) {
    logUnexpected(err)
    throw err
  }

  let generatePoPdfResponse
  try{
    generatePoPdfResponse = parseGeneratePoPdfResponse(response)
  } catch (err) {
    logUnexpected(err)
    throw err
  }

  return generatePoPdfResponse
}

async function generatePoPdfRequest(project, makeOrderNumber) {
  return fetch('/documents/create/po', {
    method: 'post',
    body: JSON.stringify({
      project,
      makeOrderNumber,
    }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json",
    }
  })
    .then(res => res.json())
}

function parseGeneratePoPdfResponse(data) {
  if(data.error){
    throw Error(data.error)
  }
  try{
    const { encodedPartReport, encodedPurchaseOrder, poTotal } = data.success
    return ({
      encodedPO: encodedPurchaseOrder,
      encodedPartReport,
      poTotal,
    })
  } catch (err) {
    let newError = Error('Unexpected data shape')
    newError.data = data
    newError.originalError = err
    throw newError
  }
}

