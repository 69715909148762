import { Button } from '@material-ui/core'

export default function EditProjectButton({onClick}){
  return (
    <div>
      <Button
        color="secondary"
        onClick={onClick}
        variant="contained"
      >
        Edit Project
      </Button>
    </div>
  )
}
