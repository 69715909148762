import React,{useEffect,useRef} from "react"
import { TextField } from "@material-ui/core"

export const NumberField = ({ className, label, InputProps, name, onChange, value, disabled, style, styles, ...otherProps }) => {
  const textFieldRef = React.useRef(null)

  useEffect(() => {
    // the proper way to prevent scrolling issues in 'number' type text field inputs
    // https://github.com/mui/material-ui/issues/7960#issuecomment-497945204

    const handleWheel = e => e.preventDefault();
    textFieldRef.current.addEventListener("wheel", handleWheel);

    return () => {
      if(textFieldRef.current){
        textFieldRef.current.removeEventListener("wheel", handleWheel);
      }
    };
  }, [])

  return(
    <TextField
      inputProps={{
        style: { textAlign: "center", ...styles },
        min: "1"
      }}
      className={className}
      disabled={disabled}
      InputProps={InputProps}
      name={name}
      onChange={onChange}
      style={style}
      type="number"
      value={value}
      label={label}
      ref={textFieldRef}
      {...otherProps}
    />
  )
}
