import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export async function pollDatabaseForParamExtractionResult(partNumber) {
    const PARAM_FIELDS_SUCCESS = ['xDimUnits','yDimUnits','zDimUnits','partVolumeUnits','partSurfaceAreaUnits','s3ObjFileId']
    const PARAM_FIELD_FAIL = 'paramExtractError'

    const throttle = 3000
    const timeout = 180000

    return pollDatabaseForResult(partNumber, PARAM_FIELDS_SUCCESS, PARAM_FIELD_FAIL, throttle, timeout)
}
