module.exports = {
  exampleProps: {
    "classes": {
      "tableTextBlue": "HeaderBar-tableTextBlue-3",
      "tableTextRed": "HeaderBar-tableTextRed-4"
    },
    "currentlyLoading": false,
    "project": {
      "projectNumber": 47298,
      "visibleSheetNames": "[\"Quote Request\"]",
      "projectManager": {
        "id": "5a8468431711945b357128de",
        "account_status": "active",
        "approval_status": "approved",
        "profile_keys_raw": [
          {
            "id": "profile_16",
            "identifier": "Admins"
          },
          {
            "id": "profile_29",
            "identifier": "Autotiv Employees"
          },
          {
            "id": "profile_12",
            "identifier": "Project Managers"
          },
          {
            "id": "profile_28",
            "identifier": "Office Managers"
          }
        ],
        "name": {
          "first": "-",
          "last": ""
        },
        "email": "nick@autotiv.com",
        "password": "**********",
        "userStatus": "active",
        "userRoles": [
          "profile_16",
          "profile_29",
          "profile_12",
          "profile_28"
        ],
        "# of Open Quote follow ups due today or earlier": 4560
      },
      "customer": {
        "id": "59c96b2bca418922b2e7ce71",
        "emailAndName": "nick@autotiv.com - Nick Woodward",
        "email": "nick@autotiv.com",
        "customerName": "Nick Woodward",
        "firstName": "Nick",
        "lastName": "Woodward",
        "company": {
          "id": "59dc0aa7ad867f328b253c94",
          "companyDomainAndName": "autotiv.com - Autotiv MFG",
          "companyDomain": "autotiv.com",
          "companyName": "Autotiv MFG",
          "billingEmail": "accounting@autotiv.com",
          "creditTerms": "Net 30",
          "creditLimit": "$10,000",
          "accountStatus": "Bronze",
          "potentialAccountStatus": "Small Team",
          "whiteLabelBool": false,
          "whiteLabelAddress": {},
          "shipOnCustomerAccountBool": "",
          "customerAccountShipCompany": "FedEx",
          "customerAccountShipNumber": "938938938",
          "customerAccountShipZip": "03079",
          "resellerBool": false,
          "companyNotes": "note",
          "website": "autotiv.com",
          "timeZone": "(GMT-05:00) New York",
          "numberOfMechanicalEngineers": 0,
          "contactEmail": "",
          "coldCampaign": "",
          "bdr": [],
          "bdrWinDup": false,
          "bdrWinDate": "",
          "accountExecutive": [
            {
              "id": "5e56a6b2dbab78001518f5c0",
              "identifier": "Dylan "
            }
          ]
        },
        "phone": {
          "formatted": "(888) 369-5442 x808",
          "ext": "808",
          "full": "8883695442",
          "number": "3695442",
          "area": "888"
        },
        "shippingCompany": "Autotiv",
        "shippingContact": "Nick Woodward",
        "shippingAddress": {
          "street": "50A Northwestern Drive",
          "street2": "Suite 7",
          "city": "Salem",
          "state": "New Hampshire",
          "zip": "03079",
          "country": "USA"
        },
        "customerNotes": "coolest customer ever. he actually is a god",
        "timeZone": "ET",
        "numberOfOpenCareFollowUpTasks": 0,
        "cellPhoneNumber": {
          "formatted": "(603) 490-1064",
          "full": "6034901064",
          "number": "4901064",
          "area": "603"
        },
        "lastOrderDate": "09/20/2019",
        "lastQuotedDate": "09/19/2019",
        "numClosedProjects": 359,
        "numNonClosedProjects": 560,
        "numDeliveredProjects": 14,
        "notWorthContacting": false,
        "coldCampaign": "",
        "customerTotalSales": 1684.8863260462501,
        "numberOfProjects": 113,
        "numberOfProjectsBuilding": 16,
        "customerTotalSalesUnlinked": 859.255
      },
      "contactedThrough": "Customer Web App",
      "projectFolderLink": "https://drive.google.com/drive/folders/1ECykFjSSasx3_c12g6wd_uShAlEYTB0C",
      "projectStatus": "Quoting",
      "quoteStatus": "Quote Started",
      "operationsPerson": "",
      "totalSale": "27.94",
      "shippingRevenue": "0.00",
      "customerNotes": "",
      "supplierNotes": "",
      "paidExpediteLevel": 4,
      "orderDate": null,
      "paidShipDate": null,
      "paidArrivalDate": null,
      "paidShipMethod": "",
      "labelType": "Autotiv Label",
      "shipProvider": "FedEx",
      "shipAccountNumber": "Autotiv",
      "shipAccountZipCode": "",
      "shipCompany": "",
      "shipName": "",
      "paymentLocation": "",
      "paymentReferenceNumber": "",
      "onlineOrderNumber": "",
      "billingName": "Nick Woodward",
      "billingEmail": "accounting@autotiv.com",
      "lastQuoteMadeDate": null,
      "quoteNotes": "",
      "expediteLeadTimeOption4": 5,
      "expediteLeadTimeOption3": 3,
      "expediteLeadTimeOption2": 2,
      "expediteLeadTimeOption1": 1,
      "lastQuoteRevision": 0,
      "mkCncParam_markup": "1.75",
      "mkCastParam_markup": "1.75",
      "mkInjmdParam_partsMarkup": "1.66666666667",
      "mkInjmdParam_moldMarkup": "1.66666666667",
      "mkShtmlParam_markup": "1.5",
      "asParam_serviceType": "",
      "leadTimeRequest": 5,
      "shippingMethodRequest": "",
      "process": "SLS",
      "partsListForQb": "1 x White Nylon 12: no_issues.stl",
      "asParam_internalServiceShipment": "",
      "asParam_supplier": "",
      "asParam_shipMethodToInt": "",
      "asParam_finishingMarkup": "",
      "asParam_finishingDays": "",
      "customShippingPrice": "",
      "quoteAdjustment": "",
      "numberOfUniqueParts": 1,
      "quoteFollowUpDate": "02/24/2022",
      "reprintRequestShipping": "",
      "reprintRequestLeadTime": "",
      "reprintRequestNotes": "",
      "markupParam_totalFreight": "",
      "markupParam_supplierLeadTime": "",
      "markupParam_additionalBufferDays": "",
      "mkDmlsParam_markup": "2",
      "expediteLeadTimeOverride4": "",
      "expediteLeadTimeOverride3": "",
      "expediteLeadTimeOverride2": "",
      "expediteLeadTimeOverride1": "",
      "customerTrackingNumber": "",
      "partsGoogleDriveFolderId": "1nE5r5TMU7GEoGdhgY3k8CTnBJNPWwlGO",
      "changeRequestNotes": "",
      "calculatedShippingPrice": "0.00",
      "chinaSmallPackageEstimate": "30.00",
      "platformCustomerName": "",
      "platformCustomerPhone": "",
      "platformCustomerCompany": "",
      "customerId": "59c96b2bca418922b2e7ce71",
      "quotingFormulaVersion": "",
      "projectAutoIncrement": 20576,
      "customerEmail": "nick@autotiv.com",
      "shipPhone": "",
      "shipEmail": "nick@autotiv.com",
      "expediteFee": "0.00",
      "customerReady": true,
      "subTotal": 27.94,
      "fixedPartsGoogleDriveFolderId": "1TA4UbekRkZVJwP70kvPtsOXuQgmjF13R",
      "customerAppQuoteType": "3dPrinting",
      "orderSubmittedDate": null,
      "orderSubmittedType": "",
      "createdAt": "02/22/2022",
      "id": "621551eefe9fa07dba6838c1",
      "shipAddress": {
        "street": "",
        "street2": "",
        "city": "",
        "state": "",
        "zip": "",
        "country": ""
      },
      "overrides": {
        "standardLeadTime": "calculated",
        "yellowLeadTime": "calculated",
        "orangeLeadTime": "calculated",
        "redLeadTime": "calculated",
        "shippingPrice": "calculated"
      },
      "partGroups": [
        {
          "partGroupNumber": 229822,
          "part": {
            "partIdentifier": "",
            "partNumber": 548725,
            "knackRecordId": "",
            "fileName": "no_issues.stl",
            "partNotes": "",
            "xDim": "73.99",
            "yDim": "11.00",
            "zDim": "94.95",
            "partVolume": "49708.54",
            "partSurfaceArea": "17601.20",
            "atRiskPartBool": "",
            "partFile": "",
            "partFileS3": "9c6161f2-b3c0-4ff0-9ecb-186b2aabaab7",
            "partDrawing": "",
            "mfgIssues": [],
            "units": "mm",
            "wallThicknessOBJFile": "",
            "wallThicknessMaterialFile": "",
            "wallThicknessOBJFileMM": "2a2c7f82-9655-4396-be0c-55330c0d3e97",
            "wallThicknessMaterialFileMM": "92377e02-2842-4775-b0d4-1a490e65297f",
            "wallThicknessResultsMM": true,
            "wallThicknessOBJFileInches": "",
            "wallThicknessMaterialFileInches": "",
            "wallThicknessResultsInches": "",
            "s3ObjFileId": "28b99856-0711-42f4-ac3e-975402207971",
            "xDimUnits": "73.9899978637695",
            "yDimUnits": "11",
            "zDimUnits": "94.9499969482422",
            "partVolumeUnits": "49708.54296875",
            "partSurfaceAreaUnits": "17601.197265625",
            "mfgAnalysisIssues": [],
            "riskAccepted": false,
            "wallThicknessTaskIdMM": "g86ugqmkh3G1RFfXGc4vvbcFjng9PfM4",
            "wallThicknessTaskIdInches": "",
            "paramExtractError": "",
            "meshProperties": {
              "shells": "1",
              "badEdges": "0",
              "boundaryEdges": "0",
              "flippedTriangleCount": "0",
              "isManifold": "true"
            },
            "thumbnailS3id": "ffa6d820-df38-4ddf-8a40-49472893783e",
            "thinWallPercentageMM": "",
            "thinWallPercentageInches": "",
            "fileSize": "3484",
            "netfabbWallThicknessTotalSurfaceAreaInches": "",
            "netfabbWallThicknessTotalSurfaceAreaMM": "17601.1999053955",
            "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
            "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0",
            "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
            "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 0,
            "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
            "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0",
            "wallThicknessErrorMM": null,
            "wallThicknessErrorInches": null,
            "dmeEstimatedRatioOfVolumeToBoundingBox": "",
            "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "",
            "cncMillOrTurn": null,
            "cncNumberOfAxes": "",
            "cncSetupsPerPart": "",
            "cncSmallestToolDiameter": ""
          },
          "project": 47298,
          "makeOrder": "",
          "shipment": "",
          "knackRecordId": "",
          "process": "SLS",
          "material": "White Nylon 12",
          "description": "",
          "quantity": 1,
          "finishing": "Standard",
          "additionalServices": "",
          "unitPrice": "27.94",
          "unitCost": "",
          "customPrice": "",
          "markupPrice": "0.00",
          "dmlsParam_asBuiltX": "",
          "dmlsParam_asBuiltY": "",
          "dmlsParam_asBuiltZ": "",
          "dmlsParam_perPartFinishingTime": "",
          "dmlsParam_perPartSlicedMaterialVolume": "",
          "fdmParam_machine": "",
          "fdmParam_resolution": "",
          "fdmParam_infill": "",
          "fdmParam_perPartSlicedMaterialVolume": "",
          "fdmParam_perPartSupportMaterialVolume": "",
          "fdmParam_perPartFinishingTimeHour": "",
          "fdmParam_perPartFinishingTimeMin": "",
          "cncParam_perPartMachineTime": "",
          "cncParam_perPartDrilledHolesCount": "",
          "cncParam_perPartTappedHolesCount": "",
          "cncParam_toolCount": "",
          "cncParam_setupCount": "",
          "cncParam_perPartMaterialCostOffset": "",
          "cncParam_perPartMachiningCostOffset": "",
          "cncParam_setupCostOffset": "",
          "mkCncParam_totalPartGroupCost": "",
          "mkCastParam_masterCost": "",
          "mkCastParam_moldCost": "",
          "mkCastParam_totalPartGroupCost": "",
          "mkInjmdParam_moldCost": "",
          "mkInjmdParam_moldLife": "",
          "mkInjmdParam_totalPartGroupCost": "",
          "mkShtmlParam_totalPartGroupCost": "",
          "asParam_perPartUnitCost": "",
          "reprintQuantityRequested": "",
          "dmlsParam_useMarkupPrice": "",
          "mkCastParam_useMarkupPrice": "",
          "mkDmlsParam_totalPartGroupCost": "",
          "processId": "sls",
          "materialId": "slsWhiteNylon12",
          "finishId": "slsStandard",
          "selectedPrice": "calculated",
          "partGroupNotes": "",
          "atRiskBool": "",
          "riskAccepted": "",
          "isMarkupCosted": "",
          "markupCostQuantity1": "",
          "markupCostQuantityX": "",
          "markupQuantityX": "",
          "guaranteedLife": "",
          "isOriginalPartGroup": "",
          "numberOfSlides": "",
          "numberOfCollapsingCores": "",
          "averageWallThickness": "",
          "standardDeviationWallThickness": "",
          "productionToolPartConfigurations": [],
          "attachments": [],
          "id": "896466d2",
          "partQuoteReviewInProgress": false,
          "userType": "customer"
        }
      ],
      "makeOrders": [],
      "shipments": [],
      "quotes": [],
      "attachments": [],
      "calculations": {
        "leadTimePrices": [
          {
            "expediteLevel": "1",
            "price": "50.29",
            "shipDate": "03/30/2022"
          },
          {
            "expediteLevel": "2",
            "price": "41.91",
            "shipDate": "03/31/2022"
          },
          {
            "expediteLevel": "3",
            "price": "34.93",
            "shipDate": "04/01/2022"
          },
          {
            "expediteLevel": "4",
            "price": "27.94",
            "shipDate": "04/05/2022"
          }
        ],
        "shippingOptions": [
          {
            "arrivalDate": "03/30/2022",
            "arrivalDateString": "Wednesday March 30 by 8:00 AM",
            "cost": "85.62",
            "shipDate": "03/29/2022",
            "shipMethod": "Next Day Air Early"
          },
          {
            "arrivalDate": "03/30/2022",
            "arrivalDateString": "Wednesday March 30 by 10:30 AM",
            "cost": "28.54",
            "shipDate": "03/29/2022",
            "shipMethod": "1 Day"
          },
          {
            "arrivalDate": "03/30/2022",
            "arrivalDateString": "Wednesday March 30",
            "cost": "25.53",
            "shipDate": "03/29/2022",
            "shipMethod": "Next Day Saver"
          },
          {
            "arrivalDate": "03/31/2022",
            "arrivalDateString": "Thursday March 31",
            "cost": "14.39",
            "shipDate": "03/29/2022",
            "shipMethod": "2 Day"
          },
          {
            "arrivalDate": "04/01/2022",
            "arrivalDateString": "Friday April 1",
            "cost": "14.27",
            "shipDate": "03/29/2022",
            "shipMethod": "3 Day"
          },
          {
            "arrivalDate": "04/05/2022",
            "arrivalDateString": "Tuesday April 5",
            "cost": "8.12",
            "shipDate": "03/29/2022",
            "shipMethod": "5 Day"
          }
        ]
      },
      "updateId": "2022-03-29T16:20:46.798Z"
    },
    "reviewReasons": {
      "projectReasons": [],
      "partsReasons": []
    },
    "submittingForRfq": false,
    "user": {
      "sub": "0cee375f-0342-4c72-8b7a-94c2b9b37d1b",
      "email_verified": true,
      "custom:lastName": "Woodward",
      "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_15fViVABs",
      "cognito:username": "0cee375f-0342-4c72-8b7a-94c2b9b37d1b",
      "aud": "2419hen1jem1oit2m7g46gj7e1",
      "event_id": "9af65bf0-4632-4f9b-a5af-797209a94dac",
      "custom:firstName": "Nick",
      "token_use": "id",
      "auth_time": 1647365949,
      "name": "Nick Woodward",
      "exp": 1648571407,
      "iat": 1648567807,
      "email": "nick@autotiv.com"
    }
  }
}
