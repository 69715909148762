// THIS FILE ALSO EXISTS IN AUTOTIV-API (https://github.com/Autotiv/autotiv-api-pricing/blob/development/plugins/DataTables/Dates.js) 
// ANY CHANGES TO EITHER FILE NEEDS TO BE COPIED OT THE OTHER
// This was done because we were having inconsistent date calculations when using two different date calculation functions and wanted
// .. to quickly get them in sync without exposing them from the backend. The future combination of this app and the back end into 
// .. the customer app to recombine these files is why we took on the temporary cost of maintaining them.
// WHEN MERGING DME-APP & AUTOTIV_API INTO CUSTOMER APP: be sure to combine this file with the one from the backend

import {sendErrorEmail} from '../../utils/util.js'

var moment = require('moment-business-days');

export function calculateShipDateFromOrderDateAndLeadTimeDays(
  orderDate, 
  orderDateFormat, 
  leadTimeDays
) {
  // items never start shipping on saturday, so this is configured as always false
  return calculateDateFromBusinessDayOffset(orderDate, orderDateFormat, leadTimeDays, false)
}

export function calculateArrivalDateFromShipDateAndShipDayEstimation(
  shippingDate,
  shippingDateFormat,
  estimationOfShippingDays,
  saturdayBool
) {
  // some shipping carriers will *deliver* on saturday, and this is calculated ahead of time outside of this function 
  return calculateDateFromBusinessDayOffset(shippingDate, shippingDateFormat, estimationOfShippingDays, saturdayBool)
}

function calculateDateFromBusinessDayOffset(date, dateFormat, businessDays, saturdayBool) {
  let weekdaysArray = [1, 2, 3, 4, 5]
  if(saturdayBool && businessDays == 1){
    weekdaysArray.push(6)
  }
  moment.updateLocale('us', {
    holidays: getHolidayArray().holidayArray,
    holidayFormat: getHolidayArray().holidayDateFormat,
    workingWeekdays: weekdaysArray
  })
  let momentDateObj
  if(date === 'today'){
    momentDateObj = moment()
  }
  else{
    momentDateObj = moment(date,dateFormat)
  }
  let isWeekend = !momentDateObj.isBusinessDay()
  if(momentDateObj.isHoliday() || isWeekend){
    businessDays ++
  }
  return momentDateObj.businessAdd(businessDays)
}

function getHolidayArray(){
  alertDevTeamIfHolidaysNeedUpdating()

  const holidayDateFormat = 'MM/DD/YYYY'
  var holidayArray = []

  //2020
  holidayArray.push('01/01/2020') // New Years Day
  holidayArray.push('01/20/2020') // MLK Day
  holidayArray.push('05/25/2020') // Memorial Day
  holidayArray.push('07/04/2020') // Fourth of July
  holidayArray.push('09/07/2020') // Labor Day
  holidayArray.push('11/26/2020') // Thanksgiving
  holidayArray.push('11/27/2020') // Black Friday
  holidayArray.push('12/24/2020') // Christmas Eve
  holidayArray.push('12/25/2020') // Christmas Day
  holidayArray.push('12/31/2020') // New Years Eve

  //2021
  holidayArray.push('01/01/2021') // New Years Day
  holidayArray.push('01/18/2021') // MLK Day
  holidayArray.push('05/31/2021') // Memorial Day
  holidayArray.push('07/05/2021') // Fourth of July - 4th is a Sunday, observed on 5th
  holidayArray.push('09/06/2021') // Labor Day
  holidayArray.push('11/25/2021') // Thanksgiving
  holidayArray.push('11/26/2021') // Black Friday
  holidayArray.push('12/24/2021') // Christmas Eve
  holidayArray.push('12/23/2021') // Christmas - 25th is a Saturday, observed on 23rd
  holidayArray.push('12/31/2021') // New Years Eve

  // 2022
  holidayArray.push('01/03/2022') // New Years Day - 1st is a Saturday, observed on 3rd
  holidayArray.push('01/17/2022') // MLK Day
  holidayArray.push('05/30/2022') // Memorial Day
  holidayArray.push('07/04/2022') // Fourth of July
  holidayArray.push('09/05/2022') // Labor Day
  holidayArray.push('11/24/2022') // Thanksgiving
  holidayArray.push('11/25/2022') // Black Friday
  holidayArray.push('12/23/2022') // Christmas Eve - 24th is a Saturday, observed 23rd
  holidayArray.push('12/26/2022') // Christmas - 25th is a Sunday, observed on 26th
  holidayArray.push('12/30/2022') // New Years Eve - 31st is a Saturday, observed 30th

  // 2023
  holidayArray.push('01/02/2023') // New Years Day - 1st is a Sunday, observed on 2nd
  holidayArray.push('01/16/2023') // MLK Day
  holidayArray.push('05/29/2023') // Memorial Day
  holidayArray.push('07/04/2023') // Fourth of July
  holidayArray.push('09/04/2023') // Labor Day
  holidayArray.push('11/23/2023') // Thanksgiving
  holidayArray.push('11/24/2023') // Black Friday
  holidayArray.push('12/22/2023') // Christmas Eve - 24th is a Sunday, observed 22nd
  holidayArray.push('12/25/2023') // Christmas

  // 2024
  holidayArray.push('01/01/2024') // New Years Day
  holidayArray.push('01/15/2024') // MLK Day
  holidayArray.push('05/27/2024') // Memorial Day
  holidayArray.push('07/04/2024') // Fourth of July
  holidayArray.push('07/05/2024') // Fourth of July Weekend (Friday)
  holidayArray.push('09/02/2024') // Labor Day
  holidayArray.push('11/28/2024') // Thanksgiving
  holidayArray.push('11/29/2024') // Black Friday
  holidayArray.push('12/24/2024') // Christmas Eve
  holidayArray.push('12/25/2024') // Christmas
  holidayArray.push('12/31/2024') // New Years Eve

  // 2025
  holidayArray.push('01/01/2025') // New Years Day
  holidayArray.push('01/20/2025') // MLK Day

  return {holidayArray, holidayDateFormat}
}

function alertDevTeamIfHolidaysNeedUpdating() {
  const ALERT_AFTER_DATE = new Date(2024, 5, 1) // 6/1/2024 (June = 5, month is 0-indexed)
  // const ALERT_AFTER_DATE = new Date(2024, 11, 1) // 12/1/2024 (Dec = 11, month is 0-indexed)
  const TODAY = new Date()
  if (ALERT_AFTER_DATE < TODAY) {
    const holidaysOutOfDateErrorObject = {
      message: "customer-web-app holidays need to be updated with 2024 holidays",
      link: "https://github.com/Autotiv/customer-web-app/blob/1ea8c2e0e4ae6769025e9a42ea1322aea27d6ea9/src/Components/ShipmentTracking/calculateDateFromBusinessDayOffset.js",
    }
    sendErrorEmail(holidaysOutOfDateErrorObject)
  }
}
