import React from 'react'
import {FormControl,Select,InputLabel} from '@material-ui/core'
import {PROCESS_TRANSLATIONS} from '../../utils'

const SelectOptionProcess = props => {
  return(
    <form>
      <FormControl fullWidth={true}>
        <InputLabel id="select-option-process">Process</InputLabel>
        <Select
          labelId="select-option-process"
          name={props.name}
          native
          onChange={props.onChange}
          value={props.value}
        >
          {props.blankOptionEnabled ? <option key={"blankProcess"} value={""}>{""}</option> : null}
          {PROCESS_TRANSLATIONS
           .filter(isActive)
           .map(process =>
            {
            return (<option key={process.id} value={process.id}>{process.display}</option>)
            }
          )}
        </Select>
      </FormControl>
    </form>
  )
}

function isActive({isDeprecated}){
  return isDeprecated !== true
}

export default SelectOptionProcess
