import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('./logUnexpected.js')

export async function updatePartGroup(partGroup, saveNetfabbFields = false) {
  let partGroupPayload = { ...partGroup }
  let result
  let response
  if(!saveNetfabbFields){
    partGroupPayload = removeNetfabbFields(partGroupPayload)
  }
  try {
    response = await updatePartGroupRequest(partGroupPayload)
  } catch (err) {
    logUnexpected(err, { partGroupPayload }, "updatePartGroupRequest", { partGroup })
    throw err
  }
  try {
    result = parseUpdatePartGroupResponse(response)
  } catch (err) {
    if (false /* add retries here; check error-type & retryCounter */) {
      // retry stub
      // RECURSION
      // return await createPart(part, retryCounter)
    } else {
      // log & rethrow
      logUnexpected(err, { response }, "parseUpdatePartGroupResponse", { partGroup })
      throw err
    }
  }
  return result
}

function updatePartGroupRequest(partGroup) {
    return axios({
        method: 'post',
        validateStatus: () => true,
        url: config.UPDATE_PART_GROUP_ENDPOINT,
        data: {
          "token": config.MICROSERVICE_TOKEN,
          "partGroup": partGroup
        }
    })
    .then(val => ({data: val.data, status: val.status}))
}

function parseUpdatePartGroupResponse(response) {
  if (response.status === 200) {
    let partGroup
    try {
      partGroup = response.data.partGroup
      if ( partGroup.partGroupNumber === undefined) {
        throw Error('Part Group has no part number')
      } else {
        return partGroup
      }
    } catch (err) {
      throw Error(`Failed to update part: unexpected reason ${err.message}`)
    }
  } else {
    throw Error(`Unexpected status code while updating part : ${JSON.stringify(response.status)}`)
  }
}

function removeNetfabbFields(partGroupPayload) {
  /* fields that get set from netfabb either:
    - should never be saved from the front end (if we don't change them)
       ex: wall thickness results

    - should never save blank values from the front end (if we do update them)
       ex: dimensions
*/

  let partGroup = JSON.parse(JSON.stringify(partGroupPayload))
  let part = partGroup.part

  // dont save until the values have been received from netfabb
  if(!Number(part.xDimUnits)){delete part.xDimUnits}
  if(!Number(part.yDimUnits)){delete part.yDimUnits}
  if(!Number(part.zDimUnits)){delete part.zDimUnits}
  if(!Number(part.partVolumeUnits)){delete part.partVolumeUnits}
  if(!Number(part.partSurfaceAreaUnits)){delete part.partSurfaceAreaUnits}

  delete part.meshProperties

  delete part.wallThicknessOBJFileMM
  delete part.wallThicknessMaterialFileMM
  delete part.wallThicknessOBJFileInches
  delete part.wallThicknessMaterialFileInches
  delete part.wallThicknessErrorMM
  delete part.wallThicknessErrorInches
  delete part.wallThicknessResultsMM
  delete part.wallThicknessResultsInches

  partGroup.part = part
  return partGroup
}
