import React from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, Radio,
         RadioGroup, Select, TextField } from '@material-ui/core'
import { SHIP_PROVIDER_OPTIONS } from '../SELECT_FIELDS.js'
import { translateShipMethodToUserDeliveryDateString } from '../../utils'
import USER_TYPES from '../../utils/USER_TYPES.js'

export const ShippingDetailsExpansionDetails = ({
  areShippingOptionsLoading,
  fieldsToHighlight,
  formatShippingLabel,
  nextStepHandler,
  project,
  shippingOptions,
  setProjectFieldAndSave,
  setShipVia,
  shipVia,
  user,
}) => {
  return(
    <div style={{width:'100%'}}>
      <span style={{fontWeight:'bold'}}>Ship via</span><br/>
      <FormControl component="fieldset" style={{marginBottom:'10px'}}>
        <RadioGroup
          value={shipVia}
          onChange={ev=>setShipVia(ev.target.value)}
        >
          <FormControlLabel value="autotiv" control={<Radio />} label="Autotiv" />
          <FormControlLabel value="customerShipping" control={<Radio />} label="Your Shipping Account" />
        </RadioGroup>
      </FormControl><br/>
      {shipVia === 'customerShipping' ?
        <div style={{padding:'5px'}}>
          <form style={{display:'flex'}}>
            <FormControl style={{minWidth:'175px'}} fullWidth={true}>
              <Select
                native
                onChange={ev=>setProjectFieldAndSave("shipProvider",ev.target.value)}
                value={project.shipProvider}
              >
                {Object.values(SHIP_PROVIDER_OPTIONS).map(
                  spo => <option key={`spo_${spo}`} value={spo}>{spo}</option>
                )}
              </Select>
            </FormControl>
          </form><br/>

          <TextField
            error={fieldsToHighlight.accountNumber}
            id="shipAccountNumber"
            label="* Account Number"
            onChange={ev=>setProjectFieldAndSave("shipAccountNumber",ev.target.value)}
            style={{marginBottom: '15px', minWidth:'220px', width:'100%'}}
            value={project.shipAccountNumber}
            variant="outlined"
          /><br/>
          <TextField
            error={fieldsToHighlight.accountZip}
            id="shipAccountZipCode"
            label="* Zip"
            onChange={ev=>setProjectFieldAndSave("shipAccountZipCode",ev.target.value)}
            style={{marginBottom: '15px', minWidth:'220px', width:'100%'}}
            value={project.shipAccountZipCode}
            variant="outlined"
          /><br/>
        </div>
        : null
      }

      {user.type === USER_TYPES.DME ?
       <>
         <span style={{fontWeight:'bold'}}>Label Type</span><br/>
         <RadioGroup aria-label="gender" name="labelType" value={project.labelType} onChange={ev => setProjectFieldAndSave("labelType", ev.target.value)}>
           <FormControlLabel value="Autotiv Label" control={<Radio />} label="Autotiv Label" />
           <FormControlLabel value="White Label" control={<Radio />} label="White Label" />
           {/* <FormControlLabel value="Customer Label" control={<Radio />} label="Customer Label" /> */}
         </RadioGroup>
         <br/>
       </> :
       project.labelType === 'White Label' || project.labelType === 'Customer Label' ?
       <>
         <span style={{fontWeight:'bold'}}>Label Type</span><br/>
         <div style={{paddingTop:'0.5em'}}>
           {project.labelType}
         </div>
         <br/>
       </>
       : null
      }

      <span style={{fontWeight:'bold', color: fieldsToHighlight.paidShipMethod ? 'red' : ''}}>Choose a Guaranteed Delivery Date</span><br/>
      {areShippingOptionsLoading ? 'Loading...' : 
       <>
         <FormControl component="fieldset" style={{marginBottom:'10px'}}>
           <RadioGroup
             value={project.isCustomShippingMethodUsed ? 'custom' : project.paidShipMethod}
             onChange={ev=>setProjectFieldAndSave("paidShipMethod",ev.target.value)}
           >
             {shippingOptions.map((option,index)=>
                                  <FormControlLabel
                                    disabled={project.isCustomShippingMethodUsed && option.shipMethod !== 'custom'}
                                    value={option.shipMethod}
                                    control={<Radio color="primary"/>}
                                    key={index + option.shipMethod}
                                    label={formatShippingLabel(option)}
                                  />
                                 )}
           </RadioGroup>
         </FormControl><br/>
         <div style={{display:'flex',justifyContent:'space-between',alignItems:'end'}}>
           <div style={{backgroundColor:'#E0E0E0',borderRadius:'4px',padding:'10px'}}>
             Guaranteed Delivery Date<br/>
             <span style={{fontWeight:'bold'}}>{translateShipMethodToUserDeliveryDateString(project.paidShipMethod, areShippingOptionsLoading, shippingOptions, project.isCustomShippingMethodUsed)}</span>
           </div>
           <div>
             <Button color="secondary" onClick={()=>{setTimeout(()=>{nextStepHandler()}, 175)}} variant="contained">
               Next
             </Button>
           </div>
         </div>
       </>
      }
    </div>
  )
}
