import React,{useContext,useEffect,useState} from 'react'
import {Paper,Table,TableBody,TableCell,TableRow} from '@material-ui/core'
import MaybeTruncatedValue from './MaybeTruncatedValue.js'
import {COLORS} from '../../../shared/COLORS.js'
import {appendInfillIfNeeded} from '../../../shared/appendInfillIfNeeded.js'
import {postProcessesAsString} from '../../../shared/postProcessesAsString.js'
const {TranslateMaterialId} = require('../../Common/TranslationTableMaterial.js')

function PartGroupsDataCard(props) {
  const { MATERIAL_TRANSLATIONS, postProcessData, project, ...otherProps } = props;

  return (
    <Paper
      {...otherProps}
    >
      <Table>
        <TableBody className="table-rows-alternating-colors">
          <TableRow>
            <TableCell className="table-header-row">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  Part Data
                </div>
                <div>
                  Quantity
                </div>
              </div>
            </TableCell>
          </TableRow>
          <LineItemRows
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            partGroups={project.partGroups}
            postProcessOptionsFromDb={postProcessData}
          />
        </TableBody>
      </Table>
    </Paper>
  )
}

function LineItemRows({MATERIAL_TRANSLATIONS, partGroups, postProcessOptionsFromDb}){
  return partGroups.flatMap((partGroup)=>{
    const PartGroupRow = (
      <PartGroupDataRow
        key={partGroup.partGroupNumber}
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        partGroup={partGroup}
        postProcessOptionsFromDb={postProcessOptionsFromDb}
      />
    )
    const PtpcsRows = partGroup.productionToolPartConfigurations
       .map(ptpc =>
            <PtpcDataRow
              key={ptpc.productionToolPartConfigurationId}
              MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
              partGroup={partGroup}
              ptpc={ptpc}
              postProcessOptionsFromDb={postProcessOptionsFromDb}
            />
           )

    return [ PartGroupRow, ...PtpcsRows ]
  })
}

const PartGroupDataRow = ({ MATERIAL_TRANSLATIONS, partGroup, postProcessOptionsFromDb }) => {
  let material = TranslateMaterialId(partGroup.processId, partGroup.materialId, MATERIAL_TRANSLATIONS)
  material = appendInfillIfNeeded(material, partGroup.processId, partGroup.infillPercentage)

  return (
    <TableRow className='fixed-part-row-height'>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1em' }}>
          <div style={{ fontWeight: 'bold' }}>
            <MaybeTruncatedValue
              value={partGroup.part.fileName}
              length={55}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {getProcessDisplay(partGroup)} - {material}
            </div>
            <div>
              {partGroup.quantity}
            </div>
          </div>
          <MaybeTruncatedValue
            value={partGroup.postProcesses.length ? 'Post Processes: ' + postProcessesAsString(partGroup.postProcesses, postProcessOptionsFromDb.POST_PROCESS_OPTIONS): ''}
            length={55}
          />
          <MaybeTruncatedValue
            value={partGroup.part.partNotes ? 'Notes: ' + partGroup.part.partNotes: ''}
            length={55}
          />
        </div>
      </TableCell>
    </TableRow>
  )
}

const PtpcDataRow = ({ MATERIAL_TRANSLATIONS, partGroup, postProcessOptionsFromDb, ptpc }) => {
  return (
    <TableRow className='fixed-part-row-height'>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1em' }}>
          <div style={{ fontWeight: 'bold' }}>
            <MaybeTruncatedValue
              value={partGroup.part.fileName}
              length={55}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {getProcessDisplay(partGroup, true)} - {TranslateMaterialId(partGroup.processId, ptpc.materialId, MATERIAL_TRANSLATIONS)} {getColorDisplayFromColor(ptpc.color)}
            </div>
            <div>
              {ptpc.quantity}
            </div>
          </div>
          <MaybeTruncatedValue
            value={ptpc.postProcesses.length ? 'Post Processes: ' + postProcessesAsString(ptpc.postProcesses, postProcessOptionsFromDb.POST_PROCESS_OPTIONS): ''}
            length={55}
          />
          <MaybeTruncatedValue
            value={ptpc.notes ? 'Notes: ' + ptpc.notes: ''}
            length={55}
          />
        </div>
      </TableCell>
    </TableRow>
  )
}

function getProcessDisplay(partGroup, isPtpc){
  if(partGroup.processId === 'injMoldingPart'){
    return isPtpc ? 'IM Part' : 'Injection Mold'
  }
  if(partGroup.processId === 'casting'){
    return isPtpc ? 'Casting Part' : 'Casting Mold'
  }
  return partGroup.process
}

function getColorDisplayFromColor(color){
  const colorMaybe = COLORS
        .find(colorObj => colorObj.value === color)

  return colorMaybe ? colorMaybe.label : ''
}

export default PartGroupsDataCard
