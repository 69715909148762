export default function limitToTwoDecimals(value){
  let [number, decimal] = String(value).split('.')
  let valueTwoDecimalPlacesMax = number

  if(decimal){
    let decimalToAdd = decimal
    if(decimal.length > 2){
      decimalToAdd = (`${decimal}00`).substring(0,2)
    }
    valueTwoDecimalPlacesMax += `.${decimalToAdd}`
  }

  return valueTwoDecimalPlacesMax
}
