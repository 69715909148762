import React from 'react'
import { Button } from '@material-ui/core'
import csvDownload from 'json-to-csv-export'

export default function DownloadCsvButton({project}){
  return (
    <Button
      color="primary"
      onClick={() => csvDownload({
        data: project.partGroups.map(csvRowFromPartGroup),
        filename: `partDataForProject${project.projectNumber}.csv`,
      })}
      variant="contained"
    >
      Download Csv
    </Button>
  )
}

function csvRowFromPartGroup(partGroup){
  const csvRow = {
    'File Name': partGroup.part.fileName,
    'Process': partGroup.process,
    'Material': partGroup.material,
    'Quantity': partGroup.quantity,
    'Part Notes': partGroup.part.partNotes,
    'Volume MM^3': partGroup.part.partVolume,
    'X Dim MM': partGroup.part.xDim,
    'Y Dim MM': partGroup.part.yDim,
    'Z Dim MM': partGroup.part.zDim,
    'Surface Area MM^2': partGroup.part.partSurfaceArea,
  }
  return csvRow
}
