import React from 'react'
import {NativeSelect as Select} from '@material-ui/core' //DXI: NativeSelect looks better in dropdown menu

const SelectOptionShipping = ( props ) => {
  const { name, onChange, value , ...otherProps } = props
  return(
        <Select
          name={name}
          onChange={onChange}
          value={value}
          {...otherProps}
        >
          <option value="">None</option>
          <option value="1 Day">1 Day</option>
          <option value="2 Day">2 Day</option>
          <option value="3 Day">3 Day</option>
          <option value="5 Day">5 Day</option>
          <option value="Next Day Air Early">Next Day Air Early</option>
          <option value="Next Day Saver">Next Day Saver</option>
          <option value="1 Day Saturday">1 Day Saturday</option>
          <option value="Ground International NA">Ground International NA</option>
          <option value="International NA">International NA</option>
          <option value="International EUR">International EUR</option>
          <option value="International ASIA">International ASIA</option>
          <option value="Pickup">Pickup</option>

          <option value="Small Package DHL on Autotiv's Account">Small Package DHL</option>
          <option value="Small Package Fedex on Autotiv's Account">Small Package Fedex</option>
          <option value="Freight Air - Contact Autotiv for Instructions">Freight Air</option>
          <option value="Freight Ocean - Contact Autotiv for Instructions">Freight Ocean</option>
        </Select>
  )
}

export default SelectOptionShipping
