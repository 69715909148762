import frg from '../../feature-release-guards.js'

export const determinePartRowTypeToReturn = ( isPartAtRisk, status, part ) => {

    if (!part.partFileS3) {
      return "uploading_part"
    }

  if (status.CAN_BE_ANALYZED) {
    if (status.PARAM_EXTRACT_FINISHED) {
      if (!part.units && !status.HAD_ISSUES_ANALYZING_PARAMS) {
        return "no_units"
      }
      else {
        if (status.WALL_THICKNESS_TASK_FINISHED || status.HAD_ISSUES_ANALYZING_WALL_THICKNESS) {
          if (isPartAtRisk) {
            // If parameter extraction is complete, part has units,
            // wall thickness analysis is done AND part is at risk
            return "at_risk"
          }
          else {
            // If parameter extraction is complete, part has units,
            // wall thickness analysis is done and part is NOT at risk
            return "analysis_complete"
          }
        }
        else {
          // If parameter extraction is complete, part has units,
          // but wall thickness analysis is not done
          return "waiting_for_wall_thickness_analysis"
        }
      }
    }
    else {
      // can be analyzed but parameter extraction is not complete
      return "parameter_extract_in_progress"
    }
  }
  else {  // If part cannot be analyzed
    return "analysis_complete"
  }
}
