import React from 'react'

export default function ClickableLink(text, url){
  return (
    <a
      href={url}
      rel="noreferrer noopener"
      style={{
        color: 'white'
      }}
      target="_blank"
    >
      {text}
    </a>
  )
}
