import * as MACROS from './threeJsMacros.js'
// note to reader: search 'MACROS' to see where threejs stuff is

export default function newInstance(el,units,objFile){
  // Create scene and add objects
  const { camera, controls, renderer, scene, cleanUp } = MACROS.sceneSetup(el)

  let { centeredModel, wireFrameObject } = MACROS.addMeshToScene(objFile, scene, camera, controls)

  // Add grid that appears under the model, and the units text that displays next to it
  MACROS.addGridHelpersAndTextSpriteToScene(centeredModel, scene, units)

  // Adjust camera to make sure model is visible
  MACROS.adjustCameraForModelSize(camera, controls, centeredModel)

  // Attach the viewer to the DOM using React ref
  el.appendChild(renderer.domElement)

  MACROS.reRender(renderer,scene,camera,controls)

  // Initialize arrays to hold objects that will be added or removed from the scene throughout the user experience
  const originalCameraPosition = camera.position.clone()
  let arrowViewObjectsToToggle = []
  const addExistingArrowsToScene = () => arrowViewObjectsToToggle.forEach(object => scene.add(object))
  const createAndAddArrowsToScene = () => arrowViewObjectsToToggle = MACROS.addArrowsToScene(centeredModel,scene,originalCameraPosition)
  const toggleArrowViewObjectsOn = () => arrowViewObjectsToToggle.length ? addExistingArrowsToScene() : createAndAddArrowsToScene()
  const toggleArrowViewObjectsOff = () => arrowViewObjectsToToggle.forEach(object => scene.remove(object))

  return {
    changeViewingMode,
    cleanUp,
    toggleArrowViewObjectsOn,
    toggleArrowViewObjectsOff,
    updateModel,
    updateModelWithDefaultMaterial,
    updateModelWithMaterial,
  }

  // THUNKS: helper functions
  function updateModelWithMaterial(material){
    centeredModel.children[0].material = MACROS.getWallThicknessMaterial(material)
    centeredModel.children[0].geometry.uvsNeedUpdate = true
    MACROS.reRender(renderer,scene,camera,controls)
  }
  function updateModelWithDefaultMaterial(){
    centeredModel.children[0].material = MACROS.getDefaultMaterial()
    centeredModel.children[0].geometry.uvsNeedUpdate = true
    MACROS.reRender(renderer,scene,camera,controls)
  }

  // THUNK
  function updateModel(objFile){
    // Must update both the model and material at the same time:
    // The model contains the thin wall locations, the material maps those to actual colors

    scene.remove(centeredModel);
    scene.remove(wireFrameObject);

    const newObjects = MACROS.addMeshToScene(objFile, scene, camera, controls)

    centeredModel = newObjects.centeredModel
    wireFrameObject = newObjects.wireFrameObject
    MACROS.reRender(renderer,scene,camera,controls)
  }

  function changeViewingMode(viewingModeIndex){
    MACROS.updateMaterialsBasedOnViewingMode(centeredModel.children[0].material,viewingModeIndex)
    MACROS.reRender(renderer,scene,camera,controls)
  }
}
