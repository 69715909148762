/*
  translateExpediteLevelToValue converts a expedite level (1-4) into the corresponding expedite lead time option's value
  example : if levelToTranslate = 3, it returns the value of project.expediteLeadTimeOption3
  You can provide an optional arg `levelOverride` that will be used instead of project.paidExpediteLevel
*/
const TranslateExpediteLevelToValue = (project,levelOverride) => {
  const PROJECT_LEVEL = project.paidExpediteLevel
  let levelToTranslate = (levelOverride) ? levelOverride : PROJECT_LEVEL  //If override is provided use it instead of project level
  if(levelToTranslate){
    let translatedExpediteValue
    translatedExpediteValue = (Number(levelToTranslate) === 1) ? (project.overrides.redLeadTime === "custom" ? project.expediteLeadTimeOverride1 : project.expediteLeadTimeOption1) : translatedExpediteValue
    translatedExpediteValue = (Number(levelToTranslate) === 2) ? (project.overrides.orangeLeadTime === "custom" ? project.expediteLeadTimeOverride2 : project.expediteLeadTimeOption2) : translatedExpediteValue
    translatedExpediteValue = (Number(levelToTranslate) === 3) ? (project.overrides.yellowLeadTime === "custom" ? project.expediteLeadTimeOverride3 : project.expediteLeadTimeOption3) : translatedExpediteValue
    translatedExpediteValue = (Number(levelToTranslate) === 4) ? (project.overrides.standardLeadTime === "custom" ? project.expediteLeadTimeOverride4 : project.expediteLeadTimeOption4) : translatedExpediteValue
    return translatedExpediteValue
  }
  else{
    return //If levelToTranslate is undefined return undefined
  }
}

export default TranslateExpediteLevelToValue
