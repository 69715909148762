import {  getDefaultFinish, FINISH_TRANSLATIONS } from '../utils'
const {getDefaultMaterialId} = require('../Components/Common/TranslationTableMaterial.js')

/*
  updateMaterialAndFinish checks if current finish and Material states are valid for updated Process
  If not, update finish and Material fields to appropriate defaults for given Process
*/
export function updateMaterialAndFinish(row, MATERIAL_TRANSLATIONS) {
  let updatedRow = row
  let finishValid = false
  let materialValid = false
  if (row.finishId) { //Check if row finish is still valid
    //loop through FINISH_TRANSLATIONS to make a filtered array of finish translation objects for given process
    let filteredArray = FINISH_TRANSLATIONS.filter(currentFinish => {
      if (currentFinish.processId === row.process) {
        return currentFinish
      }
      return null
    })
    //loop through filtered array to check if current row.finish is present
    finishValid = filteredArray.some(currentFinish => {
      if (currentFinish.id === row.finishId) {
        return true
      }
      return false
    })
  }

  if (row.materialId) { //Check if row material is still valid
    //loop through MATERIAL_TRANSLATIONS to make a filtered array of material translation objects for given process
    let filteredArray = MATERIAL_TRANSLATIONS.filter(currentMaterial => {
      if (currentMaterial.processId === row.processId) {
        return currentMaterial
      }
      return null
    })
    //loop through filtered array to check if current row.material is present
    materialValid = filteredArray.some(currentMaterial => {
      if (currentMaterial.id === row.materialId) {
        return true
      }
      return false
    })
  }
  updatedRow.finishId = finishValid ? updatedRow.finishId : getDefaultFinish(row.processId) //If finish is valid, use that value, if not use default finish for that process
  updatedRow.materialId = materialValid ? updatedRow.materialId : getDefaultMaterialId(row.processId, MATERIAL_TRANSLATIONS)
  return updatedRow
}
