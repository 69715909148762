import { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import {SnackBarContext} from '../Common/SnackBarStore'

export default function DownloadQuoteButton({color, disabled, downloadQuote, label, style, ...otherProps}){
  const [isMakingPdf, setIsMakingPdf] = useState(false)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  const handleDownloadQuoteClick = () => {
    setIsMakingPdf(true)
    downloadQuote()
      .catch(e => openSnackBarWithMessage(e.message))
      .finally(() => setIsMakingPdf(false))
  }

  const buttonLabel = label ? label : 'Download Quote Pdf'

  return (
    <Button
      color={color ? color : "primary"}
      disabled={disabled || isMakingPdf}
      onClick={handleDownloadQuoteClick}
      style={style}
      variant="contained"
      {...otherProps}
    >
    { isMakingPdf ? 'Downloading...' : buttonLabel }
    </Button>

  )
}
