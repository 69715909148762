import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('../utils/logUnexpected.js')

export default async function deleteProductionToolPartConfiguration(productionToolPartConfigurationId){
  let response
  try{
    response = await deleteProductionToolPartConfigurationRequest(productionToolPartConfigurationId)
  } catch (err){
    logUnexpected(err,{ productionToolPartConfigurationId }, 'deleteProductionToolPartConfigurationRequest')
    throw err
  }

  let result
  try{
    result = parseDeleteProductionToolPartConfigurationResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseDeleteProductionToolPartConfigurationResponse(response)', { productionToolPartConfigurationId })
    throw err
  }

  return result
}

function deleteProductionToolPartConfigurationRequest(productionToolPartConfigurationId){
  return axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.DELETE_PTPC_ENDPOINT,
    data: {
      productionToolPartConfigurationId,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseDeleteProductionToolPartConfigurationResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.statusCode === "200"){
      return response.data.statusMessage
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
