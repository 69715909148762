import { getPart } from '../../../utils/getPart.js'
import { sleep } from '../../../utils/util.js'

export { pollDatabaseForResultWithTimeout as pollDatabaseForResult }

// repeatedly check for a result from part analysis by checking the contents of the part record in
// the database. We know that the analysis is done when the part record either contains the results
// of a successful analysis or an analysis error message. A promise containing the part will resolve
// when the results are found or the promise will reject if it times out before an analysis result
// is found
async function pollDatabaseForResultWithTimeout(partNumber, successFields, errorField, throttleInMilliseconds, timeoutInMilliseconds) {
  const pollDatabasePromise = pollDatabaseForResult(partNumber, successFields, errorField, throttleInMilliseconds)
  return promiseWithTimeout(pollDatabasePromise, timeoutInMilliseconds)
  .catch((err) => {
    if (err.message.includes('timeout')) {
      throw new Error(`failed to fetch netfabb results in the alotted time (${timeoutInMilliseconds} ms) for partNumber: ${partNumber}`)
    }
    throw err
  })
}

async function pollDatabaseForResult(partNumber, successFields, errorField, throttleInMilliseconds) {
  let part
  while (true) {
    try {
      part = await getPart(partNumber)
    } catch (err) {
      // ignore individual errors from getPart and just continue to the next loop
      await sleep(throttleInMilliseconds)
      continue
    }

    let hasResults = partHasResults(part, successFields, errorField)
    if (hasResults) return part

    await sleep(throttleInMilliseconds)
  }
}

function partHasResults(part, successFields, errorField) {
  let taskSucceeded = successFields.every((field) => {
    return Boolean(part[field]) && part[field] != 0
  })

  let taskFailed = Boolean(part[errorField])

  return taskSucceeded || taskFailed
}

function promiseWithTimeout(thePromise, timeoutInMilliseconds) {
  let timerId
  let timeoutPromise = new Promise((_, reject) => {
    timerId = setTimeout(reject, timeoutInMilliseconds, Error(`Promise failed to settle before a timeout of ${timeoutInMilliseconds} ms`))
  })

  return Promise.race([
    timeoutPromise,
    thePromise
  ]).then((successfulResult) => {  // pass-through
    // clean-up the now-irrelevant timer just to be polite; not vital
    clearTimeout(timerId); return successfulResult;
  })
}
