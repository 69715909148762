import React from "react"
import { Button } from "@material-ui/core"
import AddBoxIcon from '@material-ui/icons/AddBox'

export const TextButtonWithIcon = ({ disabled, isNotDmeReviewed, onClick, buttonText }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      startIcon={<AddBoxIcon/>}
      style={{
        color: isNotDmeReviewed ? 'red' : '',
        padding: 0,
        margin: 0 /* zero-out MUI & user-agent defaults for buttons */
      }}
      className="truncated-list-container"
    >
      <span className="wrapped-list truncated-list override-button-text">{buttonText}</span>
    </Button>
  )
}
