import { stripeChargeWithTimeoutAndRetry } from './stripeCharge.js'
import { updateWholeProjectPromise } from '../../../utils/updateWholeProjectPromise.js'
import { sendErrorEmail } from '../../../utils/util.js'

// placeOrder adds some delivery and payment data to the local project object, charges the payment
// (either a PO or credit card), and then updates the project in the database at the end. It has no
// return value because this is the end of the workflow for a project and the project gets cleared
// from React state after this finishes
export function placeOrderAsCustomer(project, paymentMethod, paymentPO, stripeToken) {
  const projectToBook = prepProjectForStripeProcessing(project, paymentMethod, paymentPO)

  return stripeChargeWithTimeoutAndRetry(projectToBook, paymentMethod, stripeToken, 5000)
  .then((res) => {
    if (paymentMethod === 'credit') projectToBook.paymentReferenceNumber = res.chargeId
    // this will tell the backend to update the project file locations in Google Drive when it
    // updates the project in the database. This is a good time because its the final update of the
    // project before the it gets booked and cleared from React state
    projectToBook.updateGdriveLocations = true
    return projectToBook
  })
  .then(updateWholeProjectPromise)
  .then((response) => {
    // response handling for updateWholeProjectPromise isnt ecapsulated in the function and so we
    // are forced to do the response handling here which is not ideal. This is targeted for removal
    // after refactoring updateWholeProjectPromise
    if (response === "200") {
      return
    } else {
      throw new Error(
        `Update project call failed with a non-200 status code in the response body:
        ${JSON.stringify(response)}`
      )
    }
  })
  .catch((err) => {
    sendErrorEmail(err)
    let errorMessageForUI = determinePlaceOrderErrorMessageToDisplayToUser(err)
    throw new Error(errorMessageForUI)
  })
}

function determinePlaceOrderErrorMessageToDisplayToUser(err) {
  let msg
  if(err.message === "card_error"){
    msg = "Card was declined. Please double-check your entered billing information. If that still doesn't work try a different card or contact us at contact@autotiv.com to get these parts ordered."
  } else {
    msg = 'There was an issue submitting your project.'
    msg += ' Please reach out to us directly at contact@autotiv.com'
    msg += ' and we will get back to you ASAP'
  }

  return msg
}

// adds project data that is required for our backend book-order/payment API
function prepProjectForStripeProcessing(project, paymentMethod, paymentPO) {
  // make a copy of the project
  let projectToBook = { ...project }

  projectToBook.quoteStatus = "Quote Sent"
  projectToBook.customerReady = false
  projectToBook.orderSubmittedType = 'checkedOut'
  return projectToBook
}
