import React from 'react'
import {CircularProgress,Divider,IconButton,Modal,Tooltip,Typography,withStyles} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {FileDragAndDrop} from '../Common'
import {MAX_ALLOWED_ATTACHMENT_FILE_SIZE_IN_BYTES} from '../../utils'
import downloadFromS3AndSaveFile from '../../utils/downloadFromS3AndSaveFile.js'
import {CloudDownload} from '@material-ui/icons'

const AttachmentsModal = props => {
  return(
    <Modal
      open={props.open}
      onClose={props.onClose}
      style={{alignItems:'center',display:'flex',justifyContent:'center',maxHeight:'100vh',overflowY:'auto'}}
    >
      <div className={props.classes.paper}>
        <Typography align="center" style={{marginBottom:'16px'}} variant="h4">
          {props.partName ? `${props.partName} Attachments` : `Project ${props.projectNumber} Attachments`}
        </Typography>

        <Divider style={{marginBottom:'8px'}}/>

        {props.attachments.map((attachment,index)=>{
          return (
            <div key={"fileAttachment" + index} style={{display:'flex',justifyContent:'space-between'}}>
              <div style={{display:'flex', gap: '0.5em'}}>
                {attachment.fileName.length < 26 ?
                 <FileNameTypography name={attachment.fileName}/> : <FileNameWithAbbreviation name={attachment.fileName}/>
                }
                <IconButton tabIndex="-1" color="primary" onClick={() => downloadFromS3AndSaveFile(attachment.s3Id, attachment.fileName)}>
                  <CloudDownload
                    color="primary"
                    fontSize="small"
                  />
                </IconButton>
              </div>

              <DeleteAndLoadingIcon
                currentlyLoading={attachment.currentlyLoading}
                fileId={attachment.fileId}
                removeAttachment={props.removeAttachment}
                style={props.classes.iconHover}
              />
            </div>
          )
        })}

        {/* Allowed file type list is limited, and not progromatically linked
           to the full list of allowed file types. This was a design decision
           because the full list is too long */}
        <FileDragAndDrop
          currentlyLoading={props.currentlyLoading}
          dragAndDropType={'attachments'}
          maxFileSizeAllowedInBytes={MAX_ALLOWED_ATTACHMENT_FILE_SIZE_IN_BYTES}
          uploadParts={props.uploadParts}
        />
      </div>
    </Modal>
  )
}

const FileNameWithAbbreviation = ({name}) => {
  return(
    <Tooltip enterDelay={400} title={name}>
      <Typography align="center" color="textPrimary" variant="subtitle1" style={{paddingTop:'12px'}}>
        {name.substr(0,26).concat('...')}
      </Typography>
    </Tooltip>
  )
}

const FileNameTypography = ({name}) => {
  return(
     <Typography align="center" color="textPrimary" variant="subtitle1" style={{paddingTop:'12px'}}>
       {name}
     </Typography>
  )
}

const DeleteIconWithTooltip = ({fileId,removeAttachment,styles}) => {
  return(
    <Tooltip enterDelay={400} title="Delete this attachment">
      <IconButton color="primary" className={styles} onClick={()=>setTimeout(()=>{removeAttachment(fileId)},215)}>
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
  )
}

const DeleteAndLoadingIcon = ({currentlyLoading,fileId,removeAttachment,style}) => {
  if(currentlyLoading){
    return(
      <div style={{paddingRight:'12px',paddingTop:'12px'}}>
        <CircularProgress size={20}/>
      </div>
    )
  }
  else{
    return(
      <DeleteIconWithTooltip
        fileId={fileId}
        removeAttachment={removeAttachment}
        style={style}
      />
    )
  }
}

const STYLES = theme => ({
  iconHover: {
    '&:hover': {
      color: theme.palette.primary.dark,
    }
  },
  paper:{
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    padding: theme.spacing(4),
    position: 'absolute',
    top: '20%',
    width: '30vw',
    minWidth: '40em',

    // Needs to be display flex for the dropzone component to be able to center itself
    display: 'flex',
    flexDirection: 'column'
  }
})

export default withStyles(STYLES)(AttachmentsModal)
