import React,{Fragment,useState} from 'react'
import {Button,Checkbox,CircularProgress,FormControlLabel,TextField,Typography,withStyles} from '@material-ui/core'
import { useToggledBoolean } from '../../utils'
import { Link } from "@reach/router"

const ConfirmCodePage = ({ classes, forgotPassword, location, popupMessage }) => {
  const [code, setCode] = useState('')
  const [newPassword, setPassword] = useState('')
  const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false)
  const [revealPasswordText, setRevealPassword] = useToggledBoolean(false)

  const passwordResetConfirmCodeHandler = async () => {
    if (newPassword && code) {
      if (newPassword.length < 8) {
        popupMessage('Password must be at least 8 characters long')
        return
      }
      else{
        setIsCurrentlyLoading(true)
        await forgotPassword(location.state.email, code, newPassword)
        setIsCurrentlyLoading(false)
      }
    }
    else{
      popupMessage('Code and new password are required to submit')
    }
  }

  const HEADER_PLACEHOLDER = (isCurrentlyLoading) ? "AUTHENTICATING" : "Enter Code"
  const HIDE_OR_REVEAL_PASSWORD = (revealPasswordText) ? "text" : "password"
  return(
    <Fragment>
      <div align="center">
        <div className={classes.loadingBox}>
          {isCurrentlyLoading && <CircularProgress className={classes.progress} color="secondary" size={200}/>}
        </div>

        <div className={classes.loginDiv}>
          <Typography className={classes.paperHeader} color="textPrimary" variant="h4">
            {HEADER_PLACEHOLDER}
          </Typography>
          <TextField
            autoFocus={true}
            className={classes.textField}
            disabled={isCurrentlyLoading}
            inputProps={{min: "0"}}
            label="Code"
            margin="dense"
            name="code"
            onChange={(e) => setCode(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){passwordResetConfirmCodeHandler()}
            }}
            type="text"
            value={code||''}
          /><br/>
          <TextField
            autoFocus={true}
            className={classes.textField}
            disabled={isCurrentlyLoading}
            inputProps={{min: "0"}}
            label="New Password - minimum 8 characters"
            margin="dense"
            name="newPassword"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter'){passwordResetConfirmCodeHandler()}
            }}
            type={HIDE_OR_REVEAL_PASSWORD}
            value={newPassword||''}
          /><br/>
          <div className={classes.flexBox}>
            <div className={classes.flexBoxLeft}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={revealPasswordText}
                    onChange={setRevealPassword}
                    value="show password"
                  />
                }
                label="Show Password"
              />
            </div>
          </div>
          <Button
            className={classes.buttonSubmit}
            color="secondary"
            disabled={isCurrentlyLoading}
            onClick={passwordResetConfirmCodeHandler}
            style={{minWidth:'100px'}}
            variant="contained"
          >
            SUBMIT
          </Button><br/>
          Remember your password? Login <Link to="../">here</Link>.
        </div>
      </div>
    </Fragment>
  )
}

const styles = theme => ({
  buttonSubmit:{
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  flexBox:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexBoxLeft:{
    paddingLeft: theme.spacing(5)
  },
  loadingBox:{
    height:'300px',
    paddingTop: '50px'
  },
  loginDiv: {
    marginBottom: '10%',
    width: '600px',
  },
  paperHeader:{
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign:'center'
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: '520px'
  }
})

export default withStyles(styles)(ConfirmCodePage)
