import React from "react"
import { FormControlLabel, Switch, Typography } from "@material-ui/core"
import { SelectOption } from "./SelectOption.js"
import { NumberField } from "./NumberField.js"

export default function DmeMarkupQuote(props) {
  const onChangeHandler = e => props.onChange(e, props.partGroup.partGroupNumber)
  return(
    <div className="dme-markup-quote">
      <FormControlLabel
        checked={props.partGroup.pricingMethod === "markup"}
        color="secondary"
        control={<Switch/>}
        label={<Typography variant="h6" className="dme-section-header"> Markup Quote </Typography>}
        labelPlacement="top"
        onChange={e => {
          const { target: { checked, name }} = e
          const pricingMethod = checked ? "markup" : "calculated"
          onChangeHandler({
            target: {
              name: 'pricingMethod',
              value: pricingMethod,
            }
          })
        }}
        style={{marginLeft:'0em'}}
      />
      <NumberField
        label="Quantity 1 Price"
        name="markupCostQuantity1"
        onChange={onChangeHandler}
        styles={{width:"8em"}}
        value={props.partGroup.markupCostQuantity1}
      />
      <NumberField
        label="Quantity X"
        name="markupQuantityX"
        onChange={onChangeHandler}
        styles={{width:"10em"}}
        value={props.partGroup.markupQuantityX}
      />
      <NumberField
        label="Quantity X Price"
        name="markupCostQuantityX"
        onChange={onChangeHandler}
        styles={{width:"8em"}}
        value={props.partGroup.markupCostQuantityX}
      />
    </div>
  )
}
