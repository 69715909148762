import mockNestedPartGroupApiCalls from './mockNestedPartGroupApiCalls.js'

const configureNestedPartGroupFieldsCrud = (nestedObjectsConfig, nestedPtpcFieldsCrud) => (project, setProject) => {
  return {
    partGroups: (partGroupNumber) => ({
      ...nestedPartGroupFieldsCrud(partGroupNumber),
      productionToolPartConfigurations: (ptpcId) => ({
        ...nestedPtpcFieldsCrudGeneration(partGroupNumber, ptpcId),
      })
    })
  }

  function nestedPartGroupFieldsCrud(partGroupNumber){
    const getPartGroup = (project) => project.partGroups
          .find(ptg => ptg.partGroupNumber === partGroupNumber)

    const configToCrudObject = ptgObjConfig => (id) => nestedPartGroupFieldCrud(project, setProject, ptgObjConfig, 'partGroup', partGroupNumber, id, getPartGroup)
    return crudConfigToObject(nestedObjectsConfig, configToCrudObject)
  }

  function nestedPtpcFieldsCrudGeneration(partGroupNumber, ptpcId){
    const getPtpc = (project) => project.partGroups
          .find(ptg => ptg.partGroupNumber === partGroupNumber)
          .productionToolPartConfigurations
          .find(ptpc => ptpc.productionToolPartConfigurationId === ptpcId)

    const configToCrudObject = ptgObjConfig => (id) => nestedPartGroupFieldCrud(project, setProject, ptgObjConfig, 'productionToolPartConfigurationId', ptpcId, id, getPtpc)
    return crudConfigToObject(nestedPtpcFieldsCrud, configToCrudObject)
  }
}

// A config in this case is an object that holds the configration for a nested partGroup object
// Example: partGroup.postProcesses = [postProcessConfigObject1, poseProcessConfigObject2, ...]
// so these functions create, update, and delete postProcessConfigObjects

function nestedPartGroupFieldCrud(project, setProject, ptgObjConfig, parentField, parentId, id, getObject){
  if(!ptgObjConfig.apiCalls){
    ptgObjConfig.apiCalls = mockNestedPartGroupApiCalls
  }

  const createConfig = async (...createObjectArgs) => {
    const projectCopy = {...project}

    const newObject = await ptgObjConfig.getTemplate(...createObjectArgs)
    const newConfig = await ptgObjConfig.apiCalls.create({...newObject, [parentField]: parentId})

    const object = getObject(projectCopy)

    object[ptgObjConfig.fieldName]
      .push(newConfig)

    setProject(projectCopy)
  }

  const updateConfig = (name, value) => {
    const projectCopy = {...project}

    const object = getObject(projectCopy)

    const updatedConfig = object[ptgObjConfig.fieldName]
          .find(config => config[ptgObjConfig.primaryKey] === id)

    updatedConfig[name] = value

    const callback = () => ptgObjConfig.apiCalls.update(updatedConfig)
    setProject(projectCopy, { callback })
  }

  const deleteConfig = () => {
    const projectCopy = {...project}

    const object = getObject(projectCopy)

    object[ptgObjConfig.fieldName] = object[ptgObjConfig.fieldName]
      .filter(config => config[ptgObjConfig.primaryKey] !== id)

    const callback = () => ptgObjConfig.apiCalls.delete(id)
    setProject(projectCopy, { callback })
  }

  return {
    create: createConfig,
    update: updateConfig,
    delete: deleteConfig,
  }
}

function crudConfigToObject(array, configToCrudObject){
  const keys = array.map(config => config.fieldName)
  const arrayOfFunctions = array.map(configToCrudObject)
  return arrayToObject(arrayOfFunctions, keys)
}

function arrayToObject(array, keys){
  if(array.length !== keys.length){
    throw new Error('Invalid args')
  }

  const object = {}
  keys.forEach((key, index) => {
    object[key] = array[index]
  })

  return object
}

export default configureNestedPartGroupFieldsCrud
