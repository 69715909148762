import React from "react"
import { SelectOptionProcess } from "../../Common"

export const PartProcessAtom = ({ onChange, partId, processId }) => {
  return(
    <SelectOptionProcess
      name="processId"
      onChange={(e)=>onChange(e,partId)}
      value={processId}
    />
  )
}
