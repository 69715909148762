import config from "../config"

export function checkForPartRfqReasons(partsReasons, partId) {
  let partReasons = partsReasons.find((partReasonObj) => {
    return (partReasonObj.partGroupNumber === partId ? true : false)
  })
  partReasons = partReasons ? partReasons : {}
  partReasons = partReasons.partReasons ? partReasons.partReasons : []
  return partReasons.length > 0 ? true : false
}

/*
  sendErrorEmail notifies the dev department if another service is returning errors
  It takes an error object from another service and passes it to the service
*/
export function sendErrorEmail(errorObject) {
  const errorEmailBody = {
    "token": config.MICROSERVICE_TOKEN,
    "errorObject": errorObject
  }
  // if this is set to true, the error email will only be sent to this person
  // this can be used to prevent non-production emails from going to the whole dev team
  if(false){
    errorEmailBody.toEmail = 'nick@autotiv.com'
  }
  fetch(config.ERROR_EMAIL_ENDPOINT,{
    method: "POST",
    body: JSON.stringify(errorEmailBody),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json",
    }
  })
}

/*
  Pause execution for the specified amount of time.
  Used to stagger handleFile requests by delaying execution of each
*/
export async function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
