import React, { useContext, useEffect, useState } from "react"
import {Button,Tooltip,Typography} from '@material-ui/core'
import PostProcessInputRow, {InputField} from './PostProcessInputRow.js'
import {SnackBarContext} from '../../../Common/SnackBarStore'
import AddCircleIcon from '@material-ui/icons/AddCircle'; // DR Note: Choose icons here https://v4.mui.com/components/material-icons/#material-icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {AccordionSection} from "../../AccordionSection.js"
import {v4 as uuid} from 'uuid'
import {postProcessesAsString} from '../../../../shared/postProcessesAsString.js'
import USER_TYPES from '../../../../utils/USER_TYPES.js'

export default function PostProcessRow(props){
  const { disabled, materialId, overrideLabel, postProcesses, postProcessOptionsFromDb, postProcessesCrud, user } = props

  const openSnackBarWithMessage = useContext(SnackBarContext)

  const addPostProcessToPartGroup = (postProcessId) => {
    const postProcessDbInfo = filteredDbOptions(postProcessOptionsFromDb,materialId)
          .POST_PROCESS_OPTIONS
          .find(ppo => ppo.id === postProcessId)

    const postProcessHasNoDmeInputs = postProcessDbInfo.dmeInputs.dmeConfig.dmeConfigType === null
          && !postProcessDbInfo.dmeInputs.dmeConfigOptions.isAverageLengthOfSpecialOp
          && !postProcessDbInfo.dmeInputs.dmeConfigOptions.isHardwareCostPerPack
          && !postProcessDbInfo.dmeInputs.dmeConfigOptions.isHardwarePackQty
          && !postProcessDbInfo.dmeInputs.dmeConfigOptions.isPercentageOfPart

    const userIsDme = props.user.type === USER_TYPES.DME

    const isDmeReviewed = userIsDme || postProcessHasNoDmeInputs

    let customerConfig = ''
    if(postProcessDbInfo.customerInput.customerConfigType === 'comboBox'){
      customerConfig = postProcessDbInfo.customerInput.customerConfigSelectOptions[0]
    }

    let dmeConfig = ''
    if(postProcessDbInfo.dmeInputs.dmeConfig.dmeConfigType === 'comboBox'){
      dmeConfig = postProcessDbInfo.dmeInputs.dmeConfig.dmeConfigSelectOptions[0]
    }

    postProcessesCrud().create(postProcessId,isDmeReviewed,customerConfig,dmeConfig)
  }

  const deletePostProcess = (id) => {
    postProcessesCrud(id).delete()
  }

  const setPostProcessingField = (name, value, id) => {
    postProcessesCrud(id).update(name, value)
  }

  useEffect(() => {
      const materialFilteredPostProcessOptionsFromDb = filteredDbOptions(
        postProcessOptionsFromDb,
        materialId
      )
      const materialFilteredPostProcesses = getMaterialFilteredPostProcesses(
        postProcesses,
        materialFilteredPostProcessOptionsFromDb
      )

      // compare the selected options with the choices displayed
      const postProcessesToDelete = postProcesses
            .filter(ppo => {
              // is not an option for the new material
              const isAnOption = materialFilteredPostProcesses
                    .find(filteredPpo => {
                      return filteredPpo.postProcessId === ppo.postProcessId
                    })
              return !isAnOption
            })

      if(postProcessesToDelete.length){
        postProcessesToDelete
          .forEach(
            ppo => deletePostProcess(ppo.id)
          )

        const postProcessesRemovedString = postProcessesToDelete
              .map(p => getDisplayValueFromId(p.postProcessId, postProcessOptionsFromDb.POST_PROCESS_OPTIONS))
              .join(', ')

        openSnackBarWithMessage(`Post Process options are material-specific. Changing the material removed some options: ${postProcessesRemovedString}`)
      }
  }, [materialId])

  return (
    <div>
       <PostProcessAccordion
         addPostProcessToPartGroup={addPostProcessToPartGroup}
         deletePostProcess={deletePostProcess}
         disabled={disabled}
         materialId={materialId}
         overrideLabel={overrideLabel}
         postProcesses={postProcesses}
         postProcessOptionsFromDb={postProcessOptionsFromDb}
         setPostProcessingField={setPostProcessingField}
         user={user}
       />
    </div>
  )
}

function PostProcessAccordion(props){
  const [isExpanded, setIsExpanded] = useState(false)

  const materialFilteredPostProcessOptionsFromDb = filteredDbOptions(
    props.postProcessOptionsFromDb,
    props.materialId
  )
  const materialFilteredPostProcesses = getMaterialFilteredPostProcesses(
    props.postProcesses,
    materialFilteredPostProcessOptionsFromDb
  )

  const prefix = props.overrideLabel ? props.overrideLabel : 'Post Processes'
  const getPostProcessLabel = `${prefix}: ${postProcessesAsString(materialFilteredPostProcesses, materialFilteredPostProcessOptionsFromDb.POST_PROCESS_OPTIONS)}`

  if(props.disabled){
    return (
      <Typography>
        {getPostProcessLabel}
      </Typography>
    )
  }

  return (
    <AccordionSection label={getPostProcessLabel}>
      <div style={{width: '100%'}}>
        <PostProcessInputs
          deletePostProcess={props.deletePostProcess}
          postProcesses={materialFilteredPostProcesses}
          postProcessOptionsFromDb={materialFilteredPostProcessOptionsFromDb}
          setPostProcessingField={props.setPostProcessingField}
          user={props.user}
        />
        <AddNewPostProcessButton
          addPostProcessToPartGroup={props.addPostProcessToPartGroup}
          postProcessOptionsFromDb={materialFilteredPostProcessOptionsFromDb}
        />
        <hr/>
        <PostProcessDirectory
          addPostProcessToPartGroup={props.addPostProcessToPartGroup}
          postProcessOptionsFromDb={materialFilteredPostProcessOptionsFromDb}
        />
      </div>
    </AccordionSection>
  )
}

function filteredDbOptions(postProcessOptionsFromDb, materialId){
  const { CUSTOMER_DISPLAY_GROUPS, POST_PROCESS_OPTIONS } = postProcessOptionsFromDb

  const filteredOptions = POST_PROCESS_OPTIONS
        .filter(ppo => ppo.materialId === materialId)

  const filteredDisplayGroups = filterDisplayGroups(filteredOptions)

  return {
    CUSTOMER_DISPLAY_GROUPS: filteredDisplayGroups,
    POST_PROCESS_OPTIONS: filteredOptions
  }

  function filterDisplayGroups(filteredOptions){
    const isGroupNonEmpty = (groupKey) => filteredOptions
          .some(ppo => ppo.display.type === groupKey)

    const filteredDisplayGroups = Object.keys(CUSTOMER_DISPLAY_GROUPS)
          .filter(isGroupNonEmpty)
          .reduce((acc, key) => {
            acc[key] = CUSTOMER_DISPLAY_GROUPS[key]
            return acc
          }, {})

    return filteredDisplayGroups
  }
}

function getMaterialFilteredPostProcesses(postProcesses, postProcessOptionsFromDb){
  return postProcesses
    .filter(isPostProcessForTheMaterial)

  function isPostProcessForTheMaterial(ppc){
    return postProcessOptionsFromDb.POST_PROCESS_OPTIONS
      .find(ppo => ppo.id === ppc.postProcessId)
  }
}

function getDisplayValueFromId(postProcessId, POST_PROCESS_OPTIONS){
  return POST_PROCESS_OPTIONS
    .find(ppo => ppo.id === postProcessId)
    .display
    .name
}

function PostProcessInputs({deletePostProcess, postProcesses, postProcessOptionsFromDb, setPostProcessingField, user}){
  return (
    postProcesses.map(ppo =>
      <PostProcessInputRow
        deletePostProcess={deletePostProcess}
        key={`PostProcessInputRow-${ppo.id}`}
        postProcessObject={ppo}
        postProcessOptionsFromDb={postProcessOptionsFromDb}
        setSpecificPostProcessingField={(name, val) => setPostProcessingField(name, val, ppo.id)}
        user={user}
      />
    )
  )
}

function AddNewPostProcessButton({addPostProcessToPartGroup, overrideLabel, postProcessOptionsFromDb}){
  const [showEmptyOption, setShowEmptyOption] = useState(false)
  return (
    <>
      {showEmptyOption ?
       <div style={{ display: 'flex', gap: '0.5em', marginTop: "1.5em" }} >
         <InputField
           postProcessOption={{display:{name:"Select an Option"}}}
           POST_PROCESS_OPTIONS={postProcessOptionsFromDb.POST_PROCESS_OPTIONS}
           setSpecificPostProcessingField={(name, value) => {
             setShowEmptyOption(false)
             addPostProcessToPartGroup(value)
           }}
         />
         <Button
           onClick={() => setShowEmptyOption(!showEmptyOption)}
           style={{minWidth: '0px'}}
         >
           <DeleteForeverIcon />
         </Button>
       </div>
       : null }

      {showEmptyOption ? null :
       <div style={{ marginTop: "1em" }} >
         <Button
           onClick={() => setShowEmptyOption(!showEmptyOption)}
           size="small"
           startIcon={<AddCircleIcon size="small"/>}
           style={{
             justifyContent: "flex-start",
             textDecoration: "underline",
             textTransform: "none",
           }}
         >
           Add new {overrideLabel ? overrideLabel : 'Post Process'}
         </Button>
       </div>
      }
    </>
  )
}

function PostProcessDirectory(props){
  return (
    <div style={{display: 'flex', gap: '1em', flexWrap: 'wrap'}}>
      {Object.values(props.postProcessOptionsFromDb.CUSTOMER_DISPLAY_GROUPS).map(({display, id}) => {
        return (
          <DirectorySection
            addPostProcessToPartGroup={props.addPostProcessToPartGroup}
            display={display}
            key={id}
            group={id}
            POST_PROCESS_OPTIONS={props.postProcessOptionsFromDb.POST_PROCESS_OPTIONS}
          />
        )
      })}
    </div>
  )
}

function DirectorySection({ addPostProcessToPartGroup, display, group, POST_PROCESS_OPTIONS }){
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '.0em'}}>
      <div style={{fontWeight: 'bold', paddingBottom: '0.5em'}}>
        {display}
      </div>
      {POST_PROCESS_OPTIONS
       .filter(
         ppo => ppo.display.type === group
       )
       .sort(optionsByDisplayOrder)
       .map(
         ppo => OptionDiv(ppo, addPostProcessToPartGroup)
       )
      }
    </div>
  )
}

function optionsByDisplayOrder(optionA, optionB){
  return optionA.display.displayOrder - optionB.display.displayOrder
}

function OptionDiv(ppo, addPostProcessToPartGroup){
  return(
    <Tooltip key={`OptionDiv-${ppo.id}`} title={ppo.display.tooltipDescription}>
      <Button
        onClick={() => {addPostProcessToPartGroup(ppo.id)}}
        size="small"
        startIcon={<AddCircleIcon size="small"/>}
        style={{
          justifyContent: "flex-start",
          textDecoration: "underline",
          textTransform: "none",
        }}
      >
        {ppo.display.name}
      </Button>
    </Tooltip>
  )
}
