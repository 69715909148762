import React from 'react'
import {Dialog,DialogTitle,List,ListItem,ListItemText} from '@material-ui/core'

const DialogList = ({dialogMessageArray,dialogTitle,open,onClose}) => {
  return(
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <List>
          {dialogMessageArray.map(error => (
            <ListItem key={`dialog-option-${error}`}>
              <ListItemText primary={error} />
            </ListItem>
          ))}
        </List>
      </Dialog>
  )
}

export default DialogList
