import {v4 as uuid} from 'uuid'

const mockNestedPartGroupApiCalls = {
  create: createMockConfig,
  delete: deleteMockConfig,
  update: updateMockConfig,
}

export default mockNestedPartGroupApiCalls

async function createMockConfig(newConfig){
  console.log(`Would have created config:`)
  console.log(newConfig)

  return ({
    ...newConfig,
    id: uuid(),
  })
}

function deleteMockConfig(configId){
  console.log(`Deleting config: ${configId}`)

  return "Success"
}

function updateMockConfig(config){
  console.log("Updating config:", config)

  return config
}
