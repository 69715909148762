import config from "../config"
const axios = require('axios')
const { logUnexpected } = require('../utils/logUnexpected.js')

export default async function createFile(file) {
  let response
  try {
    response = await createFileRequest(file)
  } catch (err) {
    logUnexpected(err, { file }, 'createFileRequest')
    throw err
  }

  let result
  try {
    result = parseCreateFileResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseCreateFileResponse', { file })
    throw err
  }

  return result
}

async function createFileRequest(file) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.CREATE_FILE_ENDPOINT,
    data: {
      token: config.MICROSERVICE_TOKEN,
      file: file,
    },
  })

  return { status: response.status, data: response.data }
}

function parseCreateFileResponse(response) {
  if (response.status === 200) {
    if (response.data && response.data.file) {
      return response.data.file
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
