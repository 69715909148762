import React, {createContext, useState} from "react"
import { AppWideSnackbar } from '.'

// Used like this, by consuming sublayers:
// import {SnackBarContext} from '../Common/SnackBarStore'
// const openSnackBarWithMessage = useContext(SnackBarContext)

const useMessenger = () => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const dispatchMessage = (message) => {
    setSnackbarMessage(message)
    setIsSnackBarOpen(true)
  }
  const closeMessage = () => {
    setSnackbarMessage("")
    setIsSnackBarOpen(false)
  }
  return [ dispatchMessage, closeMessage, snackbarMessage, isSnackBarOpen ]
}

const SnackBarStore = ({ children }) => {
  const [ 
    dispatchMessage, 
    closeMessage,
    snackbarMessage, 
    isSnackBarOpen,
  ] = useMessenger()

  return (
    <SnackBarContext.Provider value={dispatchMessage}>
      {children}
      {/* the whole app goes there */}
      <AppWideSnackbar
        message={snackbarMessage}
        close={closeMessage}
        open={isSnackBarOpen}
      />
    </SnackBarContext.Provider>
  )
}

export const SnackBarContext = createContext()
export default SnackBarStore
