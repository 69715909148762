import React,{Component,Fragment} from 'react'
import {ConfirmCodePage,CreateAccount,ForgotPasswordPage,LoginPage} from '../Login'
import {NotFound,WrongBrowser} from '../Common'
import HomePage from '../HomePage'
import ProjectReviewPage from '../ProjectReviewPage'
import RemakeRequestPage from '../RemakeRequestPage'
import { AccountSettings, ChangePasswordPage } from '../AccountActionsPages'
import QuoteTool from '../QuoteTool'
import { ShipmentTrackingPage } from '../ShipmentTracking'
import {Checkout,ConfirmationPage} from '../Checkout'
import withAlerts from './withAlerts.js'
import {navigate,Redirect,Router} from "@reach/router"
import {
  projectCanBeOpenedByQuotePage,
}
from '../../utils'
import USER_TYPES from '../../utils/USER_TYPES.js'

const PAGES = {
  checkout: 'checkout',
  confirmation: 'confirmation',
  quote: 'quote',
  remakeRequest: 'remake-request',
  shipmentTracking: 'shipment-tracking',
  summary: 'summary',
}

class ViewController extends Component {
  constructor(props){
    super(props)
    this.state = {
      enableNavigationToCheckout: false, // If false navigating to x.com/checkout/{projectNumber} redirects you to quote tool
      selectedPricingData: {},
    }
  }

  navigateToProject = projectNumber => navigate(`/${PAGES.quote}/${projectNumber}`)

  navigateToConfirmationPage = () => navigate(`/${PAGES.confirmation}`)

  navigateToRemakeRequestPage = projectNumber => navigate(`/${PAGES.remakeRequest}/${projectNumber}`)

  navigateToShipmentTrackingPage = projectNumber => navigate(`/${PAGES.shipmentTracking}/${projectNumber}`)

  navigateToSummaryPage = projectNumber => navigate(`/${PAGES.summary}/${projectNumber}`)

  enableAndNavigateToCheckout = (projectNumber, pricingData, selectedLeadTime) => {
    this.setState({
      enableNavigationToCheckout: true,
      selectedPricingData: { pricingData, selectedLeadTime },
    },
      () => navigate(`/${PAGES.checkout}/${projectNumber}`)
    )
  }

  navigateToLandingPage = (pathname, project) => {
    if(projectCanBeOpenedByQuotePage(project.projectStatus, project.customerReady, this.props.user.type)){
      // correct page is Quote Tool
      this.navigateToPageIfNeeded(pathname, project.projectNumber, PAGES.quote, this.navigateToProject)
    } else {
      // correct page is Summary Page
      this.navigateToPageIfNeeded(pathname, project.projectNumber, PAGES.summary, this.navigateToSummaryPage)
    }
  }

  navigateToPageIfNeeded = (pathname, projectNumber, page, navigateToPage) => {
    if(pathname.includes(page)){
      // on the correct page, no navigation needed
    } else {
      navigateToPage(projectNumber)
    }
  }

  canNavigateToCheckout = project => {
    return this.canNavigateToQuotePage(project) && this.state.enableNavigationToCheckout
  }

  canNavigateToQuotePage = project => {
    return projectCanBeOpenedByQuotePage(project.projectStatus, project.customerReady, this.props.user.type)
  }

  canNavigateToSummaryPage = project => {
    return !projectCanBeOpenedByQuotePage(project.projectStatus, project.customerReady, this.props.user.type)
  }

  canNavigateToShipmentTrackingPage = project => {
    return this.canNavigateToSummaryPage(project) && this.props.user.type === USER_TYPES.DME
  }

  /*
    disableUserNavigationToCheckoutPage helps limit Checkout Page access only to projects that are Checkout ready,
    as determined by enableAndNavigateToCheckout being used by QT to enable access,
    and all pages removing access by default.
    It is passed as a prop to pages and invoked by their respective componentDidMount() lifecycle hooks.

    Once we move control of "book-ability" to the backend we can replace this subsystem.
    Then Checkout Page can determine if it should load or not based on asking the backend
  */
  disableUserNavigationToCheckoutPage = () => this.setState({ enableNavigationToCheckout: false, selectedPricingData: {} })

  render(){
    const userEmail = this.props.user ? this.props.user.email : null
    if(!this.props.supportedBrowser){
      return(
        <WrongBrowser/>
      )
    }

    return(
      <Fragment>
        <Router>
          {this.props.loggedIn ? <Redirect from="/login" to="/" noThrow/> : <Redirect from="/" to="/login" noThrow/>}
          {this.props.loggedIn ? <Redirect from="/login/createAccount" to="/" noThrow/> : null}
          {this.props.loggedIn ? <Redirect from="/login/forgotPassword" to="/" noThrow/> : null}
          {this.props.loggedIn ? <Redirect from="/login/confirmCode" to="/" noThrow/> : null}
          {this.props.loggedIn ? null :
           Object.values(PAGES).map(path => {
             return(
               <>
                 <Redirect from={`/${path}`} to="/login" noThrow/>
                 <Redirect from={`/${path}/*`} to="/login" noThrow/>
               </>
             )
           })
          }
          <Login path={"login"} /* shim layer so that the paths are prepended with /login/... */ >
            <LoginPage
              popupMessage={this.props.openSnackBarHandler}
              login={this.props.login}
              path={"/"}
              recoverPassword={this.passwordRecoveryHandler}
            />
            <CreateAccount
              path={"/createAccount"}
              popupMessage={this.props.openSnackBarHandler}
              signUp={this.props.signUp}
            />
            <ForgotPasswordPage
              forgotPassword={this.props.forgotPasswordSendCodeHandler}
              path={"/forgotPassword"}
              popupMessage={this.props.openSnackBarHandler}
            />
            <ConfirmCodePage
              forgotPassword={this.props.forgotPasswordConfirmCodeHandler}
              path={"/confirmCode"}
              popupMessage={this.props.openSnackBarHandler}
            />
            <NotFound default/>
          </Login>

          <HomePage
            changeUserType={this.props.changeUserType}
            navigateToProject={this.navigateToProject}
            path={"/"}
            user={this.props.user}
          />


          {this.state.enableNavigationToCheckout ? null :
            <Redirect
              from={`/${PAGES.checkout}/:projectNumber`}
              to={`/${PAGES.quote}/:projectNumber`}
              noThrow
            />
          }
          <Checkout
            canNavigateToPage={this.canNavigateToCheckout}
            closeSnackbar={this.props.closeSnackbar}
            changeUserType={this.props.changeUserType}
            selectedPricingData={this.state.selectedPricingData}
            MATERIAL_TRANSLATIONS={this.props.materialsData}
            navigateToConfirmationPage={this.navigateToConfirmationPage}
            navigateToLandingPage={this.navigateToLandingPage}
            navigateToSummaryPage={this.navigateToSummaryPage}
            openSnackBarHandler={this.props.openSnackBarHandler}
            path={`${PAGES.checkout}/:projectNumber`}
            postProcessData={this.props.postProcessData}
            snackbar={this.props.openSnackBarHandler}
            user={this.props.user}
          />

          <ProjectReviewPage
            canNavigateToPage={this.canNavigateToSummaryPage}
            changeUserType={this.props.changeUserType}
            closeSnackbar={this.props.closeSnackbar}
            MATERIAL_TRANSLATIONS={this.props.materialsData}
            navigateToLandingPage={this.navigateToLandingPage}
            navigateToProject={this.navigateToProject}
            navigateToRemakeRequestPage={this.navigateToRemakeRequestPage}
            navigateToShipmentTrackingPage={this.navigateToShipmentTrackingPage}
            navigateToSummaryPage={this.navigateToSummaryPage}
            openSnackBarHandler={this.props.openSnackBarHandler}
            path={`${PAGES.summary}/:projectNumber`}
            postProcessData={this.props.postProcessData}
            disableUserNavigationToCheckoutPage={this.disableUserNavigationToCheckoutPage}
            user={this.props.user}
          />

          <NewProject path={PAGES.quote}>
            <QuoteTool
              canNavigateToPage={this.canNavigateToQuotePage}
              changeUserType={this.props.changeUserType}
              closeSnackbar={this.props.closeSnackbar}
              enableAndNavigateToCheckout={this.enableAndNavigateToCheckout}
              isQuoteConfigurationPage
              MATERIAL_TRANSLATIONS={this.props.materialsData}
              navigateToConfirmationPage={this.navigateToConfirmationPage}
              navigateToLandingPage={this.navigateToLandingPage}
              navigateToProject={this.navigateToProject}
              navigateToSummaryPage={this.navigateToSummaryPage}
              openDialog={this.props.openDialog}
              openSnackBarHandler={this.props.openSnackBarHandler}
              path={"/:projectNumber"}
              postProcessData={this.props.postProcessData}
              disableUserNavigationToCheckoutPage={this.disableUserNavigationToCheckoutPage}
              TOOL_MATERIALS={this.props.TOOL_MATERIALS}
              user={this.props.user}
            />
            <NotFound default/>
          </NewProject>

          <ConfirmationPage
            path={PAGES.confirmation}
            disableUserNavigationToCheckoutPage={this.disableUserNavigationToCheckoutPage}
            user={this.props.user}
          />

          { this.props.user.type !== USER_TYPES.DME ? null :
            <RemakeRequestPage
              canNavigateToPage={this.canNavigateToSummaryPage}
              closeSnackbar={this.props.closeSnackbar}
              navigateToLandingPage={this.navigateToLandingPage}
              navigateToSummaryPage={this.navigateToSummaryPage}
              openSnackBarHandler={this.props.openSnackBarHandler}
              path={`${PAGES.remakeRequest}/:projectNumber`}
              postProcessData={this.props.postProcessData}
              user={this.props.user}
            />
          }

          <ShipmentTrackingPage
            canNavigateToPage={this.canNavigateToShipmentTrackingPage}
            closeSnackbar={this.props.closeSnackbar}
            MATERIAL_TRANSLATIONS={this.props.materialsData}
            openSnackBarHandler={this.props.openSnackBarHandler}
            navigateToLandingPage={this.navigateToLandingPage}
            navigateToSummaryPage={this.navigateToSummaryPage}
            path={`${PAGES.shipmentTracking}/:projectNumber`}
            postProcessData={this.props.postProcessData}
            user={this.props.user}
          />

          <AccountSettings
            logoutHandler={this.props.logoutHandler}
            path={"account"}
            user={this.props.user}
          />
          <ChangePasswordPage
            changePassword={this.props.changePasswordHandler}
            email={userEmail}
            path={"account/changePassword"}
            popupMessage={this.props.openSnackBarHandler}
          />
          <NotFound default/>
        </Router>
      </Fragment>
    )
  }
}

// Fragments can't have attributes other than "key", so we need shim layers:
const Login = props => <Fragment>{props.children}</Fragment>
const NewProject = props => <Fragment>{props.children}</Fragment>

export default withAlerts(ViewController)
