import React,{Fragment, useState} from 'react'
import {Button,Checkbox,CircularProgress,FormControlLabel,TextField,Typography} from '@material-ui/core'
import { Link } from "@reach/router"
import frg from '../../feature-release-guards.js'
import Logo from '../../Images/logoDark.png'
import { useToggledBoolean } from '../../utils'

const AutotivLogo = () => {
  return ( <img alt="logo" src={Logo} className="loginLogo"/> )
}

const LoadingIcon = () => {
    /* DXI:
      This is very wonky, and if you figure-out a better way to do this, please
      do, but in order to behave correctly & consistently, this size-value must
      be the same as we set the container to be. Without CSS-in-JS, this is
      impossible to have reference the same thing.
    */
  return <CircularProgress color="secondary" size={"20vh"}/>
}

function LoginPage({ 
  login,
  popupMessage,
}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAuthenticating, setIsAuthenticatingBool] = useState(false)
  const [revealPasswordText, toggleRevealPasswordText] = useToggledBoolean(false)

  const submitIfEnterWasClicked = (ev) => {
    if (ev.key === 'Enter'){
      loginHandler()
    }
  }

  /*
    loginHandler validates email and password and calls the login prop function
  */
  const loginHandler = async () => {
    if (email && password) {
      setIsAuthenticatingBool(true)
      await login(email, password) /* should navigate if successful */
      setIsAuthenticatingBool(false)
    }
    else{
      popupMessage('Email and Password are required to log in')
    }
  }

  // Pre-calculated convenience values
  const HEADER_PLACEHOLDER = (isAuthenticating) ? "AUTHENTICATING" : "LOG IN"
  const HIDE_OR_REVEAL_PASSWORD = (revealPasswordText) ? "text" : "password"

  return (
    <Fragment>
      <div align="center">
        <div className="loadingOrLogoContainer">
          { isAuthenticating ? <LoadingIcon/> : <AutotivLogo/> }
        </div>

        <div className="loginForm">
          <FRGDevModeIndication/>
          <Typography color="textPrimary" variant="h4">
            {HEADER_PLACEHOLDER}
          </Typography>
          { EmailField() /* thunk */} <br/> 
          { PasswordField() /* thunk */} <br/>
          <div className="passwordAdditionalActionsFooter">
            <ShowPasswordCheckbox/>
            <ForgotPasswordLink/>
          </div>
          <Button
            color="secondary"
            disabled={isAuthenticating}
            onClick={loginHandler}
            variant="contained" // DXI: adds outline & color, not just text
          >
            SUBMIT
          </Button><br/>
          <br/>
          Don't have an account? Make one <Link to={`createAccount?${(new URLSearchParams(window.location.search)).toString()}`}> here</Link>.
        </div>
      </div>
    </Fragment>
  )
  
  // HELPER THUNK:
  function EmailField() {
    return (
      <TextField
        autoFocus={true}
        className="loginCredentialsField"
        disabled={isAuthenticating}
        label="Email"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={submitIfEnterWasClicked}
        type="text"
        value={email}
      />
    )
  }
  function PasswordField() {
    return (
      <TextField
        className={"loginCredentialsField"}
        disabled={isAuthenticating}
        label="Password"
        name="password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={submitIfEnterWasClicked}
        type={HIDE_OR_REVEAL_PASSWORD}
        value={password}
      />
    )
  }

  function ShowPasswordCheckbox() {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={revealPasswordText}
              onChange={toggleRevealPasswordText}
              value="show password"
            />
          }
          label="Show Password"
        />
      </div>
    )
  }

  function ForgotPasswordLink() {
    return (
      <div>
        <Link to={`forgotPassword?${(new URLSearchParams(window.location.search)).toString()}`}>
          <Button
            disabled={isAuthenticating}
          >
            Forgot Password?
          </Button>
        </Link>
      </div>
    )
  }

  function FRGDevModeIndication() {
    if (frg.appLoginTitleFRGCheck) {
      return ( 
        <Typography color="error" variant="h4">
          (DEVELOPMENT FEATURES ON)
        </Typography>
      )
    }
    else {
      return null
    }
  }

}

export default LoginPage
