/*
  translateShipMethodToUserDeliveryDateString takes a ship method and matches it with an associated delivery date

  @data-rectification -- this is... less than ideal. Essentially, it's
  data-rectification's equivalent of a derived-field which is available through
  a table-view (a virtual table). We could do all sorts of things for this
  (essentially, we want a derivable field that is really just simple
  transformational logic + data), including how this is used which is in-flight
  derived data decorations, or OOP, or a more intelligent/responsible api, etc.
  If we dont want to go full-blown OOP, we at least need to handle all these
  functions in a single place (ie data-rectification layer) and thus single
  module so everything is nicely collocated. Collocation will allow us to
  handle the kind of intensely coupled logic of
  derived-field==dont-try-and-send-to-database. Even in implementing that, we
  still have the problem of polysemic nature of project, in which each
  Page/Component which uses project does not need every single field available,
  and scoping those fields to only where they are used would ensure we can make
  intentional/better scoping decisions.
*/
export function translateShipMethodToUserDeliveryDateString(shipMethod, areShippingOptionsLoading, shippingOptions, isCustomShippingMethodUsed) {
  let array = (shippingOptions && !areShippingOptionsLoading) ? shippingOptions : []
  let shipOptionSelected = array.find(option => {
    const method = isCustomShippingMethodUsed ? 'custom' : shipMethod
    return (option.shipMethod === method)
  })
  return (shipOptionSelected ? shipOptionSelected.arrivalDateString : '')
}

export function translateShipMethodToCustomerTranslation(shipMethod, areShippingOptionsLoading, shippingOptions, isCustomShippingMethodUsed) {
  let array = (shippingOptions && !areShippingOptionsLoading) ? shippingOptions : []
  let shipOptionSelected = array.find(option => {
    const method = isCustomShippingMethodUsed ? 'custom' : shipMethod
    return (option.shipMethod === method)
  })
  return (shipOptionSelected ? shipOptionSelected.customerTranslation : '')
}
