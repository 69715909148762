import downloadFileToLocalMachineFromBlob from './downloadFileToLocalMachineFromBlob.js'

export default async function downloadPdfToLocalMachineFromBase64(fileDataAsBase64String, fileName){
  // convert the base64 string into a Blob data type containing the pdf data using Data URLs. More info:
  // - https://stackoverflow.com/a/36183085
  // - https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
  const dataUrl = `data:application/pdf;base64,${fileDataAsBase64String}`
  const base64Response = await fetch(dataUrl)
  const blob = await base64Response.blob()

  downloadFileToLocalMachineFromBlob(blob, fileName)
}
