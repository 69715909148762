import React from "react"
import {Select,Typography,Modal} from '@material-ui/core'

const SurfaceFinishesModal = ({ open, onChange, onClose, partName, value }) => {
  return(
    <Modal
      open={open}
      onClose={onClose}
      style={{alignItems:'center',display:'flex',justifyContent:'center',maxHeight:'100vh',overflowY:'auto'}}
    >
      <div className="surface-finishes-modal">
        <Typography align="center" style={{marginBottom:'16px'}} variant="h4">
          {partName ? `${partName} Surface Finish` : `Surface Finishes`}
        </Typography>
        <Select
          style={{width:"40em"}}
          multiple={true}
          onChange={event => (event.target.value)}
          value={value}
        >
          {finishesList.map(finish => {
            return(
              <option
                disabled={finish.value === "DIVIDER"}
                key={finish.value}
                value={finish.value}
              >
                {finish.label}
              </option>
            )
          })}
        </Select>
      </div>
    </Modal>
  )
}

export default SurfaceFinishesModal

const finishesList = [
  {label:"As Machined - Tool marks visible advised for non-cosmetic surfaces only", value: "asMachined"},
  {label:"Matte Surface Finishes:",value:'DIVIDER'},
  {label:"SPI-C3 - Matte Finish - 320 Grit stone - 0.63-0.70um Ra", value: "spi-c3"},
  {label:"SPI-C2 - Medium Matte Finish - 400 Grit stone - 0.45-0.55um Ra", value: "spi-c2"},
  {label:"SPI-C1 - Fine Matte Finish - 600 Grit stone - 0.35-0.40um is Ra", value: "spi-c1"},
  {label:"Semi-Glossy Surface Finishes:", value: "DIVIDER"},
  {label:"SPI-B3 - Semi-Glossy Finish - 320 Grit paper 0.28-0.32um Ra", value: "spi-b3"},
  {label:"SPI-B2 - Medium Semi-Glossy Finish - 400 Grit paper 0.10-0.15um Ra", value: "spi-b2"},
  {label:"SPI-B1 - Fine Semi-Glossy Finish - 600 Grit paper 0.05-0.10um Ra", value: "spi-b1"},
  {label:"High Gloss Surface Finishes:", value: "DIVIDER"},
  {label:"SPI-A3 - Glossy Finish - 1200 Grit Diamond Buff - 0.05-0.10um Ra", value: "spi-a3"},
  {label:"SPI-A2 - High Glossy Finish 3,000 Grit Diamond Buff - .025-.05um Ra", value: "spi-a2"},
  {label:"SPI-A1 - Highest Gloss Finish - 6,000 Grit Diamond Buff - 0.012-0.025um Ra", value: "spi-a1"},
  {label:"Textured Surface Finishes:", value: "DIVIDER"},
  {label:"SPI-D3 - Rough Textured Finish - Dry Blast #24 Oxide - 3.2 - 18.0um Ra", value: "spi-d3"},
  {label:"SPI-D2 - Dull Textured Finish - Dry Blast #240 Oxide - 1.0 - 2.8um Ra", value: "spi-d2"},
  {label:"SPI-D1 - Satin Textured Finish - Dry Blast Glass Bead #11 - 0.8 - 1.0um Ra", value: "spi-d1"},
  {label:"MT-11010", value: "mt-11010"},
  {label:"MT-11020", value: "mt-11020"},
  {label:"MT-11030", value: "mt-11030"},
  {label:"MT-11040", value: "mt-11040"},
  {label:"MT-11050", value: "mt-11050"},
]
