import React, { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import {SnackBarContext} from '../Common/SnackBarStore'

export default function FinishCheckoutButton(props){
  const {
    disabled,
    label,
    navigateToConfirmationPage,
    onClickPromise,
  } = props

  const [handlingClick, setHandlingClick] = useState(false)
  const openSnackBarWithMessage = useContext(SnackBarContext)

  const onClickPromiseHandler = () => {
    setHandlingClick(true)

    return onClickPromise()
    .catch(
      (err) => { openSnackBarWithMessage(err.message) }
    )
    .then(() => setHandlingClick(false))
  }

  return (
    <Button
      color="secondary"
      disabled={handlingClick || disabled}
      onClick={()=>{setTimeout(()=>{onClickPromiseHandler()}, 175)}}
      size="medium"
      variant="contained"
    >
      {handlingClick ? 'Processing...' : label}
    </Button>
  )
}
