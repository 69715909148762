import supportedBrowser from './browserCheck'
// browserCheck must occur before polyfill or else ie will appear to be supported
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './global.css';
import App from './App';

ReactDOM.render(<App supportedBrowser={supportedBrowser} />, document.getElementById('root'));

