import React from "react"
import { Button } from "@material-ui/core"
import THUMBNAIL_BUTTON from "../gray-3d-view-button.svg"
import THUMBNAIL_PLACEHOLDER from "../3D-thumbnail-placeholder.svg"
import THUMBNAIL_RED_BUTTON from "../red-3d-view-button.svg"

export const ThumbnailOrPlaceHolderAtom = ({ hasModel, isAtRisk, onClick, size, thumbnailS3id }) => {
  let buttonStyle = {}
  if(isAtRisk){buttonStyle.border = "2px solid red"}
  return(
    <Button
      disabled={!hasModel}
      style={buttonStyle}
      onClick={onClick}
    >
      <img
        src={thumbnailS3id ? `https://autotiv-thumbnails.s3.amazonaws.com/${thumbnailS3id}` : THUMBNAIL_PLACEHOLDER }
        className={size === "small" ? "thumbnail-image-small" : "thumbnail-image"}
      />
      {hasModel ? <PartViewerPromptButtonOverlay isAtRisk={isAtRisk} /> : null}
    </Button>
  )
}

const PartViewerPromptButtonOverlay = ({ isAtRisk }) => (
  <img
    style={{
      position: "absolute", /* necessary for the overlay */
      width: "100%", /* otherwise it's huge */
    }}
    src={isAtRisk ? THUMBNAIL_RED_BUTTON : THUMBNAIL_BUTTON }
  />
)
