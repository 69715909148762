import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function deleteToleranceConfig(toleranceConfigId){
  let response
  try{
    response = await deleteToleranceConfigRequest(toleranceConfigId)
  } catch (err){
    logUnexpected(err, { toleranceConfigId }, 'deleteToleranceConfigRequest')
    throw err
  }

  let result
  try{
    result = parseDeleteToleranceConfigResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseDeleteToleranceConfigResponse', { toleranceConfigId })
    throw err
  }

  return result
}

function deleteToleranceConfigRequest(toleranceConfigId){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.DELETE_TOLERANCE_ENDPOINT,
    data: {
      toleranceConfigId,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseDeleteToleranceConfigResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.statusMessage === 'OK'){
      return response.data.statusMessage
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
