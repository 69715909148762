const axios = require('axios')
const config = require('../../config.js')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function saveProjectAndNestedObjects(project){
  let response
  try{
    response = await saveProjectAndNestedObjectsRequest(prectify(project))
  } catch (err){
    logUnexpected(err, { project }, 'saveProjectAndNestedObjectsRequest')
    throw err
  }

  let result
  try{
    result = parseSaveProjectAndNestedObjectsResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseSaveProjectAndNestedObjectsResponse', { project })
    throw err
  }

  return result
}

function prectify(project){
  delete project.updateId
  return project
}

function saveProjectAndNestedObjectsRequest(project){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.SAVE_FULL_PROJECT_ENDPOINT,
    data: {
      token: config.MICROSERVICE_TOKEN,
      project,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseSaveProjectAndNestedObjectsResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.statusCode === "200"){
      if(response.data.body && response.data.body.project){
        return response.data.body.project
      }
    }
    const responseDataFormatError = new Error('Malformed body format')
    responseDataFormatError.responseData = response.data
    throw responseDataFormatError
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
