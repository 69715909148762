import React,{Component} from 'react'
import {Button,FormControl,FormControlLabel,Modal,Paper,Radio,RadioGroup,Typography} from '@material-ui/core'

class UnitSelectionModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      defaultUnit: ''
    }
  }

  onChangeUnitSelector = event => {
    let {value} = event.target
    this.setState({defaultUnit:value})
  }

  submitHandler = () => {
    this.props.submit(this.state.defaultUnit.slice())
    this.props.close()
  }

  render(){
    return(
      <Modal
        open={this.props.open}
        onClose={this.props.close}
      >
        <Paper className="unit-selection-modal-paper" elevation={4}>
          <Typography variant="h5">
            What units were these parts exported in?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              name="unit"
              onChange={this.onChangeUnitSelector}
              value={this.state.defaultUnit}
            >
              <FormControlLabel value="mm" control={<Radio />} label="mm" />
              <FormControlLabel value="inches" control={<Radio />} label="inches" />
              <FormControlLabel value="notSure" control={<Radio />} label="I don't know yet, let me see the dimensions" />
            </RadioGroup>
          </FormControl><br/>

          <div align="right">
            <Button
              color="secondary"
              onClick={this.submitHandler}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
    )
  }
}

export default UnitSelectionModal
