const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function getShippingOptionsArray(project, shipDate){
  let response
  try{
    response = await getShippingOptionsArrayRequest(project, shipDate)
  } catch (err){
    logUnexpected(err, { project, shipDate }, 'getShippingOptionsArrayRequest')
    throw err
  }

  let result
  try{
    result = parseGetShippingOptionsArrayResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseGetShippingOptionsArrayResponse', { project, shipDate })
    throw err
  }

  return result
}

function getShippingOptionsArrayRequest(project, shipDate){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/project/getShippingOptions',
    data: {
      project,
      shipDate,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseGetShippingOptionsArrayResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
