import React from 'react'
import AtRiskPromptAtom from '../AtRiskPromptAtom.js'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import {Typography} from '@material-ui/core'
import {formatFileDimensions} from '../../../utils/formatFileDimensions.js'
import getManufacturabilityIssues from '../../../shared/getManufacturabilityIssues.js'

export default function PartDetailsMaybe({ onChange, partGroup, postProcessData, riskAccepted, rowType }){
  return(
    <div style={{display:'flex',flexDirection:'column',gap:'1em',padding:'1em'}}>
      <FileName fileName={partGroup.part.fileName}/>
      <FileDimensions partGroup={partGroup}/>
      <ManufacturabilityIssuesMaybe
        onChange={onChange}
        partGroup={partGroup}
        postProcessData={postProcessData}
        riskAccepted={riskAccepted}
        rowType={rowType}
      />
    </div>
  )
}

const FileName = ({fileName}) => <Typography variant="h5"> { fileName } </Typography>

const FileDimensions = ({partGroup}) => <div>{ formatFileDimensions(partGroup.part) } { partGroup.part.units }</div>

const ROW_TYPES = {
  uploadingPart: "uploading_part",
  noUnits: "no_units",
  atRisk: "at_risk",
  analysisComplete: "analysis_complete",
  wallThickness: "waiting_for_wall_thickness_analysis",
  paramExtract: "parameter_extract_in_progress",
}

const ManufacturabilityIssuesMaybe = ({onChange, partGroup, postProcessData, riskAccepted, rowType}) => {
  const manufacturabilityIssues = getManufacturabilityIssues(partGroup, postProcessData.POST_PROCESS_OPTIONS)
  return manufacturabilityIssues.length ?
    <>
      { riskAccepted ?
        <div style={{display:'flex'}}>
          <Typography>
            Risk Accepted
          </Typography>
          <CheckCircleOutline className="informative" style={{padding:'0px 10px'}}/>
        </div>
        : null
      }
      { rowType === ROW_TYPES.atRisk &&
        <AtRiskPromptAtom
          isCondensed
          manufacturabilityIssues={manufacturabilityIssues}
          onChange={onChange}
          part={partGroup}
        />
      }
    <ManufacturabilityIssues manufacturabilityIssues={manufacturabilityIssues} />
    </>
    : null
}

const ManufacturabilityIssues = ({manufacturabilityIssues}) => {
  const hasMultibodyIssue = manufacturabilityIssues
        .some(issue => issue.subIssues && issue.subIssues.map(subIssue => subIssue.includes('shells')))
  return (
    <div style={{color:'red'}}>
      Manufacturability Issues
      <ul>
        { manufacturabilityIssues.map(issue => {
          return (
            <li key={`mfg-issue-${issue.text}`}>{issue.text}
              { issue.subIssues ?
                <ul>
                  {issue.subIssues.map(subIssue => <li key={`mfg-subIssue-${subIssue}`}>{subIssue}{subIssue.includes('shells') ? '*' : ''}</li>)}
                </ul>
                : null
              }
            </li>
          )
        })}
      </ul>
      {hasMultibodyIssue ?
       <>
         <br/>
         *Tree'd parts or files with multiple bodies must be split up and submitted as separate parts or else you may face additional production costs and/or delays
       </>
       :
       null}
    </div>
  )
}

