const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function getPostProcessOptions(){
  let response
  try{
    response = await getPostProcessOptionsRequest()
  } catch (err){
    logUnexpected(err, {}, 'getPostProcessOptionsRequest')
    throw err
  }

  let result
  try{
    result = parseGetPostProcessOptionsResponse(response)
  } catch (err){
    logUnexpected(err, {response}, 'parseGetPostProcessOptionsResponse(response)')
    throw err
  }

  return result
}

function getPostProcessOptionsRequest(){
  return axios({
    method: 'get',
    validateStatus: () => true,
    url: '/pricingData/postProcessOptions',
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseGetPostProcessOptionsResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data.success
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
