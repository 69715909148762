import React from 'react'
import {calculateShipDateFromOrderDateAndLeadTimeDays} from '../ShipmentTracking/calculateDateFromBusinessDayOffset.js'
import PartGroupsSummaryTableBody from '../Common/PartGroupsSummaryTableBody.js'
import { translateShipMethodToUserDeliveryDateString } from '../../utils'
import { Divider, Typography } from '@material-ui/core'

export const ReviewExpansionDetails = (props) => {
  let sampleShipDate

  if(props.project.isLeadTimeLocked){
    const dateFormat = "MM/DD/YYYY"
    sampleShipDate = calculateShipDateFromOrderDateAndLeadTimeDays(props.project.orderDate, dateFormat, props.project.lockedSampleLeadTimeDays).format(dateFormat)
  } else {
    const leadTimePricePair = props.selectedPricingData.pricingData.leadTimePricePairs
          .find(ltpp => ltpp.price === props.selectedPricingData.selectedLeadTime)
    sampleShipDate = leadTimePricePair.sampleShipDate
  }

  return(
    <div style={{width:'100%'}}>
      <div style={{display:'flex',justifyContent:'space-between',marginLeft:'40px',marginRight:'40px'}}>
        <div>
          <Typography variant="subtitle1">
            <span style={{fontWeight:'bold'}}>
            Ship to:
            </span>
          </Typography>
          {props.project.shipName}<br/>
          {props.project.shipCompany}<br/>
          {props.project.shipAddress.street}<br/>
          {props.project.shipAddress.street2}<br/>
          {props.project.shipAddress.city}, {props.project.shipAddress.state} {props.project.shipAddress.zip}<br/>
          {props.project.shipAddress.country}<br/>
          {props.project.shipAddress.shipPhone}<br/>
        </div>

        <div style={{marginRight:'16px'}}>
          { sampleShipDate ?
            <>
            <Typography variant="subtitle1">
              <span style={{fontWeight:'bold'}}>
                Samples Ship Date
              </span>
            </Typography>
            {sampleShipDate}
            </>
            : null
          }
          <Typography variant="subtitle1">
            <span style={{fontWeight:'bold'}}>
              { sampleShipDate ?
                "Guaranteed Samples Delivery Date"
                :
                "Guaranteed Delivery Date"
              }
            </span>
          </Typography>
          {translateShipMethodToUserDeliveryDateString(props.project.paidShipMethod, props.areShippingOptionsLoading, props.shippingOptions, props.project.isCustomShippingMethodUsed)}
        </div>
      </div>
      <Typography style={{marginLeft:'24px'}} variant="subtitle1">
        <span style={{fontWeight:'bold'}}>
        Parts:
        </span>
      </Typography>
      <Divider/>
        <PartGroupsSummaryTableBody
          MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
          partGroups={decoratePartGroupsWithPrices(props.project,  props.selectedPricingData.pricingData)}
          postProcessData={props.postProcessData}
          toleranceClassifications={props.selectedPricingData.pricingData.toleranceClassifications}
        />
    </div>
  )
}

function decoratePartGroupsWithPrices(project, pricingData){
  return project.partGroups.map(ptg => {
    const partGroupPrice = pricingData.partGroupPrices
          .find(pgp => pgp.partGroupNumber === ptg.partGroupNumber)

    ptg.unitPrice = project.isLeadTimeLocked ? ptg.lockedUnitPrice : partGroupPrice.unitPrice

    ptg.productionToolPartConfigurations = ptg.productionToolPartConfigurations
      .filter(ptpc => ptpc.processId === ptg.processId)
      .map(ptpc => {
        const ptpcPrice = pricingData.ptpcPrices
              .find(ptpcPrice => ptpcPrice.productionToolPartConfigurationId === ptpc.productionToolPartConfigurationId)

        ptpc.unitPrice = project.isLeadTimeLocked ? ptpc.lockedUnitPrice : ptpcPrice.unitPrice
        return ptpc
      })

    return ptg
  })
}
