const axios = require('axios')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function submitRemakeRequest(project){
  let response
  try{
    response = await submitRemakeRequestRequest(project)
  } catch (err){
    logUnexpected(err, { project }, 'submitRemakeRequestRequest')
    throw err
  }

  let result
  try{
    result = parseSubmitRemakeRequestResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseSubmitRemakeRequestResponse', { project })
    throw err
  }

  return result
}

function submitRemakeRequestRequest(project){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/emails/remake-request',
    data: {
      project
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseSubmitRemakeRequestResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
