import React, {Fragment} from "react"
import {Checkbox} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { CustomTooltip } from "."
import { STATUS_BLUE } from "./STATUS_BLUE.js"
import USER_TYPES from '../../../utils/USER_TYPES.js'

export const StatusFeedbackAtom = ({ manufacturabilityIssues, onChange, partGroup, riskAccepted, rowType, showWallThicknessCheckbox, user }) => {
  let {partAnalysisFeedbackLabel,partAnalysisFeedbackColor} = generateFeedbackLabelAndColorTuple(rowType)
  const partAnalysisFeedbackStyles = {color:partAnalysisFeedbackColor, fontStyle: "italic" }
  const hasWallThicknessError = partGroup.part.units === 'mm' ? partGroup.part.wallThicknessErrorMM : partGroup.part.wallThicknessErrorInches
  return(
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
      <div>
        <span style={partAnalysisFeedbackStyles}>
          {partAnalysisFeedbackLabel}
        </span>
        <CheckmarkMaybe manufacturabilityIssues={manufacturabilityIssues} riskAccepted={riskAccepted}/>
      </div>
      { user.type === USER_TYPES.DME && hasWallThicknessError && showWallThicknessCheckbox ?
        <SkipAutomatedWallthicknessButton
          onChange={onChange}
          partGroup={partGroup}
        />
        : null }
    </div>
  )
}

const CheckmarkMaybe = ({ manufacturabilityIssues, riskAccepted }) => {
  const tooltipJsx = (
    <>
      <b>Risks accepted:</b><br/>
      <ul style={{paddingLeft: '1.5em', marginTop: '0em'}}>
        { manufacturabilityIssues.map(issue => {
          return (
            <li key={`mfg-issue-${issue.text}`}>{issue.text}
              { issue.subIssues ?
                <ul>
                  {issue.subIssues.map(subIssue => <li key={`mfg-subIssue-${subIssue}`}>{subIssue}</li>)}
                </ul>
                : null
              }
            </li>
          )
        })}
      </ul>
    </>
  )
  return(riskAccepted && manufacturabilityIssues.length ?
    <CustomTooltip placement="right" title={tooltipJsx}>
      <CheckCircleOutline className="informative" style={{padding:'0px 10px'}}/>
    </CustomTooltip>
    : null
  )
}

function generateFeedbackLabelAndColorTuple(partRowType) {
  let partAnalysisFeedbackLabel, partAnalysisFeedbackColor
  switch(partRowType){
    case "at_risk":
      partAnalysisFeedbackLabel = 'Manufacturability issues - Please accept risk to proceed'
      partAnalysisFeedbackColor = 'red'
      break
    case "analysis_complete":
      partAnalysisFeedbackLabel = 'Part analysis complete!'
      partAnalysisFeedbackColor = 'green'
      break
    case "no_units":
      partAnalysisFeedbackLabel = 'Units required - Please select units to proceed'
      partAnalysisFeedbackColor = 'red'
      break
    case "parameter_extract_in_progress":
      partAnalysisFeedbackLabel = 'Waiting for parameter extraction...'
      partAnalysisFeedbackColor = STATUS_BLUE
      break
    case "uploading_part":
      partAnalysisFeedbackLabel = 'Uploading part...'
      partAnalysisFeedbackColor = STATUS_BLUE
      break
    case "waiting_for_wall_thickness_analysis":
      partAnalysisFeedbackLabel = 'Waiting for wall thickness analysis... - est. 5 minutes'
      partAnalysisFeedbackColor = STATUS_BLUE
      break
  }
  return ({ partAnalysisFeedbackLabel, partAnalysisFeedbackColor })
}

function SkipAutomatedWallthicknessButton({ onChange, partGroup }){
  const isDmeReviewed = partGroup.part.units === 'mm' ? partGroup.isWallThicknessMMDmeReviewed : partGroup.isWallThicknessInchesDmeReviewed
  const name = partGroup.part.units === 'mm' ? 'isWallThicknessMMDmeReviewed' : 'isWallThicknessInchesDmeReviewed'

  const onChangeCheckbox = e => {
    onChange({
      target: {
        name,
        value: e.target.checked,
      }
    })
  }

  return(
    partGroup.part.units ?
      <div style={{display: 'flex', alignItems: 'center'}}>
        {isDmeReviewed ?
         <Checkbox
           checked={Boolean(isDmeReviewed)}
           color="primary"
           onChange={onChangeCheckbox}
         />
         :
         <Checkbox
           checked={Boolean(isDmeReviewed)}
           onChange={onChangeCheckbox}
           style={{color: 'red'}}
         />
        }
        <div style={{width: '13em', color: isDmeReviewed ? '' : 'red'}}>
          Wall Thickness Checked
        </div>
      </div>
    : null
  )
}
