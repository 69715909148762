import calculateProjectQuoteData from './calculateProjectQuoteData.js'
import {v4 as uuid} from 'uuid'
const deepCopy = (array) => JSON.parse(JSON.stringify(array))
const CNC_TYPES = {
  mill: 'MILL',
  turn: 'TURN',
}

const MOCK_THE_RACE_CONDITION_PREVENTING_SUBSYSTEM_THAT_USES_THE_TIMESTAMP_IN_A_CLOSURE = true // Evan will probably not cause overwrites fast enough to be bothered by it

const DEBOUNCE_TIME_MS = 0 // 0 since this is now a process after tolerance classifications, which is debounced itself
let lastCall

export default async function decorateProjectWithPrices(project){
  const calculationReadyProject = prectifyForBackend(deepCopy(project)) // has to get moved down

  let decoratedProject

  const makeApiCall = async () => {

    const quoteData = await calculateProjectQuoteData(calculationReadyProject)
    return quoteData

  }

  return new Promise (function (resolve, reject) {
    const myCall = uuid()
    lastCall = myCall
    setTimeout(() => {
      if(myCall === lastCall){
        resolve(makeApiCall())
      }
    }, DEBOUNCE_TIME_MS)
  })

}

// There is no rectify b/c we dont get a project back from this call; it is not
// symmetrical.
function prectifyForBackend(projectInFrontend){
  /* Currently the backend just has some wrong field names. Should get fixed at
   * some point. */
  projectInFrontend.partGroups = projectInFrontend.partGroups.map(ptg => {
    /* Looks like the pricing-engine got the wrong name for x/y/z dimensions
     * for what it EXPECTS, I'll correct it on ingest here until fixed in the
     * backend where it is originally generated.

    PEngine-expect-as = currently-in-database-as
    */
    ptg.material = ptg.materialId; delete ptg.materialId;
    if(ptg.productionToolPartConfigurations !== undefined && ptg.productionToolPartConfigurations.length > 0){
      ptg.productionToolPartConfigurations.map((ptpc) => {
	ptpc.material = ptpc.materialId
	delete ptpc.materialId
	return ptpc
      })
    }

    // Markup pricing mapping
    ptg.isMarkupCosted = ptg.pricingMethod === "markup"

    // Markup lead time rectification
    projectInFrontend.markupLeadTimeQuantity1 = parseNumber(projectInFrontend.markupLeadTimeQuantity1)
    projectInFrontend.markupLeadTimeQuantityX = parseNumber(projectInFrontend.markupLeadTimeQuantityX)

    ptg.tolerances.forEach((tol) => {
      tol.nominal = parseNumber(tol.nominal)
      tol.quantity = parseNumber(tol.quantity)
      tol.lowerBound = parseNumber(tol.lowerBound)
      tol.upperBound = parseNumber(tol.upperBound)
    })

    ptg.overmoldedInserts.forEach((insert) => {
      insert.qtyInsertsPerPart = parseNumber(insert.qtyInsertsPerPart)
      insert.qtyInsertsPerPack = parseNumber(insert.qtyInsertsPerPack)
      insert.costPerPack = parseNumber(insert.costPerPack)
      insert.volume = parseNumber(insert.volume)
      insert.surfaceArea = parseNumber(insert.surfaceArea)
      insert.xDimension = parseNumber(insert.xDimension)
      insert.yDimension = parseNumber(insert.yDimension)
      insert.zDimension = parseNumber(insert.zDimension)
    })

    // Post processes rectification
    ptg.postProcesses.forEach((pp) => {
      pp.percentageOfPart = parseNumber(pp.percentageOfPart)
      pp.averageLengthOfSpecialOp = parseNumber(pp.averageLengthOfSpecialOp)
      pp.hardwareCostPerPack = parseNumber(pp.hardwareCostPerPack)
      pp.hardwarePackQty = parseNumber(pp.hardwarePackQty)
      pp.dmeConfig = parseNumber(pp.dmeConfig)
      pp.dmeConfiguration = pp.dmeConfig
      pp.dmeConfig = undefined
      pp.customerConfig = parseNumber(pp.customerConfig)
      pp.customerConfiguration = pp.customerConfig
      pp.customerConfig = undefined
    })

    ptg.productionToolPartConfigurations.forEach((ptpc) => {
      ptpc.postProcesses.forEach((pp) => {
        pp.percentageOfPart = parseNumber(pp.percentageOfPart)
        pp.averageLengthOfSpecialOp = parseNumber(pp.averageLengthOfSpecialOp)
        pp.hardwareCostPerPack = parseNumber(pp.hardwareCostPerPack)
        pp.hardwarePackQty = parseNumber(pp.hardwarePackQty)
        pp.dmeConfig = parseNumber(pp.dmeConfig)
        pp.dmeConfiguration = pp.dmeConfig
        pp.dmeConfig = undefined
      })
    })

    const part = ptg.part
    // Renames and deletes old properties
    part.xDimension = part.xDim; delete part.xDim;
    part.yDimension = part.yDim; delete part.yDim;
    part.zDimension = part.zDim; delete part.zDim;
    part.surfaceArea = part.partSurfaceArea; delete part.partSurfaceArea;
    part.volume = part.partVolume; delete part.partVolume;

    part.numberOfSetups = part.cncSetupsPerPart; delete part.cncSetupsPerPart;
    part.cncRoughingTools
      .map(crt => {
        crt.diameter = parseNumber(crt.diameter)
        crt.volumeRatio = crt.volumePercentage / 100
        delete crt.volumePercentage
        return crt
      })

    part.cncFinishingTools
      .map(cft => {
        cft.diameter = parseNumber(cft.diameter)
        cft.surfaceAreaRatio = cft.surfaceAreaPercentage / 100
        delete cft.surfaceAreaPercentage
        return cft
      })

    part.imRoughingTools
      .map(crt => {
        crt.diameter = parseNumber(crt.diameter)
        crt.volumeRatio = crt.volumePercentage / 100
        delete crt.volumePercentage
        return crt
      })

    part.imFinishingTools
      .map(cft => {
        cft.diameter = parseNumber(cft.diameter)
        cft.surfaceAreaRatio = cft.surfaceAreaPercentage / 100
        delete cft.surfaceAreaPercentage
        return cft
      })


    // Add same logic for IM tools

    part.cncStockXOrInnerDiameter = ptg.part.cncMillOrTurn === CNC_TYPES.mill ? part.cncStockX : part.cncStockInnerDiameter
    part.cncStockYOrOuterDiameter = ptg.part.cncMillOrTurn === CNC_TYPES.mill ? part.cncStockY : part.cncStockOuterDiameter
    delete part.cncStockX
    delete part.cncStockY
    delete part.cncStockInnerDiameter
    delete part.cncStockOuterDiameter

    return ptg
  })

  projectInFrontend = {
    ...projectInFrontend,

    partGroups: projectInFrontend.partGroups.map(ptg => {
      return ({
        ...ptg,
        partGroupNumber: parseNumber(ptg.partGroupNumber),
        quantity: parseNumber(ptg.quantity),
        cncNumberOfAxes: parseNumber(ptg.cncNumberOfAxes),
	markupQuantityX: parseNumber(ptg.markupQuantityX),
	markupLeadTimeQuantityX: parseNumber(ptg.markupLeadTimeQuantityX),
	markupCostQuantityX: parseNumber(ptg.markupCostQuantityX),
	markupLeadTimeQuantity1: parseNumber(ptg.markupLeadTimeQuantity1),
	markupCostQuantity1:  parseNumber(ptg.markupCostQuantity1),
	infillRate: parseNumber(ptg.infillPercentage / 100),

        part: {
          ...ptg.part,
          xDimension: parseNumber(ptg.part.xDimension),
          yDimension: parseNumber(ptg.part.yDimension),
          zDimension: parseNumber(ptg.part.zDimension),
          surfaceArea: parseNumber(ptg.part.surfaceArea),
          volume: parseNumber(ptg.part.volume),

          numberOfSetups: parseNumber(ptg.part.numberOfSetups),
          cncStockXOrInnerDiameter: parseNumber(ptg.part.cncStockXOrInnerDiameter),
          cncStockYOrOuterDiameter: parseNumber(ptg.part.cncStockYOrOuterDiameter),
          cncStockZ: parseNumber(ptg.part.cncStockZ),

          sheetMetalStockX: parseNumber(ptg.part.sheetMetalStockX),
          sheetMetalStockY: parseNumber(ptg.part.sheetMetalStockY),
          sheetMetalCutPathLength: parseNumber(ptg.part.sheetMetalCutPathLength),
          sheetMetalNumberOfBends: parseNumber(ptg.part.sheetMetalNumberOfBends),
          sheetMetalNumberOfWelds: parseNumber(ptg.part.sheetMetalNumberOfWelds),
          sheetMetalStockThickness: parseNumber(ptg.part.sheetMetalStockThickness),

	  numberOfWelds: parseNumber(ptg.part.numberOfWelds),
          numberOfBends: parseNumber(ptg.part.numberOfBends),

	  standardDeviationWallThickness: parseNumber(ptg.part.standardDeviationWallThickness),
	  averageWallThickness: parseNumber(ptg.part.averageWallThickness),
	  numberOfCollapsingCores: parseNumber(ptg.part.numberOfCollapsingCores),
	  numberOfSlides: parseNumber(ptg.part.numberOfSlides),
        }
      })
    })
  }

  return projectInFrontend
}

function parseNumber(val){
  return looksLikeNumber(val) ? Number(val) : val

  // THUNK
  function looksLikeNumber(value){
    const isNumber = /^\d*\.?\d*$/.test(value)
    const endsInDot = /\.$/.test(value)
    return isNumber && !endsInDot
  }
}
