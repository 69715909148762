import React,{ Fragment, useState } from 'react'
import { IconButton,Typography } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import AddCircle from '@material-ui/icons/AddCircle'
import AttachmentsModal from './AttachmentsModal'
import { ActionLink } from '../Common'

const AttachmentsList = ({
  attachments,
  currentlyLoading,
  isNotDmeReviewed,
  projectNumber,
  removeAttachment,
  uploadNewAttachment,
}) => {

  const [modalListOpen, setModalListOpen] = useState(false)

  const handleModalAttachmentUpload = (file, failedDropZoneFiles) => {
    // this upload comes from the modal dropzone, so we will pass in true for dropZoneUploadBool
    const dropZoneUploadBool = true
    uploadNewAttachment(file, undefined, dropZoneUploadBool, failedDropZoneFiles)
  }

  // Format a list of attachments, one attachment per line
  let ATTACHMENT_LIST = attachments.map((attachment,index)=>{
    if(index === 0){
      return(
        <ActionLink action={()=>{setModalListOpen(true)}} key={"AttachmentLi-" + index} label={attachment.fileName} theme="secondary"/>
      )
    }
    else if(index < 2){
      return(
        <Fragment key={"AttachmentLi-" + index}>
          <br/>
          <ActionLink action={()=>{setModalListOpen(true)}} label={attachment.fileName} theme="secondary"/>
        </Fragment>
      )
    }
    else if (index === 2){ // If > 3 attachments, truncate remaining elements to "...and "x" more"
      let remainingAttachments = attachments.length - 2
      let linkLabel = `...and ${remainingAttachments} more`
      return(
        <Fragment key={"AttachmentLi-" + index}>
          <br/>
          <ActionLink action={()=>{setModalListOpen(true)}} label={linkLabel} theme="secondary"/>
        </Fragment>
      )
    }
    else{
      return null
    }
  })

  return(
    <div align="left">
      <Typography style={{ color: isNotDmeReviewed ? 'red' : '' }} variant="subtitle1">
        Attachments
      </Typography>

      <div style={{ display:"flex" }}>
          <IconButton color="secondary" style={{ color: isNotDmeReviewed ? 'red' : '' }} component="span" onClick={()=>{setModalListOpen(true)}}>
            { attachments.length ? <Edit/> : <AddCircle/> }
          </IconButton>
        <div style={{ fontSize: "0.9em" }}align="left">
          {ATTACHMENT_LIST}
        </div>
      </div>

      <AttachmentsModal
        attachments={attachments}
        currentlyLoading={currentlyLoading}
        open={modalListOpen}
        onClose={()=>{setModalListOpen(false)}}
        projectNumber={projectNumber}
        removeAttachment={removeAttachment}
        uploadParts={handleModalAttachmentUpload}
      />
    </div>
  )
}

export default AttachmentsList
