export function needsManufacturabilityIssuesApproved(status){
  const {
    CAN_BE_ANALYZED,
    PARAM_EXTRACT_FINISHED,
    WALL_THICKNESS_TASK_FINISHED,
    HAD_ISSUES_ANALYZING_PARAMS,
    RISK_ACCEPTED,
    HAS_ISSUES
  } = status
  return CAN_BE_ANALYZED &&
    PARAM_EXTRACT_FINISHED &&
    WALL_THICKNESS_TASK_FINISHED &&
    HAS_ISSUES &&
    !HAD_ISSUES_ANALYZING_PARAMS &&
    !RISK_ACCEPTED
}
