import configureNestedPartGroupFieldsCrud from './configureNestedPartGroupFieldsCrud.js'

import * as s3 from '../../Components/QuoteTool/cloudObjectStorage'

import createPostProcessConfig from './crudConfig/createPostProcessConfig.js'
import deletePostProcessConfig from './crudConfig/deletePostProcessConfig.js'
import updatePostProcessConfig from './crudConfig/updatePostProcessConfig.js'
import createOvermoldedInsertConfig from './crudConfig/createOvermoldedInsertConfig.js'
import deleteOvermoldedInsertConfig from './crudConfig/deleteOvermoldedInsertConfig.js'
import updateOvermoldedInsertConfig from './crudConfig/updateOvermoldedInsertConfig.js'
import createToleranceConfig from './crudConfig/createToleranceConfig.js'
import deleteToleranceConfig from './crudConfig/deleteToleranceConfig.js'
import updateToleranceConfig from './crudConfig/updateToleranceConfig.js'
import createFile from '../../utils/createFile.js'
import deleteFile from '../../utils/deleteFile.js'

/*
  **** How to configure a nested partGroup object: ****

  1. Add an empty object to the array 'nestedObjectsConfig'
  2. Add the field 'fieldName' with the nested object array name
  3. Add the field 'getTemplate' which is function that converts
       data from the front end into the full data object. The
       function arguments depends on what data, if any, is needed
       to create a new object.

  ** At this point, you can use the crud system with this object.
    However, the object won't actually save to a database until
    you add the api calls.

  4. Add the field 'apiCalls' which is an object of api calls
       The standard signature for each function is as follows:
       - create: (newObject, partGroupNumber) => {}
       - update: (updatedObject) => {}
       - delete: (objectId) => {}
*/

const nestedObjectsConfig = [
  {
    fieldName: 'postProcesses',
    getTemplate: getPostProcessConfigTemplate,
    primaryKey: 'id',
    apiCalls: {
      create: createPostProcessConfig,
      update: updatePostProcessConfig,
      delete: deletePostProcessConfig,
    }
  },{
    fieldName: 'tolerances',
    getTemplate: getTolerancesConfigTemplate,
    primaryKey: 'id',
    apiCalls: {
      create: createToleranceConfig,
      update: updateToleranceConfig,
      delete: deleteToleranceConfig,
    }
  },{
    fieldName: 'overmoldedInserts',
    getTemplate: getOvermoldedInsertsConfigTemplate,
    primaryKey: 'id',
    apiCalls: {
      create: createOvermoldedInsertConfig,
      update: updateOvermoldedInsertConfig,
      delete: deleteOvermoldedInsertConfig,
    }
  }
]

const nestedPtpcFieldsCrud = [
  {
    fieldName: 'postProcesses',
    getTemplate: getPostProcessConfigTemplate,
    primaryKey: 'id',
    apiCalls: {
      create: createPostProcessConfig,
      update: updatePostProcessConfig,
      delete: deletePostProcessConfig,
    }
  },{
    fieldName: 'attachments',
    getTemplate: getAttachmentTemplate,
    primaryKey: 'fileId',
    apiCalls: {
      create: createFile,
      update: () => { throw new Error('Not yet implemented. No user action did this up to this point.') },
      delete: deleteFile,
    }
  }
]

export default configureNestedPartGroupFieldsCrud(nestedObjectsConfig, nestedPtpcFieldsCrud)

async function getAttachmentTemplate(file, userEmail, projectNumber){
  const s3Id = await s3.uploadFileToS3(file)
  return {
    s3Id,
    fileName: file.name,
    isActive: true,
    fileType: 'attachment',
    projectId: projectNumber,
    userUploaded: userEmail,
  }
}

function getTolerancesConfigTemplate(){
  return {
    featureNumber: '',
    type: 'dimensional',
    nominal: '',
    lowerBound: '',
    upperBound: '',
    quantity: '',
  }
}

function getPostProcessConfigTemplate(postProcessId,isDmeReviewed,customerConfig,dmeConfig){
  return {
    postProcessId,
    customerConfig, // doesnt seem great, as could be a string, number, enum, etc.
    dmeConfig, // doesnt seem great, as could be a string, number, enum, etc.
    percentageOfPart: 100,
    averageLengthOfSpecialOp: '',
    hardwarePackQty: '',
    hardwareCostPerPack: '',
    isDmeReviewed,
  }
}

function getOvermoldedInsertsConfigTemplate(){
  return {
    name: '',
    qtyInsertsPerPart: '',
    qtyInsertsPerPack: '',
    costPerPack: '',
    volume: '',
    surfaceArea: '',
    xDimension: '',
    yDimension: '',
    zDimension: '',
  }
}
