import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export async function pollDatabaseForWallThicknessResult(partNumber, units) {
    const WALL_THICKNESS_FIELDS_SUCCESS_MM = ['wallThicknessOBJFileMM','wallThicknessMaterialFileMM']
    const WALL_THICKNESS_FIELDS_SUCCESS_INCHES = ['wallThicknessOBJFileInches','wallThicknessMaterialFileInches']
    const wtPartFieldsToCheckFor = (units === 'mm') ? WALL_THICKNESS_FIELDS_SUCCESS_MM : WALL_THICKNESS_FIELDS_SUCCESS_INCHES

    const wtErrorField = (units === 'mm') ? 'wallThicknessErrorMM' : 'wallThicknessErrorInches'

    const throttle = 3000
    const timeout = 900000

    return pollDatabaseForResult(partNumber, wtPartFieldsToCheckFor, wtErrorField, throttle, timeout)
}
