import React from "react"
import { formatPrice } from "../../../utils"

export const FormattedPriceText = ({ unitPrice, quantity }) => (
  <>
    {formatPrice(unitPrice)}&nbsp;ea.
    <br/>
    <b>{ formatPrice(unitPrice * quantity) }</b>
  </>
)
