import React, { useState } from 'react'
import SmartShipmentTracking from './SmartShipmentTracking.js'
import withProject from '../Common/withProject.js'
import { AppBar } from '@material-ui/core'
import AppToolBar from '../Common/AppToolBar'
import {loadProject} from '../../utils'
import saveProjectAndNestedObjects from './saveProjectAndNestedObjects.js'

function ShipmentTrackingPage(props){
  const [isSaving, setIsSaving] = useState(false)

  async function saveProjectHandler(project){
    const updatedProject = await this.saveProjectPromise(project)
    const loadedProject = await loadProject(project.projectNumber, props.user)
    props.setProject(loadedProject, {dontRecalc: true})
  }

  function saveProjectPromise(project){
    setIsSaving(true)
    return saveProjectAndNestedObjects(project)
      .catch(err => {
        props.openSnackBarHandler(`There was an error saving the project: ${err.message}`)
        throw err
      })
      .finally(() => setIsSaving(false))
  }

  return (
    <>
      <AppBar position={"sticky"}>
        <AppToolBar loggedIn={true} showHomeLink={true} user={props.user}/>
      </AppBar>
      <SmartShipmentTracking
        currentlySaving={isSaving} // controls an infinite LinearProgress bar in ActivitiesForm and BoxesForm. Ui lock associated with props.saveProject
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        onDoneButtonClick={() => props.navigateToSummaryPage(props.project.projectNumber) } // after making all paperwork, users can navigate back to some main page using this button handler
        postProcessData={props.postProcessData}
        project={props.project}
        saveProjectHandler={saveProjectHandler} // save project, then set the response to state (this save project creates sub objects so we need to add those ids to state, among other defaults)
        saveProjectPromise={saveProjectPromise} // save project, don't set to state but return the saved project
        setProject={props.setProject} // set project to state, don't save
        user={props.user}
      />
    </>
  )
}

export default withProject(ShipmentTrackingPage)
