import React from "react"
import { NumberField } from '../../NumberField.js'
import { SelectOption } from '../../SelectOption.js'

const CONFIG_TYPE = {
  none: null,
  comboBox: 'comboBox',
  number: 'number',
}

export default function PostProcessConfig({
  className,
  configSelectOptions,
  configNameValue,
  configTitle,
  configType,
  postProcessObject,
  setSpecificPostProcessingField,
}){
  switch(configType) {
    case CONFIG_TYPE.none:
      return null
      return <div> No Config </div>
      break

    case CONFIG_TYPE.comboBox:
      return(
        <ComboBoxInput
          className={className}
          configNameValue={configNameValue}
          configSelectOptions={configSelectOptions}
          configTitle={configTitle}
          postProcessObject={postProcessObject}
          setSpecificPostProcessingField={setSpecificPostProcessingField}
        />
      )
      break

    case CONFIG_TYPE.number:
      return(
        <NumberInput
          className={className}
          configNameValue={configNameValue}
          configTitle={configTitle}
          postProcessObject={postProcessObject}
          setSpecificPostProcessingField={setSpecificPostProcessingField}
        />
      )
      break

    default:
      throw new Error(`Unknown post processing CONFIG_TYPE: ${configType}`)
  }
}

function ComboBoxInput({className, configSelectOptions, configNameValue, configTitle, postProcessObject, setSpecificPostProcessingField}){
  return (
    <SelectOption
      className={className}
      label={configTitle}
      name={configNameValue}
      onChange={e => setSpecificPostProcessingField(configNameValue, e.target.value)}
      styles={{width:'10em'}}
      value={postProcessObject[configNameValue]}
    >
      {configSelectOptions
       .map(value =>
            <option key={`customerConfigSelectOption-${value}`} value={value}> {value} </option>
           )
      }
    </SelectOption>
  )
}

function NumberInput({className, configTitle, configNameValue, postProcessObject, setSpecificPostProcessingField}){
  return (
    <NumberField
      className={className}
      label={configTitle}
      name={configNameValue}
      onChange={e => setSpecificPostProcessingField(configNameValue, e.target.value)}
      size='small'
      styles={{width:'10em'}}
      value={postProcessObject[configNameValue]}
    />
  )
}

