const { logUnexpected } = require('../../../utils/logUnexpected.js')

export async function generatePackslipPdf(project, shipmentNumber, includeBoxesPaperwork) {
  let response
  try{
    response = await generatePackslipPdfRequest(project, shipmentNumber, includeBoxesPaperwork)
  } catch (err) {
    logUnexpected(err)
    throw err
  }

  let generatePackslipPdfResponse
  try{
    generatePackslipPdfResponse = parseGeneratePackslipPdfResponse(response)
  } catch (err) {
    logUnexpected(err)
    throw err
  }

  return generatePackslipPdfResponse
}

async function generatePackslipPdfRequest(project, shipmentNumber, includeBoxesPaperwork) {
  return fetch('/documents/create/packslip', {
    method: 'post',
    body: JSON.stringify({
      project,
      shipmentNumber,
      includeBoxesPaperwork,
    }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json",
    }
  })
    .then(res => res.json())
}

function parseGeneratePackslipPdfResponse(data) {
  if(data.error){
    throw Error(data.error)
  }
  try{
    return data.success.base64Pdf
  } catch (err) {
    let newError = Error('Unexpected data shape')
    newError.data = data
    newError.originalError = err
    throw newError
  }
}

