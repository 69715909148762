/*
  FINISH_TRANSLATIONS is an array of objects that are used to determine which finishes are
  available for a given process
*/
const FINISH_TRANSLATIONS =
[
  { "id": "slsBlackDye", "display": "Black Dye", "processId": "sls", "active": "No" },
  { "id": "slsCaWaterproof", "display": "CA Waterproof", "processId": "sls", "active": "No" },
  { "id": "slsCerakote", "display": "Cerakote", "processId": "sls", "active": "No" },
  { "id": "fdmChromed", "display": "Chromed", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetChromed", "display": "Chromed", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfChromed", "display": "Chromed", "processId": "mjf", "active": "Yes" },
  { "id": "slsChromed", "display": "Chromed", "processId": "sls", "active": "Yes" },
  { "id": "slaChromed", "display": "Chromed", "processId": "sla", "active": "Yes" },
  { "id": "fffChromed", "display": "Chromed", "processId": "fff", "active": "Yes" },
  { "id": "mjfCopperPlated", "display": "Copper Plated", "processId": "mjf", "active": "Yes" },
  { "id": "slsCopperPlated", "display": "Copper Plated", "processId": "sls", "active": "Yes" },
  { "id": "slaCopperPlated", "display": "Copper Plated", "processId": "sla", "active": "Yes" },
  { "id": "fffCopperPlated", "display": "Copper Plated", "processId": "fff", "active": "Yes" },
  { "id": "fdmDimensionalReport", "display": "Dimensional Report", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetDimensionalReport", "display": "Dimensional Report", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfDimensionalReport", "display": "Dimensional Report", "processId": "mjf", "active": "Yes" },
  { "id": "slsDimensionalReport", "display": "Dimensional Report", "processId": "sls", "active": "Yes" },
  { "id": "slaDimensionalReport", "display": "Dimensional Report", "processId": "sla", "active": "Yes" },
  { "id": "fffDimensionalReport", "display": "Dimensional Report", "processId": "fff", "active": "Yes" },
  { "id": "fdmEMIShielded", "display": "EMI Shielded", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetEMIShielded", "display": "EMI Shielded", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfEMIShielded", "display": "EMI Shielded", "processId": "mjf", "active": "Yes" },
  { "id": "slsEMIShielded", "display": "EMI Shielded", "processId": "sls", "active": "Yes" },
  { "id": "slaEMIShielded", "display": "EMI Shielded", "processId": "sla", "active": "Yes" },
  { "id": "fffEMIShielded", "display": "EMI Shielded", "processId": "fff", "active": "Yes" },
  { "id": "fdmHeatPressInserts", "display": "Heat Press Inserts", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetHeatPressInserts", "display": "Heat Press Inserts", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfHeatPressInserts", "display": "Heat Press Inserts", "processId": "mjf", "active": "Yes" },
  { "id": "slsHeatPressInserts", "display": "Heat Press Inserts", "processId": "sls", "active": "Yes" },
  { "id": "slaHeatPressInserts", "display": "Heat Press Inserts", "processId": "sla", "active": "Yes" },
  { "id": "fffHeatPressInserts", "display": "Heat Press Inserts", "processId": "fff", "active": "Yes" },
  { "id": "fdmHydrographics", "display": "Hydrographics", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetHydrographics", "display": "Hydrographics", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfHydrographics", "display": "Hydrographics", "processId": "mjf", "active": "Yes" },
  { "id": "slsHydrographics", "display": "Hydrographics", "processId": "sls", "active": "Yes" },
  { "id": "slaHydrographics", "display": "Hydrographics", "processId": "sla", "active": "Yes" },
  { "id": "fffHydrographics", "display": "Hydrographics", "processId": "fff", "active": "Yes" },
  { "id": "slsImprex", "display": "Imprex", "processId": "sls", "active": "No" },
  { "id": "slaInsideOutsidePattern", "display": "Inside/Outside Pattern", "processId": "sla", "active": "No" },
  { "id": "slaInsidePattern", "display": "Inside Pattern", "processId": "sla", "active": "No" },
  { "id": "mjfNickelPlated", "display": "Nickel Plated", "processId": "mjf", "active": "Yes" },
  { "id": "slsNickelPlated", "display": "Nickel Plated", "processId": "sls", "active": "Yes" },
  { "id": "slaNickelPlated", "display": "Nickel Plated", "processId": "sla", "active": "Yes" },
  { "id": "fffNickelPlated", "display": "Nickel Plated", "processId": "fff", "active": "Yes" },
  { "id": "slaOpticalClarityTreatment", "display": "Optical Clarity Treatment", "processId": "sla", "active": "Yes" },
  { "id": "polyJetOpticalClarityTreatment", "display": "Optical Clarity Treatment", "processId": "polyJet", "active": "Yes" },
  { "id": "slaOther", "display": "Other - See Notes", "processId": "sla", "active": "No" },
  { "id": "slsOther", "display": "Other - See Notes", "processId": "sls", "active": "No" },
  { "id": "castingOther", "display": "Other - See Notes", "processId": "casting", "active": "No" },
  { "id": "cncOther", "display": "Other - See Notes", "processId": "cnc", "active": "No" },
  { "id": "dmlsOther", "display": "Other - See Notes", "processId": "dmls", "active": "No" },
  { "id": "eSlaOther", "display": "Other - See Notes", "processId": "eSla", "active": "No" },
  { "id": "fdmOther", "display": "Other - See Notes", "processId": "fdm", "active": "No" },
  { "id": "fffOther", "display": "Other - See Notes", "processId": "fff", "active": "No" },
  { "id": "injMoldingMoldOther", "display": "Other - See Notes", "processId": "injMoldingMold", "active": "No" },
  { "id": "injMoldingPartOther", "display": "Other - See Notes", "processId": "injMoldingPart", "active": "No" },
  { "id": "mjfOther", "display": "Other - See Notes", "processId": "mjf", "active": "No" },
  { "id": "polyJetOther", "display": "Other - See Notes", "processId": "polyJet", "active": "No" },
  { "id": "rapidCncOther", "display": "Other - See Notes", "processId": "rapidCnc", "active": "No" },
  { "id": "sheetMetalOther", "display": "Other - See Notes", "processId": "sheetMetal", "active": "No" },
  { "id": "otherOther", "display": "Other - See Notes", "processId": "other", "active": "No" },
  { "id": "slaOutsidePattern", "display": "Outside Pattern", "processId": "sla", "active": "No" },
  { "id": "fdmPadPrinted", "display": "Pad Printed", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetPadPrinted", "display": "Pad Printed", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfPadPrinted", "display": "Pad Printed", "processId": "mjf", "active": "Yes" },
  { "id": "slsPadPrinted", "display": "Pad Printed", "processId": "sls", "active": "Yes" },
  { "id": "fffPadPrinted", "display": "Pad Printed", "processId": "fff", "active": "Yes" },
  { "id": "slaPaint", "display": "Painted", "processId": "sla", "active": "Yes" },
  { "id": "fdmPainted", "display": "Painted", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetPainted", "display": "Painted", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfPainted", "display": "Painted", "processId": "mjf", "active": "Yes" },
  { "id": "slsPainted", "display": "Painted", "processId": "sls", "active": "Yes" },
  { "id": "fffPainted", "display": "Painted", "processId": "fff", "active": "Yes" },
  { "id": "slaQuickclear", "display": "Quickclear", "processId": "sla", "active": "No" },
  { "id": "fdmSmoothingTreatment", "display": "Smoothing Treatment", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetSmoothingTreatment", "display": "Smoothing Treatment", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfSmoothingTreatment", "display": "Smoothing Treatment", "processId": "mjf", "active": "Yes" },
  { "id": "slsSmoothingTreatment", "display": "Smoothing Treatment", "processId": "sls", "active": "Yes" },
  { "id": "slaSmoothingTreatment", "display": "Smoothing Treatment", "processId": "sla", "active": "Yes" },
  { "id": "fffSmoothingTreatment", "display": "Smoothing Treatment", "processId": "fff", "active": "Yes" },
  { "id": "fdmSoftTouchCoated", "display": "Soft Touch Coated", "processId": "fdm", "active": "Yes" },
  { "id": "polyJetSoftTouchCoated", "display": "Soft Touch Coated", "processId": "polyJet", "active": "Yes" },
  { "id": "mjfSoftTouchCoated", "display": "Soft Touch Coated", "processId": "mjf", "active": "Yes" },
  { "id": "slsSoftTouchCoated", "display": "Soft Touch Coated", "processId": "sls", "active": "Yes" },
  { "id": "slaSoftTouchCoated", "display": "Soft Touch Coated", "processId": "sla", "active": "Yes" },
  { "id": "fffSoftTouchCoated", "display": "Soft Touch Coated", "processId": "fff", "active": "Yes" },
  { "id": "slaStandard", "display": "Standard", "processId": "sla", "active": "Yes" },
  { "id": "slsStandard", "display": "Standard", "processId": "sls", "active": "Yes" },
  { "id": "castingStandard", "display": "Standard", "processId": "casting", "active": "Yes" },
  { "id": "cncStandard", "display": "Standard", "processId": "cnc", "active": "Yes" },
  { "id": "dmlsStandard", "display": "Standard", "processId": "dmls", "active": "Yes" },
  { "id": "eSlaStandard", "display": "Standard", "processId": "eSla", "active": "Yes" },
  { "id": "fdmStandard", "display": "Standard", "processId": "fdm", "active": "Yes" },
  { "id": "fffStandard", "display": "Standard", "processId": "fff", "active": "Yes" },
  { "id": "injMoldingMoldStandard", "display": "Standard", "processId": "injMoldingMold", "active": "Yes" },
  { "id": "injMoldingPartStandard", "display": "Standard", "processId": "injMoldingPart", "active": "Yes" },
  { "id": "mjfStandard", "display": "Standard", "processId": "mjf", "active": "Yes" },
  { "id": "polyJetStandard", "display": "Standard", "processId": "polyJet", "active": "Yes" },
  { "id": "rapidCncStandard", "display": "Standard", "processId": "rapidCnc", "active": "Yes" },
  { "id": "sheetMetalStandard", "display": "Standard", "processId": "sheetMetal", "active": "Yes" },
  { "id": "otherStandard", "display": "Standard", "processId": "other", "active": "Yes" },
  { "id": "slaStripShip", "display": "Strip & Ship", "processId": "sla", "active": "Yes" },
  { "id": "slaUSPVIBiocompatibility", "display": "USP VI Biocompatibility", "processId": "sla", "active": "Yes" },
  { "id": "slaWaterclear", "display": "Waterclear", "processId": "sla", "active": "No" },
  { "id": "slsWaterTightTreatment", "display": "Water Tight Treatment", "processId": "sls", "active": "Yes" },
  { "id": "fffWaterTightTreatment", "display": "Water Tight Treatment", "processId": "fff", "active": "Yes" }
]
export default FINISH_TRANSLATIONS

/*
  GenerateFinishList is a method that can be imported to other modules
  Use it to acquire a list of objects that match your given process
*/
export function GenerateFinishList(processId,blankOptionEnabled){
  let FILTERED_FINISH_LIST = FINISH_TRANSLATIONS.filter(currentFinish => {
    if(currentFinish.processId === processId && currentFinish.active === "Yes"){
      return currentFinish
    }
    return null
  })
  if(blankOptionEnabled){
    FILTERED_FINISH_LIST.push({ "id": "", "display": "", "processId": "", "active": "Yes" })
  }
  return FILTERED_FINISH_LIST
}

/*
  TranslateFinishId takes a process(that matches some FINISH_TRANSLATIONS element's processID)
  and a finish that matches some FINISH_TRANSLATIONS element's id) and returns that element's display value
*/
export function TranslateFinishId(process,finish){
  const FILTERED_FINISH_LIST = GenerateFinishList(process)
  const RELEVANT_FINISH_OBJECT = FILTERED_FINISH_LIST.find(currentFinish => {
    if(currentFinish.id === finish){
      return currentFinish
    }
    return null
  })
  //If there is no display value to be shown (in the case of a part not having an optional finish value) return an empty string
  const DISPLAY_VALUE = (RELEVANT_FINISH_OBJECT) ? RELEVANT_FINISH_OBJECT.display : ""
    return DISPLAY_VALUE
}

/*
  getDefaultFinish takes a process string and returns a finish id string that is the standard finish for that process
*/
export function getDefaultFinish(process){
  if(!process){ //If resetting process to '', set finish to '' as well
    return ""
  }
  else{ //For all other process types, find the standard finish
    let filteredArray = FINISH_TRANSLATIONS.filter(currentFinish => {
      if(currentFinish.processId === process){
        return currentFinish
      }
      return null
    })
    let finishToReturn = filteredArray.find(currentFinish => {
      if(currentFinish.display === "Standard"){
        return currentFinish
      }
      return null
    })
    return finishToReturn.id
  }
}
