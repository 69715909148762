import React from 'react'
import {Paper,Table,TableBody,TableCell,TableRow,Typography} from '@material-ui/core'
import TranslateExpediteLevel from '../../../utils/TranslateExpediteLevelToValue.js'
import MaybeTruncatedValue from './MaybeTruncatedValue.js'

const ProjectMakeOrderDataCard = ( props ) => {
  const { project, ...otherProps } = props
  return (
    <Paper
      { ...otherProps }
    >
      <Table
        className="make-order-row-height"
      >
        <TableBody>
          <TableRow>
            <TableCell className="table-header-row"
 colSpan="2">
              <Typography variant="h6">
                Project Make Order Data
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="key-cell" >
              Order Place Date
            </TableCell>
            <TableCell>
              {project.orderDate}
            </TableCell>
          </TableRow>

          {project.samplesLeadTime ?
          <TableRow>
            <TableCell className="key-cell" >
              Samples Lead Time
            </TableCell>
            <TableCell>
              {project.samplesLeadTime} day{project.samplesLeadTime > 1 ? 's' : ''}
            </TableCell>
          </TableRow>
           : null}

          <TableRow>
            <TableCell className="key-cell" >
              Lead Time
            </TableCell>
            <TableCell>
              {project.paidLeadTime} day{project.paidLeadTime > 1 ? 's' : ''}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="key-cell" >
              First Ship Date
            </TableCell>
            <TableCell>
              {project.paidShipDate}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="key-cell" >
              Supplier/Internal Only Notes
            </TableCell>
            <TableCell>
              <MaybeTruncatedValue
                value={project.supplierNotes}
                length={320}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

function parseLeadTime(project) {
  return TranslateExpediteLevel({ ...project })
}

export default ProjectMakeOrderDataCard

