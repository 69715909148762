import React from 'react'
import {Button,Table,TableBody,TableCell,TableHead,TableRow,Typography} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EDIT_ALL_BOXES from './EDIT_ALL_BOXES.js'
import { NumberField } from '../../QuoteTool/NumberField.js'
import { calculateLeftToBox, calculatePtpcTotal, calculatePtpcLeftToBox, calculateTotal } from './boxConfigurationTableCalculations.js'

export default function BoxesConfigurationTable({boxes, boxNumberToEdit, deleteBox, project, setPartInBoxQuantity, setPartInBoxPtpcQuantity, shipmentId}){
  return (
    <Table size="small">
      <HeaderRow
        boxes={boxes}
        deleteBox={deleteBox}
      />
      <TableBody>
        {
          project.partGroups
            .map(partGroup =>
                 <>
                 <PartBatchRow
                   boxes={boxes}
                   boxNumberToEdit={boxNumberToEdit}
                   key={`PartBatchRow-${partGroup.partGroupNumber}`}
                   partGroup={partGroup}
                   project={project}
                   setPartInBoxQuantity={setPartInBoxQuantity}
                   shipmentId={shipmentId}
                 />
                   {partGroup.productionToolPartConfigurations
                    .map(ptpc =>
                         <PtpcPartBatchRow
                           boxes={boxes}
                           boxNumberToEdit={boxNumberToEdit}
                           key={`PtpcPartBatchRow-${partGroup.partGroupNumber}-${ptpc.productionToolPartConfigurationId}`}
                           partGroup={partGroup}
                           project={project}
                           ptpc={ptpc}
                           setPartInBoxPtpcQuantity={setPartInBoxPtpcQuantity}
                           shipmentId={shipmentId}
                         />
                        )}
                 </>
                )
        }
      </TableBody>
    </Table>
  )
}

function HeaderRow({boxes, deleteBox}){
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{background:'gray'}}>Part</TableCell>
        <TableCell style={{background:'darkGray'}}>Notes</TableCell>
        <TableCell>Total Left in Project</TableCell>
        {boxes.map(box => BoxNameCell(box, deleteBox))}
        <TableCell style={{background:'darkGray'}}>Total in this Shipment</TableCell>
      </TableRow>
    </TableHead>
  )
}

function BoxNameCell(box, deleteBox){
  return (
    <TableCell key={box.boxNumber} style={!isEven(box.boxNumber) ? {background:'lightGray'} : null}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography> Box {box.boxNumber} </Typography>
        <Button
          onClick={() => deleteBox(box.boxNumber)}
          style={{minWidth: 0}}
        >
          <DeleteForeverIcon />
        </Button>
      </div>
    </TableCell>
  )
}

function PartBatchRow({boxes, boxNumberToEdit, partGroup, project, setPartInBoxQuantity, shipmentId}){
  return (
    <TableRow>
      <TableCell style={{width: '10em', background:'gray'}}>{partGroup.part.fileName} - {getProcessDisplay(partGroup)}</TableCell>
      <TableCell style={{minWidth: '30em', background:'darkGray'}}>{partGroup.part.partNotes}</TableCell>
      <TableCell style={{width: '8em'}}>{calculateLeftToBox(boxes, partGroup.partGroupNumber, project, shipmentId)}</TableCell>
      {
        boxes.map((box, index) => {
          return (
            <BoxQuantityCell
              box={box}
              index={index}
              isActiveInput={boxNumberToEdit === box.boxNumber || boxNumberToEdit === EDIT_ALL_BOXES}
              key={`${partGroup.partGroupNumber}_${box.boxNumber}`}
              partGroupNumber={partGroup.partGroupNumber}
              setPartInBoxQuantity={quantity => setPartInBoxQuantity(index, partGroup.partGroupNumber, quantity)}
            />
	  )
        })
      }
      <TableCell style={{width: '8em', background:'darkGray'}}>{calculateTotal(partGroup.partGroupNumber, boxes)}</TableCell>
    </TableRow>
  )
}

function getProcessDisplay(partGroup, isPtpc){
  if(partGroup.processId === 'injMoldingPart'){
    return isPtpc ? 'IM Part' : 'Injection Mold'
  }
  if(partGroup.processId === 'casting'){
    return isPtpc ? 'Casting Part' : 'Casting Mold'
  }
  return partGroup.process
}

function PtpcPartBatchRow({boxes, boxNumberToEdit, partGroup, project, ptpc, setPartInBoxPtpcQuantity, shipmentId}){
  return (
    <TableRow>
      <TableCell style={{width: '10em', background:'gray'}}>{partGroup.part.fileName} - {getProcessDisplay(partGroup, true)}</TableCell>
      <TableCell style={{minWidth: '30em', background:'darkGray'}}>{partGroup.part.partNotes}</TableCell>
      <TableCell style={{width: '8em'}}>{calculatePtpcLeftToBox(boxes, ptpc.productionToolPartConfigurationId, project, shipmentId)}</TableCell>
      {
        boxes.map((box, index) => {
          return (
            <BoxPtpcQuantityCell
              box={box}
              index={index}
              isActiveInput={boxNumberToEdit === box.boxNumber || boxNumberToEdit === EDIT_ALL_BOXES}
              key={`${ptpc.productionToolPartConfigurationId}_${box.boxNumber}`}
              ptpcId={ptpc.productionToolPartConfigurationId}
              setPartInBoxPtpcQuantity={quantity => setPartInBoxPtpcQuantity(index, ptpc.productionToolPartConfigurationId, quantity)}
            />
          )
        })
      }
      <TableCell style={{width: '8em', background:'darkGray'}}>{calculatePtpcTotal(ptpc.productionToolPartConfigurationId, boxes)}</TableCell>
    </TableRow>
  )
}

function BoxQuantityCell(props){
  const partInBox = props.box.partsInBox.find(pt => pt.partGroupNumber === props.partGroupNumber)
  const value = partInBox && partInBox.quantity ? partInBox.quantity : 0

  return (
    <TableCell
      style={cellStyle(props.index, props.isActiveInput)}
      size='small'
    >
      { props.isActiveInput?
        <NumberField
          label="quantity"
          onChange={e => props.setPartInBoxQuantity(e.target.value)}
          style={{width: '100%'}}
          value={value}
          variant="standard"
        />
       :
        value
      }
    </TableCell>
  )
}

function BoxPtpcQuantityCell({ isActiveInput, box, index, ptpcId, setPartInBoxPtpcQuantity }){
  const partInBox = box.partsInBox.find(pt => pt.ptpcId === ptpcId)
  const value = partInBox && partInBox.quantity ? partInBox.quantity : 0

  return (
    <TableCell
      style={cellStyle(index, isActiveInput)}
      size='small'
    >
      { isActiveInput?
        <NumberField
          label="quantity"
          onChange={e => setPartInBoxPtpcQuantity(e.target.value)}
          style={{width: '100%'}}
          value={value}
          variant="standard"
        />
       :
        value
      }
    </TableCell>
  )
}

function cellStyle(index, isActiveInput){
  return {
    background: isEven(index) ? 'lightGray' : 'white',
    minWidth: isActiveInput ? '12em': '6em',
  }
}

function isEven(x){ return x % 2 == 0 }

