import {format} from 'date-fns'
import {formatToTimeZone} from 'date-fns-timezone'

export {getOrderDateFromCurrentTime}

function getOrderDateFromCurrentTime() {
  let date
  const currentUnixTime = Date.now()
  const easternTime = formatToTimeZone(currentUnixTime, 'HH:mm:ss', {timeZone: 'America/New_York'})
  const easternDate = formatToTimeZone(currentUnixTime, 'MM/DD/YYYY', {timeZone: 'America/New_York'})

  // Orders placed after 4:30PM should have the order placed date be the next day
  if (isAfterCutoffTime(easternTime)) {
    // set order placed date to tomorrow
    date = new Date(easternDate).setDate(new Date(easternDate).getDate() + 1)
  } else {
    // set order placed date to today
    date = easternDate
  }

  // format the date and retun it
  return format(date, 'MM/DD/YYYY')
}

// check if a time in the format HH:mm:ss (24 hour clock) is after 4:30 PM
function isAfterCutoffTime(time) {
  const CUTOFF_TIME = '16:30:00' // 4:30 PM

  if (time > CUTOFF_TIME) { return true }
  return false
}
