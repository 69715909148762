export const CONTACTED_THROUGH_COMMUNICATION_PLATFORMS = {
  "blank":"",
  "email":"Email",
  "instantQuoteTool":"Instant Quote Tool",
  "hubsBos":"Hubs BOS",
  "hubsCa":"Hubs CA",
  "makeXYZ":"MakeXYZ",
  "xometry":"Xometry",
  "3Diligent":"3Diligent",
  "all3Dp":"All3DP",
  "3dExperienceMarketplace":"3D Experience Marketplace",
  "fictiv":"Fictiv"
}
